/**
 * Seznam inzerce
 */
import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as state from "../../../lib/state";
import * as navigation from "../../../lib/navigation";
import * as api from "../../../lib/api";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import IconSvg from "../../../components/controls/IconSvg";
import Paginator from "../../system/lists/ListPaginator";
import Spinner from "../../../components/controls/Spinner";
import Card from "../../../components/controls/Card";
import FormItem from "../../../components/controls/Forms/FormItem";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import Checkbox from "../../../components/controls/Checkbox";
import Button from "../../../components/controls/Button";
import FormsSelect from "../../../components/controls/Forms/FormSelect";
import HtmlContent from "../../../components/controls/HtmlContent";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";

function AdvertismentsPage() {
	const { advertisements: model } = state.useStateContext();
	const filterForm = model.list.getAdvancedFilterForm();
	const { contents } = state.useStateContext();
	const { authorization } = state.useStateContext();

	return (
		<Page title={contents.getRes("prilezitosti-inzerce-nadpis")} description="">
			<h1 className="d-flex align-items-center mb-5">
				<HtmlContent htmlKey="prilezitosti-inzerce-nadpis" />
				{model.list.justLoadingData() &&
					<Spinner small className="ml-1" />
				}
			</h1>

			<FormsSelect
				form={filterForm}
				field="region"
				options={[
					{
						title: "- Vyberte kraj -",
						value: undefined
					},
					...model.getRegions().map(i => ({
						title: i.name,
						value: i
					}))
				]}
			/>

			{model.getCategories().length == 0 && model.justLoadingCategories() &&
				<div className="d-flex align-items-center mb-4">
					<Spinner small className="mr-2" /> <span>Načítám seznam rubrik...</span>
				</div>
			}
			{model.getCategories().length > 0 &&
				<FormItem form={filterForm} field="categories">
					<CheckBoxRadioFrame className="mb-formitem column-count-lg-2">
						{model.getCategories().map(i =>
							<Checkbox
								title={i.name}
								toolip={i.tooltip}
								checked={filterForm.getField("categories").value.find(j => i.id === j) !== undefined}
								onCheckedChanged={_ => model.toggleCategory(i.id)} />
						)}
					</CheckBoxRadioFrame>
				</FormItem>
			}

			<CheckBoxRadioFrame className="mb-formitem">
				{authorization.userLoggedIn() &&
					<FormsCheckBox
						form={filterForm}
						enhancedTitle={<>Moje inzerce</>}
						field="my_advertisement"
					/>
				}
				{!authorization.userLoggedIn() &&
					<div className="ml-3">
						<div>
						Pro zobrazení "mojí inzerce" je nutné přihlášení.
						</div>
						<div className="mt-3">
							<Button theme="primary" onClick={_ => navigation.to("/prihlaseni")}>
								Přihlášení
							</Button>
						</div>
				    </div>
				}
			</CheckBoxRadioFrame>

			{authorization.userLoggedIn() &&
				<div className="mb-5">
					<Button theme="primary" onClick={_ => navigation.to("/inzerce/" + api.emptyGuid)}>
						<IconSvg name="plus" className="mr-3" />Přidat inzerát
					</Button>
				</div>
			}

			{!authorization.userLoggedIn() &&
				<CheckBoxRadioFrame className="mb-formitem">
					<div className="ml-3">
						<p>
							Pokud inzerujete poprvé a nemáte přístup, je potřebné se nejdříve zaregistrovat (nejedná se o přihlášení se do členské sekce).
						</p>
						<div className="mt-3">
							<Button theme="primary" onClick={_ => navigation.to("/registrace")}>
								Registrace
							</Button>
						</div>
					</div>
				</CheckBoxRadioFrame>
			}

			<div className="mb-5">
				{model.list.getData().map(i =>
					<Card className="mb-4">
						<div className="row">
							<div className="col-lg-3">
								<p>
									{formatting.formatDate(i.system_information?.creation.date)}<br />
									<div className="d-lg-none">
										<strong>{i.categories.map(i => i.name).join(", ")}</strong>
									</div>
									{i.region.name}
								</p>

								{i.contact_email.trim().length > 0 &&
									<>
										<a href={"mailto:" + i.contact_email}>{i.contact_email}</a><br />
									</>
								}
								{i.contact_phone.trim().length > 0 &&
									<>
										tel: <a href={"tel:" + i.contact_phone}>{i.contact_phone}</a><br />
									</>
								}
								{i.contact_web.trim().length > 0 &&
									<>
										<a href={i.contact_web}>{i.contact_web}</a>
									</>
								}
							</div>
							<div className="col-lg-7">
								<p className="d-none d-lg-block">
									<strong>{i.categories.map(i => i.name).join(", ")}</strong>
								</p>
								<HtmlContent content={i.description} />

							</div>
							{i.is_own &&
								<div className="col-lg-2 text-right">
									<Button theme="primary" onClick={_ => navigation.to("/inzerce/" + i.id)}>
										Editace
									</Button>
								</div>
							}
						</div>
					</Card>
				)}

				<Paginator list={model.list} />
			</div>
		</Page >
	);
}

export default state.bindContainers(
	AdvertismentsPage,
	c => c.advertisements
);