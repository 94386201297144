import * as React from "react";
import * as routing from "./lib/routing";
import { StateContext } from "./context";

import HomePage from "./modules/pages/home/HomePage";
import MyProfilePage from "./modules/pages/my-profile/MyProfilePage";
import MyEducationPage from "./modules/pages/my-education/MyEducationPage";
import ClickableObligationPage from "./modules/pages/clickable-obligation/ClickableObligationPage";
import MyRecordsPage from "./modules/pages/my-records/MyRecordsPage";
import WebRequestsPage from "./modules/pages/web-requests/WebRequestsPage";
import NewsPage from "./modules/pages/news/NewsPage";
import NewsFormMembersPage from "./modules/pages/news-for-members/NewsFormMembersPage";
import NewsDetailPage from "./modules/pages/news-detail/NewsDetailPage";
import DynamicListItemPage from "./modules/pages/dynamic-list-item/DynamicListItemPage";
import RegistrationPage from "./modules/pages/registration/RegistrationPage";
import DentistsPage from "./modules/pages/dentists/DentistsPage";
import DentistsAlphabeticalPage from "./modules/pages/dentists-alphabetical/DentistsAlphabeticalPage";
import DentistsHostListPage from "./modules/pages/dentists-host-list/DentistsHostListPage";
import DentistsVisitingGuests from "./modules/pages/dentists-visiting-guests/DentistsVisitingGuests";
import EmergenciesPage from "./modules/pages/emergencies/EmergenciesPage";
import DentistPage from "./modules/pages/dentist/DentistPage";
import WorkplacesPage from "./modules/pages/workplaces/WorkplacesPage";
import EducationalActionsPage from "./modules/pages/educational-actions/EducationsActionsPage";
import EducationalActionPage from "./modules/pages/educational-action/EducationalActionPage";
import StagePage from "./modules/pages/stage/StagePage";
import StagesPage from "./modules/pages/stages/StagesPage";
import MyEducationalActionsPage from "./modules/pages/my-educational-actions/MyEducationalActionsPage";
import MyEducationalProgramsPage from "./modules/pages/my-educational-programs/MyEducationalProgramsPage";
import MyMessagesPage from "./modules/pages/my-messages/MyMessagesPage";
import MyDocumentsPage from "./modules/pages/my-documents/MyDocumentsPage";
import MyExamsPage from "./modules/pages/my-exams/MyExamsPage";
import MyPaymentPrescriptionsPage from "./modules/pages/my-payment-prescriptions/MyPaymentPrescriptionsPage";
import OskPage from "./modules/pages/osk/OskPage";
import OskMembersMaterialsPage from "./modules/pages/osk-materials/OskMaterialsPage";
import OskMemebersNegotiationPage from "./modules/pages/osk-materials-negotiation/OskMaterialsNegotiationPage";
import OskOrgansPage from "./modules/pages/osk-organs/OskOrgansPage";
import RulesOfProcedures from "./modules/pages/rules-of-procedures/RulesOfProcedures";
import OskMembersPage from "./modules/pages/osk-members/OskMembersPage";
import OskTransferFeesPage from "./modules/pages/osk-transfer-fees/OskTransferFeesPage";
import OskTransferFeesItemsPage from "./modules/pages/osk-transfer-fees-items/OskTransferFeesItemsPage";
import OskMessagesPage from "./modules/pages/osk-messages/OskMessagesPage";
import OskDocumentsPage from "./modules/pages/osk-documents/OskDocumentsPage";
import OskWebRequestsPage from "./modules/pages/osk-web-requests/OskWebRequestsPage";
import OskProfilePage from "./modules/pages/osk-profile/OskProfilePage";
import MemberPage from "./modules/pages/member/MemberPage";
import MessagePage from "./modules/pages/message/MessagePage";
import StaticTargetPage from "./modules/pages/static-target/StaticTargetPage";
import ContactsCskPage from "./modules/pages/contacts-csk/ContactsCskPage";
import ContactsExpertsPage from "./modules/pages/contacts-experts/ContactsExpertsPage";
import ContactsOskPage from "./modules/pages/contacts-osk/ContactsOskPage";
import ContactsForJournalistsPage from "./modules/pages/contacts-for-journalists/ContactsForJournalistsPage";
import ContactsProfessionalCompaniesPage from "./modules/pages/contacts-professional-companies/ContactsProfessionalCompaniesPage";
import ContactsClinicsPage from "./modules/pages/contacts-clinics/ContactsClinicsPage";
import ContactsStudentsPage from "./modules/pages/contacts-students/ContactsStudentsPage";
import ContactsComplanitsPage from "./modules/pages/contacts-complaints/ContactsComplanitsPage";
import PresidentialElectionInformationPage from "./modules/pages/presidential-election-information/PresidentialElectionInformationPage";
import PresidentialElectionLegalAnalysisPage from "./modules/pages/presidential-election-legal-analysis/PresidentialElectionLegalAnalysisPage";
import CoronavirusVariousPage from "./modules/pages/coronavirus-various/CoronavirusVariousPage";
import CoronavirusHealthInsurencePage from "./modules/pages/coronavirus-health-insurance/CoronavirusHealthInsurencePage";
import CoronavirusSituationReportrsPage from "./modules/pages/coronavirus-situation-reports/CoronavirusSituationReportsPage";
import CoronavirusPracticeDuringCovidPage from "./modules/pages/coronavirus-practice-during-covid/CoronavirusPracticeDuringCovidPage";
import CoronavirusLaborLegalIssuesPage from "./modules/pages/coronavirus-labor-legal-issues/CoronavirusLaborLegalIssuesPage";
import AgendaScheduleOfEventsPage from "./modules/pages/agenda-schedule-of-events/AgendaScheduleOfEventsPage";
import AgendaLegalAdvicePage from "./modules/pages/agenda-legal-advice/AgendaLegalAdvicePage";
import AgendaEconomicAdvicePage from "./modules/pages/agenda-economic-advice/AgendaEconomicAdvicePage";
import AgendaEconomyPage from "./modules/pages/agenda-economy/AgendaEconomyPage";
import AgendaElearningWebinarPage from "./modules/pages/agenda-elearning-webinar/AgendaElearningWebinarPage";
import PartnersPage from "./modules/pages/partners/PartnersPage";
import AgendaMeetingOfBoardDirectorsPage from "./modules/pages/agenda-meetings-of-board-directors/AgendaMeetingOfBoardDirectorsPage";
import EducationPage from "./modules/pages/education/EducationPage";
import EducationAboutPage from "./modules/pages/education-about/EducationAboutPage";
import EducationCongressPDDPage from "./modules/pages/education-congress-pdd/EducationCongressPDDPage";
import EducationActionRegistrationPage from "./modules/pages/education-action-registration/EducationActionRegistrationPage";
import EducationMagazinesPage from "./modules/pages/education-magazines/EducationMagazinesPage";
import WebRequestOskCskEntryPage from "./modules/pages/web-request-osk-csk-entry/WebRequestOskCskEntryPage";
import WebRequestOskCskEntryPage3 from "./modules/pages/web-request-osk-csk-entry/WebRequestOskCskEntryPage3";
import AboutUsPage from "./modules/pages/about-us/AboutUsPage";
import AboutUsGraphicStandartsPage from "./modules/pages/about-us-graphic-standarts/AboutUsGraphicStandartsPage";
import AboutUsBecomeMemberPage from "./modules/pages/about-us-become-member/AboutUsBecomeMemberPage";
import AboutUsOfficialDeskPage from "./modules/pages/about-us-official-desk/AboutUsOfficialDeskPage";
import AboutUsGDPRPage from "./modules/pages/about-us-gdpr/AboutUsGDRPPage";
import AboutUsEstablishmentRegulationsPage from "./modules/pages/about-us-establishment-regulations/AboutUsEstablishmentRegulationsPage";
import AboutUsYearBooksPage from "./modules/pages/about-us-yearbooks/AboutUsYearbooksPage";
import AboutUsParliamentaryProceedingsPage from "./modules/pages/about-us-parliamentary-proceedings/AboutUsParliamentaryProceedingsPage";
import AboutUsCskAuthoritiesPage from "./modules/pages/about-us-csk-authorities/AboutUsCskAuthoritiesPage";
import AboutUsCouncilMembersPage from "./modules/pages/about-us-council-members/AboutUsCouncilMembersPage";
import AboutUsCskAuthorityPage from "./modules/pages/about-us-csk-authority/AboutUsCskAuthorityPage";
import PublicationPage from "./modules/pages/publication/PublicationPage";
import PublicationArticlePage from "./modules/pages/publication-article/PublicationArticlePage";
import AdvertisementsPage from "./modules/pages/advertisements/AdvertisementsPage";
import AdvertisementsEntryPage from "./modules/pages/advertisements-entry/AdvertisementsEntryPage";
import BooksPage from "./modules/pages/books/BooksPage";
import BooksItemPage from "./modules/pages/books-item/BooksItemPage";
import BooksCartPage from "./modules/pages/books-cart/BooksCartPage";
import BooksCheckoutPage from "./modules/pages/books-checkout/BooksCheckoutPage";
import PaymentGatewayResponsePage from "./modules/pages/payment-gateway-response/PaymentGatewayResponsePage";
import WebRequestClinicEducationContributionPage from "./modules/pages/web-request-clinic-education-contribution/WebRequestClinicEducationContributionPage";
import WebRequestIssueCertificatePage from "./modules/pages/web-request-issue-certificate/WebRequestIssueCertificatePage";
import WebRequestIssueFirstCertificatePage from "./modules/pages/web-request-issue-first-certificate/WebRequestIssueFirstCertificatePage";
import WebRequestExtenstionOfValidity from "./modules/pages/web-request-extension-of-validity/WebRequestExtensionOfValidityPage";
import WebRequestCreditCountingPage from "./modules/pages/web-request-credit-counting/WebRequestCreditCountingPage";
import WebRequestEntryEduProgramPage from "./modules/pages/web-request-entry-edu-program/WebRequestEntryEduProgramPage";
import WebRequestExpertsEntry from "./modules/pages/web-request-experts-entry/WebRequestExpertsEntryPage";
import WebRequestIssueCertificateAfterEducationPage from "./modules/pages/web-request-issue-certificate-after-education/WebRequestIssueCertificateAfterEducationPage";
import WebRequestExamInVpPage from "./modules/pages/web-request-exam-in-vp/WebRequesExamInVpPage";
import WebRequestExamInVpRepeatedPage from "./modules/pages/web-request-exam-in-vp/WebRequestExamInVpRepeatedPage";
import WebRequestChangeExamDatePage from "./modules/pages/web-request-change-exam-date/WebRequestChangeExamDatePage";
import WebRequestMembersRemovingPage from "./modules/pages/web-request-members-removing/WebRequestMembersRemovingPage";
import WebRequestMembersConfirmationPage from "./modules/pages/web-request-members-confirmation/WebRequestMembersConfirmationPage";
import WebRequestMembersLeavingPage from "./modules/pages/web-request-members-leaving/WebRequestMembersLeavingPage";
import WebRequestChildCarePage from "./modules/pages/web-request-child-care/WebRequestChildCarePage";
import WebRequestForAChildAppeal from "./modules/pages/web-request-for-a-child-appeal/WebRequestForAChildAppeal";
import WebRequestChangeOfPersonalDataPage from "./modules/pages/web-request-change-of-personal-data/WebRequestChangeOfPersonalDataPage";
import WebRequestPrescriptionPage from "./modules/pages/web-request-prescription/WebRequestPrescriptionPage";
import WebRequestFeeRemissionPage from "./modules/pages/web-request-fee-remission/WebRequestFeeRemissionPage";
import WebRequestLeadDoctorCertificatePage from "./modules/pages/web-request-issue-certificate/WebRequestIssueCertificatePage";
import WebRequestJobFinishPage from "./modules/pages/web-request-job-finish/WebRequestJobFinishPage";
import WebRequestChangeContactsPage from "./modules/pages/web-request-change-contacts/WebRequestChangeContactsPage";
import WebRequestOskRegistrationPage from "./modules/pages/web-request-osk-registration/WebRequestOskRegistrationPage";
import WebRequestOskRegistrationJobPage from "./modules/pages/web-request-osk-registration-job/WebRequestOskRegistrationJobPage";
import WebRequestActionRegistrationPage from "./modules/pages/web-request-education-action-registration/WebRequestActionRegistrationPage";
import AgendaDentalPracticePage from "./modules/pages/agenda-dental-practice/AgendaDentalPracticePage";
import WebRequestRepetitionOfTheProfessionalExamPage from "./modules/pages/web-request-repetition-of-the-professional-exam/WebRequestRepetitionOfTheProfessionalExamPage";
import WebRequestIssueCerificateAfterLifelongEducationPage from "./modules/pages/web-request-issue-certificate-after-lifelong-education/WebRequestIssueCerificateAfterLifelongEducationPage";
import WebRequestActionRegistrationPageK4 from "./modules/pages/web-request-action-registration/WebRequestActionRegistrationPage";
import WebRequestOrderCspzlPage from "./modules/pages/web-request-order-cspzl/WebRequestOrderCspzlPage";
import WebRequestOrderLksPage from "./modules/pages/web-request-order-lks/WebRequestOrderLksPage";
import WebRequestAccreditationPage from "./modules/pages/web-request-accreditation/WebRequestAccreditationPage";
import WebRequestIssueCertificateRepresentativePage from "./modules/pages/web-request-issue-certificate-representative/WebRequestIssueCertificateRepresentativePage";
import WorkplacesListPage from "./modules/pages/workplaces/WorkplacesListPage";
import ContactOrgansPage from "./modules/pages/contact-organs/ContactOrgansPage";
import AssemblyMaterialsPage from "./modules/pages/assembly-materials/AssemblyMaterialsPage";
import WorkplaceEditPage from "./modules/pages/workplaces/WorkplaceEditPage";
import WebRequestJobInterruptPage from "./modules/pages/web-request-job-interrupt/WebRequestJobInterruptPage";
import WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl from "./modules/pages/web-request-for-performance-professional-exams-within-the-entry-program-vppzl/WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl";
import WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023 from "./modules/pages/web-request-for-performance-professional-exams-within-the-entry-program-vppzl2023/WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023";
import WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat from "./modules/pages/web-request-for-performance-professional-exams-within-the-entry-program-vppzl2023-repeat/WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat";
import WebRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl from "./modules/pages/web-request-about-performing-professional-exams-repeated-10 years-experience-vppzl/WebRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl";
import EducationActionELearningRegistrationPage from "./modules/pages/education-action--e-learning-registration/EducationActionELearningRegistrationPage";
import WebRequestActionELearningRegistrationPage from "./modules/pages/web-request-e-learning-action-registration/WebRequestActionELearningRegistrationPage";
import ForgottenPassword from "./modules/pages/forgotten-password/ForgottenPassword";
import ResetPassword from "./modules/pages/reset-password/ResetPassword";
import PrivacyStatementPage from "./modules/pages/privacy-statement/PrivacyStatementPage";
import MethodologicalGuidelinesPage from "./modules/pages/methodological_guidelines/MethodologicalGuidelinesPage";
import ChangePasswordPage from "./components/sections/ChangePasswordPage";
import MyEducationalPageCategory from "./modules/pages/my-educational-actions/MyEducationalPageCategory";
import ContentPage from "./modules/pages/content/ContentPage";
import LoginDificultiespage from "./modules/pages/login-difficulties/LoginDificultiesPage";
import OtherPage from "./modules/pages/other/OtherPage";
import AgendaMaterialPage from "./modules/pages/agenda-materials/AgendaMaterialPage";
import PublicPage from "./modules/pages/public-page/PublicPage";
import WebRequestDisposableEducationalProgramRemovableProsthetics from "./modules/pages/web-request-disposable-educational-program-removable-prosthetics/WebRequestDisposableEducationalProgramRemovableProsthetics";
import DocumentsSnemPage from "./modules/pages/documents/DocumentsSnemPage";
import WebRequestInsuranceApplicationPage from "./modules/pages/web-request-insurance-application/WebRequestInsuranceApplicationPage";
import WebRequestCheckoutFromInsurancePage from "./modules/pages/web-request-checkout-from-insurance/WebRequestCheckoutFromInsurancePage";
import WebRequestChangeFormPage from "./modules/pages/web-request-change-form/WebRequestChangeFormPage";
import CookieInfoPage from "./modules/pages/cookie-info/CookieInfoPage";
import AccessibilityStatementPage from "./modules/pages/accessibility-statement/AccessibilityStatementPage";
import ProfessionalInsurancePage from "./modules/pages/professional-insurance/ProfessionalInsurancePage";
import PresidentialElectionPage from "./modules/pages/elections/PresidentialElectionPage";
import OskElectionPage from "./modules/pages/osk-election/OskElectionPage";

export default function createRoutes(context: StateContext, hostname: string): routing.Route[] {

	async function loadWorkplaceLists() {
		context.workplacesListMain.loadData();
		context.workplacesListSecondary.loadData();
	}

	async function loadData(fnc: (id?: string, param?: any) => void, id?: string, param?: any) {
		const profile = context.authorization.getUserProfile();
		if (context.authorization.userLoggedIn() && profile) {
			fnc(id, param);
		}
		else if (!profile) {
			setTimeout(function () { loadData(fnc, id, param) }, 250)
		}
	}


	async function loadDataWithParam(fnc: (param?: any) => void, param?: any) {
		const profile = context.authorization.getUserProfile();
		if (context.authorization.userLoggedIn() && profile) {
			fnc(param);
		}
		else if (!profile) {
			setTimeout(function () { loadData(fnc, param) }, 250)
		}
	}

	function loadWebRequestExamDate() {
		context.webRequestChangeExamDate.reset(false)
	}

	return [
		{
			route: "/",
			component: HomePage,
			loadData: context.home.loadData,
			loadSsr: true
		},
		{
			route: "/muj-profil",
			component: MyProfilePage,
			loadData: () => loadData(context.myProfile.loadData),
			loadSsr: false
		},
		{
			route: "/moje-vzdelani",
			component: MyEducationPage,
			loadData: () => loadData(context.myEducation.loadData),
		},
		{
			route: "/zmena-hesla",
			component: ChangePasswordPage
		},
		{
			route: "/klikaci-povinnost",
			component: ClickableObligationPage,
			loadData: _ => context.webRequestOskCskEntry3.reset()
		},
		{
			route: "/aktuality",
			component: NewsPage,
			loadData: context.news.loadData,
			loadSsr: false
		},
		{
			route: "/aktuality-pro-cleny",
			aliases: ["/prihlaseni"],
			component: NewsFormMembersPage,
			loadData: context.newsForMembers.loadData

		},
		{
			route: "/aktuality/:id",
			component: NewsDetailPage,
			loadData: (match) => context.newsDetail.loadData((match.params as any).id),
			loadSsr: false
		},
		{
			route: "/aktuality-pro-cleny/:id",
			render: (route) => <NewsDetailPage authorized={true} />,
			loadData: (match) => context.newsDetail.loadData((match.params as any).id),
			loadSsr: false
		},
		{
			route: "/stranky/:id",
			render: (route) => <NewsDetailPage authorized={true} isPage={true} />,
			loadData: (match) => loadData(context.newsDetail.loadData, (match.params as any).id),
			loadSsr: false
		},
		{
			route: "/stranky-verejne/:id",
			render: (route) => <NewsDetailPage isPage={true} />,
			loadData: (match) => context.newsDetail.loadData((match.params as any).id),
			loadSsr: false
		},
		{
			route: "/obsah/:id",
			aliases: [
				"/o-nas/stavovsky-predpis/:id",
				"/agendy-komory/materialy-ze-snemu/:id",
				"/o-nas/jednani-snemu/:id",
				"/agendy-komory/jednani-predstavenstva/:id",
				"/agendy-komory/financovani-zubni-praxe/:id",
				"/agendy-komory/provozovani-zubni-praxe/:id",
				"/agendy-komory/vse-o-koronaviru/:id"
			],
			component: DynamicListItemPage,
			loadData: (match) => context.dynamicListItem.loadData((match.params as any).id),
			loadSsr: false
		},
		{
			route: "/registrace",
			component: RegistrationPage,
			loadSsr: false
		},
		{
			route: "/registrace",
			component: RegistrationPage,
			loadSsr: false
		},
		{
			route: "/metodicke-pokyny",
			component: MethodologicalGuidelinesPage,
			loadData: _ => context.methodologicalGuidelines.loadData()
		},
		{
			route: "/zubni-lekari",
			component: DentistsPage,
			loadData: context.dentists.loadData,
			loadSsr: false
		},
		{
			route: "/zubni-lekari/abecedne",
			component: DentistsAlphabeticalPage,
			loadData: context.dentistsAlphabetical.loadData,
			loadSsr: false
		},
		{
			route: "/zubni-lekari/seznam-hostujicich-osob",
			component: DentistsHostListPage,
			loadData: context.dentistsHostList.loadData,
			loadSsr: false
		},
		{
			route: "/zubar/:id",
			component: DentistPage,
			loadData: (match) => context.dentist.loadData((match.params as any).id),
			loadSsr: false
		},
		{
			route: "/pohotovosti",
			component: EmergenciesPage,
			loadData: context.emergencies.loadData,
			loadSsr: false
		},
		{
			route: "/vzdelavani/akce",
			component: EducationalActionsPage,
			loadData: _ => context.educationalActions.loadData(),
			loadSsr: false
		},
		{
			route: "/vzdelavani/akce/:id",
			component: EducationalActionPage,
			loadData: (match) => context.educationalAction.loadData((match.params as any).id),
			loadSsr: false
		},
		{
			route: "/vzdelavani/pracoviste",
			component: WorkplacesPage,
			loadData: context.workplaces.loadData,
			loadSsr: false
		},
		{
			route: "/vzdelavani/staze",
			render: () => <StagesPage />,
			loadData: _ => context.stages.loadData(),
			loadSsr: false
		},
		{
			route: "/vzdelavani/staz/:id",
			render: () => <StagePage />,
			loadData: (match) => context.stage.loadData((match.params as any).id),
			loadSsr: false
		},
		{
			route: "/cms",
			component: StaticTargetPage,
		},
		{
			route: "/moje-agenda/zadosti",
			aliases: ["/moje-agenda"],
			component: WebRequestsPage,
			loadData: () => loadData(context.webRequests.loadData)
		},
		{
			route: "/moje-agenda/zpravy",
			component: MyMessagesPage,
			loadData: () => loadData(context.myMessages.loadData)
		},
		{
			route: "/moje-agenda/pracoviste",
			component: WorkplacesListPage,
			loadData: () => loadData(loadWorkplaceLists)
		},
		{
			route: "/muj-profil/zaznamy",
			component: MyRecordsPage,
			loadData: () => loadData(context.myRecords.loadData)
		},
		{
			route: "/moje-agenda/dokumenty",
			component: MyDocumentsPage,
			loadData: () => loadData(context.myDocuments.loadData)
		},
		{
			route: "/agendy-komory/materialy-ze-snemu",
			component: DocumentsSnemPage,
			loadData: () => loadData(context.documentsSnem.list.load)
		},
		{
			route: "/moje-agenda/akce",
			component: MyEducationalActionsPage,
			loadData: () => loadData(context.myEducationalActions.loadData)
		},
		{
			route: "/moje-agenda/akce/pridat",
			component: MyEducationalPageCategory
		},
		{
			route: "/moje-agenda/zkousky",
			component: MyExamsPage,
			loadData: () => loadData(context.myExams.loadData)
		},
		{
			route: "/moje-agenda/vzdelavaci-program",
			component: MyEducationalProgramsPage,
			loadData: () => loadData(context.myEducationalPrograms.loadData)
		},
		{
			route: "/moje-agenda/platby",
			component: MyPaymentPrescriptionsPage,
			loadData: () => loadData(context.myPaymentPrescriptions.loadData)
		},
		{
			route: "/osk",
			component: OskPage,
			loadData: () => loadData(context.osk.loadData)
		},
		{
			route: "/osk/materialy-pro-jednani",
			component: OskMemebersNegotiationPage,
			loadData: () => loadData(context.oskMaterialsNegotiations.loadData)

		},
		{
			route: "/osk/materialy-ze-skoleni",
			component: OskMembersMaterialsPage,
			loadData: () => loadData(context.oskMaterials.loadData)

		},
		{
			route: "/osk/organy-osk",
			component: OskOrgansPage,
			loadData: () => loadData(context.oskOrgans.loadData)
		},
		{
			route: "/rady-csk",
			component: RulesOfProcedures,
		},
		{
			route: "/osk/profil/:id",
			component: OskProfilePage,
			loadData: (match) => loadData(context.oskProfile.loadData, (match.params as any).id)
		},
		{
			route: "/osk/seznam-clenu-komory",
			component: OskMembersPage,
			loadData: () => loadData(context.oskMembers.loadData)
		},
		{
			route: "/osk/prevody-z-csk",
			component: OskTransferFeesPage,
			loadData: () => loadData(context.oskTransferFees.loadData)
		},
		{
			route: "/osk/prevody-z-csk/:id",
			component: OskTransferFeesItemsPage,
			loadData: (match) => loadData(context.oskTransferFeesItems.loadData, (match.params as any).id)
		},
		{
			route: "/osk/zadosti",
			component: OskWebRequestsPage,
			loadData: () => loadData(context.oskWebRequests.loadData)
		},
		{
			route: "/osk/zpravy",
			component: OskMessagesPage,
			loadData: () => loadData(context.oskMessages.loadData)
		},
		{
			route: "/osk/dokumenty",
			component: OskDocumentsPage,
			loadData: () => loadData(context.oskDocuments.loadData)
		},
		{
			route: "/clen/:id",
			component: MemberPage,
			loadData: (match) => context.member.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zprava/:id",
			component: MessagePage,
			loadData: (match) => context.message.loadData((match.params as any).id)
		},
		{
			route: "/kontakty/kancelar",
			aliases: ["/kontakty"],
			component: ContactsCskPage,
			loadData: _ => context.contactsCsk.loadData()
		},
		{
			route: "/kontakty/znalci",
			component: ContactsExpertsPage,
			loadData: () => loadData(context.contactsExperts.loadData)
		},
		{
			route: "/kontakty/osk",
			component: ContactsOskPage,
			loadData: _ => context.contactsOsk.loadData()
		},
		{
			route: "/kontakty/pro-novinare",
			component: ContactsForJournalistsPage,
			loadData: _ => context.contactsForJournalists.loadData()
		},
		{
			route: "/kontakty/odborne-spolecnosti",
			component: ContactsProfessionalCompaniesPage,
			loadData: _ => context.contactsProfessionalCompanies.loadData()
		},
		{
			route: "/kontakty/kliniky",
			component: ContactsClinicsPage
		},
		{
			route: "/kontakty/studenti",
			component: ContactsStudentsPage
		},
		{
			route: "/kontakty/stiznosti",
			component: ContactsComplanitsPage
		},
		{
			route: "/volby-prezidenta/info",
			aliases: ["/volby-prezidenta"],
			component: PresidentialElectionInformationPage
		},
		{
			route: "/volby-prezidenta/pravni-analyza",
			component: PresidentialElectionLegalAnalysisPage
		},
		{
			route: "/vse-o-koronaviru/ruzne",
			aliases: ["/vse-o-koronaviru"],
			component: CoronavirusVariousPage
		},
		{
			route: "/vse-o-koronaviru/zdravotni-pojistovny",
			component: CoronavirusHealthInsurencePage
		},
		{
			route: "/vse-o-koronaviru/situacni-zpravy",
			component: CoronavirusSituationReportrsPage
		},
		{
			route: "/vse-o-koronaviru/provoz-praxe-v-dobe-covidu",
			component: CoronavirusPracticeDuringCovidPage
		},
		{
			route: "/vse-o-koronaviru/pracovne-pravni-otazky",
			component: CoronavirusLaborLegalIssuesPage
		},
		{
			route: "/agendy-komory/harmonogram-akci-csk",
			component: AgendaScheduleOfEventsPage
		},
		{
			route: "/agendy-komory/pravni-poradna",
			component: AgendaLegalAdvicePage
		},
		{
			route: "/agendy-komory/ekonomicka-stranka",
			component: AgendaEconomicAdvicePage
		},
		{
			route: "/agendy-komory/hospodareni-csk",
			component: AgendaEconomyPage,
			loadData: () => loadData(context.economy.loadData)
		},
		{
			route: "/agendy-komory/ruzne",
			component: AgendaMaterialPage,
		},
		{
			route: "/agendy-komory/elearnig-a-webinare",
			aliases: ["/agendy-komory"],
			component: AgendaElearningWebinarPage
		},
		{
			route: "/agendy-komory/jednani-predstavenstva",
			component: AgendaMeetingOfBoardDirectorsPage,
			loadData: _ => context.meetingOfBoardDirectors.loadData()
		},
		{
			route: "/agendy-komory/provozovani-zubni-praxe",
			component: AgendaDentalPracticePage,
			loadData: _ => context.dentalPractice.loadData()
		},
		{
			route: "/zubni-lekari/seznam-hostujicich-osob/",
			component: DentistsVisitingGuests,
			loadData: _ => context.dentistsVisitingGuests.loadData()
		},
		{
			route: "/komercni-spoluprace",
			component: PartnersPage,
		},
		{
			route: "/vzdelavani",
			component: EducationPage
		},
		{
			route: "/vzdelavani/o-vzdelavani",
			component: EducationAboutPage
		},
		{
			route: "/vzdelavani/kongres-pdd",
			component: EducationCongressPDDPage
		},
		{
			route: "/vzdelavani/registrace-akce",
			component: EducationActionRegistrationPage
		},
		{
			route: "/vzdelavani/registrace-e-learningova-akce",
			component: EducationActionELearningRegistrationPage
		},
		{
			route: "/vzdelavani/casopis",
			component: EducationMagazinesPage
		},
		{
			route: "/o-nas",
			component: AboutUsPage,
			loadSsr: false,
			loadData: _ => context.aboutUs.loadData()
		},
		{
			route: "/o-nas/graficke-standardy",
			component: AboutUsGraphicStandartsPage
		},
		{
			route: "/o-nas/organy-csk",
			component: AboutUsCskAuthoritiesPage
		},
		{
			route: "/o-nas/stante-se-clenem",
			component: AboutUsBecomeMemberPage
		},
		{
			route: "/o-nas/uredni-deska",
			component: AboutUsOfficialDeskPage
		},
		{
			route: "/o-nas/gdpr",
			component: AboutUsGDPRPage
		},
		{
			route: "/zapomenute-heslo",
			component: ForgottenPassword
		},
		{
			route: "/reset-hesla",
			component: ResetPassword
		},
		{
			route: "/o-nas/stavovske-predpisy",
			component: AboutUsEstablishmentRegulationsPage,
			loadData: _ => context.aboutUsEstabilishmentRegulations.loadData()
		},
		{
			route: "/o-nas/rocenky",
			component: AboutUsYearBooksPage,
			loadData: _ => context.aboutUsYearbooks.loadData()
		},
		{
			route: "/o-nas/snem",
			component: AboutUsParliamentaryProceedingsPage,
			loadData: _ => context.aboutUsParliamentaryProceedings.loadData(1)
		},
		{
			route: "/obsahy",
			component: ContentPage,
			loadData: _ => context.content.loadData("", 1)
		},
		{
			route: "/o-nas/clenove-snemu",
			component: AboutUsCouncilMembersPage,
			loadData: _ => context.aboutUsCouncilMembers.loadData()
		},
		{
			route: "/prohlaseni-o-ochrane-soukromi",
			component: PrivacyStatementPage,
		},
		{
			route: "/o-nas/predstavenstvo",
			component: AboutUsCskAuthorityPage,
			loadData: _ => context.aboutUsCskAuthority.loadData(true)
		},
		{
			route: "/o-nas/cestna-rada",
			component: AboutUsCskAuthorityPage,
			loadData: _ => context.aboutUsCskAuthority.loadData(false)
		},
		{
			route: "/o-nas/revizni-komise",
			component: AboutUsCskAuthorityPage,
			loadData: _ => context.aboutUsCskAuthority.loadData(true)
		},
		{
			route: "/komornik",
			component: PublicationPage,
			loadData: _ => context.publication.loadData()
		},
		{
			route: "/kontakty-organy-csk",
			component: ContactOrgansPage,
			loadData: () => loadData(context.contactOrgans.loadData)
		},
		{
			route: "/komornik/clanek/:id",
			component: PublicationArticlePage,
			loadData: match => context.publicationArticle.loadData((match.params as any).id)
		},
		{
			route: "/inzerce",
			component: AdvertisementsPage,
			loadData: _ => context.advertisements.loadData()
		},
		{
			route: "/inzerce/:id",
			component: AdvertisementsEntryPage,
			loadData: match => loadData(context.advertisementsEntry.loadData, (match.params as any).id)
		},
		{
			route: "/vzdelavani/knihy",
			component: BooksPage,
			loadData: _ => context.books.loadData()
		},
		{
			route: "/vzdelavani/knihy/kosik",
			component: BooksCartPage,
			loadData: _ => context.shopCart.loadData()
		},
		{
			route: "/vzdelavani/knihy/pokladna",
			component: BooksCheckoutPage,
			loadData: _ => context.booksCheckout.loadData()
		},
		{
			route: "/vzdelavani/knihy/:id",
			component: BooksItemPage,
			loadData: match => context.booksItem.loadData((match.params as any).id)
		},
		{
			route: "/platebni-brana/odpoved",
			component: PaymentGatewayResponsePage,
			loadData: context.paymentGatewayResponse.confirm
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zapis-do-csk",
			component: WebRequestOskCskEntryPage,
			loadData: _ => context.webRequestOskCskEntry.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zapis-do-csk/:id",
			render: (route) => <WebRequestOskCskEntryPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestOskCskEntry.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-prispevek-z-uctu",
			component: WebRequestClinicEducationContributionPage,
			loadData: () => loadData(context.webRequestClinicEducationContribution.reset)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-prispevek-z-uctu/:id",
			render: (route) => <WebRequestClinicEducationContributionPage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestClinicEducationContribution.loadData, (match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-prvniho-osvedceni",
			component: WebRequestIssueFirstCertificatePage,
			loadData: () => loadData(context.webRequestIssueFirstCertificate.reset)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-prvniho-osvedceni/:id",
			render: (route) => <WebRequestIssueFirstCertificatePage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestIssueFirstCertificate.loadData, (match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zapocitani-kreditu",
			loadData: match => context.webRequestCreditCounting.reset(),
			component: WebRequestCreditCountingPage
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zapocitani-kreditu/:id",
			render: (route) => <WebRequestCreditCountingPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestCreditCounting.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zarazeni-do-vstupniho-vp",
			component: WebRequestEntryEduProgramPage,
			loadData: () => loadData(context.webRequestEntryEduProgram.reset)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zarazeni-do-vstupniho-vp/:id",
			render: (route) => <WebRequestEntryEduProgramPage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestEntryEduProgram.loadData, (match.params as any).id)

		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zapis-do-seznamu-znalcu",
			component: WebRequestExpertsEntry,
			loadData: match => context.webRequestExpertsEntry.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zapis-do-seznamu-znalcu/:id",
			render: (route) => <WebRequestExpertsEntry id={(route as any).match.params.id} />,
			loadData: match => context.webRequestExpertsEntry.loadData((match.params as any).id)

		},
		{
			route: "/moje-agenda/zadosti/zadost-o-prodlouzeni-doby-platnosti-osvedceni",
			component: WebRequestExtenstionOfValidity,
			loadData: _ => context.webRequestExtensionOfValidity.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-prodlouzeni-doby-platnosti-osvedceni/:id",
			render: (route) => <WebRequestExtenstionOfValidity id={(route as any).match.params.id} />,
			loadData: match => context.webRequestExtensionOfValidity.loadData((match.params as any).id)

		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vykonani-profesni-zkousky-vp",
			render: _ => <WebRequestExamInVpPage />,
			loadData: () => loadData(context.webRequestExamInVp.reset)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vykonani-profesni-zkousky-vp/:id",
			render: (route) => <WebRequestExamInVpPage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestExamInVp.loadData, (match.params as any).id)

		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-pro-absolvovani-vp",
			render: _ => <WebRequestIssueCertificateAfterEducationPage />,
			loadData: _ => context.webRequestIssueCertificateAfterEducation.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-pro-absolvovani-vp/:id",
			render: (route) => <WebRequestIssueCertificateAfterEducationPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestIssueCertificateAfterEducation.loadData((match.params as any).id)

		},
		{
			route: "/moje-agenda/zadosti/zadost-o-opakovani-profesni-zkousky-vp",
			render: _ => <WebRequestExamInVpRepeatedPage />,
			loadData: () => loadData(context.webRequestExamInVp.reset)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-opakovani-profesni-zkousky-vp/:id",
			render: route => <WebRequestExamInVpRepeatedPage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestExamInVp.loadData, (match.params as any).id)

		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zmenu-terminu-profesni-zkousky",
			component: WebRequestChangeExamDatePage,
			loadData: () => loadData(loadWebRequestExamDate)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zmenu-terminu-profesni-zkousky/:id",
			render: route => <WebRequestChangeExamDatePage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestChangeExamDate.loadData, (match.params as any).id, false)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zmenu-terminu-profesni-zkousky-zop",
			render: _ => <WebRequestChangeExamDatePage zop />,
			loadData: () => loadDataWithParam(context.webRequestChangeExamDate.reset, true)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zmenu-terminu-profesni-zkousky-zop/:id",
			render: route => <WebRequestChangeExamDatePage zop id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestChangeExamDate.loadData, (match.params as any).id, true)
		},
		{
			route: "/moje-agenda/zadosti/zadost-bez-osk",
			component: WebRequestMembersRemovingPage,
			loadData: _ => context.webRequestMembersRemoving.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-bez-osk/:id",
			render: route => <WebRequestMembersRemovingPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestMembersRemoving.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-potvrzeni-clenstvi",
			component: WebRequestMembersConfirmationPage,
			loadData: _ => context.webRequestMembersConfirmation.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-potvrzeni-clenstvi/:id",
			render: route => <WebRequestMembersConfirmationPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestMembersConfirmation.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vystoupeni-z-CSK",
			component: WebRequestMembersLeavingPage,
			loadData: _ => context.webRequestMembersLeaving.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vystoupeni-z-CSK/:id",
			render: route => <WebRequestMembersLeavingPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestMembersLeaving.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-peci-o-dite-odvolani",
			component: WebRequestForAChildAppeal
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-jednorazovy-vzdelavaci-program-snimatelna-protetika",
			component: WebRequestDisposableEducationalProgramRemovableProsthetics
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-jednorazovy-vzdelavaci-program-snimatelna-protetika/:id",
			render: route => <WebRequestDisposableEducationalProgramRemovableProsthetics id={(route as any).match.params.id} />,
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-peci-o-dite",
			component: WebRequestChildCarePage,
			loadData: _ => context.webRequestChildCare.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-peci-o-dite/:id",
			render: route => <WebRequestChildCarePage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestChildCare.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zmenu-osobnich-udaju",
			component: WebRequestChangeOfPersonalDataPage,
			loadData: _ => context.webRequestChangeOfPersonalData.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zmenu-osobnich-udaju/:id",
			render: route => <WebRequestChangeOfPersonalDataPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestChangeOfPersonalData.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-preskripci",
			component: WebRequestPrescriptionPage,
			loadData: _ => context.webRequestPrescription.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-preskripci/:id",
			render: route => <WebRequestPrescriptionPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestPrescription.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-prominuti-prispevku",
			component: WebRequestFeeRemissionPage,
			loadData: _ => context.webRequestFeeRemission.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-prominuti-prispevku/:id",
			render: route => <WebRequestFeeRemissionPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestFeeRemission.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-primar-vedouci-lekar",
			component: WebRequestLeadDoctorCertificatePage,
			loadData: _ => context.webRequestIssueCertificate.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-primar-vedouci-lekar/:id",
			render: route => <WebRequestLeadDoctorCertificatePage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestIssueCertificate.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-ukonceni-vykonu-povolani",
			component: WebRequestJobFinishPage,
			loadData: _ => context.webRequestJobFinish.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-preruseni-vykonu-povolani",
			component: WebRequestJobInterruptPage,
			loadData: _ => context.webRequestJobInterrupt.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vykonani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl",
			component: WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl,
			loadData: _ => context.webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vyknani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl",
			component: WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl,
			loadData: _ => context.webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vyknani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl-2023",
			component: WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023,
			loadData: _ => context.webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vykonani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl-2023-opakovana",
			component: WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat,
			loadData: _ => context.WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023Repeat.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vykonani-profesni-zkousky-opakovane-10let-praxe-vppzl",
			component: WebRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl,
			loadData: _ => context.webRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vyknani-profesni-zkousky-opakovane-10let-praxe-vppzl",
			component: WebRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl,
			loadData: _ => context.webRequestAboutPerformingProfessionalExamsRepeated10YearsExperienceVppzl.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-ukonceni-vykonu-povolani/:id",
			render: route => <WebRequestJobFinishPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestJobFinish.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-preruseni-vykonu-povolani/:id",
			render: route => <WebRequestJobInterruptPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestJobInterrupt.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zmenu-kontaktnich-udaju",
			component: WebRequestChangeContactsPage,
			loadData: _ => context.webRequestChangeContacts.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zmenu-kontaktnich-udaju/:id",
			render: route => <WebRequestChangeContactsPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestChangeContacts.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zapis-do-hlavni-osk",
			component: WebRequestOskRegistrationPage,
			loadData: _ => context.webRequestOskRegistration.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-zapis-do-hlavni-osk/:id",
			render: route => <WebRequestOskRegistrationPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestOskRegistration.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/oznameni-o-zahajeni-vykonu",
			component: WebRequestOskRegistrationJobPage,
			loadData: _ => context.webRequestOskRegistrationJob.reset()
		},
		{
			route: "/moje-agenda/zadosti/oznameni-o-zahajeni-vykonu/:id",
			render: route => <WebRequestOskRegistrationJobPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestOskRegistrationJob.loadData((match.params as any).id)
		},
		{
			route: "/vzdelavani/prihlasit-se-na-akci/:id",
			render: route => <WebRequestActionRegistrationPage step="form" hostname={hostname} />,
			loadData: match => context.webRequestEducationActionRegistration.loadDefaultAction((match.params as any).id)
		},
		{
			route: "/vzdelavani/prihlasit-se-na-akci/:id/shrnuti",
			render: _ => <WebRequestActionRegistrationPage step="summary" hostname={hostname} />,
			loadData: _ => context.webRequestEducationActionRegistration.checkSummaryAvailableOrRedirect()
		},
		{
			route: "/moje-agenda/zadosti/zadost-prihlaska-na-akci",
			component: WebRequestActionRegistrationPage,
			loadData: _ => context.webRequestEducationActionRegistration.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-prihlaska-na-akci/:id",
			render: route => <WebRequestActionRegistrationPage id={(route as any).match.params.id} step="form" />,
			loadData: match => context.webRequestEducationActionRegistration.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-opakovani-profesni-zkousky",
			component: WebRequestRepetitionOfTheProfessionalExamPage,
			loadData: _ => context.webRequestRepetitionOfTheProfessionalExam.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-opakovani-profesni-zkousky/:id",
			render: route => <WebRequestRepetitionOfTheProfessionalExamPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestRepetitionOfTheProfessionalExam.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-po-celozivotnim-studiu",
			component: WebRequestIssueCerificateAfterLifelongEducationPage,
			loadData: _ => context.webRequestIssueCerificateAfterLifelongEducation.reset()

		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-po-celozivotnim-studiu/:id",
			render: route => <WebRequestIssueCerificateAfterLifelongEducationPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestIssueCerificateAfterLifelongEducation.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-registraci-akce",
			component: WebRequestActionRegistrationPageK4,
			loadData: _ => context.webRequestWebRequestActionRegistration.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-registraci-akce/:id",
			render: route => <WebRequestActionRegistrationPageK4 id={(route as any).match.params.id} />,
			loadData: match => context.webRequestWebRequestActionRegistration.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-registraci-e-lerningove-akce",
			component: WebRequestActionELearningRegistrationPage,
			loadData: _ => context.webRequestWebRequestActionElearningRegistration.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-registraci-e-lerningove-akce/:id",
			render: route => <WebRequestActionELearningRegistrationPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestWebRequestActionElearningRegistration.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zavazna-objednavka-casopis-cspzl",
			component: WebRequestOrderCspzlPage,
			loadData: _ => context.webRequestOrderCspzl.reset()
		},
		{
			route: "/moje-agenda/zadosti/zavazna-objednavka-casopis-cspzl/:id",
			render: route => <WebRequestOrderCspzlPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestOrderCspzl.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zavazna-objednavka-casopis-lks",
			component: WebRequestOrderLksPage,
			loadData: _ => context.webRequestOrderLks.reset()
		},
		{
			route: "/moje-agenda/zadosti/zavazna-objednavka-casopis-lks/:id",
			render: route => <WebRequestOrderLksPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestOrderLks.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-akreditaci-typu-i",
			component: WebRequestAccreditationPage,
			loadData: _ => context.webRequestAccreditation.reset("i")
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-akreditaci-typu-i/:id",
			render: route => <WebRequestAccreditationPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestAccreditation.loadData("i", (match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-akreditaci-typu-ii",
			component: WebRequestAccreditationPage,
			loadData: _ => context.webRequestAccreditation.reset("ii")
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-akreditaci-typu-ii/:id",
			render: route => <WebRequestAccreditationPage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestAccreditation.loadData("ii", (match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-odborny-zastupce",
			component: WebRequestIssueCertificateRepresentativePage,
			loadData: _ => context.webRequestIssueCertificateRepresentative.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-odborny-zastupce/:id",
			render: route => <WebRequestIssueCertificateRepresentativePage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestIssueCertificateRepresentative.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-obnoveni-zapisu-do-seznamu-clenu-csk",
			component: WebRequestOskCskEntryPage3,
			loadData: _ => context.webRequestOskCskEntry3.reset()
		},
		{
			route: "/moje-agenda/zadosti/zadost-o-obnoveni-zapisu-do-seznamu-clenu-csk/:id",
			render: (route) => <WebRequestOskCskEntryPage3 id={(route as any).match.params.id} />,
			loadData: match => context.webRequestOskCskEntry3.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/formular-x",
			component: WebRequestIssueCertificatePage,
			loadData: _ => context.webRequestIssueCertificate.reset()
		},
		{
			route: "/moje-agenda/zadosti/formular-x/:id",
			render: route => <WebRequestIssueCertificatePage id={(route as any).match.params.id} />,
			loadData: match => context.webRequestIssueCertificate.loadData((match.params as any).id)
		},
		{
			route: "/moje-agenda/pracoviste/:id",
			render: route => <WorkplaceEditPage id={(route as any).match.params.id} />,
			loadData: match => context.workplaceEditPage.loadData((match.params as any).id)
		},
		{
			route: "/problemy-s-prihlasenim",
			component: LoginDificultiespage,
		},
		{
			route: "/ostatni",
			component: OtherPage,
		},
		{
			route: "/o-nas/verejne",
			component: PublicPage,
		},
		{
			route: "/moje-agenda/zadosti/prihlaska-k-profesnimu-pojisteni",
			component: WebRequestInsuranceApplicationPage,
			loadData: () => loadData(context.webRequestInsuranceApplication.reset)
		},
		{
			route: "/moje-agenda/zadosti/prihlaska-k-profesnimu-pojisteni/:id",
			render: (route) => <WebRequestInsuranceApplicationPage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestInsuranceApplication.loadData, (match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/odhlaska-z-pojisteni",
			component: WebRequestCheckoutFromInsurancePage,
			loadData: () => loadData(context.webRequestCheckoutFromInsurance.reset)
		},
		{
			route: "/moje-agenda/zadosti/odhlaska-z-pojisteni/:id",
			render: (route) => <WebRequestCheckoutFromInsurancePage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestCheckoutFromInsurance.loadData, (match.params as any).id)
		},
		{
			route: "/moje-agenda/zadosti/zmenovy-tiskopis",
			component: WebRequestChangeFormPage,
			loadData: () => loadData(context.webRequestChangeForm.reset)
		},
		{
			route: "/moje-agenda/zadosti/zmenovy-tiskopis/:id",
			render: (route) => <WebRequestChangeFormPage id={(route as any).match.params.id} />,
			loadData: (match) => loadData(context.webRequestChangeForm.loadData, (match.params as any).id)
		},
		{
			route: "/zasady-ochrany-osobnich-udaju",
			component: CookieInfoPage,
		},
		{
			route: "/prohlaseni-o-pristupnosti",
			component: AccessibilityStatementPage,
		},
		{
			route: "/profesni-pojisteni",
			component: ProfessionalInsurancePage,
		},
		{
			route: "/volba-prezidenta",
			component: PresidentialElectionPage,
		},
		{
			route: "/volby-osk",
			component: OskElectionPage,
			loadData: () => loadData(context.oskElections.loadData)
		},
	];
}