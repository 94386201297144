/**
 * Seznam mých akcí
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import List from "../../system/lists/List";
import HtmlContent from "../../../components/controls/HtmlContent";

function OskMessagesPage() {
	const { myMessages } = state.useStateContext();
	const { contents } = state.useStateContext();

	return (
		<Page
			title={contents.getRes("seznam-zprav-nadpis")}
			description=""
			authorized
		>
			<h1>
				<HtmlContent htmlKey="seznam-zprav-nadpis" />
			</h1>
			<List list={myMessages.list} />
		</Page>
	);
}

export default state.bindContainers(
	OskMessagesPage,
	context => context.oskMessages
);