/**
 *  Formulář žádosti o zařazení do vstupního vzdělávacího programu
 */
import React from "react";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequestInsuranceApplication from "./web-request-change-form";
import WebRequest from "../../../components/templates/WebRequest";
import FormInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";
import FormFileUpload from "../../system/file/FormFileUpload";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import Checkbox from "../../../components/controls/Checkbox";
import IconSvg from "../../../components/controls/IconSvg";
import Input from "../../../components/controls/Input";

interface Props {
	id?: string;
}

function WebRequestChangeFormPage(props: Props) {
	const title = "ZMĚNOVÝ TISKOPIS";
	const { webRequestChangeForm } = state.useStateContext();
	const form = webRequestChangeForm.form;

	function setItem(index: number, value: boolean) {
		const items = form.getField("change_of_personal_data").value;
		items[index].checked = value;
		form.setField("change_of_personal_data", items);
	}

	async function setBasiItem(index: number, value: boolean) {
		await form.setField("scope_of_insurances", webRequestChangeForm.defaultValue());
		const items = form.getField("scope_of_insurances").value;
		items[index].checked = value;
		await form.setField("scope_of_insurances", items);
	}

	function setValue(index: number, value: string) {
		const items = form.getField("change_of_personal_data").value;
		items[index].value = value;
		form.setField("change_of_personal_data", items);
	}
	const sublimit = form.getField("scope_of_insurances").value.find(x => x.checked)?.sublimit;
	interface FormFilesProps {
		forms: forms.FormCollection<webRequestInsuranceApplication.FilesFormFields>;
		label: React.ReactNode;
		hint?: React.ReactNode;
		required?: boolean;
	}

	function FormFiles(props: FormFilesProps) {
		return (
			<div className="web-request-osk-csk-entry-page__files mb-4">
				<div className="mb-1">
					<div>
						{props.label}
						{props.required &&
							<span className="form-item__required ml-1"></span>
						}
					</div>
					<div>
						{props.hint}
					</div>
				</div>
				<Button className="mb-3" onClick={props.forms.add}>
					<IconSvg name="plus" className="mr-3" />

					{props.forms.get().length === 0 &&
						<>Připojit soubor</>
					}
					{props.forms.get().length > 0 &&
						<>Připojit další soubor</>
					}
				</Button >
				{props.forms.get().map((form, index) =>
					<>
						<FormFileUpload
							key={index}
							form={form}
							field="file"
						/>
						<div className="col-4 col-lg-2 pl-0">
							<Button className="d-block w-100 mt-3 mb-3" onClick={() => webRequestChangeForm.formsAttachment.remove(form)}>Odebrat</Button>
						</div>
					</>
				)
				}
			</div>
		);
	}


	return (
		<WebRequest
			title={title}
			description=""
			authorized
			model={webRequestChangeForm.commonModel}
			id={props.id}
		>
			<>
				na základě rámcové pojistné smlouvy č. <strong>8601750275</strong> uzavřené mezi
				Českou stomatologickou komorou a Kooperativou pojišťovnou, a.s., Vienna Insurance Group
			</>

			<h2>A.	Údaje o pojištěném </h2>


			<div className="row">
				<div className="col-sm-12">
					<FormInput
						form={form}
						field="insurance_name"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="provider_address"
					/>
				</div>
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="health_services_address"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<FormInput
						form={form}
						field="representation"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="registration_number"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="identification_number"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="account_number"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="phone"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="mobile"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="email"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<FormInput
						form={form}
						field="mailing_address"
					/>
				</div>
			</div>

			<hr />

			<h2>B.	Změna osobních údajů pojištěného (aktuální údaje, u kterých dochází ke změně)</h2>

			<table className="table-designed mt-5 mb-3">
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[0].checked}
							onCheckedChanged={value => setItem(0, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Titul, jméno a příjmení/ Název:</label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[0].value}
										onChange={value => setValue(0, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>

				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[10].checked}
							onCheckedChanged={value => setItem(10, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Rodné číslo:</label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[10].value}
										onChange={value => setValue(10, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>

				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[1].checked}
							onCheckedChanged={value => setItem(1, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Adresa sídla/ bydliště (uveďte včetně PSČ): </label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[1].value}
										onChange={value => setValue(1, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[2].checked}
							onCheckedChanged={value => setItem(2, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Adresa místa poskytování zdravotních služeb (uveďte včetně PSČ):</label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[2].value}
										onChange={value => setValue(2, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[3].checked}
							onCheckedChanged={value => setItem(3, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Zastoupená kým (jméno, příjmení, funkce) – vyplnit pouze u právnických osob: </label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[3].value}
										onChange={value => setValue(3, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[4].checked}
							onCheckedChanged={value => setItem(4, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Společník – člen ČSK (titul, jméno, příjmení, datum narození) </label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[4].value}
										onChange={value => setValue(4, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[5].checked}
							onCheckedChanged={value => setItem(5, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Odborný zástupce – člen ČSK (titul, jméno, příjmení, datum narození) </label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[5].value}
										onChange={value => setValue(5, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[6].checked}
							onCheckedChanged={value => setItem(6, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Korespondenční adresa (liší-li se od adresy sídla/bydliště), včetně PSČ:</label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[6].value}
										onChange={value => setValue(6, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[7].checked}
							onCheckedChanged={value => setItem(7, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Bankovní spojení:</label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[7].value}
										onChange={value => setValue(7, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[8].checked}
							onCheckedChanged={value => setItem(8, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Telefon/ Mobilní telefon:</label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[8].value}
										onChange={value => setValue(8, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>

				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[11].checked}
							onCheckedChanged={value => setItem(11, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">E-mail:</label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[11].value}
										onChange={value => setValue(11, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>

				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("change_of_personal_data").value[9].checked}
							onCheckedChanged={value => setItem(9, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-inline">Jiné (uveďte):</label>
								</div>
								<div className="col-md-6">
									<Input
										className="d-inline"
										value={form.getField("change_of_personal_data").value[9].value}
										onChange={value => setValue(9, value)}
									/>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</table>

			<hr />

			<h2>C.	Změna rozsahu pojištění (vyplňte pouze pokud ANO - v takovém případě uveďte kompletně rozsah pojištění po změně)</h2>
			<div className="d-flex mt-3">
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="change_in_insurance" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="change_in_insurance" option={false} title="NE" />
			</div>

			<div>
				<strong>P – Základní rozsah pojištění</strong> (pojištění profesní odpovědnosti; v jeho rámci pojištění obecné odpovědnosti s limitem plnění <strong>500 000 Kč</strong>)
			</div>

			<table className="table-designed mt-5 mb-5">
				<tr>
					<td className="font-weight-bold">Limit pojistného plnění pro pojištění profesní odpovědnosti v Kč </td>
					<td>Sublimit pro pojištění náhrady nemajetkové újmy (v Kč)</td>
					<td className="font-weight-bold">Roční pojistné v Kč </td>
					<td>Označte jednu z variant</td>
				</tr>
				<tr>
					<td className="font-weight-bold">2 000 000</td>
					<td>600 000</td>
					<td className="font-weight-bold">1 980</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[0].checked}
							onCheckedChanged={value => setBasiItem(0, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">3 000 000</td>
					<td>800 000</td>
					<td className="font-weight-bold">2 868</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[1].checked}
							onCheckedChanged={value => setBasiItem(1, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">4 000 000</td>
					<td>1 000 000</td>
					<td className="font-weight-bold">3 860</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[2].checked}
							onCheckedChanged={value => setBasiItem(2, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">5 000 000</td>
					<td>1 200 000</td>
					<td className="font-weight-bold">4 632</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[3].checked}
							onCheckedChanged={value => setBasiItem(3, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">7 000 000</td>
					<td>1 600 000</td>
					<td className="font-weight-bold">6 248</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[4].checked}
							onCheckedChanged={value => setBasiItem(4, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">10 000 000</td>
					<td>2 000 000</td>
					<td className="font-weight-bold">8 620</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[5].checked}
							onCheckedChanged={value => setBasiItem(5, value)}
						/>
					</td>
				</tr>
			</table>

			<h3 className="mr-2">PV – Rozšířený rozsah pojištění (+ „vada výrobku“) </h3>
			<div className="d-flex mt-3">
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="expanded_insurence" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="expanded_insurence" option={false} title="NE" />
			</div>

			<table className="table-designed mt-5 mb-5 font-weight-bold">
				<tr>
					<td>Limit pojistného plnění pro pojištění profesní odpovědnosti v Kč</td>
					<td>Újma způsobená vadou výrobku - roční pojistné za rozšíření v Kč</td>
					<td>Roční pojistné celkem v Kč</td>
				</tr>
				<tr>
					<td>2 000 000</td>
					<td>692</td>
					<td>2 672</td>
				</tr>
				<tr>
					<td>3 000 000</td>
					<td>996</td>
					<td>3 864</td>
				</tr>
				<tr>
					<td>4 000 000</td>
					<td>1 340</td>
					<td>5 200</td>
				</tr>
				<tr>
					<td>5 000 000</td>
					<td>1 608</td>
					<td>6 240</td>
				</tr>
				<tr>
					<td>7 000 000</td>
					<td>2 168</td>
					<td>8 416</td>
				</tr>
				<tr>
					<td>10 000 000</td>
					<td>2 992</td>
					<td>11 612</td>
				</tr>
			</table>

			<h3>Připojištění</h3>
			<strong>ODPOVĚDNOST ZA ŠKODU ZPŮSOBENOU NA MOVITÉ VĚCI PŘEVZATÉ/UŽÍVANÉ,</strong> kterou pojištěný převzal za účelem objednané činnosti nebo kterou oprávněně užívá v souvislosti s provozováním pojištěné činnosti (s výjimkou škod na užívaném motorovém vozidle).
			<div className="d-flex mt-3">
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="adding_insurance" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="adding_insurance" option={false} title="NE" />
			</div>
			<div>Sublimit: <strong>500 000 Kč.</strong></div>
			<div><strong>Roční pojistné: 740 Kč.</strong></div>

			<hr />

			<h3>ZVÝŠENÍ SUBLIMITU NEMAJETKOVÁ ÚJMA (OCHRANA OSOBNOSTI)</h3>
			<div>Výše sublimitu automaticky zahrnutého v rámci základního pojištění je uvedena v bodě B výše.</div>
			<div><strong>Zvýšení sublimitu</strong> pro pojištění povinnosti k peněžité náhradě nemajetkové újmy způsobené neoprávněným zásahem do práva na ochranu osobnosti (max. do celkové výše 5 000 000 Kč, nesmí však současně přesahovat limit pojistného plnění sjednaný pro základní pojištění).</div>
			<div className="d-flex mt-3">
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="sublimit_increase" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="sublimit_increase" option={false} title="NE" />
			</div>


			{form.getField("sublimit_increase").value && sublimit &&
				<>
					<div>Vámi vybraný základní rozsah pojištění již automaticky obsahuje sublimit náhrady nemajetkové újmy ve výši: {sublimit} Kč</div>
					<div>Tento sublimit můžete dle Vašeho uvážení navýšit až o {(5000000 - Number(sublimit.replace(/\s/g, ""))).toLocaleString('cs-CZ')} Kč (částka musí být dělitelná 50.000 Kč).</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								form={form}
								inputProps={{ type: "number" }}
								field="total_selected_sublimit"
							/>
						</div>
					</div>
					<div>Max. celková výše sublimitu je 5.000.000 Kč.</div>
					<div>Roční pojistné bude navýšeno o 5؉ z rozdílu mezi zvolenou částkou 0 Kč</div>
				</>
			}
			<hr />

			<h2>D.	Účinnost změny</h2>

			<p>Změna rozsahu pojištění je účinná od prvního dne kalendářního měsíce následujícího po uplynutí kalendářního měsíce, ve kterém bude změnový tiskopis doručen České stomatologické komoře.</p>

			<p>Česká stomatologická komora doporučuje zájemci o pojištění odpovědnosti poskytovatele zdravotních služeb přistoupení k pojištění odpovědnosti za újmu na základě RPS č. 8601750275 s rozsahem pojištění vyplývajícím z tohoto změnového tiskopisu.</p>

			<p>Sjednání pojištění ve Vašem případě neznamená uzavření pojistné smlouvy, ale tzv. přistoupení do již existujícího pojistného vztahu (k Rámcové pojistné smlouvě). Přistoupením do pojištění se nestáváte smluvní stranou pojistné smlouvy (pojistníkem), proto nemáte práva a povinnosti, které se vztahují pouze k pojistníkovi, zejména nemůžete (rámcovou) pojistnou smlouvu měnit či ukončit. Vlastním právním jednáním můžete sjednané pojištění změnit nebo ukončit pouze prostřednictvím pojistníka, a to za podmínek a v souladu s (rámcovou) pojistnou smlouvou. Pro případ, že pojistník neuhradí pojišťovně včas pojistné, jste oprávněn/a pojištění ukončit, sjednat si individuální pojištění a domáhat se na pojistníkovi případné újmy.</p>

			<p>Doručením tohoto změnového tiskopisu České stomatologické komoře prohlašujete, že jste byl/a jsem Českou stomatologickou komorou jako pojistníkem seznámen/a s předpoklady pro možnost být pojištěným na základě rámcové smlouvy stanovenými pojistníkem a zavazujete se dodržovat podmínky, které z těchto předpokladů pro vás jako pro pojištěného vyplývají, a dále prohlašujete, že jste byl/a informována, že předpoklady pro možnost být pojištěným na základě rámcové smlouvy stanovené pojistníkem jsou zveřejněny na webových stránkách www.dent.cz.</p>

			<hr />


			<div className="row">
				<div className="col-md-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={form}
						field="date"
					/>
				</div>
				<div className="col-md-9">
					<FormInput
						form={form}
						field="signature"
					/>
				</div>
			</div>

			<hr />

			<h2>E.	Pojistné a způsob placení pojistného</h2>

			<table className="table-designed mt-5 mb-5 font-weight-bold">
				<tr>
					<td>Pojistné bude placeno převodním příkazem na účet č.</td>
					<td>3655040277/0100</td>
				</tr>
				<tr>
					<td>Variabilní symbol - Identifikační kód člena (vyplní zubní lékař):</td>
					<td></td>
				</tr>
				<tr>
					<td>Konstantní symbol:</td>
					<td>3558</td>
				</tr>
				<tr>
					<td>Celkové roční pojistné v Kč:</td>
					<td>{form.getField("total").value} Kč</td>
				</tr>
				<tr>
					<td>Potvrzení o pojištění číslo (vyplní Kooperativa):</td>
					<td>
						<FormInput
							form={form}
							field="certificate_of_insurance"
						/>
					</td>
				</tr>
			</table>
			<h3>Vyplněný a podepsaný změnový tiskopis zašlete na e-mail: profesnipojisteni@dent.cz, datovou schránkou ID yqaaih2, poštou na adresu Česká stomatologická komora, Slavojova 22, Praha 2, 128 00, nebo jej na uvedenou adresu doručte osobně.</h3>

			<hr />
			<FormFiles
				forms={webRequestChangeForm.formsAttachment}
				label="Přílohy k změnovému tiskopisu"
				required={true}
			/>

			<p style={{ border: "1px solid" }} className="p-3">
				Při převodu na nového vlastníka nutno dodat kopii Rozhodnutí o udělení oprávnění k poskytování zdravotních služeb, které již nabylo právní moci (event. Rozhodnutí o registraci nestátního zdravotnického zařízení). Právnická osoba přikládá též kopii výpisu z obchodního rejstříku.
			</p>

			<hr />

			<h2>F.	Potvrzení přijetí změnového tiskopisu</h2>

			<div className="row mb-5">
				<div style={{ border: "solid 1px", paddingBottom: 120 }} className="col-6 text-center">
					<div>
						Datum doručení tohoto tiskopisu pojistníkovi:
					</div>
					<div>
						Podpis/razítko zástupce ĆSK:
					</div>

				</div>
				<div style={{ border: "solid 1px", paddingBottom: 120 }} className="col-6 text-center">
					<div>
						Datum převzetí tohoto tiskopisu pojistitelem:
					</div>
					<div>
						Podpis/razítko zástupce Kooperativy:
					</div>

				</div>
			</div>

			{!props.id &&
				<div className="text-right">
					{/* <div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br /> */}
					<Button theme="primary" onClick={webRequestChangeForm.confirm} spinner={webRequestChangeForm.commonModel.justSending()}>Odeslat</Button>
				</div>
			}
			<div className="insurance-application-total text-center font-weight-bold">Vypočítané celkové roční pojistné: {webRequestChangeForm.form.getField("total").value} Kč</div>
		</WebRequest>
	);
}
export default state.bindContainers(
	WebRequestChangeFormPage,
	c => c.webRequestChangeForm
);