/**
 *  Informace o volbě prezidenta
 */
import React from "react";
import * as state from "../../../lib/state";
import HtmlContent from "../../../components/controls/HtmlContent";
import Page from "../../../components/templates/Page";

function OskElectionPage() {
	const { contents, oskElections } = state.useStateContext();
	return (
		<Page title={contents.getRes("informace-nadpis")} description={contents.getRes("informace-nadpis")} authorized>
			<HtmlContent content={oskElections.getData()} />
		</Page>
	);
}

export default state.bindContainers(
	OskElectionPage,
	context => context.oskElections
);