import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as formatting from "../../../lib/formatting";
import * as apiEducationalActions from "../../shared/educational-actions/api-educational-actions";

import JoinedList from "../../../components/controls/JoinedList";


interface EducationalActionsTimeLineItemProps {
	action: apiEducationalActions.WebEducationalAction;
	stages?: boolean;
}

export default function EducationalActionsTimeLineItem(props: EducationalActionsTimeLineItemProps) {
	return (
		<div className="box-new">
			<Link to={(props.stages ? "/vzdelavani/staz/" : "/vzdelavani/akce/") + props.action.id} className="box-new__inner educational-actions-page__time-line-content">
				<div className="mb-1 pb-1 time-line__bb secondary-title">
					{props.action.name}
				</div>
				<p className="box-new__date d-md-none">
					<JoinedList
						items={[
							props.action.date_from,
							props.action.date_to
						]}
						delimiter={<> &ndash; </>}
						itemTemplate={formatting.formatDate}
					/>
				</p>
				<p className="mb-3">
					<div>
						<strong>
							{props.action.place_of_activity}
						</strong>
					</div>
					<div className="u-text-small u-text-capitalize">
						<JoinedList
							items={[
								props.action.subject.name,
								props.action.lecturers
							]}
							delimiter=", "
							itemTemplate={item => item}
						/>
					</div>
				</p>
				<p dangerouslySetInnerHTML={{ __html: props.action.description }}>
				</p>
			</Link>
		</div>
	);
}