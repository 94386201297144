/**
 * Zapomenutý email
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";


function ResetPassword() {
	const { resetPassword } = state.useStateContext();

	return (
		<Page title="Reset hesla" description="Reset hesla">
			<h1>Reset hesla</h1>
			<div className="row">
				<div className="col-6">
					<FormsInput
						form={resetPassword.form} field="password" inputProps={{ type: "password" }}
					/>
				</div>
				<div className="col-6">
					<FormsInput
						form={resetPassword.form} field="repeat_password" inputProps={{ type: "password" }}
					/>
				</div>
			</div>
			<div className="text-right">
				<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
				<Button theme="primary" onClick={resetPassword.send}>Odeslat</Button>
			</div>
		</Page>
	);
}

export default state.bindContainers(
	ResetPassword,
	c => c.resetPassword
);