/**
 *  Formulář žádosti o zařazení do vstupního vzdělávacího programu
 */
import React, { useEffect, useRef } from "react";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequestInsuranceApplication from "./web-request-insurance-application";
import WebRequest from "../../../components/templates/WebRequest";
import FormInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";
import FormFileUpload from "../../system/file/FormFileUpload";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";
import Checkbox from "../../../components/controls/Checkbox";
import IconSvg from "../../../components/controls/IconSvg";

interface Props {
	id?: string;
}

function WebRequestInsuranceApplicationPage(props: Props) {
	const title = "PŘIHLÁŠKA K POJIŠTĚNÍ";
	const { webRequestInsuranceApplication, api } = state.useStateContext();
	const form = webRequestInsuranceApplication.form;
	const sublimit = form.getField("scope_of_insurances").value.find(x => x.checked)?.sublimit;

	async function setItem(index: number, value: boolean) {
		await form.setField("scope_of_insurances", webRequestInsuranceApplication.defaultValue());
		const items = form.getField("scope_of_insurances").value;
		items[index].checked = value;
		await form.setField("scope_of_insurances", items);
	}

	interface FormFilesProps {
		forms: forms.FormCollection<webRequestInsuranceApplication.FilesFormFields>;
		label: React.ReactNode;
		hint?: React.ReactNode;
		required?: boolean;
	}

	function FormFiles(props: FormFilesProps) {
		return (
			<div className="web-request-osk-csk-entry-page__files mb-4">
				<div className="mb-1">
					<div>
						{props.label}
						{props.required &&
							<span className="form-item__required ml-1">*</span>
						}
					</div>
					<div>
						{props.hint}
					</div>
				</div>
				<Button className="mb-3" onClick={props.forms.add}>
					<IconSvg name="plus" className="mr-3" />

					{props.forms.get().length === 0 &&
						<>Připojit soubor</>
					}
					{props.forms.get().length > 0 &&
						<>Připojit další soubor</>
					}
				</Button >
				{props.forms.get().map((form, index) =>
					<>
						<FormFileUpload
							key={index}
							form={form}
							field="file"
						/>
						<div className="col-4 col-lg-2 pl-0">
							<Button className="d-block w-100 mt-3 mb-3" onClick={() => webRequestInsuranceApplication.formsAttachment.remove(form)}>Odebrat</Button>
						</div>
					</>
				)
				}
			</div>
		);
	}


	return (
		<WebRequest
			title={title}
			description=""
			authorized
			model={webRequestInsuranceApplication.commonModel}
			id={props.id}
		>
			<>podle rámcové pojistné smlouvy č. <strong>8601750275 </strong>
				uzavřené mezi Českou stomatologickou komorou a Kooperativou pojišťovnou, a.s.,
				Vienna Insurance Group</>

			<h2>A.	Údaje o zájemci o pojištění</h2>

			<div className="row">
				<div className="col-sm-12">
					<FormInput
						form={form}
						field="insurance_name"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="provider_address"
					/>
				</div>
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="health_services_address"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<FormInput
						form={form}
						field="representation"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="registration_number"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="identification_number"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="account_number"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="phone"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="mobile"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="email"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<FormInput
						form={form}
						field="mailing_address"
					/>
				</div>
			</div>

			<hr />
			<p>
				Člen České stomatologické komory (dále také „ČSK“) - fyzická osoba, která je společníkem a odborným zástupcem zájemce o pojištění – vyplnit pouze u právnických osob:
			</p>

			<h3>Společník (člen ČSK)</h3>
			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="associate_name"
					/>
				</div>
				<div className="col-sm-6">
					<FormInput
						form={form}
						inputProps={{ type: "date" }}
						field="associate_birthdate"
					/>
				</div>
			</div>

			<h3>Odborný zástupce (člen ČSK)</h3>
			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="representative_name"
					/>
				</div>
				<div className="col-sm-6">
					<FormInput
						form={form}
						inputProps={{ type: "date" }}
						field="representative_birthdate"
					/>
				</div>
			</div>
			<div className="mr-2">Uveďte, zda v době podání této přihlášky již jste pojištěn/a pro případ své profesní odpovědnosti</div>

			<div className="d-flex mt-3">

				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="insured" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="insured" option={false} title="NE" />
			</div>

			<div className="mt-0">Pokud ANO, uveďte u kterého pojistitele: </div>
			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="insurer"
					/>
				</div>
			</div>
			<div>Pokud u Kooperativy pojišťovny, a.s., Vienna Insurance Group (dále také „Kooperativa“): </div>
			<div className="d-flex mt-3">
				<FormsCheckBox
					checkBoxProps={{ className: "remove-panel-radio no-background" }}
					form={form}
					field="declaration"
				/>
				<span>Prohlašuji, že mám uzavřeno u Kooperativy jiné pojištění odpovědnosti poskytovatele zdravotních služeb v oboru zubní lékařství a žádám tímto o ukončení tohoto dřívějšího pojištění ke dni předcházejícímu dni počátku pojištění, které vznikne na základě této přihlášky:</span>
			</div>
			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={form}
						field="insurance_policy_number"
					/>
				</div>
			</div>

			<h2>B.	Rozsah pojištění požadovaný zájemcem</h2>
			<div>Podrobné informace o pojištění získáte na stránkách <a href="www.dent.cz">www.dent.cz</a></div>
			<div className="mt-3 d-flex">
				<h3 className="mr-2 d-inline">P – Základní rozsah pojištění </h3>
				<span className="mr-2">ANO</span>
				<FormsCheckBox
					checkBoxProps={{ className: "remove-panel-radio no-background" }}
					form={form}
					field="basic_insurance_coverage"
				/>
			</div>

			<p>Základní pojištění obsahuje:</p>
			<p>
				<strong>PROFESNÍ ODPOVĚDNOST</strong> 	– 	za újmu vzniklou jinému v souvislosti s poskytováním zdravotních služeb, včetně nemajetkové újmy vyplývající z újmy na zdraví.
				<div>Kryje též: odpovědnost za újmu způsobenou zavlečením nebo rozšířením <strong>nakažlivé choroby lidí</strong> a odpovědnost za újmu způsobenou přenosem viru <strong>HIV</strong> (viz rámcová pojistná smlouva).</div>
			</p>
			<p>
				<strong>OBECNOU ODPOVĚDNOST</strong>	– 	včetně pojištění odpovědnosti za škodu vyplývající z vlastnictví, držby nebo jiného oprávnění užívání nemovitosti sloužící provozu zdravotnického zařízení. Limit pojistného plnění <strong>500 000 Kč</strong> v rámci limitu plnění pro pojištění profesní odpovědnosti.
			</p>
			<p>
				<strong>NEMAJETKOVOU ÚJMU</strong>	–	vztahuje se na povinnost pojištěného poskytnout peněžitou <strong>náhradu nemajetkové újmy</strong> nevyplývající z újmy na zdraví, pokud byla přiznána pravomocným rozhodnutím soudu na základě neoprávněného zásahu pojištěného do přirozených práv člověka ve smyslu § 2956 občanského zákoníku, k němuž došlo v souvislosti s činností nebo vztahem pojištěného. Zahrnuta se sublimitem uvedeným v následující tabulce:
			</p>

			<table className="table-designed mt-5 mb-5">
				<tr>
					<td className="font-weight-bold">Limit pojistného plnění pro pojištění profesní odpovědnosti v Kč </td>
					<td>Sublimit pro pojištění náhrady nemajetkové újmy (v Kč)</td>
					<td className="font-weight-bold">Roční pojistné v Kč </td>
					<td>Označte jednu z variant</td>
				</tr>
				<tr>
					<td className="font-weight-bold">2 000 000</td>
					<td>600 000</td>
					<td className="font-weight-bold">1 980</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[0].checked}
							onCheckedChanged={value => setItem(0, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">3 000 000</td>
					<td>800 000</td>
					<td className="font-weight-bold">2 868</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[1].checked}
							onCheckedChanged={value => setItem(1, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">4 000 000</td>
					<td>1 000 000</td>
					<td className="font-weight-bold">3 860</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[2].checked}
							onCheckedChanged={value => setItem(2, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">5 000 000</td>
					<td>1 200 000</td>
					<td className="font-weight-bold">4 632</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[3].checked}
							onCheckedChanged={value => setItem(3, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">7 000 000</td>
					<td>1 600 000</td>
					<td className="font-weight-bold">6 248</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[4].checked}
							onCheckedChanged={value => setItem(4, value)}
						/>
					</td>
				</tr>
				<tr>
					<td className="font-weight-bold">10 000 000</td>
					<td>2 000 000</td>
					<td className="font-weight-bold">8 620</td>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("scope_of_insurances").value[5].checked}
							onCheckedChanged={value => setItem(5, value)}
						/>
					</td>
				</tr>
			</table>

			<h3 className="mr-2">PV – Rozšířený rozsah pojištění (+ „vada výrobku“) </h3>
			<div className="d-flex mt-3">
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="expanded_insurence" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="expanded_insurence" option={false} title="NE" />
			</div>

			<div>Základní pojištění <strong>(P)</strong> se při zaškrtnuté variantě ANO rozšiřuje o:</div>
			<div><strong>ODPOVĚDNOST ZA ÚJMU ZPŮSOBENOU JINÉMU VADOU VÝROBKU</strong> (za následnou újmu způsobenou vadou výrobku, ne odpovědnost za vadu výrobku - reklamační nároky nejsou předmětem pojištění).
				<div>Limit pojistného plnění shodný s limitem pojistného plnění zvoleným v základním pojištění P, v jeho rámci (limity se nesčítají)</div>
			</div>

			<table className="table-designed mt-5 mb-5 font-weight-bold">
				<tr>
					<td>Limit pojistného plnění pro pojištění profesní odpovědnosti v Kč</td>
					<td>Újma způsobená vadou výrobku - roční pojistné za rozšíření v Kč</td>
					<td>Roční pojistné celkem v Kč</td>
				</tr>
				<tr>
					<td>2 000 000</td>
					<td>692</td>
					<td>2 672</td>
				</tr>
				<tr>
					<td>3 000 000</td>
					<td>996</td>
					<td>3 864</td>
				</tr>
				<tr>
					<td>4 000 000</td>
					<td>1 340</td>
					<td>5 200</td>
				</tr>
				<tr>
					<td>5 000 000</td>
					<td>1 608</td>
					<td>6 240</td>
				</tr>
				<tr>
					<td>7 000 000</td>
					<td>2 168</td>
					<td>8 416</td>
				</tr>
				<tr>
					<td>10 000 000</td>
					<td>2 992</td>
					<td>11 612</td>
				</tr>
			</table>

			<h3>Připojištění</h3>
			<h3>ODPOVĚDNOST ZA ŠKODU ZPŮSOBENOU NA MOVITÉ VĚCI PŘEVZATÉ/UŽÍVANÉ,</h3>kterou pojištěný převzal za účelem objednané činnosti nebo kterou oprávněně užívá v souvislosti s provozováním pojištěné činnosti
			(s výjimkou škod na užívaném motorovém vozidle).
			<div className="d-flex mt-3">
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="adding_insurance" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="adding_insurance" option={false} title="NE" />
			</div>
			<div>Sublimit: <strong>500 000 Kč.</strong></div>
			<div><strong>Roční pojistné: 740 Kč.</strong></div>

			<hr />

			<h3>ZVÝŠENÍ SUBLIMITU NEMAJETKOVÁ ÚJMA (OCHRANA OSOBNOSTI)</h3>
			<div>Výše sublimitu automaticky zahrnutého v rámci základního pojištění je uvedena v bodě B výše.</div>
			<div><strong>Zvýšení sublimitu</strong> pro pojištění povinnosti k peněžité náhradě nemajetkové újmy způsobené neoprávněným zásahem do práva na ochranu osobnosti (max. do celkové výše 5 000 000 Kč, nesmí však současně přesahovat limit pojistného plnění sjednaný pro základní pojištění).</div>
			<div className="d-flex mt-3">
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="sublimit_increase" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="sublimit_increase" option={false} title="NE" />
			</div>


			{form.getField("sublimit_increase").value && sublimit &&
				<>
					<div>Vámi vybraný základní rozsah pojištění již automaticky obsahuje sublimit náhrady nemajetkové újmy ve výši: {sublimit} Kč</div>
					<div>Tento sublimit můžete dle Vašeho uvážení navýšit až o {(5000000 - Number(sublimit.replace(/\s/g, ""))).toLocaleString('cs-CZ')} Kč (částka musí být dělitelná 50.000 Kč).</div>
					<div className="row">
						<div className="col-md-6">
							<FormInput
								form={form}
								inputProps={{ type: "number" }}
								field="total_selected_sublimit"
							/>
						</div>
					</div>
					<div>Max. celková výše sublimitu je 5.000.000 Kč.</div>
					<div>Roční pojistné bude navýšeno o 5؉ z rozdílu mezi zvolenou částkou 0 Kč</div>
				</>
			}





			<hr />

			<h2>C.	Nástupnická právnická osoba - pouze právnické osoby (např. s.r.o.), které poskytující zdravotní služby po „transformaci“ z fyzické osoby</h2>
			<div>Žádáme o zahrnutí udržovacího pojištění dosavadního poskytovatele zdravotních služeb - fyzické osoby (našeho předchůdce) do pojištění a současně prohlašujeme, že:</div>
			<div>a) pokračujeme v poskytování zdravotních služeb v rozsahu a za podmínek, v jakém byly poskytovány níže uvedeným dosavadním poskytovatelem,</div>
			<div>b) dosavadní poskytovatel je naším společníkem a současně odborným zástupcem,</div>
			<div>c) dosavadní poskytovatel nadále nepokračuje v poskytování zdravotních služeb jako fyzická osoba:</div>

			<div className="d-flex mt-3">
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="successor_legal_entity" option={true} title="ANO" />
				<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="successor_legal_entity" option={false} title="NE" />
			</div>

			<h3>Údaje o dosavadním poskytovateli (fyzické osobě)</h3>

			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="provider_name"
					/>
				</div>
				<div className="col-sm-6">
					<FormInput
						form={form}
						inputProps={{ type: "date" }}
						field="provider_end_date"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="provider_residential_address"
					/>
				</div>
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="provider_health_services_address"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="provider_registration_number"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="provider_identification_number"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="provider_insurance_certificate_number"
					/>
				</div>
			</div>

			<hr />

			<h2>D.	Počátek pojištění</h2>

			<div>Na základě přihlášky předané pojistníkem pojistiteli vznikne pojištění:</div>
			<div>a) k počátku kalendářního měsíce následujícího po měsíci, ve kterém byla přihláška doručena zájemcem o pojištění ČSK, pokud byla přihláška ze strany ČSK předána Kooperativě nejpozději do 5. dne kalendářního měsíce následujícího po kalendářním měsíci, v němž byla přihláška doručena ČSK, </div>
			<div>b) k počátku kalendářního měsíce následujícího po kalendářním měsíci, ve kterém byla přihláška ze strany ČSK předána Kooperativě pojistiteli v jiných případech, než které jsou uvedeny v písm.
				<div>a).Pokud zájemci o pojištění ke dni, v němž by měl nastat počátek pojištění dle písm. a) či b), dosud nevzniklo oprávnění k poskytování zdravotních služeb v oboru zubní lékařství, nabude pojištění účinnosti nejdříve dnem vzniku takového oprávnění.</div>
			</div>

			<hr />

			<h2>Prohlášení zájemce o pojištění: </h2>

			<div>-	jsem nebo ke dni vzniku pojištění budu oprávněn/a na území ČR poskytovat zdravotní služby na základě obecně závazných právních předpisů v oboru zubní lékařství,</div>
			<div>-	chci být pojištěn/a na základě výše uvedené rámcové pojistné smlouvy v rozsahu, který jsem výše uvedl/a,</div>
			<div>-	byl/a jsem Českou stomatologickou komorou jako pojistníkem seznámen/a s rámcovou pojistnou smlouvou, včetně Všeobecných pojistných podmínek pro pojištění majetku a odpovědnosti P-100/14 a Zvláštních pojistných podmínek pro pojištění odpovědnosti poskytovatele zdravotních služeb za újmu P-510/14, které tvoří její nedílnou součást, a souhlasím s nimi, <strong>a dále jsem byl/a informován/a, že veškerá dokumentace
				vč. dokumentu IPID je zveřejněna k nahlédnutí na webových stránkách <a href="www.dent.cz">www.dent.cz</a>,</strong>
			</div>
			<div>-	byl/a jsem Českou stomatologickou komorou jako pojistníkem seznámen/a s předpoklady pro možnost být pojištěným na základě rámcové smlouvy stanovenými pojistníkem a zavazuji se dodržovat podmínky, které z těchto předpokladů pro mě jako pro pojištěného vyplývají, a dále jsem byl/a informována, že předpoklady pro možnost být pojištěným na základě rámcové smlouvy stanovené pojistníkem jsou zveřejněny na webových stránkách <a href="www.dent.cz">www.dent.cz</a>,</div>
			<div>-	údaje uvedené na této přihlášce k pojištění jsou úplné a pravdivé a zavazuji se oznámit Kooperativě každou změnu těchto údajů, a to prostřednictvím ČSK jako pojistníka,</div>
			<div>-	byl/a jsem informován/a o rozsahu a účelu zpracování svých osobních údajů a o právech, která mi přísluší v souvislosti se zpracováním mých osobních údajů, a beru na vědomí, že informace a novinky z oblasti ochrany osobních údajů naleznu na webových stránkách www.koop.cz v sekci „O pojišťovně Kooperativa“,</div>
			<div>-	souhlasím, abych v záležitostech týkajících se pojištění na základě výše uvedené rámcové smlouvy byl/a kontaktován/a také cestou prostředků elektronické komunikace,</div>
			<div>-	souhlasím, aby Kooperativa poskytovala ČSK jako pojistníkovi veškeré informace související se šetřením škodných událostí podle výše uvedené rámcové pojistné smlouvy, zejména za účelem zjišťováním příčin vzniku škod a jejich předcházení, zjišťování případů postupu non lege artis apod.</div>

			<p>ČSK doporučuje zájemci o pojištění odpovědnosti poskytovatele zdravotních služeb přistoupení k pojištění odpovědnosti za újmu na základě RPS č. 8601750275 s rozsahem pojištění vyplývajícím z této přihlášky.</p>
			<p>Pojistník je za nabízení pojištění finančně odměňován pojišťovnou.</p>
			<p>Sjednání pojištění ve Vašem případě neznamená uzavření pojistné smlouvy, ale tzv. přistoupení do již existujícího pojistného vztahu (k Rámcové pojistné smlouvě). Přistoupením do pojištění se nestáváte smluvní stranou pojistné smlouvy (pojistníkem), proto nemáte práva a povinnosti, které se vztahují pouze k pojistníkovi, zejména nemůžete (rámcovou) pojistnou smlouvu měnit či ukončit. Vlastním právním jednáním můžete sjednané pojištění změnit nebo ukončit pouze prostřednictvím pojistníka, a to za podmínek a v souladu s (rámcovou) pojistnou smlouvou. Pro případ, že pojistník neuhradí pojišťovně včas pojistné, jste oprávněn/a pojištění ukončit, sjednat si individuální pojištění a domáhat se na pojistníkovi případné újmy.</p>

			<div className="row">
				<div className="col-md-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={form}
						field="date"
					/>
				</div>
				<div className="col-md-9">
					<FormInput
						form={form}
						field="signature"
					/>
				</div>
			</div>

			<h3>Přílohy k této přihlášce</h3>

			<p className="p-3" style={{ border: "solid 1px" }}>Kopie Rozhodnutí o udělení oprávnění k poskytování zdravotních služeb, které již nabylo právní moci (event. Rozhodnutí o registraci nestátního zdravotnického zařízení). Právnická osoba přikládá též kopii výpisu z obchodního rejstříku. </p>

			<FormFiles
				forms={webRequestInsuranceApplication.formsAttachment}
				label="Přílohy"
				required={true}
			/>

			{/* <h4>Vyplněnou a podepsanou přihlášku spolu s kopiemi příp. požadovaných dokladů zašlete na e-mail: profesnipojisteni@dent.cz, datovou schránkou ID yqaaih2, poštou na adresu Česká stomatologická komora, Slavojova 22, Praha 2, 128 00, nebo ji na uvedenou adresu doručte osobně.</h4> */}

			<hr />

			<h2>E.	Pojistné a způsob placení pojistného</h2>

			<table className="table-designed mt-5 mb-5 font-weight-bold">
				<tr>
					<td>Pojistné bude placeno převodním příkazem na účet č.</td>
					<td>3655040277/0100</td>
				</tr>
				<tr>
					<td>Variabilní symbol - Identifikační kód člena (vyplní zubní lékař):</td>
					<td></td>
				</tr>
				<tr>
					<td>Konstantní symbol:</td>
					<td>3558</td>
				</tr>
				<tr>
					<td>Celkové roční pojistné v Kč:</td>
					<td>{form.getField("total").value} Kč</td>
				</tr>
				<tr>
					<td>Potvrzení o pojištění číslo (vyplní Kooperativa):</td>
					<td>
						<FormInput
							form={form}
							field="certificate_of_insurance"
						/>
					</td>
				</tr>
			</table>

			<hr />

			<h2>F.	Potvrzení přijetí přihlášky</h2>

			<div className="row mb-5">
				<div style={{ border: "solid 1px", paddingBottom: 120 }} className="col-6 text-center">
					<div>
						Datum doručení této přihlášky pojistníkovi:
					</div>
					<div>
						Podpis/razítko zástupce ČSK:
					</div>

				</div>
				<div style={{ border: "solid 1px", paddingBottom: 120 }} className="col-6 text-center">
					<div>
						Datum převzetí této přihlášky pojistitelem:
					</div>
					<div>
						Podpis/razítko zástupce Kooperativy:
					</div>

				</div>
			</div>

			{!props.id &&
				<div className="text-right">
					{/* <div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br /> */}
					<Button theme="primary" onClick={webRequestInsuranceApplication.confirm} spinner={webRequestInsuranceApplication.commonModel.justSending()}>Odeslat</Button>
				</div>
			}
			<div className="insurance-application-total text-center font-weight-bold">Vypočítané celkové roční pojistné: {webRequestInsuranceApplication.form.getField("total").value} Kč</div>
		</WebRequest>
	);
}
export default state.bindContainers(
	WebRequestInsuranceApplicationPage,
	c => c.webRequestInsuranceApplication
);