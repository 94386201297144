import React, { useState, useEffect } from "react";

export default function usePopup() {
	const [isOpen, setOpen] = useState(false);

	function onGlobalClick() {
		setOpen(false);
	}

	function toggle(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		// without setTimeout causes onGlobalClick() calling before setOpen()
		setTimeout(() => setOpen(!isOpen), 0);
	}

	function close(e: React.MouseEvent<HTMLElement, MouseEvent>) {
		e.stopPropagation();
		setOpen(false);
	}

	useEffect(() => {
		window.addEventListener("click", onGlobalClick);
		return () => {
			window.removeEventListener("click", onGlobalClick);
		};
	});

	return { isOpen, toggle, close } as const;
}