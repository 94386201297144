/**
 * Detail položky dynamického seznamu
 */

import * as dynamicListItem from "../../shared/dynamic-lists/api-dynamic-lists";
import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";

/**
 * Definice stavu
 */
interface State {
	item?: dynamicListItem.Item;
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	/**
	*  Seznam zubařů
	*/
	public stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoading: false
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Načte data modulu
	 */
	loadData = async (id: api.Id) => {
		await this.stateContainer.merge(_ => ({
			item: undefined
		}));
		const newsItem = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiDynamicLists.standard.load(id),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(_ => ({
			item: newsItem
		}));
	}

	getJustLoading = () => this.stateContainer.get().justLoading;
	getItem = () => this.stateContainer.get().item;
}