/**
 * Žádost o zařazení do vstupního vzdělávacího programu
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

export interface FormFields {

	// Druh vzdělávacího programu
	program_type: "vppzl" | "vpchir" | "vpparo" | "vppedo";
	entry_date_which: "delivery-date" | "edu-action-date";
	entry_date: string;

	// Konzultant
	consultant_ikc: string;
	consultant_title_before: string;
	consultant_first_name: string;
	consultant_last_name: string;
	consultant_full_name: string;
	consultant_id: string;
	attachment?: api.CodeBookItem;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public commonModel: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.commonModel = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				program_type: {
					title: "Druh vzdělávacího programu",
					defaultValue: "vppzl"
				},
				attachment: {
					title: "",
					defaultValue: undefined,
					required: (form) => true,
					validate: (value, field, form) => form.getField("program_type").value !== "vppzl" && !value ? "Prosím, vyplňte přílohu" : ""
				},
				entry_date_which: {
					title: "Ke kterému dni provést zařazení",
					defaultValue: "delivery-date"
				},
				entry_date: {
					title: "Datum účasti na akci",
					defaultValue: "",
					required: (form) => true,
					validate: (value, field, form) => form.getField("entry_date_which").value == "edu-action-date" && value == "" ? "Prosím, vyplňte datum" : ""
				},
				consultant_ikc: {
					title: "IKČ konzultanta",
					defaultValue: "",
					onChange: async (value, field, form) => {
						if (value.length == 8) {
							const member = await context.apiMembers.findMemberByIKC(value);
							if (member) {
								await form.setFields({
									consultant_first_name: member.first_name,
									consultant_last_name: member.last_name,
									consultant_title_before: member.title_before,
									consultant_full_name: member.full_name,
									consultant_id: member.id
								});
							}
						}
					},
					required: (form) => true,
					validate: (value, field, form) => form.getField("program_type").value != "vppzl" && value == "" ? "Prosím, vyplňte IKČ" : ""
				},
				consultant_title_before: {
					title: "Titul před",
					defaultValue: ""
				},
				consultant_first_name: {
					title: "Jméno",
					defaultValue: "",
				},
				consultant_last_name: {
					title: "Příjmení",
					defaultValue: "",
				},
				consultant_full_name: {
					title: "Jméno a příjmení konzultanta",
					defaultValue: "",
					readOnly: true
				},
				consultant_id: {
					defaultValue: api.emptyGuid,
					title: ""
				}
			}
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => {
		return [
			...this.commonModel.getStateContainers(),
			...this.form.getStateContainers(),
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			let code = "zadost-o-zarazeni-do-vstupniho-vp";
			switch (this.form.getField("program_type").value) {
				case "vppzl":
					code = "zadost-o-zarazeni-do-vstupniho-vppzl";
					break;
				case "vpchir":
					code = "zadost-o-zarazeni-do-vstupniho-vpchir";
					break;
				case "vpparo":
					code = "zadost-o-zarazeni-do-vstupniho-vpparo";
					break;
				case "vppedo":
					code = "zadost-o-zarazeni-do-vstupniho-vppedo";
					break;
			}
			await this.commonModel.send(data, code as any);
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.commonModel.loadData(webRequestId);
		const webRequest = this.commonModel.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			await Promise.all([
				this.form.setFields(data),
			]);
		}
	}

	getProgramTypeName = () => {
		const program_type = this.form.getField("program_type").value;
		let value = "praktickém zubním lékařství";
		if (program_type == "vpchir") {
			value = "stomatochirurgii";
		}
		if (program_type == "vpparo") {
			value = "parodontologii";
		}
		else if (program_type == "vppedo") {
			value = "pedostomatologii";
		}
		return value;
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.commonModel.reset()
		]);
	}
}