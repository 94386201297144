/**
 *  Seznam žádostí ke schválení OSK
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiWebRequests from "../../shared/web-request/api-web-request";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiWebRequests.WebRequest>({
		context: context,
		title: "Seznam žádostí ke schválení OSK",
		quickFilterPlaceholder: "Vyhledejte žádost...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiRegionalChambers.requests,
		sortingFields: [],
		defaultSorting: { field: "date", order: lists.SortingOrder.Desc },
		grid: {
			minWidth: "1200px",
			columns: [
				{
					field: "number",
					type: "string",
					title: "Číslo žádosti",
					width: "180px"
				},
				{
					field: "request_state.name" as any,
					type: "string",
					title: "Stav",
					width: "110px"
				},
				{
					field: "request_type.name" as any,
					type: "string",
					title: "Typ",
					width: "21%"
				},
				{
					field: "member.full_name" as any,
					type: "string",
					title: "Žadatel",
				},
				{
					field: "date",
					type: "date",
					title: "Zadáno",
					width: "200px"
				},
				{
					field: "payment_date",
					type: "date",
					title: "Uhrazeno",
					width: "200px"
				},
				{
					field: "request_type.paid" as any,
					type: "boolean",
					title: "Poplatek",
					width: "110px"
				}
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiWebRequests.WebRequest>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.userLoggedIn())
			await this.list.loadWithResetPage();
	}
}