/**
 * Kongres PDD
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function EducationCongressPDDPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("kongres-pdd-nadpis")} description={contents.getRes("kongres-pdd-nadpis")}>
			<HtmlContent htmlKey="kongres-pdd-obsah" />
		</Page>
	);
}