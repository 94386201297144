/**
 * Úřední deska
 */
import React from "react";
import * as state from "../../../lib/state";
import HtmlContent from "../../../components/controls/HtmlContent";


import Page from "../../../components/templates/Page";

export default function AboutUseOfficialDeskPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("uredni-deska-nadpis")} description={contents.getRes("uredni-deska-nadpis")} >
			<HtmlContent htmlKey="uredni-deska-obsah" />
		</Page>
	);
}