import * as React from "react";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";

import FormItem, { FormItemOptions } from "./FormItem";
import Input, { InputProps } from "../Input";


export interface FormsInputProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof common.SubType<TFormFields, string>;
	className?: string;
	inputProps?: InputProps;
	formItemOptions?: FormItemOptions;
	hideNumeric?: boolean;
}

export default function FormsInput<TFormFields>(props: FormsInputProps<TFormFields>) {
	const field = props.form.getField(props.field);
	const fieldOptions = props.form.getFieldOptions(props.field);
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
			className={props.className + (props.hideNumeric ? " hide-numeric" : "")}
		>
			<Input
				{...props.inputProps}
				value={field.value as any}
				placeholder={fieldOptions.placeHolderText}
				disabled={field.readOnly}
				onChange={value => props.form.setField(props.field, value as any)}
			/>
		</FormItem>
	);
}