/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import List from "../../system/lists/List";

function MyRecordsPage() {
	const { myPaymentPrescriptions } = state.useStateContext();
	return (
		<Page
			title={myPaymentPrescriptions.list.getTitle()}
			description=""
			authorized
		>
			<h1>
				{myPaymentPrescriptions.list.getTitle()}
			</h1>
			<List simpleTable
				list={myPaymentPrescriptions.list}
				itemNode={(item, field) => field === "total_price_with_vat" ? formatting.formatCurrency(item[field]) : undefined}
			/>
		</Page>
	);
}

export default state.bindContainers(
	MyRecordsPage,
	context => context.myPaymentPrescriptions
);