/**
 * Model žádosti o prodloužení doby platnosti osvědčení o 1 rok
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as formating from "../../../lib/formatting";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";

export interface FormFields {
	declaration: boolean;
	submission_date: string;
	name: string;
	place: string;

	date_from: string;
	date_to: string;

	// files
	document_attachment?: apiFile.File;
	description_attachment?: apiFile.File;
	amount: number;
}

interface FormData extends FormFields { }

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		const current_date = formating.formatDate(new Date());

		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				declaration: {
					title: "Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti, jsou pravdivé a úplné.",
					defaultValue: false,
					validate: value => !value ? "Prohlášení je povinné pole" : "",
				},
				submission_date: {
					title: "Datum podání",
					readOnly: true,
					defaultValue: current_date
				},
				name: {
					title: "Název akce",
					defaultValue: ""
				},
				place: {
					title: "Místo",
					defaultValue: ""
				},
				date_from: {
					title: "Datum od",
					defaultValue: ""
				},
				date_to: {
					title: "Datum do",
					defaultValue: ""
				},
				document_attachment: {
					title: "Doklad prokazující absolvování vzdělávací akce.",
					defaultValue: undefined
				},
				description_attachment: {
					title: "Podrobný popis akce a časový harmonogram.",
					defaultValue: undefined
				},
				amount: {
					title: "",
					defaultValue: 0
				}
			}
		}, context);
	}

	loadAmount = async () => {
		const amount = await this.context.api.get<number>("/request_types/" + "zadost-o-zapocitani-kreditu" + "/price/", {});
		await this.form.setField("amount", amount);
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			await this.common.send(data, "zadost-o-zapocitani-kreditu");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	/**
 * Načte data do formuláře 
 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.common.loadData(webRequestId),
				this.loadAmount(),
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadAmount()
		]);
	}
}