/**
 * Seznam převodů na OSK
 */
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import List from "../../system/lists/List";

function OskTransferFeesPage() {
	const { oskTransferFees } = state.useStateContext();
	return (
		<Page
			title={oskTransferFees.list.getTitle()}
			description=""
			authorized
		>
			<h1>
				{oskTransferFees.list.getTitle()}
			</h1>
			<List
				list={oskTransferFees.list}
				itemNode={(item, field) => {
					switch (field) {
						case "total_price_with_vat": return formatting.formatCurrency(item[field]);
						case "code": return <Link to={"/osk/prevody-z-csk/" + item.id}>{item[field]}</Link>;
						case "date_of_transfer": return formatting.formatDateTime(item[field]);
						default: return undefined;
					}
				}}
			/>
		</Page>
	);
}

export default state.bindContainers(
	OskTransferFeesPage,
	context => context.oskTransferFees
);