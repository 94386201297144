/**
 * Domovská stránka
 */
import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as formatting from "../../../lib/formatting";
import HtmlContent from "../../../components/controls/HtmlContent";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";

function Tile(props: { number: number; text: string; link: string }) {
	;
	return (
		<Link to={props.link} className="about-us-page__tile">
			<div className="about-us-page__tile-number">{formatting.formatWholeNumber(props.number)}</div>
			<div className="about-us-page__tile-text">{props.text}</div>
		</Link>
	);
}

function Partner(props: { image: string; target: string; name: string }) {
	return (
		<a href={props.target} className="about-us-page__partner">
			<img src={props.image} />
			<div className="about-us-page__partner-text">{props.name}</div>
		</a>
	);
}

function AboutUsPage() {
	const { contents } = state.useStateContext();
	const { aboutUs } = state.useStateContext();
	const statistic = aboutUs.getStatistics();
	return (
		<Page title={contents.getRes("o-nas-nadpis")} description={contents.getRes("o-nas-nadpis")}>
			<h1><HtmlContent htmlKey="o-nas-nadpis" /></h1>
			<div className="text-center">
				<HtmlContent htmlKey="o-nas-obsah" />
				<div className="d-flex justify-content-center flex-wrap mb-5">
					<Tile number={statistic.count_of_regional_chamber} text="oblastních komor" link="kontakty/osk" />
					<Tile number={statistic.count_of_snem} text="členů sněmu" link="o-nas/clenove-snemu" />
					<Tile number={statistic.count_of_board_of_directors} text="členů představenstva" link="o-nas/predstavenstvo" />
					<Tile number={statistic.count_of_rk} text="členů revizní komise" link="o-nas/revizni-komise" />
					<Tile number={statistic.count_of_cr} text="členů čestné rady" link="o-nas/cestna-rada" />
					<Tile number={statistic.count_of_member} text="členů" link="zubni-lekari/abecedne" />
					<Tile number={statistic.count_of_visiting_foreigners} text="hostujících cizinců" link="zubni-lekari/seznam-hostujicich-osob" />
				</div>

				<h2>Jsme členy</h2>
				<div className="d-flex justify-content-center flex-wrap">
					<Partner image="/images/fdi-logo.png" target="https://www.fdiworlddental.org" name="World dental federation" />
					<Partner image="/images/ero-logo.png" target="https://www.erodental.org" name="European Regional Organisation" />
					<Partner image="/images/ced-logo.png" target="https://cedentists.eu" name="Council of European Dentists" />
					<Partner image="/images/adee-logo.png" target="http://www.adee.org" name="Association for Dental Education in Europe" />
					<Partner image="/images/cecdo-logo.png" target="http://www.cecdo.org" name="The Council of European Chief Dental Officers" />
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	AboutUsPage,
	c => c.aboutUs
);
