/**
 * Zubní lékaří - hostující osoby
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

/**
 * Stav modelu
 */
interface State {
	data: apiDynamicLists.Item[];
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			data: [],
			justLoading: false
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Indikace načítání seznamu jednání 
	 */
	justLoading = () => this.stateContainer.get().justLoading;

	getData = () => {
		return this.stateContainer.get().data;
	}

	/**
	 * Načte data
	 */
	loadData = async () => {
		const list = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiDynamicLists.standard.loadList({
				sort_fields: ["order_number"],
				filter: [api.qp("tagsname" as any, "=", ["hostujici-osoby"])],
				per_page: 999
			}),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(prevState => ({
			data: list.data
		}));
	}
}