/**
 * Modul stránky "Klikací povinnost"
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as forms from "../../../lib/forms";
import * as notification from "../../../lib/notification";
import * as apiWorkplace from "../../shared/workplace/api-workplace";
import * as apiCodeList from "../../system/code-list/api-code-list";

interface State {
	justLoadingInsuranceCompanies: boolean;
	loaded: boolean;
}

interface InsuranceCompany extends apiCodeList.CodeList {
	checked: boolean;
}


/**
 * Definice dat formuláře
 */
export interface FormFields {
	id?: string;
	workplace?: apiWorkplace.Workplace;
	workplaceName: string;
	workplaceAcceptsNewPatients: boolean;
	workplaceWeb: string;
	workplaceInsuranceCompanies: InsuranceCompany[];
	workplaceTypeCares: apiCodeList.CodeList[];
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		id: {
			defaultValue: undefined,
			title: ""
		},
		workplace: {
			title: "Pracoviště",
			defaultValue: undefined
		},
		workplaceName: {
			title: "",
			defaultValue: ""
		},
		workplaceAcceptsNewPatients: {
			title: "Přijímá nové pacienty",
			readOnly: (form) => form.getField("workplace").value != undefined && !form.getField("workplace").value!.editable,
			defaultValue: false,
			onChange: async (value, field, form) => {
				let workplace = form.getField("workplace").value;
				if (workplace) {
					workplace!.accepts_new_patients = value;
					form.setField("workplace", workplace as any);
				}
			}
		},
		workplaceWeb: {
			title: "Web:",
			readOnly: (form) => form.getField("workplace").value != undefined && !form.getField("workplace").value!.editable,
			defaultValue: "",
			onChange: async (value, field, form) => {
				let workplace = form.getField("workplace").value;
				if (workplace) {
					workplace!.contact!.web = value;
					form.setField("workplace", workplace as any);
				}
			}
		},
		workplaceInsuranceCompanies: {
			title: "Smlouvy se zdravotními pojišťovnami",
			readOnly: (form) => form.getField("workplace").value != undefined && !form.getField("workplace").value!.editable,
			defaultValue: [],
			onChange: async (value, field, form) => {
				let workplace = form.getField("workplace").value;
				if (workplace) {
					workplace!.insurance_companies = value;
					form.setField("workplace", workplace as any);
				}
			}
		},
		workplaceTypeCares: {
			title: "Zdravotní péče v oboru",
			readOnly: (form) => form.getField("workplace").value != undefined && !form.getField("workplace").value!.editable,
			defaultValue: [],
			onChange: async (value, field, form) => {
				let workplace = form.getField("workplace").value;
				if (workplace) {
					workplace!.type_cares = value;
					form.setField("workplace", workplace as any);
				}
			}
		}
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ justLoadingInsuranceCompanies: false, loaded: false }, context);
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.api.post("/member/workplaces/" + this.form.getField("id").value, this.form.getField("workplace").value)
			if (result) {
				notification.successMessage("Pracoviště bylo uloženo.")
			}
		} else {
			await this.context.standardDialogs.openInformationDialog("Nejdříve je třeba potvrdit všechny kontroly.");
		}
	}

	toggleInsuranceCompany = async (chamber: InsuranceCompany) => {
		await this.form.setField(
			"workplaceInsuranceCompanies",
			this.form.getField("workplaceInsuranceCompanies").value.map(i => ({
				...i,
				checked: chamber.id === i.id ? !i.checked : i.checked
			})));
	}

	justLoadingInsuranceCompanies = () => {
		return this.stateContainer.get().justLoadingInsuranceCompanies;
	}

	loaded = () => {
		return this.stateContainer.get().loaded;
	}

	loadData = async (id: string) => {
		await this.form.setField("id", id);
		const item = await this.context.api.get("/member/workplaces/" + id) as any;
		await this.form.setField("workplace", item.workplace);
		await this.form.setField("workplaceName", item.workplace.name);
		await this.form.setField("workplaceAcceptsNewPatients", item.workplace.accepts_new_patients);
		await this.form.setField("workplaceInsuranceCompanies", item.workplace.insurance_companies);
		await this.form.setField("workplaceTypeCares", item.workplace.type_cares);
		await this.form.setField("workplaceWeb", item.workplace.contact?.web!);
	}

	/**
	* Provede reset formuláře
	*/
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}