/**
 * Prohlášení o přístupnosti
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";


export default function AccessibilityStatementPage() {
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("prohlaseni-o-pristupnosti-nadpis")} description={contents.getRes("prohlaseni-o-pristupnosti-nadpis")}>
			<HtmlContent htmlKey="prohlaseni-o-pristupnosti" />
		</Page>
	);
}