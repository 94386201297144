/**
 *  Různé
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function CoronavirusVariousPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("ruzne-nadpis")} description={contents.getRes("ruzne-nadpis")} authorized>
			<HtmlContent htmlKey="vse-o-koronaviru-obsah" />
		</Page>
	);
}

