import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as lists from "../../system/lists/lists";
import * as apiWorkplace from "../../shared/workplace/api-workplace";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

interface State {
	insuranceCompanies: apiCodeList.CodeList[];
}

/**
 * Fiedly pro filtrování seznamu
 */
interface FilterFields {
	place: string;
	insuranceCompanies: string[];
	accepting_new_patients: boolean;
}

/**
 * Modul stránky seznamu zubařů
 */
export class Model {
	public workplaces: lists.StandardList<apiWorkplace.Workplace, FilterFields>;
	private stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			insuranceCompanies: []
		}, context);

		this.workplaces = new lists.StandardList<apiWorkplace.Workplace, FilterFields>({
			context: context,
			standardEntityApi: context.apiWorkplace.standard,
			// loadFields: ["full_name"],
			defaultSorting: { field: "name", order: lists.SortingOrder.Asc },
			defaultPageSize: 30,
			defaultFilterMode: "advanced",
			quickFilterPlaceholder: "Člen",
			advancedFilter: {
				place: {
					field: {
						title: "Zadejte obec nebo ulici",
						placeHolderText: "Obec / ulice",
						defaultValue: ""
					},
					query: value => value ? [api.qpOr([
						api.qp("address.city" as any, "=", "*" + value + "*"),
						api.qp("address.street" as any, "=", "*" + value + "*"),
						api.qp("address.postcode" as any, "=", "*" + value + "*")
					])] : []
				},
				insuranceCompanies: {
					field: {
						title: "Zdravotní pojišťovny",
						defaultValue: []
					},
					query: value => value.length > 0 ? [api.qp("insurance_companies" as any, "=", value)] : []
				},
				accepting_new_patients: {
					field: {
						title: "Přijímáme nové pacienty",
						defaultValue: false
					},
					query: value => [api.qp("accepts_new_patients" as any, "=", value)]
				}
			}
		});
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.workplaces.getStateContainers()
	]

	/**
	 * Načte data modulu
	 */
	loadData = async () => {
		await Promise.all([
			this.workplaces.load(),
			this.loadInsuranceCompanies()
		]);
	}

	/**
	 * Načte seznam pojišťoven
	 */
	loadInsuranceCompanies = async () => {
		const result = await this.context.apiInsuranceCompanies.loadList({});
		await this.stateContainer.merge(_ => ({
			insuranceCompanies: result.data
		}));
	}

	/**
	 * Vrací seznam pojišťoven
	 */
	getInsuranceCompanies = () => {
		return this.stateContainer.get().insuranceCompanies;
	}

	/**
	 * Zaškrtne pojišťovnu ve filtru
	 */
	toggleInsuranceCompany = async (insuranceCompany: apiCodeList.CodeList, checked: boolean) => {
		const insuranceCompanies = this.workplaces.getAdvancedFilterForm().getField("insuranceCompanies").value;

		await this.workplaces.getAdvancedFilterForm().setField("insuranceCompanies",
			checked
				? insuranceCompanies.concat(insuranceCompany.id)
				: insuranceCompanies.filter(i => i !== insuranceCompany.id)
		);
	}

	/**
	 * Vrací, zda je pojišťova zaškrnutá
	 */
	isInsuranceCompanyChecked = (insuranceCompany: apiCodeList.CodeList) => {
		const insuranceCompanies = this.workplaces.getAdvancedFilterForm().getField("insuranceCompanies").value;
		return insuranceCompanies.indexOf(insuranceCompany.id) !== -1;
	}
}