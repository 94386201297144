import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as apiAccreditation from "../../shared/accreditation/api-accreditation";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

/**
 * Definice stavu
 */
interface State {
	accreditationSpecializations: apiCodeList.CodeList[];
	accreditationsTypeOne: apiAccreditation.Accreditation[];
	accreditationsTypeTwo: apiAccreditation.Accreditation[];
	justLoadingAccreditationSpecializations: boolean;
	justLoadingAccreditationsTypeOne: boolean;
	justLoadingAccreditationsTypeTwo: boolean;
	specializationForAccreditationsTypeOne?: apiCodeList.CodeList;
	specializationForAccreditationsTypeTwo?: apiCodeList.CodeList;
}

/**
 * Modul stránky
 */
export class Model implements state.StateModel {
	public stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			accreditationSpecializations: [],
			accreditationsTypeOne: [],
			accreditationsTypeTwo: [],
			justLoadingAccreditationSpecializations: false,
			justLoadingAccreditationsTypeOne: false,
			justLoadingAccreditationsTypeTwo: false
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	loadAccreditationSpecializations = async () => {
		await this.stateContainer.merge(_ => ({
			accreditationSpecializations: []
		}));
		const groups = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingAccreditationSpecializations: true })),
			exec: () => this.context.apiAccreditationSpecialisations.loadList({
				sort_fields: ["name"]
			}),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingAccreditationSpecializations: false }))
		});
		await this.stateContainer.merge(_ => ({
			accreditationSpecializations: groups.data
		}));
	}

	loadAccreditationsTypeOne = async () => {
		const specialization = this.stateContainer.get().specializationForAccreditationsTypeOne;
		const accreditations = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingAccreditationsTypeOne: true })),
			exec: () => this.context.apiAccreditation.standard.loadList({
				filter: [
					api.qp("accreditation_type.id" as any, "=", 1),
					api.qp("accreditation_specialisation.id" as any, "=", specialization?.id),
				],
				sort_fields: ["provider.name" as any]
			}),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingAccreditationsTypeOne: false }))
		});
		await this.stateContainer.merge(_ => ({
			accreditationsTypeOne: accreditations.data
		}));
	}

	loadAccreditationsTypeTwo = async () => {
		const specialization = this.stateContainer.get().specializationForAccreditationsTypeTwo;
		const accreditations = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingAccreditationsTypeTwo: true })),
			exec: () => this.context.apiAccreditation.standard.loadList({
				filter: [
					api.qp("accreditation_type.id" as any, "=", 2),
					api.qp("accreditation_specialisation.id" as any, "=", specialization?.id),
				],
				sort_fields: ["provider.name" as any]
			}),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingAccreditationsTypeTwo: false }))
		});
		await this.stateContainer.merge(_ => ({
			accreditationsTypeTwo: accreditations.data
		}));
	}

	/**
	 * Načte data modulu
	 */
	loadData = async () => {
		await this.loadAccreditationSpecializations();
		await this.stateContainer.merge(prevState => ({
			specializationForAccreditationsTypeOne: prevState.specializationForAccreditationsTypeOne ?? prevState.accreditationSpecializations.length > 0 ? prevState.accreditationSpecializations[0] : undefined,
			specializationForAccreditationsTypeTwo: prevState.specializationForAccreditationsTypeTwo ?? prevState.accreditationSpecializations.length > 0 ? prevState.accreditationSpecializations[0] : undefined
		}));
		await Promise.all([
			this.loadAccreditationsTypeOne(),
			this.loadAccreditationsTypeTwo()
		]);
	}

	getSpecializations = () => {
		return this.stateContainer.get().accreditationSpecializations;
	}

	getAccreditationsTypeOne = () => {
		return this.stateContainer.get().accreditationsTypeOne;
	}

	getAccreditationsTypeTwo = () => {
		return this.stateContainer.get().accreditationsTypeTwo;
	}

	getSpecializationForAccreditationsTypeOne = () => {
		return this.stateContainer.get().specializationForAccreditationsTypeOne;
	}

	setSpecializationForAccreditationsTypeOne = async (specialization: apiCodeList.CodeList) => {
		await this.stateContainer.merge(_ => ({
			specializationForAccreditationsTypeOne: specialization
		}));
		await this.loadAccreditationsTypeOne();
	}

	getSpecializationForAccreditationsTypeTwo = () => {
		return this.stateContainer.get().specializationForAccreditationsTypeTwo;
	}

	setSpecializationForAccreditationsTypeTwo = async (specialization: apiCodeList.CodeList) => {
		await this.stateContainer.merge(_ => ({
			specializationForAccreditationsTypeTwo: specialization
		}));
		await this.loadAccreditationsTypeTwo();
	}

	justLoadingAccreditationsTypeOne = () => {
		return this.stateContainer.get().justLoadingAccreditationsTypeOne;
	}

	justLoadingAccreditationsTypeTwo = () => {
		return this.stateContainer.get().justLoadingAccreditationsTypeTwo;
	}

	justLoadingSpecializations = () => {
		return this.stateContainer.get().justLoadingAccreditationSpecializations;
	}
}