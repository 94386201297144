/**
 *  Formulář žádosti o opakování profesní zkoušky podle řádu o celoživotním 
 *	vzdělávání zubních lékařů, účinného do 31. 12. 2016 
 */


import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";

import CardLink from "../../../components/controls/CardLink";

interface Props {
	id: string;
}

function WebRequestRepetitionOfTheProfessionalExamPage(props: Props) {
	const model = state.useStateContext().webRequestRepetitionOfTheProfessionalExam;

	return (
		<WebRequest id={props.id} model={model.commonModel} title="Žádost o opakování profesní zkoušky podle řádu o celoživotním
		vzdělávání zubních lékařů, účinného do 31. 12. 2016
		(původní základní odborná příprava)" description="">

			<h2 className="form-title text-danger">Žádost není dostupná v elektronické podobě.</h2>

			<CardLink className="mb-3 d-block" href="#">
				Formulář ke stažení zde: ZOP Žádost o opakování profesni zkoušky.docx
			</CardLink>

			<p>
				<strong>Vyplněnou a podepsanou žádost vč. kopie dokladu o zaplacení poplatku zašlete v oskenované podobě
					<div>ve formátu pdf na e-mail: hynkova@dent.cz</div></strong>
			</p>

			<p>Místo konání profesních zkoušek: ČSK, Vzdělávací středisko, Slavojova 22, 128 00 Praha 2.</p>
			<p>Termín Vám Kancelář Komory přidělí s ohledem na aktuální nabídku dostupných termínů.</p>

			<strong><u>Poučení:</u></strong>

			<p>
				<small>
					Tuto žádost o opakování profesní zkoušky lze podat <strong>nejpozději do 2 měsíců</strong> od absolvování profesní zkoušky podle ŘCVZL účinného do 31. 12. 2016 s hodnocením
					„neprospěl“.
				</small>
			</p>

			<p>
				<small>Termín opakování je přípustný nejdříve <strong>po uplynutí 6 měsíců</strong> od konání profesní zkoušky, o jejíž opakování jde.</small>
			</p>

			<p>
				<small>
					Podmínkou účasti na profesní zkoušce je dodání zdravotnické dokumentace, Seznamu výkonů a výrobků potvrzeného konzultantem (nebo čestného prohlášení
					potvrzujícího, že uchazeč splnil požadavky dle seznamu výkonů a výrobků) a Dokladu o celoživotním vzdělávání zubního lékaře („Index“) s potvrzením o absolvované
					teoretické části odborné přípravy.
				</small>
			</p>

			<p><small><strong>K profesní zkoušce je povinností uchazeče předložit soubor originálů nebo kompletních kopií zdravotnické dokumentace pacientů své ordinace obsahující:</strong></small></p>

			<small className="mb-2 d-block">
				<div className="d-flex">
					<span className="mr-2">3x</span>
					<span>
						vstupní komplexní vyšetření pacienta s kariesním nebo špatně ošetřeným chrupem zahrnující RTG dokumentaci (bite-wing, OPG eventuálně status), plán ošetření
						a kontrola výsledku ošetření RTG po 1 roce (kompletní dokumentace)
					</span>
				</div>
				<div className="d-flex">
					<span className="mr-2">3x</span>
					<span>endodoncie – kompletně dokumentovaná – zahrnující RTG před, v průběhu a po ošetření (kompletní dokumentace)</span>
				</div>
				<div className="d-flex">
					<span className="mr-2">3x</span>
					<span>postendodontické ošetření – dokumentovaná RTG před a po ošetření (kompletní dokumentace)</span>
				</div>
				<div className="d-flex">
					<span className="mr-2">3x</span>
					<span>
						kompletní dokumentace pacienta s parodontopatií zahrnující mimo jiné vyšetření parodontu pomocí indexů, RTG, úroveň hygieny a stanovení diagnózy.
						Stanovení terapeutického plánu a kontrola po 1 roce dokumentovaná RTG (kompletní dokumentace)
					</span>
				</div>
				<div className="d-flex">
					<span className="mr-2">3x</span>
					<span>
						fixní protetika – dokumentace zahrnující vyšetření dokumentované RTG, návrh protetické terapie, sádrový model preparovaných pilířů včetně protiskusu bez
						zásahu laboranta, kontrolní RTG po nasazené protetické práci (kompletní dokumentace, sádrový model původní situace a po napreparování pilířových zubů)
					</span>
				</div>
				<div className="d-flex">
					<span className="mr-2">3x</span>
					<span>
						snímatelná protetika – částečná snímatelná náhrada – dokumentace zahrnující preprotetické vyšetření dokumentované RTG, studijní modely obou čelistí před
						provedením protetické práce včetně mezičelistních vztahů (kompletní dokumentace, okluzní registrát, spojené skusové šablony, skusová šablona)
					</span>
				</div>
				<div className="d-flex">
					<span className="mr-2">3x</span>
					<span>dokumentace ošetření dítěte se smíšeným chrupem zahrnující vyšetření dokumentované RTG a plán léčby (kompletní dokumentace)</span>
				</div>
			</small>

			<p>
				<small>
					<strong>
						Kompletní zdravotnickou dokumentací se rozumí nejen psaný nebo tištěný záznam průběhu ošetřování pacienta, ale i všechny používané formuláře pro
						parodontologické vyšetření, informované souhlasy, vstupní dotazníky, laboratorní příkazy, souhlasy s protetickým ošetřením, s ambulantním ošetřením apod.
					</strong>
				</small>
			</p>

			<p><strong><small><u>BEZ DOLOŽENÍ POŽADOVANÉ KOMPLETNÍ DOKUMENTACE NEMŮŽE UCHAZEČ PROFESNÍ ZKOUŠKU ABSOLVOVAT S VÝSLEDKEM PROSPĚL !</u></small></strong></p>

			<p><small><strong>Za zpracování této žádosti je stanoven poplatek </strong> ve výši 1000 Kč. Vaše žádost bude zpracována po připsání platby na účet ČSK. Pokyny k platbě najdete na formuláři</small></p>

		</WebRequest>
	);
}

export default state.bindContainers(
	WebRequestRepetitionOfTheProfessionalExamPage,
	c => c.webRequestRepetitionOfTheProfessionalExam
);