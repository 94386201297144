import { useState } from "react";

export default function useExclusiveSwitch(initial?: string) {
	const [current, setOpenedId] = useState(initial ?? "");

	function toggle(id: string) {
		setOpenedId(current === id ? "" : id);
	}

	function closeAll() {
		setOpenedId("");
	}

	return { current, toggle, closeAll } as const;
}