/**
 * Seznam vzdělávacích programů
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiEducationalPrograms from "../../shared/educational-programs/api-educational-programs";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiEducationalPrograms.EducationalProgramMember>({
		context: context,
		title: "Seznam vzdělávacích programů",
		quickFilterPlaceholder: "Vyhledejte vzdělávací program...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiEducationalPrograms.member,
		sortingFields: [],
		grid: {
			minWidth: "1000px",
			columns: [
				{
					field: "educational_program.code" as any,
					type: "string",
					title: "Kód",
				},
				{
					field: "date",
					type: "date",
					title: "Datum zařazení"
				},
				{
					field: "educational_program.name" as any,
					type: "string",
					title: "Typ odborné přípravy",
					width: "50%"
				},
				{
					field: "educational_program_member_state.name" as any,
					type: "string",
					title: "Stav"
				}
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiEducationalPrograms.EducationalProgramMember>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.userLoggedIn())
			await this.list.loadWithResetPage();
	}
}