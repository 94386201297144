/**
 * Seznam vlastních zkoušek
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiExams from "../../shared/exams/api-exams";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiExams.UserExam>({
		context: context,
		title: "Seznam zkoušek",
		quickFilterPlaceholder: "Vyhledejte zkoušku...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiExams.user,
		sortingFields: [],
		grid: {
			minWidth: "1200px",
			columns: [
				{
					field: "number",
					type: "string",
					title: "Kód"
				},
				{
					field: "educational_exam_date",
					type: "date",
					title: "Datum konání"
				},
				{
					field: "repetition",
					type: "number",
					title: "Opakování"
				},
				{
					field: "educational_exam_term_state.name" as any,
					type: "string",
					title: "Výsledek"
				},
				{
					field: "canceled",
					type: "boolean",
					title: "Anulovaná"
				},
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiExams.UserExam>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({ counter: 0 }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.userLoggedIn())
			await this.list.loadWithResetPage();
	}
}