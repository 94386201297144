/**
 * Formulář pro zadání inzerátu
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as notification from "../../../lib/notification";
import * as navigation from "../../../lib/navigation";
import * as common from "../../../lib/common";
import * as forms from "../../../lib/forms";
import * as validations from "../../../lib/validations";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiAdvertisements from "../../shared/advertisements/api-advertisements";

/**
 * Stav modulu
 */
interface State {
	id: string;
	regions: apiCodeList.CodeList[];
	sent: boolean;
	justLoadingCategories: boolean;
	justSending: boolean;
}

/**
 * Rozšíření seznamu rubrik o zaškrtnutí
 */
interface Category extends apiCodeList.CodeList {
	checked: boolean;
}

/**
 * Definice dat formuláře
 */
export type FormFields = Omit<apiAdvertisements.AdvertisementEntry, "categories" | "region" | "id"> & {
	categories: Category[];
	region?: apiCodeList.CodeList;
};

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		first_name: {
			title: "Jméno",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "Jméno je povinné pole" : ""
		},
		last_name: {
			title: "Příjmení",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "Příjmení je povinné pole" : ""
		},
		company: {
			title: "Firma",
			defaultValue: ""
		},
		email: {
			title: "E-mail",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0
				? "E-mail je povinné pole"
				: validations.validateEmailAddress(value)

		},
		phone: {
			title: "Telefon",
			defaultValue: "",
			placeHolderText: "+420 XXX XXX XXX",
			required: true,
			validate: value => value.trim().length === 0
				? "Telefon je povinné pole"
				: !validations.isPhoneNumber(value)
					? "Neplatný formát telefonního čísla"
					: ""
		},
		description: {
			title: "Text",
			defaultValue: "",
			required: true,
			hint: "max. 1000 znaků, nevkládejte kontaktní údaje",
			validate: value => value.trim().length === 0
				? "Zadejte prosím text inzerátu"
				: value.length > 1000
					? "Text inzerátu je delší než 1000 znaků"
					: "",
			onChange: async (value, field, form) => {
				if (value.length > 1000) {
					await form.setField("description", value.slice(0, 1000));
				}
			}
		},
		contact_email: {
			title: "Kontaktní e-mail",
			placeHolderText: "+420 XXX XXX XXX",
			defaultValue: "",
			validate: (value, field, form) =>
				value.trim().length === 0 && form.getField("contact_phone").value.trim().length === 0
					? "Prosím vyplňte kontaktní e-mail nebo telefon"
					: value.trim().length > 0 && !validations.isEmailAddress(value)
						? "Neplatný formát e-mailové adresy"
						: "",
			onChange: (value, field, form) => form.validateField("contact_phone")
		},
		contact_phone: {
			title: "Kontaktní telefon",
			placeHolderText: "+420 XXX XXX XXX",
			defaultValue: "",
			validate: (value, field, form) =>
				value.trim().length === 0 && form.getField("contact_email").value.trim().length === 0
					? "Prosím vyplňte kontaktní telefon nebo e-mail"
					: value.trim().length > 0 && !validations.isPhoneNumber(value)
						? "Neplatný formát telefonního čísla"
						: "",
			onChange: (value, field, form) => form.validateField("contact_email")
		},
		contact_web: {
			title: "Webové stránky",
			defaultValue: "",
		},
		region: {
			title: "Kraj",
			defaultValue: undefined,
			required: true,
			validate: value => value === undefined ? "Vyberte prosím kraj" : ""
		},
		categories: {
			title: "Rubrika",
			defaultValue: [],
			required: true,
			validate: value => value.filter(i => i.checked).length === 0 ? "Zašrtněte prosíme alespoň jednu rubriku" : ""
		}
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			regions: [],
			sent: false,
			justLoadingCategories: false,
			justSending: false,
			id: api.emptyGuid
		}, context);
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.form.getStateContainers()
		];
	}

	/**
	 * Načte seznam kategorií
	 */
	loadCategories = async () => {
		const categoriesList = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingCategories: true })),
			exec: () => this.context.api.loadList<any>("/adv_categories", {}),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingCategories: false }))
		});
		await this.form.setField("categories", categoriesList.data.map(i => ({ ...i, checked: false })));
	}

	/**
	 * Načte seznam krajů
	 */
	loadRegions = async () => {
		const regions = await this.context.apiRegions.loadList({});
		await this.stateContainer.merge(_ => ({
			regions: regions.data
		}));
	}

	/**
	 * Indikace načítání rubrik
	 */
	justLoadingCategories = () => {
		return this.stateContainer.get().justLoadingCategories;
	}

	/**
	 *  Načtení CSK 
	 */
	loadUserProfile = async (regions: apiCodeList.CodeList[]) => {
		if (!this.context.authorization.userLoggedIn()) {
			return;
		}

		let userProfile = this.context.authorization.getUserProfile();
		if (!userProfile) {
			userProfile = await this.context.authorization.loadUserProfile();
		}
		await this.form.setFields({
			first_name: userProfile.first_name,
			last_name: userProfile.last_name,
			email: userProfile.profile.contact?.email1 ?? userProfile.profile.contact?.email2 ?? "",
			phone: userProfile.profile.contact?.phone1 ?? userProfile.profile.contact?.phone2 ?? "",
			contact_email: userProfile.profile.contact?.email1 ?? userProfile.profile.contact?.email2 ?? "",
			contact_phone: userProfile.profile.contact?.phone1 ?? userProfile.profile.contact?.phone2 ?? "",
			contact_web: userProfile.profile.contact?.web ?? "",
			region: regions.find(i => i.id === userProfile!.profile.address?.region?.id)
		});
	}

	/**
	 * Vrací seznam krajů
	 */
	getRegions = () => {
		return this.stateContainer.get().regions;
	}

	send = async () => {
		const { categories, region, ...restProps } = this.form.getFields();
		if (!region) {
			// region by měl být zvalidován
			return;
		}

		const advertisment: apiAdvertisements.AdvertisementEntry = {
			...restProps,
			id: this.getId(),
			categories: categories.filter(i => i.checked).map(i => i.id),
			region: region
		};

		await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justSending: true })),
			exec: () => this.context.apiAdvertisements.standard.create(advertisment),
			finish: () => this.stateContainer.merge(_ => ({ justSending: false }))
		});
		await this.stateContainer.merge(_ => ({
			sent: true
		}));
	}

	/**
	 * Formulář je odeslán
	 */
	isSent = () => {
		return this.stateContainer.get().sent;
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			await this.send();
		} else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Indikace odesílání formuláře
	 */
	justSending = () => {
		return this.stateContainer.get().justSending;
	}

	/**
	 * Provede reset formuláře
	 */
	loadData = async (id: string) => {
		await this.loadRegions();
		await Promise.all([
			this.stateContainer.merge(_ => ({ sent: false })),
			this.form.clearFields(),
		]);
		if (id == api.emptyGuid) {
			await this.loadCategories();
			this.loadUserProfile(this.stateContainer.get().regions)
		}
		else {
			const detail = await this.context.api.get("/advertisements/" + id) as FormFields;
			if (detail) {
				await this.form.setFields(detail);
				await this.loadCategories();
				const categories = this.form.getField("categories").value.map(i => ({ ...i, checked: (detail.categories as any[]).map(x => x).includes(i.id) ? true : false }));
				await this.form.setField("categories", categories);
				await this.stateContainer.merge(_ => ({
					id: id
				}));
			}
		}
	}

	getId = () => {
		return this.stateContainer.get().id;
	}

	/**
	 * Přepne rubriku
	 */
	toggleCategory = async (category: Category) => {
		const categories = this.form.getField("categories").value;
		await this.form.setField("categories", categories.map(i => ({ ...i, checked: i.id === category.id ? !i.checked : i.checked })));
	}

	removeDetail = async () => {
		const result = await this.context.api.del("/advertisements/" + this.getId());
		if (result) {
			notification.successMessage("Došlo ke smazání inzerce.");
			navigation.to("/inzerce");
		}
	}

	remove = async () => {
		await this.context.standardDialogs.openYesNoDialog("Opravdu chcete smazat inzerci?") === "yes"
			? this.removeDetail()
			: undefined;
	}
}