/**
 *  Formulář zadost-o-peci-o-dite-odvolani
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";

function WebRequestForAChildAppeal() {
	const title = "ODVOLÁNÍ (ZRUŠENÍ) ČESTNÉHO PROHLÁŠENÍ - PÉČE O DÍTĚ DO 4 LET VĚKU";
	const { webRequestChildAppeal: model } = state.useStateContext();

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
		>
			<div className="text-right">
				<p>Odvolávám (ruším) čestné prohlášení, že osobně celodenně pečuji o dítě do 4 let věku, tj. dítě není svěřeno do péče jiné osobě ani není umístěno v jeslích/mateřské škole, popř. jiném obdobném zařízení na dobu, která převyšuje 4 hodiny denně.</p>
				<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
			</div>
		</WebRequest >
	);
}

export default state.bindContainers(
	WebRequestForAChildAppeal,
	c => c.webRequestChildAppeal
);