/**
 * Detail knihy v e-shopu
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import Button from "../../../components/controls/Button";
import Input from "../../../components/controls/Input";
import PhotoGalleryImage from "../../../components/controls/PhotoGalleryImage";
import HtmlContent from "../../../components/controls/HtmlContent";
import CardLink from "../../../components/controls/CardLink";
import Books from "../../shared/shop/Books";
import CartBanner from "../../shared/shop/CartBanner";

function BooksItemPage() {
	const { booksItem: model } = state.useStateContext();
	const item = model.getItem();
	const justLoading = model.justLoadingItem();
	const title = justLoading ? "Načítám knihu..." : item?.name ?? "";
	return (
		<Page title={title} description="">
			<h1 className="d-flex align-items-center mb-5">
				{justLoading &&
					<Spinner small className="ml-1" />
				}
				{title}
			</h1>

			<CartBanner />

			{item &&
				<>
					<div className="row">
						<div className="col-md-6">
							<PhotoGalleryImage
								className="books-item-page__image mb-4 mb-md-0"
								src={item.image_id}
								gallerySrc={item.image_id}
							/>
						</div>
						<div className="col-md-6 pl-lg-5">
							<h2><strong>{formatting.formatCurrency(item.price)}</strong></h2>
							<div className="mb-3">
								<HtmlContent content={item.info} />
							</div>
							<div>
								<Input
									className="books-item-page__count"
									type="number"
									value={model.getCount()}
									onChange={model.setCount}
								/>
								<Button theme="primary" onClick={model.addToCart}>Přidat do košíku</Button>
							</div>
							<div className="mb-2">
								Kategorie: <strong>{item.categories.map(i => i.name).join(", ")}</strong>
							</div>
							{item.review &&
								<CardLink href="#" >
									<a target="_blank" href={item.review.link} >
										{item.review.name}
									</a>
								</CardLink>
							}
						</div>
					</div>
					<h2>Popis</h2>
					<p><strong>{item.description.title}</strong></p>
					<p>
						<HtmlContent content={item.description.text} />
					</p>
					<h2>Související produkty</h2>
					<Books items={item.related_items} />
				</>
			}
		</Page >
	);
}

export default state.bindContainers(
	BooksItemPage,
	c => c.booksItem
);