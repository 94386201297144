/**
 *  Formulář žádosti o zápis do seznamu znalců České stomatologické komory pro obor znalecké činnosti
 */

import React from "react";

import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormInput from "../../../components/controls/Forms/FormsInput";

import FormFileUpload from "../../system/file/FormFileUpload";

import * as state from "../../../lib/state";

interface Props {
	id: string;
}

function WebRequestExpertsEntryPage(props: Props) {
	const { webRequestExpertsEntry } = state.useStateContext();
	const model = state.useStateContext().webRequestExpertsEntry;
	const formEntry = webRequestExpertsEntry.form;

	return (
		<WebRequest id={props.id} model={webRequestExpertsEntry.commonModel} title="ŽÁDOST O ZÁPIS DO SEZNAMU ODBORNÍKŮ" description="">

			<h2 className="form-title">Žádám o zápis do seznamu oborníků České stomatologické komory pro obor odborné činnosti</h2>

			<div className="p-4 row">
				<div className="col-md-6">
					<FormCheckBox
						form={formEntry}
						field="child_stomatologi"
					/>
					<FormCheckBox
						form={formEntry}
						field="oral_maxillofacial_and_facial_surgery"
					/>
					<FormCheckBox
						form={formEntry}
						field="oral_surgery"
					/>
					<FormCheckBox
						checkBoxProps={{ className: "mb-0" }}
						form={formEntry}
						field="periodontology"
					/>
				</div>
				<div className="col-md-6">
					<FormCheckBox
						form={formEntry}
						field="orthodontics"
					/>
					<FormCheckBox
						form={formEntry}
						field="prosthetic_dentistry"
					/>
					<FormCheckBox
						form={formEntry}
						field="restorative_dentistry"
					/>
				</div>
			</div>

			<h2 className="form-title mb-5">K žádosti přiložte tyto přílohy (v českém jazyce)</h2>

			<FormFileUpload
				form={formEntry}
				field="education_evidence_attachment"
				className="mb-5"
			/>
			<FormFileUpload
				form={formEntry}
				field="professional_cv_attachment"
				className="mb-5"
			/>
			<FormFileUpload
				form={formEntry}
				field="membership_data_attachment"
				className="mb-5"
			/>
			<FormFileUpload
				form={formEntry}
				field="statement_attachment"
				className="mb-5"
			/>
			<FormFileUpload
				form={formEntry}
				field="lecture_activities_attachment"
				className="mb-5"
			/>
			<FormFileUpload
				form={formEntry}
				field="publishing_activities_attachment"
				className="mb-5"
			/>
			<FormFileUpload
				form={formEntry}
				field="other_attachment"
			/>

			<div className="row">
				<div className="col-md-4 col-lg-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={formEntry}
						field="submission_date"
					/>
				</div>
			</div>
			<div className="text-right">
				<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
				<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
				<Button theme="primary" onClick={model.confirm} spinner={model.commonModel.justSending()}>Odeslat</Button>
			</div>
		</WebRequest>
	);
}

export default state.bindContainers(
	WebRequestExpertsEntryPage,
	c => c.webRequestExpertsEntry
);