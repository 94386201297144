import * as history from "history";

let historyInstance: history.History | null;
let lastRouteParams: any = null;

export interface StandardNavParams {
	reset?: boolean;
	replaceHistory?: boolean;
}

export function registerHistory(history: history.History) {
	historyInstance = history;
}

/**
 * Přejde na danou routu
 * 
 * @param route URL routy
 * @param reset příznak, zda se má stránka resetovat, tj. nezachovat původní stav
 */
export function to<TParams extends StandardNavParams>(route: string, params?: TParams) {
	if (!historyInstance) {
		throw "Není registrován objekt history.";
	}

	const routeWithoutHash = route.replace(/\#.*/, "");
	const hashTag = route.replace(/^[^#]*/, "");

	if (routeWithoutHash === getCurrent()) {
		if (hashTag.length > 0) {
			window.location.href = hashTag;
		}
	} else {
		lastRouteParams = params;

		if (params && params.replaceHistory) {
			historyInstance.replace(route);
		} else {
			historyInstance.push(route);
		}
	}
}

/**
 * Vrací příznak, zda poslední routa byla volaná s příznakem reset.
 */
export function popParams<TParams extends StandardNavParams>() {
	if (lastRouteParams == null) {
		return null;
	}
	const result = lastRouteParams as TParams;
	lastRouteParams = null;
	return result;
}

export function getCurrent() {
	if (!historyInstance) {
		return "/";
	}

	return historyInstance.location.pathname;
}

export function scroolTo(elementId: string, smooth: boolean = false) {
	const element = document.getElementById(elementId);
	if (element) {
		setTimeout(function () {
			element.scrollIntoView({
				behavior: smooth ? "smooth" : "auto",
				block: "start",
			});
		}, 100);
	}
}