/**
 * Dialog pro resource
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "./Dialog";
import Button from "../../../components/controls/Button";
import EnterPressArea from "../../../components/controls/EnterPressArea";
import HtmlEditor from "../../../components/controls/HtmlEditor";
import Select from "../../../components/controls/Select";

function ResourceDialog() {
	const { standardDialogs } = state.useStateContext();
	const dialog = standardDialogs.resourceDialog;
	function handleHtmlEditor(value: string) {
		if (value) {
			dialog.setCustomField("text", value);
		}
	}

	return (
		<Dialog dialog={dialog}>
			<EnterPressArea>
				<h1 className="pb-1">Detail obsahu</h1>
				<div className="text-center">
					<div className="row">
						<div className="col">
							<label htmlFor="res-key" className="inp-label float-left">
								Klíč
							</label>
							<span className="inp-fix">
								<input
									disabled={true}
									id="res-key"
									type="text"
									className="inp-text mb-3"
									value={dialog.getCustomField("key")}
									onChange={e => dialog.setCustomField("key", e.target.value)}
								/>
							</span>
						</div>
						<div className="col">
							<label htmlFor="res-content_type" className="inp-label float-left">
								Typ obsahu
							</label>
							<span className="inp-fix">
								<Select
									id="res-content_type"
									value={dialog.getCustomField("content_type")}
									onChange={value => dialog.setCustomField("content_type", value)}
									options={
										dialog.getCustomField("data").map(i => ({
											title: i.name,
											value: i
										}))
									} />
							</span>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<label htmlFor="res-placement" className="inp-label float-left">
								Umístění na webu
							</label>
							<span className="inp-fix">
								<input
									id="res-placement"
									type="text"
									className="inp-text mb-3"
									value={dialog.getCustomField("placement")}
									onChange={e => dialog.setCustomField("placement", e.target.value)}
								/>
							</span>
						</div>
					</div>
					<div className="row mt-2 text-left">
						<div className="col">
							<label className="inp-label" htmlFor={"body"}>Tělo</label>
							<span className="inp-fix">
								<HtmlEditor
									onChange={(value: any) => handleHtmlEditor(value)}
									value={dialog.getCustomField("text")}
								/>
							</span>
						</div>
					</div>
				</div>
				<div className="mt-4 text-center">
					<Button onClick={e => standardDialogs.saveResourceDialog()} className="pt-2 pb-2 float-right">
						<strong>Uložit a zavřít</strong>
					</Button>
				</div>
			</EnterPressArea>
		</Dialog >
	);
}

export default state.bindContainers(ResourceDialog,
	context => context.standardDialogs.resourceDialog
);