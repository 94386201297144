/**
 * Stavovské předpisy
 */
import React from "react";
import Page from "../../../components/templates/Page";

import HtmlContent from "../../../components/controls/HtmlContent";

import * as state from "../../../lib/state";

export default function RulesOfProcedures() {
	const { contents } = state.useStateContext();

	return (
		<Page authorized title={contents.getRes("rady-csk-nadpis")} description={contents.getRes("rady-csk-nadpis")}>
			<HtmlContent htmlKey="rady-csk-obsah" />
		</Page>
	);
}
