/**
 * Model žádosti o registraci akce
 * 
 * */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

export interface StudentFormFields {
	name: string;
	lastname: string;
	tel: string;
	email: string;

	street: string;
	city: string;
	psc: string;
	state: "čr";

	company_name: string;
	company_street: string;
	company_city: string;
	company_psc: string;
	company_ico: string;
	company_dic: string;

	print_count: "1" | "2";
	total_price: string;
}

const StudentForm: forms.FormOptions<StudentFormFields> = {
	fields: {
		name: {
			title: "Jméno",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Jméno je povinné pole" : ""
		},
		lastname: {
			title: "Přijmení",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Přijmení je povinné pole" : ""
		},
		tel: {
			title: "Telefon",
			placeHolderText: "+420 XXX XXX XXX",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Telefon je povinné pole" : ""
		},
		email: {
			title: "E-mail",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "E-mail je povinné pole" : ""
		},
		street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Ulice je povinné pole" : ""
		},
		city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Obec je povinné pole" : ""
		},
		psc: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		state: {
			title: "Stát",
			defaultValue: "čr",
			readOnly: true
		},
		company_name: {
			title: "Název firmy",
			defaultValue: "",
			required: false
		},
		company_street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Ulice je povinné pole" : ""
		},
		company_city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Obec je povinné pole" : ""
		},
		company_psc: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		company_ico: {
			title: "IČO",
			defaultValue: ""
		},
		company_dic: {
			title: "DIČ",
			defaultValue: ""
		},

		print_count: {
			title: "Počet výtisků od jednoho čísla",
			defaultValue: "1",
			readOnly: true
		},
		total_price: {
			title: "Celkem k úhradě v Kč",
			defaultValue: "0"
		}
	}
};

export interface CompanyFormFields {
	title_before: string;
	title_pedagogical: string;
	title_after: string;
	name: string;
	lastname: string;
	tel: string;
	email: string;

	company_name: string;
	street: string;
	city: string;
	psc: string;
	state: string;

	billing_name: string;
	billing_street: string;
	billing_city: string;
	billing_psc: string;
	billing_state: string;
	billing_ico: string;
	billing_dic: string;

	print_count: "1" | "2";
	total_price: string;
}

const CompanyForm: forms.FormOptions<CompanyFormFields> = {
	fields: {
		title_before: {
			title: "Titul před",
			defaultValue: ""
		},
		title_pedagogical: {
			title: "Pedagogický titul",
			defaultValue: ""
		},
		title_after: {
			title: "Titul za",
			defaultValue: ""
		},
		name: {
			title: "Jméno",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Jméno je povinné pole" : ""
		},
		lastname: {
			title: "Přijmení",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Příjmení je povinné pole" : ""
		},
		tel: {
			title: "Telefon",
			placeHolderText: "+420 XXX XXX XXX",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Telefon je povinné pole" : ""
		},
		email: {
			title: "E-mail",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "E-mail je povinné pole" : ""
		},
		street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Ulice je povinné pole" : ""
		},
		city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Obec je povinné pole" : ""
		},
		psc: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		state: {
			title: "Stát",
			defaultValue: "čr",
			readOnly: true
		},
		company_name: {
			title: "Název firmy",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Jméno je povinné pole" : ""
		},
		billing_name: {
			title: "Název firmy",
			defaultValue: "",
			required: false
		},
		billing_street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Ulice je povinné pole" : ""
		},
		billing_state: {
			title: "Stát",
			defaultValue: "ČR",
			readOnly: true
		},
		billing_city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Obec je povinné pole" : ""
		},
		billing_psc: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		billing_ico: {
			title: "IČO",
			defaultValue: ""
		},
		billing_dic: {
			title: "DIČ",
			defaultValue: ""
		},

		print_count: {
			title: "Počet výtisků od jednoho čísla",
			defaultValue: "1",
			readOnly: true
		},
		total_price: {
			title: "Celkem k úhradě v Kč",
			defaultValue: "0"
		}
	}
};

export interface CSKFormFields {
	title_before: string;
	title_pedagogical: string;
	title_after: string;
	name: string;
	lastname: string;
	tel: string;
	email: string;

	street: string;
	city: string;
	psc: string;
	state: "čr";

	billing_name: string;
	billing_street: string;
	billing_city: string;
	billing_psc: string;
	billing_ico: string;
	billing_dic: string;

	print_count: "1" | "2";
	total_price: string;
}

const CSKForm: forms.FormOptions<CSKFormFields> = {
	fields: {
		title_before: {
			title: "Titul před",
			defaultValue: ""
		},
		title_pedagogical: {
			title: "Pedagogický titul",
			defaultValue: ""
		},
		title_after: {
			title: "Titul za",
			defaultValue: ""
		},
		name: {
			title: "Jméno",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Jméno je povinné pole" : ""
		},
		lastname: {
			title: "Přijmení",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Příjmení je povinné pole" : ""
		},
		tel: {
			title: "Telefon",
			placeHolderText: "+420 XXX XXX XXX",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Telefon je povinné pole" : ""
		},
		email: {
			title: "E-mail",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "E-mail je povinné pole" : ""
		},
		street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Ulice je povinné pole" : ""
		},
		city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Obec je povinné pole" : ""
		},
		psc: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		state: {
			title: "Stát",
			defaultValue: "čr",
			readOnly: true
		},
		billing_name: {
			title: "Název firmy",
			defaultValue: "",
			required: false
		},
		billing_street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Ulice je povinné pole" : ""
		},
		billing_city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Obec je povinné pole" : ""
		},
		billing_psc: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		billing_ico: {
			title: "IČO",
			defaultValue: ""
		},
		billing_dic: {
			title: "DIČ",
			defaultValue: ""
		},

		print_count: {
			title: "Počet výtisků od jednoho čísla",
			defaultValue: "1",
			readOnly: true
		},
		total_price: {
			title: "Celkem k úhradě v Kč",
			defaultValue: "0"
		}
	}
};

/**
 * Stav modulu
 */
interface State {
	buyer: 1 | 2 | 3;
}

interface FormData extends State {
	data: CSKFormFields | StudentFormFields | CompanyFormFields;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public studentForm: forms.Form<StudentFormFields>;
	public companyForm: forms.Form<CompanyFormFields>;
	public cskForm: forms.Form<CSKFormFields>;

	public common: webRequest.Model;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.studentForm = new forms.Form<StudentFormFields>(StudentForm, context);
		this.companyForm = new forms.Form<CompanyFormFields>(CompanyForm, context);
		this.cskForm = new forms.Form<CSKFormFields>(CSKForm, context);

		this.stateContainer = new state.StateContainer<State>({
			buyer: 1,
		}, context);
	}

	getBuyer = () => this.stateContainer.get().buyer;
	changeBuyer = (buyer: 1 | 2 | 3) => this.stateContainer.merge(_ => ({ buyer }));

	getBuyerForm = () =>
		this.stateContainer.get().buyer === 1
			? this.cskForm
			: this.stateContainer.get().buyer === 2
				? this.studentForm
				: this.companyForm

	/**
	 *  Načtení CSK 
	 */
	loadCskMember = async () => {
		if (this.context.authorization.userLoggedIn()) {
			let userProfile = this.context.authorization.getUserProfile();
			if (!userProfile) {
				userProfile = await this.context.authorization.loadUserProfile();
			}
			await this.cskForm.setFields({
				name: userProfile.first_name,
				lastname: userProfile.last_name,
				title_before: userProfile.profile.title_before,
				title_after: userProfile.profile.title_after,
				title_pedagogical: userProfile.profile.title_pedagogical,
				tel: userProfile.profile.contact?.phone1,
				email: userProfile.profile.contact?.email1,
				street: userProfile.profile.delivery_address?.street ?? "",
				city: userProfile.profile.delivery_address?.city ?? "",
				psc: userProfile.profile.delivery_address?.postcode == undefined ? "" : userProfile.profile.delivery_address?.postcode.replace(/\s+/g, ""),
				billing_street: userProfile.profile.address?.street ?? "",
				billing_name: userProfile.profile.address?.city ?? "",
				billing_psc: userProfile.profile.address?.postcode == undefined ? "" : userProfile.profile.address?.postcode.replace(/\s+/g, ""),
			});
		}
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		...this.common.getStateContainers(),
		...this.studentForm.getStateContainers(),
		...this.companyForm.getStateContainers(),
		...this.cskForm.getStateContainers(),
		this.stateContainer
	]

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		const form = this.getBuyerForm();
		await form.validate();
		if (form.isValid()) {
			const data = {
				...form.getFields(),
				buyer: this.stateContainer.get().buyer,
			};
			await this.common.send(data, "zavazna-objednavka-casopis-cspzl");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { buyer, data: formData } = data;
			await this.stateContainer.merge(_ => ({ buyer }));
			await this.getBuyerForm().setFields(formData as any);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.studentForm.clearFields(),
			this.companyForm.clearFields(),
			this.cskForm.clearFields(),
			this.common.reset()
		]);
		await this.loadCskMember();
	}
}