/**
 * O vzdělávání
 */
import React from "react";

import * as state from "../../../lib/state";
import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function EducationAboutPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("o-vzdelani-nadpis")} description={contents.getRes("o-vzdelani-nadpis")}>
			<HtmlContent htmlKey="o-vzdelani-obsah" />
		</Page>
	);
}
