import * as React from "react";
import * as common from "./common";
import { RouteComponentProps, matchPath, match } from "react-router-dom";

type ComponentRenderFunc = (props: RouteComponentProps<any>) => React.ReactNode;

export interface Route {
	route: string;
	aliases?: string[];
	component?: React.ComponentType;
	render?: ComponentRenderFunc;
	loadData?: (routeMatch: match) => Promise<void>;
	loadSsr?: boolean;
	pageTitle?: string;
	pageDescription?: string;
}

export async function loadData(urlPath: string, routes: Route[], firstLoading?: boolean) {
	for (let i = 0; i < routes.length; i++) {
		const route = routes[i];
		const aliases = routes[i].aliases;
		let match = matchPath(urlPath, {
			path: route.route,
			exact: true
		});

		if (match === null && aliases) {
			for (let alias of aliases) {
				match = matchPath(urlPath, {
					path: alias,
					exact: true
				});
				if (match !== null) {
					break;
				}
			}
		}

		if (match !== null && route.loadData) {
			if ((common.serverExecution() && route.loadSsr) || (!common.serverExecution() && (!firstLoading || !route.loadSsr || (window as any).developmentMode))) {
				try {
					await route.loadData(match);
				} finally {
					break;
				}
			}
		}
	}
}

export function justInitialLoading() {
	return (window as any).justInitialLoading === true;
}