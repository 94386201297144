/**
 * Publikace
 */
import React, { useState } from "react";

import * as state from "../../../lib/state";
import * as apiPublications from "../../shared/publications/api-publications";
import * as common from "../../../lib/common";
import * as navigation from "../../../lib/navigation";

import Page from "../../../components/templates/Page";
import Card from "../../../components/controls/Card";
import Spinner from "../../../components/controls/Spinner";
import Link from "../../../components/controls/Link";
import SlideDown from "../../../components/controls/SlideDown";
import HtmlContent from "../../../components/controls/HtmlContent";
import EnterPressArea from "../../../components/controls/EnterPressArea";

const ITEMS_COUNT = 5;

interface TopicCardProps {
	topic: apiPublications.WebPublicationTopic;
}

function TopicCard(props: TopicCardProps) {
	return (
		<Card className="publication-page__card">
			<div>
				<h2><Link className="publication-page-topic-color" to={"/komornik/clanek/" + props.topic.id + "?isRoot=true"}>{props.topic.name}</Link></h2>
			</div>

			<ul className="--neutral mb-0">
				{props.topic.items.slice(0, ITEMS_COUNT).map((j, index) =>
					<li key={index}><Link to={"/komornik/clanek/" + j.id} className="d-inline-flex"><span className="publication-page__topic-number">{j.number}</span> {j.name}</Link></li>
				)}
			</ul>

			<SlideDown open={false}>
				<ul className="--neutral mb-0">
					{props.topic.items.slice(5).map((j, index) =>
						<li key={index}><Link to={"/komornik/clanek/" + j.id} className="d-inline-flex"><span className="publication-page__topic-number">{j.number}</span> {j.name}</Link></li>
					)}
				</ul>
			</SlideDown>

			{props.topic.items.length == 0 &&
				<span>Připravuje se</span>
			}
			{props.topic.items.length > ITEMS_COUNT &&
				<Link to={"/komornik/clanek/" + props.topic.id + "?isRoot=true&expand=true"} className="mt-3 text-right publication-page-expand">
					<a href="#">
						<small>
							<>Více &gt;&gt;</>
						</small>
					</a>
				</Link>
			}
		</Card>
	);
}

function PublicationPage() {
	const { publication: model } = state.useStateContext();
	const { api } = state.useStateContext();
	const { contents } = state.useStateContext();

	async function handleSearch() {
		await common.deffered(async () => {
			const result = await api.loadList<apiPublications.PublicationTopicContent>("/publications/search", { fulltext: model.getFulltext() });
			await model.setIsSearchActive(true);
			model.setItems(result.data);
		});
	}

	async function handleReset() {
		await model.setFulltext("");
		await model.setIsSearchActive(false);
		model.resetItems();
	}

	async function handleTopic(id: string, isParent: boolean) {
		navigation.to("/komornik/clanek/" + id + (isParent ? "?isRoot=true" : ""));
	}

	return (
		<Page title={contents.getRes("komornik-nadpis")} authorized description="">
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="komornik-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>
			<HtmlContent className="mb-3" htmlKey="komornik-obsah" />
			<EnterPressArea onEnter={handleSearch}>
				<div className="form-filter u-mb-md">
					<div id="records" className="form-filter__inner row">
						<div className="col-12 col-sm-6 col-md-4 col-lg-5 u-mb-md">
							<label htmlFor="filter-search" className="inp-label u-vhide">
								Zadejte jméno člena
							</label>
							<span className="inp-fix">
								<input
									type="text"
									id="inp-filter-search"
									placeholder={"Hledat..."}
									className="inp-text inp-text--filter"
									value={model.getFulltext()}
									onChange={(e) => model.setFulltext(e.target.value)}
								/>
							</span>
						</div>
						<div className="col-12 col-sm-6 col-md-auto u-mb-md ml-md-auto">
							<button type="submit" className="btn btn--filter" onClick={handleSearch}>
								<span className="btn__text">
									Vyhledat
								</span>
							</button>
						</div>
						<div className="col-12 col-sm-6 col-md-auto u-mb-md">
							<button type="submit" className="btn btn--filter" onClick={handleReset}>
								<span className="btn__text">
									Reset
								</span>
							</button>
						</div>
					</div>
				</div>
			</EnterPressArea>
			<div className="row mt-4">
				{!model.getIsSearchActive() && model.getTopics().map((i, index) =>
					<div key={index} className="col-12 col-md-6 col-lg-4 mb-gutter publication-page-topic">
						<TopicCard topic={i} />
						<div className="publication-page-items-count">{index + 1}</div>
					</div>
				)}
				{model.getIsSearchActive() && model.getItems().map((i, index) =>
					<div className="col-12">
						<React.Fragment key={index}>
							<div className="publication-page-link-container">
								<div className="publication-page-link font-weight-bold" onClick={() => handleTopic(i.id, false)}><span>{i.parent?.name}</span> {' > '}</div>
								<div className="publication-page-link font-weight-bold" onClick={() => handleTopic(i.id, false)}><span>{i.number} {i.name}</span></div>
							</div>
							<HtmlContent content={i.information} />
						</React.Fragment>
					</div>
				)}
				{model.getItems().length == 0 && model.getIsSearchActive() &&
					<div className="col-12">
						<div className="ml-2 publication-page-link font-weight-bold secondary-title">Nebyly nalezeny žádné výsledky vyhledávání.</div>
					</div>
				}
			</div>
		</Page>
	);
}

export default state.bindContainers(
	PublicationPage,
	c => c.publication
);