import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as state from "../../lib/state";

interface LogoProps {
	isHomePage?: boolean;
}

export default function Logo(props: LogoProps) {
	const image = <img src="/images/logo-big.svg" alt="ČSK" width="380" height="60" />;

	return (
		<>
			{(props.isHomePage) &&
				<h1 className={"logo"}>
					<Link to="/">
						{image}
					</Link>
				</h1>
			}
			{!props.isHomePage &&
				<p className="logo">
					<Link to="/">
						{image}
					</Link>
				</p>
			}
			<div className="logo logo--mobile">
				<Link to="/">
					<img src="/images/logo.svg" alt="ČSK" width="33" height="60" />
				</Link>
			</div>
		</>);
}