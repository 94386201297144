
import * as React from "react";
import * as forms from "../../../lib/forms";

import FormItem, { FormItemOptions } from "./FormItem";
import Select, { SelectProps } from "../Select";


export interface FormsSelectProps<TFormFields, TField extends keyof TFormFields> {
	form: forms.Form<TFormFields>;
	field: TField;
	options: { title: string; value: TFormFields[TField] }[];
	className?: string;
	selectProps?: SelectProps<TField>;
	formItemOptions?: FormItemOptions;
}

export default function FormsSelect<TFormFields, TField extends keyof TFormFields>(props: FormsSelectProps<TFormFields, TField>) {
	const field = props.form.getField(props.field);
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
			className={props.className}
		>
			<Select
				{...props.selectProps}
				options={props.options}
				value={field.value}
				disabled={field.readOnly}
				onChange={value => props.form.setField(props.field, value)}
			/>
		</FormItem>
	);
}