/**
 *  Formulář žádosti o vydání osvědčení o splnění podmínek k výkonu funkce odborného zástupce
 */

import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import WebRequest from "../../../components/templates/WebRequest";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormFileUpload from "../../system/file/FormFileUpload";
import Button from "../../../components/controls/Button";
import IconSvg from "../../../components/controls/IconSvg";
import Checkbox from "../../../components/controls/Checkbox";
import HtmlContent from "../../../components/controls/HtmlContent";

interface Props {
	id?: string;
}


function WebRequestIssueCertificatePage(props: Props) {
	const { authorization } = state.useStateContext();
	const { webRequestIssueCertificate: model } = state.useStateContext();
	const form = model.form;
	const credits = form.getField("credits").value;
	const certificate = authorization.getUserProfile()?.profile.certificate;
	const membershipFrom = authorization.getUserProfile()?.profile.membership_from;
	const showWarning = membershipFrom == null || (Math.abs((new Date()).getFullYear() - membershipFrom.getFullYear())) < 5;

	return (
		<WebRequest
			id={props.id}
			model={model.common}
			title="ŽÁDOST O VYDÁNÍ OSVĚDČENÍ O SPLNĚNÍ PODMÍNEK PRO VÝKON FUNKCE VEDOUCÍHO LÉKAŘE A PRIMÁŘE"
			description=""
		>
			<p className="text-danger font-weight-bold">
				Toto osvědčení je určeno pouze pro pracovníky fakultních nemocnic a fakultních klinik.
			</p>
			<HtmlContent content={form.getField("request_text").value} />
			<p><strong>Žádám o vydání osvědčení o splnění podmínek pro výkon funkce:</strong></p>

			<CheckBoxRadioFrame className="mb-3">
				<FormRadio
					form={form}
					field="function"
					option="chief-physician"
					title="vedoucí lékař"
				/>
				<FormRadio
					form={form}
					field="function"
					option="primary"
					title="primář"
				/>
			</CheckBoxRadioFrame>
			<p><strong>pro zdravotní péči v oboru:</strong></p>

			<CheckBoxRadioFrame className="mb-3">
				<FormRadio
					form={form}
					field="care"
					option="zubni-lekarstvi"
					title="zubní lékařství"
				/>
				<FormRadio
					form={form}
					field="care"
					option="ortodencie"
					title="ortodoncie"
				/>
				<FormRadio
					form={form}
					field="care"
					option="ustni-celistni-oblicejova-chirurgie"
					title="ústní, čelistní a obličejová chirurgie"
				/>
				<FormRadio
					form={form}
					field="care"
					option="klinicka-stomatologie"
					title="klinická stomatologie"
				/>
			</CheckBoxRadioFrame>

			<div><span className="font-weight-bold">Podmínky k vydání osvědčení:</span>
				<div>a) členství v Komoře,</div>
				<div>b) trvající zápis v seznamu členů vedeném některou Oblastní komorou,</div>
				<div>c) způsobilost k samostatnému výkonu povolání zubního lékaře (odborná způsobilost a zdravotní způsobilost),</div>
				<FormCheckBox
					form={form}
					field="declaration"
					className="mb-2"
				/>
				<div>d) disciplinární bezúhonnost,</div>
				<div>e) trestní bezúhonnost,</div>
				<FormCheckBox
					form={form}
					field="second_declaration"
					className="mb-2"
				/>
				<div>f) bezdlužnost vůči Komoře,</div>
				<div>g) absolvování vzdělávacího kurzu pro odborné zástupce (v průběhu posledních 5 let bezprostředně předcházejících podání žádosti),</div>
				{certificate &&
					<div>h) platné osvědčení o soustavné účasti v systému celoživotního vzdělávání organizovaného Komorou</div>
				}
				{!certificate &&
					<>
						<div>h) souvislý výkon povolání zubního lékaře alespoň v rozsahu preventivní, diagnostické a léčebné činnosti v oblasti péče o zuby, ústa, čelisti a související tkáně (po dobu nejméně 5 let bezprostředně předcházejících podání žádosti, souvislým výkonem povolání se rozumí nepřerušený výkon povolání v rozsahu alespoň 8 hodin týdně. Výkon povolání je možno přerušit na nejvýše 5 týdnů v kalendářním roce.)</div>
						<CheckBoxRadioFrame className="mb-3">
							<FormRadio
								form={form}
								field="multiple_declaration"
								option="5_years_of_experience"
								title="Čestně prohlašuji, že jsem po dobu nejméně 5 let před podáním této žádosti souvisle vykonával/a povolání zubního lékaře v rozsahu uvedeném výše."
							/>
							<FormRadio
								form={form}
								field="multiple_declaration"
								option="no_need_to_prove_practice"
								title="Praxi nemusím prokazovat, protože členství v Komoře mi vzniklo v době od 1.1.2018 do 31.12.2020 a tuto žádost podávám do 5 let od vzniku členství."
							/>
							<FormRadio
								form={form}
								field="multiple_declaration"
								option="serious_reasons"
								title="Mám závažné důvody, pro které jsem souvislou praxi nemohl/a absolvovat, a žádám o prominutí praxe."
							/>
						</CheckBoxRadioFrame>
						{form.getField("multiple_declaration").value == "serious_reasons" &&
							<div>
								<b>Poučení:</b><br />
								Důvody prominutí nelze kombinovat a maximální dobu prominutí nelze sčítat.
							</div>
						}
						{form.getField("multiple_declaration").value == "serious_reasons" &&
							<div>
								<CheckBoxRadioFrame className="mb-3">
									<div style={{ color: "#004288" }} className="ml-3">Závažné důvody, pro které jsem nemohl/a absolvovat praxi:</div>
									<FormRadio
										form={form}
										field="serious_reasons"
										option="maternity_and_parental_leave"
										title="mateřská a rodičovská dovolená (lze prominut nejvýše 4 roky praxe)"
									/>
									<FormRadio
										form={form}
										field="serious_reasons"
										option="serious_health_reasons"
										title="vážné zdravotní důvody, dlouhodobá nemoc, ošetřování člena rodiny (lze prominout nevýše 1 rok praxe)"
									/>
									<FormRadio
										form={form}
										field="serious_reasons"
										option="study_stay_abroad"
										title="zahraniční studijní pobyt stomatologického zaměření (lze prominout nejvýše 1 rok praxe)"
									/>
									<FormRadio
										form={form}
										field="serious_reasons"
										option="damage_due_to_natural_disaster"
										title="poškození ordinace živelní pohromou (lze prominout nejvýše 6 měsíců praxe)"
									/>
									<FormRadio
										form={form}
										field="serious_reasons"
										option="other"
										title="jiné (lze prominout nejvýše 4 roky praxe)"
									/>
									<div style={{ color: "#004288" }} className="ml-3">
										<div>
											<b>Poučení:</b><br />
											Důvody prominutí je třeba doložit kopií přislušného dokladu -
											{form.getField("serious_reasons").value == "maternity_and_parental_leave" &&
												<span>
													- Rodný list dítěte nebo Rozhodnutí o nároku na peněžitou pomoc v mateřství nebo Rozhodnutí o výplatě rodičovského příspěvku.
												</span>
											}
											{form.getField("serious_reasons").value == "serious_health_reasons" &&
												<span>
													- Potvrzení lékaře o pracovní neschopnosti nebo Rozhodnutí o dočasné pracovní neshopnosti nebo Rozhodnutí o potřebě ošetřování (péče) nebo Potvrzení o trvání potřeby ošetřování (péče).
												</span>
											}
											{form.getField("serious_reasons").value == "study_stay_abroad" &&
												<span>
													- Potvrzení o studiu (s uvedením délky studia a oboru, názvu a adresy školy) a zároveň úřední překlad potvrzení do českého jazyka.
												</span>
											}
											{form.getField("serious_reasons").value == "damage_due_to_natural_disaster" &&
												<span>
													- Zápis škodní události od pojišťovny nebo vyjádření OSK o způsobených škodách.
												</span>
											}
											{form.getField("serious_reasons").value == "other" &&
												<span>
													- Relevantní doklad.
												</span>
											}
										</div>
									</div>
								</CheckBoxRadioFrame>

								<FormCheckBox
									form={form}
									field="third_declaration"
									className="mb-2"
								/>
							</div>
						}
						{(form.getField("multiple_declaration").value == "5_years_of_experience" && showWarning && false) &&
							<div>
								Vaše čestné prohlášení o době trvání nepřetržité praxe je v rozporu s dobou trvání Vašeho členství v ČSK. Prokázání splnění povinné praxe je nutné - přiložte relevantní doklady o trvání praxe v zahraničí (s uvedením názvu a adresy pracoviště, délky praxe a obor, jméno, telefon a e-mail kontaktní osoby pro ověření a poskytnutí referencí) a zároveň úřední překlad potvrzení do českého jazyka. Nebo máte-li závažný důvod, můžete požádat o její prominutí.
							</div>
						}
						{(form.getField("multiple_declaration").value == "serious_reasons" /*|| (form.getField("multiple_declaration").value == "5_years_of_experience" && showWarning)*/) &&
							<div>
								{model.formsFiles.get().map((formFile) =>
									<div className="row pl-0 mt-2">
										<div className="col-8 col-lg-10">
											<FormFileUpload
												fileUploadProps={{
													className: "mb-4"
												}}
												form={formFile}
												field="attachment"
											/>
										</div>
										<div className="col-4 col-lg-2">
											<Button className="d-block w-100 mt-4" onClick={() => model.removeFile(formFile)}>Odebrat</Button>
										</div>
									</div>
								)}
								<Button onClick={model.addFile} className="mb-5"><IconSvg name="plus" className="mr-3" /> Přidat další přílohu</Button>
							</div>
						}
						<div>ch) celoživotní vzdělávání v rozsahu nejméně 30 kreditů získaných za nejvýše 5 let bezprostředně předcházejících podání žádosti.</div>

						<div className="form-title mb-0">Kredity:</div>
						{credits.length == 0 &&
							<>
								Nebyly načteny žádné kredity.
							</>
						}
						{credits.length > 0 &&
							<div>
								<div>Níže zobrazujeme kredity, které evidujeme ve Vašich záznamech o vzdělávání a které jsou započitatelné pro účely této žádosti.
									Vyberte vzdělávací akci v hodnotě nejméně 30 kreditů, kredity za jednu akci nelze dělit.
									<span className="color-red">
										&nbsp;Doporučujeme jako první uplatnit nejstarší kredity a zbytečně nepřekračovat 30 kreditů - zbývající kredity (pokud nebudou starší 5 let) tak můžete využít při podání žádosti v budoucnu.
									</span>
								</div>
								<table className="table-designed mt-3">
									<thead>
										<tr>
											<th>Vybrat</th>
											<th>Popis</th>
											<th style={{ width: 140 }}>Datum</th>
											<th>Kredity</th>
										</tr>
									</thead>
									<tbody>
										{credits.map((i, index) =>
											<tr key={index}>
												<td className="text-center">
													<Checkbox
														checked={i.checked}
														className="web-request-issue-certificate-after-education-page-check mb-1 pb-0 pt-0 mt-1"
														onCheckedChanged={_ => model.toggleCredit(i)} />
												</td>
												<td>{i.description}</td>
												<td>{formatting.formatDate(i.date)}</td>
												<td className="text-center">
													{i.value}
												</td>
											</tr>
										)}
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td className="text-center font-weight-bold">
												{credits.reduce((pv, c) => c.checked ? (Number(pv) + Number(c.value)) : Number(pv) + 0, 0)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						}
					</>

				}
			</div>
			<div className="font-weight-bold mt-3">Beru na vědomí, že:</div>
			<div>a) tato žádost je zpoplatněna dle aktuálního usnesení sněmu ČSK ve výši 1 000 Kč,</div>
			<div>b) platnost vydaného osvědčení je 5 let.</div>
			{!props.id &&
				<div className="text-right">
					{/* <div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div> */}
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button
						theme="primary"
						onClick={model.confirm}
						spinner={model.common.justSending()}
					>Odeslat</Button>
				</div>
			}

		</WebRequest>
	);
}

export default state.bindContainers(
	WebRequestIssueCertificatePage,
	ctx => ctx.webRequestIssueCertificate
);
