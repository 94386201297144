/**
 *  Formulář detailu pracoviště
 */

import React from "react";

import * as state from "../../../lib/state";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";
import Page from "../../../components/templates/Page";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";
import Spinner from "../../../components/controls/Spinner";
import Checkbox from "../../../components/controls/Checkbox";

interface Props {
	id?: string;
}

function WorkplaceEditPage(props: Props) {
	const { workplaceEditPage: model } = state.useStateContext();
	const form = model.form;

	return (
		<Page
			title={"Detail pracoviště"}
			description=""
		>
			{form.getField("workplace") &&
				<>
					<div className="row mb-3">
						<div className="col-12 color-red"><b>Vyplnění níže uvedených údajů není povinné...</b></div>
					</div>
					<div className="row mb-2">
						<div className="col-12 col-sm-8 col-md-7">
							<span className="box-profile__value">
								{form.getField("workplace").value?.name}
							</span>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormsInput form={form} field="workplaceWeb" />
						</div>
					</div>
					{/* <div className="row">
						<div className="col-md-6">
							<FormsCheckBox
								form={form}
								field="workplaceAcceptsNewPatients"
							/>
						</div>
						<div className="col-md-6">
							Informace o přijmu nových pacientů se bude zobrazovat pouze po dobu 2 měsíců, poté bude automaticky z webu odstraněna. V případě zájmu o prodloužrní platnosti je třeba ji znovu aktualizovat.
						</div>
					</div> */}
					<div>
						<div>
							<div className="secondary-title">Smlouvy se zdravotními pojišťovnami</div>
							<CheckBoxRadioFrame className="mb-4">
								{
									(form.getField("workplaceInsuranceCompanies").value.length == 0 && model.loaded()) &&
									<div className="ml-3">Nejsou k dispozici žádné zdravcotní pojišťovny</div>
								}
								{model.justLoadingInsuranceCompanies() &&
									<div className="d-flex align-items-center">
										<Spinner small className="ml-3 mr-2" /> Nahrávám seznam zdravotních pojišťoven...
									</div>
								}
								{form.getField("workplaceInsuranceCompanies").value.length > 0 &&
									<div className="row">
										{form.getField("workplaceInsuranceCompanies").value.map(i =>
											<div className="col-md-4">
												<Checkbox
													title={
														<>
															<div>
																{i.name}
															</div>
														</>}
													checked={i.checked}
													readOnly={!form.getField("workplace").value?.editable}
													onCheckedChanged={_ => model.toggleInsuranceCompany(i)} />
											</div>
										)}
									</div>
								}
							</CheckBoxRadioFrame>
						</div>
					</div>

					<div className="text-right">
						{/* <div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div> */}
						{
							(form.getField("workplace").value?.editable) &&
							<Button theme="primary" onClick={model.confirm}>Uložit</Button>
						}
					</div>
				</>
			}
		</Page >
	);
}



export default state.bindContainers(
	WorkplaceEditPage,
	c => c.workplaceEditPage
);