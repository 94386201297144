import React, { useEffect } from "react";

export interface MapProps {
	title?: string;
	zoom?: number;
	latitude: number;
    longitude: number;
}

export default function Map(props: MapProps) {
	useEffect(() => {
		if ((window as any).Loader === undefined) {
			const script = document.createElement("script");
			script.src = "https://api.mapy.cz/loader.js";
			document.head.append(script);

			script.onload = () => {
				(window as any).Loader.async = true;
				createMap();
			};
		} else {
			createMap();
		}
	}, []);

	function createMap() {
		const w = window as any;
		w.Loader.load(null, null, () => {
			const w = window as any;

			const souradnice = w.SMap.Coords.fromWGS84(props.longitude,props.latitude);

			var m = new w.SMap(w.JAK.gel("map"), souradnice, props.zoom ?? 13);
			m.addDefaultLayer(w.SMap.DEF_BASE).enable();

			const mouseControl = new w.SMap.Control.Mouse(w.SMap.MOUSE_PAN);
			m.addControl(mouseControl);

			var zoomControl = new w.SMap.Control.Zoom([], { showZoomMenu: false });
			m.addControl(zoomControl, { right: "8px", top: "9px" });

			var scaleControl = new w.SMap.Control.Scale();
			m.addControl(scaleControl, { left: "8px", bottom: "30px" });


			const vrstvaZnacek = new w.SMap.Layer.Marker();
			m.addLayer(vrstvaZnacek).enable();

			const znacka = new w.SMap.Marker(souradnice, null, {
				url: "/images/map-mark.png",
				title: props.title,
				anchor: { left: 24, bottom: 1 }  /* Ukotvení značky za bod uprostřed dole */
			});
			vrstvaZnacek.addMarker(znacka);
		});
	}

	return <div id="map" className="mapy-cz-map"></div>;
}