/**
 * O nas - Jednání sněmu
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

const PAGE_SIZE = 20;

/**
 * Stav modelu
 */
interface State {
	councils: api.ListResponse<apiDynamicLists.Item>;
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			councils: {
				data: [],
				pagination: {
					per_page: PAGE_SIZE,
					object_count: 0,
					page: 1
				},
				message: null
			},
			justLoading: false
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Indikace načítání seznamu
	 */
	justLoading = () => this.stateContainer.get().justLoading;

	/**
	 * Načte data
	 */
	loadData = async (page: number) => {
		const councils = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiDynamicLists.standard.loadList({
				sort_fields: ["order_number"],
				filter: [api.qp("tagsname" as any, "=", ["jednani-snemu"])],
				page: page,
				per_page: PAGE_SIZE
			}),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(_ => ({
			councils
		}));
	}

	/**
	 * Vrací seznam sněmů
	 */
	getCouncils = () => {
		return this.stateContainer.get().councils;
	}
}