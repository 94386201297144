/**
 * Detail knihy e-shopu
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as apiShop from "../../shared/shop/api-shop";
import * as context from "../../../context";

/**
 * Stav modulu
 */
interface State {
	justLoadingItem: boolean;
	item?: apiShop.ShopItem;
	count: number;
}

/**
 * Model stránky
 */
export class Model {
	public stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoadingItem: false,
			count: 1
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Vrací položku zboží
	 */
	getItem = () => {
		return this.stateContainer.get().item;
	}

	/**
	 * Indikace načítání
	 */
	justLoadingItem = () => {
		return this.stateContainer.get().justLoadingItem;
	}

	/**
	 * Nastaví množství zboží pro přidání do košíku
	 */
	setCount = async (count: string) => {
		let countNumber = Number(count);
		if (isNaN(countNumber)) {
			return;
		}

		if (countNumber < 1) {
			countNumber = 1;
		}

		await this.stateContainer.merge(_ => ({
			count: countNumber
		}));
	}

	/**
	 * Vrací množství zboží pro přidání do košíku
	 */
	getCount = () => {
		return this.stateContainer.get().count;
	}

	/**
	 * Přidá položku do košíku
	 */
	addToCart = async () => {
		const item = this.stateContainer.get().item;
		if (!item) {
			return;
		}

		const count = this.stateContainer.get().count;
		const sucess = await this.context.shopCart.addItem(item, count);

		if (sucess) {
			await this.stateContainer.merge(_ => ({
				count: 1
			}));
		}
	}

	/**
	 * Načte data modulu
	 */
	loadData = async (id: api.Id) => {
		const item = await this.context.apiShop.items.load(id);
		await this.stateContainer.merge(_ => ({
			item: item,
			count: 1
		}));
	}
}