/**
 *  Provoz praxe v době COVIDu
 */
import HtmlContent from "../../../components/controls/HtmlContent";
import React from "react";

import Page from "../../../components/templates/Page";

export default function CoronavirusPracticeDuringCovidPage() {
	return (
		<Page title="Provoz praxe v době COVIDu" description="Provoz praxe v době COVIDu" authorized>
			<HtmlContent htmlKey="provoz-praxe-v-dobe-covidu-obsah" />
		</Page>
	);
}