/**
 * Obecná šablona pro (inline) seznam.
 * 
 * Zahrnuje pouze položky, které jsou různé od undefined, null a prázdného řetězce,
 * spojí je pomocí oddělovače (nebo mezery) a zařadí element před nebo za, pokud je
 * počet položek neprázdný.
 */

import * as React from "react";

interface JoinedListProps<Item> {
	items: (Item | undefined)[];
	itemTemplate: (item: Item) => React.ReactNode;
	beforeElement?: React.ReactNode;
	afterElement?: React.ReactNode;
	delimiter?: React.ReactNode;
}

export default function JoinedList<Item>(props: JoinedListProps<Item>) {
	const fullfilledItems = props.items.filter(i => i !== undefined && i !== null && (typeof i !== "string" || i.trim().length > 0)) as Item[];

	return (
		<>
			{props.beforeElement && fullfilledItems.length > 0 &&
				props.beforeElement
			}
			{fullfilledItems.map((item, index) =>
				<React.Fragment key={index}>
					{index > 0 &&
						props.delimiter ? props.delimiter : " "
					}
					{props.itemTemplate(item)}
				</React.Fragment>
			)}
			{props.afterElement && fullfilledItems.length > 0 &&
				props.afterElement
			}
		</>
	);
}