/*
 * Layout homepage stránky.
 */

import React, { useState, useEffect } from "react";

import * as state from "../../lib/state";

import PageHeader from "../sections/PageHeader";
import PageFooter from "../sections/PageFooter";
import LoginForm from "../sections/LoginForm";
import Accessibility from "../controls/Accessibility";
import Spinner from "../controls/Spinner";
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import Link from "../controls/Link";

export interface PageProps {
	title: React.ReactNode;
	description?: string;
	authorized?: boolean;
	children: React.ReactNode;
}

export default function Page(props: PageProps) {
	const stateContext = state.useStateContext();
	const { authorization, login, dialogs } = stateContext;
	const title = (authorizedToPage() ? (props.title ? props.title : "") : "Přihlášení") + " | ČSK";

	function googleAnalytics() {
		const script = document.createElement("script");
		script.src = "https://www.googletagmanager.com/gtag/js?id=G-2MJ0PRM79Q";
		script.async = true;
		document.body.appendChild(script);
		const myWindow = window as any;
		myWindow.dataLayer = myWindow.dataLayer || [];
		function gtag(value: any, value2: any) {
			dataLayer.push(arguments);
		}
		gtag("js", new Date());
		gtag("config", "G-2MJ0PRM79Q");
	}

	useEffect(() => {
		//resetCookieConsentValue("cookie-consent")
		const consent = getCookieConsentValue("cookie-consent");
		if (consent != "false") {
			googleAnalytics();
		}
		if (document.title != title) {
			document.title = title;
		}
	}, []);

	function authorizedToPage() {
		return !props.authorized || authorization.userLoggedIn();
	}

	return (
		<>
			<div>
				<title>{title}</title>
				<meta name="description" content={props.description} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={props.description} />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={props.description} />
				<meta property="og:image" content="" />
			</div>

			<CookieConsent
				cookieName="cookie-consent"
				onAccept={() => {
					googleAnalytics();
				}}
				onDecline={() => {
					(window as any)["ga-disable-G-2MJ0PRM79Q"] = true;
				}}
				buttonText="Souhlasím"
				enableDeclineButton
				declineButtonText="Nesouhlasím"
			>
				Abychom zlepšili Vaši zkušenost na našem webu, používáme výkonnostní a analytické cookies. V souladu s GDPR potřebujeme Váš souhlas. <Link style={{ color: "rgb(255, 212, 45)" }} to={"/zasady-ochrany-osobnich-udaju"}>Zásady ochrany osobních údajů</Link>
			</CookieConsent>

			<div className={"page" + (dialogs.isSomeDialogOpen() ? " page--modal-open" : "")}>
				<Accessibility />
				<PageHeader />
				<main role="main" id="main" className="main">
					<div className="row-main">
						{authorizedToPage() &&
							<>{props.children}</>
						}
						{!authorizedToPage() && !authorization.justLoggingIn() &&
							<LoginForm />
						}
						{authorization.justLoggingIn() &&
							<div className="d-flex align-items-center">
								<Spinner small className="mr-2" />Přihlašuji uživatele {login.loginForm.getField("username").value}...
							</div>
						}
					</div>
				</main>
				<PageFooter />
			</div>
		</>
	);
}