/**
 * Zubní lékaři - Seznam hostujících osob 
 */


import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import Card from "../../../components/controls/Card";
import HtmlContent from "../../../components/controls/HtmlContent";

function DentistsHostListPage() {
	const model = state.useStateContext().dentistsHostList;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("seznam-hostujicich-osob-nadpis")} description={contents.getRes("seznam-hostujicich-osob-nadpis")}>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="seznam-hostujicich-osob-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-1" />
				}
			</h1>
			<HtmlContent htmlKey="seznam-hostujicich-osob-obsah" />
			{model.getHostList().map(d =>
				<Card className="mb-4">
					<h2 className="mt-2">{d.title}</h2>
					<HtmlContent content={d.text} />
				</Card>
			)}
		</Page>
	);
}


export default state.bindContainers(
	DentistsHostListPage,
	c => c.dentistsHostList
);




