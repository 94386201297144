/**
 * Seznam mých akcí
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import Button from "../../../components/controls/Button";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormTextArea from "../../../components/controls/Forms/FormsTextArea";
import FormsRadio from "../../../components/controls/Forms/FormRadio";
import FormFileUpload from "../../system/file/FormFileUpload";
import * as myCategories from "./my-educational-page-category";
import Input from "../../../components/controls/Input";
import FormsAutocomplete from "../../../components/controls/Forms/FormsAutocomplete";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";

function MyEducationalPageCategory() {
	const { myEducationalCategory } = state.useStateContext();
	const form = myEducationalCategory.form;
	const main = form.getField("id_main_category").value;
	const sub = form.getField("id_category").value;
	const category = myCategories.Category;
	const hideDateFrom = !myEducationalCategory.categories(sub).dateFrom;
	const hideDateTo = !myEducationalCategory.categories(sub).dateTo;
	const hideQuantity = !myEducationalCategory.categories(sub).quantity;

	return (
		<Page
			title="Přidat neregistrovanou akci"
			description=""
			authorized
		>
			<h1>
				Celoživotní vzdělávání mimo vzdělávací programy Komory
			</h1>
			<div>
				Vyberte, pokud chcete zadávat vzdělávání pro účely evidence celoživotního vzdělávání mimo vzdělávací programy ČSK. Kredity budou započitatelné jen pro evidenci celoživotního vzdělávání mimo vzdělávací programy (např. pro vydání osvědčení odborného zástupce), nikoliv však pro účely získání navazujícího osvědčení PZL, CHIR, PARO, PEDO.
			</div>
			<div className="row">
				<div className="col">
					<CheckBoxRadioFrame className="mb-3">
						<FormsRadio
							form={form}
							field="id_main_category"
							option={1}
							title="FORMA 1 – OBECNÁ ČINNOST" />
						<FormsRadio
							form={form}
							field="id_main_category"
							option={2}
							title="FORMA 2 – VZDĚLÁVACÍ AKCE" />
						<FormsRadio
							form={form}
							field="id_main_category"
							option={3}
							title="FORMA 3 - ODBORNÁ STÁŽ" />
						<FormsRadio
							form={form}
							field="id_main_category"
							option={4}
							title="FORMA 4 - ODBORNÁ ČINNOST" />
						<FormsRadio
							form={form}
							field="id_main_category"
							option={5}
							title="FORMA 5 - E-LEARNING" />
						<FormsRadio
							form={form}
							field="id_main_category"
							option={6}
							title="FORMA 6 – ON-LINE VZDĚLÁVÁNÍ" />
						<FormsRadio
							form={form}
							field="id_main_category"
							option={7}
							title="FORMA 7 – SAMOSTUDIUM" />
					</CheckBoxRadioFrame>
				</div>
			</div>
			<div className="row">
				<div className="col">
					{main == 1 &&
						<CheckBoxRadioFrame className="mb-3">
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F1A}
								title="F1-A Odborná přednáška"
							/>
							<div className="my-educational-category-checkbox-radio">
								<div>
									Odborná přednáška v rozsahu nejméně 1 hodiny, neregistrovaná v systému celoživotního vzdělávání organizovaném Komorou, jejímž pořadatelem je Komora, Oblastní komora, stomatologická klinika, klinika zubního lékařství, klinika ústní, čelistní a obličejové chirurgie, akreditovaný poskytovatel, jemuž byla udělena akreditace I. typu, nebo odborná společnost, která splňuje podmínky vyplývající z pravidel spolupráce s odbornými společnostmi schválených představenstvem Komory.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Potvrzení účasti u odborných přednášek, jejichž organizátorem není Komora ani Oblastní komora.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									1 kredit za 1 hodinu trvání odborné přednášky, nejvýše však 2 kredity za 1 odbornou přednášku a 1 den. Kredity lze započítat pouze tehdy, zúčastnil-li se člen Komory celé akce.
								</div>
							</div>
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F1B}
								title="F1-B Stavovská činnost" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Účast na sněmu Oblastní komory, v jejímž seznamu členů je člen Komory zapsán, nebo účast na valné hromadě Komory.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Potvrzení účasti, jde-li o účast na valné hromadě Komory.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									1 kredit za účast na sněmu Oblastní komory, v jejímž seznamu členů je člen Komory zapsán, nebo na valné hromadě Komory. Kredity lze započítat pouze tehdy, zúčastnil-li se člen Komory celé akce.
								</div>
							</div>
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F1C}
								title="F1-C Členství v odborné společnosti" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Členství v odborné společnosti se sídlem v České republice nebo v zahraničí, která splňuje podmínky vyplývající z pravidel spolupráce s odbornými společnostmi schválených představenstvem Komory.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Potvrzení členství.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									1 kredit za členství v každé odborné společnosti za rok. Každou odbornou společnosti a každý rok je nutno zadat zvlášť jako samostatnou položku.
								</div>
							</div>
						</CheckBoxRadioFrame>
					}
					{main == 2 &&
						<div>
							<div>
								Registrované vzdělávací akce (vzdělávací formy F2-A, F2-B) zapisuje do záznamů o celoživotním vzdělávání Kancelář ČSK na základě prezenčních listin dodaných pořadateli akcí. Zápis proto může trvat až 30 dnů od konání akce. Kancelář ČSK zapisuje i absolvování jednorázových vzdělávacích programů (forma F2-C). Pokud jste absolvoval/a registrovanou vzdělávací akci, která není ve Vašich záznamech dosud uvedena, obraťte se na vzdělávací středisko.
							</div>
							<CheckBoxRadioFrame className="mb-3">
								<FormsRadio
									form={form}
									field="id_category"
									option={category.F2D}
									title="F2-D Neregistrovaná vzdělávací akce" />
								<div className="my-educational-category-checkbox-radio">
									<div>
										Vzdělávací akce s medicínskou tematikou neregistrovaná v systému celoživotního vzdělávání organizovaném Komorou v rozsahu nejméně 2 hodin.
									</div>
									<div className="font-weight-bold">
										Doklad:
									</div>
									<div>
										Potvrzení účasti.
									</div>
									<div className="font-weight-bold">
										Hodnota:
									</div>
									<div>
										2 kredity za 1 den vzdělávací akce. Kredity za 1 den vzdělávací akce lze započítat pouze tehdy, zúčastnil-li se člen Komory vzdělávací akce po celý den, za který se kredity započítávají. Za absolvování vzdělávacích akcí v jiných medicínských oborech než v oboru zubní lékařství lze započítat nejvýše 15 kreditů za 5 let.
									</div>
								</div>
							</CheckBoxRadioFrame>
						</div>
					}
					{main == 3 &&
						<>
							<div>
								Stáže uskutečněné na pracovištích akreditovaných Komorou zapisuje do záznamů o celoživotním vzdělávání Kancelář ČSK na základě prezenčních listin dodaných pořadateli stáží. Zápis proto může trvat až 30 dnů od konání stáže. Pokud jste absolvoval/a stáž na pracovišti akreditovaném Komorou, která není ve Vašich záznamech dosud uvedena, obraťte se na vzdělávací středisko.
							</div>
							<CheckBoxRadioFrame className="mb-3">
								<FormsRadio
									form={form}
									field="id_category"
									option={category.F3A}
									title="F3-A Odborná stáž" />
								<div className="my-educational-category-checkbox-radio">
									<div>
										Odborná stáž u akreditovaného poskytovatele včetně poskytovatelů akreditovaných Komorou nebo na zahraničním univerzitním pracovišti v Evropské unii v délce nejméně 5 hodin denně.
									</div>
									<div className="font-weight-bold">
										Doklad:
									</div>
									<div>
										Potvrzení účasti.
									</div>
									<div className="font-weight-bold">
										Hodnota:
									</div>
									<div>
										10 kreditů za 1 den účasti.
									</div>
								</div>
							</CheckBoxRadioFrame>
						</>
					}
					{main == 4 &&
						<CheckBoxRadioFrame className="mb-3">
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F4A}
								title="F4-A Přednášková činnost" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Vlastní přednášková činnost (včetně posterů) na registrovaných vzdělávacích akcích či významných vzdělávacích akcích nebo vedení webináře Komory.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Vlastní záznam se stručným obsahem, datem a místem konání akce.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									5 kreditů za uskutečněnou přednášku (poster) nebo webinář.
								</div>
							</div>
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F4C}
								title="F4-C Výzkumná činnost" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Výzkumná činnost se vztahem k zubnímu lékařství.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Závěrečná zpráva z výzkumu.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									20 kreditů.
								</div>
							</div>
							<FormsRadio
								form={form}
								field="id_category"
								option={11}
								title="F4-D Doktorské studium" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Absolvování akreditovaného doktorského studijního programu v zubním lékařství.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Vysokoškolský diplom o absolvování doktorského studijního programu.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									20 kreditů.
								</div>
							</div>
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F4E}
								title="F4-E Pregraduální výuka" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Systematická výuková činnost pro studenty zubního lékařství.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Potvrzení vysoké školy.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									2 kredity za každý rok, ve kterém člen Komory realizoval alespoň 3 přednášky pro studenty zubního lékařství, nejvýše však 10 kreditů za 5 let. Každou vysokou školu a každý rok výuky je nutno zadat zvlášť jako samostatnou položku.

								</div>
							</div>
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F4Ga}
								title="F4-G Publikační činnost (článek v odborném časopisu či e-learningový kurz - první autor)" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Odborná publikace v odborném časopisu medicínského zaměření a tvorba e-learningového kurzu v oboru zubní lékařství.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Vlastní záznam s přesnou identifikací publikace či kurzu.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									<div>15 kreditů pro prvního autora za každý článek v odborném časopisu či e-learningový kurz, které byly publikovány v průběhu posledních 5 let.</div>
								</div>
							</div>
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F4Gb}
								title="F4-G Publikační činnost (článek v odborném časopisu či e-learningový kurz - ostatní autor)" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Odborná publikace v odborném časopisu medicínského zaměření a tvorba e-learningového kurzu v oboru zubní lékařství.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Vlastní záznam s přesnou identifikací publikace či kurzu.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									<div>2 kredity pro každého z ostatních autorů za každý článek v odborném časopisu či e-learningový kurz, které byly publikovány v průběhu posledních 5 let.</div>
								</div>
							</div>
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F4Gc}
								title="F4-G Publikační činnost (autorství nebo spoluautorství monografie v oboru zubní lékařství)" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Odborná publikace monografie v oboru zubní lékařství.
								</div>
								<div className="font-weight-bold">
									Doklad:
								</div>
								<div>
									Vlastní záznam s přesnou identifikací monografie.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									<div>30 kreditů za autorství nebo spoluautorství monografie publikované v průběhu posledních 5 let.</div>
								</div>
							</div>
						</CheckBoxRadioFrame>
					}
					{main == 5 &&
						<>
							<div>
								Vzdělávací kurzy vytvořené a distribuované prostřednictvím informačních a komunikačních technologií registrované Komorou.
							</div>
							<CheckBoxRadioFrame className="mb-3">
								<FormsRadio
									form={form}
									field="id_category"
									option={category.F5A}
									title="F5-A Registrované e-learningové vzdělávací kurzy" />
								<div className="my-educational-category-checkbox-radio">
									<div className="font-weight-bold">
										Doklad:
									</div>
									<div>
										Potvrzení o úspěšně složeném testu z e-learningového kurzu na vzdělávacím portálu ČSK, nestanoví-li představenstvo Komory při registraci kurzu jinak. Registrované e-learningové kurzy (vzdělávací forma F5-A – poznají se podle toho, že mají přidělen kód) zapisuje do záznamů o celoživotním vzdělávání Kancelář ČSK na základě informací dodaných pořadateli akcí. Zápis proto může trvat až 30 dnů od konání akce. Pokud jste absolvoval/a registrovaný e-learningový kurz, která není ve Vašich záznamech dosud uvedena, obraťte se na vzdělávací středisko.
									</div>
									<div className="font-weight-bold">
										Hodnota:
									</div>
									<div>
										2 kredity za úspěšně složený test e-learningového kurzu, nestanoví-li představenstvo Komory při registraci kurzu jiný počet kreditů, nejvýše však 10 kreditů za 5 let.
									</div>
								</div>
								<FormsRadio
									form={form}
									field="id_category"
									option={category.F5B}
									title="F5-B Neregistrované e-learningové vzdělávací kurzy" />
								<div className="my-educational-category-checkbox-radio">
									<div>
										Vzdělávací kurzy v oboru zubní lékařství vytvořené a distribuované prostřednictvím informačních a komunikačních technologií neregistrované Komorou.
									</div>
									<div className="font-weight-bold">
										Doklad:
									</div>
									<div>
										Potvrzení o úspěšně složeném testu z e-learningového kurzu.
									</div>
									<div className="font-weight-bold">
										Hodnota:
									</div>
									<div>
										1 kredit za úspěšně složený test e-learningového kurzu, nejvýše však 5 kreditů za 5 let.
									</div>
								</div>
							</CheckBoxRadioFrame>
						</>
					}
					{main == 6 &&
						<CheckBoxRadioFrame className="mb-3">
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F6B}
								title="F6-B Neregistrovaná on-line vzdělávací akce" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Teoretická vzdělávací akce s medicínskou tématikou v rozsahu nejméně 30 minut a nejvýše 2 hodin zpřístupněná účastníkům živým přenosem on-line za použití technologie, která umožňuje účastníkům vzdělávací akce klást přednášejícímu dotazy alespoň formou textové zprávy zpřístupněné současně přednášejícímu a všem ostatním účastníkům vzdělávací akce a která současně umožňuje pořadateli kontrolními dotazy či jiným způsobem ověřit, že účastník vzdělávací akce její živý přenos sleduje, a vést o tomto ověření věrohodné záznamy.
								</div>
								<div className="font-weight-bold">
									Způsob vykázání:
								</div>
								<div>
									Potvrzení účasti.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									1 kredit za absolvování celé vzdělávací akce. Za absolvování vzdělávacích akcí v jiných medicínských oborech než v oboru zubní lékařství lze započítat nejvýše 15 kreditů za 5 let.
								</div>
							</div>
						</CheckBoxRadioFrame>
					}
					{main == 7 &&
						<CheckBoxRadioFrame className="mb-3">
							<FormsRadio
								form={form}
								field="id_category"
								option={category.F7A}
								title="F7-A Studium odborné literatury" />
							<div className="my-educational-category-checkbox-radio">
								<div>
									Četba odborných článků publikovaných v odborných časopisech medicínského zaměření.
								</div>
								<div className="font-weight-bold">
									Způsob vykázání:
								</div>
								<div>
									Čestné prohlášení.
								</div>
								<div className="font-weight-bold">
									Hodnota:
								</div>
								<div>
									2 kredity za 5 let.
								</div>
							</div>
						</CheckBoxRadioFrame>
					}
				</div>
			</div>
			<div className="row mb-2">
				{hideDateFrom &&
					<div className="col-3">
						<FormsInput form={form} inputProps={{ type: "date" }} field={"date_from"} />
					</div>
				}
				{hideDateTo &&
					<div className="col-3">
						<FormsInput form={form} inputProps={{ type: "date" }} field={"date_to"} />
					</div>
				}
				{hideQuantity &&
					<div className="col-3">
						<FormsInput form={form} inputProps={{ type: "number" }} field={"quantity" as any} />
					</div>
				}
				{(sub == category.F1C || sub == category.F4E) &&
					<div className="col-3">
						<FormsInput form={form} inputProps={{ type: "number" }} field={"year" as any} />
					</div>
				}
				<div className="col-3">
					<label>Počet kreditů</label>
					<Input
						type="number"
						disabled={true}
						value={myEducationalCategory.getCredits()}
					/>
				</div>
			</div>
			{(sub == category.F1C || sub == category.F4E) &&
				<div className="row">
					<div className="col">
						<FormsAutocomplete
							form={form}
							field="company"
							autocompleteProps={{
								trigger: "onfocus",
								loadItems: [{ id: 0, name: "Odborná společnost" }] as any,
								getItemText: item => item?.name ?? "",
								getMenuItem: item => item?.name
							}}
						/>
					</div>
				</div>
			}
			<div className="row">
				<div className="col">
					<FormTextArea form={form} field="description" />
				</div>
			</div>
			{sub != category.F7A &&
				<>
					<div>Doklady</div>

					{myEducationalCategory.formsFiles.get().map(formAttachment =>
						<div className="row">
							<div className="col-8 col-lg-10">
								<FormFileUpload
									form={formAttachment}
									field="attachment"
									formItemOptions={{ hideTitle: true }}
									fileUploadProps={{ className: "mb-1 mb-lg-4" }}
								/>
							</div>
							<div className="col-4 col-lg-2">
								<Button onClick={() => myEducationalCategory.formsFiles.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
							</div>
						</div>
					)}
					<div className="mb-5">
						<Button onClick={myEducationalCategory.formsFiles.add}>Přidat doklad</Button>
					</div>
				</>
			}
			{sub == category.F7A &&
				<div className="row">
					<div className="col">
						<FormsCheckBox form={form} field="declaration" />
					</div>
				</div>
			}
			<div className="mt-4 text-center">
				<Button onClick={e => myEducationalCategory.save()} className="btn-primary float-right">
					<strong>Odeslat</strong>
				</Button>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	MyEducationalPageCategory,
	context => context.myEducationalCategory
);