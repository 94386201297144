/**
 * Publikace
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";


function PaymentGatewayResponsePage() {
	const model = state.useStateContext().paymentGatewayResponse;
	const paymentResult = model.getPaymemtConfirmResult();
	const title = model.justConfirms()
		? "Potvrzuji platbu"
		: "Potvrzení dokončeno";

	return (
		<Page title={title} description="">
			<h1 className="d-flex align-items-center">
				{title}
				{model.justConfirms() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			{paymentResult &&
				<HtmlContent content={paymentResult?.text} />
			}
		</Page>
	);
}

export default state.bindContainers(
	PaymentGatewayResponsePage,
	c => c.paymentGatewayResponse
);