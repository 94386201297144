/**
 *  Formulář žádosti o započítání kreditů za účast na neregistrované vzdělávácí akci nebo jiné formě vzdělávání nebo jiné odborné činnosti.
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormFileUpload from "../../system/file/FormFileUpload";
import Button from "../../../components/controls/Button";

interface Props {
	id: string
}

function WebRequestCreditCountingPage(props: Props) {
	const { webRequestCreditCounting } = state.useStateContext();

	const formEntry = webRequestCreditCounting.form;
	const model = state.useStateContext().webRequestCreditCounting;

	return (
		<WebRequest id={props.id} model={webRequestCreditCounting.common} title="ŽÁDOST O ZAPOČÍTÁNÍ KREDITŮ ZA ÚČAST NA NEREGISTROVANÉ VZDĚLÁVÁCÍ AKCI NEBO JINÉ FORMĚ VZDĚLÁVÁNÍ NEBO JINÉ ODBORNÉ ČINNOSTI." description="">

			<h2 className="form-title">Žádám o započítání kreditů za účast na níže uvedené vzdělávací akci (jiné formě vzdělávání či jiné odborné činnosti), která není registrovaná v systému celoživotního vzdělávání Komory.</h2>

			<div>
				<b>POZOR: <span className="color-red">TATO ŽÁDOST NENÍ URČENA PRO PŔÍPAD, ŽE POTŘEBUJETE ZAPSAT KREDITY ZA ÚČELEM ZÍSKÁNÍ OSVĚDČENÍ O SPLNĚNÍ PODMÍNEK PRO VÝKON FUNKCE ODBORNÉHO ZÁSTUPCE.</span></b>
				<div>
					V TAKOVÉM PŘÍPADĚ SI KREDITY ZA NEREGISTROVANÉ VZDÉLÁVACÍ AKCE ZAPISUJE ŽADATEL DO SVÝCH ELEKRONICKÝCH ZÁZNAMŮ O VZDĚLÁVÁNÍ SÁM zde: www.dent.cz  (Pro členy, Moje agenda, Vzdělávací akce, přidat záznam celoživotního vzdělávání)
				</div>
			</div>

			<h2 className="form-title">Vzdělávací akce</h2>

			<div className="row">
				<div className="col-md-8">
					<FormInput
						form={formEntry}
						field="name"
					/>
				</div>
				<div className="col-md-4">
					<FormInput
						inputProps={{ className: "mb-0" }}
						form={formEntry}
						field="place"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-8">
					<FormInput
						inputProps={{ type: "date" }}
						form={formEntry}
						field="date_from"
					/>
				</div>
				<div className="col-md-4">
					<FormInput
						inputProps={{ type: "date", className: "mb-0" }}
						form={formEntry}
						field="date_to"
					/>
				</div>
			</div>
			<div className="form-title secondary-title">K žádosti přiložte tyto přílohy:</div>
			<div className="row">
				<div className="col-md-12">
					<FormFileUpload
						form={formEntry}
						field="document_attachment"
					/>
				</div>

				<div className="col-md-12">
					<FormFileUpload
						form={formEntry}
						field="description_attachment"
					/>
				</div>
			</div>
			<h2 className="form-title">Poznámka</h2>
			<div>Nepoužívejte tuto žádost k započítání kreditů za sněmy OSK a e-learningové kurzy.</div>
			<div>Tyto kredity doložíte až při žádosti o osvědčení.</div>

			<h2 className="form-title">Čestné prohlášení žadatele</h2>
			<FormCheckBox
				form={formEntry}
				field="declaration"
			/>

			<br />

			<div className="row">
				<div className="col-md-4 col-lg-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={formEntry}
						field="submission_date"
					/>
				</div>
			</div>

			<p>
				<strong>Za zpracování této žádosti je stanoven poplatek ve výši {formEntry.getField("amount").value} Kč.</strong>
			</p>
			<p>
				Pokyn k úhradě poplatku obdržíte obratem po odeslání Vaší žádosti. Zaplatit můžete buď ihned platební kartou nebo bankovním převodem na základě předpisu, který Vám bude současně odeslán na Váš e-mail. Vaše žádost bude zpracována po připsání platby na účet ČSK.
			</p>
			<div className="text-right mt-5">
				<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
				<em>Odesláním potvrzuji správnost údajů</em><br /><br />
			</div>

			<Button onClick={model.confirm} className="float-right">Odeslat</Button>

		</WebRequest >
	);
}

export default state.bindContainers(
	WebRequestCreditCountingPage,
	c => c.webRequestCreditCounting
);