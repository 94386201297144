import * as api from "../../../lib/api";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

export interface EducationalProgramMember extends api.EntityReadBase {
	educational_program: apiCodeList.CodeList;
	educational_program_member_state: apiCodeList.CodeListSystem;
	date: Date;
}

export class Api {
	member: api.EntityApi<EducationalProgramMember, EducationalProgramMember>;

	constructor(private context: context.StateContext) {
		this.member = new api.EntityApi<EducationalProgramMember, EducationalProgramMember>({
			api: context.api,
			path: "/users/educational_programs"
		});
	}
}	