/**
 * GDPR
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";


export default function CookieInfoPage() {
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("zasady-ochrany-osobnich-udaju-nadpis")} description={contents.getRes("zasady-ochrany-osobnich-udaju-nadpis")}>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="zasady-ochrany-osobnich-udaju-nadpis" />
			</h1>
			<HtmlContent htmlKey="zasady-ochrany-osobnich-udaju-obsah" />
		</Page>
	);
}