/**
 *  Formulář žádosti o vydání osvědčení po absolvování navazujícího vzdělávacího programu.
 */

import React from "react";


import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";
import FormFileUpload from "../../system/file/FormFileUpload";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormTextArea from "../../../components/controls/Forms/FormsTextArea";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import Checkbox from "../../../components/controls/Checkbox";

import * as state from "../../../lib/state";
import { formatDate } from "../../../lib/formatting";

interface WebRequestIssueCertificateAfterEducationPageProps {
	id?: string;
}

function WebRequestIssueCertificateAfterEducationPage(props: WebRequestIssueCertificateAfterEducationPageProps) {
	const { webRequestIssueCertificateAfterEducation: model } = state.useStateContext();
	const form = model.form;
	const credits = form.getField("credits").value;

	return (
		<WebRequest id={props.id} model={model.common} title="ŽÁDOST O VYDÁNÍ OSVĚDČENÍ PO ABSOLVOVÁNÍ NAVAZUJÍCÍHO VZDĚLÁVÁCÍHO PROGRAMU" description="">

			<h2 className="form-title mb-5">Žádám o vydání osvědčení po absolvování navazujícího vzdělávacího programu.</h2>

			{model.getCertifications().length > 1 &&
				<FormSelect
					className="mt-5"
					form={form}
					field="certificate"
					options={model.getCertifications().map(i => ({ title: i.name, value: i.id }))}
				/>

			}
			{model.getCertifications().length == 1 &&
				<h2 className="text-danger font-weight-bold">Není k dispozici žádné osvědčení</h2>
			}

			<div className="form-title secondary-title">Poučení:</div>

			<p>Pro úspěšné absolvování navazujícího vzdělávacího programu v praktickém zubním lékařství je třeba za účast na vzdělávacích akcích a na jiných formách vzdělávání získat alespoň 50 kreditů. Započítat lze jen kredity získané nejvýše 3 roky před podání této žádosti. Chcete-li však, aby platnost nového osvědčení bezprostředně navazovala na Vaše předcházející osvědčení, je třeba celých 50 kreditů získat za účast na vzdělávacích akcích konaných v průběhu platnosti Vašeho Předcházejícího osvědčení.</p>

			<p>Pro úspěšné absolvování navazujícího vzdělávacího programu ve stomatochirurgii, parodontologii i pedostomatologii je třeba za účast na vzdělávacích akcích
				a na jiných formách vzdělávání získat nejméně 50 kreditů a za účast na stážích na příslušných pracovištích akreditovaných poskytovatelů dalších nejméně 50
				kreditů. Kromě toho je pro úspěšné absolvování navazujícího vzdělávacího programu ve stomatochirurgii, parodontologii i pedostomatologii potřeba absolvovat též
				předepsaný kurz kardiopulmonální resuscitace a řešení neodkladných stavů. Započítat lze jen kredity získané za kurz absolvovaný nejvýše 5 let před dnem podání
				této žádosti. Chcete-li však, aby platnost nového osvědčení bezprostředně navazovala na Vaše předcházející odvědčení, je třeba kurz kardiopulmonální resuscitace a
				řešení neodkladných stavů absolvovat a celých 100 kreditů získat za účast na vzdělávacích akcích konaných v průběhu platnosti Vašeho předcházejícího osvědčení.
				Praktický kurz kardiopulmonální resuscitace musí být registrovaný v systému vzdělávání ČSK a musí být organizovaný pověřeným poskytovatelem.</p>

			<p>
				Součástí navazujícího vzdělávacího programu v praktickém zubním lékařství je výkon povolání zubního lékaře nejméně po dobu alespoň 2 let v průběhu posledních 3 let před podáním této žádosti, a to
				alespoň v rozsahu preventivní, diagnostické a léčebné činnosti v oblasti péče o zuby, ústa, čelisti a související tkáně.
			</p>

			<p>
				Součástí navazujícího vzdělávacího programu ve stomatochirurgii, parodontologii i pedostomatologii je výkon povolání zubního lékaře nejméně po dobu 5 let před
				podáním této žádosti, a to alespoň v rozsahu preventivní, diagnostické a léčebné činnosti ve stomatochirurgii, parodontologii, resp. pedostomatologii.
			</p>

			<h2 className="form-title">Vyberte, které kredity chcete použít</h2>

			<div className="color-red">
				<b>
					Pro  vydání osvědčení po absolvování navazujícího vzdělávacího programu, lze započítat za dané období pouze 15 kreditů za e-learningové akce.
				</b>
			</div>
			<div className="mt-3">
				Příloha č. 1 usnesení sněmu České stomatologické komory č. USN/2020/12/09 ze dne 12. 12. 2020, ve znění usnesení sněmu České stomatologické komory č. USN/2021/11/21 ze dne 7. 11. 2021 (znění účinné od 8. 11. 2021)
				<div>
					<a href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fold.dent.cz%2Fwp-content%2Fuploads%2F2020%2F12%2Fusn-2020-12-09-ze-dne-12-12-2020-priloha-1-formy-vzdelavani.docx&wdOrigin=BROWSELINK"><b>usn-2020-12-09-ze-dne-12-12-2020-priloha-1-formy-vzdelavani.docx (live.com)</b></a>
				</div>
			</div>
			{form.getField("certificate").value && credits.length > 0 &&
				<>
					<div className="form-title secondary-title">Kredity</div>
					<table className="table-designed">
						<thead>
							<tr>
								<th>Vybrat</th>
								<th>Popis</th>
								<th style={{ width: 140 }}>Datum</th>
								<th>Kredity</th>
							</tr>
						</thead>
						<tbody>
							{credits.map((i, index) =>
								<tr key={index}>
									<td className="text-center">
										<Checkbox
											checked={i.checked}
											className="web-request-issue-certificate-after-education-page-check mb-1 pb-0 pt-0 mt-1"
											onCheckedChanged={_ => model.toggleCredit(i)} />
									</td>
									<td>{i.description}</td>
									<td>{formatDate(i.date)}</td>
									<td className="text-center">
										{i.value}
									</td>
								</tr>
							)}
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td className="text-center font-weight-bold">
									{credits.reduce((pv, c) => c.checked ? (Number(pv) + Number(c.value)) : Number(pv) + 0, 0)}
								</td>
							</tr>
						</tbody>
					</table>
				</>
			}
			<div className="form-title secondary-title">Jiné formy vzdělávání a odborné činnosti</div>

			<FormTextArea
				form={form}
				field="credits_for_other_forms"
			/>

			<div>Kredity za absolvované akce, které nemáte uvedené v kartě vzdělávání</div>
			{model.formsFiles.get().map((formFile, index) =>
				<div key={index} className="row">
					<div className="col-8 col-lg-10">
						<FormFileUpload
							fileUploadProps={{
								className: "mb-4"
							}}
							form={formFile}
							field="attachment"
							formItemOptions={{
								hideTitle: true
							}}
						/>
					</div>
					<div className="col-4 col-lg-2">
						<Button className="d-block w-100" onClick={() => model.removeFile(formFile)}>Odebrat</Button>
					</div>
				</div>
			)}

			<Button onClick={model.addFile} className="mb-5">Přidat přílohu</Button>

			<FormFileUpload
				form={form}
				field="index_attachment"
			/>

			<h2 className="form-title">Čestné prohlášení žadatele</h2>

			<FormCheckBox
				form={form}
				field="declaration"
				checkBoxProps={{ className: "mb-5" }}
			/>
			<p>
				<strong>Za zpracování této žádosti je stanoven poplatek ve výši {form.getField("amount").value} Kč</strong>.
			</p>
			<p>
				Pokyn k úhradě poplatku obdržíte obratem po odeslání Vaší žádosti. Zaplatit můžete ihned platební kartou nebo bankovním převodem
				na základě předpisu, který Vám bude současně odeslán na Váš e-mail.
				Vaše žádost bude zpracována po připsání platby na účet ČSK.
			</p>
			<div className="text-right mt-5">
				<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
				<em>Odesláním potvrzuji správnost údajů</em><br /><br />
			</div>
			{!props.id &&
				<div className="text-right">
					<Button
						theme="primary"
						onClick={model.confirm}
						spinner={model.common.justSending()}>

						Odeslat
					</Button>
				</div>
			}

		</WebRequest>
	);
}

export default state.bindContainers(
	WebRequestIssueCertificateAfterEducationPage,
	c => c.webRequestIssueCertificateAfterEducation
);