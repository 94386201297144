/**
 * Seznam zpráv
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiWorkplaces from "./api-workplaces";
import * as api from "../../../lib/api";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext, filter?: any) {
	return new lists.StandardList<apiWorkplaces.Workplace>({
		context: context,
		title: "Seznam pracovišť",
		quickFilterPlaceholder: "Vyhledejte záznam...",
		filterSystem: _ => {
			return filter;
		},
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiListWorkplaces.workplaces as any,
		sortingFields: [],
		grid: {
			minWidth: "1000px",
			columns: [
				{
					field: "detail" as any,
					type: "string",
					title: "Detail",
				},
				{
					field: "member_workplace_type" as any,
					type: "string",
					title: "Poskytovatel",
				},
				{
					field: "member_workplace_relationship" as any,
					type: "string",
					title: "Pracoviště / vztah",
				},
				{
					field: "workspace.regional_chamber.name" as any,
					type: "string",
					title: "Oblast",
				},
				{
					field: "address" as any,
					type: "string",
					title: "Adresa",
				},
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiWorkplaces.Workplace>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

	loadData = async () => {
		await this.list.loadWithResetPage();
	}
}