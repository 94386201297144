/**
 * Domovská stránka
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "./Dialog";
import Button from "../../../components/controls/Button";
import EnterPressArea from "../../../components/controls/EnterPressArea";
import HtmlContent from "../../../components/controls/HtmlContent";

function Request1Dialog() {
	const { standardDialogs } = state.useStateContext();
	const dialog = standardDialogs.request1Dialog;

	return (
		<Dialog dialog={dialog} limitedWidth>
			<EnterPressArea onEnter={dialog.close}>
				<h1 className="pb-1 text-center">Opravdu chcete pokračovat?</h1>
				<div className="text-center mb-3">
					ANO - pokračovat bez vstupu do VPPZL a bez možnosti získat nižší roční členský příspěvek v tomto a příštím roce.
				</div>
				<div className="text-center mb-3">
					NE - upravit žádost a vstoupit do VPPZL s možností získat nižší roční členský příspěvek v tomto a příštím roce.
				</div>
				<div className="mt-4 text-center">
					<Button onClick={e => standardDialogs.request1Dialog.close(true)} className="pt-2 pb-2 mr-3">
						<strong>ANO</strong>
					</Button>
					<Button onClick={e => standardDialogs.request1Dialog.close(false)} className="pt-2 pb-2">
						<strong>NE</strong>
					</Button>
				</div>
			</EnterPressArea>
		</Dialog >
	);
}

export default state.bindContainers(Request1Dialog,
	context => context.standardDialogs.request1Dialog
);