/**
 * Model žádosti o vydání prvního osvědčení
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as api from "../../../lib/api";
import * as formating from "../../../lib/formatting";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiFile from "../../system/file/api-file";

interface State {
	certificationTypes: apiCodeList.CodeListSystem[];
	requestReasons: apiCodeList.CodeListSystem[];
}

export interface FileFormFields {
	attachment?: apiFile.File;
}

interface FormData extends FormFields {
	attachments: FileFormFields[];
}

export interface FormFields {

	// Žádám o vydání prvního osvědčení
	certificate_type?: number;
	exam_date: string;
	declaration: boolean;
	submission_date: string;
	request_reason?: number;
	amount: number;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;
	public formsFiles: forms.FormCollection<FileFormFields>;

	constructor(private context: context.StateContext) {
		const current_date = formating.formatDate(new Date());

		this.stateContainer = new state.StateContainer<State>({
			certificationTypes: [],
			requestReasons: []
		}, context);

		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				certificate_type: {
					title: "Požadovaný typ osvědčení",
					onChange: (value, field, form) => this.loadRequestReasons(value!),
					defaultValue: 0,
					required: true,
					validate: value => value === 0 ? "Typ je povinné pole" : ""
				},
				exam_date: {
					title: "Datum vykonání profesní zkoušky",
					defaultValue: current_date,
					required: true,
					validate: value => !value ? "Datum je povinné pole" : ""
				},
				declaration: {
					title: "Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti, jsou pravdivé a úplné. Čestně prohlašuji, že jsem členem Komory, že jsem způsobilý/á k samostatnému výkonu povolání zubního lékaře, že mi nebylo pravomocně uloženo disciplinární opatření anebo že takové disciplinární opatření již bylo zahlazeno, že jsem nebyl/a pravomocně odsouzen/a za trestný čin spáchaný v souvislosti s výkonem povolání zubního lékaře anebo že se na mě hledí, jako bych nebyl/a odsouzen/a, a že nemám vůči Komoře nedoplatek na stanovených příspěvcích či poplatcích nebo na pokutě uložené pravomocným rozhodnutím v disciplinárním řízení.",
					defaultValue: false,
					required: true,
					validate: value => !value ? "Prohlášení je povinné pole" : ""
				},
				submission_date: {
					title: "Datum podání",
					defaultValue: current_date,
					readOnly: true
				},
				request_reason: {
					title: "Důvod žádosti",
					defaultValue: 0,
					required: true,
					validate: (value, field, form) => value === 0 ? "Typ je povinné pole" : ""
				},
				amount: {
					title: "",
					defaultValue: 0
				}
			}
		}, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Připojit soubor",
					defaultValue: undefined
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
	}

	addFile = () => this.formsFiles.add();

	removeFile = async (formFile: forms.Form<FileFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? this.formsFiles.remove(formFile)
			: undefined

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	loadCertificationTypes = async () => {
		const certificationsTypes = await this.context.apiCertificationTypes.loadList({});
		const data = certificationsTypes.data;
		data.unshift({ id: 0, name: "", code: "" })
		await this.stateContainer.merge(_ => ({
			certificationTypes: data
		}));
	}

	loadRequestReasons = async (id: number) => {
		const requestReasons = await this.context.api.loadList<apiCodeList.CodeListSystem>("/certificates/" + id + "/reasons/", {});
		const data = requestReasons.data;
		data.unshift({ id: 0, name: "", code: "" })
		await this.stateContainer.merge(_ => ({
			requestReasons: data
		}));
	}

	loadAmount = async () => {
		const amount = await this.context.api.get<number>("/request_types/" + "zadost-o-vydani-prvniho-osvedceni" + "/price/", {});
		await this.form.setField("amount", amount);
	}

	getCertificationTypes = () => {
		return this.stateContainer.get().certificationTypes;
	}

	getRequestReasons = () => {
		return this.stateContainer.get().requestReasons;
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			await this.common.send(data, "zadost-o-vydani-prvniho-osvedceni");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { attachments, ...formFields } = data;
			await this.form.setFields(data);
			await Promise.all([
				this.common.loadData(webRequestId),
				this.formsFiles.setFields(attachments),
				this.loadRequestReasons(data.certificate_type!),
				this.loadCertificationTypes(),
				this.loadAmount()
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadCertificationTypes(),
			this.loadAmount()
		]);
	}
}