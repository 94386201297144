import * as forms from "../../../lib/forms";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as navigation from "../../../lib/navigation";
import storage from "store";

interface State {
	redirectRoute: string;
}

interface LoginFields {
	username: string;
	password: string;
	save: boolean;
	fulltext: string;
}

export class Model {
	stateContainer: state.StateContainer<State>;
	loginForm: forms.Form<LoginFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			redirectRoute: ""
		}, context);

		this.loginForm = new forms.Form<LoginFields>({
			fields: {
				fulltext: {
					defaultValue: "",
					title: ""
				},
				username: {
					title: "IKČ nebo e-mail",
					defaultValue: storage.get("loginUsername") == undefined ? "" : storage.get("loginUsername"),
					validate: (value) => value.trim().length === 0 ? "Vyplňte IKČ nebo e-mail" : ""
				},
				password: {
					title: "Heslo",
					defaultValue: storage.get("loginPassword") == undefined ? "" : storage.get("loginPassword"),
					validate: (value) => value.trim().length === 0 ? "Vyplňte heslo" : ""
				},
				save: {
					title: "Zapamatovat",
					defaultValue: storage.get("loginUsername") && storage.get("loginPassword") ? true : false,
					onChange: (value) => {
						if (!value) {
							storage.set("loginUsername", "");
							storage.set("loginPassword", "");
						}
					}
				}
			}
		}, context);

	}

	setRedirectRoute = async (redirectRoute: string) => {
		await this.stateContainer.merge(_ => ({
			redirectRoute: redirectRoute
		}));
	}

	/**
	 * Provede přihlášení přes Link2stars
	 */
	doLogin = async () => {
		await this.loginForm.validate();
		if (this.loginForm.isValid()) {
			const userName = this.loginForm.getField("username").value;
			const password = this.loginForm.getField("password").value;
			const saved = this.loginForm.getField("save").value;

			try {
				const result = await this.context.authorization.login(userName, password);
				if (saved) {
					storage.set("loginUsername", userName);
					storage.set("loginPassword", password);
				}
				if (navigation.getCurrent() != "/prihlaseni" && navigation.getCurrent() != "" && navigation.getCurrent() != "/") {
					return;
				}
				const membershipTo = new Date(storage.get("membershipTo"));
				if (!result.is_member) {
					navigation.to("/inzerce");
					return;
				}
				if (result.is_osk && result.is_member) {
					navigation.to("/osk");
					return;
				}
				if (membershipTo < new Date()) {
					navigation.to("/moje-agenda/zadosti/zadost-o-obnoveni-zapisu-do-seznamu-clenu-csk");
					return;
				}
				if (navigation.getCurrent() === "/prihlaseni") {
					const redirectRoute = this.stateContainer.get().redirectRoute;
					if (redirectRoute !== "") {
						navigation.to(redirectRoute, { replaceHistory: true });
						await this.stateContainer.merge(_ => ({
							redirectRoute: ""
						}));
						return;
					}
					if (this.context.authorization.isOSKMember()) {
						navigation.to("/osk");
						return;
					}

					navigation.to("/aktuality-pro-cleny");
					return;
				}
				if (this.context.authorization.isOSKMember()) {
					navigation.to("/osk");
					return;
				}
				navigation.to("/aktuality-pro-cleny");
				return;

			} catch (error) {
				if (api.isOperationResponse(error)) {
					await this.loginForm.clearValidations();
				}
			}
		}
	}
}