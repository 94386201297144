import React, { useState } from "react";
import Input, { InputProps } from "../../../components/controls/Input";
import Button from "../../../components/controls/Button";

import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as state from "../../../lib/state";

export interface File {
	id: string;
	name: string;
}

export interface FileUploadProps {
	className?: string;
	accept?: string;
	value?: File;
	fallbackErrorMessage?: string;
	onChange?: (file?: File) => void;
}

function createHiddenInput() {
	const body = document.getElementsByTagName("body")[0];
	const input = document.createElement("input");
	input.type = "file";
	input.style.display = "none";
	body.appendChild(input);
	return input;
}

function removeHiddenInput(input: HTMLInputElement) {
	const body = document.getElementsByTagName("body")[0];
	body.removeChild(input);
}

export default function FileUpload(props: FileUploadProps) {

	const [justUploading, setJustUploading] = useState(false);
	const [uploadingFileName, setUploadingFileName] = useState("");
	const context = state.useStateContext();


	async function apiUpload(file: globalThis.File) {
		return context.apiFile.upload(file, { suppressErrorNotification: true });
	}

	async function uploadFile() {
		const input = createHiddenInput();
		input.accept = props.accept ?? "";
		input.onchange = async e => {
			const file = (e as any).target.files![0];
			let uploadResult: api.OperationResponse | undefined = undefined;
			try {
				uploadResult = await common.withIndication({
					start: async () => setUploadingFileName(file.name),
					indicateStart: async () => setJustUploading(true),
					finish: async () => { setJustUploading(false); setUploadingFileName(""); },
					exec: () => apiUpload(file)
				});
			} catch (err) {
				await context.standardDialogs.openInformationDialog((props.fallbackErrorMessage ?? "Soubor se nepodařilo nahrát.") + " " + api.getErrorMessage(err, "Neznámá chyba."));
			}
			removeHiddenInput(input);
			if (uploadResult && props.onChange) {
				props.onChange({
					id: uploadResult.id as string,
					name: file.name
				});
			}
		};
		input.click();
	}

	function clearFile() {
		if (props.onChange) {
			props.onChange(undefined);
		}
	}

	async function handleButtonClick() {
		if (props.value === undefined) {
			await uploadFile();
		} else {
			clearFile();
		}
	}

	function getFileName() {
		if (justUploading) {
			return uploadingFileName;
		} else {
			return props.value?.name ?? "";
		}
	}

	return (
		<div className={"file-upload " + (props.className ?? "")} >
			<Input className="file-upload__input" value={getFileName()} />
			<Button className="file-upload__button" disabled={justUploading} onClick={handleButtonClick}>
				{!justUploading && props.value === undefined &&
					<>Nahrát</>
				}
				{!justUploading && props.value &&
					<>Smazat</>
				}
				{justUploading &&
					<span className="spinner-border file-upload__spinner"></span>
				}
			</Button>
		</div>
	);
}