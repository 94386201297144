/*
 * Modul pro zobrazení notifikačních zpráv.
 */

import * as toastr from "toastr";
import * as common from "./common";

toastr.options.closeButton = true;
toastr.options.toastClass = "toast-customized";
toastr.options.positionClass = "toast-bottom-right";

/**
 * Zpráva o úspěšně provedené operaci.
 */
export function successMessage(message: string) {
	if (!common.serverExecution()) {
		toastr.success(message, "");
	}
}

/**
 * Zpráva o chybě.
 */
export function errorMessage(message: string) {
	if (!common.serverExecution()) {
		toastr.error(message, "");
	}
}

/**
 * Varování.
 */
export function warningMessage(message: string) {
	if (!common.serverExecution()) {
		toastr.warning(message, "");
	}
}

/**
 * Informační zpráva.
 */
export function infoMessage(message: string) {
	if (!common.serverExecution()) {
		toastr.info(message, "");
	}
}