/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

import Page from "../../../components/templates/Page";
import Card from "../../../components/controls/Card";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

function ExpertCard(props: { item: apiDynamicLists.Item }) {
	return (
		<div className="col-12 col-md-6 col-lg-4 mb-gutter">
			<Card className="contacts-experts-page__card">
				<strong>{props.item.title}</strong>
				<div>
					<HtmlContent content={props.item.text} />
				</div>
			</Card>
		</div>
	);
}

function ContactOrgansPage() {
	const model = state.useStateContext().contactOrgans;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("kontakty-organu-csk-nadpis")} description="" authorized>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="kontakty-organu-csk-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<HtmlContent htmlKey="kontakty-organu-csk-obsah" />
			<div className="mt-3">
				<div className="row align-items-stretch">
					{model.getSections().map(i =>
						<ExpertCard item={i} />
					)}
				</div>
			</div>
		</Page>
	);
}

export default state.bindContainers(
	ContactOrgansPage,
	c => c.contactOrgans
);