/**
 * Metodické pokyny
 */
import React from "react";
import * as state from "../../../lib/state";
import useExclusiveSwitch from "../../../hooks/exclusive-switch";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";
import CardExpandable from "../../../components/controls/CardExpandable";

function MethodologicalGuidelinesPage() {
	const cardSwitch = useExclusiveSwitch();
	const model = state.useStateContext().methodologicalGuidelines;

	return (
		<Page title="Metodické pokyny" description="">
			<h1>
				Metodické pokyny
			</h1>
			{/* <HtmlContent htmlKey="metodicke-pokyny-obsah" /> */}
			<div className="row">
				{model.getGuideLines().map((s, i) =>
					<div className="col-12">
						<CardExpandable
							open={cardSwitch.current === i.toString()}
							onToggle={() => cardSwitch.toggle(i.toString())}
							className="mb-gutter"
							cardTitle={<div className="mb-0 secondary-title">{s.title}</div>}
						>
							<HtmlContent content={s.text} />
						</CardExpandable>
					</div>
				)}
			</div>
		</Page >
	);
}

export default state.bindContainers(
	MethodologicalGuidelinesPage,
	c => c.methodologicalGuidelines
);