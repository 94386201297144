/**
 * Žádost o zařazení do vstupního vzdělávacího programu
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as apiFile from "../../system/file/api-file";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

export interface ScopeOfInsurances {
	limit: string;
	sublimit: string;
	annual_insurance: string;
	checked: boolean;
}

export interface FormFields {
	insurance_name: string;
	provider_address: string;
	health_services_address: string;
	representation: string;
	registration_number: string;
	identification_number: string;
	account_number: string;
	phone: string;
	mobile: string;
	email: string;
	mailing_address: string;
	associate_name: string;
	associate_birthdate: string;
	representative_name: string;
	representative_birthdate: string;
	insured?: boolean;
	insurer: string;
	declaration: boolean;
	insurance_policy_number: string;
	basic_insurance_coverage: boolean;
	scope_of_insurances: ScopeOfInsurances[];
	extended_insurance_coverage: boolean;
	adding_insurance?: boolean;
	expanded_insurence?: boolean;
	sublimit_increase?: boolean;
	total_selected_sublimit: string;
	successor_legal_entity?: boolean;
	provider_name: string;
	provider_residential_address: string;
	provider_health_services_address: string;
	provider_registration_number: string;
	provider_identification_number: string;
	provider_insurance_certificate_number: string;
	provider_end_date: string;
	date: string;
	signature: string;
	total_annual_premium: string;
	certificate_of_insurance: string;
	total: number;
}

interface FormData extends FormFields {
	attachments: FilesFormFields[];
}

/**
 * Definice polí souboru přílohy
 */
export interface FilesFormFields {
	file?: apiFile.File;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public commonModel: webRequest.Model;
	public formsAttachment: forms.FormCollection<FilesFormFields>;

	createFilesFormOptions = (required?: () => boolean): forms.FormOptions<FilesFormFields> => {
		return {
			fields: {
				file: {
					title: "",
					required: true,
					defaultValue: undefined,
					validate: value => value === undefined ? "Nahrajte prosím povinnou přílohu" : ""
				}
			}
		};
	}

	constructor(private context: context.StateContext) {
		this.commonModel = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				insurance_name: {
					title: "Titul, jméno a příjmení/ Název:",
					defaultValue: "",
					required: true,
					validate: value => !value ? "Titul, jméno a příjmení/ Název je povinné pole" : ""
				},
				certificate_of_insurance: {
					defaultValue: "",
					title: ""
				},
				total_annual_premium: {
					defaultValue: "",
					title: ""
				},
				provider_address: {
					title: "Adresa sídla/ bydliště (uveďte včetně PSČ):",
					defaultValue: "",
					required: true,
					validate: value => !value ? "Adresa sídla/ bydliště (uveďte včetně PSČ) je povinné pole" : ""
				},
				total: { defaultValue: 0, title: "" },
				health_services_address: {
					title: "Adresa místa poskytování zdravotních služeb (uveďte včetně PSČ):",
					defaultValue: "",
					required: true,
					validate: value => !value ? "Adresa místa poskytování zdravotních služeb (uveďte včetně PSČ)je povinné pole" : ""
				},
				representation: {
					title: "Zastoupená kým (jméno, příjmení, funkce) – vyplnit pouze u právnických osob:",
					defaultValue: ""
				},
				expanded_insurence: {
					defaultValue: undefined,
					title: "",
					required: true,
					validate: value => value == undefined ? "Výběr je povinné pole" : "",
					onChange: () => this.handleOnChange()
				},
				registration_number: {
					title: "IČ:",
					defaultValue: ""
				},
				identification_number: {
					title: "Rodné číslo:",
					defaultValue: ""
				},
				account_number: {
					title: "Bankovní spojení:",
					defaultValue: ""
				},
				phone: {
					title: "Telefon:",
					defaultValue: ""
				},
				mobile: {
					title: "Mobilní telefon:",
					defaultValue: ""
				},
				email: {
					title: "E-mail:",
					defaultValue: "",
					required: true,
					validate: value => !value ? "E-mail je povinné pole" : ""
				},
				mailing_address: {
					title: "Korespondenční adresa (liší-li se od adresy sídla/ bydliště):",
					defaultValue: ""
				},
				associate_name: {
					title: "Titul, jméno a příjmení:",
					defaultValue: ""
				},
				associate_birthdate: {
					title: "Datum narození:",
					defaultValue: ""
				},
				representative_name: {
					title: "Titul, jméno a příjmení:",
					defaultValue: ""
				},
				representative_birthdate: {
					title: "Datum narození:",
					defaultValue: ""
				},
				insured: {
					title: "",
					defaultValue: undefined,
					required: true,
					validate: value => value == undefined ? "Výběr je povinné pole" : ""
				},
				declaration: {
					title: "",
					defaultValue: false
				},
				insurance_policy_number: {
					title: "Číslo pojistné smlouvy dřívějšího pojištění u Kooperativy: ",
					defaultValue: ""
				},
				basic_insurance_coverage: {
					title: "",
					defaultValue: false
				},
				scope_of_insurances: {
					title: "",
					onChange: () => this.handleOnChange(),
					defaultValue: this.defaultValue()
				},
				extended_insurance_coverage: {
					title: "",
					defaultValue: false
				},
				adding_insurance: {
					title: "",
					defaultValue: undefined,
					required: true,
					validate: value => value == undefined ? "Výběr je povinné pole" : "",
					onChange: () => this.handleOnChange()
				},
				sublimit_increase: {
					title: "",
					defaultValue: undefined,
					required: true,
					validate: value => value == undefined ? "Výběr je povinné pole" : ""
				},
				total_selected_sublimit: {
					title: "",
					defaultValue: "",
					onChange: () => this.handleOnChange(),
					validate: (value, field, form) => form.getField("sublimit_increase").value ?
						(!value ? "Částka musí být vyšší než 0 Kč" :
							(Number(value) > 5000000) ? "Max. celková výše sublimitu je 5.000.000 Kč" :
								(Number(value) % 50000 === 0) ? "" : "Částka musí být dělitelná 50.000 Kč") : ""
				},
				successor_legal_entity: {
					title: "",
					defaultValue: undefined,
					required: true,
					validate: value => value == undefined ? "Výběr je povinné pole" : ""
				},
				provider_name: {
					title: "Titul, jméno a příjmení:",
					defaultValue: ""
				},
				provider_residential_address: {
					title: "Adresa bydliště:",
					defaultValue: ""
				},
				provider_health_services_address: {
					title: "Adresa zdravotnického zařízení (místa poskytování zdravotních služeb): ",
					defaultValue: ""
				},
				provider_identification_number: {
					title: "Rodné číslo:",
					defaultValue: ""
				},
				provider_registration_number: {
					title: "IČ:",
					defaultValue: ""
				},
				provider_insurance_certificate_number: {
					title: "Potvrzení o pojištění číslo:",
					defaultValue: ""
				},
				provider_end_date: {
					title: "Datum ukončení poskytování zdrav. služeb fyzickou osobou:",
					defaultValue: ""
				},
				date: {
					title: "Ke dni",
					defaultValue: "",
					required: true,
					validate: value => value.length == 0 ? "Datum je povinné pole" : ""
				},
				insurer: {
					title: "",
					defaultValue: ""
				},
				signature: {
					title: "Podpis zájemce o pojištění",
					defaultValue: "",
					readOnly: true
				},
			}
		}, context);
		this.formsAttachment = new forms.FormCollection(this.createFilesFormOptions(), context, this.form);
		this.formsAttachment.add();
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => {
		return [
			...this.commonModel.getStateContainers(),
			...this.form.getStateContainers(),
		];
	}

	defaultValue = () => {
		const item = [
			{
				checked: false,
				limit: "2 000 000",
				sublimit: "600 000",
				annual_insurance: "1 980",
			},
			{
				checked: false,
				limit: "3 000 000",
				sublimit: "800 000",
				annual_insurance: "2 868",
			},
			{
				checked: false,
				limit: "4 000 000",
				sublimit: "1 000 000",
				annual_insurance: "3 860",
			},
			{
				checked: false,
				limit: "5 000 000",
				sublimit: "1 200 000",
				annual_insurance: "4 632",
			},
			{
				checked: false,
				limit: "7 000 000",
				sublimit: "1 600 000",
				annual_insurance: "6 248",
			},
			{
				checked: false,
				limit: "10 000 000",
				sublimit: "2 000 000",
				annual_insurance: "8 620",
			},
		] as ScopeOfInsurances[];
		return item;
	}

	handleOnChange = async () => {
		const sum_insured = this.form.getField("scope_of_insurances").value.find(x => x.checked);
		const product_liability_insurance = this.form.getField("expanded_insurence").value;
		const movable_assets_liability_insurance = this.form.getField("adding_insurance").value;
		const non_property_damage_insurance = Number(this.form.getField("total_selected_sublimit").value);

		const item = {
			sum_insured: { value: Number(sum_insured?.limit.trim().replace(/\s+/g, '')) },
			product_liability_insurance: product_liability_insurance == undefined ? false : product_liability_insurance,
			movable_assets_liability_insurance: movable_assets_liability_insurance == undefined ? false : movable_assets_liability_insurance,
			non_property_damage_insurance: non_property_damage_insurance,
		};
		const result = await this.context.api.post("/insurances/total", item) as number;
		this.form.setField("total", result);
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsAttachment.get()
					.filter(form => form.getField("file").value !== undefined)
					.map(form => form.getFields())
			}
			let code = "prihlaska-k-profesnimu-pojisteni";
			await this.commonModel.send(data, code as any);
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	loadProfileData = async () => {
		if (this.form.getField("insurance_name").value.trim().length > 0) {
			return;
		}

		if (!this.context.authorization.userLoggedIn()) {
			return;
		}

		let userProfile = this.context.authorization.getUserProfile();
		if (!userProfile) {
			userProfile = await this.context.authorization.loadUserProfile();
		}
		await this.form.setFields({
			insurance_name: userProfile.full_name,
			phone: userProfile.profile.contact?.phone1,
			mobile: userProfile.profile.contact?.phone2,
			email: userProfile.profile.contact?.email1,
			provider_address: userProfile.profile.address?.print
		});
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.commonModel.loadData(webRequestId);
		const webRequest = this.commonModel.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			await Promise.all([
				this.form.setFields(data),
			]);
		}
		this.handleOnChange();
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.commonModel.reset(),
			this.loadProfileData(),
			this.handleOnChange()
		]);
	}
}