/**
 * Košík e-shopu
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import storage from "store";
import * as api from "../../../lib/api";
import * as apiShop from "./api-shop";
import * as context from "../../../context";

/**
 * Stav modulu
 */
interface State {
	justLoadingCart: boolean;
	cart: apiShop.Cart;
}

/**
 * Model stránky
 */
export class Model {
	public stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoadingCart: false,
			cart: {
				id: api.emptyGuid,
				items: [],
				sum: 0,
				total_price: 0,
				transport_price: 0,
				deleted: false
			}
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Načte košík
	 */
	loadCart = async () => {
		const authorized = this.context.authorization.userLoggedIn();
		// TODO: Do authInfo přidat id košíku asociovaného s uživatelem
		const cartId = authorized ? undefined : storage.get("cartId");
		const cart = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingCart: true })),
			exec: () => this.context.apiShop.loadOrCreateCart(cartId),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingCart: false }))
		});
		storage.set("cartId", cart.id);
		await this.stateContainer.merge(_ => ({
			cart: cart
		}));
	}

	/**
	 * Načte data modulu
	 */
	loadData = async () => {
		await this.loadCart();
	}

	/**
	 * Indikace načítání
	 */
	justLoadingCart = () => {
		return this.stateContainer.get().justLoadingCart;
	}

	/**
	 * Vrací košík
	 */
	getCart = () => {
		return this.stateContainer.get().cart;
	}

	/**
	 * Vrací true, pokud zamýšlené množství položek pro přidání nezvýší počet položek nad 7
	 */
	private checkItemsCount = async (cart: apiShop.Cart, count: number) => {
		if (cart.items.map(i => i.count).reduce((sum, i) => sum + i, 0) + count > 7) {
			await this.context.standardDialogs.openInformationDialog("Nelze objednat více než 7 knih.");
			return false;
		}

		return true;
	}

	/**
	 * Přidá množství dané položkoy do košíku
	 */
	addItem = async (item: apiShop.ShopItem | apiShop.ShopItemSimple, count: number): Promise<boolean> => {
		if (count < 1) {
			return false;
		}

		const cart = this.stateContainer.get().cart;

		if (!await this.checkItemsCount(cart, count)) {
			return false;
		}

		await this.context.apiShop.addToCart({
			cart_id: cart.id,
			item_id: item.id,
			count: count,
		});

		await this.context.standardDialogs.openInformationDialog("Kniha<br/><br/> <strong>" + item.name + "</strong><br/><br/> byla přidána do košíku.");

		await this.loadCart();

		return true;
	}

	/**
	 * Aktualizuje množství dané položky
	 */
	updateItemCount = async (item: apiShop.CartItem, count: string): Promise<boolean> => {
		let c = count.trim().length === 0 ? 1 : parseInt(count);

		if (isNaN(c)) {
			return false;
		}

		if (c < 1) {
			c = 1;
		}

		const cart = this.stateContainer.get().cart;
		if (!await this.checkItemsCount(cart, c - item.count)) {
			return false;
		}

		await this.context.apiShop.updateItem({
			cart_id: cart.id,
			item_id: item.shop_item.id,
			count: c,
		});

		await this.loadCart();

		return true;
	}

	/**
	 * Vyjme položku z košíku
	 */
	removeItem = async (item: apiShop.CartItem): Promise<void> => {
		const cart = this.stateContainer.get().cart;

		await this.context.apiShop.updateItem({
			cart_id: cart.id,
			item_id: item.shop_item.id,
			count: 0
		});

		await this.loadCart();
	}
}