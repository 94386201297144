/**
 * Členové sněmu
 */
import React from "react";

import * as state from "../../../lib/state";

import Spinner from "../../../components/controls/Spinner";
import Page from "../../../components/templates/Page";
import Card from "../../../components/controls/Card";

import useExclusiveSwitch from "../../../hooks/exclusive-switch";

function AboutUsCouncilMembersPage() {
	const cardSwitch = useExclusiveSwitch();

	const model = state.useStateContext().aboutUsCouncilMembers;

	return (
		<Page title="Členové sněmu" description="">
			<h1 className="d-flex align-items-center">
				Členové sněmu
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<div className="row">
				{model.getMembers().map((s, i) =>
					<div className="col-12 col-md-6 col-lg-4">
						<Card className="mb-gutter">
							{s.title}
						</Card>
					</div>
				)}
			</div>

		</Page >
	);
}


export default state.bindContainers(
	AboutUsCouncilMembersPage,
	c => c.aboutUsCouncilMembers
);