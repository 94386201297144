/**
 * Harmonogram akcí ČSK
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function AgendaScheduleOfEventsPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("harmonogram-akci-csk-nadpis")} description={contents.getRes("harmonogram-akci-csk-nadpis")} authorized>
			<HtmlContent htmlKey="harmonogram-akci-csk-obsah" />
		</Page>
	);
}