/**
 * Domovská stránka
 */
import React, { useEffect, useState } from "react";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as navigation from "../../../lib/navigation";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";
import Button from "../../../components/controls/Button";

function MyEducationPage() {
	const app = state.useStateContext();
	const myProfile = app.authorization.getUserProfile()?.profile;
	const justLoading = app.authorization.loadingUserProfile();
	const { contents } = state.useStateContext();
	const [education, setEducation] = useState(null as any);

	useEffect(() => {
		setEducation(api.getUrlParam("education"));
	}, []);

	function handleKlikaciPovinnost() {
		const param = window.location.search;
		navigation.to("/klikaci-povinnost" + param.replace("education=false", "education=true"));
	}

	return (
		<Page title={contents.getRes("moje-vzdelani-nadpis")} description="" authorized>
			<div className="box-profile">
				<div className="anchor" id="profil" />
				<h2 className="form-title d-flex align-items-center">
					{justLoading &&
						<Spinner small className="mr-2" />
					}
					<HtmlContent htmlKey="moje-vzdelani-nadpis" />
				</h2>

				<div className="u-mb-lg">
					<div className="row mb-3">
						<div className="col-3">
							<span className="box-profile__label">
								Škola
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{myProfile?.school}
							</span>
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							<span className="box-profile__label">
								Datum ukončení studia
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{formatting.formatDate(myProfile?.date_of_graduation, { format: "year" })}
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__label">
								Atestace 1
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{myProfile?.certification_first}
							</span>
						</div>
					</div>
					<div className="row">
						{/*
						<div className="col-3">
							<span className="box-profile__label">
								Číslo diplomu
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{myProfile?.diploma_number}
							</span>
						</div>
						*/}
						<div className="col-3">
							<span className="box-profile__label">
								Atestace 2
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{myProfile?.certification_second}
							</span>
						</div>
					</div>
					<div className="row">
						{/*
						<div className="col-3">
							<span className="box-profile__label">
								Místo prom.
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{myProfile?.graduation_place}
							</span>
						</div>
						*/}
						<div className="col-3">
							<span className="box-profile__label">
								Specializace rok
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{myProfile?.specialization_year}
							</span>
						</div>
					</div>
					<div className="row">
						{/*
						<div className="col-3">
							<span className="box-profile__label">
								Odborná zaměření
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{myProfile?.professional_specializations}
							</span>
						</div>
						*/}
						<div className="col-3">
							<span className="box-profile__label">
								Specializace obor
							</span>
						</div>
						<div className="col-3">
							<span className="box-profile__value">
								{myProfile?.specialization_field}
							</span>
						</div>
					</div>
				</div>
				{education != null &&
					<div className="text-right">
						<Button theme="primary" onClick={() => handleKlikaciPovinnost()}>Kontrola provedena</Button>
					</div>
				}
			</div>
		</Page >
	);
}

export default state.bindContainers(
	MyEducationPage,
	context => context.myEducation
);