/*
 * API obrázků
 */
import * as context from "../../../context";

const imagesRoute = "/images";

export class Api {
	constructor(private context: context.StateContext) { }

	/**
	 * Vrátí URL obrázku.
	 */
	getImageUrl(id: string) {
		return this.context.api.options.endpoint + imagesRoute + "/" + id;
	}
}