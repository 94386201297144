/**
 * Domovská stránka
 */
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Label from "../../../components/controls/Label";
import List from "../../system/lists/List";

function OskWebRequestsPage() {
	const { oskWebRequests } = state.useStateContext();
	return (
		<Page
			title={oskWebRequests.list.getTitle()}
			description=""
			authorized
		>
			<h1>
				{oskWebRequests.list.getTitle()}
			</h1>
			<List
				list={oskWebRequests.list}
				itemNode={(item, field) => {
					switch (field) {
						case "request_state.name" as any: return item.request_state.id === 1 ? <Label>{eval("item." + field)}</Label> : undefined;
						case "number": return <Link to={"/moje-agenda/zadosti/" + item.request_type.url_web + "/" + item.id}>{item.number}</Link>;
						default: return undefined;
					}
				}}
			/>
		</Page>
	);
}

export default state.bindContainers(
	OskWebRequestsPage,
	context => context.oskWebRequests
);