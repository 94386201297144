/**
 * Změna hesla
 */
import React from "react";
import * as state from "../../lib/state";
import Page from "../templates/Page";
import Button from "../../components/controls/Button";
import FormsInput from "../controls/Forms/FormsInput";

function ChangePasswordPage() {
	const { changePassword: model } = state.useStateContext();
	const form = model.form;

	return (
		<Page
			title={"Změna hesla"}
			description=""
		>
			<form autoComplete="off" className="mb-3">
				<div className="row">
					<div className="col-md-12">
						<FormsInput
							form={form}
							inputProps={{ type: "password", autoComplete: "new-password" }}
							field="old_password"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<FormsInput
							form={form}
							inputProps={{ type: "password", autoComplete: "new-password" }}
							field="password"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<FormsInput
							form={form}
							inputProps={{ type: "password", autoComplete: "new-password" }}
							field="confirm_password"
						/>
					</div>
				</div>
			</form>
			<div className="text-right">
				<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
				<Button theme="primary" onClick={model.confirm}>Odeslat</Button>
			</div>
		</Page>
	);
}


export default state.bindContainers(
	ChangePasswordPage,
	c => c.changePassword
);