/**
 * Dlaždice
 */

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as formatting from "../../lib/formatting";

export interface TileProps {
	className?: string;
	number?: number;
	text: string;
	link?: string;
	emphasiz?: boolean;
}

export default function Tile(props: TileProps) {
	const tileContent = <>
		<div className={"tile__number"}>
			{props.number !== undefined ? formatting.formatWholeNumber(props.number) : <>&nbsp;</>}
		</div>
		<div className="tile__text">{props.text}</div>
	</>;

	const className = "tile " + (props.emphasiz ? "tile--emphasiz " : "") + (props.className ?? "");

	if (props.link !== undefined) {
		return <Link to={props.link} className={className}>{tileContent}</Link>;
	} else {
		return <div className={className}>{tileContent}</div>;
	}
}