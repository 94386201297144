/**
 *  Formulář oznámení o přerušení výkonu povolání
 */

import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import WebRequest from "../../../components/templates/WebRequest";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import CheckBox from "../../../components/controls/Checkbox";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";
import Spinner from "../../../components/controls/Spinner";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormFileUpload from "../../system/file/FormFileUpload";
import IconSvg from "../../../components/controls/IconSvg";

interface Props {
	id?: string;
}

function WebRequestJobInterruptPage(props: Props) {
	const title = "OZNÁMENÍ O PŘERUŠENÍ VÝKONU POVOLÁNÍ POUZE PRO OSVČ A PRÁVNICKÉ SUBJEKTY (přerušení poskytování zdravotních služeb v hlavní nebo hostující Oblastní komoře)";
	const { webRequestJobInterrupt: model } = state.useStateContext();
	const form = model.form;



	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p className="color-red font-weight-bold">Tato žádost je určena pouze pro OSVČ a právnické subjekty - netýká se zaměstnanců.</p>
			<p className="font-weight-bold">
				Oznamuji, že přerušuji výkon povolání na území zvolené Oblastní komory: <span className="form-item__required">*</span>
			</p>
			<div className="row">
				<div className="col-md-4 col-lg-3">
					<FormsInput
						form={form} field="date_from"
						inputProps={{ type: "date", min: formatting.formatDate(formatting.getDateBefore(new Date(), -14), { format: "iso" }) }} />
				</div>
				<div className="col-md-4 col-lg-3">
					<FormsInput
						form={form} field="date_to"
						inputProps={{ type: "date" }} />
				</div>
			</div>
			<p className="font-weight-bold">
				Vyberte poskytovatele nebo pracoviště, kde přerušujete výkon povolání:
			</p>
			<p className="font-weight-bold">
				Hlavní oblastní komora:
			</p>
			<FormCheckBox
				form={form} field="finish_membership_primary_chamber" enhancedTitle={
					<>
						<div>{(form.getField("workplace").value?.name)}</div>
						Hlavní oblastní komora {(form.getField("primary_chamber").value !== undefined ? ": " + form.getField("primary_chamber").value?.name : "")}
					</>
				} formItemOptions={{ hideTitle: true }} />
			<p>
				<strong>Poučení</strong><br />
				<small>
					Po tomto oznámení nebudete nadále moci vykonávat povolání na území hlavní Oblastní komory. <u className="text-danger font-weight-bold text-decoration-underline">Pokud výkon povolání znovu zahájíte, nebo chcete jako novou hlavní Oblastní komoru stanovit některou z Vašich hostujících Oblastních komor</u>, je třeba vyplnit Žádost o zápis do seznamu členů Oblastní komory, kde Vám na základě Vámi
					uvedeného nového poskytovatele a pracoviště bude určena nová hlavní Oblastní komora.
				</small>
			</p>
			<p className="font-weight-bold">
				Hostující oblastní komory:
			</p>
			<CheckBoxRadioFrame className="mb-4">
				{(form.getField("finish_membership_host_chambers").value.length == 0 && model.loadedHostChambers()) &&
					<div className="ml-3">Nejsou k dispozici žádné hostujici oblastni komory</div>
				}
				{model.justLoadingHostChambers() &&
					<div className="d-flex align-items-center">
						<Spinner small className="ml-3 mr-2" /> Nahrávám seznam oblastních komor a pracovišť...
					</div>
				}
				{form.getField("finish_membership_host_chambers").value.length > 0 && form.getField("finish_membership_host_chambers").value.map(i =>
					<div className="row">
						<div className="col-md-6">
							<CheckBox
								title={
									<>
										<div>
											{i.regional_chamber.name}
										</div>
										<div>
											<small>
												{[i.workplace.name, i.workplace.address?.street, i.workplace.address?.city].filter(i => i !== undefined).join(", ")}
											</small>
										</div>
									</>}
								checked={i.checked}
								onCheckedChanged={_ => model.toggleHostChamber(i)} />
						</div>
					</div>
				)}
			</CheckBoxRadioFrame>
			<p className="font-weight-bold">
				V případě přerušení poskytování zdravotních služeb přiložte rozhodnutí o přerušení k poskytování zdravotních služeb z KÚ nebo MHMP (v případě Prahy):
			</p>
			{model.formsFiles.get().map((formFile) =>
				<div className="row">
					<div className="col-8 col-lg-10">
						<FormFileUpload
							fileUploadProps={{
								className: "mb-4"
							}}
							form={formFile}
							field="attachment"
						/>
					</div>
					<div className="col-4 col-lg-2">
						<Button className="d-block w-100 mt-4" onClick={() => model.removeFile(formFile)}>Odebrat</Button>
					</div>
				</div>
			)}

			<Button onClick={model.addFile} className="mb-5"><IconSvg name="plus" className="mr-3" /> Připojit další soubor</Button>
			<p>
				<strong>Poučení</strong><br />
				<small>
					Po tomto oznámení nebude nadále v této hostující Oblastní komoře evidován/evidována
					a nemůžete na území této hostující Oblastní komory vykonávat povolání zubního lékaře.
				</small>
				<p className="mt-3">UPOZORNĚNÍ: V případě, že v souvislosti se změnou pracoviště měníte i adresu bydliště, nezapomeňte podat i Žádost o změnu kontaktních údajů.</p>
			</p>

			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestJobInterruptPage,
	c => c.webRequestJobInterrupt
);