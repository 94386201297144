/**
 * Žádost o vykonání opakované profesní zkoušky v rámci vstupního vzdělávacího programu
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as navigation from "../../../lib/navigation";
import * as webRequest from "../../shared/web-request/web-request";
import * as api from "../../../lib/api";
import * as apiFile from "../../system/file/api-file";

/**
 * Deklarace polí hlavního fomuláře
 */

interface State {
	amount: number;
	amountRepeat: number;
}

export interface ConsultantFields {
	attachment?: api.CodeBookItem;
	attachmentInterships: apiFile.FileFormFields[];
	date_from: string;
	date_to: string;
	consultant_title_before: string;
	consultant_first_name: string;
	consultant_last_name: string;
	consultant_full_name: string;
	consultant_id: string;
	consultant_ikc: string;
}

interface FormData extends FormFields {
	consultants: ConsultantFields[];
}

export interface FormFields {
	program_type: "vppzl" | "vpchir" | "vpparo" | "vppedo";
	confirmed_declaration: boolean;
	confirmed_children_care: boolean;
}
/**
 * Deklarace polí fomuláře péče o dítě
 */
export interface ChildFormFields {
	care_from: string;
	care_to: string;
}

/**
 * Definice fomuláře péče o dítě
 */
const formChildrenOptions: forms.FormOptions<ChildFormFields> = {
	fields: {
		care_from: {
			title: "Od",
			defaultValue: ""
		},
		care_to: {
			title: "Do",
			defaultValue: ""
		}
	}
};

/**
 * Data odesílaná a načítaná
 */
interface FormData extends FormFields {
	children_care: ChildFormFields[];
}

export interface FileFormFields {
	attachment?: apiFile.File;
}


/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public common: webRequest.Model;
	public form: forms.Form<FormFields>;
	public formChildren: forms.FormCollection<ChildFormFields>;
	public formsConsultants: forms.FormCollection<ConsultantFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			amount: 0,
			amountRepeat: 0
		}, context);
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				program_type: {
					title: "Druh vzdělávacího programu",
					defaultValue: "vppzl",
					onChange: () => this.loadAmount()
				},
				confirmed_declaration: {
					title: "Čestné prohlášení",
					defaultValue: false,
					required: true,
					validate: value => value ? "" : "Prosím, potvrďte čestné prohlášení"
				},
				confirmed_children_care: {
					title: "Čestně prohlašuji, že jsem v níže uvedené době soustavně osobně pečoval/a o dítě do 4 let věku.",
					defaultValue: false,
				}
			}
		}, context);

		this.formsConsultants = new forms.FormCollection<ConsultantFields>({
			fields: {
				attachment: {
					title: "",
					defaultValue: undefined,
					required: (form) => true,
					validate: (value, field, form) => !value && this.form.getField("program_type").value != "vppzl" ? "Prosím, vyplňte přílohu" : "",
				},
				attachmentInterships: {
					title: "",
					defaultValue: [],
				},
				date_from: {
					title: "Konzultantem od",
					defaultValue: ""
				},
				date_to: {
					title: "Konzultantem do",
					defaultValue: ""
				},
				consultant_title_before: {
					title: "Titul před",
					defaultValue: ""
				},
				consultant_first_name: {
					title: "Jméno",
					defaultValue: "",
				},
				consultant_last_name: {
					title: "Příjmení",
					defaultValue: "",
				},
				consultant_id: {
					defaultValue: "",
					title: ""
				},
				consultant_full_name: {
					title: "Jméno a příjmení konzultanta",
					defaultValue: "",
					readOnly: true
				},
				consultant_ikc: {
					title: "IKČ konzultanta",
					required: true,
					validate: (value, field, form) => !value && this.form.getField("program_type").value != "vppzl" ? "Prosím, vyplňte IKČ" : "",
					defaultValue: "",
					onChange: async (value, field, form) => {
						if (value.length == 8) {
							const member = await context.apiMembers.findMemberByIKC(value);
							if (member) {
								await form.setFields({
									consultant_first_name: member.first_name,
									consultant_last_name: member.last_name,
									consultant_title_before: member.title_before,
									consultant_full_name: member.full_name,
									consultant_id: member.id
								});
							}
						}
					},
				},
			}
		}, context, this.form);

		this.formsConsultants.add();
		this.formsConsultants.get()[0].getField("attachmentInterships").value.push({ attachment: undefined });

		this.formChildren = new forms.FormCollection<ChildFormFields>(formChildrenOptions, context, this.form);
		this.formChildren.add();


	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.common.getStateContainers(),
			...this.form.getStateContainers(),
		];
	}

	addConsultant = () => this.formsConsultants.add();

	removeConsultant = async (formDay: forms.Form<ConsultantFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Smazat konzultanta?") === "yes"
			? this.formsConsultants.remove(formDay)
			: undefined

	getAmount = () => {
		return this.stateContainer.get().amount;
	}

	getAmountRepeat = () => {
		return this.stateContainer.get().amountRepeat;
	}

	getProgramTypeName = () => {
		const program_type = this.form.getField("program_type").value;
		let value = "stomatochirurgii";
		if (program_type == "vpparo") {
			value = "parodontologii";
		}
		else if (program_type == "vppedo") {
			value = "pedostomatologii";
		}
		return value;
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				consultants: this.formsConsultants.get().map(f => f.getFields()),
				children_care: this.formChildren.get().map(i => i.getFields())
			};
			if (navigation.getCurrent().split("/").slice(-1).pop() === "zadost-o-vykonani-profesni-zkousky-vp") {
				await this.common.send(data, this.getCode(false));
			}
			if (navigation.getCurrent().split("/").slice(-1).pop() === "zadost-o-opakovani-profesni-zkousky-vp") {
				await this.common.send(data, this.getCode(true));
			}
		} else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	loadAmount = async () => {
		if (navigation.getCurrent().split("/").slice(-1).pop() === "zadost-o-vykonani-profesni-zkousky-vp") {
			const amount = await this.context.api.get<number>("/request_types/" + this.getCode(false) + "/price/", {});
			await this.stateContainer.merge(_ => ({
				amount: amount,
			}));
		}
		if (navigation.getCurrent().split("/").slice(-1).pop() === "zadost-o-opakovani-profesni-zkousky-vp") {
			const amountRepeat = await this.context.api.get<number>("/request_types/" + this.getCode(true) + "/price/", {});
			await this.stateContainer.merge(_ => ({
				amountRepeat: amountRepeat
			}));
		}
	}

	getCode = (repeat: boolean) => {
		let code = "zadost-o-" + (repeat ? "opakovani" : "vykonani") + "-profesni-zkousky-vppzl" as any;
		switch (this.form.getField("program_type").value) {
			case "vppzl":
				code = "zadost-o-" + (repeat ? "opakovani" : "vykonani") + "-profesni-zkousky-vppzl";
				break;
			case "vpchir":
				code = "zadost-o-" + (repeat ? "opakovani" : "vykonani") + "-profesni-zkousky-vpchir";
				break;
			case "vpparo":
				code = "zadost-o-" + (repeat ? "opakovani" : "vykonani") + "-profesni-zkousky-vpparo";
				break;
			case "vppedo":
				code = "zadost-o-" + (repeat ? "opakovani" : "vykonani") + "-profesni-zkousky-vppedo";
				break;
		}
		return code;
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { children_care, ...formFields } = data;
			this.common.loadData(webRequestId),
				await Promise.all([
					this.form.setFields(formFields),
					this.formChildren.setFields(children_care),
					this.loadAmount()
				]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadAmount()
		]);
	}
}