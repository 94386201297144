/**
 * Model oznámení o ukončení výkonu povolání
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as forms from "../../../lib/forms";
import * as formatting from "../../../lib/formatting";
import * as api from "../../../lib/api";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiMembers from "../../shared/members/api-members";

interface State {
	justLoadingHostChambers: boolean;
	justLoadingHostChambersOnlyEmergency: boolean;
	loadedHostChambers: boolean;
	loadHostChambersOnlyEmergency: boolean;
}

/**
 * Hostující komora ve výběru hostujících komor
 */
interface HostChamber extends apiMembers.VisitingRegionalChamber {
	checked: boolean;
}

interface FormData extends FormFields {
	attachments: FileFormFields[];
}

export interface FileFormFields {
	attachment?: api.CodeBookItem;
}

/**
 * Definice dat formuláře
 */
export interface FormFields {
	date_from: string;
	date_to: string;
	primary_chamber?: api.CodeBookItem;
	workplace?: api.CodeBookItem;
	finish_membership_primary_chamber: boolean;
	finish_membership_host_chambers: HostChamber[];
	finish_membership_host_chambers_only_emergency: HostChamber[];
	attachments: FileFormFields[];
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		date_from: {
			title: "Od",
			defaultValue: "",
			required: true,
			validate: value => !value ? "Datum je povinné pole" : formatting.getDateBefore(new Date(), -15) > new Date(value) ? "Datum nesmí být menší než 14 dní" : ""
		},
		date_to: {
			title: "Do",
			defaultValue: "",
			required: true,
			validate: value => !value ? "Datum je povinné pole" : ""
		},
		primary_chamber: {
			title: "Hlavní oblastní komora",
			defaultValue: undefined
		},
		workplace: {
			title: "Pracoviště",
			defaultValue: undefined
		},
		finish_membership_primary_chamber: {
			title: "",
			defaultValue: false,
		},
		finish_membership_host_chambers: {
			title: "Hostující oblastní komory",
			defaultValue: [],
		},
		finish_membership_host_chambers_only_emergency: {
			title: "Hostující oblastní komory - Jen pohotovost",
			defaultValue: [],
		},
		attachments: {
			title: "Příloha",
			defaultValue: [],
		}
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;
	public formsFiles: forms.FormCollection<FileFormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ justLoadingHostChambers: false, justLoadingHostChambersOnlyEmergency: false, loadHostChambersOnlyEmergency: false, loadedHostChambers: false }, context);
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined,
					required: true,
					validate: value => !value ? "Příloha je povinné pole" : ""
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	private createHostChamber(hostChamber: HostChamber) {
		return hostChamber;
	}

	loadPrimaryChamber = async (data?: any) => {
		let userProfile = this.context.authorization.getUserProfile();
		if (!userProfile) {
			userProfile = await this.context.authorization.loadUserProfile();
		}
		if (userProfile.profile.regional_chamber_main && !data) {
			await this.form.setField("primary_chamber", {
				id: userProfile.profile.regional_chamber_main.id,
				name: userProfile.profile.regional_chamber_main.name
			});
		}
		if (data && data.primary_chamber) {
			await this.form.setField("primary_chamber", {
				id: data.primary_chamber.id,
				name: data.primary_chamber.name
			});
		}

		if (userProfile.profile.workplace_main && !data) {
			await this.form.setField("workplace", {
				id: userProfile.profile.workplace_main.id,
				name: userProfile.profile.workplace_main.name
			});
		}
		if (data && data.workplace) {
			await this.form.setField("workplace", {
				id: data.workplace.id,
				name: data.workplace.name
			});
		}
	}

	loadRegionalChambers = async () => {
		const loadedChambers = await common.withIndication({
			start: () => this.stateContainer.merge(_ => ({ justLoadingHostChambers: true })),
			exec: () => this.context.apiMembers.loadVisitingRegionalChambersWithFilter(false),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingHostChambers: false, loadHostChambers: true }))
		});
		const loadedChambersOnlyEmergency = await common.withIndication({
			start: () => this.stateContainer.merge(_ => ({ justLoadingHostChambersOnlyEmergency: true })),
			exec: () => this.context.apiMembers.loadVisitingRegionalChambersWithFilter(true),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingHostChambersOnlyEmergency: false, loadHostChambersOnlyEmergency: true, loadedHostChambers: true }))
		});
		const hostChambers = loadedChambers.data.map(i => this.createHostChamber({ ...i, checked: false }));
		const hostChambersOnlyEmergency = loadedChambersOnlyEmergency.data.map(i => this.createHostChamber({ ...i, checked: false }));
		await this.form.setField("finish_membership_host_chambers", hostChambers);
		await this.form.setField("finish_membership_host_chambers_only_emergency", hostChambersOnlyEmergency);
	}

	addFile = () => this.formsFiles.add();

	removeFile = async (formFile: forms.Form<FileFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? this.formsFiles.remove(formFile)
			: undefined

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			data.finish_membership_host_chambers = data.finish_membership_host_chambers.filter(i => i.checked);
			data.finish_membership_host_chambers_only_emergency = data.finish_membership_host_chambers_only_emergency.filter(i => i.checked);
			await this.common.send(data, "zadost-o-preruseni-vykonu-povolani");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	toggleHostChamber = async (chamber: HostChamber) => {
		await this.form.setField(
			"finish_membership_host_chambers",
			this.form.getField("finish_membership_host_chambers").value.map(i => ({
				...i,
				checked: chamber.id === i.id ? !i.checked : i.checked
			})));
	}

	toggleHostChamberEmergency = async (chamber: HostChamber) => {
		await this.form.setField(
			"finish_membership_host_chambers_only_emergency",
			this.form.getField("finish_membership_host_chambers_only_emergency").value.map(i => ({
				...i,
				checked: chamber.id === i.id ? !i.checked : i.checked
			})));
	}

	justLoadingHostChambers = () => {
		return this.stateContainer.get().justLoadingHostChambers;
	}

	justLoadingHostChambersOnlyEmergency = () => {
		return this.stateContainer.get().justLoadingHostChambersOnlyEmergency;
	}

	loadedHostChambers = () => {
		return this.stateContainer.get().loadedHostChambers;
	}

	loadHostChambersOnlyEmergency = () => {
		return this.stateContainer.get().loadHostChambersOnlyEmergency;
	}

	/**
 * Načte data do formuláře 
 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { attachments, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.formsFiles.setFields(attachments),
				this.loadPrimaryChamber(data),
				this.loadRegionalChambers(),
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadPrimaryChamber(),
			this.loadRegionalChambers(),
		]);
	}
}