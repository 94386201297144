import * as React from "react";

interface AplphabetProps {
	current: string;
	onLetterClick: (letter: string) => void;
}

export default function Alphabet(props: AplphabetProps) {
	function onLetterClick(letter: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		e.preventDefault();
		props.onLetterClick(letter);
	}

	const list = ["a", "b", "c-č", "d-ď", "e", "f", "g", "h", "ch", "i", "j", "k", "l", "m", "n-ň", "o", "p", "q", "r-ř", "s-š", "t-ť", "u", "v", "w", "x", "y", "z-ž"];

	return (
		<div className="alphabetical">
			<div className="alphabetical__inner">
				{list.map(i => {
					const value = i != "ch" ? i[0] : i;
					return <div
						className={"alphabetical__item " + (props.current === value ? "alphabetical__item--active" : "")}
						onClick={e => onLetterClick(value, e)}>
						<a href={"#" + value}>
							{i}
						</a>
					</div>
				})}
			</div>
		</div>
	);
}