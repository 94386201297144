/**
 * Pohotovosti
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

/**
 * Stav modulu
 */
interface State {
	items: apiDynamicLists.Item[];
	regionActive: Region | undefined;
	justLoading: boolean;
}

/**
 * Kraj
 */
interface Region {
	title: string;
	tag: string;
}

/**
 * Seznam krajů
 */
export const regions: Region[] = [
	{ title: "Hlavní město Praha", tag: "pohotovosti-hlavni-mesto-praha" },
	{ title: "Jihočeský kraj", tag: "pohotovosti-jihocesky-kraj" },
	{ title: "Jihomoravský kraj", tag: "pohotovosti-jihomoravsky-kraj" },
	{ title: "Karlovarský kraj", tag: "pohotovosti-karlovarsky-kraj" },
	{ title: "Kraj Vysočina", tag: "pohotovosti-kraj-vysocina" },
	{ title: "Královéhradecký kraj", tag: "pohotovosti-kralovehradecky-kraj" },
	{ title: "Liberecký kraj", tag: "pohotovosti-liberecky-kraj" },
	{ title: "Moravskoslezský kraj", tag: "pohotovosti-moravskoslezsky-kraj" },
	{ title: "Olomoucký kraj", tag: "pohotovosti-olomoucky-kraj" },
	{ title: "Pardubický kraj", tag: "pohotovosti-pardubicky-kraj" },
	{ title: "Plzeňský kraj", tag: "pohotovosti-plzensky-kraj" },
	{ title: "Středočeský kraj", tag: "pohotovosti-stredocesky-kraj" },
	{ title: "Ústecký kraj", tag: "pohotovosti-ustecky-kraj" },
	{ title: "Zlínský kraj", tag: "pohotovosti-zlinsky-kraj" },
];

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			items: [],
			justLoading: false,
			regionActive: regions[0],
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Indikace načítání krajů
	 */
	justLoading = () => {
		return this.stateContainer.get().justLoading;
	}

	/**
	 * Je daný region vybrán?
	 */
	isRegionActive = (region: Region) => {
		return this.stateContainer.get().regionActive?.tag === region.tag;
	}

	/**
	 * Je daný region vybrán?
	 */
	getActiveRegion = () => {
		return this.stateContainer.get().regionActive;
	}

	/**
	 * Nastavý daný region jako vybraný
	 */
	setActiveRegion = (region: Region) => {
		return this.stateContainer.merge(_ => ({ regionActive: region }));
	}

	/**
	 * Vrací položky daného regionu
	 */
	getRegionItems = (region: Region | undefined) => {
		return region
			? this.stateContainer.get().items.filter(i => i.tags.find(i => i.name === region.tag) !== undefined)
			: [];
	}

	/**
	 * Načte data
	 */
	loadData = async () => {
		const items = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiDynamicLists.standard.loadList({ filter: [api.qp("tagsname" as any, "=", regions.map(i => i.tag))], per_page: 999 }),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(_ => ({
			items: items.data
		}));
	}
}