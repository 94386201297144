/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Card from "../../../components/controls/Card";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

function ContactsForJournalistsPage() {

	const model = state.useStateContext().contactsForJournalists;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("kontakty-pro-novinare-nadpis")} description="">
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="kontakty-pro-novinare-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>
			<HtmlContent htmlKey="kontakty-pro-novinare-obsah" />
			<div className="row">
				{model.getContacts().map(s =>
					<div className="col-12 col-md-6 col-lg-3">
						<Card className="mb-gutter">
							<HtmlContent className="contacts-page-overflow" content={s.text} />
						</Card>
					</div>
				)}
			</div>
		</Page>
	);
}

export default state.bindContainers(
	ContactsForJournalistsPage,
	c => c.contactsForJournalists
);