/**
 * Formulář pro zadání inzerátu
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as forms from "../../../lib/forms";
import * as validations from "../../../lib/validations";

/**
 * Definice dat formuláře
 */
export interface FormFields {
	email: string;
};

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		email: {
			title: "E-mail",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0
				? "E-mail je povinné pole"
				: validations.validateEmailAddress(value)

		},
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers()
		];
	}

	send = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.api.post("/auth/forgot_password", { email: this.form.getField("email").value });
			if (result) {
				await this.context.standardDialogs.openInformationDialog("Do emailu vám byly poslány pokyny k resetu vašeho hesla.");
			}
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}