/**
 * Modul pro přihlášení a odhlášení uživatele
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as lists from "../../../modules/system/lists/lists";
import storage from "store";
import * as navigation from "../../../lib/navigation";
import * as notification from "../../../lib/notification";
import * as context from "../../../context";
import * as apiWebRequests from "../../../modules/shared/web-request/api-web-request";

import * as apiAuth from "./api-authorization";

type CurrentSection = "osk" | "aktuality-pro-cleny";

/**
 * Stav modulu
 */
export interface State {
	userProfile?: apiAuth.Profile;
	loadingUserprofile: boolean;
	justLoggingIn: boolean;
	userLoggedIn: boolean;
	currentSection?: CurrentSection;
	webRequestId: string;
	justSendingWebRequest: boolean;
}

/**
 * Pomocná metoda zajišťující typovou obálku argumentu.
 */
function createPartialState(state: Partial<State>) {

	return state;
}

export class Model {
	public stateContainer: state.StateContainer<State>;
	public webRequestList: lists.StandardList<apiWebRequests.WebRequestDocument>;
	constructor(private context: context.StateContext) {
		this.webRequestList = new lists.StandardList<apiWebRequests.WebRequestDocument>({
			context: context,
			title: "Seznam dokumentů",
			quickFilterPlaceholder: "Vyhledejte dokument...",
			filterSystem: _ => [
				api.qp("request.id" as any, "=", this.stateContainer.get().webRequestId)
			],
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiWebRequests.documents,
			sortingFields: [],
			grid: {
				minWidth: "1200px",
				columns: [
					{
						field: "name",
						type: "string",
						title: "Název"
					},
					{
						field: "last_change" as any,
						type: "string",
						title: "Datum vytvoření",
						width: "200px"
					},
					{
						field: "action" as any,
						type: "string",
						title: "Akce",
						width: "175px"
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		const userLoggedIn = this.getAuthToken() !== "";
		this.stateContainer = new state.StateContainer<State>({
			userProfile: undefined,
			userLoggedIn: userLoggedIn,
			loadingUserprofile: false,
			justLoggingIn: false,
			justSendingWebRequest: false,
			webRequestId: api.emptyGuid,
			currentSection: storage.get("section") as CurrentSection
		}, context);

		context.api.setBindings(
			this.getAuthToken,
			this.rejectAuthToken
		);

		if (this.userLoggedIn()) {
			common.ignorePromises(this.loadUserProfile());
		}
		else if (context.contents) {
			this.loadContents();
		}
	}

	/**
	 * Přihlásí uživatele pomocí jména a hesla
	 */
	login = async (userName: string, password: string) => {
		const credentials: apiAuth.Credentials = {
			username: userName,
			password: password
		};

		const authresponse = await common.withIndication({
			exec: () => this.context.apiAuth.loginWithCredentials(credentials),
			start: () => this.stateContainer.merge(() => createPartialState({ justLoggingIn: true })),
			finish: () => this.stateContainer.merge(() => createPartialState({ justLoggingIn: false }))
		});

		if (authresponse.access_token.length > 0) {
			storage.set("authToken", authresponse.access_token);
			storage.set("membershipTo", authresponse.membership_to);
			storage.set("section", authresponse.is_osk ? "osk" : "aktuality-pro-cleny");

			await this.stateContainer.merge(() => ({
				userLoggedIn: true,
				currentSection: storage.get("section") as CurrentSection
			}));
		}

		common.ignorePromises(this.loadUserProfile());
		return authresponse;
	}

	/**
	 * Odhlásí uživatele
	 */
	logout = async () => {
		navigation.to("/")
		await this.context.apiAuth.logout();
		storage.remove("authToken");
		storage.remove("section");

		await this.stateContainer.merge(() => createPartialState({
			userProfile: undefined,
			userLoggedIn: false,
			currentSection: undefined
		}));
		notification.successMessage("Byli jste úspěšně odhlášeni.");
	}

	/**
	 * Vrací true, pokud je uživatel nalogován, avšak nemusí být ještě načten profil
	 */
	userLoggedIn = () => {
		return this.getAuthToken().length > 0;
	}

	/**
	 * Načte profil přihlášeného uživatele
	 */
	loadContents = async () => {
		await this.context.contents.loadContents();
		await this.stateContainer.merge(() => createPartialState({
			currentSection: storage.get("section") as CurrentSection
		}));
	}

	/**
	 * Načte profil přihlášeného uživatele
	 */
	loadUserProfile = async (): Promise<apiAuth.Profile> => {
		const user = await common.withIndication({
			exec: () => this.context.apiAuth.loadUserProfile(),
			start: () => this.stateContainer.merge(() => createPartialState({ loadingUserprofile: true })),
			finish: () => this.stateContainer.merge(() => createPartialState({ loadingUserprofile: false }))
		});
		await this.context.contents.loadContents();
		await this.stateContainer.merge(() => createPartialState({
			userProfile: user,
			currentSection: user.is_osk ? "osk" : "aktuality-pro-cleny"
		}));
		return user;
	}

	/**
	 * Vrací info o přihlášeném uživateli nebo undefined, není-li uživatelský
	 * profil dosud načten.
	 */
	getUserProfile = (): apiAuth.Profile | undefined => {
		return this.stateContainer.get().userProfile;
	}

	getAuthToken = () => {
		const authToken = storage.get("authToken");
		return authToken || "";
	}

	loadingUserProfile = () => {
		return this.stateContainer.get().loadingUserprofile;
	}

	justLoggingIn = () => {
		return this.stateContainer.get().justLoggingIn;
	}

	rejectAuthToken = async () => {
		storage.remove("authToken");
		storage.remove("section");
		await this.stateContainer.merge(() => createPartialState({
			userProfile: undefined,
			userLoggedIn: false,
			currentSection: undefined
		}));
	}

	loadData = async () => {
		if (this.userLoggedIn()) {
			await this.loadUserProfile();
		}
	}

	getCurrentSection = () => {
		return this.stateContainer.get().currentSection;
	}

	setCurrentSection = async (section: CurrentSection) => {
		storage.set("section", section);
		await this.stateContainer.merge(() => createPartialState({
			currentSection: section
		}));
	}

	isOSKMember = () => {
		return this.stateContainer.get().currentSection === "osk";
	}

	getId = () => {
		return this.stateContainer.get().webRequestId;
	}

	getJustSendingWebRequest = () => {
		return this.stateContainer.get().justSendingWebRequest;
	}

	setJustSendingWebRequest = async (value: boolean) => {
		await this.stateContainer.merge(_ => ({
			justSendingWebRequest: value
		}));
	}

	loadWebRequest = async (id: string) => {
		await this.stateContainer.merge(_ => ({
			webRequestId: id
		}));
		await this.webRequestList.loadWithResetPage();
		await this.stateContainer.merge(_ => ({
			webRequestId: id
		}));
	}
}