/**
 * Model žádosti o registraci akce
 * 
 * */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

export interface FormFields {
	buyer: 1 | 2 | 3;

	name: string;
	lastname: string;
	school: "" | "LF UK Praha" | "LF UK Plzen" | "LF UK Hradec Kralove" | "LF MU Brno" | "LF UP Olomouc";
	tel: string;
	email: string;
	study_year: "" | "4." | "5.";


	street: string;
	city: string;
	psc: string;
	state: "čr";



	title_before: string;
	title_pedagogical: string;
	title_after: string;

	company_name: string;

	billing_name: string;
	billing_street: string;
	billing_city: string;
	billing_psc: string;
	billing_ico: string;
	billing_dic: string;

	billing_state: string;

	print_count: "1" | "2";
}

const Form: forms.FormOptions<FormFields> = {
	fields: {
		buyer: {
			title: "",
			defaultValue: 1
		},
		name: {
			title: "Jméno",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Jméno je povinné pole" : ""
		},
		lastname: {
			title: "Přijmení",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Příjmení je povinné pole" : ""
		},
		school: {
			title: "Lékařská fakulta",
			defaultValue: "",
			required: (form) => form.getField("buyer").value == 1,
			validate: (value, field, form) => form.getField("buyer").value == 1 && value.length == 0 ? "Fakulta je povinné pole" : ""
		},
		tel: {
			title: "Telefon",
			defaultValue: "",
			placeHolderText: "+420 XXX XXX XXX",
			required: true,
			validate: value => value.length == 0 ? "Telefon je povinné pole" : ""
		},
		email: {
			title: "E-mail",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "E-mail je povinné pole" : ""
		},
		study_year: {
			title: "Ročník studia",
			defaultValue: "",
			required: (form) => form.getField("buyer").value == 1,
			validate: (value, field, form) => form.getField("buyer").value == 1 && value.length == 0 ? "Ročník je povinné pole" : ""
		},
		street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Ulice je povinné pole" : ""
		},
		city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "Obec je povinné pole" : ""
		},
		psc: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => value.length == 0 ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		state: {
			title: "Stát",
			defaultValue: "čr",
			readOnly: true
		},

		title_before: {
			title: "Titul před",
			defaultValue: ""
		},
		title_pedagogical: {
			title: "Pedagogický titul",
			defaultValue: ""
		},
		title_after: {
			title: "Titul za",
			defaultValue: ""
		},

		company_name: {
			title: "Název firmy NEBO jméno a přijmení adresáta",
			defaultValue: ""
		},

		billing_name: {
			title: "Název firmy",
			defaultValue: "",
			required: false
		},
		billing_street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: (value, field, form) => form.getField("buyer").value == 3 && value.length == 0 ? "Ulice je povinné pole" : ""
		},
		billing_city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: (value, field, form) => form.getField("buyer").value == 3 && value.length == 0 ? "Obec je povinné pole" : ""
		},
		billing_psc: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: (value, field, form) => form.getField("buyer").value == 3 && value.length == 0 ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : form.getField("buyer").value == 3 ? "PSČ musí obsahovat 5 znaků" : ""
		},
		billing_ico: {
			title: "IČO",
			defaultValue: ""
		},
		billing_dic: {
			title: "Ulice a číslo",
			defaultValue: ""
		},
		billing_state: {
			title: "Stát",
			defaultValue: ""
		},
		print_count: {
			title: "Počet výtisků od jednoho čísla",
			defaultValue: "1",
			readOnly: true
		}
	}
};


/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;

	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(Form, context);

	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		...this.common.getStateContainers(),
		...this.form.getStateContainers(),
	]

	/**
	 *  Načtení CSK 
	 */
	loadCskMember = async () => {
		if (this.context.authorization.userLoggedIn()) {
			let userProfile = this.context.authorization.getUserProfile();
			if (!userProfile) {
				userProfile = await this.context.authorization.loadUserProfile();
			}
			await this.form.setFields({
				name: userProfile.first_name,
				lastname: userProfile.last_name,
				title_before: userProfile.profile.title_before,
				title_after: userProfile.profile.title_after,
				title_pedagogical: userProfile.profile.title_pedagogical,
				tel: userProfile.profile.contact?.phone1,
				email: userProfile.profile.contact?.email1,
				street: userProfile.profile.delivery_address?.street ?? "",
				city: userProfile.profile.delivery_address?.city ?? "",
				psc: userProfile.profile.delivery_address?.postcode == undefined ? "" : userProfile.profile.delivery_address?.postcode.replace(/\s+/g, ""),
				billing_street: userProfile.profile.address?.street ?? "",
				billing_name: userProfile.profile.address?.city ?? "",
				billing_psc: userProfile.profile.address?.postcode == undefined ? "" : userProfile.profile.address?.postcode.replace(/\s+/g, ""),
			});
		}
	}

	getPriceString = () => {
		const buyer = this.form.getField("buyer").value;

		return (
			buyer === 1
				? "0 Kč"
				: buyer === 2
					? "900 Kč"
					: "600 Kč"
		);
	}

	getPrice = () => {
		const buyer = this.form.getField("buyer").value;

		return (
			buyer === 1
				? 0
				: buyer === 2
					? 900
					: 600
		);
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			await this.common.send(data, "zavazna-objednavka-casopis-lks");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			await this.form.setFields(data);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset()
		]);
		await this.loadCskMember();
	}
}