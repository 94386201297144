/**
 *  Žádost o změnu termínu profesní zkoušky
 */
import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import Button from "../../../components/controls/Button";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";

import CardLink from "../../../components/controls/CardLink";

interface WebRequestChangeExamDateProps {
	id?: string;
	zop?: boolean;
}

function WebRequestChangeExamPage(props: WebRequestChangeExamDateProps) {
	const { webRequestChangeExamDate: model } = state.useStateContext();
	const form = model.form;

	const title = props.zop ? "ŽÁDOST O ZMĚNĚ TERMÍNU PROFESNÍ ZKOUŠKY (ZOP)" : "ŽÁDOST O ZMĚNĚ TERMÍNU PROFESNÍ ZKOUŠKY";

	return (
		<WebRequest
			model={model.common}
			id={props.id}
			title={title}
			description=""
			authorized
		>


			{!props.zop ?
				<>
					<h2 className="form-title">Žádám o změnu termínu profesní zkoušky v rámci</h2>
					<CheckBoxRadioFrame className="mb-5">
						<div className="row">
							<div className="col-md-6">
								<FormRadio
									form={form}
									field="program_type"
									option="vppzl"
									title="v praktickém zubním lékařství (VPPZL)"
								/>
							</div>
							<div className="col-md-6">
								<FormRadio
									form={form}
									field="program_type"
									option="vpchir"
									title="ve stomatochirurgii (VPCHIR)"
								/>
							</div>
							<div className="col-md-6">
								<FormRadio
									form={form}
									field="program_type"
									option="vpparo"
									title="v parodontologii (VPPARO)"
								/>
							</div>
							<div className="col-md-6">
								<FormRadio
									form={form}
									field="program_type"
									option="vppedo"
									title="v pedostomatologii (VPPEDO)"
								/>
							</div>
						</div>
					</CheckBoxRadioFrame>

					<div className="row">
						<div className="col-md-4 col-lg-3">
							<FormInput
								form={form}
								field="old_date"
								inputProps={{ type: "date" }}
							/>
						</div>
					</div>
					<p>
						Místo konání profesních zkoušek: ČSK, <strong>Vzdělávací středisko, Slavojova 22, 128 00 Praha 2</strong>.<br />
						Termín Vám Kancelář Komory přidělí s ohledem na aktuální nabídku dostupných termínů
					</p>
				</>
				:
				<>
					<h2 className="form-title text-danger">Žádost není dostupná v elektronické podobě.</h2>

					<div className="row">
						<div className="col-md-8">
							<CardLink cardProps={{ className: "mb-4" }} href="#">
								Formulář ke stažení zde: Žádost o změnu termínu profesní zkoušky (ZOP).docx
							</CardLink>
						</div>
					</div>

					<p>
						<strong>
							Vyplněnou a podepsanou žádost vč. kopie dokladu o zaplacení poplatku zašlete v oskenované podobě
							ve formátu pdf na e-mail: <a href="mailto:hynkova@dent.cz">hynkova@dent.cz</a>
						</strong>
					</p>
				</>
			}

			<strong>Poučení:</strong><br />
			<em>
				<small>
					<p>
						Změna přiděleného termínu konání zkoušky a opakované zkoušky je přípustná jen výjimečně
						a nejvýše třikrát v rámci jednoho vzdělávacího programu. Změny přiděleného termínu konání
						zkoušky a změny přiděleného termínu konání opakované zkoušky se v rámci jednoho vzdělávacího
						programu sčítají. Výše úhrady za zpracování žádosti závisí na celkovém počtu podaných žádostí
						o změnu termínu.
					</p>

					<p>
						Žádost o změnu přiděleného termínu konání zkoušky nebo opakované zkoušky lze Kanceláři
						Komory podat <strong>nejpozději do 12:00 hod. pracovního dne bezprostředně předcházejícího
							přidělenému termínu</strong> konání zkoušky nebo opakované zkoušky. <br /> Zmešká-li člen
						Komory přidělený termín konání zkoušky nebo opakované zkoušky, platí, že zkoušku nebo
						opakovanou zkoušku absolvoval s hodnocením „neprospěl“.
					</p>

					<p>
						Žádost o změnu termínu podávanou za účelem anulování podmíněného výsledku zkoušky
						nebo opakované zkoušky lze Kanceláři Komory podat nejpozději do 7 dnů od data absolvování
						zkoušky s podmíněným výsledkem. Výše úhrady za zpracování žádosti závisí na celkovém počtu
						podaných žádostí o změnu termínu v rámci příslušného vzdělávacího programu.
					</p>
				</small>
			</em>

			<h2 className="form-title">Za zpracování této žádosti je stanoven poplatek ve výši:</h2>

			<div className="row mb-4">
				<div className="col-md-6">
					<div className="row">
						<div className="col-md-4"><strong>500 Kč</strong> za 1. změnu</div>
						<div className="col-md-4"><strong>3000 Kč</strong> za 2. změnu</div>
						<div className="col-md-4"><strong>6000 Kč</strong> za 3. změnu</div>
					</div>
				</div>
			</div>

			{props.zop ?
				<p>Vaše žádost bude zpracována po připsání platby na účet ČSK. Pokyny k platbě najdete na formuláři.</p>
				:
				<p>Pokyn k úhradě poplatku Vám bude odeslán e-mailem obratem po odeslání Vaší žádosti. Vaše žádost bude zpracována po připsání platby na účet ČSK.</p>
			}


			{!props.zop && !props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}


		</WebRequest>
	);
}

export default state.bindContainers(
	WebRequestChangeExamPage,
	c => c.webRequestChangeExamDate
);