import * as api from "../../../lib/api";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

interface AdvertisementCommon {
	first_name: string;
	last_name: string;
	company: string;
	email: string;
	phone: string;
	description: string;
	contact_email: string;
	contact_phone: string;
	contact_web: string;
}

export interface Advertisement extends api.EntityReadBase, AdvertisementCommon {
	subject: apiCodeList.CodeList;
	region: apiCodeList.CodeList;
	is_own: boolean;
	categories: apiCodeList.CodeList[];
}

export interface AdvertisementEntry extends api.EntityUpdateBase, AdvertisementCommon {
	region: apiCodeList.CodeList;
	categories: api.Id[];
}

export class Api {
	standard: api.EntityApi<Advertisement, AdvertisementEntry>;

	constructor(private context: context.StateContext) {
		this.standard = new api.EntityApi<Advertisement, AdvertisementEntry>({
			api: context.api,
			path: "/advertisements"
		});
	}
}