/**
 * Obecný dialog
 */
import * as React from "react";
import * as dialogs from "./dialogs";
import Modal from "react-responsive-modal";

interface DialogProps<DialogResult, DialogState> {
	dialog: dialogs.Dialog<DialogResult, DialogState>;
	bottomToolbar?: React.ReactNode;
	limitedWidth?: boolean;
	preventClose?: boolean;
	className?: string;
}

export default class Dialog<DialogResult, DialogState> extends React.Component<DialogProps<DialogResult, DialogState>> {
	render = () => {
		return (
			<Modal
				classNames={{
					modal: (this.props.className ? this.props.className : "") + (this.props.limitedWidth ? " dialog--limited-width" : ""),
				}}
				center
				showCloseIcon={!this.props.dialog.closeButtonHidden()}
				closeOnOverlayClick={!this.props.preventClose}
				closeOnEsc={!this.props.preventClose}
				open={this.props.dialog.isOpen()}
				onClose={this.props.dialog.close}
			>
				<div className="">
					{this.props.children}
					{this.props.bottomToolbar &&
						<div className="dialog__toolbar">
							{this.props.bottomToolbar}
						</div>
					}
				</div>
			</Modal>
		);
	}
}