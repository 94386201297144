/**
 * Model žádosti o zápis do seznamu znalců České stomatologické komory pro obor znalecké činnosti
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as formating from "../../../lib/formatting";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";



export interface FormFields {
	child_stomatologi: boolean;
	oral_maxillofacial_and_facial_surgery: boolean;
	oral_surgery: boolean;
	periodontology: boolean;
	orthodontics: boolean;
	prosthetic_dentistry: boolean;
	restorative_dentistry: boolean;

	// K žádosti přiložte tyto přílohy
	education_evidence_attachment?: apiFile.File;
	professional_cv_attachment?: apiFile.File;
	membership_data_attachment?: apiFile.File;
	statement_attachment?: apiFile.File;
	lecture_activities_attachment?: apiFile.File;
	publishing_activities_attachment?: apiFile.File;
	other_attachment?: apiFile.File;

	submission_date: string;
}

interface FormData extends FormFields { }

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public commonModel: webRequest.Model;

	constructor(private context: context.StateContext) {
		const current_date = formating.formatDate(new Date(), { format: "iso" });

		this.commonModel = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				child_stomatologi: {
					title: "dětská stomatologie",
					defaultValue: false,
					onChange: async (value, field, form) => {
						form.validateField("oral_maxillofacial_and_facial_surgery");
						form.validateField("oral_surgery");
						form.validateField("periodontology");
						form.validateField("orthodontics");
						form.validateField("prosthetic_dentistry");
						form.validateField("restorative_dentistry");
					},
					validate: (value, item, form) => value ||
						form.getField("oral_maxillofacial_and_facial_surgery").value ||
						form.getField("oral_surgery").value ||
						form.getField("periodontology").value ||
						form.getField("orthodontics").value ||
						form.getField("prosthetic_dentistry").value ||
						form.getField("restorative_dentistry").value
						? "" : "Prosím, vyberte alespoň jednu možnost"
				},
				oral_maxillofacial_and_facial_surgery: {
					title: "ústní, čelistní a obličejová chirurgie",
					defaultValue: false,
					onChange: async (value, field, form) => {
						form.validateField("child_stomatologi");
						form.validateField("oral_surgery");
						form.validateField("periodontology");
						form.validateField("orthodontics");
						form.validateField("prosthetic_dentistry");
						form.validateField("restorative_dentistry");
					},
					validate: (value, item, form) => value ||
						form.getField("child_stomatologi").value ||
						form.getField("oral_surgery").value ||
						form.getField("periodontology").value ||
						form.getField("orthodontics").value ||
						form.getField("prosthetic_dentistry").value ||
						form.getField("restorative_dentistry").value
						? "" : "Prosím, vyberte alespoň jednu možnost"
				},
				oral_surgery: {
					title: "ústní chirurgie",
					defaultValue: false,
					onChange: async (value, field, form) => {
						form.validateField("child_stomatologi");
						form.validateField("oral_maxillofacial_and_facial_surgery");
						form.validateField("periodontology");
						form.validateField("orthodontics");
						form.validateField("prosthetic_dentistry");
						form.validateField("restorative_dentistry");
					},
					validate: (value, item, form) => value ||
						form.getField("child_stomatologi").value ||
						form.getField("oral_maxillofacial_and_facial_surgery").value ||
						form.getField("periodontology").value ||
						form.getField("orthodontics").value ||
						form.getField("prosthetic_dentistry").value ||
						form.getField("restorative_dentistry").value
						? "" : "Prosím, vyberte alespoň jednu možnost"
				},
				periodontology: {
					title: "parodontologie",
					defaultValue: false,
					onChange: async (value, field, form) => {
						form.validateField("child_stomatologi");
						form.validateField("oral_maxillofacial_and_facial_surgery");
						form.validateField("oral_surgery");
						form.validateField("orthodontics");
						form.validateField("prosthetic_dentistry");
						form.validateField("restorative_dentistry");
					},
					validate: (value, item, form) => value ||
						form.getField("child_stomatologi").value ||
						form.getField("oral_maxillofacial_and_facial_surgery").value ||
						form.getField("oral_surgery").value ||
						form.getField("orthodontics").value ||
						form.getField("prosthetic_dentistry").value ||
						form.getField("restorative_dentistry").value
						? "" : "Prosím, vyberte alespoň jednu možnost"
				},
				orthodontics: {
					title: "ortodoncie",
					defaultValue: false,
					onChange: async (value, field, form) => {
						form.validateField("child_stomatologi");
						form.validateField("oral_maxillofacial_and_facial_surgery");
						form.validateField("oral_surgery");
						form.validateField("periodontology");
						form.validateField("prosthetic_dentistry");
						form.validateField("restorative_dentistry");
					},
					validate: (value, item, form) => value ||
						form.getField("child_stomatologi").value ||
						form.getField("oral_maxillofacial_and_facial_surgery").value ||
						form.getField("oral_surgery").value ||
						form.getField("periodontology").value ||
						form.getField("prosthetic_dentistry").value ||
						form.getField("restorative_dentistry").value
						? "" : "Prosím, vyberte alespoň jednu možnost"
				},
				prosthetic_dentistry: {
					title: "protetická stomatologie",
					defaultValue: false,
					onChange: async (value, field, form) => {
						form.validateField("child_stomatologi");
						form.validateField("oral_maxillofacial_and_facial_surgery");
						form.validateField("oral_surgery");
						form.validateField("periodontology");
						form.validateField("orthodontics");
						form.validateField("restorative_dentistry");
					},
					validate: (value, item, form) => value ||
						form.getField("child_stomatologi").value ||
						form.getField("oral_maxillofacial_and_facial_surgery").value ||
						form.getField("oral_surgery").value ||
						form.getField("periodontology").value ||
						form.getField("orthodontics").value ||
						form.getField("restorative_dentistry").value
						? "" : "Prosím, vyberte alespoň jednu možnost"
				},
				restorative_dentistry: {
					title: "záchovná stomatologie",
					defaultValue: false,
					onChange: async (value, field, form) => {
						form.validateField("child_stomatologi");
						form.validateField("oral_maxillofacial_and_facial_surgery");
						form.validateField("oral_surgery");
						form.validateField("periodontology");
						form.validateField("orthodontics");
						form.validateField("prosthetic_dentistry");
					},
					validate: (value, item, form) => value ||
						form.getField("child_stomatologi").value ||
						form.getField("oral_maxillofacial_and_facial_surgery").value ||
						form.getField("oral_surgery").value ||
						form.getField("periodontology").value ||
						form.getField("orthodontics").value ||
						form.getField("prosthetic_dentistry").value
						? "" : "Prosím, vyberte alespoň jednu možnost"
				},

				education_evidence_attachment: {
					title: "Doklady o dosaženém vzdělání.",
					defaultValue: undefined,
					required: (form) => true,
					validate: (value, field, form) => !value ? "Příloha je povinné pole" : ""
				},
				professional_cv_attachment: {
					title: "Odborný životopis.",
					defaultValue: undefined,
					required: (form) => true,
					validate: (value, field, form) => !value ? "Příloha je povinné pole" : ""
				},
				membership_data_attachment: {
					title: "Údaje o členství v odborných společnostech, redakčních radách apod.",
					defaultValue: undefined
				},
				statement_attachment: {
					title: "Vyjádření odborné společnosti.",
					defaultValue: undefined,
					required: (form) => (
						form.getField("child_stomatologi").value ||
						form.getField("orthodontics").value ||
						form.getField("oral_maxillofacial_and_facial_surgery").value ||
						form.getField("prosthetic_dentistry").value ||
						form.getField("periodontology").value ||
						form.getField("oral_surgery").value ||
						!form.getField("restorative_dentistry").value
					),
					validate: (value, field, form) => (
						(
							form.getField("child_stomatologi").value ||
							form.getField("orthodontics").value ||
							form.getField("oral_maxillofacial_and_facial_surgery").value ||
							form.getField("prosthetic_dentistry").value ||
							form.getField("periodontology").value ||
							form.getField("oral_surgery").value ||
							!form.getField("restorative_dentistry").value
						)
						&& !value) ? "Příloha je povinné pole" : ""
				},
				lecture_activities_attachment: {
					title: "Seznam přednáškové činnosti.",
					defaultValue: undefined
				},
				publishing_activities_attachment: {
					title: "Seznam publikační činnosti.",
					defaultValue: undefined
				},
				other_attachment: {
					title: "Přidat další přílohu",
					defaultValue: undefined
				},

				submission_date: {
					title: "Datum podání",
					readOnly: true,
					defaultValue: current_date
				}
			}
		}, context);
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormFields = {
				...this.form.getFields(),
			};
			await this.commonModel.send(data, "zadost-o-zapis-do-seznamu-znalcu");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
 * Vrací kolekci stavových kontejnerů
 */
	getStateContainers = () => {
		return [
			...this.commonModel.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	/**
 * Načte data do formuláře 
 */
	loadData = async (webRequestId: string) => {
		await this.commonModel.loadData(webRequestId);
		const webRequest = this.commonModel.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.commonModel.reset()
		]);
	}
}