import * as React from "react";

type IconSvgProps = React.HTMLProps<HTMLSpanElement> & {
	name: string;
	modifier?: string;
};

export default function IconSvg(props: IconSvgProps) {
	const className = "icon-svg "
		+ ("icon-svg--" + props.name + " ")
		+ (props.modifier ? props.modifier : "") + " "
		+ (props.className ?? "");

	const { name, modifier, className: origClassName, ...restProps } = props;
	return <span className={className + " " + (origClassName ?? "")} {...restProps}>
		<svg
			className="icon-svg__svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<use
				xlinkHref={"/images/bg/icons-svg.svg#icon-" + props.name}
				width="100%"
				height="100%"
				focusable="false"
			></use>
		</svg >
	</span >;
}