/**
 * Modul stránky "Klikací povinnost"
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as navigation from "../../../lib/navigation";
import * as webRequest from "../../shared/web-request/web-request";
import * as forms from "../../../lib/forms";

export interface FormFields {
	control_of_personal_data?: boolean;
	checking_data_on_educational_attainment?: boolean;
	workplace_control?: boolean;
}

export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				control_of_personal_data: {
					defaultValue: false,
					readOnly: true,
					title: "",
					validate: value => !value ? "Nejdříve je třeba vyplnit" : ""
				},
				checking_data_on_educational_attainment: {
					defaultValue: false,
					readOnly: true,
					title: "",
					validate: value => !value ? "Nejdříve je třeba vyplnit" : ""
				},
				workplace_control: {
					defaultValue: false,
					readOnly: true,
					title: "",
					validate: value => !value ? "Nejdříve je třeba vyplnit" : ""
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers(),
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			const result = await this.context.api.post("/members/change_confirm", data);
			if (result) {
				navigation.to("/muj-profil");
			}
		} else {
			await this.context.standardDialogs.openInformationDialog("Nejdříve je třeba potvrdit všechny kontroly.");
		}
	}

	/**
 * Provede reset formuláře
 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
		]);
	}
}