/**
 * Dms
 */
import React from "react";

import * as state from "../../../lib/state";
import * as apiDms from "../../shared/dialogs/api-dms";
import * as icons from "../../system/icons/icons";
import Page from "../../../components/templates/Page";
import * as apiLib from "../../../lib/api";
import List from "../../../modules/system/lists/List";
import Icon from "../../../modules/system/icons/Icon";

function DocumentsSnemPage() {
	const { documentsSnem, apiFile, api } = state.useStateContext();

	async function handleClick(item: any) {
		if (item.id != apiLib.emptyGuid && item.dms_file_type!.id != apiDms.DocumentType.Folder) {
			await api.download("/dms_files" + "/" + item.id + "/download", item.name, undefined);
			return;
		}
		if (item.dms_file_type!.id == apiDms.DocumentType.Folder) {
			const path = documentsSnem.stateContainer.get().path;
			path.push({ id: item.id, name: item.name, index: path.length });
			await documentsSnem.stateContainer.merge(_ => ({
				idParent: item.id,
				path: path
			}));
			documentsSnem.list.loadWithResetPage();
		}
	}

	async function handleRoot() {
		await documentsSnem.stateContainer.merge(_ => ({
			idParent: undefined,
			path: []
		}));
		documentsSnem.list.loadWithResetPage();
	}

	async function handlePath(index: number) {
		const path = documentsSnem.stateContainer.get().path;
		if (index + 1 != path.length) {
			const pathObject = path[index];
			const slice = documentsSnem.stateContainer.get().path.slice(0, index + 1);
			await documentsSnem.stateContainer.merge(_ => ({
				path: slice,
				idParent: pathObject.id
			}));
			documentsSnem.list.loadWithResetPage();
		}
	}

	function getDocumentType(type: apiDms.DocumentType) {
		const item = apiDms.getDocumentType(type);
		return <div style={{ color: item.color }}><Icon size="2x" picture={item.icon} /></div>
	}

	return (
		<Page
			title="Seznam doumentů"
			description=""
			authorized
		>
			<h1>
				Seznam dokumentů
			</h1>
			<div className="document-snem">
				<div className="document-list-path">
					<Icon size="2x" className="document-list-folder" picture="Folder" />
					<div className="document-list-full-path ml-2">
						<span className="ml-2">{'>'}</span>
						<div onClick={() => handleRoot()} className="document-list-path-item ml-2 font-weight-bold">root</div>
						{documentsSnem.stateContainer.get().path.map((item, index) =>
							<>
								<span className="ml-2">{'>'}</span>
								<div onClick={() => handlePath(index)} className="document-list-path-item ml-2">{item.name}</div>
							</>
						)}
					</div>
				</div>
				<List
					list={documentsSnem.list}
					simpleTable={true}
					itemNode={(item, field) => {
						switch (field) {
							case "name": return <span className="span-as-hyperlink" onClick={() => handleClick(item)}>{item.name}</span>;
							case "dms_file_type.name" as any: return getDocumentType(item.dms_file_type!.id as any);
							default: return undefined;
						}
					}}
				/>
			</div>
		</Page>
	);
}

export default state.bindContainers(
	DocumentsSnemPage,
	context => context.documentsSnem
);