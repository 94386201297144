/**
 * Zapomenutý email
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";


function ForgottenPassword() {
	const { forgottenPassword } = state.useStateContext();

	return (
		<Page title="Zapomenuté heslo" description="Zapomenuté heslo">
			<h1>Zapomenuté heslo</h1>
			<div className="row">
				<div className="col-6">
					<FormsInput
						form={forgottenPassword.form} field="email"
					/>
				</div>
			</div>
			<div>
				<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
				<Button theme="primary" onClick={forgottenPassword.send}>Odeslat</Button>
			</div>
		</Page>
	);
}

export default state.bindContainers(
	ForgottenPassword,
	c => c.forgottenPassword
);