/**
 * Icona aplikace.
 */
import * as React from "react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "./icons";

interface Props {
	picture: icons.Icon;
	size?: SizeProp;
	className?: string;
}

export default function Icon(props: Props) {
	return (
		<FontAwesomeIcon icon={icons.icon[props.picture] as any} className={props.className} size={props.size} />
	);
}