/**
 * Detail člena (zubaře)
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as apiMembers from "../../shared/members/api-members";
import * as context from "../../../context";

interface State {
	member?: apiMembers.WebMember;
	justLoading: boolean;
}

/**
 * Modul stránky seznamu zubařů
 */
export class Model {
	private stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoading: false
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Načte data modulu
	 */
	loadData = async (memberId: api.Id) => {
		await this.loadMember(memberId);
	}

	/**
	 * Načte člena
	 */
	loadMember = async (memberId: api.Id) => {
		const result = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiMembers.standard.load(memberId),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(_ => ({
			member: result
		}));
	}

	/**
	 * Vrací člena
	 */
	getMember = () => {
		return this.stateContainer.get().member;
	}

	/**
	 * Indikace načítání člena 
	 */
	justLoading = () => {
		return this.stateContainer.get().justLoading;
	}
}