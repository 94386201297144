import * as React from "react";

import SocialIcons from "./SocialIcons";

export default function Contact() {
	return (
		<>
			<p>
				<img src="/images/logo-big.svg" alt="ČSK" width="380" height="60" />
			</p>
			<p>
				Česká stomatologická komora<br />
				Slavojova 270/22<br />
				128 00 Praha 2<br />
				Telefon: <a href="tel:+420234709611">234 709 611</a><br />
				E-mail: <a href="mailto:info@dent.cz">info@dent.cz</a>
			</p>
			<SocialIcons variant="footer" />
		</>
	);
}