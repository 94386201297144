/**
 *  Formulář žádosti o příspěvek z účtu klinik a vzdělávání
 */
import React from "react";

import WebRequest from "../../../components/templates/WebRequest";

import Button from "../../../components/controls/Button";

import FormInput from "../../../components/controls/Forms/FormsInput";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormTextArea from "../../../components/controls/Forms/FormsTextArea";
import FormsRadio from "../../../components/controls/Forms/FormRadio";
import FormFileUpload from "../../system/file/FormFileUpload";

import * as state from "../../../lib/state";

interface Props {
	id: string;
}

function WebRequestClinicEducationContributionPage(props: Props) {
	const { webRequestClinicEducationContribution } = state.useStateContext();

	const formEntry = webRequestClinicEducationContribution.form;
	const { webRequestClinicEducationContribution: model } = state.useStateContext();

	return (
		<WebRequest id={props.id} model={webRequestClinicEducationContribution.commonModel} title="ŽÁDOST O PŘÍSPĚVEK Z ÚČTU KLINIK A VZDĚLÁVÁNÍ" description="" authorized>

			<h2 className="form-title">Pracoviště</h2>

			<div className="row">
				<div className="col-md-6">
					<div className="row mt-4">
						<div className="col-6">
							<FormsRadio
								form={model.form}
								field="is_professional_society"
								option={false}
								title="člen" />
						</div>
						<div className="col-6">
							<FormsRadio
								form={model.form}
								field="is_professional_society"
								option={true}
								title="odborná společnost" />
						</div>
					</div>
				</div>
				{formEntry.getField("is_professional_society").value &&
					<div className="col-md-6">
						<FormInput
							form={formEntry}
							field="professional_society"
						/>
					</div>
				}
			</div>

			<div className="row">
				<div className="col-md-12">
					<FormInput
						form={formEntry}
						field="clinic"
					/>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={formEntry}
						field="workplace_name"
					/>
					<FormInput
						form={formEntry}
						field="township"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={formEntry}
						field="street_and_number"
					/>
					<FormInput
						form={formEntry}
						hideNumeric={true}
						inputProps={{ type: "number" }}
						field="psc"
					/>
				</div>
			</div>

			<h2 className="form-title mb-5">Základní údaje o akci</h2>

			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={formEntry}
						field="action_name"
					/>

					<div className="row">
						<div className="col-md-6">
							<FormInput
								inputProps={{ type: "date" }}
								form={formEntry}
								field="action_date_from"
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								inputProps={{ type: "date" }}
								form={formEntry}
								field="action_date_to"
							/>
						</div>
					</div>
					<FormInput
						form={formEntry}
						field="information_confirmation"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={formEntry}
						field="action_place"
					/>
					<FormInput
						form={formEntry}
						field="stay_time"
					/>
					<FormFileUpload
						form={formEntry}
						field="confirmation"
					/>
				</div>
			</div>

			<h2 className="form-title mb-5">Náklady</h2>

			<div className="row">
				<div className="col-md-3">
					<FormInput
						form={formEntry}
						inputProps={{ type: "number" }}
						field="congress_fee"
					/>
				</div>
				<div className="col-md-3">
					<FormInput
						form={formEntry}
						inputProps={{ type: "number" }}
						field="travel_expenses"
					/>
				</div>
				<div className="col-md-3">
					<FormInput
						form={formEntry}
						inputProps={{ type: "number" }}
						field="accommodations"
					/>
				</div>
				<div className="col-md-3">
					<FormInput
						form={formEntry}
						inputProps={{ type: "number" }}
						field="costs"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={formEntry}
						field="requested_contribution"
					/>
				</div>
				<div className="col-md-6 mt-4">
					<FormCheckBox
						form={formEntry}
						field="deposit"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<FormTextArea
						form={formEntry}
						field="contribution_use"
						textAreaProps={{
							className: "mb-0"
						}}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<FormFileUpload
						form={formEntry}
						field="costs_attachment"
						className="mb-5"
					/>
				</div>
			</div>

			<h2 className="form-title mt-3">Účast</h2>
			<div>Aktivní</div>
			<div className="row">
				<div className="col-md-3">
					<FormCheckBox
						form={formEntry}
						field="lecture"
					/>
				</div>
				<div className="col-md-3">
					<FormCheckBox
						form={formEntry}
						field="poster"
					/>
				</div>
				<div className="col-md-3">
					<FormCheckBox
						form={formEntry}
						field="internship"
					/>
				</div>
				<div className="col-md-3">
					<FormCheckBox
						form={formEntry}
						field="course"
					/>
				</div>
			</div>
			<div>Pasivní</div>
			<div className="row">
				<div className="col-md-3">
					<FormCheckBox
						form={formEntry}
						field="congress"
					/>
				</div>
				<div className="col-md-3">
					<FormCheckBox
						form={formEntry}
						field="symposium"
					/>
				</div>
			</div >
			<FormTextArea
				form={formEntry}
				field="lecture_name"
			/>

			<FormFileUpload
				form={formEntry}
				field="participation_confirmation_attachment"
			/>

			<FormFileUpload
				form={formEntry}
				field="abstract_attachment"
			/>

			<FormFileUpload
				form={formEntry}
				field="poster_attachment"
			/>

			<h2 className="form-title mt-3">Spolupráce s ČSK</h2>

			<FormTextArea
				form={formEntry}
				field="overview_of_cooperation"
			/>
			<FormTextArea
				form={formEntry}
				field="proposal_of_cooperation"
			/>

			<h2 className="form-title">Žadatel se při přiznání příspěvku po ukončení cesty zavazuje k</h2>

			<ol className="mb-5">
				<li>Podání zprávy o průběhu akce a podklady k vyúčtování do 7 dnů po skončení akce.</li>
				<li>Přednesení odborného sdělení na kongresu PDD = poster nebo přednáška, nebo vedení přednášek a kurzů, tvorba e-lerningových kurzů pro VS ČSK a vedení webináře ČSK nebo odborná přednáška na registrovaných akcích OSK, nebo účast ve zkušební komisi profesních zkoušek.</li>
				<li>Zpracování odborného článku k publikaci v časopise LKS / ČSPZL nebo Malé ilustrované repetitorium.</li>
			</ol>

			<h2 className="form-title mt-3">Vyjádření přednosty kliniky</h2>
			<p className="text-danger font-weight-bold">
				Po odeslání vyplněných údajů obdržíte email s Vaší žádostí v PDF. Tuto žádost doplňte o výjádření přednosty kliniky a vložte do dokumentů k této žádosti
			</p>

			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={formEntry}
						field="name"
					/>
				</div>
			</div>

			<FormCheckBox
				form={formEntry}
				field="affidavit"
			/>

			<div className="row">
				<div className="col-sm-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={formEntry}
						field="submission_date"
					/>
				</div>
			</div>
			<div className="text-right">
				<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
				<Button theme="primary" onClick={model.confirm} spinner={model.commonModel.justSending()}>Odeslat</Button>
			</div>

			<br />

		</WebRequest >
	);
}

export default state.bindContainers(
	WebRequestClinicEducationContributionPage,
	c => c.webRequestClinicEducationContribution
);