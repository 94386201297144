/**
 * Domovská stránka
 */
import React, { useEffect } from "react";

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import Button from "../../../components/controls/Button";
import Page from "../../../components/templates/Page";
import List from "../../system/lists/List";
import { Link } from "react-router-dom";

function OskMemebersPage() {
	const { oskMembers } = state.useStateContext();

	useEffect(() => {
		if (api.getUrlParam("hostujici")) {
			oskMembers.seChamber1(null);
			oskMembers.setRelationship2(null);
			oskMembers.setRelationship1(1);
			oskMembers.seChamber2(2);
		}
		if (api.getUrlParam("oskplus")) {
			oskMembers.setRelationship1(null);
			oskMembers.seChamber2(null);
			oskMembers.setRelationship2(2);
			oskMembers.seChamber1(1);
		}
		if (!api.getUrlParam("hostujici") && !api.getUrlParam("oskplus")) {
			oskMembers.seChamber1(null);
			oskMembers.setRelationship1(null);
			oskMembers.setRelationship2(null);
			oskMembers.seChamber2(null);
		}
	}, []);

	return (
		<Page
			title={oskMembers.list.getTitle()}
			description=""
			authorized
		>
			<h1>
				{oskMembers.list.getTitle()}
			</h1>
			<List
				list={oskMembers.list}
				advancedFilter={<>
					<div className="row mb-3">
						<div className="col-md-3">
							<label className="inp-label inp-label--checkbox inp-label--filter">
								<input
									type="checkbox"
									name="checkbox"
									value="0"
									className="inp-checkbox"
									checked={oskMembers.getChamber1() ? true : false}
									onChange={e => oskMembers.seChamber1(e.target.checked ? 1 : null)}
								/>
								<span>
									Členové OSK
								</span>
							</label>
						</div>
						<div className="col-md-3">
							<label className="inp-label inp-label--checkbox inp-label--filter">
								<input
									type="checkbox"
									name="checkbox"
									value="0"
									className="inp-checkbox"
									checked={oskMembers.getChamber2() ? true : false}
									onChange={e => oskMembers.seChamber2(e.target.checked ? 2 : null)}
								/>
								<span>
									Hostující členové
								</span>
							</label>
						</div>
					</div>
					<div className="row mb-3">
						<div className="col-md-3">
							<label className="inp-label inp-label--checkbox inp-label--filter">
								<input
									type="checkbox"
									name="checkbox"
									value="0"
									className="inp-checkbox"
									checked={oskMembers.getRelationship1() ? true : false}
									onChange={e => oskMembers.setRelationship1(e.target.checked ? 1 : null)}
								/>
								<span>
									Vztah OSK
								</span>
							</label>
						</div>
						<div className="col-md-3">
							<label className="inp-label inp-label--checkbox inp-label--filter">
								<input
									type="checkbox"
									name="checkbox"
									value="0"
									className="inp-checkbox"
									checked={oskMembers.getRelationship2() ? true : false}
									onChange={e => oskMembers.setRelationship2(e.target.checked ? 2 : null)}
								/>
								<span>
									Vztah Bez OSK+
								</span>
							</label>
						</div>
					</div>
				</>}
				extraButton={<Button className="ml-2" theme="primary" onClick={oskMembers.export}>
					Export
				</Button>}
				itemNode={(item, field) => {
					switch (field) {
						case "is_new": return item[field] ? <span className="osk-members-page__label-new">Nový</span> : <></>;
						case "full_name": return <Link to={"/osk/profil/" + item.id}>{item.full_name}</Link>;
						default: return undefined;
					}
				}}
			/>
		</Page>
	);
}

export default state.bindContainers(
	OskMemebersPage,
	context => context.oskMembers
);
