/**
 * Dialog pro resource
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "./Dialog";
import Button from "../../../components/controls/Button";
import EnterPressArea from "../../../components/controls/EnterPressArea";
import HtmlEditor from "../../../components/controls/HtmlEditor";

function ResourceDialog() {
	const { dynamicDetail } = state.useStateContext();
	const dialog = dynamicDetail.dialog;

	function handleHtmlEditor(value: string) {
		if (value) {
			const detail = dialog.getCustomFields();
			const item = detail.item;
			item.text = value;
			dialog.setCustomField("item", item);
		}
	}

	function setTitle(title: string) {
		const detail = dialog.getCustomFields();
		const item = detail.item;
		item.title = title;
		dialog.setCustomField("item", item);
	}

	return (
		<Dialog className="dialog-min-width-900" dialog={dialog}>
			<EnterPressArea>
				<h1 className="pb-1">Detail obsahu</h1>
				<div className="text-center">
					<div className="row">
						<div className="col">
							<label htmlFor="res-key" className="inp-label float-left">
								Nadpis
							</label>
							<span className="inp-fix">
								<input
									id="res-key"
									style={{ background: "white" }}
									type="text"
									className="inp-text mb-3"
									value={dialog.getCustomField("item").title}
									onChange={e => setTitle(e.target.value)}
								/>
							</span>
						</div>
					</div>
					<div className="row mt-2 text-left">
						<div className="col">
							<label className="inp-label" htmlFor={"body"}>Obsah</label>
							<span className="inp-fix">
								<HtmlEditor
									onChange={(value: any) => handleHtmlEditor(value)}
									value={dialog.getCustomField("item").text}
								/>
							</span>
						</div>
					</div>
				</div>
				<div className="mt-4 text-center">
					<Button onClick={e => dynamicDetail.save()} className="pt-2 pb-2 float-right">
						<strong>Uložit a zavřít</strong>
					</Button>
				</div>
			</EnterPressArea>
		</Dialog >
	);
}

export default state.bindContainers(ResourceDialog,
	context => context.dynamicDetail.dialog
);