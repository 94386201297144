/**
 * Stránka veřejné
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function PublicPage() {
	const { contents } = state.useStateContext();

	return (
		<Page
			title={contents.getRes("verejne-nadpis")}
			description={contents.getRes("verejne-nadpis")}
		>
			<h1>
				<HtmlContent
					htmlKey="verejne-nadpis" />
			</h1>
			<HtmlContent
				htmlKey="verejne-obsah" />

		</Page>
	);
}