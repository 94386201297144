/**
 *  Formulář žádost o péče o dítě
 */

import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import WebRequest from "../../../components/templates/WebRequest";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";

interface WebRequestChildCarePageProps {
	id?: string;
}

function WebRequestChildCarePage(props: WebRequestChildCarePageProps) {
	const title = "ČESTNÉ PROHLÁŠENÍ – PÉČE O DÍTĚ DO 4 LET VĚKU";
	const { webRequestChildCare: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p>
				Čestně prohlašuji, že osobně celodenně pečuji o dítě do 4 let věku, tj. dítě není svěřeno do péče jiné osobě ani není umístěno v jeslích/mateřské
				škole, popř. jiném obdobném zařízení na dobu, která převyšuje 4 hodiny denně.
			</p>
			<p>
				Dále čestně prohlašuji, že nejsem ustanoven/a do funkce odborného zástupce, primáře ani vedoucího lékaře u žádného poskytovatele zdravotních
				služeb v oboru zubního lékařství, orální a maxilofaciální chirurgie, ortodoncie nebo klinická stomatologie.
			</p>
			<div><span className="font-weight-bold">Datum narození dítěte</span> (ne starší než 4 roky) <span className="form-item__required ml-1">*</span></div>
			<div className="row">
				<div className="col-md-5">
					<FormsInput form={form} field="date_of_child" inputProps={{ type: "date", min: formatting.formatDate(formatting.getDateBefore(new Date(), -1456), { format: "iso" }) }} />
				</div>
			</div>

			<strong>Poučení:</strong><br />
			<ul className="mb-5">
				<li><small>Pečují-li o dítě členové Komory, kteří žijí ve společné domácnosti, toto prohlášení může podat pouze jeden z nich.</small></li>
				<li><small>Toto prohlášení je platné do odvolání nebo oznámení změny rozhodných skutečností, nejpozději do dne 4. narozenin dítěte, na které se vztahuje.</small></li>
			</ul>

			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestChildCarePage,
	c => c.webRequestChildCare
);