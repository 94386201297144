/**
 * Stránka novinek
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

function DynamicListItemPage() {
	const title = "Aktualita";
	const context = state.useStateContext();
	const item = context.dynamicListItem.getItem();
	const justLoading = context.newsDetail.getJustLoading();

	function reload() {
		context.dynamicListItem.loadData(item?.id!)
	}

	return (
		<Page title={item?.title ?? ""} description="">
			<h1>
				{justLoading &&
					<><Spinner className="mr-1" /> Načítám obsah...</>
				}
				{item?.title}
			</h1>
			<p className="u-mb-lg">
				<HtmlContent item={item} isDetail={true} content={item?.text} customReload={reload} />
			</p>
		</Page >
	);
}

export default state.bindContainers(
	DynamicListItemPage,
	c => c.dynamicListItem
);