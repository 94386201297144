/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";
import Spinner from "../../../components/controls/Spinner";

function OskProfilePage() {
	const { contents } = state.useStateContext();
	const model = state.useStateContext().oskProfile;
	const justLoading = model.justLoading();
	const myProfile = model.stateContainer.get().profile;

	return (
		<Page
			title={contents.getRes("osk-profil-nadpis")}
			description={contents.getRes("osk-profil-nadpis")}
			authorized
		>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="osk-profil-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>
			{myProfile &&
				<div className="box-profile">
					<div className="anchor" id="profil" />
					<h2 className="form-title d-flex align-items-center">
						{justLoading &&
							<Spinner small className="mr-2" />
						}
						Profil zubního lékaře
					</h2>

					<div className="message message--flex u-mb-lg">
						<div className="message__inner">
							<span>
								Datum posledního potvrzení správnosti členských údajů provedené členem.
							</span>
							<span>
								<strong>{formatting.formatDate(myProfile?.date_of_last_confirmation)}</strong>
							</span>
						</div>
					</div>

					<div className="u-mb-lg">
						<div className="row">
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Titul před
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.x_title_before}
										</span>
									</div>
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Jméno
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.first_name}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Příjmení
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.last_name}
										</span>
									</div>
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Prostřední
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.middle_name}
										</span>
									</div>
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Titul za
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.x_title_after}
										</span>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											IKČ
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.identification_number}
										</span>
									</div>
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Kat. příspěvků
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.member_category?.name}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Hl. oblast
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.regional_chamber_main?.name}
										</span>
									</div>
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Hostující oblasti
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.visiting_regional_chambers.map(i => i.name).join(", ")}
										</span>
									</div>
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Pedagogický titul
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.x_title_pedagogical}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="anchor" id="kontakty" />
					<h2 className="form-title">
						Adresa
					</h2>

					<div className="u-mb-lg">
						<div className="row">
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 mb-4">
										&nbsp;
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Ulice a číslo
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.address?.street}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Obec
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.address?.city}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											PSČ
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.address?.postcode}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Stát
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.address?.country?.name}
										</span>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 mb-4 mt-4 mt-lg-0">
										<em>Kontaktní adresa</em>
									</div>
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Ulice a číslo
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.delivery_address?.street}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Obec
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.delivery_address?.city}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											PSČ
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.delivery_address?.postcode}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Stát
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.delivery_address?.country?.name}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<h2 className="form-title">
						Kontakty
					</h2>
					<div className="u-mb-lg">
						<div className="row">
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Telefon
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.contact?.phone1}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											E-mail
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.contact?.email1}
										</span>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div className="anchor" id="detail" />
					<h2 className="form-title">
						Detail
					</h2>

					<div className="u-mb-lg">
						<div className="row">
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Datum narození
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{formatting.formatDate(myProfile?.birthdate)}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Pohlaví
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.gender?.name}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Státní příslušnost
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.nationality?.name}
										</span>
									</div>
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Platnost pov. pobytu
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.validity_of_the_residence_permit &&
												<>{formatting.formatDate(myProfile.validity_of_the_residence_permit)}</>
											}
										</span>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Důvod ukonč. členství
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{myProfile?.reason_for_termination_of_membership}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Členství od
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{formatting.formatDate(myProfile?.membership_from)}
										</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">
											Členství do
										</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">
											{formatting.formatDate(myProfile?.membership_to)}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					{myProfile?.child_birthdate &&
						<>
							<div className="anchor" id="prohlaseni" />
							<h2 className="form-title">
								Prohlášení
							</h2>
							<div className="message u-mb-md">
								<p>
									Čestne prohlašuji, že osobně celodenně pečuji o dítě do 4 let věku.
								</p>
							</div>
							<div className="u-mb-lg">
								<div className="row">
									<div className="col-12 col-lg-6 u-mb-md">
										<div className="row align-items-center">
											<div className="col-12 col-sm-4 col-md-5">
												<span className="box-profile__label">
													Datum narození dítěte
												</span>
											</div>
											<div className="col-12 col-sm-8 col-md-7">
												<span className="box-profile__value">
													{formatting.formatDate(myProfile.child_birthdate)}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					}

					<div className="anchor" id="mailing" />
					<h2 className="form-title">
						Odběry informací
					</h2>

					{myProfile?.send_bulk_emails &&
						<div className="message u-mb-md">
							<p>
								Chci dostávat hromadné maily.
							</p>
						</div>
					}

					{myProfile?.send_monitoring &&
						<div className="message u-mb-md">
							<p>
								Chci dostávat monitoring.
							</p>
						</div>
					}

					{myProfile?.journal_subscribe &&
						<div className="message u-mb-md">
							<p>
								Chci dostávat časopis LKS (na adresu: {myProfile.journal_address.name}).
							</p>
						</div>
					}

					{!(myProfile?.send_bulk_emails || myProfile?.send_monitoring || myProfile?.journal_subscribe) &&
						<p>
							Žádný odběr informací.
						</p>
					}

				</div>
			}
		</Page>
	);
}

export default state.bindContainers(
	OskProfilePage,
	context => context.oskProfile
);