/**
 * Dms list
 */
import React from "react";
import * as state from "../../../lib/state";
import * as apiLib from "../../../lib/api";
import List from "../../../modules/system/lists/List";
import * as apiDms from "./api-dms";
import Icon from "../../../modules/system/icons/Icon";
import Button from "../../../components/controls/Button";

export default function ListDms() {
	const { dmsDialog, api } = state.useStateContext();

	async function handleClick(item: any) {
		if (item.id != apiLib.emptyGuid && item.dms_file_type!.id != apiDms.DocumentType.Folder) {
			await api.download("/dms_files" + "/" + item.id + "/download", item.name, undefined);
			return;
		}
		if (item.dms_file_type!.id == apiDms.DocumentType.Folder) {
			const path = dmsDialog.stateContainer.get().path;
			path.push({ id: item.id, name: item.name, index: path.length });
			await dmsDialog.stateContainer.merge(_ => ({
				idParent: item.id,
				path: path
			}));
			dmsDialog.list.loadWithResetPage();
		}
	}

	async function handleSelect(item: any) {
		dmsDialog.stateContainer.get().onSet!(item);
		dmsDialog.dmsDialog.close();
	}

	async function handleRoot() {
		await dmsDialog.stateContainer.merge(_ => ({
			idParent: undefined,
			path: []
		}));
		dmsDialog.list.loadWithResetPage();
	}

	async function handlePath(index: number) {
		const path = dmsDialog.stateContainer.get().path;
		if (index + 1 != path.length) {
			const pathObject = path[index];
			await dmsDialog.stateContainer.merge(_ => ({
				idParent: undefined,
				path: []
			}));
			const slice = dmsDialog.stateContainer.get().path.slice(0, index + 1);
			await dmsDialog.stateContainer.merge(_ => ({
				path: slice
			}));
		}
	}

	return (
		<>
			<div className="document-list-path">
				<Icon size="2x" className="document-list-folder" picture="Folder" />
				<div className="document-list-full-path ml-2">
					<span className="ml-2">{'>'}</span>
					<div onClick={() => handleRoot()} className="document-list-path-item ml-2 font-weight-bold">root</div>
					{dmsDialog.stateContainer.get().path.map((i, index) =>
						<>
							<span className="ml-2">{'>'}</span>
							<div onClick={() => handlePath(index)} className="document-list-path-item ml-2">{i.name}</div>
						</>
					)}
				</div>
			</div>
			<List
				list={dmsDialog.list}
				itemNode={(item, field) => {
					switch (field) {
						case "name": return <span className="span-as-hyperlink" onClick={() => handleClick(item)}>{item.name}</span>;
						case "action" as any: return item.dms_file_type!.id as any != apiDms.DocumentType.Folder ? <Button theme="primary" onClick={() => handleSelect(item)} className="mr-2 btn-sm p-2">Převzít</Button> : <></>;
						case "dms_file_type.name" as any: return item.dms_file_type!.id as any == apiDms.DocumentType.Folder ?
							<Icon className="folder-font" size="2x" picture="Folder" /> : <></>;
						default: return undefined;
					}
				}}
			/>
		</>
	);
}