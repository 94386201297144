/**
 * Modul seznamu žádostí
 */

import * as state from "../../lib/state";
import * as api from "../../lib/api";
import * as context from "../../context";
import * as lists from "../../modules/system/lists/lists";
import * as apiWebRequests from "../../modules/shared/web-request/api-web-request";

export interface State {
	id: string;
	justSending: boolean;
}

export class Model {
	stateContainer: state.StateContainer<State>;
	list: lists.StandardList<apiWebRequests.WebRequestDocument>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiWebRequests.WebRequestDocument>({
			context: context,
			title: "Seznam dokumentů",
			quickFilterPlaceholder: "Vyhledejte dokument...",
			filterSystem: _ => [
				api.qp("request.id" as any, "=", this.getId())
			],
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiWebRequests.documents,
			sortingFields: [],
			grid: {
				minWidth: "1200px",
				columns: [
					{
						field: "name",
						type: "string",
						title: "Název"
					},
					{
						field: "last_change" as any,
						type: "string",
						title: "Datum vytvoření",
						width: "200px"
					},
					{
						field: "action" as any,
						type: "string",
						title: "Akce",
						width: "175px"
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		this.stateContainer = new state.StateContainer<State>({
			id: api.emptyGuid,
			justSending: false
		}, context);

	}

	getId = () => {
		return this.stateContainer.get().id;
	}

	getJustSending = () => {
		return this.stateContainer.get().justSending;
	}

	setJustSending = async (value: boolean) => {
		await this.stateContainer.merge(_ => ({
			justSending: value
		}));
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async (id: string) => {
		await this.stateContainer.merge(_ => ({
			id: id
		}));
		await this.list.loadWithResetPage();
		await this.stateContainer.merge(_ => ({
			id: id
		}));
	}
}