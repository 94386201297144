/**
 * Registrace akce
 */
import React from "react";
import * as navigation from "../../../lib/navigation";
import * as state from "../../../lib/state";


import Page from "../../../components/templates/Page";
import Button from "../../../components/controls/Button";
import HtmlContent from "../../../components/controls/HtmlContent";


export default function EducationActionELearningRegistrationPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("registrace-e-learningova-akce-nadpis")} description={contents.getRes("registrace-e-learningova-akce-nadpis")}>
			<HtmlContent htmlKey="registrace-e-learningova-akce-obsah" />
			<div className="text-center">
				<Button
					theme="primary"
					onClick={() => navigation.to("/moje-agenda/zadosti/zadost-o-registraci-e-lerningove-akce")}>
					Žádost o novou akci
				</Button>
			</div>

		</Page>
	);
}