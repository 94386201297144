/**
 * Seznam členů OSK
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiRegionalMembers from "../../shared/regional-chambers/api-regional-chambers";

/**
 * Stav modulu
 */
export interface State {
	justSending: boolean;
	id_member_regional_chamber_type1: number | null;
	id_member_regional_chamber_type2: number | null;
	id_regional_chamber_relationship1: number | null;
	id_regional_chamber_relationship2: number | null;
}

export class Model {
	stateContainer: state.StateContainer<State>;
	list: lists.StandardList<apiRegionalMembers.RegionalChamberMember>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiRegionalMembers.RegionalChamberMember>({
			context: context,
			title: "Seznam členů komory",
			quickFilterPlaceholder: "Vyhledejte člena...",
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiRegionalChambers.members,
			defaultSorting: { field: "is_new", order: lists.SortingOrder.Desc },
			filterSystem: _ => [
				api.qp("id_member_regional_chamber_type" as any, "=", this.getChamber1() ? 1 : this.getChamber2() ? 2 : null),
				api.qp("id_regional_chamber_relationship" as any, "=", this.getRelationship1() ? 1 : this.getRelationship2() ? 2 : null)
			],
			grid: {
				minWidth: "1200px",
				columns: [
					{
						field: "full_name",
						type: "string",
						title: "Jméno"
					},
					{
						field: "is_new",
						type: "boolean",
						title: "Nový",
						width: "100px"
					},
					{
						field: "identification_number",
						type: "string",
						title: "IKČ",
						width: "120px"
					},
					{
						field: "contact.full" as any,
						type: "string",
						title: "Kontakt",
					},
					{
						field: "workplaces" as any,
						type: "string",
						title: "Pracoviště"
					},
					{
						field: "member_category.name" as any,
						type: "string",
						width: "250px",
						title: "Kategorie příspěvku"
					}
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
		this.stateContainer = new state.StateContainer<State>({
			justSending: false,
			id_member_regional_chamber_type1: 1,
			id_member_regional_chamber_type2: null,
			id_regional_chamber_relationship1: 1,
			id_regional_chamber_relationship2: null
		}, context);
	}

	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.isOSKMember())
			await this.list.loadWithResetPage();
	}

	justSending = () => {
		return this.stateContainer.get().justSending;
	}

	export = async () => {
		await this.stateContainer.merge(prevState => ({
			justSending: true
		}));
		await this.context.api.downloadList("/regional_chamber/members/export", "export.xlsx");
		await this.stateContainer.merge(prevState => ({
			justSending: false
		}));
	}

	getChamber1 = () => {
		return this.stateContainer.get().id_member_regional_chamber_type1;
	}

	getChamber2 = () => {
		return this.stateContainer.get().id_member_regional_chamber_type2;
	}

	seChamber1 = async (value: number | null) => {
		await this.stateContainer.merge(prevState => ({
			id_member_regional_chamber_type1: value,
			id_member_regional_chamber_type2: null
		}));
		this.list.delayedLoadWithResetPage();
	}

	seChamber2 = async (value: number | null) => {
		await this.stateContainer.merge(prevState => ({
			id_member_regional_chamber_type2: value,
			id_member_regional_chamber_type1: null
		}));
		this.list.delayedLoadWithResetPage();
	}

	getRelationship1 = () => {
		return this.stateContainer.get().id_regional_chamber_relationship1;
	}

	getRelationship2 = () => {
		return this.stateContainer.get().id_regional_chamber_relationship2;
	}

	setRelationship1 = async (value: number | null) => {
		await this.stateContainer.merge(prevState => ({
			id_regional_chamber_relationship1: value,
			id_regional_chamber_relationship2: null
		}));
		this.list.delayedLoadWithResetPage();
	}

	setRelationship2 = async (value: number | null) => {
		await this.stateContainer.merge(prevState => ({
			id_regional_chamber_relationship2: value,
			id_regional_chamber_relationship1: null
		}));
		this.list.delayedLoadWithResetPage();
	}
}