/*
 * Box pro radio/checkbox buttony.
 */
import * as React from "react";
import { Tooltip } from "react-tippy";

export type CheckBoxRadioFrameProps = React.HTMLProps<HTMLDivElement> & {
	readOnly?: boolean;
	invalidMessage?: string;
};

export default function CheckRadioBoxFrame(props: CheckBoxRadioFrameProps) {
	const { className, ...restProps } = props;
	const invalid = (props.invalidMessage?.length ?? 0) > 0;

	return (
		<Tooltip
			className="d-block form-item__tooltip"
			arrow
			position="bottom-start"
			title={props.invalidMessage}
			trigger="mouseenter"
			disabled={!invalid}
		>
			<div className={
				"checkbox-radio-frame " + (props.readOnly ? "checkbox-radio-frame--readonly " : "")
				+ (invalid ? "--invalid " : "")
				+ props.className}
				{...restProps}>{props.children}
			</div>
		</Tooltip>
	);
}