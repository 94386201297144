import * as context from "../../../context";

export interface PaymentConfirm {
	PRCODE: number;
	SRCODE: string;
	ORDERNUMBER: number;
}

export interface PaymentConfirmResult {
	code: number;
	text: string;
}

export class Api {
	constructor(private context: context.StateContext) {
	}

	confirm = (confirmationData: PaymentConfirm) => {
		return this.context.api.post<PaymentConfirm, PaymentConfirmResult>("/payments/gateway/confirm", confirmationData);
	}
}