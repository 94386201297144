import React from "react";
import IconSvg from "./IconSvg";

interface FileListProps {
	className?: string;
	items: {
		fileName: string;
		link: string;
		icon: string;
	}[];
}

export default function FileList(props: FileListProps) {
	return (
		<div className={"box-files " + (props.className ?? "")}>
			<div className="box-files__title secondary-title">
				Soubory ke stažení
			</div>
			<div className="box-files__inner">
				{props.items.map(i =>
					<div className="box-files__item">
						<IconSvg name={i.icon} />
						<a href={i.link} target="_blank" className="box-files__file">
							{i.fileName}
						</a>
					</div>
				)}
			</div>
		</div>
	);
}