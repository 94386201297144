/**
 * Model žádosti o akreditaci
 * 
 * */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";
import * as file from "../../system/file/api-file";
import * as apiFile from "../../system/file/api-file";
import * as validations from "../../../lib/validations";
import * as api from "../../../lib/api";
import * as apiWorkplace from "../../shared/workplace/api-workplace";

export interface State {
	requestType: "i" | "ii";
}

export interface FormFields {
	type: "child-stomatology" | "paradantology" | "stomatology-surgery";
	provider: string;
	clear: boolean;
	title_before: string;
	title_after: string;
	name: string;
	lastname: string;
	tel: string;
	email: string;

	doctor: string;

	doctor_title_before: string;
	doctor_title_after: string;
	doctor_name: string;
	doctor_lastname: string;
	doctor_email: string;

	certificate: "parodontology" | "stomatochirurgy" | "pedostomatology";

	specialist_years: string;
	specialist_specialization: string;

	range: string;
	theoretical_sylabus: string;
	practical_sylabus: string;

	file_declaration?: file.File;
	file_information?: file.File;

	primary_chamber?: api.CodeBookItem;
	host_chamber?: api.CodeBookItem;
	workplace?: apiWorkplace.Workplace;
	workplace_new_name: string;
	workplace_new_provider: string;
	workplace_new_provider_address_street: string;
	workplace_new_provider_address_city: string;
	workplace_new_provider_address_postcode: string;

	workplace_new_address_street: string;
	workplace_new_address_city: string;
	workplace_new_address_postcode: string;
	workplace_new_contact_phone: string;
	workplace_new_contact_email: string;
	workplace_new_ico: string;
	certificate_file?: apiFile.File;
	certificate_doctor_name: string;
	certificate_name: string;
	relationship_legal_person: boolean;
	relationship_statutory_authority: boolean;
	relationship_osvc: boolean;
	relationship_employee: boolean;
	relationship_professional_representative: boolean;
	relationship_chief_physician: boolean;
	relationship_primary: boolean;
	request_workplace_type?: "existing_workplace" | "hosting_workplace" | "not_found";
	attachments: apiFile.FileFormFields[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;

	public common: webRequest.Model;
	public stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.stateContainer = new state.StateContainer<State>({
			requestType: "i"
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				type: {
					title: "",
					defaultValue: "child-stomatology"
				},
				clear: { title: "", defaultValue: false },
				provider: {
					title: "Vyberte poskytovatele zdravotních služeb",
					defaultValue: ""
				},
				title_before: {
					title: "Titul před",
					defaultValue: ""
				},
				title_after: {
					title: "Titul za",
					defaultValue: ""
				},
				name: {
					title: "Jméno",
					defaultValue: "",
					// required: true,
					// validate: value => value.trim().length == 0 ? "Jméno je povinný údaj" : ""
				},
				lastname: {
					title: "Příjmení",
					defaultValue: "",
					// required: true,
					// validate: value => value.trim().length == 0 ? "Příjmení je povinný údaj" : ""
				},
				tel: {
					title: "Telefon",
					placeHolderText: "+420 XXX XXX XXX",
					defaultValue: ""
				},
				email: {
					title: "E-mail",
					defaultValue: "",
					// required: true,
					// validate: value => value.trim().length == 0 ? "E-mail je povinný údaj" : ""
				},

				doctor: {
					title: "IKČ načíst",
					defaultValue: ""
				},

				doctor_title_before: {
					title: "Titul před",
					defaultValue: ""
				},
				doctor_title_after: {
					title: "Titul za",
					defaultValue: ""
				},
				doctor_name: {
					title: "Jméno",
					defaultValue: ""
				},
				doctor_lastname: {
					title: "Příjmení",
					defaultValue: ""
				},
				doctor_email: {
					title: "E-mail",
					defaultValue: ""
				},

				certificate: {
					title: "Držitel platného osvědčení",
					defaultValue: "parodontology",
					required: true,
					validate: value => value.trim().length == 0 ? "Držitel je povinný údaj" : ""
				},

				specialist_years: {
					title: "Specialista v oboru / let (Počet let)",
					defaultValue: "0"
				},
				specialist_specialization: {
					title: "Specialista v oboru",
					defaultValue: ""
				},
				range: {
					title: "Rozsah poskytovaných zdravotních služeb(Stručná charakterictika zdravotních služeb, které poskytovatel poskytuje)",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length == 0 ? "Rozsah je povinný údaj" : ""
				},
				theoretical_sylabus: {
					title: "Sylabus teoretické části stáží",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length == 0 ? "Sylabus je povinný údaj" : ""
				},
				practical_sylabus: {
					title: "Sylabus praktické části stáží",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length == 0 ? "Rozsah je povinný údaj" : ""
				},

				file_declaration: {
					title: () => this.stateContainer && this.stateContainer.get().requestType == "i" ? "Prohlášení odpovědného zubního lékaře" : "Sylabus teoretické i praktické výuky, který musí odpovídat rozsahu zdravotních služeb poskytovaných poskytovatelem, včetně informace o rozsahu poskytovaných zdravotních služeb",
					defaultValue: undefined
				},
				file_information: {
					title: () => this.stateContainer && this.stateContainer.get().requestType == "i" ? "Informace o rozsahu poskytovaných zdravotních služeb a sylabus teoretické i praktické výuky" : "Podepsaný návrh dohody o akreditaci typ II mezi Komorou a poskytovatelem",
					defaultValue: undefined,
					// required: true,
					// validate: value => value == undefined ? "Informace o rozsahu je povinný údaj" : ""
				},
				primary_chamber: {
					title: "Jsem zapsán/a v seznamu členů Oblastní komory (hlavní)",
					defaultValue: undefined
				},
				workplace: {
					title: "Vyberte poskytovatele zdravotní péče a pracoviště (nově ohlašované - dle IČO).",
					defaultValue: undefined,
					onChange: (value) => this.form.setField("host_chamber", value == null ? undefined : value.regional_chamber!),
					required: (form) => form.getField("request_workplace_type").value == "existing_workplace" ? true : false,
					validate: (value, field, form) => !value && form.getField("request_workplace_type").value == "existing_workplace" ? "Poskytovatel je povinné pole" : ""
				},
				workplace_new_provider: {
					title: "Název poskytovatele",
					defaultValue: ""
				},
				workplace_new_provider_address_street: {
					title: "Ulice a číslo (sídlo poskytovatele)",
					defaultValue: ""
				},
				workplace_new_provider_address_city: {
					title: "Obec (sídlo poskytovatele)",
					defaultValue: ""
				},
				workplace_new_provider_address_postcode: {
					title: "PSČ (sídlo poskytovatele)",
					defaultValue: "",
					validate: value => value.trim().length == 5 || value.trim().length == 0 ? "" : "PSČ musí obsahovat 5 znaků"
				},
				workplace_new_name: {
					title: "Název pracoviště",
					defaultValue: ""
				},
				workplace_new_address_street: {
					title: "Ulice a číslo (pracoviště)",
					defaultValue: ""
				},
				workplace_new_address_city: {
					title: "Obec (pracoviště)",
					defaultValue: ""
				},
				workplace_new_address_postcode: {
					title: "PSČ (pracoviště)",
					defaultValue: "",
					validate: value => value.trim().length == 5 || value.trim().length == 0 ? "" : "PSČ musí obsahovat 5 znaků"
				},
				workplace_new_contact_phone: {
					title: "Telefon (pracoviště)",
					placeHolderText: "+420 XXX XXX XXX",
					defaultValue: "",
					validate: value => !validations.isPhoneNumber(value) && value.length > 0 ? "Telefon je v chybném formát" : ""
				},
				workplace_new_contact_email: {
					title: "E-mail (pracoviště)",
					defaultValue: ""
				},
				workplace_new_ico: {
					title: "IČO",
					defaultValue: ""
				},
				certificate_doctor_name: {
					title: "Lékař",
					defaultValue: ""
				},
				certificate_name: {
					title: "Název rozhodnutí",
					defaultValue: ""
				},
				host_chamber: {
					title: "",
					defaultValue: undefined,
					readOnly: (form) => form.getField("request_workplace_type").value == "not_found" ? false : true,
				},
				relationship_legal_person: {
					title: "Společník nebo člen právnické osoby poskytovalele",
					defaultValue: false
				},
				relationship_statutory_authority: {
					title: "Statutární orgán nebo člen statut. orgánu (např. jednatel)",
					defaultValue: false
				},
				relationship_osvc: {
					title: "OSVČ",
					defaultValue: false
				},
				relationship_employee: {
					title: "Zaměstnanec",
					defaultValue: false
				},
				relationship_professional_representative: {
					title: "Odborný zástupce",
					defaultValue: false
				},
				relationship_chief_physician: {
					title: "Vedoucí lékař",
					defaultValue: false
				},
				relationship_primary: {
					title: "Primář",
					defaultValue: false
				},
				request_workplace_type: {
					title: "",
					defaultValue: "existing_workplace"
				},
				attachments: {
					title: "",
					defaultValue: []
				},
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		...this.common.getStateContainers(),
		...this.form.getStateContainers(),
	]

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			await this.common.send(data, this.stateContainer.get().requestType === "i" ? "zadost-o-akreditaci-typu-i" : "zadost-o-akreditaci-typu-ii");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (type: "i" | "ii", webRequestId: string) => {
		await this.stateContainer.merge(_ => ({
			requestType: type
		}));
		await this.form.setField("clear", true);
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			await this.form.setFields(data);
		}
	}

	loadWorkplaces = async (prefix: string): Promise<apiWorkplace.Workplace[]> => {
		const workplaces = await this.context.apiWorkplace.standard.loadList({
			filter: prefix.length > 0
				? [
					api.qpOr([
						api.qp("name", "=", "*" + prefix + "*"),
						api.qp("provider.registration_number" as any, "=", prefix),
						api.qp("address.street" as any, "=", prefix + "*"),
						api.qp("address.city" as any, "=", prefix + "*")
					])
				]
				: []
		});
		return workplaces.data;
	}

	/**
	 * Načte a vrátí seznam oblastních komor pro našeptávač nové oblastní komory
	 */
	loadChambers = async (prefix: string): Promise<api.CodeBookItem[]> => {
		const chambers = await this.context.apiRegionalChambers.standard.loadList({
			filter: prefix.length > 0 ? [api.qp("name", "=", prefix + "*")] : []
		});
		return chambers.data.map(i => ({ id: i.id, name: i.name }));
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async (type: "i" | "ii") => {
		await this.stateContainer.merge(_ => ({
			requestType: type
		}));
		await this.form.setField("clear", true);
		await Promise.all([
			this.form.clearFields(),
			this.common.reset()
		]);
	}
}