/**
 * O nas, Ročenky
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

/**
 * Stav modelu
 */
interface State {
	section: { section: Section; data: apiDynamicLists.Item[] };
	justLoading: boolean;
}

interface Section {
	title: string;
	tag: string;
}

const section: Section = {
	title: "Ročenky",
	tag: "rocenky"
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			section: { section: section, data: [] },
			justLoading: false
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	justLoading = () => this.stateContainer.get().justLoading;

	private getItemsByTag = (tag: string, data: apiDynamicLists.Item[]) => {
		return data.filter(i => i.tags.find(i => i.name === tag) !== undefined);
	}

	/**
	 * Načte data
	 */
	loadData = async () => {
		const list = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiDynamicLists.standard.loadList({ sort_fields: ["order_number"], filter: [api.qp("tagsname" as any, "=", [section.tag])], per_page: 999 }),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(prevState => ({
			section: { ...prevState.section, data: this.getItemsByTag(prevState.section.section.tag, list.data) }
		}));
	}

	getSection = () => this.stateContainer.get().section;
}