/**
 * Jednání představenstva
 */

import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";

import Spinner from "../../../components/controls/Spinner";
import CardLink from "../../../components/controls/CardLink";
import HtmlContent from "../../../components/controls/HtmlContent";

function AgendaMeetingOfBoardDirectors() {
	const model = state.useStateContext().meetingOfBoardDirectors;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("jednani-predstavenstva-nadpis")} description={contents.getRes("jednani-predstavenstva-nadpis")}>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="jednani-predstavenstva-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>


			{model.getBoards().map(d =>
				<CardLink cardProps={{ className: "mb-3" }} href={"/agendy-komory/jednani-predstavenstva/" + d.id}>
					<HtmlContent content={d.title} />
				</CardLink>
			)}
		</Page>
	);
}

export default state.bindContainers(
	AgendaMeetingOfBoardDirectors,
	c => c.meetingOfBoardDirectors
);