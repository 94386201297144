/**
 * Kontakty, Seznam znalců
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

/**
 * Stav modulu
 */
interface State {
	sections: { section: Section; data: apiDynamicLists.Item[] }[];
	justLoading: boolean;
}

interface Section {
	title: string;
	tag: string;
}

const sections: Section[] = [
	{ title: "Parodontologie", tag: "znalci-paradontologie" },
	{ title: "Protetická stomatologie", tag: "znalci-prot-stomatologie" },
	{ title: "Ortodoncie", tag: "znalci-ortodoncie" },
	{ title: "Dětská stomatologie", tag: "znalci-detska-stomatologie" },
	{ title: "Ústní, čelistní a obličejová chirurgie", tag: "znalci-ustni-celistni-oblicejova-chirurgie" },
	{ title: "Záchovná stomatologie", tag: "znalci-zachovna-stomatologie" },
	{ title: "Ústní chirurgie", tag: "znalci-ustni-chirurgie" }
];

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			sections: sections.map(i => ({ section: i, data: [] })),
			justLoading: false
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	justLoading = () => {
		return this.stateContainer.get().justLoading;
	}

	private getItemsByTag = (tag: string, data: apiDynamicLists.Item[]) => {
		return data.filter(i => i.tags.find(i => i.name === tag) !== undefined);
	}

	/**
	 * Načte data
	 */
	loadData = async () => {
		const list = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiDynamicLists.standard.loadList({ filter: [api.qp("tagsname" as any, "=", sections.map(i => i.tag))], per_page: 999 }),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(prevState => ({
			sections: prevState.sections.map(i => ({ ...i, data: this.getItemsByTag(i.section.tag, list.data) }))
		}));
	}

	getSections = () => {
		return this.stateContainer.get().sections;
	}
}