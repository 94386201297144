import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiContact from "../../shared/contact/api-contact";
import * as apiMessages from "../../shared/messages/api-messages";
import * as apiWebRequest from "../../shared/web-request/api-web-request";
import * as apiPaymentPrescriptions from "../../shared/payment-prescriptions/api-payment-prescriptions";
import * as apiDocuments from "../../shared/documents/api-documents";

export interface RegionalChamberMember extends api.EntityReadBase {
	identification_number: string;
	member_category?: apiCodeList.CodeList;
	full_name: string;
	title_before: string;
	first_name: string;
	last_name: string;
	title_after: string;
	contact: apiContact.Contact;
	is_new: boolean;
	workplaces: string;
}

export interface RegionalChamberStatistics extends api.EntityReadBase {
	number_of_total_members: number;
	number_of_new_members: number;
	number_of_members_osk_plus: number;
	number_of_visiting_members: number;
	number_of_total_requests: number;
	number_of_new_requests: number;
	number_of_total_messages: number;
	number_of_new_messages: number;
	number_of_total_documents: number;
	number_of_new_documents: number;
	number_of_category_p: number;
	number_of_category_a: number;
	number_of_category_n: number;
}

export interface RegionalChamber extends api.EntityReadBase {
	name: string;
}

export interface RegionalChamberSimple extends api.CodeBookItemWithCode {
	secretariat: api.CodeBookItem;
}

export interface TransferFee extends api.EntityReadBase {
	transfer_fee_state: apiCodeList.CodeListSystem;
	date_of_transfer: Date;
	total_price_with_vat: number;
	code: string;
}

export interface TransferFeeItem extends api.EntityReadBase {
	payment_prescription: apiPaymentPrescriptions.PaymentPrescription;
	description: string;
	price_with_vat: number;
}

export class Api {
	standard: api.EntityApi<RegionalChamber, RegionalChamber>;
	members: api.EntityApi<RegionalChamberMember, RegionalChamberMember>;
	transferFees: api.EntityApi<TransferFee, TransferFee>;
	transferFeesItems: api.EntityApi<TransferFeeItem, TransferFeeItem>;
	requests: api.EntityApi<apiWebRequest.WebRequest, apiWebRequest.WebRequest>;
	messages: api.EntityApi<apiMessages.Message, apiMessages.Message>;
	documents: api.EntityApi<apiDocuments.DmsFile, apiDocuments.DmsFile>;

	constructor(private context: context.StateContext) {
		this.standard = new api.EntityApi<RegionalChamber, RegionalChamber>({
			api: context.api,
			path: "/regional_chambers"
		});
		this.members = new api.EntityApi<RegionalChamberMember, RegionalChamberMember>({
			api: context.api,
			path: "/regional_chamber/members"
		});
		this.transferFees = new api.EntityApi<TransferFee, TransferFee>({
			api: context.api,
			path: "/regional_chamber/transfer_fees"
		});
		this.transferFeesItems = new api.EntityApi<TransferFeeItem, TransferFeeItem>({
			api: context.api,
			path: "/regional_chamber/transfer_fees_items"
		});
		this.requests = new api.EntityApi<apiWebRequest.WebRequest, apiWebRequest.WebRequest>({
			api: context.api,
			path: "/regional_chamber/requests"
		});
		this.messages = new api.EntityApi<apiMessages.Message, apiMessages.Message>({
			api: context.api,
			path: "/regional_chamber/messages"
		});
		this.documents = new api.EntityApi<apiDocuments.DmsFile, apiDocuments.DmsFile>({
			api: context.api,
			path: "/regional_chamber/documents"
		});
	}

	loadStatistics = (options?: api.RequestOptions) => {
		return this.context.api.post<{}, RegionalChamberStatistics>("/regional_chambers/statistic", {}, options);
	}
}