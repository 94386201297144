/**
 * Článek publikace
 */

import * as apiPublications from "../../shared/publications/api-publications";
import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";

/**
 * Definice stavu
 */
interface State {
	content?: apiPublications.PublicationTopicContent;
	topic?: apiPublications.WebPublicationTopic;
	originalItems: apiPublications.PublicationTopicContent[];
	justLoadingContent: boolean;
	justLoadingTopic: boolean;
	isRoot: boolean;
	fulltext: string;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	/**
	*  Seznam zubařů
	*/
	public stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoadingContent: false,
			justLoadingTopic: false,
			originalItems: [],
			isRoot: true,
			fulltext: ""
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Načte článek (content) 
	 */
	loadContent = async (id: api.Id) => {
		const content = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingContent: true })),
			exec: () => this.context.apiPublications.contents.load(id),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingContent: false }))
		});
		await this.stateContainer.merge(_ => ({
			content: content
		}));
	}

	/**
	 * Načte Obsah (topic) 
	 */
	loadTopic = async (id: api.Id) => {
		const content = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingTopic: true })),
			exec: () => this.context.apiPublications.topics.load(id),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingTopic: false }))
		});
		await this.stateContainer.merge(_ => ({
			topic: { ...content, menuItems: content.items },
			originalItems: content.items
		}));
	}

	/**
	 * Načte data modulu
	 */
	loadData = async (id: api.Id) => {
		await this.stateContainer.merge(_ => ({
			content: undefined
		}));
		const param = api.getUrlParam("isRoot");
		if (param) {
			this.setIsRoot(true);
		}
		else {
			this.setIsRoot(false);
		}
		const isRoot = this.getIsRoot();
		if (!isRoot) {
			await this.loadContent(id);
			const content = this.stateContainer.get().content!;
			await this.loadTopic(content.publication_topic.id);
		}
		else {
			await this.loadTopic(id);
		}
	}

	setItems = async (items: apiPublications.PublicationTopicContent[]) => {
		await this.stateContainer.merge(prevState => ({
			topic: { ...prevState.topic!, items: items, menuItems: this.stateContainer.get().originalItems }!
		}));
	}

	resetItems = async () => {
		await this.stateContainer.merge(prevState => ({
			topic: { ...prevState.topic!, items: this.stateContainer.get().originalItems, menuItems: this.stateContainer.get().originalItems }!
		}));
	}

	setIsRoot = async (value: boolean) => {
		await this.stateContainer.merge(prevState => ({
			isRoot: value
		}));
	}

	setFulltext = async (value: string) => {
		await this.stateContainer.merge(prevState => ({
			fulltext: value
		}));
	}

	getJustLoadingContent = () => this.stateContainer.get().justLoadingContent;
	getJustLoadingTopic = () => this.stateContainer.get().justLoadingTopic;
	getContent = () => this.stateContainer.get().content;
	getTopic = () => this.stateContainer.get().topic;
	getIsRoot = () => this.stateContainer.get().isRoot;
	getFulltext = () => this.stateContainer.get().fulltext;
}