import React, { useState } from "react";
import Contact from "../controls/Contact";
import HtmlContent from "../controls/HtmlContent";
import MenuFooter from "../controls/MenuFooter";
import storage from "store";

export default function PageFooter() {
	const [checkedCookie, setCheckedCookie] = useState(storage.get("checkedCookie"));

	function setCookie(value: boolean) {
		setCheckedCookie(true);
		storage.set("checkedCookie", value);
	}

	return (
		<footer role="contentinfo" className="footer u-pt-lg u-pb-xl">
			<div className="row-main">
				<div className={"footer__inner "}>
					<div className="footer__item">
						<MenuFooter />
					</div>
					<div className="footer__item">
						<Contact />
					</div>
					{/* {!checkedCookie &&
						<div className="alert text-center cookiealert show" role="alert">
							<div className="row">
								<div className="col-12 col-lg-8 col-xl-8 p-5 bold text-left">
									<HtmlContent htmlKey="prohlaseni-o-ochrane-soukromi-info" />
								</div>
								<div className="col-12 col-lg-4 col-xl-4 p-5">
									<button onClick={() => setCookie(true)} type="button" className="btn mb-1 btn-primary btn-sm acceptcookies red mr-2">
										POVOLIT VŠECHNY COOKIES
									</button>
									<button onClick={() => setCookie(false)} type="button" className="btn mb-1 btn-primary btn-sm disacceptcookies mr-2">
										ODMÍTNĚTE VŠECHNY COOKIES
									</button>
								</div>
							</div>
						</div>
					} */}
				</div>
			</div>
		</footer>
	);
}