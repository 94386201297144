/*
 * Komponenta checkboxu.
 */
import * as React from "react";
import CheckboxRadio, { CheckBoxRadioProps } from "./CheckboxRadio";

export type CheckBoxProps = Omit<CheckBoxRadioProps, "type">;

export default function Checkbox(props: CheckBoxProps) {
	return (
		<CheckboxRadio toolip="string" type="checkbox" {...props} />
	);
}