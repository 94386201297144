/**
 * eLearning a webináře
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function AgendaMaterialPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("informacni-materialy-nadpis")} description={contents.getRes("informacni-materialy-nadpis")} authorized>
			<HtmlContent htmlKey="informacni-materialy-obsah" />
		</Page>
	);
}