/**
 *  Formulář žádosti o osvědčení po absolvování celoživotního vzdělávání
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormTextArea from "../../../components/controls/Forms/FormsTextArea";
import FormFileUpload from "../../system/file/FormFileUpload";
import Button from "../../../components/controls/Button";

interface WebRequestIssueCerificateAfterLifelongEducationPageProps {
	id?: string;
}

function WebRequestIssueCerificateAfterLifelongEducationPage(props: WebRequestIssueCerificateAfterLifelongEducationPageProps) {

	const title = "ŽÁDOST O VYDÁNÍ OSVĚDČENÍ PO ABSOLVOVÁNÍ CELOŽIVOTNÍHO VZDĚLÁVÁNÍ, MIMO SYSTÉM CELOŽIVOTNÍHO VZDĚLÁVÁNÍ KOMORY";

	const model = state.useStateContext().webRequestIssueCerificateAfterLifelongEducation;
	const form = model.form;

	return (
		<WebRequest model={model.common} title={title} description="" id={props.id}>

			<h2>Požadovaný typ osvědčení</h2>

			<CheckBoxRadioFrame className="mb-5">
				<div className="row">
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vppzl" title="v praktickém zubním lékařství (VPPZL)" />
					</div>
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vpchir" title="ve stomatochirurgii (VPCHIR)" />
					</div>
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vpparo" title="v parodontologii (VPPARO)" />
					</div>

					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vppedo" title="v pedostomatologii (VPPEDO)" />
					</div>
				</div>
			</CheckBoxRadioFrame>

			<h2>Celoživotní vzdělávání mimo systém celoživotního vzdělávání Komory</h2>
			<p>
				Popište prosím, jaké celoživotní vzdělávání jste absolvoval/a. K popisu případně použijte samostatný list/přílohu
			</p>
			<FormTextArea
				form={form}
				field="description"
				formItemOptions={{ hideTitle: true }}
			/>

			<h2>Přílohy k žádosti</h2>
			<p>
				K žádosti přiložte: kopie dokladů prokazujících výkon povolání, účast na celoživotním vzdělávání a způsob, rozsah, obsah a kvalitu tohoto
				vzdělání
			</p>

			{model.formsFiles.get().map(formAttachment =>
				<div className="row">
					<div className="col-8 col-lg-10">
						<FormFileUpload
							form={formAttachment}
							field="attachment"
							formItemOptions={{ hideTitle: true }}
							fileUploadProps={{ className: "mb-1 mb-lg-4" }}
						/>
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.formsFiles.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.formsFiles.add}>Přidat přílohu</Button>
			</div>

			<strong>Poučení:</strong>
			<p>
				<small>
					Představenstvo Komory může Vaší žádosti vyhovět, pokud čestně prohlásíte, že splňujete obecné podmínky pro vydání osvědčení, a věrohodnými doklady prokážete,
					že vykonáváte povolání zubního lékaře v rozsahu odpovídajícím příslušnému vzdělávacímu programu a že absolvujete celoživotní vzdělávání v zahraničí, které je co
					do způsobu, rozsahu, obsahu a kvality srovnatelné se vzděláváním v rámci vzdělávacích programů podle tohoto řádu; představenstvo Komory může vydání osvědčení
					podmínit absolvováním vzdělávací akce nebo akcí, stáže či jiné formy vzdělávání, získáním určitého počtu kreditů nebo absolvováním profesní zkoušky.
				</small>
			</p>


			<h2>Čestné prohlášení žadatele:</h2>

			<FormCheckBox
				form={form}
				field="affidavit"
			/>

			<strong>Za zpracování této žádosti je stanoven poplatek ve výši: {model.form.getField("amount").value} Kč.</strong>

			<p className="mb-5">
				Pokyn k úhradě poplatku obdržíte obratem po odeslání Vaší žádosti. Zaplatit můžete ihned platební kartou nebo bankovním převodem na
				základě předpisu, který Vám bude současně odeslán na Váš e-mail.
				Vaše žádost bude zpracována po připsání platby na účet ČSK.
			</p>

			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}

		</WebRequest>
	);
}



export default state.bindContainers(
	WebRequestIssueCerificateAfterLifelongEducationPage,
	c => c.webRequestIssueCerificateAfterLifelongEducation
);




