/**
 * Modul stránky novinek
 */
import * as state from "../../../lib/state";
import * as lists from "../../system/lists/lists";
import * as apiInformation from "../../shared/information/api-information";
import * as context from "../../../context";

/**
 * Modul stránky seznamu zubařů
 */
export class Model implements state.StateModel {
	public list: lists.StandardList<apiInformation.Information>;

	constructor(private context: context.StateContext, forMembers: boolean) {
		this.list = new lists.StandardList<apiInformation.Information>({
			context: context,
			standardEntityApi: forMembers ? context.apiInformation.standardForMembers : context.apiInformation.standard,
			loadFields: ["id", "title", "perex", "image", "valid_from"],
			defaultSorting: { field: "valid_from", order: lists.SortingOrder.Desc },
			defaultPageSize: 30,
		});
	}

	setList = async () => {
		this.list.setFilterFulltextPhrase(this.context.login.loginForm.getField("fulltext").value);
	}

	resetList = async () => {
		this.list.setFilterFulltextPhrase("");
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		...this.list.getStateContainers(),
		...this.context.login.loginForm.getStateContainers()
	]

	/**
	 * Načte data modulu
	 */
	loadData = async () => {
		await this.list.load();
	}
}