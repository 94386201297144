/**
 * Seznam mých akcí
 */
import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";
import * as lists from "../../system/lists/lists";
import * as navigation from "../../../lib/navigation";
import List from "../../system/lists/List";
import Link from "../../../components/controls/Link";
import Button from "../../../components/controls/Button";

export interface Props {
	list?: lists.StandardList<any, any>;
}

function handleOpen(id: string) {
	navigation.to("/moje-agenda/pracoviste/" + id);
}

function WorkplacesList(props: Props) {
	const { workplacesListMain } = state.useStateContext();
	return (
		<>
			<List simpleTable
				list={props.list == undefined ? workplacesListMain.listMain : props.list}
				className="workplace-inactive-list"
				itemNode={(item, field) => {
					const inactive = item.date_to && item.date_to < new Date();
					switch (field) {
						case "detail" as any: return <div className={(inactive ? "workplace-inactive " : "") + "text-center"}>
							{item.date_to && item.date_to < new Date() &&
								<>Neaktivní</>
							}
							{(!item.date_to || item.date_to > new Date()) &&
								<Button theme="primary" onClick={() => handleOpen(item.workplace.id)} className="mr-2">Detail</Button>
							}
						</div>;
						case "member_workplace_type" as any: return <div className={inactive ? "workplace-inactive " : ""}>
							<div>
								<b>{item.provider.name}</b>
							</div>
							<div>
								{item.relationships_string}
							</div>
						</div>;
						case "member_workplace_relationship" as any: return <div className={inactive ? "workplace-inactive " : ""}>
							<div>
								<b>{item.workplace.name}</b>
							</div>
							<div>
								{item.date_from &&
									"Od: " + formatting.formatDate(item.date_from)
								}
								{item.date_from && item.date_to &&
									"-"
								}
								{!item.date_from && !item.date_to &&
									"neuvedeno"
								}
								{item.date_to &&
									"Do: " + formatting.formatDate(item.date_to)
								}
							</div>
						</div>;
						case "workspace.regional_chamber.name" as any: return <div className={inactive ? "workplace-inactive " : ""}>
							<div>
								{item.workplace.regional_chamber?.name}
							</div>
						</div>;
						case "address" as any: return <div className={inactive ? "workplace-inactive " : ""}>
							<div>
								{item.workplace.address.street}
							</div>
							<div>
								{item.workplace.address.city}, {item.workplace.address.postcode}
							</div>
						</div>;
						default: return undefined;
					}
				}}
			/>
		</>
	);
}

export default state.bindContainers(
	WorkplacesList,
	context => context.workplacesListMain
);