import * as api from "../../../lib/api";
import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiMembers from "../../shared/members/api-members";

/**
 * Fieldy pro filtrování seznamu
 */
interface FilterFields {
	letter: string;
}

/**
 * Modul stránky abecedního seznamu zubařů
 */
export class Model implements state.StateModel {
	/**
	*  Seznam zubařů
	*/
	public list: lists.StandardList<apiMembers.WebMember, FilterFields>;

	constructor(context: context.StateContext) {

		/**
		 *  Seznam zubařů
		 */
		this.list = new lists.StandardList<apiMembers.WebMember, FilterFields>({
			context: context,
			standardEntityApi: context.apiMembers.standard,
			loadFields: ["id", "full_name"],
			defaultSorting: { field: "last_name", order: lists.SortingOrder.Asc },
			defaultPageSize: 30,
			defaultFilterMode: "advanced",
			quickFilterPlaceholder: "Člen",
			advancedFilter: {
				letter: {
					field: {
						title: "Písmeno",
						defaultValue: ""
					},
					query: (value) => value.length > 0 ? [api.qp("last_name", "=", value + "*")] : []
				}
			}
		});
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		...this.list.getStateContainers()
	]

	/**
	 * Načte data modulu
	 */
	loadData = async () => {
		await this.list.load();
	}
}