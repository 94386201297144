/**
 * Stránka novinek
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Paginator from "../../system/lists/ListPaginator";
import Spinner from "../../../components/controls/Spinner";
import TimeLine from "../../../components/controls/TimeLine";
import NewsTimeLineItem from "./NewsTimeLineItem";
import HtmlContent from "../../../components/controls/HtmlContent";
import EnterPressArea from "../../../components/controls/EnterPressArea";

interface NewsPageProps {
	forMembers?: boolean;
}

function NewsPage(props: NewsPageProps) {
	const app = state.useStateContext();
	const { contents } = state.useStateContext();
	const { login } = state.useStateContext();
	const news = props.forMembers ? app.newsForMembers : app.news;

	function getPageTitle() {
		return props.forMembers ? contents.getRes("aktuality-pro-cleny-nadpis") : contents.getRes("aktuality-nadpis");
	}

	async function handleSearch() {
		news.setList();
	}

	async function handleReset() {
		login.loginForm.setField("fulltext", "");
		news.resetList();
	}

	function handleValue(value: string) {
		login.loginForm.setField("fulltext", value);
	}

	return (
		<Page title={getPageTitle()} description="" authorized={props.forMembers}>

			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey={props.forMembers ? "aktuality-pro-cleny-nadpis" : "aktuality-nadpis"} />
				{news.list.justLoadingData() &&
					<Spinner small className="ml-1" />
				}
			</h1>
			<EnterPressArea onEnter={handleSearch}>
				<div className="form-filter u-mb-md">
					<div id="records" className="form-filter__inner row">
						<div className="col-12 col-sm-6 col-md-4 col-lg-5 u-mb-md">
							<label htmlFor="filter-search" className="inp-label u-vhide">
								Zadejte jméno člena
							</label>
							<span className="inp-fix">
								<input
									type="text"
									id="inp-filter-search"
									placeholder={"Hledat..."}
									className="inp-text inp-text--filter"
									value={login.loginForm.getField("fulltext").value}
									onChange={(e) => handleValue(e.target.value)}
								/>
							</span>
						</div>
						<div className="col-12 col-sm-6 col-md-auto u-mb-md ml-md-auto">
							<button type="submit" className="btn btn--filter" onClick={handleSearch}>
								<span className="btn__text">
									Vyhledat
								</span>
							</button>
						</div>
						<div className="col-12 col-sm-6 col-md-auto u-mb-md">
							<button type="submit" className="btn btn--filter" onClick={handleReset}>
								<span className="btn__text">
									Reset
								</span>
							</button>
						</div>
					</div>
				</div>
			</EnterPressArea>
			<div className="u-mb-lg">
				<TimeLine
					items={news.list.getData()}
					mark={i => formatting.formatDate(i.valid_from, { format: "long" })}
					content={i => <NewsTimeLineItem news={i} forMembers={props.forMembers} />}
				/>
			</div>
			<Paginator list={news.list} />
		</Page >
	);
}

export default state.bindContainers(
	NewsPage,
	c => c.news
);