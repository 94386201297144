import * as api from "../../../lib/api";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiUsers from "../../shared/users/api-users";
import * as context from "../../../context";

export interface PaymentPrescription extends api.EntityReadBase {
	code: string;
	text: string;
	member: apiUsers.UserSimple;
	payment_prescription_state: apiCodeList.CodeListSystem;
	invoice_date: Date;
	due_date: Date;
	payment_date?: Date;
	total_price_with_vat: number;
}

export class Api {
	user: api.EntityApi<PaymentPrescription, PaymentPrescription>;

	constructor(private context: context.StateContext) {
		this.user = new api.EntityApi<PaymentPrescription, PaymentPrescription>({
			api: context.api,
			path: "/users/payment_prescriptions"
		});
	}
}	