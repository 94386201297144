/**
 * Model oznámení o zahájení výkonu povolání na území oblastní komory
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiWorkplace from "../../shared/workplace/api-workplace";

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
		];
	}

	loadWorkplaces = async (prefix: string): Promise<apiWorkplace.Workplace[]> => {
		const workplaces = await this.context.apiWorkplace.standard.loadList({
			filter: prefix.length > 0
				? [
					api.qpOr([
						api.qp("provider.registration_number" as any, "=", prefix),
					])
				]
				: []
		});
		return workplaces.data;
	}

	/**
	 * Načte a vrátí seznam oblastních komor pro našeptávač nové oblastní komory
	 */
	loadChambers = async (prefix: string): Promise<api.CodeBookItem[]> => {
		const chambers = await this.context.apiRegionalChambers.standard.loadList({
			filter: prefix.length > 0 ? [api.qp("name", "=", prefix + "*")] : []
		});
		return chambers.data.map(i => ({ id: i.id, name: i.name }));
	}
}