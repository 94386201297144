/**
 * Ekonomická poradna
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function AgendaEconomicAdvicePage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("ekonomicka-poradna-nadpis")} description={contents.getRes("ekonomicka-poradna-nadpis")} authorized>
			<HtmlContent htmlKey="ekonomicka-poradna-obsah" />
		</Page>
	);
}