/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";

import Spinner from "../../../components/controls/Spinner";
import Page from "../../../components/templates/Page";
import CardExpandable from "../../../components/controls/CardExpandable";
import HtmlContent from "../../../components/controls/HtmlContent";

import useExclusiveSwitch from "../../../hooks/exclusive-switch";

function ContactsOskPage() {
	const cardSwitch = useExclusiveSwitch();

	const model = state.useStateContext().contactsOsk;

	return (
		<Page title="Kontakty OSK" description="">
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="kontakty-osk-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>
			<HtmlContent htmlKey="kontakty-osk-obsah" />
			<div className="row">
				{model.getContacts().map((s, i) =>
					<div className="col-12 col-md-6 col-lg-4">
						<CardExpandable
							open={cardSwitch.current === i.toString()}
							onToggle={() => cardSwitch.toggle(i.toString())}
							className="mb-gutter"
							cardTitle={<div className="mb-0 secondary-title">{s.title}</div>}
						>
							<HtmlContent content={s.text} />
						</CardExpandable>
					</div>
				)}
			</div>

		</Page >
	);
}


export default state.bindContainers(
	ContactsOskPage,
	c => c.contactsOsk
);