import * as forms from "../../../lib/forms";
import * as context from "../../../context";
import * as validations from "../../../lib/validations";
import * as navigation from "../../../lib/navigation";

interface Fields {
	identification_number: string;
	email: string;
	description: string;
}

export class Model {
	form: forms.Form<Fields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<Fields>({
			fields: {
				identification_number: {
					title: "IKČ",
					required: true,
					defaultValue: "",
					validate: (value) => value.trim().length === 0 ? "Vyplňte prosím IKČ" : ""
				},
				email: {
					title: "E-mail",
					required: true,
					defaultValue: "",
					validate: value => value.trim().length === 0
						? "Vyplňte prosím e-mail"
						: validations.validateEmailAddress(value),
				},
				description: {
					title: "Popis",
					required: true,
					defaultValue: "",
					validate: (value) => value.trim().length === 0 ? "Vyplňte prosím popis" : ""
				},
			}
		}, context);

	}

	/**
	* Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
		];
	}

	/**
	 * Provede přihlášení přes Link2stars
	 */
	doLogin = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.api.post("/auth/send_message", this.form.getFields());
			if (result) {
				await this.context.standardDialogs.openInformationDialog("Váš požadavek byl úspěšně odeslán.");
				navigation.to("/");
			}
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}
}