import * as React from "react";

import IconSvg from "./IconSvg";

interface SocialIconsProps {
	variant?: "footer";
}

export default function SocialIcons(props: SocialIconsProps) {
	return <div className={"social-icons " + (props.variant ? "social-icons--" + props.variant : "")}>
		<ul>
			<li>
				<a aria-label="externí odkaz na www.facebook.com" href="https://www.facebook.com/CeskaStomatologickaKomora" target="_blank" className="social-icons__link social-icons__link--facebook">
					<IconSvg name="facebook" />
				</a>
			</li>
			<li>
				<a aria-label="externí odkaz na twitter.com" href="https://twitter.com/stomatologove" target="_blank" className="social-icons__link social-icons__link--twitter">
					<IconSvg name="twitter" />
				</a>
			</li>
			<li>
				<a aria-label="externí odkaz na www.linkedin.com" href="https://www.linkedin.com/company/ceskastomatologickakomora/" target="_blank" className="social-icons__link social-icons__link--linkedin">
					<IconSvg name="linkedin" />
				</a>
			</li>
		</ul>
	</div>;
}