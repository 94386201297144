/**
 * Tlačítka pro stránkování seznamu.
 */

import * as React from "react";
import IconSvg from "./IconSvg";

import * as api from "../../lib/api";

export interface PaginatorProps {
	className?: string;
	listResponse: api.ListResponse<any>;
	onPageChanged: (page: number) => Promise<void>;
}

export default function Pagination(props: PaginatorProps) {

	async function handlePageSelected(page: number) {
		await props.onPageChanged(page);
	}

	let pagesCount = Math.ceil(props.listResponse.pagination.object_count / props.listResponse.pagination.per_page);
	if (pagesCount == 0)
		pagesCount = 1;

	if (pagesCount === 1) {
		return null;
	}

	return (
		<div className={"box-pager u-mb-lg " + (props.className ?? "")}>
			<div className="box-pager__inner">

				<button
					className={"box-pager__item box-pager__btn box-pager__btn--prev"}
					disabled={props.listResponse.pagination.page == 1}
					onClick={_ => handlePageSelected(1)}
				>
					<div className="box-pager__btn-txt">
						první strana
						</div>
					<IconSvg name="first" className="icon-svg--first" />
				</button>

				<button
					className="box-pager__item box-pager__btn box-pager__btn--prev"
					disabled={props.listResponse.pagination.page == 1}
					onClick={_ => handlePageSelected(props.listResponse.pagination.page - 1)}
				>
					<div className="box-pager__btn-txt">
						předchozí strana
					</div>
					<IconSvg name="prev" className="icon-svg--prev" />
				</button>

				<div className="box-pager__item">
					{props.listResponse.pagination.page} / {pagesCount}
				</div>

				<button
					className="box-pager__item box-pager__btn box-pager__btn--next"
					disabled={props.listResponse.pagination.page == pagesCount}
					onClick={_ => handlePageSelected(props.listResponse.pagination.page + 1)}
				>
					<div className="box-pager__btn-txt">
						následující strana
						</div>
					<IconSvg name="next" className="icon-svg--next" />
				</button>

				<button
					className="box-pager__item box-pager__btn box-pager__btn--next"
					disabled={props.listResponse.pagination.page == pagesCount}
					onClick={_ => handlePageSelected(pagesCount)}
				>
					<div className="box-pager__btn-txt">
						poslední strana
					</div>
					<IconSvg name="last" className="icon-svg--last" />
				</button>
			</div>
		</div>
	);
}