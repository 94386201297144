/**
 * Domovská stránka
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";

function RegistrationPage() {
	const { registration } = state.useStateContext();

	return (
		<Page title="Titulní stránka" description="">
			<div className="message u-mb-md u-text-center u-text-uppercase">
				<p>
					Pokud ještě nemáte registraci, zaregistrujte se.
				</p>
			</div>
			<h1 className="u-text-center mb-5">
				Registrujte se
			</h1>
			<div className="form-login u-max-500 u-mb-lg">
				<form action="" method="" id="form-login" className="form-login__form" autoComplete="off">
					<div className="mb-4">
						<FormsInput form={registration.form} field="first_name" inputProps={{ autoFocus: true, autoComplete: "new-password" }} />
					</div>
					<div className="mb-4">
						<FormsInput form={registration.form} field="last_name" inputProps={{ type: "text", autoComplete: "new-password" }} />
					</div>
					<div className="mb-4">
						<FormsInput form={registration.form} field="email" inputProps={{ type: "text", autoComplete: "new-password" }} />
					</div>
					<div className="row">
						<div className="col-12 col-md-6 u-mb-md">
							<FormsInput form={registration.form} field="password" inputProps={{ type: "password", autoComplete: "new-password" }} />
						</div>
						<div className="col-12 col-md-6 u-mb-md">
							<FormsInput form={registration.form} field="password_copy" inputProps={{ type: "password", autoComplete: "new-password" }} />
						</div>
					</div>
					
					<div className="text-center">
						<Button type="button" theme="primary" onClick={registration.doRegister}>
							Registrovat
						</Button>
					</div>
					
				</form>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	RegistrationPage,
	c => c.registration
);