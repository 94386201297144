/**
 *  Pracovně právní otázky
 */
import HtmlContent from "../../../components/controls/HtmlContent";
import * as state from "../../../lib/state";
import React from "react";

import Page from "../../../components/templates/Page";

export default function CoronavirusLaborLegalIssuesPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("pracovne-pravni-otazky-nadpis")} description={contents.getRes("pracovne-pravni-otazky-nadpis")} authorized>
			<HtmlContent htmlKey="pracovne-pravni-otazky-obsah" />
		</Page>
	);
}