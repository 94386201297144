/**
 * Detail pevodu na OSK
 */

import * as common from "../../../lib/common";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiRegionalMembers from "../../shared/regional-chambers/api-regional-chambers";

/**
 * Stav modulu
 */
export interface State {
	justloadingTransferFee: boolean;
	transferFee?: apiRegionalMembers.TransferFee;
	transferFeeId?: api.Id;
}

export class Model {
	private stateContainer: state.StateContainer<State>;
	list: lists.StandardList<apiRegionalMembers.TransferFeeItem>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ justloadingTransferFee: false }, context);
		this.list = new lists.StandardList<apiRegionalMembers.TransferFeeItem>({
			context: context,
			title: "Položky převodu na OSK",
			quickFilterPlaceholder: "Vyhledejte položku...",
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiRegionalChambers.transferFeesItems,
			filterSystem: _ => {
				const transferFeeId = this.stateContainer.get().transferFeeId;
				return transferFeeId ? [api.qp("transfer_fee.id" as any, "=", transferFeeId)] : [];
			},
			defaultSorting: { field: "payment_prescription.member.full_name" as any, order: lists.SortingOrder.Asc },
			grid: {
				minWidth: "600px",
				columns: [
					{
						field: "payment_prescription.member.full_name" as any,
						type: "string",
						title: "Člen"
					},
					{
						field: "description",
						type: "string",
						title: "Popis"
					},
					{
						field: "price_with_vat",
						type: "string",
						title: "Částka"
					},
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
	}

	getStateContainers = () => [this.stateContainer, ...this.list.getStateContainers()];

	loadTransferFee = async (transferFeeId: string) => {
		await this.stateContainer.merge(_ => ({
			transferFee: undefined
		}));
		const transferFee = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justloadingTransferFee: true })),
			exec: () => this.context.apiRegionalChambers.transferFees.load(transferFeeId),
			finish: () => this.stateContainer.merge(_ => ({ justloadingTransferFee: false }))
		});
		await this.stateContainer.merge(_ => ({
			transferFee: transferFee
		}));
	}

	/**
	 * Načte data modulu
	 */
	loadData = async (transferFeeId: string) => {
		if (this.context.authorization.isOSKMember()) {
			await this.stateContainer.merge(_ => ({
				transferFeeId: transferFeeId
			}));
			await Promise.all([
				this.loadTransferFee(transferFeeId),
				this.list.loadWithResetPage()
			]);
		}
	}

	getTransferFee = () => {
		return this.stateContainer.get().transferFee;
	}

	justLoadingTransferFee = () => {
		return this.stateContainer.get().justloadingTransferFee;
	}
}