import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiCodeList from "../../system/code-list/api-code-list";

export interface Workplace {
	id: string;
	name: string;
	address: Address;
	regional_chamber?: apiCodeList.CodeList;
}

export interface Address {
	street: string;
	city: string;
	postcode: string;
	country: string;
}

export interface Workplace extends api.EntityReadBase {
	provider: apiCodeList.CodeList;
	workplace: Workplace;
	relationships_string: string;
	member_workplace_relationship: apiCodeList.CodeList;
	member_workplace_type: apiCodeList.CodeListSystem;
	date_from: Date;
	date_to: Date;
}

export class Api {
	workplaces: api.EntityApi<Workplace, Workplace>;

	constructor(private context: context.StateContext) {
		this.workplaces = new api.EntityApi<Workplace, Workplace>({
			api: context.api,
			path: "/member/workplaces"
		});
	}

	loadWorkplaces = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/member/workplaces", { per_page: Number.MAX_SAFE_INTEGER });
	}
}