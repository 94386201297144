import React, { useEffect } from "react";

import * as common from "../../lib/common";
import * as state from "../../lib/state";
import * as navigation from "../../lib/navigation";

import FormsInput from "../controls/Forms/FormsInput";
import FormCheckBox from "../controls/Forms/FormCheckBox";
import Button from "../controls/Button";
import EnterPressArea from "../controls/EnterPressArea";
import Link from "../controls/Link";

function LoginForm() {
	const { login, authorization } = state.useStateContext();

	useEffect(() => {
		common.ignorePromises(login.loginForm.clearFields());
	}, []);

	return (
		<>
			<div className="message u-mb-md u-text-center u-text-uppercase">
				<p>
					Pro přístup do členské sekce je nutné se přihlásit.
				</p>
			</div>

			<div className="message u-mb-md u-text-center u-text-uppercase">
				<p>
					PŘIHLÁŠENÍ JE TŘEBA I U BÝVALÝCH ČLENŮ ČSK, KTEŘÍ MAJÍ ZÁJEM ČLENSTVÍ V ČSK OBNOVIT.
				</p>
			</div>

			<h1 className="u-text-center mb-5">
				Přihlaste se
			</h1>

			<div className="login-form u-max-500 u-mb-lg">
				<div id="form-login">
					<EnterPressArea onEnter={login.doLogin}>
						<FormsInput form={login.loginForm} field="username" inputProps={{ autoFocus: true, autoComplete: "new-password" }} />
						<FormsInput form={login.loginForm} field="password" inputProps={{ type: "password", autoComplete: "new-password" }} />
						<div className="row mb-4">
							<div className="col-12 mb-0 col-md-6 u-mb-md">
								<FormCheckBox form={login.loginForm} field="save" />
							</div>
							<div className="col-12 mb-0 col-md-6 u-mb-md d-flex align-items-center justify-content-end">
								<Link href="/zapomenute-heslo">
									Zapomenuté heslo?
								</Link>
							</div>
							<div className="col-12 text-right">
								<Link href="/problemy-s-prihlasenim">
									Pokud máte stále problém s přihlášením, klikněte <b>zde</b>.
								</Link>
							</div>
						</div>
						<p>
							<Button theme="primary" onClick={login.doLogin} spinner={authorization.justLoggingIn()}>Přihlásit se</Button>
							{/* <Button className="float-right" theme="primary" onClick={() => navigation.to("/registrace")}>Registrace</Button> */}
						</p>
					</EnterPressArea>
				</div>
			</div>

			<div className="message u-mb-md u-text-center u-text-uppercase">
				<p>
					POKUD JSTE NIKDY ČLENEM ČSK NEBYL/A, PODEJTE NEJPRVE ŽÁDOST O ZÁPIS DO SEZNAMU ČLENŮ ČSK A OSK <strong><Button className="ml-2" theme="primary" onClick={() => navigation.to("/o-nas/stante-se-clenem")} spinner={authorization.justLoggingIn()}>PODAT ŽÁDOST</Button></strong>
				</p>
			</div>
		</>
	);
}

export default state.bindContainers(
	LoginForm,
	context => context.login.loginForm
);