/**
 * Modul pro obsluhu uživatelského obsahu
 */
import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as common from "../../../lib/common";

export interface Content {
	key: string;
	text: string;
}

interface ContentMap {
	[key: string]: Content;
}

/**
 * Stav modulu
 */
export interface State {
	indicateLoading: boolean;
	loaded: boolean;
	contentMap: ContentMap;
}

/**
* Model stránky
*/
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			indicateLoading: false,
			loaded: false,
			contentMap: {}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Pomocná metoda zajišťující typovou obálku argumentu.
	 */
	createPartialState = (state: Partial<State>) => {
		return state;
	}

	/**
	 * Načte obsah ze serveru
	 */
	loadContents = () => {
		return common.withIndication({
			exec: async () => {
				const content = await this.context.api.post("/contents", {}) as any;;
				let contentMap: ContentMap = {};
				for (let item of content) {
					contentMap[item.key] = item;
				}
				await this.stateContainer.merge(_ => ({ contentMap: contentMap }));
			},
			indicateStart: () => this.stateContainer.merge(_ => ({ indicateLoading: true })),
			finish: () => this.stateContainer.merge(_ => ({ indicateLoading: false, loaded: true }))
		});

	}

	/**
	 * Má se indikovat načítání obsahu
	 */
	indicateLoading = () => {
		return this.stateContainer.get().indicateLoading;
	}

	/**
	 * Vrací obsah podle key
	 */
	getContent = (key: string) => {
		const contentMap = this.stateContainer.get().contentMap;
		return contentMap[key] ? contentMap[key].text : "";
	}

	/**
 * Vrací obsah podle key
 */
	getRes = (key: string) => {
		const contentMap = this.stateContainer.get().contentMap;
		return contentMap[key] ? contentMap[key].text.replace(/<[^>]*>?/gm, "").replace(/&nbsp;/g, " ") : "";
	}
}