/**
 * Model oznámení o zahájení výkonu povolání na území oblastní komory
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as api from "../../../lib/api";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";
import * as apiWorkplace from "../../shared/workplace/api-workplace";
import * as validations from "../../../lib/validations";
import * as formatting from "../../../lib/formatting";

/**
 * Definice dat formuláře
 */
export interface FormFields {
	primary_chamber?: api.CodeBookItem;
	host_chamber?: api.CodeBookItem;
	workplace?: apiWorkplace.Workplace;
	workplace_new_name: string;
	workplace_new_provider: string;
	workplace_new_provider_address_street: string;
	workplace_new_provider_address_city: string;
	workplace_new_provider_address_postcode: string;

	workplace_new_address_street: string;
	workplace_new_address_city: string;
	workplace_new_address_postcode: string;
	workplace_new_contact_phone: string;
	workplace_new_contact_email: string;
	workplace_new_ico: string;
	certificate_file?: apiFile.File;
	certificate_doctor_name: string;
	certificate_name: string;
	date: string;
	relationship_legal_person: boolean;
	relationship_statutory_authority: boolean;
	relationship_osvc: boolean;
	relationship_employee: boolean;
	relationship_professional_representative: boolean;
	relationship_chief_physician: boolean;
	relationship_primary: boolean;
	request_workplace_type?: "existing_workplace" | "hosting_workplace" | "not_found";
	announcement: boolean;
	attachments: apiFile.FileFormFields[];
	date_of_entry_into_force: string;
	date_of_validity_of_the_amendments: string;
	visiting_only_emergency_regional_chambers: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				primary_chamber: {
					title: "Jsem zapsán/a v seznamu členů Oblastní komory (hlavní)",
					defaultValue: undefined
				},
				date_of_entry_into_force: {
					title: "Datum nabytí právní moci Rozhodnutí",
					defaultValue: ""
				},
				date_of_validity_of_the_amendments: {
					title: "Datum platnosti změn uvedené v Rozhodnutí (je-li uvedeno)",
					defaultValue: ""
				},
				workplace: {
					title: "Vyberte poskytovatele zdravotní péče a pracoviště (nově ohlašované - dle IČO).",
					defaultValue: undefined,
					onChange: (value) => this.form.setField("host_chamber", value == null ? undefined : value.regional_chamber!),
					required: (form) => form.getField("request_workplace_type").value == "existing_workplace" ? true : false,
					validate: (value, field, form) => !value && form.getField("request_workplace_type").value == "existing_workplace" ? "Poskytovatel je povinné pole" : ""
				},
				workplace_new_provider: {
					title: "Název poskytovatele",
					defaultValue: ""
				},
				workplace_new_provider_address_street: {
					title: "Ulice a číslo (sídlo poskytovatele)",
					defaultValue: ""
				},
				workplace_new_provider_address_city: {
					title: "Obec (sídlo poskytovatele)",
					defaultValue: ""
				},
				visiting_only_emergency_regional_chambers: {
					title: "Hostující oblasti - jen pohotovost",
					defaultValue: false
				},
				workplace_new_provider_address_postcode: {
					title: "PSČ (sídlo poskytovatele)",
					defaultValue: "",
					validate: value => value.trim().length == 5 || value.trim().length == 0 ? "" : "PSČ musí obsahovat 5 znaků"
				},
				workplace_new_name: {
					title: "Název pracoviště",
					defaultValue: ""
				},
				workplace_new_address_street: {
					title: "Ulice a číslo (pracoviště)",
					defaultValue: ""
				},
				workplace_new_address_city: {
					title: "Obec (pracoviště)",
					defaultValue: ""
				},
				workplace_new_address_postcode: {
					title: "PSČ (pracoviště)",
					onChange: this.handleChamberByPostcode,
					defaultValue: "",
					validate: value => value.trim().length == 5 || value.trim().length == 0 ? "" : "PSČ musí obsahovat 5 znaků"
				},
				workplace_new_contact_phone: {
					title: "Telefon (pracoviště)",
					placeHolderText: "+420 XXX XXX XXX",
					defaultValue: "",
					validate: value => !validations.isPhoneNumber(value) && value.length > 0 ? "Telefon je v chybném formát" : ""
				},
				workplace_new_contact_email: {
					title: "E-mail (pracoviště)",
					defaultValue: ""
				},
				workplace_new_ico: {
					title: "IČO",
					defaultValue: ""
				},
				certificate_doctor_name: {
					title: "Lékař",
					defaultValue: ""
				},
				certificate_name: {
					title: "Název rozhodnutí",
					defaultValue: ""
				},
				date: {
					title: "ke dni",
					defaultValue: "",
					required: true,
					validate: value => !value ? "Ke dni je povinné pole" : formatting.getDateBefore(new Date(), -15) > new Date(value) ? "Datum nesmí být menší než 14 dní" : ""
				},
				host_chamber: {
					title: "",
					defaultValue: undefined,
					readOnly: (form) => form.getField("request_workplace_type").value == "not_found" ? false : true,
				},
				relationship_legal_person: {
					title: "Společník nebo člen právnické osoby poskytovalele",
					defaultValue: false
				},
				relationship_statutory_authority: {
					title: "Statutární orgán nebo člen statut. orgánu (např. jednatel)",
					defaultValue: false
				},
				relationship_osvc: {
					title: "OSVČ",
					defaultValue: false
				},
				relationship_employee: {
					title: "Zaměstnanec",
					defaultValue: false
				},
				relationship_professional_representative: {
					title: "Odborný zástupce",
					defaultValue: false
				},
				relationship_chief_physician: {
					title: "Vedoucí lékař",
					defaultValue: false
				},
				relationship_primary: {
					title: "Primář",
					defaultValue: false
				},
				request_workplace_type: {
					title: "",
					defaultValue: "existing_workplace"
				},
				announcement: {
					title: "Prohlašuji, že na uzemí této nové hostující Oblastní komory vykonávám pouze pohotovostní služby",
					defaultValue: false
				},
				attachments: {
					title: "",
					defaultValue: []
				}
			}
		}, context, this.form);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	handleChamberByPostcode = async (value: string) => {
		if (value.length == 5) {
			const chamber = await this.context.webRequests.getRegionalChamberByPostcode(value) as api.CodeBookItem;
			this.form.setField("host_chamber", chamber);
		}
	}

	loadPrimaryChamber = async (data?: any) => {
		let userProfile = this.context.authorization.getUserProfile();
		if (!userProfile) {
			userProfile = await this.context.authorization.loadUserProfile();
		}
		if (userProfile.profile.regional_chamber_main && !data) {
			await this.form.setField("primary_chamber", {
				id: userProfile.profile.regional_chamber_main.id,
				name: userProfile.profile.regional_chamber_main.name
			});
		}
		if (data.primary_chamber) {
			await this.form.setField("primary_chamber", {
				id: data.primary_chamber.id,
				name: data.primary_chamber.name
			});
		}
	}

	loadWorkplaces = async (prefix: string): Promise<apiWorkplace.Workplace[]> => {
		const workplaces = await this.context.apiWorkplace.standard.loadList({
			filter: prefix.length > 0
				? [
					api.qpOr([
						api.qp("name", "=", "*" + prefix + "*"),
						api.qp("provider.registration_number" as any, "=", prefix),
						api.qp("address.street" as any, "=", prefix + "*"),
						api.qp("address.city" as any, "=", prefix + "*")
					])
				]
				: []
		});
		return workplaces.data;
	}

	/**
	 * Načte a vrátí seznam oblastních komor pro našeptávač nové oblastní komory
	 */
	loadChambers = async (prefix: string): Promise<api.CodeBookItem[]> => {
		const chambers = await this.context.apiRegionalChambers.standard.loadList({
			filter: prefix.length > 0 ? [api.qp("name", "=", prefix + "*")] : []
		});
		return chambers.data.map(i => ({ id: i.id, name: i.name }));
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormFields = {
				...this.form.getFields(),
			};
			await this.common.send(data, "oznameni-o-zahajeni-vykonu");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			const { ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.loadPrimaryChamber(data)
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadPrimaryChamber()
		]);
	}
}