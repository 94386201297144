/**
 * Domovská stránka
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "./Dialog";
import Button from "../../../components/controls/Button";
import EnterPressArea from "../../../components/controls/EnterPressArea";

function SearchDialog() {
	const { standardDialogs } = state.useStateContext();
	const dialog = standardDialogs.searchDialog;

	return (
		<Dialog dialog={dialog} limitedWidth>
			<EnterPressArea onEnter={standardDialogs.saveSearchDialog}>
				<div className="text-right">
					<input
						type="text"
						placeholder="Vyhledat..."
						className="inp-text inp-text--filter mb-3"
						value={dialog.getCustomField("fulltext")}
						onChange={e => dialog.setCustomField("fulltext", e.target.value)}
					/>
					<Button className="mr-10 pt-2 pb-2" onClick={standardDialogs.saveSearchDialog}>
						Potvrdit
					</Button>
				</div>
			</EnterPressArea>
		</Dialog >
	);
}

export default state.bindContainers(SearchDialog,
	c => c.standardDialogs.searchDialog
);