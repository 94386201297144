/**
 * Model žádosti o opakování profesní zkoušky podle řádu o celoživotním vzdělávání zubních lékařů, účinného do 31. 12. 2016 
 */
import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as webRequest from "../../shared/web-request/web-request";
import * as forms from "../../../lib/forms";

export interface FormFields {
}

const formOptions: forms.FormOptions<FormFields> = {
	fields: {}
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public commonModel: webRequest.Model;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.commonModel = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		...this.commonModel.getStateContainers(),
	]

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.commonModel.loadData(webRequestId);
		const webRequest = this.commonModel.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			await this.form.setFields(data);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.commonModel.reset()
		]);
	}

}