/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";
import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";
import CardExpandable from "../../../components/controls/CardExpandable";
import useExclusiveSwitch from "../../../hooks/exclusive-switch";

function OskMemebersPage() {
	const { contents } = state.useStateContext();
	const model = state.useStateContext().oskMaterialsNegotiations;
	const cardSwitch = useExclusiveSwitch();

	return (
		<Page
			title={contents.getRes("materialy-pro-jednani-nadpis")}
			description=""
			authorized
		>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="materialy-pro-jednani-nadpis" />
			</h1>
			<div className="row">
				{model.getContacts().map((s, i) =>
					<div className="col-12">
						<CardExpandable
							open={cardSwitch.current === i.toString()}
							onToggle={() => cardSwitch.toggle(i.toString())}
							className="mb-gutter"
							cardTitle={<div className="mb-0 mt-0 secondary-title">{s.title}</div>}
						>
							<HtmlContent content={s.text} />
						</CardExpandable>
					</div>
				)}
			</div>
		</Page>
	);
}

export default state.bindContainers(
	OskMemebersPage,
	context => context.oskMaterialsNegotiations
);