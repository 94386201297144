/**
 * Domovská stránka
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "./Dialog";
import Button from "../../../components/controls/Button";
import EnterPressArea from "../../../components/controls/EnterPressArea";

function InformationDialog() {
	const { standardDialogs } = state.useStateContext();
	const dialog = standardDialogs.yesNoDialog;

	return (
		<Dialog dialog={dialog} limitedWidth>
			<EnterPressArea onEnter={dialog.close}>
				<div className="text-center mb-3">
					{dialog.getCustomField("message")}
				</div>
				<div className="mt-30 text-center">
					<Button className="mr-10 pt-2 pb-2" onClick={e => dialog.close("yes")}>
						<strong>{dialog.getCustomField("buttonYesTitle")}</strong>
					</Button>
					<Button className="ml-4 pt-2 pb-2" onClick={e => dialog.close("no")} theme="secondary">
						<strong>{dialog.getCustomField("buttonNoTitle")}</strong>
					</Button>
				</div>
			</EnterPressArea>
		</Dialog >
	);
}

export default state.bindContainers(InformationDialog,
	c => c.standardDialogs.yesNoDialog
);