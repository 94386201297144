/**
 * Seznam převodů na OSK
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiRegionalMembers from "../../shared/regional-chambers/api-regional-chambers";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiRegionalMembers.TransferFee>({
		context: context,
		title: "Seznam převodů na OSK",
		quickFilterPlaceholder: "Vyhledejte převod...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiRegionalChambers.transferFees,
		defaultSorting: { field: "date_of_transfer", order: lists.SortingOrder.Desc },
		grid: {
			minWidth: "600px",
			columns: [
				{
					field: "code",
					type: "string",
					title: "Kód"
				},
				{
					field: "transfer_fee_state.name" as any,
					type: "string",
					title: "Stav"
				},
				{
					field: "date_of_transfer",
					type: "date",
					title: "Datum převodu"
				},
				{
					field: "total_price_with_vat",
					type: "number",
					title: "Částka převodu"
				}
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiRegionalMembers.TransferFee>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.isOSKMember())
			await this.list.loadWithResetPage();
	}
}