import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as context from "../../../context";
import * as webRequestApi from "./api-web-request";

interface State {
	formShown: boolean;
	webRequest?: webRequestApi.WebRequest;
	justLoading: boolean;
	justSending: boolean;
	sent: boolean;
	sentText: string;
}

export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>(this.getDefaultState(), context);
	}

	private getDefaultState = (): State => {
		return {
			formShown: false,
			justLoading: false,
			justSending: false,
			sent: false,
			sentText: "",
			webRequest: undefined
		};
	}

	getStateContainers(): state.StateContainer<any>[] {
		return [this.stateContainer];
	}

	toggleForm = async () => {
		await this.stateContainer.merge(prevState => ({
			formShown: !prevState.formShown
		}));
	}

	formShown = () => {
		return this.stateContainer.get().formShown;
	}

	loadData = async (webRequestId: api.Id) => {
		if (this.context.authorization.userLoggedIn()) {
			const webRequest = await common.withIndication({
				indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
				exec: () => this.context.apiWebRequests.standard.load(webRequestId),
				finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
			});
			await this.reset();
			await this.stateContainer.merge(_ => ({
				webRequest: webRequest
			}));
		}
	}

	getWebRequest = () => {
		return this.stateContainer.get().webRequest;
	}

	send = async (data: object, requestType: webRequestApi.RequestType, urlParam?: string) => {
		const response = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justSending: true })),
			exec: () => this.context.apiWebRequests.send(data, requestType, undefined, urlParam),
			finish: () => this.stateContainer.merge(_ => ({ justSending: false }))
		});
		await this.stateContainer.merge(_ => ({
			sent: true,
			sentText: response.text
		}));
		return response;
	}

	justLoading = () => {
		return this.stateContainer.get().justLoading;
	}

	justSending = () => {
		return this.stateContainer.get().justSending;
	}

	sent = () => {
		return this.stateContainer.get().sent;
	}

	getSentText = () => {
		return this.stateContainer.get().sentText;
	}

	reset = async () => {
		await this.stateContainer.merge(_ => (this.getDefaultState()));
	}
}