/**
 * Modul domovské stránky
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiEducationalActions from "../../shared/educational-actions/api-educational-actions";
import * as apiInformation from "../../shared/information/api-information";

/**
 * Stav modulu
 */
interface State {
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;
	public actions: lists.StandardList<apiEducationalActions.WebEducationalAction>;
	public news: lists.StandardList<apiInformation.Information>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.actions = new lists.StandardList<apiEducationalActions.WebEducationalAction>({
			context: context,
			standardEntityApi: context.apiEducationalActions.standard,
			defaultSorting: { field: "date_from", order: lists.SortingOrder.Desc },
			filterSystem: _ => [
				// pouze akce (ne stáže)
				api.qp("educational_action_category.id" as any, "!=", 2),
				api.qp("educational_action_category.id" as any, "!=", 14)
			],
			defaultPageSize: 4
		});
		this.news = new lists.StandardList<apiInformation.Information>({
			context: context,
			standardEntityApi: context.apiInformation.standard,
			defaultSorting: { field: "valid_from", order: lists.SortingOrder.Desc },
			defaultPageSize: 4
		});
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.actions.getStateContainers(),
		...this.news.getStateContainers()
	]

	loadData = async () => {
		await Promise.all([
			this.actions.load(),
			this.news.load()
		]);
	}
}