/**
 * Stránka s akcí
 */
import * as React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import JoindedList from "../../../components/controls/JoinedList";
import HtmlContent from "../../../components/controls/HtmlContent";

function WorkPlacesPage() {
	const { contents } = state.useStateContext();
	const context = state.useStateContext();
	const workplaces = context.workplaces;

	return (
		<Page title={contents.getRes("akreditovana-pracoviste-nadpis")} description="">
			<HtmlContent htmlKey="akreditovana-pracoviste-obsah-horni" />

			<h2 className="u-color-text d-flex align-items-center">
				<span>Seznam akreditovaných pracovišť typu I.</span>
				{(workplaces.justLoadingAccreditationsTypeOne() || workplaces.justLoadingSpecializations()) &&
					<Spinner small className="ml-2" />
				}
			</h2>

			<div className="box-tabs">
				<ul className="box-tabs__list">
					{workplaces.getSpecializations().map(i =>
						<li className="box-tabs__item">
							<a href="#"
								className={"box-tabs__link " + (workplaces.getSpecializationForAccreditationsTypeOne()?.id === i.id ? "is-active" : "")}
								onClick={async (e) => { e.preventDefault(); await workplaces.setSpecializationForAccreditationsTypeOne(i); }}>
								{i.name}
							</a>
						</li>
					)}
				</ul>

				<div className="box-tabs__fragment">
					<div className="table-wrap">
						<table className="table-designed">
							<thead>
								<tr>
									<th>
										Provozovatel
									</th>
									<th>
										Adresa pracoviště
									</th>
									<th>
										Akreditace od
									</th>
									<th>
										Akreditace do
									</th>
								</tr>
							</thead>
							<tbody>
								{workplaces.getAccreditationsTypeOne().map(i =>
									<tr>
										<td>
											{i.provider.name}
										</td>
										<td>
											<JoindedList
												items={[
													i.workplace.address?.street,
													i.workplace.address?.city,
													i.workplace.address?.postcode,
												]}
												itemTemplate={(item) => item}
												delimiter=", "
											/>
										</td>
										<td>
											{formatting.formatDate(i.decision_date_from)}
										</td>
										<td>
											{formatting.formatDate(i.decision_date_to)}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<HtmlContent htmlKey="akreditovana-pracoviste-obsah-akreditace-typu-2" />

			<h2 className="u-color-text d-flex align-items-center">
				Seznam akreditovaných pracovišť typu II.
				{(workplaces.justLoadingAccreditationsTypeTwo() || workplaces.justLoadingSpecializations()) &&
					<Spinner small className="ml-2" />
				}
			</h2>

			<div className="box-tabs js-tabs">
				<ul className="box-tabs__list">
					{workplaces.getSpecializations().map(i =>
						<li className="box-tabs__item">
							<a href="#"
								className={"box-tabs__link " + (workplaces.getSpecializationForAccreditationsTypeTwo()?.id === i.id ? "is-active" : "")}
								onClick={async (e) => { e.preventDefault(); await workplaces.setSpecializationForAccreditationsTypeTwo(i); }}>
								{i.name}
							</a>
						</li>
					)}
				</ul>

				<div className="box-tabs__fragment">
					<div className="table-wrap">
						<table className="table-designed">
							<thead>
								<tr>
									<th>
										Provozovatel
									</th>
									<th>
										Adresa pracoviště
									</th>
									<th>
										Akreditace od
									</th>
									<th>
										Akreditace do
									</th>
								</tr>
							</thead>
							<tbody>
								{workplaces.getAccreditationsTypeTwo().map(i =>
									<tr>
										<td>
											{i.provider.name}
										</td>
										<td>
											<JoindedList
												items={[
													i.workplace.address?.street,
													i.workplace.address?.city,
													i.workplace.address?.postcode,
												]}
												itemTemplate={(item) => item}
												delimiter=", "
											/>
										</td>
										<td>
											{formatting.formatDate(i.decision_date_from)}
										</td>
										<td>
											{formatting.formatDate(i.decision_date_to)}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	WorkPlacesPage,
	c => c.workplaces
);