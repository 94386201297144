/**
 * Seznam knih
 */
import * as React from "react";
import * as formatting from "../../../lib/formatting";
import * as state from "../../../lib/state";
import * as apiShop from "./api-shop";

import Link from "../../../components/controls/Link";
import Button from "../../../components/controls/Button";

interface BooksListProps {
	className?: string;
	items: apiShop.ShopItemSimple[];
}

export default function BooksList(props: BooksListProps) {

	const { shopCart } = state.useStateContext();

	return (
		<div className={"row books-list__cards " + (props.className ?? "")}>
			{props.items.map(i =>
				<div className="col-md-6 col-lg-3  mb-5">
					<Link className="books-list__card" noStyle href={"/vzdelavani/knihy/" + i.id}>
						<img className="books-list__item-image mb-3" src={i.image_id} />
						<div>
							<p className="books-list__item-title">{i.name}</p>
							<div className="d-flex justify-content-between align-items-center">
								<strong>{formatting.formatCurrency(i.price)}</strong>
								<Button
									theme="primary"
									className="books-list__item-button-add"
									onClick={async e => { e.preventDefault(); await shopCart.addItem(i, 1); }}
								>
									Přidat do košíku
								</Button>
							</div>
						</div>
					</Link>
				</div>
			)}
		</div>
	);
}