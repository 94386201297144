/**
 * Žádost o zařazení do vstupního vzdělávacího programu
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as apiFile from "../../system/file/api-file";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

export interface ScopeOfInsurances {
	value: string;
	checked: boolean;
}

export interface FormFields {
	insurance_name: string;
	provider_address: string;
	representation: string;
	registration_number: string;
	identification_number: string;
	account_number: string;
	insurance: boolean;
	phone: string;
	mobile: string;
	email: string;
	mailing_address: string;
	basic_insurance_coverage: boolean;
	termination_of_insurances: ScopeOfInsurances[];
	extended_insurance_coverage: boolean;
	date: string;
	insurance_certificate_number: string;
	day?: number;
}

interface FormData extends FormFields {
	attachments: FilesFormFields[];
}

/**
 * Definice polí souboru přílohy
 */
export interface FilesFormFields {
	file?: apiFile.File;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public commonModel: webRequest.Model;
	public formsAttachment: forms.FormCollection<FilesFormFields>;

	createFilesFormOptions = (required?: () => boolean): forms.FormOptions<FilesFormFields> => {
		return {
			fields: {
				file: {
					title: "",
					defaultValue: undefined
				}
			}
		};
	}

	constructor(private context: context.StateContext) {
		this.commonModel = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				insurance_name: {
					title: "Titul, jméno a příjmení/ Název:",
					defaultValue: ""
				},
				insurance_certificate_number: {
					defaultValue: "",
					title: "Potvrzení o pojištění číslo:"
				},
				insurance: {
					defaultValue: false,
					title: ""
				},
				provider_address: {
					title: "Adresa sídla/ bydliště:",
					defaultValue: ""
				},
				representation: {
					title: "Zastoupená kým (jméno, příjmení, funkce) – vyplnit pouze u právnických osob:",
					defaultValue: ""
				},
				registration_number: {
					title: "IČ:",
					defaultValue: ""
				},
				identification_number: {
					title: "Rodné číslo: ",
					defaultValue: ""
				},
				account_number: {
					title: "Bankovní spojení:",
					defaultValue: ""
				},
				phone: {
					title: "Telefon:",
					defaultValue: ""
				},
				mobile: {
					title: "Mobilní telefon:",
					defaultValue: ""
				},
				email: {
					title: "E-mail:",
					defaultValue: ""
				},
				mailing_address: {
					title: "Korespondenční adresa (liší-li se od adresy sídla/ bydliště):",
					defaultValue: ""
				},
				basic_insurance_coverage: {
					title: "",
					defaultValue: false
				},
				termination_of_insurances: {
					title: "",
					defaultValue: [{ checked: false, value: "" }, { checked: false, value: "" }, { checked: false, value: "" }, { checked: false, value: "" }, { checked: false, value: "" }, { checked: false, value: "" }, { checked: false, value: "" }]
				},
				extended_insurance_coverage: {
					title: "",
					defaultValue: false
				},
				date: {
					title: "Zvolte měsíc odhlášení (nelze zpětně)",
					defaultValue: "",
					required: true,
					validate: value => value.length == 0 ? "Datum je povinné pole" : ""
				},
				day: {
					title: "Zvolte variantu dne",
					defaultValue: undefined,
					required: true,
					validate: value => !value ? "Variantu dne je povinné pole" : ""
				}
			}
		}, context);
		this.formsAttachment = new forms.FormCollection(this.createFilesFormOptions(), context, this.form);
		this.formsAttachment.add();
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => {
		return [
			...this.commonModel.getStateContainers(),
			...this.form.getStateContainers(),
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsAttachment.get()
					.filter(form => form.getField("file").value !== undefined)
					.map(form => form.getFields())
			}
			let code = "odhlaska-z-pojisteni";
			await this.commonModel.send(data, code as any);
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	loadProfileData = async () => {
		if (this.form.getField("insurance_name").value.trim().length > 0) {
			return;
		}

		if (!this.context.authorization.userLoggedIn()) {
			return;
		}

		let userProfile = this.context.authorization.getUserProfile();
		if (!userProfile) {
			userProfile = await this.context.authorization.loadUserProfile();
		}
		await this.form.setFields({
			insurance_name: userProfile.full_name,
			phone: userProfile.profile.contact?.phone1,
			mobile: userProfile.profile.contact?.phone2,
			email: userProfile.profile.contact?.email1,
			provider_address: userProfile.profile.address?.print
		});
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.commonModel.loadData(webRequestId);
		const webRequest = this.commonModel.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			await Promise.all([
				this.form.setFields(data),
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.commonModel.reset(),
			this.loadProfileData()
		]);
	}
}