/**
 * Modul stránky "Změna hesla"
 */

import * as state from "../../lib/state";
import * as context from "../../context";
import * as navigation from "../../lib/navigation";
import * as validation from "../../lib/validations";
import * as forms from "../../lib/forms";

export interface FormFields {
	password: string;
	confirm_password: string;
	old_password: string;
}

export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<FormFields>({
			fields: {
				password: {
					defaultValue: "",
					title: "Nové heslo",
					validate: value => this.validatePassword(value)
				},
				confirm_password: {
					defaultValue: "",
					title: "Potvrďte heslo",
					validate: value => this.validateConfirmPassword(value)
				},
				old_password: {
					defaultValue: "",
					title: "Staré heslo",
					validate: value => this.validateOldPassword(value)
				}
			}
		}, context);
	}

	/**
	 * Validuje password
	 */
	validateOldPassword = (value: string) => {
		return value.trim().length == 0 ? "Vyplňte prosím heslo" : "";
	}

	/**
	 * Validuje password
	 */
	validatePassword = (value: string) => {
		return validation.isValidPassword(value);
	}

	/**
	 * Validuje password
	 */
	validateConfirmPassword = (value: string) => {
		if (value != this.form.getField("password").value) {
			return "Heslo pro potvrzení musí být stejné";
		}

		return value.trim().length == 0 ? "Vyplňte prosím heslo" : "";
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers(),
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			const result = await this.context.apiUsers.changePassword(data.password, data.old_password);
			if (result) {
				navigation.to("/");
				await this.context.standardDialogs.openInformationDialog("Heslo bylo změněno.");
			}
		} else {
			await this.context.standardDialogs.openInformationDialog("Nejdříve je třeba potvrdit všechny kontroly.");
		}
	}

	/**
 * Provede reset formuláře
 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
		]);
	}
}