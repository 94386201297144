import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as navigation from "../../lib/navigation";
import * as state from "../../lib/state";
import * as common from "../../lib/common";

import usePopup from "../../hooks/popup";
import IconSvg from "./IconSvg";
import SlideDown from "./SlideDown";
import Button from "./Button";

export default function MenuDivision() {
	const popup = usePopup();
	const { authorization } = state.useStateContext();

	function onMenuTriggerClick(e: React.MouseEvent<HTMLElement>) {
		if (authorization.userLoggedIn()) {
			popup.toggle(e);
		} else {
			navigation.to("/prihlaseni");
		}
	}

	function getSectionName() {
		const sectionId = authorization.getCurrentSection();
		switch (sectionId) {
			case "aktuality-pro-cleny": return "Pro členy";
			case "osk": return "OSK";
			case undefined: return "";
			default: return common.unwanted(sectionId);
		}
	}

	const section = authorization.getCurrentSection();
	const isMember = authorization.getUserProfile()?.is_member;
	const isOsk = authorization.getUserProfile()?.is_osk;

	return (
		<nav
			role="navigation"
			id="menu-division"
			className={"menu-division " + (authorization.userLoggedIn() ? "menu-division--division " : "")}
		>
			<Button theme="primary" className="menu-division__burger" onClick={onMenuTriggerClick}>
				{getSectionName()} <IconSvg name="arrow-down" className="ml-2" />
			</Button>
			<SlideDown
				as="ul"
				className="menu-division__list"
				open={popup.isOpen}
				onClick={popup.close}
			>
				{authorization.userLoggedIn() &&
					<>
						{isMember &&
							<li className={"menu-division__item menu-division__item--division " + (section === "aktuality-pro-cleny" ? "--active" : "")}>
								<Link to="/aktuality-pro-cleny" onClick={_ => authorization.setCurrentSection("aktuality-pro-cleny")}>
									Pro členy
								</Link>
							</li>
						}
						{isOsk &&
							<li className={"menu-division__item menu-division__item--division " + (section === "osk" ? "--active" : "")}>
								<Link to="/osk" onClick={_ => authorization.setCurrentSection("osk")}>
									OSK
								</Link>
							</li>
						}
						{/* <li className="menu-division__item menu-division__item--division">
							<Link to="#">
								Pro firmy
							</Link>
						</li> */}
					</>
				}
			</SlideDown>
		</nav>
	);
}