import React, { useRef, useState, useEffect } from "react"
import * as state from "../../lib/state";
import * as apiLib from "../../lib/api";

const HtmlEditor = ({ value, onChange, disabled }: any) => {
	const editor = useRef(null);
	const { dmsDialog, api } = state.useStateContext();
	const [isLoaded, setIsLoaded] = useState(false);
	const [Editor, setEditor] = useState(null as any);
	useEffect(() => {
		import("jodit-react").then(res => {
			setEditor(res.default);
			setIsLoaded(true)
		});
	}, []);
	const [config] = useState({
		readonly: disabled,
		toolbar: true,
		language: "cs",
		cleanHTML: {
			fillEmptyParagraph: false
		},
		buttons: [
			...[
				{
					group: 'source',
					buttons: []
				},
				{
					group: 'font-style',
					buttons: []
				},
				{
					group: 'script',
					buttons: []
				},
				{
					group: 'list',
					buttons: ['ul', 'ol']
				},
				{
					group: 'indent',
					buttons: []
				},
				{
					group: 'font',
					buttons: []
				},
				{
					group: 'color',
					buttons: []
				},
				{
					group: 'media',
					buttons: []
				},
				'\n',
				{
					group: 'state',
					buttons: []
				},
				{
					group: 'clipboard',
					buttons: []
				},
				{
					group: 'insert',
					buttons: []
				},
				{
					group: 'form',
					buttons: []
				},
				{
					group: 'history',
					buttons: []
				},
				{
					group: 'search',
					buttons: []
				},
				{
					group: 'other',
					buttons: []
				},
				{
					group: 'info',
					buttons: []
				}
			] as any,
			{
				tooltip: 'Vloží dokument ze seznamu',
				icon: "file",
				exec: (editor: any) => {
					function setDmsDocument(item: apiLib.CodeBookItem) {
						editor.s.insertHTML(`<a class="list-item-body-link" download href="${api.options.endpoint}/dms_files/${item.id}/download">${item.name}</a>`);
						dmsDialog.dmsDialog.close();
					}
					dmsDialog.openDmsDialog(false, setDmsDocument);
				}
			},
			{
				tooltip: 'Vloží obrázek ze seznamu',
				icon: "image",
				exec: (editor: any) => {
					function setDmsDocument(item: apiLib.CodeBookItem) {
						editor.s.insertHTML(`<img class="list-item-body-image" src="${api.options.endpoint}/dms_files/${item.id}/image"/>`);
						dmsDialog.dmsDialog.close();
					}
					dmsDialog.openDmsDialog(true, setDmsDocument);
				}
			},
		],
		removeButtons: ["about", "fullsize"],
		enter: "div",
		i18n: {
			cs: {
				"Type something": "Napiště něco",
				"Your code is similar to HTML. Keep as HTML?": "Váš kód vypadá jako HTML. Ponechat jako HTML?",
				"Paste as HTML": "Kopírovat jako HTML",
				"Keep": "Ponechat",
				"Insert as Text": "Vložit jako text",
				"Cancel": "Zrušit",
				"Insert only Text": "Vložit pouze text",
				"Chars": "Znaků",
				"Words": "Slov"
			}
		}
	});
	if (!isLoaded) {
		return null;
	}
	else {
		setTimeout(onBlur, 200);
	}

	const handleBlur = (content: string) => {
		onChange(content);
	}

	function onBlur() {
		const editorRef = editor as any;
		const doc = editorRef.current.parentElement.querySelector(".ace_text-input") as any;
		if (doc) {
			doc.onblur = function () {
				const value = editorRef.current.parentElement.querySelector(".jodit-wysiwyg")!.innerHTML;
				handleBlur(value);
			}
		}
	}

	return (
		<>
			<Editor
				ref={editor}
				value={value}
				config={config as any}
				onBlur={handleBlur}
			/>
		</>
	)
}

export default HtmlEditor