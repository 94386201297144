/**
 * Seznam členů OSK
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as common from "../../../lib/common";
import * as lists from "../../system/lists/lists";
import * as apiRegionalMembers from "../../shared/regional-chambers/api-regional-chambers";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

/**
 * Stav modulu
 */
export interface State {
	data: apiDynamicLists.Item[];
	justLoading: boolean;
}

export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			data: [],
			justLoading: false
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	loadData = async () => {
		const list = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiDynamicLists.standard.loadList({ sort_fields: ["order_number"], filter: [api.qp("tagsname" as any, "=", ["materialy-ze-skoleni-osk"])], per_page: 999 }),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(_ => ({
			data: list.data
		}));
	}

	getContacts = () => this.stateContainer.get().data;

}