/**
 * Stránka pokladny e-shopu
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Link from "../../../components/controls/Link";
import Button from "../../../components/controls/Button";
import Checkbox from "../../../components/controls/Checkbox";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import LoginForm from "../../../components/sections/LoginForm";

function BooksCheckoutPage() {
	const { shopCart: cartModel, booksCheckout: checkoutModel, authorization } = state.useStateContext();
	const cart = cartModel.getCart();
	const form = checkoutModel.form;
	const title = "Pokladna";

	return (
		<Page title={title}>
			<h1 className="d-flex align-items-center mb-5">
				{title}
			</h1>


			{!checkoutModel.sent() &&
				<>
					{(cart?.items.length ?? 0) > 0 &&
						<>
							{!authorization.userLoggedIn() &&
								<Button onClick={checkoutModel.login}>Přihlásit se pro načtení údajů</Button>
							}

							<h2 className="mb-5">Fakturační údaje</h2>

							<div className="row mb-5">
								<div className="col-lg-4">
									<FormsInput form={form} field="first_name" inputProps={{ name: "first_name" }} />
								</div>
								<div className="col-lg-4">
									<FormsInput form={form} field="last_name" inputProps={{ name: "last_name" }} />
								</div>
								<div className="col-lg-4">
									<FormsInput form={form} field="company" inputProps={{ name: "company_name" }} />
								</div>
							</div >
							<div className="row">
								<div className="col-lg-6">
									<FormsInput form={form} field="street" inputProps={{ name: "street" }} />
									<FormsInput form={form} field="city" inputProps={{ name: "city" }} />
									<FormsInput hideNumeric={true} form={form} field="postcode" inputProps={{ name: "postcode", type: "number" }} />
								</div>
								<div className="col-lg-6">
									<FormsInput form={form} field="country" inputProps={{ name: "country" }} />
									<FormsInput form={form} field="state" inputProps={{ name: "state" }} />
								</div>
							</div >

							<h2 className="mb-5">Kontaktní údaje</h2>
							<div className="row">
								<div className="col-lg-6">
									<FormsInput form={form} field="phone" inputProps={{ name: "phone" }} />
								</div>
								<div className="col-lg-6">
									<FormsInput form={form} field="email" inputProps={{ type: "email", name: "email" }} />
								</div>
							</div >

							<h2>Shrnutí objednávky</h2>
							<table className="table-designed books-checkout-page__table-items mb-5">
								<thead>
									<tr>
										<th>Produkt</th>
										<th className="text-right" style={{ width: "8rem" }}>Mezisoučet</th>
									</tr>
								</thead>
								<tbody>
									{cart?.items.map(i =>
										<tr>
											<td>
												{i.shop_item.name} <strong>x{i.count}</strong>
											</td>
											<td className="text-right">
												{formatting.formatCurrency(i.shop_item.price * i.count)}
											</td>
										</tr>
									)}
									<tr>
										<th>Mezisoučet</th>
										<td className="text-right pl-3">
											{formatting.formatCurrency(cart?.sum)}
										</td>
									</tr>
									<tr>
										<th className="pb-3">
											Doprava a dobírka
										</th>
										<td className="text-right pl-3">
											<div>
												{formatting.formatCurrency(cart?.transport_price)}
											</div>
										</td>
									</tr>
									<tr>
										<th className="text-uppercase font-weight-bold">Cena celkem</th>
										<td className="text-right pl-3"><strong>{formatting.formatCurrency(cart?.total_price)}</strong></td>
									</tr>
								</tbody>
							</table>

							<p className="text-right">
								<strong>Platba hotově při doručení na dobírku.</strong>
							</p>

							{(cart?.items.length ?? 0 > 0) > 0 &&
								<div className="text-right">
									<Button className="mr-2" onClick={_ => history.back()}>&lt; Zpět</Button>
									<Button theme="primary" className="mb-5" onClick={checkoutModel.confirm}>Potvrdit objednávku</Button>
								</div>
							}
						</>
					}

					{(cart?.items.length ?? 0) === 0 &&
						<p className="mb-5 text-center">
							<strong>Váš košík je prázdný</strong>
						</p>
					}
				</>
			}

			{checkoutModel.sent() &&
				<p>
					Vaše objednávka byla úspěšně odeslána.<br /><br />
					<Link to="/vzdelavani/knihy">&lt; Zpět na seznam knih</Link>
				</p>
			}
		</Page >
	);
}

export default state.bindContainers(
	BooksCheckoutPage,
	c => c.booksCheckout
);