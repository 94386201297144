/**
 * Stránka seznamu stáží
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Paginator from "../../system/lists/ListPaginator";
import Spinner from "../../../components/controls/Spinner";
import TimeLine from "../../../components/controls/TimeLine";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import EducationalActionsTimeLineItemOriginal from "./EducationalActionsTimeLineItemOriginal";
import HtmlContent from "../../../components/controls/HtmlContent";

interface EducationalActionsProps {
	stages: boolean;
}

export default function EducationalActionsPage(props: EducationalActionsProps) {
	const title = "Seznam zubních lékařů a poskytovatelů zubní péče";
	const context = state.useStateContext();
	const actions = props.stages ? context.stages : context.educationalActions;
	const formFilter = actions.list.getAdvancedFilterForm();

	return (
		<Page title={title} description="">
			<h1>
				<HtmlContent htmlKey={props.stages ? "staze-nadpis" : "kalendar-akci-nadpis"} />
			</h1>

			<div className="form-filter u-mb-lg">
				<div id="records" className="form-filter__inner pt-3">
					<div className="row">
						<div className="col-md-7">
							<div className="row">
								<div className="col-12">
									<span className="inp-fix">
										<label>{props.stages ? "Vyhledat (např. parodontologie, chirurgie, pedostomatologie)" : "Vyhledat"}</label>
										<FormsInput
											form={formFilter}
											field="fulltext"
											formItemOptions={{ hideTitle: true }}
											inputProps={{
												className: "inp-text inp-text--filter"
											}}
										/>
									</span>
								</div>
							</div>
						</div>
						<div className="col-md-5 pt-4">
							<button type="submit" className="btn btn--filter" onClick={actions.list.clearFilter}>
								<span className="btn__text">
									Zrušit filtr
								</span>
							</button>
						</div>
					</div>
					{!props.stages &&
						<div className="row mb-3">
							<div className="col-md-7">
								<div className="row">
									<div className="col-lg-6">
										<span className="inp-fix inp-fix--requests">
											<FormsInput
												form={formFilter}
												field="dateFrom"
												inputProps={{
													type: "date",
													className: "inp-text inp-text--filter"
												}}
											/>
										</span>
									</div>
									<div className="col-lg-6">
										<span className="inp-fix inp-fix--requests">
											<FormsInput
												form={formFilter}
												field="dateTo"
												inputProps={{
													type: "date",
													className: "inp-text inp-text--filter"
												}}
											/>
										</span>
									</div>
								</div>
							</div>
						</div>
					}
					{!props.stages &&
						<>
							<div>
								<strong>
									{actions.justLoadingGroups() &&
										<div className="d-flex align-items-center">
											<Spinner small className="mr-2" /> Načítám pořadatele...
										</div>
									}
									{!actions.justLoadingGroups() &&
										<>Pořadatel:</>
									}
								</strong>
							</div>

							<div className="row mb-3">
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("vsc")}
											onChange={e => actions.setGroup("vsc", e.target.checked)}
										/>
										<span>
											Vzdělávací středisko ČSK
										</span>
									</label>
								</div>
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("rvsoo")}
											onChange={e => actions.setGroup("rvsoo", e.target.checked)}
										/>
										<span>
											Regionální vzdělávací středisko OSK Olomouc
										</span>
									</label>
								</div>
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("op")}
											onChange={e => actions.setGroup("op", e.target.checked)}
										/>
										<span>
											Ostatní pořadatelé
										</span>
									</label>
								</div>
							</div>
							<div>
								<strong>
									{actions.justLoadingGroups() &&
										<div className="d-flex align-items-center">
											<Spinner small className="mr-2" /> Načítám formy...
										</div>
									}
									{!actions.justLoadingGroups() &&
										<>Forma:</>
									}
								</strong>
							</div>

							<div className="row mb-3">
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("tk")}
											onChange={e => actions.setGroup("tk", e.target.checked)}
										/>
										<span>
											Teoretický kurz
										</span>
									</label>
								</div>
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("tpk")}
											onChange={e => actions.setGroup("tpk", e.target.checked)}
										/>
										<span>
											Teoreticko - praktický kurz
										</span>
									</label>
								</div>
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("elk")}
											onChange={e => actions.setGroup("elk", e.target.checked)}
										/>
										<span>
											E-learningový kurz
										</span>
									</label>
								</div>
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("ok")}
											onChange={e => actions.setGroup("ok", e.target.checked)}
										/>
										<span>
											On-line kurz
										</span>
									</label>
								</div>
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("pk")}
											onChange={e => actions.setGroup("pk", e.target.checked)}
										/>
										<span>
											Praktický kurz
										</span>
									</label>
								</div>
								<div className="col-md-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={actions.getGroups("vkpoz")}
											onChange={e => actions.setGroup("vkpoz", e.target.checked)}
										/>
										<span>
											Vzdělávací kurz pro odborné zástupce
										</span>
									</label>
								</div>
							</div>
						</>
					}
					{!props.stages &&
						<div>
							<strong>
								{actions.justLoadingGroups() &&
									<div className="d-flex align-items-center">
										<Spinner small className="mr-2" /> Načítám určení...
									</div>
								}
								{!actions.justLoadingGroups() &&
									<>Určení:</>
								}

							</strong>
						</div>
					}
					{!props.stages &&
						<div className="row mb-3">
							<div className="col-md-4">
								<label className="inp-label inp-label--checkbox inp-label--filter">
									<input
										type="checkbox"
										name="checkbox"
										value="0"
										className="inp-checkbox"
										checked={actions.getGroups("pzl")}
										onChange={e => actions.setGroup("pzl", e.target.checked)}
									/>
									<span>
										PZL
									</span>
								</label>
							</div>
							<div className="col-md-4">
								<label className="inp-label inp-label--checkbox inp-label--filter">
									<input
										type="checkbox"
										name="checkbox"
										value="0"
										className="inp-checkbox"
										checked={actions.getGroups("vppzl")}
										onChange={e => actions.setGroup("vppzl", e.target.checked)}
									/>
									<span>
										VPPZL - předepsaný kurz
									</span>
								</label>
							</div>
							<div className="col-md-4">
								<label className="inp-label inp-label--checkbox inp-label--filter">
									<input
										type="checkbox"
										name="checkbox"
										value="0"
										className="inp-checkbox"
										checked={actions.getGroups("dvppzl")}
										onChange={e => actions.setGroup("dvppzl", e.target.checked)}
									/>
									<span>
										DVPPZL - doporučený kurz
									</span>
								</label>
							</div>
							<div className="col-md-4">
								<label className="inp-label inp-label--checkbox inp-label--filter">
									<input
										type="checkbox"
										name="checkbox"
										value="0"
										className="inp-checkbox"
										checked={actions.getGroups("vpchir")}
										onChange={e => actions.setGroup("vpchir", e.target.checked)}
									/>
									<span>
										VPCHIR - předepsaný kurz
									</span>
								</label>
							</div>
							<div className="col-md-4">
								<label className="inp-label inp-label--checkbox inp-label--filter">
									<input
										type="checkbox"
										name="checkbox"
										value="0"
										className="inp-checkbox"
										checked={actions.getGroups("vpparo")}
										onChange={e => actions.setGroup("vpparo", e.target.checked)}
									/>
									<span>
										VPPARO - předepsaný kurz
									</span>
								</label>
							</div>
							<div className="col-md-4">
								<label className="inp-label inp-label--checkbox inp-label--filter">
									<input
										type="checkbox"
										name="checkbox"
										value="0"
										className="inp-checkbox"
										checked={actions.getGroups("vppedo")}
										onChange={e => actions.setGroup("vppedo", e.target.checked)}
									/>
									<span>
										VPPEDO - předepsaný kurz
									</span>
								</label>
							</div>
						</div>
					}
				</div>
			</div>

			<div className="u-mb-lg">
				<div className="mb-3">
					<HtmlContent htmlKey="akce-perex" />
				</div>
				<TimeLine
					items={actions.list.getData()}
					stages={true}
					mark={i => i.educational_action_category?.id == 26 ?
						<>
							<div>{formatting.formatDate(i.date_from)}</div>
							<div>-</div>
							<div>{formatting.formatDate(i.date_to)}</div>
						</> : <></>}
					range={i =>
						<>
							<div className="font-weight-bold">
								{!(formatting.formatCustomDate(i.date_from, "H:mm") != "0:00" && !formatting.isSameDate(i.date_from, i.date_to)) &&
									<>
										{props.stages ? i.date_from?.getFullYear() : formatting.formatCustomDate(i.date_from, "D. M. YYYY")}
									</>
								}
							</div>
							{i.educational_action_category?.id != 26 &&
								<>
									{!props.stages && formatting.formatCustomDate(i.date_from, "H:mm") != formatting.formatCustomDate(i.date_to, "H:mm") &&
										<>
											{formatting.formatCustomDate(i.date_from, "H:mm") != "0:00" && formatting.isSameDate(i.date_from, i.date_to) &&
												<>{formatting.formatCustomDate(i.date_from, "H:mm")} - </>
											}
											{formatting.formatCustomDate(i.date_from, "H:mm") != "0:00" && formatting.isSameDate(i.date_from, i.date_to) &&
												<>
													{formatting.formatCustomDate(i.date_to, "H:mm")}
												</>
											}
											{formatting.formatCustomDate(i.date_from, "H:mm") != "0:00" && !formatting.isSameDate(i.date_from, i.date_to) &&
												<>
													<div className="text-left font-weight-bold">{formatting.formatDate(i.date_from)} {formatting.formatCustomDate(i.date_from, "H:mm")}</div>
												</>
											}
											{formatting.formatCustomDate(i.date_from, "H:mm") != "0:00" && !formatting.isSameDate(i.date_from, i.date_to) &&
												<>
													<div className="text-left font-weight-bold">{formatting.formatDate(i.date_to)} {formatting.formatCustomDate(i.date_to, "H:mm")}</div>
												</>
											}
										</>
									}
									{!props.stages && formatting.formatCustomDate(i.date_from, "H:mm") == formatting.formatCustomDate(i.date_to, "H:mm") && formatting.formatCustomDate(i.date_from, "H:mm") != "0:00" &&
										<>
											{formatting.formatCustomDate(i.date_from, "H:mm")}
											{!formatting.isSameDate(i.date_from, i.date_to) &&
												<>{formatting.formatCustomDate(i.date_to, "H:mm")}</>
											}
										</>
									}
								</>
							}
						</>
					}
					content={i =>
						<EducationalActionsTimeLineItemOriginal action={i} stages={props.stages} />
					}
				/>
			</div>
			<Paginator list={actions.list} />
		</Page >
	);
}