/**
 * Model žádosti o vyškrtnutí ze seznamu členů oblastní komory
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as formatting from "../../../lib/formatting";

export interface State {
	regionalChambers: apiCodeList.CodeList[];
}

/**
 * Definice dat formuláře
 */
export interface FormFields {
	regional_chamber_id: api.Id | undefined;
	day: string;
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		regional_chamber_id: {
			title: "Žádám o vyškrtnutí ze seznamu členů Oblastní Komory (hlavní Oblastní komory)",
			defaultValue: undefined,
			required: true,
			validate: value => value === api.emptyGuid ? "Oblastní komora je povinné pole" : ""
		},
		day: {
			title: "ke dni (nelze provést zpětně)",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "Datum je povinné pole" : formatting.setDateToZero(new Date()) > new Date(value) ? "Nelze provést zpětně" : ""
		}
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);
		this.stateContainer = new state.StateContainer<State>({
			regionalChambers: []
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	getRegionalChambers = () => {
		return this.stateContainer.get().regionalChambers;
	}

	loadRegionalChambers = async () => {
		if (this.context.authorization.userLoggedIn()) {
			const regionalChambers = await this.context.apiMembers.loadRegionalChambers();
			const data = regionalChambers.data;
			data.unshift({ id: api.emptyGuid, name: "", code: "" })
			await this.stateContainer.merge(_ => ({
				regionalChambers: data
			}));
			if (regionalChambers.data.length > 0 && this.form.getField("regional_chamber_id").value === undefined) {
				await this.form.setField("regional_chamber_id", regionalChambers.data[0].id);
			}
		}
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			await this.common.send(data, "zadost-bez-osk");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await Promise.all([
			this.loadRegionalChambers()
		]);
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			await this.form.setFields(data);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadRegionalChambers()
		]);
	}
}