/**
 *  Formulář žádosti o změnu kontaktních údajů
 */

import React from "react";

import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequestChangeContacts from "./web-request-change-contacts";

import WebRequest from "../../../components/templates/WebRequest";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import CheckBox from "../../../components/controls/Checkbox";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";

interface WebRequestChangeContactsPageProps {
	id?: string;
}

interface AddressProps {
	form: forms.Form<webRequestChangeContacts.AddressFormFields>;
}

function Address(props: AddressProps) {
	const { webRequestChangeContacts: model } = state.useStateContext();
	return (
		<div className="row mt-5">
			<div className="col-md-6">
				<FormsInput inputProps={{ className: model.checkChangedAddressField("street") }} form={props.form} field="street" />
				<FormsInput inputProps={{ className: model.checkChangedAddressField("city") }} form={props.form} field="city" />
				<FormsInput hideNumeric={true} inputProps={{ className: model.checkChangedAddressField("postcode"), type: "number" }} form={props.form} field="postcode" />
			</div>
			<div className="col-md-6">
				<FormsInput inputProps={{ className: model.checkChangedAddressField("phone") }} form={props.form} field="phone" />
				<FormsInput inputProps={{ className: model.checkChangedAddressField("email") }} form={props.form} field="email" />
			</div>
		</div>
	);
}

function AddressOnly(props: AddressProps) {
	const { webRequestChangeContacts: model } = state.useStateContext();
	return (
		<div className="row mt-5">
			<div className="col-md-6">
				<FormsInput inputProps={{ className: model.checkChangedAddressField("contact_street") }} form={props.form} field="contact_street" />
				<FormsInput inputProps={{ className: model.checkChangedAddressField("contact_city") }} form={props.form} field="contact_city" />
				<FormsInput hideNumeric={true} inputProps={{ className: model.checkChangedAddressField("contact_postcode"), type: "number" }} form={props.form} field="contact_postcode" />
			</div>
		</div>
	);
}

function WebRequestChangeContactsPage(props: WebRequestChangeContactsPageProps) {
	const title = "ŽÁDOST O ZMĚNU KONTAKTNÍCH ÚDAJŮ (adresa, telefon, e-mail, časopis, mailing)";
	const { webRequestChangeContacts: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p>Přepiště pouze údaje které chcete změnit.</p>

			<h2>Změna adresy pobytu na území ČR:</h2>
			{form.getField("loaded") &&
				<>
					<Address form={model.formAddress} />

					<h2 className="mb-1">Změna adresy pro doručování na území ČR:</h2>
					<p>
						(kontaktní adresa, uveďte jen, pokud se liší od adresy pobytu)
					</p>
					<AddressOnly form={model.formAddress} />

					<h2>Časopis LKS a mailing:</h2>
					<p>Prosíme zvolte požadovanou variantu.</p>
					<CheckBoxRadioFrame className="mb-5">
						<FormCheckBox form={form} field="subscribe_lsk_journal" />
						{form.getField("subscribe_lsk_journal").value &&
							<div className="d-flex align-items-center mb-3 ml-5">
								<span>LKS bude zasílán na adresu:</span>
								<FormRadio form={form} field="lsk_journal_address" option="contact-address" title="na adresu pro doručování na území ČR" />
								<FormRadio form={form} field="lsk_journal_address" option="workplace-address" title="na adresu hlavního pracoviště" />
							</div>
						}
						<FormCheckBox form={form} field="subscribe_mailing" />
					</CheckBoxRadioFrame>
				</>
			}
			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestChangeContactsPage,
	c => c.webRequestChangeContacts
);