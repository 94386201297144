/**
 *  Formulář žádosti o zařazení do vstupního vzdělávacího programu
 */
import React from "react";
import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import Button from "../../../components/controls/Button";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormFileUpload from "../../system/file/FormFileUpload";

interface WebRequestEntryEduProgramPageProps {
	id?: string;
}

function WebRequestEntryEduProgramPage(props: WebRequestEntryEduProgramPageProps) {
	const title = "ŽÁDOST O ZAŘAZENÍ DO VSTUPNÍHO VZDĚLÁVACÍHO PROGRAMU";
	const { webRequestEntryEduProgram } = state.useStateContext();
	const form = webRequestEntryEduProgram.form;
	const model = state.useStateContext().webRequestEntryEduProgram;

	return (
		<WebRequest
			title={title}
			description=""
			authorized
			model={webRequestEntryEduProgram.commonModel}
			id={props.id}
		>
			<h2 className="form-title">Druh vzdělávacího programu</h2>
			<CheckBoxRadioFrame className="mb-5">
				<div className="row">
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vppzl" title="v praktickém zubním lékařství (VPPZL)" />
					</div>
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vpchir" title="ve stomatochirurgii (VPCHIR)" />
					</div>
					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vpparo" title="v parodontologii (VPPARO)" />
					</div>

					<div className="col-md-6">
						<FormRadio form={form} field="program_type" option="vppedo" title="v pedostomatologii (VPPEDO)" />
					</div>
				</div>
			</CheckBoxRadioFrame>

			{form.getField("program_type").value === "vppzl" &&
				<>
					<div className="secondary-title">Žádám o zařazení do vstupního vzdělávacího programu v praktickém zubním lékařství:</div>
					<CheckBoxRadioFrame className="mb-5">
						<FormRadio form={form} field="entry_date_which" option="delivery-date" title="ke dni doručení této žádosti Komoře" />
						<FormRadio form={form} field="entry_date_which" option="edu-action-date" title="zpětně ke dni kdy jsem se zúčastnil/a první vzdělávací akce zahrnuté do vstupního vzdělávacího programu v praktickém zubním lékařství" />
					</CheckBoxRadioFrame>
					{form.getField("entry_date_which").value === "edu-action-date" &&
						<div className="row">
							<div className="col-md-6 col-lg-4">
								<FormInput form={form} field="entry_date" inputProps={{ type: "date" }} />
							</div>
						</div>
					}

					<p className="mb-4">
						<strong>Poučení:</strong><br />
						<small>Podmínkou zařazení do vstupního vzdělávacího programu v praktickém zubním lékařství je členství
							v Komoře.</small>
					</p>
				</>
			}

			{form.getField("program_type").value !== "vppzl" &&
				<>
					<p>
						Do vstupního vzdělávacího programu ve {model.getProgramTypeName()},
						budete automaticky zařazeni ke dni doručení této žádosti Komoře.
					</p>

					<p>
						<strong>Poučení:</strong><br />
						<small>
							Podmínkou zařazení do vstupního vzdělávacího programu ve {model.getProgramTypeName()} je úspěšné absolvování vstupního vzdělávacího programu v praktickém zubním lékařství.
						</small>
					</p>

					<h2 className="form-title">Konzultant</h2>
					<p className="mb-5">
						Spolupráce s konzultantem je povinná a musí pokrývat dobu v součtu nejméně 2 let v době od zařazení do vstupního vzdělávacího programu ve {model.getProgramTypeName()} do data podání žádosti o vykonání profesní zkoušky v rámci vstupního vzdělávacího programu ve {model.getProgramTypeName()}.
						<div>Změnu konzultanta je nutné oznámit Vzdělávacímu středisku ČSK.</div>
					</p>
					<div className="row">
						<div className="col-md-4">
							<FormInput form={form} field="consultant_ikc" />
						</div>
						<div className="col-8">
							<FormInput form={form} field="consultant_full_name" />
						</div>
					</div>
					<h2 className="form-title">Přiložte čestné prohlášení konzultanta <span className="form-item__required">*</span></h2>
					<div className="row">
						<div className="col-8 col-lg-10">
							<FormFileUpload
								fileUploadProps={{
									className: "mb-4"
								}}
								form={form}
								field="attachment"
								formItemOptions={{
									hideTitle: true
								}}
							/>
						</div>
					</div>
					<div>
						<a href="/images/CESTNE-PROHLASENI-KONZULTANTA.docx" download>
							Čestné prohlášení konzultanta ke stažení.docx
						</a>
					</div>
				</>
			}

			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={webRequestEntryEduProgram.confirm} spinner={webRequestEntryEduProgram.commonModel.justSending()}>Odeslat</Button>
				</div>
			}

		</WebRequest>
	);
}

export default state.bindContainers(
	WebRequestEntryEduProgramPage,
	c => c.webRequestEntryEduProgram
);