/**
 * Model oznámení o ukončení výkonu povolání
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as api from "../../../lib/api";
import * as webRequest from "../../shared/web-request/web-request";

interface State {
	justLoadingHostChambers: boolean;
	justLoadingHostChambersOnlyEmergency: boolean;
	loadedHostChambers: boolean;
	loadHostChambersOnlyEmergency: boolean;
}


interface FormData extends FormFields {
	attachments: FileFormFields[];
	children_care: ChildFormFields[];
}

export interface FileFormFields {
	attachment?: api.CodeBookItem;
}

/**
 * Deklarace polí fomuláře péče o dítě
 */
export interface ChildFormFields {
	care_from: string;
	care_to: string;
}

/**
 * Definice fomuláře péče o dítě
 */
const formChildrenOptions: forms.FormOptions<ChildFormFields> = {
	fields: {
		care_from: {
			title: "Od",
			defaultValue: ""
		},
		care_to: {
			title: "Do",
			defaultValue: ""
		}
	}
};

/**
 * Definice dat formuláře
 */
export interface FormFields {
	attachments: FileFormFields[];
	confirmed_declaration: boolean;
	confirmed_children_care: boolean;
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		attachments: {
			title: "Příloha",
			defaultValue: [],
		},
		confirmed_declaration: {
			title: "Čestné prohlášení",
			defaultValue: false,
			required: false,
		},
		confirmed_children_care: {
			title: "Čestně prohlašuji, že jsem v níže uvedené době soustavně osobně pečoval/a o dítě do 4 let věku.",
			defaultValue: false,
		}
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;
	public formsFiles: forms.FormCollection<FileFormFields>;
	public formChildren: forms.FormCollection<ChildFormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ justLoadingHostChambers: false, justLoadingHostChambersOnlyEmergency: false, loadHostChambersOnlyEmergency: false, loadedHostChambers: false }, context);
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined,
					required: false,
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
		this.formChildren = new forms.FormCollection<ChildFormFields>(formChildrenOptions, context, this.form);
		this.formChildren.add();
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	addFile = () => this.formsFiles.add();

	removeFile = async (formFile: forms.Form<FileFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? this.formsFiles.remove(formFile)
			: undefined

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				children_care: this.formChildren.get().map(i => i.getFields())
			};
			await this.common.send(data, "zadost-o-vykonani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
 * Načte data do formuláře 
 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { children_care, attachments, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.formsFiles.setFields(attachments),
				this.formChildren.setFields(children_care),
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
		]);
	}
}