/**
 * Seznam mých akcí
 */
import React, { useEffect, useState } from "react";
import * as api from "../../../lib/api";
import * as navigation from "../../../lib/navigation";
import * as state from "../../../lib/state";
import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";
import Button from "../../../components/controls/Button";
import WorkplaceListMain from "./WorkplaceListMain";
import WorkplaceListSecondary from "./WorkplaceListSecondary";

export default function WorkplacesListPage() {
	const { contents } = state.useStateContext();
	const { workplacesListMain: model } = state.useStateContext();
	const { workplacesListSecondary: modelSecodndary } = state.useStateContext();

	const [workplace, setWorkplace] = useState(null as any);

	useEffect(() => {
		setWorkplace(api.getUrlParam("workplace"));
	}, []);

	function handleKlikaciPovinnost() {
		const param = window.location.search;
		navigation.to("/klikaci-povinnost" + param.replace("workplace=false", "workplace=true"));
	}

	return (
		<Page
			title={contents.getRes("seznam-pracovist-nadpis")}
			description=""
			authorized
		>
			<h1>
				<HtmlContent htmlKey="seznam-pracovist-nadpis" />
			</h1>
			<div className="secondary-title">Hlavní</div>
			<WorkplaceListMain list={model.listMain} />
			<div className="secondary-title">Vedlejší</div>
			<WorkplaceListSecondary list={modelSecodndary.listSecondary} />
			{workplace != null &&
				<div className="text-right">
					<Button theme="primary" onClick={() => handleKlikaciPovinnost()}>Kontrola provedena</Button>
				</div>
			}
		</Page>
	);
}