/**
 * Komorník
 */
import React from "react";

import * as apiPublicattionTopics from "../../modules/shared/publications/api-publications";
import * as state from "../../lib/state";
import * as common from "../../lib/common";
import * as navigation from "../../lib/navigation";

import Page from "./Page";
import Spinner from "../controls/Spinner";
import Link from "../controls/Link";
import EnterPressArea from "../controls/EnterPressArea";

interface KomornikPageProps {
	headerTitle: string;
	contentTitle: React.ReactNode;
	justLoadingTopic: boolean;
	justLoading?: boolean;
	topic?: apiPublicattionTopics.WebPublicationTopic;
	children?: React.ReactNode;
}

export default function KomornikPage(props: KomornikPageProps) {
	const { publicationArticle } = state.useStateContext();
	const { api } = state.useStateContext();

	async function handleSearch() {
		await common.deffered(async () => {
			const url = window.location.href.split("/");
			const id = url[url.length - 1].replace("?isRoot=true", "");
			const result = await api.loadList<apiPublicattionTopics.PublicationTopicContent>("/publications/search", { fulltext: publicationArticle.getFulltext(), id: id } as any);
			await publicationArticle.setIsRoot(true);
			publicationArticle.setItems(result.data);
		});
	}

	async function handleReset() {
		await publicationArticle.setFulltext("");
		await publicationArticle.setIsRoot(true);
		publicationArticle.resetItems();
		navigation.to("/komornik/clanek/" + publicationArticle.getTopic()!.id + "?isRoot=true");
	}

	function handleValue(value: string) {
		publicationArticle.setFulltext(value);
	}

	return (
		<Page title={props.headerTitle}>
			<EnterPressArea onEnter={handleSearch}>
				<div className="form-filter u-mb-md">
					<div id="records" className="form-filter__inner row">
						<div className="col-12 col-sm-6 col-md-4 col-lg-5 u-mb-md">
							<label htmlFor="filter-search" className="inp-label u-vhide">
								Zadejte jméno člena
							</label>
							<span className="inp-fix">
								<input
									type="text"
									id="inp-filter-search"
									placeholder={"Hledat..."}
									className="inp-text inp-text--filter"
									value={publicationArticle.getFulltext()}
									onChange={(e) => handleValue(e.target.value)}
								/>
							</span>
						</div>
						<div className="col-12 col-sm-6 col-md-auto u-mb-md ml-md-auto">
							<button type="submit" className="btn btn--filter" onClick={handleSearch}>
								<span className="btn__text">
									Vyhledat
								</span>
							</button>
						</div>
						<div className="col-12 col-sm-6 col-md-auto u-mb-md">
							<button type="submit" className="btn btn--filter" onClick={handleReset}>
								<span className="btn__text">
									Reset
								</span>
							</button>
						</div>
					</div>
				</div>
			</EnterPressArea>
			<h1 className="d-flex align-items-center mt-3">
				{props.justLoading &&
					<>
						<Spinner small className="mr-1" />
					</>
				}
				{props.contentTitle}
			</h1>
			<div className="row mb-5">
				<div className="col-md-12 order-md-last mb-3 mb-md-0">
					{props.children}
				</div>
				{/* <div className="col-md-6 col-lg-4 order-md-first">
					<div className="publication-article-template__topics p-4">
						{props.justLoadingTopic && props.topic === undefined &&
							<><Spinner small className="mr-1" /> Načítám obsah...</>
						}
						{props.topic &&
							<ul className="--neutral mb-0">
								<li>
									<h2>
										<Link to={"/komornik/clanek/" + props.topic.id + "?isRoot=true"} className="d-inline-flex"><span className="publication-article-template__topic-number">{props.topic.number}</span> {props.topic.name}</Link>
									</h2>
								</li>
								{props.topic.menuItems.map((i, index) =>
									<li key={index}><Link to={"/komornik/clanek/" + i.id} className="d-inline-flex"><span className="publication-article-template__topic-number">{i.number}</span> {i.name}</Link></li>
								)}
							</ul>
						}
					</div>
				</div> */}
			</div>
		</Page >
	);
}