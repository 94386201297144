/**
 * Domovská stránka
 */
import React, { useState, useEffect } from "react";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as navigation from "../../../lib/navigation";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import Button from "../../../components/controls/Button";

function MyProfilePage() {
	const { authorization } = state.useStateContext();
	const myProfile = authorization.getUserProfile()?.profile;
	const justLoading = authorization.loadingUserProfile();
	const [url, setUrl] = useState(null as any);

	useEffect(() => {
		setUrl(api.getUrlParam("checkPersonalInfo"));
	}, []);

	function handleKlikaciPovinnost() {
		const param = window.location.search;
		navigation.to("/klikaci-povinnost" + param.replace("checkPersonalInfo=false", "checkPersonalInfo=true"));
	}

	return (
		<Page title="Můj profil" description="" authorized>
			<div className="box-profile">
				<div className="anchor" id="profil" />
				<h2 className="form-title d-flex align-items-center">
					{justLoading &&
						<Spinner small className="mr-2" />
					}
					Osobní a identifikační údaje
				</h2>

				<div className={"message message--flex u-mb-lg " + (url != null ? "bg-input-changed" : "")}>
					<div className="message__inner">
						<span>
							Datum posledního potvrzení správnosti členských údajů provedené členem (klikací povinnost):
						</span>
						<span>
							<strong>{formatting.formatDate(myProfile?.date_off_last_confirmations)}</strong>
						</span>
					</div>
				</div>

				<div className="u-mb-lg">
					<div className="row">
						<div className="col-lg-6">
							<div className="row align-items-center">
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Titul před
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.title_before}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Jméno
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.first_name}
									</span>
								</div>

								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Příjmení
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.last_name}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Prostřední
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.middle_name}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Titul za
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.title_after}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Pedagogický titul
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.title_pedagogical}
									</span>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="row align-items-center">
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										IKČ
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.identification_number}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Kat. příspěvků
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.member_category?.name}
									</span>
								</div>

								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Hlavní oblast
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.regional_chamber_main?.name}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Hostující oblasti
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.visiting_regional_chambers.map(i => i.name).join(", ")}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Hostující oblasti jen pohotovost
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.visiting_only_emergency_regional_chambers.map(i => i.name).join(", ")}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5 mt-2">
									<span className="box-profile__label">
										Členství od
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{formatting.formatDate(myProfile?.membership_from)}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>

				<div className="anchor" id="kontakty" />
				<h2 className="form-title">
					Adresa
				</h2>

				<div className="u-mb-lg">
					<div className="row">
						<div className="col-lg-6">
							<div className="row align-items-center">
								<div className="col-12 mb-4 mt-4 mt-lg-0">
									<em>Adresa pobytu na území ČR:</em>
								</div>

								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Ulice a číslo
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.address?.street}
									</span>
								</div>

								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Obec
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.address?.city}
									</span>
								</div>

								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										PSČ
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.address?.postcode}
									</span>
								</div>

							</div>
						</div>
						<div className="col-lg-6">
							<div className="row align-items-center">
								<div className="col-12 mb-4 mt-4 mt-lg-0">
									<em>Adresa pro doručení na území ČR:</em>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Ulice a číslo
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.delivery_address?.street}
									</span>
								</div>

								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Obec
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.delivery_address?.city}
									</span>
								</div>

								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										PSČ
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.delivery_address?.postcode}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>

				<h2 className="form-title">
					Kontakty
				</h2>
				<div className="u-mb-lg">
					<div className="row">
						<div className="col-lg-6">
							<div className="row align-items-center">
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Telefon
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.contact?.phone1}
									</span>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="row align-items-center">
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										E-mail
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.contact?.email1}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="anchor" id="detail" />
				<h2 className="form-title">
					Detail
				</h2>

				<div className="u-mb-lg">
					<div className="row">
						<div className="col-lg-6">
							<div className="row align-items-center">
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Datum narození
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{formatting.formatDate(myProfile?.birthdate)}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Rodné číslo
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.personal_identification_number}
									</span>
								</div>
								{/*

								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Pohlaví
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.gender?.name}
									</span>
								</div>
								*/}
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Státní příslušnost
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.nationality?.name}
									</span>
								</div>
								<div className="col-12 col-sm-4 col-md-5">
									<span className="box-profile__label">
										Platnost pov. pobytu
									</span>
								</div>
								<div className="col-12 col-sm-8 col-md-7">
									<span className="box-profile__value">
										{myProfile?.validity_of_the_residence_permit &&
											<>{formatting.formatDate(myProfile.validity_of_the_residence_permit)}</>
										}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="anchor" id="fotogalerie" />
				<h2 className="form-title">
					Fotogalerie
				</h2>
				<PhotoGallery
					className="u-mb-lg"
					items={[
						{
							previewImageUrl: "/images/illust/default_640x480.jpg",
							imageUrl: "/images/illust/default_800x600.jpg"
						},
						{
							previewImageUrl: "/images/illust/default_640x480.jpg",
							imageUrl: "/images/illust/default_800x600.jpg"
						},
						{
							previewImageUrl: "/images/illust/default_640x480.jpg",
							imageUrl: "/images/illust/default_800x600.jpg"
						},
						{
							previewImageUrl: "/images/illust/default_640x480.jpg",
							imageUrl: "/images/illust/default_800x600.jpg"
						}
					]} /> */}

				{myProfile?.child_birthdate &&
					<>
						<div className="anchor" id="prohlaseni" />
						<h2 className="form-title">
							Prohlášení
						</h2>
						<div className="message u-mb-md">
							<p>
								Čestne prohlašuji, že osobně celodenně pečuji o dítě do 4 let věku.
							</p>
						</div>
						<div className="u-mb-lg">
							<div className="row">
								<div className="col-12 col-lg-6 u-mb-md">
									<div className="row align-items-center">
										<div className="col-12 col-sm-4 col-md-5">
											<span className="box-profile__label">
												Datum narození dítěte
											</span>
										</div>
										<div className="col-12 col-sm-8 col-md-7">
											<span className="box-profile__value">
												{formatting.formatDate(myProfile.child_birthdate)}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				}

				<div className="anchor" id="mailing" />
				<h2 className="form-title">
					Odběry informací
				</h2>

				{myProfile?.send_bulk_emails &&
					<div className="message u-mb-md">
						<p>
							Chci dostávat hromadné maily.
						</p>
					</div>
				}

				{myProfile?.send_monitoring &&
					<div className="message u-mb-md">
						<p>
							Chci dostávat monitoring.
						</p>
					</div>
				}

				{myProfile?.journal_subscribe &&
					<div className="message u-mb-md">
						<p>
							Chci dostávat časopis LKS (na adresu: {myProfile.journal_address.name}).
						</p>
					</div>
				}

				{!(myProfile?.send_bulk_emails || myProfile?.send_monitoring || myProfile?.journal_subscribe) &&
					<p>
						Žádný odběr informací.
					</p>
				}
				{url != null &&
					<div className="text-right">
						<Button theme="primary" onClick={handleKlikaciPovinnost}>Kontrola provedena</Button>
					</div>
				}
			</div>
		</Page >
	);
}

export default state.bindContainers(
	MyProfilePage,
	context => context.myProfile
);
