/**
 * Modul pro komplexní práci se seznamy objektů.
 */

import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as formatting from "../../../lib/formatting";
import * as forms from "../../../lib/forms";
import * as state from "../../../lib/state";
import * as actions from "../actions/actions";
import * as context from "../../../context";

/**
 * Způsob řazení
 */
export enum SortingOrder {
	Asc,
	Desc
}

/**
 * Módy pro filtr
 */
export type FilterMode = "quick" | "advanced" | "query";

/**
 * Stav seznamu
 */
export interface State<TModel extends api.EntityReadBase> {
	/**
	 * Data zobrazovaná v seznamu
	 */
	receivedData: api.ListResponse<TModel>;

	/**
	 * Vyhledávací fráze
	 */
	filterFultextPhrase: string;

	/**
	 * Aplikovaný vyhledávací dotaz (v query módu)
	 */
	filterQuery: string;

	/**
	 * Aktuální filtrační záložka (tab)
	 */
	filterTabId: string;

	/**
	 * Neaplikovaný vyhledávací dotaz (v query nódu)
	 */
	query: string;

	/**
	 * Aktuální mód filtrování
	 */
	filterMode: FilterMode;

	/**
	 * Manuální aplikace filtru
	 */
	manualFilterPost?: boolean;

	/**
	 * Vybrané záznamy
	 */
	selected: api.Id[];

	/**
	 * Záznamy, na nichž jsou expandované action listy
	 */
	actionListExpanded: api.Id[];

	/**
	 * Pole, podle kterého je seznam seřazen
	 */
	sortingField: keyof TModel | undefined;

	/**
	 * Způsob řazení vzestupně/sestupně
	 */
	sortingOrder: SortingOrder;

	/**
	 * Indikace probíhajícího načítání dat
	 */
	justLoadingData: boolean;

	/**
	 * Sloupce mřížky
	 */
	gridColumns: GridColumnState<TModel, keyof TModel>[];
}

/**
 * Definice filtrační záložky
 */
export interface TabFilterItem<Item> {
	id: string;
	title: string;
	query: (tabFilterItem: TabFilterItem<Item>) => api.QueryFilterItem<Item, keyof Item>[];
}

/**
 * Definice filtrační záložky pro všechny záznamy
 */
export const TabFilterAll: TabFilterItem<any> = {
	id: "_all",
	title: "Všechny",
	query: () => []
};

/**
 * Definice filtrační záložky pro odstraněné záznamy
 */
export const TabFilterDeleted: TabFilterItem<any> = {
	id: "_deleted",
	title: "Odstraněné",
	query: () => []		// Odstraněné záznamy nejsou předmetem query, ale speciálního parametru
};

/**
 * Definice rozšířeného filtru pro jednotlivé fieldy
 */
export type AdvancedFilter<Item, AdvancedFilterFields> = {
	[P in keyof AdvancedFilterFields]: {
		field: forms.FieldOption<AdvancedFilterFields[P], AdvancedFilterFields>,
		query: (value: AdvancedFilterFields[P]) => api.QueryFilterItem<Item, keyof Item>[];
		filterInfoCaption?: string;
	}
};

/**
 * Definice položky, podle níž se řadí
 */
interface SortingField<TItem extends api.EntityReadBase> {
	field: keyof TItem | undefined;
	title: string;
}

/**
 * Typ sloupce v mřížce
 */
type GridColumnType = "string" | "number" | "boolean" | "date";

/**
 * Definice konfigurace jednoho sloupce v mřížce
 */
interface GridColumnOptions<TItem, TField extends keyof TItem> {
	field: TField;
	title: string;
	width?: string;
	type: GridColumnType;
	alignRight?: boolean;
}

/**
 * Operátory pro filtrování sloupce typu string
 */
type GridColumnFilterOperatorString = "=" | "!=" | "starts" | "contains";

/**
 * Operátory pro filtrování sloupce typu number
 */
type GridColumnFilterOperatorNumber = "=" | "!=" | "<" | "<=" | ">" | ">=" | "between";

/**
 * Operátory pro filtrování sloupce typu date
 */
type GridColumnFilterOperatorDate = GridColumnFilterOperatorNumber;

/**
 * Operátory pro filtrování sloupce typu boolean
 */
type GridColumnFilterOperatorBoolean = "all" | "true" | "false" | "not true" | "not false";

/**
 * Operátory pro filtrování sloupce
 */
export type GridColumnFilterOperator =
	GridColumnFilterOperatorString |
	GridColumnFilterOperatorNumber |
	GridColumnFilterOperatorDate |
	GridColumnFilterOperatorBoolean;

/**
 * Defince stavu sloupce v mřížce
 */
interface GridColumnState<TItem, TField extends keyof TItem> {
	field: TField;
	options: GridColumnOptions<TItem, TField>;
	operator: GridColumnFilterOperator;
	filterValue: string;
	filterValueTwo: string;
}

/**
 * Konfigurace seznamu
 */
type StandardListOptions<TItem extends api.EntityReadBase, AdvancedFilterFields> = {
	/**
	 * Titulek seznamu
	 */
	title?: string;

	/**
	 * Placeholder v univerzálním vyhledávacím políčku
	 */
	quickFilterPlaceholder?: string;

	/**
	 * Standardní rozhraní pro entitu listu
	 */
	standardEntityApi: api.EntityApi<TItem, any>;

	/**
	 * Fieldy, které se mají do API načíst
	 */
	loadFields?: (keyof TItem)[];

	/**
	 * Fieldy podle kterých lze řadit
	 */
	sortingFields?: SortingField<TItem>[];

	/**
	 * Implicitní řazení
	 */
	defaultSorting?: { field: keyof TItem, order: SortingOrder };

	/**
	 * Definice gridu
	 */
	grid?: {
		minWidth?: string;
		columns: GridColumnOptions<TItem, keyof TItem>[];
	}

	/**
	 * Akce nad jednou, nebo více vybranými položkami
	 */
	itemActionsList?: actions.ItemActionList<TItem>;

	/**
	 * Definice TABů filtru seznamu
	 */
	tabFilter?: TabFilterItem<TItem>[];

	/**
	 * Skryje TAB filter
	 */
	hideTabFilter?: boolean;

	/**
	 * Skryje pouze položku smazané
	 */
	hideDeletedTabFilter?: boolean;

     /**
	 * Skryje filtr na radku
	 */
    hideFilterRow?: boolean;

	/**
	 * Definice rozšířeného filteru
	 */
	advancedFilter?: AdvancedFilter<TItem, AdvancedFilterFields>;

	/**
	 * Definice systémového filtru, který se aplikuje pro všechny módy filtrování a uživatel ho nemůže nijak zrušit 
	 */
	filterSystem?: (list: StandardList<TItem, AdvancedFilterFields>) => api.QueryFilterItem<TItem, keyof TItem>[];

	/**
	 * Implicitní velikost stránky
	 */
	defaultPageSize?: number;

	/**
	 * Implicitní mód dotazu
	 */
	defaultFilterMode?: FilterMode;

	/**
	 * Skryje možnost zadat dotaz
	 */
	hideQueryMode?: boolean;

	context: context.StateContext;
};

/** 
 * Třída pro obsluhu standardního seznamu. 
 * TItem je datový viewmodel jedné položky v seznamu, TItemState je typ stavu
 */
export class StandardList<TItem extends api.EntityReadBase, AdvancedFilterFields = {}> {
	options: StandardListOptions<TItem, AdvancedFilterFields>;
	private advancedFilterForm: forms.Form<AdvancedFilterFields>;
	private state: state.StateContainer<State<TItem>>;

	constructor(options: StandardListOptions<TItem, AdvancedFilterFields>) {
		this.options = options;

		const defaultState: State<TItem> = {
			receivedData: api.createDefaultListEnvelope<TItem>(),
			filterFultextPhrase: "",
			filterQuery: "",
			filterMode: "quick",
			filterTabId: "_all",
			query: "",
			selected: [],
			actionListExpanded: [],
			sortingField: undefined,
			sortingOrder: SortingOrder.Asc,
			justLoadingData: false,
			gridColumns: []
		};
		if (this.options.defaultPageSize !== undefined) {
			defaultState.receivedData.pagination.per_page = this.options.defaultPageSize;
		}
		if (this.options.defaultSorting !== undefined) {
			defaultState.sortingField = this.options.defaultSorting.field;
			defaultState.sortingOrder = this.options.defaultSorting.order;
		}
		if (this.options.defaultFilterMode !== undefined) {
			defaultState.filterMode = this.options.defaultFilterMode;
		}
		defaultState.gridColumns = this.options.grid?.columns.map(i => (
			{
				field: i.field,
				options: i,
				operator: i.type === "boolean" ? "all" as GridColumnFilterOperator : "contains" as GridColumnFilterOperator,
				filterValue: "",
				filterValueTwo: ""
			}
		)) ?? [];

		this.state = new state.StateContainer<State<TItem>>(defaultState, this.options.context);

		let formFields = {} as forms.FieldsOptions<AdvancedFilterFields>;
		if (this.options.advancedFilter) {
			for (let p in this.options.advancedFilter) {
				formFields[p] = this.options.advancedFilter[p].field;
			}
		}

		this.advancedFilterForm = new forms.Form<AdvancedFilterFields>({
			fields: formFields,
			onChangeForm: this.advancedFilterChanged
		}, this.options.context);
	}

	private advancedFilterChanged = async () => {
		await this.advancedFilterForm.validate();
		if (this.advancedFilterForm.isValid()) {
			this.delayedLoadWithResetPage();
		}
	}

	private createPartialState = (state: Partial<State<TItem>>) => {
		return state;
	}

	private getStateForFirstPage = (prevState: State<TItem>) => {
		return {
			receivedData: {
				...prevState.receivedData,
				pagination: {
					...prevState.receivedData.pagination,
					page: 1
				}
			},
		};
	}

	private getStateWithPageNumber = (prevState: State<TItem>, page: number): State<TItem> => {
		return {
			...prevState,
			receivedData: {
				...prevState.receivedData,
				pagination: {
					...prevState.receivedData.pagination,
					page: page
				}
			},
			selected: [],
			actionListExpanded: []
		};
	}

	private createFilterQuick = (): api.QueryFilterItem<TItem, keyof TItem>[] | undefined => {
		const systemFilter = this.options.filterSystem ? this.options.filterSystem(this) : undefined;
		const currentFilterTab = this.getCurrentFilterTab();
		const tabFilter = currentFilterTab.query(currentFilterTab);
		const columnsGridFilter = this.createFilterGridColumns();

		const result = (systemFilter ?? []).concat(tabFilter).concat(columnsGridFilter);
		return result.length > 0 ? result : undefined;
	}

	private createFilterAdvancedPart = () => {
		return this.options.advancedFilter
			? Object
				.entries<any>(this.options.advancedFilter)
				.map<api.QueryFilterItem<TItem, keyof TItem>>((field) => field[1].query(this.advancedFilterForm.getField(field[0] as any).value))
				.flat()
			: undefined;
	}

	private createFilterAdvanced = (): api.QueryFilterItem<TItem, keyof TItem>[] | undefined => {
		const systemFilter = this.options.filterSystem ? this.options.filterSystem(this) : undefined;
		const currentFilterTab = this.getCurrentFilterTab();
		const tabFilter = currentFilterTab.query(currentFilterTab);
		const advancedFilter = this.createFilterAdvancedPart();
		const result = (systemFilter ?? []).concat(advancedFilter ?? []).concat(tabFilter);
		return result.length > 0 ? result : undefined;
	}

	private createFilterQuery = (): api.QueryFilterItem<TItem, keyof TItem>[] | undefined => {
		const query = this.state.get().filterQuery.trim();
		const systemFilter = this.options.filterSystem ? this.options.filterSystem(this) : undefined;
		const queryFilter = query !== "" ? [api.qpe(query)] : undefined;

		const result = (systemFilter ?? []).concat(queryFilter ?? []);
		return result.length > 0 ? result : undefined;
	}

	private createFilterValue = (strValue: string, type: GridColumnType) => {
		switch (type) {
			case "string":
				return strValue;
			case "number":
				if (strValue.trim() === "") return undefined;
				const numValue = Number(strValue);
				return !isNaN(numValue) ? numValue : undefined;
			case "date":
				return formatting.parseDate(strValue);
			case "boolean":
				return strValue === "1" ||
					strValue.toLowerCase() === "true" ||
					strValue.toLowerCase() === "ano" ||
					strValue.toLowerCase() === "yes";
			default: common.unwanted(type);
		}
	}

	private createQueryFromGridColumn = <TItem, TField extends keyof TItem>(column: GridColumnState<TItem, TField>) => {
		switch (column.options.type) {
			case "string":
				if (column.filterValue.trim().length === 0) {
					return [];
				}
				const operatorString = column.operator as GridColumnFilterOperatorString;
				const filterValue = this.createFilterValue(column.filterValue, "string");
				switch (operatorString) {
					case "=": return [api.qp<TItem, any>(column.field, "=" as any, filterValue as any)];
					case "!=": return [api.qp<TItem, any>(column.field, "!=" as any, filterValue as any)];
					case "starts": return [api.qp<TItem, any>(column.field, "=" as any, (filterValue + "*") as any)];
					case "contains": return [api.qp<TItem, any>(column.field, "=" as any, ("*" + filterValue + "*") as any)];
					default: return common.unwanted(operatorString);
				}

			case "number":
				const numberFilterValue = this.createFilterValue(column.filterValue, "number");
				const numberFilterValueTwo = this.createFilterValue(column.filterValueTwo, "number");
				if (numberFilterValue === undefined) {
					return [];
				}
				const operatorNumber = column.operator as GridColumnFilterOperatorNumber;
				switch (operatorNumber) {
					case "=": return [api.qp<TItem, any>(column.field, "=" as any, numberFilterValue as any)];
					case "!=": return [api.qp<TItem, any>(column.field, "<>" as any, numberFilterValue as any)];
					case "<": return [api.qp<TItem, any>(column.field, "<" as any, numberFilterValue as any)];
					case "<=": return [api.qp<TItem, any>(column.field, "<=" as any, numberFilterValue as any)];
					case ">": return [api.qp<TItem, any>(column.field, ">" as any, numberFilterValue as any)];
					case ">=": return [api.qp<TItem, any>(column.field, ">=" as any, numberFilterValue as any)];
					case "between": return [
						api.qp<TItem, any>(column.field, ">=" as any, numberFilterValue as any),
						api.qp<TItem, any>(column.field, "<=" as any, numberFilterValueTwo as any),
					];
					default: return common.unwanted(operatorNumber);
				}
			case "date":
				const dateFilterValue = this.createFilterValue(column.filterValue, "date");
				const dateFilterValueTwo = this.createFilterValue(column.filterValueTwo, "date");
				if (dateFilterValue === undefined) {
					return [];
				}
				const operatorDate = column.operator as GridColumnFilterOperatorDate;
				switch (operatorDate) {
					case "=": return [api.qp<TItem, any>(column.field, "=" as any, dateFilterValue as any)];
					case "!=": return [api.qp<TItem, any>(column.field, "<>" as any, dateFilterValue as any)];
					case "<": return [api.qp<TItem, any>(column.field, "<" as any, dateFilterValue as any)];
					case "<=": return [api.qp<TItem, any>(column.field, "<=" as any, dateFilterValue as any)];
					case ">": return [api.qp<TItem, any>(column.field, ">" as any, dateFilterValue as any)];
					case ">=": return [api.qp<TItem, any>(column.field, ">=" as any, dateFilterValue as any)];
					case "between": return [
						api.qp<TItem, any>(column.field, ">=" as any, dateFilterValue as any),
						api.qp<TItem, any>(column.field, "<=" as any, dateFilterValueTwo as any),
					];
					default: return common.unwanted(operatorDate);
				}
			case "boolean":
				const operatorBool = column.operator as GridColumnFilterOperatorBoolean;
				switch (operatorBool) {
					case "all": return [];
					case "true": return [api.qp<TItem, any>(column.field, "=" as any, true as any)];
					case "false": return [api.qp<TItem, any>(column.field, "=" as any, false as any)];
					case "not true": return [api.qp<TItem, any>(column.field, "!=" as any, true as any)];
					case "not false": return [api.qp<TItem, any>(column.field, "!=" as any, false as any)];
					default: return common.unwanted(operatorBool);
				}
		}
	}

	private createFilterGridColumns = (): api.QueryFilterItem<TItem, keyof TItem>[] => {
		return this.state.get().gridColumns
			.map(this.createQueryFromGridColumn)
			.flat();
	}

	private createFilter = (): api.QueryFilterItem<TItem, keyof TItem>[] | undefined => {
		const filterMode = this.state.get().filterMode;
		switch (filterMode) {
			case "quick": return this.createFilterQuick();
			case "advanced": return this.createFilterAdvanced();
			case "query": return this.createFilterQuery();

			default: common.unwanted(filterMode);
		}
	}

	private createQuery = (): api.Query<TItem> => {
		return {
			fulltext: this.state.get().filterFultextPhrase,
			deleted: this.getCurrentFilterTab() === TabFilterDeleted,
			fields: this.options.loadFields,
			filter: this.createFilter(),
			page: this.getPage(),
			per_page: this.getPageSize(),
			sort_fields: this.getSortingField() !== undefined ? [this.getSortingField()!] : [],
			sort_fields_desc: this.getSortingField() !== undefined && this.getSortingOrder() == SortingOrder.Desc ? [this.getSortingField()!] : undefined
		};
	}

	private getStateWithClearedFilter = (prevState: State<TItem>) => {
		return {
			...prevState,
			filterDeleted: false,
			filterFultextPhrase: "",
			filterItemState: null,
			filterQuery: "",
			filterTab: TabFilterAll,
			gridColumns: this.options.grid?.columns.map(i => (
				{
					field: i.field,
					options: i,
					operator: i.type === "boolean" ? "all" as GridColumnFilterOperator : "contains" as GridColumnFilterOperator,
					filterValue: "",
					filterValueTwo: ""
				}
			)) ?? []
		};
	}

	private getStateWithSortingField = (prevState: State<TItem>, field: keyof TItem | undefined) => {
		return {
			...prevState,
			sortingField: field,
			sortingOrder: prevState.sortingField == field ? prevState.sortingOrder : SortingOrder.Asc
		};
	}

	private getStateWithSortingOrderDefault = (prevState: State<TItem>) => {
		return {
			...prevState,
			sortingOrder: SortingOrder.Asc
		};
	}

	/**
	 * Vrací kolekci stavových kontejnerů pro binding na vizuální komponentu
	 */
	getStateContainers = () => {
		return [
			this.state,
			this.advancedFilterForm.stateContainer
		];
	}

	/**
	 * Vrací kolekci aktuálních dat
	 */
	getData = () => {
		return this.state.get().receivedData.data;
	}

	/**
	 * Vrací poslední odpověď
	 */
	getListResponse = () => {
		return this.state.get().receivedData;
	}

	/**
	 * Vrací celkový počet záznamů vyhovující filtru
	 */
	getTotalRecords = () => {
		return this.state.get().receivedData.pagination.object_count;
	}

	/**
	 * Vrací číslo aktuální stránky
	 */
	getPage = () => {
		return this.state.get().receivedData.pagination.page;
	}

	/**
	 * Nastaví aktuální stránku seznamu
	 */
	setPage = async (page: number) => {
		await this.state.merge((prevState) => this.createPartialState({
			...this.getStateWithPageNumber(prevState, page)
		}));
		await this.load();
	}

	/**
	 * Vrací velikost stránky
	 */
	getPageSize = () => {
		return this.state.get().receivedData.pagination.per_page;
	}

	/**
	 * Nastaví velikost stránky
	 */
	setPageSize = async (pageSize: number) => {
		await this.state.merge((prevState) => this.createPartialState({
			receivedData: {
				...prevState.receivedData,
				pagination: {
					...prevState.receivedData.pagination,
					page: 1,
					per_page: pageSize
				}
			},
			selected: [],
			actionListExpanded: []
		}));
		await this.load();
	}

	/**
	 * Načte (obnoví) data do seznamu z API. Zachová aktuální stránku, filtry, řazení, atd.
	 */
	load = async () => {
		const received = await common.withIndication({
			indicateStart: () => { return this.state.merge(() => ({ justLoadingData: true })); },
			exec: () => this.options.standardEntityApi.loadList(this.createQuery()),
			finish: () => { return this.state.merge(() => ({ justLoadingData: false })); }
		});
		await this.state.merge(() => this.createPartialState({
			receivedData: received,
			selected: [],
			actionListExpanded: []
		}));
	}

	/**
	 * Načte data do seznamu z API a nastaví první stránku
	 */
	loadWithResetPage = async () => {
		await this.state.merge((state) => {
			state = this.getStateWithPageNumber(state, 1);
			return state;
		});
		await this.load();
	}

	/**
	 * Načte data do seznamu z API, setnam resetuje do výchozí konfigurace (prádné filtry, implicitní řazení, apod.)
	 */
	loadWithFullReset = async () => {
		await this.state.merge((state) => {
			state = this.getStateWithPageNumber(state, 1);
			state = this.getStateWithClearedFilter(state);
			state = this.getStateWithSortingField(state, undefined);
			state = this.getStateWithSortingOrderDefault(state);
			state.filterMode = "quick";
			return state;
		});
		await this.advancedFilterForm.clearFields();
		await this.load();
	}

	/**
	 * Odložené opakované volání load()
	 */
	delayedLoad = common.debounce(this.load, 350);

	/** 
	 * Odložené opakované volání loadWithResetPage() 
	 * */
	delayedLoadWithResetPage = common.debounce(this.loadWithResetPage, 350);

	/**
	 * Vybere/zruší výběr daného záznamu 
	 */
	toogleSelection = async (item: TItem) => {
		if (this.state.get().selected.find(i => i == item.id)) {
			await this.state.merge((prevState) => this.createPartialState({
				selected: prevState.selected.filter(i => i != item.id)
			}));
		} else {
			await this.state.merge((prevState) => this.createPartialState({
				selected: prevState.selected.concat(item.id)
			}));
		}
	}

	/**
	 * Vrací tue, pokud je záznam s daným Id vybrán.
	 */
	isSelected = (id: api.Id) => {
		return this.state.get().selected.find(i => i == id) !== undefined;
	}

	/**
	 * Vrací počet vybraných záznamů
	 */
	getSelectedNum = () => {
		return this.state.get().selected.length;
	}

	/**
	 * Vrací vybrané záznamy
	 */
	getSelectedItems = () => {
		return this.state.get().selected
			.map(i => this.state.get().receivedData.data.find(k => k.id == i)).filter(i => i !== undefined) as TItem[];
	}

	/**
	 * Vybere všechny záznamy (z logiky seznamu pouze na stránce, nikoli všechny výsledky filtru)
	 */
	selectAll = async () => {
		await this.state.merge((prevState) => this.createPartialState({
			selected: prevState.receivedData.data.map(i => i.id)
		}));
	}

	/**
	 * Zruší výběr záznamů
	 */
	unselectAll = async () => {
		await this.state.merge(() => this.createPartialState({
			selected: []
		}));
	}

	/**
	 * Expanduje/zabalí akce na záznamu
	 */
	toogleExpansion = async (item: TItem) => {
		if (this.state.get().actionListExpanded.find(i => i == item.id)) {
			await this.state.merge((prevState) => this.createPartialState({
				actionListExpanded: prevState.actionListExpanded.filter(i => i != item.id)
			}));
		} else {
			await this.state.merge((prevState) => this.createPartialState({
				actionListExpanded: prevState.actionListExpanded.concat(item.id)
			}));
		}
	}

	/**
	 * Vrací true, pokud má záznam daného Id rozbalen action list
	 */
	isActionListExpanded = (id: api.Id) => {
		return this.state.get().actionListExpanded.find(i => i == id) !== undefined;
	}

	/**
	 * Vrací vyhledávací fulltext frázi
	 */
	getFilterFulltextPhrase = () => {
		return this.state.get().filterFultextPhrase;
	}

	/**
	 * Nastaví vyhledávací fulltext frázi
	 */
	setFilterFulltextPhrase = async (phrase: string) => {
		await this.state.merge((prevState) => this.createPartialState({
			filterFultextPhrase: phrase,
			...this.getStateForFirstPage(prevState)
		}));
		this.delayedLoadWithResetPage();
	}

	/**
	 * Vrací (možno i neaplikovaný) dotaz zadaný v editačním prvku pro zadání dotazu
	 */
	getQuery = () => {
		return this.state.get().query;
	}

	/**
	 * Nastaví dotaz v editačním prvku pro zadání dotazu v query módu. Bez aplikace dotazu.
	 * Dotaz je třeba aplikovat voláním metody applyQuery()
	 */
	setQuery = async (query: string) => {
		await this.state.merge((prevState) => this.createPartialState({
			query: query
		}));
	}

	/**
	 * Aplikuje dotaz (v query módu)
	 */
	applyQuery = async () => {
		await this.state.merge((prevState) => this.createPartialState({
			filterQuery: prevState.query,
			...this.getStateForFirstPage(prevState)
		}));
		this.delayedLoad();
	}

	/**
	 * Vrací aplikovaný dotaz
	 */
	getFilterQuery = () => {
		return this.state.get().filterQuery;
	}

	/**
	 * Vrací seznam filtračních záložek (tabů)
	 */
	getFilterTabs = () => {
		return [
			TabFilterAll,
			...this.options.tabFilter ?? [],
			TabFilterDeleted
		];
	}

	/**
	 * Vrací aktuální filtrační záložku (tab)
	 */
	getCurrentFilterTab = () => {
		const filterTabId = this.state.get().filterTabId;
		if (filterTabId === "_deleted") {
			return TabFilterAll as TabFilterItem<TItem>;
		} else if (this.options.tabFilter) {
			const customTab = this.options.tabFilter.find(i => i.id === filterTabId);
			if (customTab) {
				return customTab;
			}
		}

		return TabFilterAll as TabFilterItem<TItem>;
	}

	/**
	* Nastaví aktuální filtrační záložku (tab)
	*/
	setFilterTab = async (tabFilter: TabFilterItem<TItem>) => {
		await this.state.merge((prevState) => this.createPartialState({
			filterTabId: tabFilter.id,
			...this.getStateForFirstPage(prevState)
		}));
		this.delayedLoad();
	}

	/**
	 * Vrací aktuální mód filtrování
	 */
	getFilterMode = () => {
		return this.state.get().filterMode;
	}

	/**
	 * Nastaví mód filtrování 
	 */
	setFilterMode = async (mode: FilterMode) => {
		await this.state.merge((prevState) => this.createPartialState({
			filterMode: mode,
			query: prevState.filterMode !== "query" && mode === "query" && prevState.query.trim() === "" ? this.getRawAPIFilter() : prevState.query
		}));
		this.delayedLoadWithResetPage();

	}

	/**
	 * Vynuluje všechny filtry na výchozí hodnoty
	 */
	clearFilter = async () => {
		await this.state.merge((prevState) => this.createPartialState({
			...this.getStateWithClearedFilter(prevState)
		}));
		if (this.getFilterMode() === "advanced") {
			await this.advancedFilterForm.clearFields();
		}
		this.delayedLoadWithResetPage();
	}

	/**
	 * Vrací field, podle kterého je řazeno
	 */
	getSortingField = () => {
		return this.state.get().sortingField;
	}

	/**
	 * Vrací titulek fieldu, podle kterého je řazeno
	 */
	getSortingFieldTitle = () => {
		if (this.options.sortingFields === undefined) {
			return "";
		}
		const currentSortingField = this.options.sortingFields.filter(i => i.field === this.getSortingField());
		return currentSortingField.length > 0 ? currentSortingField[0].title : undefined;
	}

	/**
	 * Nastaví field pro řazení
	 */
	setSortingField = async (field: keyof TItem | undefined) => {
		await this.state.merge((prevState) => this.createPartialState({
			...this.getStateWithSortingField(prevState, field)
		}));
		this.delayedLoadWithResetPage();
	}

	/**
	 * Vrací aktuální způsob řazení
	 */
	getSortingOrder = () => {
		return this.state.get().sortingOrder;
	}

	/**
	 * Přepne způsob řazení
	 */
	toggleSortingOrder = async () => {
		await this.state.merge((prevState) => this.createPartialState({
			sortingOrder: prevState.sortingOrder == SortingOrder.Asc ? SortingOrder.Desc : SortingOrder.Asc
		}));
		this.delayedLoadWithResetPage();
	}

	/**
	 * Provede export dat
	 */
	export = async () => {
		await this.options.standardEntityApi.downloadList(this.createQuery());
	}

	/**
	 * Vrací formulář rozšířeného filtru
	 */
	getAdvancedFilterForm = () => {
		return this.advancedFilterForm;
	}

	/**
	 * Vrací true, pokud je indikováni načátání dat z API
	 */
	justLoadingData = () => {
		return this.state.get().justLoadingData;
	}

	/**
	 * Vrací titulek seznamu
	 */
	getTitle = () => {
		return this.options.title ?? "";
	}

	/**
	 * Vrací pole informací o aktuálním aplikovaném filtru
	 */
	getFilterInfo = () => {
		let filterInfo: { title: string, value: string }[] = [];

		const currentFilterTab = this.getCurrentFilterTab();
		if (this.getFilterMode() !== "query" && currentFilterTab !== TabFilterAll) {
			filterInfo.push({
				title: "",
				value: currentFilterTab.title
			});
		}

		if (this.getFilterMode() !== "query" && this.getFilterFulltextPhrase().length > 0) {
			filterInfo.push({
				title: "Položka obsahuje",
				value: this.getFilterFulltextPhrase()
			});
		}

		if (this.options.advancedFilter !== undefined && this.getFilterMode() !== "query") {
			const advancedFilterFields = this.advancedFilterForm.getFields();
			for (let fieldName in advancedFilterFields) {
				const field = this.advancedFilterForm.getField(fieldName);
				const fieldFilterCaption = this.options.advancedFilter[fieldName].filterInfoCaption ?? this.advancedFilterForm.getFieldTitle(fieldName);

				const fieldStringValue: string = (field.value as any).toString ? (field.value as any).toString() : "";
				if (fieldStringValue.length > 0) {
					filterInfo.push({
						title: fieldFilterCaption,
						value: (field.value as any).toString ? (field.value as any).toString() : ""
					});
				}
			}
		}

		if (this.getFilterMode() === "query") {
			if (this.getFilterQuery().trim().length > 0) {
				filterInfo.push({
					title: "dotaz",
					value: this.getFilterQuery().trim()
				});
			}
		}

		return filterInfo;
	}

	/**
	 * Vrací metadata o struktuře dat vrácených z API
	 */
	getFieldsMetadata = () => {
		return this.state.get().receivedData.structure;
	}

	/**
	 * Vrací textovou definici filtru pro zaslání do API
	 */
	getRawAPIFilter = () => {
		const currentFilterTab = this.getCurrentFilterTab();
		const filterTab = currentFilterTab.query(currentFilterTab);
		const filterAdvanced = this.createFilterAdvancedPart();
		const filterColumns = this.createFilterGridColumns();

		return api.toQueryString([...filterTab, ...(filterAdvanced ?? []), ...filterColumns], "&");
	}

	/**
	 * Vrací sloupce gridu
	 */
	getGridColumns = () => {
		return this.state.get().gridColumns;
	}

	/**
	 * Vrací sloupec gridu pro daný field
	 */
	getGridColumn = <TField extends keyof TItem>(field: TField) => {
		return this.state.get().gridColumns.find(i => i.field === field)!;
	}

	/**
	 * Nastaví operátor pro filtraci podle daného sloupce
	 */
	setGridColumnOperator = async <TField extends keyof TItem>(field: TField, operator: GridColumnFilterOperator) => {
		await this.state.merge((prevState) => this.createPartialState({
			gridColumns: prevState.gridColumns.map(i => i.field === field ? <GridColumnState<TItem, TField>>{ ...i, operator: operator } : i)
		}));

		const column = this.getGridColumn(field);
		const filtervalue = column.filterValue;
		const filtervalueTyped = this.createFilterValue(column.filterValue, column.options.type);

		if (filtervalue.trim().length === 0 || filtervalueTyped !== undefined) {
			this.delayedLoadWithResetPage();
		}
	}

	/**
	 * Nastaví operand pro filtraci podle daného sloupce
	 */
	setGridColumnFilterValue = async <TField extends keyof TItem>(field: TField, filterValue: string) => {
		await this.state.merge((prevState) => this.createPartialState({
			gridColumns: prevState.gridColumns.map(i => i.field === field ? <GridColumnState<TItem, TField>>{ ...i, filterValue: filterValue } : i)
		}));

		const columnType = this.state.get().gridColumns.find(i => i.field === field)?.options.type!;
		const filtervalueTyped = this.createFilterValue(filterValue, columnType);

		if (filterValue.trim().length === 0 || filtervalueTyped !== undefined) {
			this.delayedLoadWithResetPage();
		}
	}

	/**
	 * Nastaví druhý operand pro filtraci podle daného sloupce
	 */
	setGridColumnFilterValueTwo = async <TField extends keyof TItem>(field: TField, filterValue: string) => {
		await this.state.merge((prevState) => this.createPartialState({
			gridColumns: prevState.gridColumns.map(i => i.field === field ? <GridColumnState<TItem, TField>>{ ...i, filterValueTwo: filterValue } : i)
		}));

		const columnType = this.state.get().gridColumns.find(i => i.field === field)?.options.type!;
		const filtervalueTyped = this.createFilterValue(filterValue, columnType);

		if (filterValue.trim().length === 0 || filtervalueTyped !== undefined) {
			this.delayedLoadWithResetPage();
		}
	}

	/**
	 * Vrací seznam polí, podle kterých lze řadit
	 */
	getSortingFields = () => {
		return this.options.sortingFields ?? [];
	}
}