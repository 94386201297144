import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import useSwitch from "../../hooks/switch";
import * as state from "../../lib/state";
import storage from "store";
import Logo from "../controls/Logo";
import MenuDivision from "../controls/MenuDivision";
import DropDown from "../controls/DropDown";
import MenuMain, { MenuItem } from "../controls/MenuMain";
import SocialIcons from "../controls/SocialIcons";
import ContactSmall from "../controls/ContactSmall";
import IconSvg from "../controls/IconSvg";
import Button from "../controls/Button";
import MenuList from "../controls/MenuList";


interface PageHeaderProps {
	isHomePage?: boolean;
}

export default function PageHeader(props: PageHeaderProps) {
	const { contents, standardDialogs } = state.useStateContext();
	const searchSwitch = useSwitch();
	const { authorization } = state.useStateContext();
	const expiredMembership = new Date(storage.get("membershipTo")) < new Date();
	const isMember = authorization.getUserProfile()?.is_member;
	const isOsk = authorization.getUserProfile()?.is_osk;
	const isSnemovnik = authorization.getUserProfile()?.is_snemovnik;

	function handleSearch() {
		standardDialogs.openSearchDialog();
	}

	const menuItemsPublic: MenuItem[] = [
		{
			title: contents.getRes("o-nas-nadpis"),
			link: "/o-nas",
			subItems: [
				{ title: contents.getRes("stavovske-predpisy-nadpis"), link: "/o-nas/stavovske-predpisy" },
				{ title: contents.getRes("organy-csk-nadpis"), link: "/o-nas/organy-csk" },
				{ title: contents.getRes("stante-se-clenem-nadpis"), link: "/o-nas/stante-se-clenem" },
				{ title: contents.getRes("uredni-deska-nadpis"), link: "/o-nas/uredni-deska" },
				{ title: contents.getRes("rocenky-nadpis"), link: "/o-nas/rocenky" },
				{ title: contents.getRes("gdpr-nadpis"), link: "/o-nas/gdpr" },
				{ title: contents.getRes("jednani-snemu-nadpis"), link: "/o-nas/snem" },
				{ title: contents.getRes("graficke-standardy-nadpis"), link: "/o-nas/graficke-standardy" },
			]
		},
		{
			title: contents.getRes("aktuality-nadpis"),
			link: "/aktuality"
		},
		{
			title: contents.getRes("zubni-lekari-nadpis"),
			link: "/zubni-lekari/abecedne",
			subItems: [
				{ title: contents.getRes("seznam-poskytovatelu-zubni-pece-nadpis-menu"), link: "/zubni-lekari" },
				{ title: contents.getRes("abecedni-seznam-clenu-nadpis-menu"), link: "/zubni-lekari/abecedne" },
				{ title: contents.getRes("seznam-hostujicich-osob-nadpis"), link: "/zubni-lekari/seznam-hostujicich-osob" }
			]
		},
		{
			title: contents.getRes("pohotovosti-nadpis"),
			link: "/pohotovosti"
		},
		{
			title: contents.getRes("vzdelavani-nadpis"),
			link: "/vzdelavani/o-vzdelavani",
			subItems: [
				{ title: contents.getRes("o-vzdelani-nadpis"), link: "/vzdelavani/o-vzdelavani" },
				{ title: contents.getRes("kalendar-akci-nadpis"), link: "/vzdelavani/akce" },
				{ title: contents.getRes("staze-nadpis"), link: "/vzdelavani/staze" },
				{ title: contents.getRes("akreditovana-pracoviste-nadpis"), link: "/vzdelavani/pracoviste" },
				{ title: contents.getRes("kongres-pdd-nadpis"), link: "/vzdelavani/kongres-pdd" },
				{ title: contents.getRes("knihy-nadpis"), link: "/vzdelavani/knihy" },
				{ title: contents.getRes("casopisy-nadpis"), link: "/vzdelavani/casopis" },
				{ title: contents.getRes("registrace-akce-nadpis"), link: "/vzdelavani/registrace-akce" },
				// { title: contents.getRes("registrace-e-learningova-akce-nadpis"), link: "/vzdelavani/registrace-e-learningova-akce" }
			]
		},
		{
			title: contents.getRes("prilezitosti-inzerce-nadpis"),
			link: "/inzerce"
		},
		{
			title: contents.getRes("partneri-nadpis"),
			link: "/komercni-spoluprace"
		},
		{
			title: contents.getRes("kontakty-nadpis"),
			link: "/kontakty",
			subItems: [
				{ title: contents.getRes("csk-nadpis"), link: "/kontakty/kancelar" },
				{ title: contents.getRes("osk-nadpis"), link: "/kontakty/osk" },
				{ title: contents.getRes("kontakty-pro-novinare-nadpis"), link: "/kontakty/pro-novinare" },
				{ title: contents.getRes("odborne-spolecnosti-nadpis"), link: "/kontakty/odborne-spolecnosti/" },
				{ title: contents.getRes("kliniky-nadpis"), link: "/kontakty/kliniky" },
				{ title: contents.getRes("studenti-nadpis"), link: "/kontakty/studenti" },
				{ title: contents.getRes("stiznosti-nadpis"), link: "/kontakty/stiznosti" }
			]
		},
		{
			title: contents.getRes("verejne-nadpis"), link: "/o-nas/verejne"
		},
	];

	const menuItemsPrivate: MenuItem[] = [
		{
			title: contents.getRes("aktuality-pro-cleny-nadpis"),
			link: "/aktuality-pro-cleny"
		},
		{
			title: contents.getRes("volby-2025-2029-nadpis"),
			link: "/volba-prezidenta",
			subItems: [
				{
					title: contents.getRes("volba-prezidenta-nadpis"),
					link: "/volba-prezidenta"
				},
				{
					title: contents.getRes("volby-osk-nadpis"),
					link: "/volby-osk"
				},
			]
		},
		{
			title: contents.getRes("moje-agenda-nadpis"),
			link: "/moje-agenda",
			subItems: [
				{
					title: contents.getRes("klikaci-povinnost-nadpis"),
					link: "/klikaci-povinnost"
				},
				{
					title: contents.getRes("moje-osobni-udaje-nadpis"),
					link: "/muj-profil"
				},
				{
					title: contents.getRes("moje-vzdelani-nadpis"),
					link: "/moje-vzdelani"
				},
				{
					title: contents.getRes("pracoviste-nadpis"),
					link: "/moje-agenda/pracoviste"
				},
				{
					title: contents.getRes("vzdelavaci-akce-nadpis"),
					link: "/moje-agenda/akce"
				},
				{
					title: contents.getRes("zkousky-nadpis"),
					link: "/moje-agenda/zkousky"
				},
				{
					title: contents.getRes("vzdelavaci-programy-nadpis"),
					link: "/moje-agenda/vzdelavaci-program"
				},
				{
					title: contents.getRes("zadosti-nadpis"),
					link: "/moje-agenda/zadosti"
				},
				{
					title: contents.getRes("zpravy-nadpis"),
					link: "/moje-agenda/zpravy"
				},
				{
					title: contents.getRes("platby-nadpis"),
					link: "/moje-agenda/platby"
				},
				{
					title: contents.getRes("dokumenty-nadpis"),
					link: "/moje-agenda/dokumenty"
				}
			]
		},
		{
			title: contents.getRes("agendy-komory-nadpis"),
			link: "/agendy-komory",
			subItems: [
				{
					title: contents.getRes("e-learning-a-webinare-nadpis"),
					link: "/agendy-komory/elearnig-a-webinare"
				},
				{
					title: contents.getRes("harmonogram-akci-csk-nadpis"),
					link: "/agendy-komory/harmonogram-akci-csk"
				},
				{
					title: contents.getRes("jednani-snemu-nadpis"),
					link: "/o-nas/snem"
				},

				{
					title: contents.getRes("materialy-ze-snemu-nadpis"),
					link: "/agendy-komory/materialy-ze-snemu",
				},
				{
					title: contents.getRes("jednani-predstavenstva-nadpis"),
					link: "/agendy-komory/jednani-predstavenstva"
				},
				{
					title: contents.getRes("pravni-poradna-nadpis"),
					link: "/agendy-komory/pravni-poradna"
				},
				// {
				// 	title: contents.getRes("ekonomicka-poradna-nadpis"),
				// 	link: "/agendy-komory/ekonomicka-stranka"
				// },
				{
					title: contents.getRes("hospodareni-csk-nadpis"),
					link: "/agendy-komory/hospodareni-csk"
				},
				{
					title: contents.getRes("seznam-odobrniku-komory-nadpis"),
					link: "/kontakty/znalci"
				},
				{
					title: contents.getRes("dalsi-informace-nadpis"),
					link: "/dalsi-informace"
				},
				{
					title: contents.getRes("informacni-materialy-nadpis"),
					link: "/agendy-komory/ruzne"
				},
			]
		},
		{
			title: contents.getRes("komornik-nadpis"),
			link: "/komornik"
		},
		{
			title: contents.getRes("organy-csk-nadpis"),
			link: "/kontakty-organy-csk"
		},
		{
			title: contents.getRes("rady-csk-nadpis"),
			link: "/rady-csk"
		},
		{
			title: "Zahraniční časopisy",
			link: "/stranky/8059bb5d-85a1-4d42-86d6-f5cfd913f5c9"
		},
		{
			title: contents.getRes("profesni-pojisteni-nadpis"),
			link: "/profesni-pojisteni"
		},
	];

	const menuItemsOSK: MenuItem[] = [
		{
			title: contents.getRes("materialy-pro-jednani-nadpis"),
			link: "/osk/materialy-pro-jednani"
		},
		{
			title: contents.getRes("metodicke-pokyny-nadpis"),
			link: "/metodicke-pokyny"
		},
		{
			title: contents.getRes("seznam-clenu-nadpis"),
			link: "/osk/seznam-clenu-komory"
		},
		{
			title: contents.getRes("zadosti-nadpis"),
			link: "/osk/zadosti"
		},
		{
			title: contents.getRes("prevody-z-csk-nadpis"),
			link: "/osk/prevody-z-csk"
		},
		// {
		// 	title: contents.getRes("dokumenty-nadpis"),
		// 	link: "/osk/dokumenty"
		// },
		{
			title: contents.getRes("zpravy-nadpis"),
			link: "/osk/zpravy"
		},
		{
			title: contents.getRes("organy-osk-nadpis"),
			link: "/osk/organy-osk"
		},
	];

	function getAuthorizedMenu() {

		const section = authorization.getCurrentSection();
		if (section != "osk" || expiredMembership) {
			const items =
				menuItemsPrivate.filter(i => isSnemovnik || i.link != "/agendy-komory/materialy-ze-snemu").map(x => ({ ...x, subItems: x.subItems }));
			return items;
		}
		else {
			return menuItemsOSK
		}
	}

	return (
		<>
			<div id="page-top" className="anchor anchor--page-top" />
			<header role="banner" className="header">
				<div className="row-main">
					<div className="header__inner">
						<div className={"header__item " + (authorization.userLoggedIn() ? "header__item--login " : "")}>
							<Logo />
						</div>
						<div className={"header__item " + (authorization.userLoggedIn() ? "header__item--login " : "")}>
							<div className="header__top">
								<div className="header__top-item">
									<DropDown
										slide
										triggerElement={
											authorization.userLoggedIn()
												? <Button><IconSvg name="user" className="header__icon-user" />&nbsp;<span className="d-none d-sm-inline">{authorization.getUserProfile()?.full_name ?? "Načítám profil..."}</span><IconSvg name="arrow-down" className="ml-2" /></Button>
												: <Link to="/prihlaseni">
													<Button className="login-text-desktop">
														Přihlášení do členské sekce
													</Button>
													<Button className="login-text-mobile">
														Členská sekce
													</Button>
												</Link>
										}
										children={
											<MenuList>
												<li><Link to="/muj-profil">Můj profil</Link></li>
												<li><Link to="/zmena-hesla">Změna hesla</Link></li>
												<li onClick={() => authorization.logout()}><span className="separated">Odhlásit se</span></li>
											</MenuList>
										}
									/>
								</div>
								{authorization.userLoggedIn() &&
									<div className="header__top-item">
										<MenuDivision />
									</div>
								}
								<div className="header__top-item">
									<SocialIcons />
								</div>
							</div>
							{!authorization.userLoggedIn() &&
								<div className="header__bottom">
									<ContactSmall />
								</div>
							}
						</div>
					</div>
					{/* <div className={"search " + (authorization.userLoggedIn() ? "search--loggedin " : "") + "js-search " + (searchSwitch.isOn ? "is-open " : "")}>
						<form action="?" role="search" className="search__form js-form">
							<label htmlFor="search" className="inp-label u-vhide">
								Vyhledávání
								</label>
							<span className="inp-fix inp-fix--search">
								<input type="text" name="search" id="search" className="inp-text inp-text--search" placeholder="Vyhledávání" />
								<span className="search__close js-close" onClick={searchSwitch.toogle}>
									<IconSvg name="close" />
								</span>
							</span>
							<button type="submit" className="btn btn--search">
								<span className="btn__text">
									<IconSvg name="search" />
									<span className="u-vhide">
										Vyhledat
									</span>
								</span>
							</button>
						</form>
						<span className="search__open js-open" onClick={searchSwitch.toogle}>
							<IconSvg name="search" />
						</span>
					</div> */}
					<div className="u-mb-md d-flex justify-content-end d-lg-block">
						<nav role="navigation" className="menu-main menu-main--authorized header__menu-authorized">
							<span onClick={handleSearch} className="menu-main__burger js-menu__burger">
								<IconSvg name="search" />
							</span>
						</nav>
						<MenuMain search={handleSearch} items={menuItemsPublic} className={"header__menu-public " + (authorization.userLoggedIn() && !expiredMembership ? "--authorized" : "--authorized")} />
						{authorization.userLoggedIn() && !expiredMembership && (isOsk || isMember) &&
							<MenuMain items={getAuthorizedMenu()} theme="authorized" className="header__menu-authorized" />
						}
					</div>
				</div>
			</header >
		</>
	);
}