/**
 * Domovská stránka
 */
import React from "react";

import Page from "../../../components/templates/Page";
import CardExpandable from "../../../components/controls/CardExpandable";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

import useExclusiveSwitch from "../../../hooks/exclusive-switch";

import * as state from "../../../lib/state";

function ContactsProfessionalCompaniesPage() {
	const cardSwitch = useExclusiveSwitch();

	const model = state.useStateContext().contactsProfessionalCompanies;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("odborne-spolecnosti-nadpis")} description="">
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="odborne-spolecnosti-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>
			<HtmlContent htmlKey="odborne-spolecnosti-obsah" />
			<div className="row">
				{model.getCompanies().map((s, i) =>
					<div className="col-12">
						<CardExpandable
							open={cardSwitch.current === i.toString()}
							onToggle={() => cardSwitch.toggle(i.toString())}
							cardTitle={<div className="mb-0 secondary-title">{s.title}</div>}
							className="mb-gutter"
						>
							<HtmlContent content={s.text} />
						</CardExpandable>
					</div>
				)}
			</div>
		</Page>
	);
}

export default state.bindContainers(
	ContactsProfessionalCompaniesPage,
	c => c.contactsProfessionalCompanies
);