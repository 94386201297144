/**
 *  Formulář žádosti o vydání osvědčení o splnění podmínek k výkonu funkce zubního lékaře nebo primáře
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import Button from "../../../components/controls/Button";

interface WebRequestExtensionOfValidityPageProps {
	id?: string;
}

function WebRequestExtensionOfValidityPage(props: WebRequestExtensionOfValidityPageProps) {
	const { webRequestExtensionOfValidity: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title="ŽÁDOST O PRODLOUŽENÍ DOBY PLATNOSTI OSVĚDČENÍ O 1 ROK"
			description=""
			id={props.id}
		>
			<h2 className="form-title">Žádám o prodloužení doby platnosti níže uvedeného osvědčení o 1 rok</h2>
			{model.getCertifications().length > 1 &&
				<FormSelect
					className="mt-5"
					form={form}
					field="certificate"
					options={model.getCertifications().map(i => ({ title: i.name, value: i.id }))}
				/>

			}
			{model.getCertifications().length == 1 &&
				<h2 className="text-danger font-weight-bold">Není k dispozici žádné osvědčení</h2>
			}
			<p>
				<strong>Poučení</strong><br />
				<small>
					Možnosti prodloužit platnost svého stávajícího osvědčení můžete využít,
					pokud se Vám nepodařilo nebo zřejmě nepodaří absolvovat celý navazující
					vzdělávací program v předepsané době (3 roky v případě navazujícího
					vzdělávacího programu v praktickém zubním lékařství, 5 let v případě
					navazujícího vzdělávacího programu ve stomatochirurgii, parodontologii
					nebo pedostomatologii). Tuto žádost můžete podat do 1 roku od skončení
					platnosti svého posledního osvědčení. Prodloužením platnosti osvědčení o 1 rok dojde
					automaticky i k prodloužení doby trvání navazujícího vzdělávacího programu; počet kreditů,
					který pro absolvování takto prodlouženého vzdělávacího programu potřebujete získat,
					se zvyšuje o 20.
				</small>
			</p>

			<FormCheckBox
				form={form}
				field="declaration"
				className="mb-5"
			/>

			<div className="row">
				<div className="col-md-4 col-lg-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={form}
						field="submission_date"
					/>
				</div>
			</div>

			<p>
				Za zpracování této žádosti je stanoven poplatek ve výši <strong>{form.getField("amount").value} Kč</strong>.
			</p>
			<p>
				Pokyn k úhradě poplatku Vám bude odeslán e-mailem obratem po odeslání Vaší žádosti. Vaše žádost bude zpracována po připsání platby
				na účet ČSK.
			</p>
			<div className="text-right mt-5">
				<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
				<em>Odesláním potvrzuji správnost údajů</em><br /><br />
			</div>
			{!props.id &&
				<div className="text-right">
					<Button
						theme="primary"
						onClick={model.confirm}
						spinner={model.common.justSending()}>

						Odeslat
					</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestExtensionOfValidityPage,
	c => c.webRequestExtensionOfValidity
);