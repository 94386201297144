/**
 * Model žádosti o přihlášku na akci
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";
import * as formatting from "../../../lib/formatting";
import * as validations from "../../../lib/validations";
import * as navigation from "../../../lib/navigation";
import * as api from "../../../lib/api";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiEducationalActions from "../../shared/educational-actions/api-educational-actions";
import * as apiPriceList from "../../shared/price-list/api-price-list";
import * as apiAuth from "../../../modules/system/authorization/api-authorization";

export type FormStep = "form" | "summary";
type PaymentMethod = "transfer" | "gateway";

/**
 * Stav modulu
 */
interface State {
	justLoadingAction: boolean;
	discount: apiEducationalActions.Discount;
	id_request: string | undefined;
}

/**
 * Definice dat formuláře
 */
export interface FormFields {
	action?: Action;
	from_admin: boolean;
	is_chamber_member: boolean;
	price?: Price;
	hotel?: api.CodeBookItemWithNumberId;
	offers_in_accommodation_facilities?: boolean;
	// účastník akce
	ikc: string;
	ikc_loaded: boolean;
	first_name: string;
	last_name: string;
	title_before: string;
	title_after: string;
	birthday_date: string;
	place_of_birth: string;
	phone: string;
	email: string;
	id_request?: string;

	// fakturovat na
	invoice_name: string;
	invoice_street: string;
	invoice_city: string;
	invoice_country: string;
	invoice_postcode: string;
	invoice_ico: string;
	invoice_dic: string;

	// pracoviště
	workplace_name: string;
	workplace_street: string;
	workplace_city: string;
	workplace_country: string;
	workplace_postcode: string;
	workplace_ico: string;
	workplace_dic: string;

	// platební metoda
	payment_method: PaymentMethod;
	capacity_exhausted: boolean;
	generate_payments: boolean;
	educational_action_category?: api.CodeBookItemWithNumberId;
	description_summary: string;
}

interface Action extends api.CodeBookItem {
	introduction: string;
	date_from?: Date;
	date_to?: Date;
	place_of_activity: string;
	price_list: apiPriceList.WebPriceList;
	prices: Price[];
	social_programs: SocialProgram[];
	programs: Program[];
	generate_payments: boolean;
	do_not_register: boolean;
	capacity_exhausted?: boolean;
	educational_action_category?: api.CodeBookItemWithNumberId;
	show_hotel: boolean;
	is_public: boolean;
}

/**
 * Zjednodušená struktura společenského programu pro formulář
 */
export interface SocialProgram extends api.CodeBookItem {
	prices: Price[];
	checked: boolean | undefined;
	next_persons: number;
}

/**
 * Zjednodušená struktura programu pro formulář
 */
interface Program {
	name: string;
	date?: Date;
	items: ProgramItem[];
}

/**
 * Položka programu ve formuláři
 */
interface ProgramItem extends apiEducationalActions.WebEducationalActionProgramGroupItem {
	checked: boolean;
}

/**
 * Zjednodušení typ pro cenu a její platnost pro formulář
 */
export interface Price {
	id: api.Id;
	name: string;
	amount: number;
	amount2?: number;
	max_count: number;
	valid_to?: Date;
	role_id: number;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;

	constructor(public context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ justLoadingAction: false, id_request: undefined, discount: { amount: 0, text: "" } }, context);
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				action: {
					title: "Akce",
					required: true,
					defaultValue: undefined,
					validate: value => value === undefined ? "Vyplňte prosím akci" : ""
				},
				generate_payments: {
					defaultValue: false,
					title: ""
				},
				from_admin: {
					defaultValue: false,
					title: ""
				},
				is_chamber_member: {
					title: "Členství v ČSK",
					defaultValue: false
				},
				hotel: {
					title: "Zvolte hotel",
					defaultValue: undefined,
					required: (form) => form.getField("action").value && form.getField("action").value?.show_hotel && form.getField("offers_in_accommodation_facilities").value ? true : false,
					validate: (value, field, form) => value === undefined && form.getField("action").value && form.getField("action").value?.show_hotel && (form.getField("offers_in_accommodation_facilities").value == undefined || form.getField("offers_in_accommodation_facilities").value == true) ? "Vyplňte prosím hotel" : ""
				},
				offers_in_accommodation_facilities: {
					title: "Údaje o ubytování jsou pouze informativní, nejedná se o rezervaci ubytování. Údaje sbíráme pouze proto, abychom se mohli ucházet o dotaci na pořádání kongresu u Hlavního města Prahy.",
					defaultValue: undefined,
					required: (form) => form.getField("action").value && form.getField("action").value?.show_hotel ? true : true,
					validate: (value, field, form) => form.getField("action").value && form.getField("action").value?.show_hotel && value == undefined ? "Vyplňte prosím pole" : ""
				},
				price: {
					title: "Výběr kategorie účastníka",
					required: true,
					validate: (value, field, form) => {
						if (this.getIdRequest()) {
							return "";
						}
						return value === undefined ? "Vyplňte prosím kategorii účastníka" : ""
					},
					defaultValue: undefined
				},
				ikc: {
					title: "IKČ",
					defaultValue: "",
					placeHolderText: "po zadání IKČ budou Vaše údaje načteny z registru členů",
					required: true,
					validate: (value) => {
						if (this.getIdRequest()) {
							return "";
						}
						return this.validateIkc(value);
					},
					onChange: async (value, field, form) => {
						value = this.normalizeIkc(value),
							await form.setField("ikc_loaded", false);
						if (value.trim().length === 8) {
							const member = await this.context.apiMembers.findMemberByIKC(value);
							const action = this.form.getField("action").value;
							if (action) {
								const discount = await this.context.apiEducationalActions.loadDiscount(action.id, value);
								await this.setDiscount(discount);
							}
							await form.setFields({
								ikc_loaded: true,

								// účastník akce
								first_name: member.first_name,
								last_name: member.last_name,
								title_before: member.title_before,
								title_after: member.title_after,
								phone: member.contact.phone1 || member.contact.phone2,
								email: member.contact.email1 || member.contact.email2,
								birthday_date: formatting.formatDate(member.birthdate),

								// fakturace
								invoice_name: member.address.name,
								invoice_street: member.address.street,
								invoice_city: member.address.city,
								invoice_postcode: member.address.postcode,
								invoice_ico: member.registration_number,
								invoice_dic: member.vat_number,

								// // pracoviště
								// workplace_name: member.address.name,
								// workplace_street: member.address.street,
								// workplace_city: member.address.city,
								// workplace_postcode: member.address.postcode,
								// workplace_ico: member.registration_number,
								// workplace_dic: member.vat_number,
							});
							if (form.validated()) {
								await form.validate();
							}
						}
					}
				},
				ikc_loaded: {
					title: "IKČ ověřeno jako platné",
					defaultValue: false,
				},
				first_name: {
					title: "Jméno",
					defaultValue: "",
					required: true,
					readOnly: form => this.visitorFieldsReadOnlyMember(form) || this.getIdRequest() as any,
					validate: value => value.trim().length === 0 ? "Vyplňte prosím jméno účastníka" : "",
				},
				last_name: {
					title: "Příjmení",
					defaultValue: "",
					required: true,
					readOnly: form => this.visitorFieldsReadOnlyMember(form) || this.getIdRequest() as any,
					validate: value => value.trim().length === 0 ? "Vyplňte prosím příjmení účastníka" : "",
				},
				title_before: {
					title: "Titul před",
					defaultValue: "",
					readOnly: form => this.visitorFieldsReadOnlyMember(form) || this.getIdRequest() as any,
				},
				title_after: {
					title: "Titul za",
					defaultValue: "",
					readOnly: form => this.visitorFieldsReadOnlyMember(form) || this.getIdRequest() as any,
				},
				place_of_birth: {
					title: "Místo narození",
					defaultValue: "",
					required: (form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public ? true : false,
					validate: (value, field, form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public && value.trim().length === 0 ? "Vyplňte prosím místo narození" : "",
				},
				birthday_date: {
					title: "Datum narození",
					defaultValue: "",
					required: true,
					validate: (value, field, form) => {
						if (this.context.authorization.userLoggedIn() && form.getField("is_chamber_member").value === false) {
							return "";
						}

						if (this.getIdRequest()) {
							return "";
						}

						if (form.getField("is_chamber_member").value) {
							return "";
						}

						if (value.trim().length === 0) {
							return "Vyplňte prosím datum narození";
						}

						if (!validations.isValidDate(value)) {
							return "Chybný formát data narození (zkontrolujte formát DEN. MĚSÍC. ROK)";
						}

						const birthdDate = formatting.parseDate(value);
						const now = new Date();
						if (birthdDate && now.getFullYear() - birthdDate.getFullYear() <= 1) {
							return "Datum narození je příliš vysoké.";
						}
						if (birthdDate && birthdDate < new Date("1/1/1900")) {
							return "Datum narození je příliš malé.";
						}
						if (!validations.isValidDate(value)) {
							return "Chybný formát data narození (zkontrolujte formát DEN. MĚSÍC. ROK)";
						}

						return "";
					}
				},
				phone: {
					title: "Telefon",
					defaultValue: "",
					required: true,
					placeHolderText: "+420 XXX XXX XXX",
					validate: value => value.trim().length === 0 ? "Telefon je povinný údaj" : !validations.isPhoneNumber(value) ? "Telefon je v chybném formát" : "",
					readOnly: form => this.visitorFieldsReadOnly(form) && !this.getIdRequest(),
				},
				email: {
					title: "E-mail",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length === 0
						? "Vyplňte prosím e-mail účastníka"
						: validations.validateEmailAddress(value),
					readOnly: form => this.visitorFieldsReadOnly(form) || this.getIdRequest() as any,

				},
				invoice_name: {
					title: "Fakturovat na",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length === 0 ? "Vyplňte prosím subjekt, na který bude fakturováno" : "",
				},
				workplace_name: {
					title: "Název pracoviště",
					defaultValue: "",
					required: (form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public ? true : false,
					validate: (value, field, form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public && value.trim().length === 0 ? "Vyplňte prosím název pracoviště" : "",
				},
				invoice_street: {
					title: "Ulice + č.p.",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length === 0 ? "Vyplňte prosím ulici do fakturačních údajů" : "",
				},
				workplace_street: {
					title: "Ulice + č.p.",
					defaultValue: "",
					required: (form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public ? true : false,
					validate: (value, field, form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public && value.trim().length === 0 ? "Vyplňte prosím ulici + č.p." : "",
				},
				invoice_city: {
					title: "Obec",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length === 0 ? "Vyplňte prosím obec do fakturačních údajů" : "",
				},
				workplace_city: {
					title: "Obec",
					defaultValue: "",
					required: (form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public ? true : false,
					validate: (value, field, form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public && value.trim().length === 0 ? "Vyplňte obec" : "",
				},
				invoice_country: {
					title: "Stát",
					defaultValue: ""
				},
				workplace_country: {
					title: "Stát",
					defaultValue: "",
				},
				invoice_postcode: {
					title: "PSČ",
					defaultValue: "",
					required: true,
					validate: value => value.trim().length === 0 ? "Vyplňte prosím PSČ do fakturačních údajů" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků",
				},
				workplace_postcode: {
					title: "PSČ",
					defaultValue: "",
					required: (form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public ? true : false,
					validate: (value, field, form) => form.getField("is_chamber_member").value === false && form.getField("action").value && form.getField("action").value?.is_public && value.trim().length === 0 ? "Vyplňte PSČ" : value.trim().length == 5 ? "" : value.trim().length === 0 ? "" : "PSČ musí obsahovat 5 znaků",
				},
				invoice_ico: {
					title: "IČO",
					defaultValue: ""
				},
				workplace_ico: {
					title: "IČO",
					defaultValue: "",
				},
				invoice_dic: {
					title: "DIČ",
					defaultValue: ""
				},
				workplace_dic: {
					title: "DIČ",
					defaultValue: "",
				},
				payment_method: {
					title: "Způsob platby",
					defaultValue: "transfer",
				},
				description_summary: {
					title: "Souhrn popisu",
					defaultValue: "",
				},
				educational_action_category: {
					defaultValue: undefined,
					title: ""
				},
				capacity_exhausted: {
					title: "",
					defaultValue: false
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	validateIkc = (value: string) => {
		value = this.normalizeIkc(value);

		if (this.context.authorization.userLoggedIn() || this.form.getField("is_chamber_member").value === false)
			return "";

		if (value.trim().length === 0)
			return "Prosím vyplňte IKČ";

		if (value.trim().length > 0 && !/^\d{8}$/.test(value))
			return "IKČ musí obsahovat přesně 8 číslic";

		if (!this.form.getField("ikc_loaded").value) {
			return "Platnost IKČ nebyla ověřena v databázi";
		}

		return "";
	}

	normalizeIkc = (value: string) => {
		return value.replace(/\s/g, "");
	}

	visitorFieldsReadOnly(form: forms.Form<FormFields>) {
		return !this.context.authorization.userLoggedIn()
			&& form.getField("is_chamber_member").value
			&& !form.getField("ikc_loaded").value;
	}

	visitorFieldsReadOnlyMember(form: forms.Form<FormFields>) {
		if (this.context.authorization.userLoggedIn())
			return true;
		return !this.context.authorization.userLoggedIn()
			&& form.getField("is_chamber_member").value
			&& !form.getField("ikc_loaded").value;
	}

	loadProfileData = async (idRequest?: string, profile?: apiAuth.UserProfile) => {
		if (this.form.getField("last_name").value.trim().length > 0) {
			return;
		}

		if (!this.context.authorization.userLoggedIn() && !idRequest) {
			return;
		}

		let address = {} as any;

		let userProfile = this.context.authorization.getUserProfile()!;
		if (!userProfile && !idRequest) {
			userProfile = await this.context.authorization.loadUserProfile();
		}
		if (idRequest && profile) {
			userProfile = {} as any;
			userProfile.profile = profile;
			address = profile.address;
		}
		else {
			address = userProfile.profile.workplace_main.address ? userProfile.profile.workplace_main.address : userProfile.profile.address;
		}


		await this.form.setFields({
			is_chamber_member: true,
			title_after: userProfile.profile.title_after,
			title_before: userProfile.profile.title_before,
			first_name: userProfile.profile.first_name,
			last_name: userProfile.profile.last_name,
			birthday_date: formatting.formatDate(userProfile.profile.birthdate),
			phone: userProfile.profile.contact?.phone1 ?? "",
			email: userProfile.profile.contact?.email1 ?? "",
			invoice_name: userProfile.profile.first_name + " " + userProfile.profile.last_name,
			invoice_street: address?.street ?? "",
			invoice_city: address?.city ?? "",
			invoice_country: address?.country?.name,
			invoice_postcode: address?.postcode == undefined ? "" : address?.postcode.replace(/\s+/g, ""),
		});
	}

	private toPriceListSimple = (priceLists: apiPriceList.WebPriceList[]): Price[] => {
		if (priceLists.length === 0)
			return [];

		return priceLists[0].items
			.map(p => (<Price>{
				id: p.id,
				name: p.name,
				amount: p.amount,
				amount2: p.amount2,
				max_count: p.max_count,
				valid_to: priceLists[0].date_to,
				role_id: p.id_role
			}));
	}

	private toSocialProgramsSimple = (socialPrograms: apiEducationalActions.WebEducationalActionProgram[]): SocialProgram[] => {
		return socialPrograms.map(i => ({
			id: i.id,
			name: i.name,
			prices: this.toPriceListSimple(i.price_lists),
			checked: undefined,
			next_persons: 0
		}));
	}

	private toProgramsSimple = (programs: apiEducationalActions.WebEducationalActionProgramGroup[]): Program[] => {
		return programs.map(i => ({
			name: i.name,
			date: i.date,
			items: i.items.map(i => ({ ...i, checked: false }))
		}));
	}

	loadDefaultAction = async (actionId: string) => {
		const currentAction = this.form.getField("action").value;
		if (currentAction?.id === actionId) {
			return;
		}
		const idRequest = api.getUrlParam("id_request") ? "?id_request=" + api.getUrlParam("id_request") : "";
		const action = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingAction: true })),
			exec: () => this.context.apiEducationalActions.standard.load(actionId, idRequest),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingAction: false, id_request: api.getUrlParam("id_request") ?? undefined }))
		});
		this.loadProfileData(idRequest, action.profile);
		if (action) {
			const priceList = this.toPriceListSimple(action.price_lists);
			await this.form.setField("action", {
				id: action.id,
				show_hotel: action.show_hotel,
				is_public: action.is_public,
				name: action.name,
				introduction: action.introduction,
				date_from: action.date_from,
				date_to: action.date_to,
				place_of_activity: action.place_of_activity,
				price_list: action.price_lists[0],
				prices: priceList,
				social_programs: this.toSocialProgramsSimple(action.social_programs),
				programs: this.toProgramsSimple(action.programs),
				generate_payments: action.generate_payments,
				do_not_register: action.do_not_register,
				capacity_exhausted: this.form.getField("capacity_exhausted").value
			});
			await this.form.setField("educational_action_category", action.educational_action_category as any);
			await this.form.setField("description_summary", action.description_summary);
			await this.form.setField("price", priceList && priceList.length == 1 ? priceList[0] : undefined);
			const discount = await this.context.apiEducationalActions.loadDiscount(action.id);
			await this.setDiscount(discount);
		}
	}

	/**
	 * Indikace načítání qkce
	 */
	justLoadingAction = () => {
		return this.stateContainer.get().justLoadingAction;
	}

	getIdRequest = () => {
		return this.stateContainer.get().id_request;
	}

	/**
	 * Vrací seznam rolí návštěvníka, které jsou k disppozici pro vybranou akci
	 */
	getPrices = (): Price[] => {
		const action = this.form.getField("action").value;
		return action?.prices ?? [];
	}

	/**
	 * Vrací celkovou cenu společenského programu i s ohledem na doprovodné osoby
	 */
	getSocialProgramTotalPrice = (socialProgram: SocialProgram) => {
		return socialProgram.prices[0].amount + (socialProgram.next_persons * (socialProgram.prices[0].amount2 ?? 0));
	}

	/**
	 * Vrací celkovou cenu za akci
	 */
	getTotalPrice = (): number | undefined => {
		const actionPrice = this.form.getField("price").value;
		const socialProgramsPrice = this.getSocialPrograms()
			.filter(i => i.checked)
			.map(i => this.getSocialProgramTotalPrice(i))
			.reduce((p, c) => p + c, 0);

		return actionPrice ? actionPrice.amount + socialProgramsPrice + this.getDiscount().amount : undefined;
	}

	getSocialPrograms = (): SocialProgram[] => {
		const action = this.form.getField("action").value;
		const price = this.form.getField("price").value;

		return action && price
			? action.social_programs
				.map(i => ({
					...i,
					prices: i.prices.filter(p => p.role_id === price.role_id)
				}))
				.filter(i => i.prices.length > 0)
			: [];
	}

	/**
	 * Označí/zruší subskripci na společenský program
	 */
	toggleSocialProgram = async (value: boolean | undefined) => {
		const currentAction = this.form.getField("action").value;
		if (!currentAction)
			return;

		await this.form.setField("action", {
			...currentAction,
			social_programs: currentAction.social_programs.map(i => ({
				...i,
				checked: value
			}))
		});
	}

	/**
	 * Nastaví počet doprovodných osob pro sociální program
	 */
	setSocialProgramNextPersons = async (socialProgram: SocialProgram, nextPersons: string) => {
		const nextPersonsNum = parseInt(nextPersons);
		if (isNaN(nextPersonsNum)) {
			return;
		}

		if (nextPersonsNum < 0) {
			return;
		}

		if (nextPersonsNum > socialProgram.prices[0].max_count) {
			return;
		}

		const currentAction = this.form.getField("action").value;
		if (!currentAction)
			return;

		await this.form.setField("action", {
			...currentAction,
			social_programs: currentAction.social_programs.map(i => ({
				...i,
				next_persons: i.id === socialProgram.id ? nextPersonsNum : i.next_persons
			}))
		});
	}

	/**
	 * Označí/zruší subskripci na společenský program
	 */
	toggleProgram = async (programIndex: number, item: ProgramItem | undefined) => {
		const currentAction = this.form.getField("action").value;
		if (!currentAction)
			return;

		if (!item) {
			await this.form.setField("action", {
				...currentAction,
				programs: currentAction.programs.map((p, pindex) => ({
					...p,
					items: p.items.map(j => ({
						...j,
						checked: pindex === programIndex
							? false
							: j.checked
					}))
				}))
			});
		} else {
			await this.form.setField("action", {
				...currentAction,
				programs: currentAction.programs.map((p, pindex) => ({
					...p,
					items: p.items.map(j => ({
						...j,
						checked: pindex === programIndex
							? j.id === item.id
								? !j.checked
								: false
							: j.checked
					}))
				}))
			});
		}
	}

	/**
	 * Přejde na shrnutí přihlášky
	 */
	nextToSummary = async () => {
		await this.form.validate();
		const isPaymentEnabled = api.getUrlParam("enablePayment");
		const idRequest = api.getUrlParam("id_request");
		if (this.form.isValid() &&
			(this.getSocialPrograms().every(x => x.checked != undefined))
		) {
			navigation.to("/vzdelavani/prihlasit-se-na-akci/" + this.form.getField("action").value!.id + "/shrnuti" + (isPaymentEnabled ? "?enablePayment=true" : "") + (idRequest ? "?id_request=" + idRequest : ""));
		} else {
			await this.context.standardDialogs.openInformationDialog("Přihláška na akci obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Kontrola, zda je možno zbrazit summary. Pokud není formulář validní, přesměruje na žádost
	 */
	checkSummaryAvailableOrRedirect = async () => {
		if (!this.form.isValid()) {
			navigation.to("/zadost-prihlaska-na-akci", { replaceHistory: true });
		}
	}

	/**
	 * Potvrdí platbu, provede uložení přihlášky a případně přesměruje na platební bráu
	 */
	confirm = async () => {
		if (this.form.isValid()) {
			const data = this.form.getFields();
			if (data.action) {
				data.action.introduction = "";
			}
			data.description_summary = "";
			data.ikc = this.normalizeIkc(data.ikc);
			const fromAdmin = api.getUrlParam("fromAdmin");
			if (fromAdmin) {
				data.from_admin = true;
			}
			const requestParam = api.getUrlParam("id_request");
			const id_request = requestParam ? ("?" + requestParam) : undefined;
			data.id_request = requestParam ?? undefined;
			const response = await this.common.send(data, "zadost-prihlaska-na-akci", id_request);
			if (response.messages.length > 0 && response.messages[0].code === 205) {
				const paymentGatewayUrl = (response as any).url as string;
				window.location.href = paymentGatewayUrl;
			}
		}
	}

	/**
	 * Nastaví slevu
	 */
	setDiscount = async (discount: apiEducationalActions.Discount) => {
		await this.stateContainer.merge(_ => ({
			discount
		}));
	}

	/**
	 * Nastaví slevu
	 */
	getDiscount = () => {
		return this.stateContainer.get().discount;
	}

	/**
 * Načte data do formuláře 
 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const formFields = webRequest.data as FormFields;
			await this.form.setFields({
				...formFields,
				// Toto mapování je nutné pro to, aby vybraný záznam odpovídal číselníku "instančně"
				price: formFields.action?.prices.find(i => i.role_id === formFields.price?.role_id)
			});
		}
	}

	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			await this.loadProfileData()
		]);
	}
}