/**
 * Formulář pro reset hesla
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as forms from "../../../lib/forms";

/**
 * Definice dat formuláře
 */
export interface FormFields {
	password: string;
	repeat_password: string;
};

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		password: {
			title: "Nové heslo",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "Heslo je povinné pole" : "",
		},
		repeat_password: {
			title: "Zopakujte nové heslo",
			defaultValue: "",
			required: true,
			validate: (value, model, form) => value != form.getField("password").value ? "Heslo neodpovídá novému heslu" : "",
		}
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.form.getStateContainers()
		];
	}

	send = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const result = await this.context.api.post("/auth/change_password?token=" + api.getUrlParam("token"), { new_password: this.form.getField("password").value });
			if (result) {
				await this.context.standardDialogs.openInformationDialog("Heslo bylo změněno.");
			}
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}