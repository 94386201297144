/**
 * Model publikace
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiPublicationTopics from "../../shared/publications/api-publications";

/**
 * Stav modulu
 */
interface State {
	topics: apiPublicationTopics.WebPublicationTopic[];
	items: apiPublicationTopics.PublicationTopicContent[];
	isSearchActive: boolean;
	fulltext: string;
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			topics: [],
			justLoading: false,
			isSearchActive: false,
			items: [],
			fulltext: ""
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	justLoading = () => {
		return this.stateContainer.get().justLoading;
	}

	/**
	 * Načte data
	 */
	loadData = async () => {
		const response = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiPublications.topics.loadList({ per_page: 999 }),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(prevState => ({
			topics: response.data
		}));
	}

	setItems = async (items: apiPublicationTopics.PublicationTopicContent[]) => {
		await this.stateContainer.merge(prevState => ({
			items: items
		}));
	}

	resetItems = async () => {
		await this.stateContainer.merge(prevState => ({
			items: []
		}));
	}

	setIsSearchActive = async (value: boolean) => {
		await this.stateContainer.merge(prevState => ({
			isSearchActive: value
		}));
	}

	setFulltext = async (value: string) => {
		await this.stateContainer.merge(prevState => ({
			fulltext: value
		}));
	}

	getTopics = () => this.stateContainer.get().topics;
	getItems = () => this.stateContainer.get().items;
	getIsSearchActive = () => this.stateContainer.get().isSearchActive;
	getFulltext = () => this.stateContainer.get().fulltext;
}