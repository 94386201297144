/**
 * Pokladna e-shopu
 */

import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as navigation from "../../../lib/navigation";
import * as validations from "../../../lib/validations";
import * as context from "../../../context";

interface State {
	sent: boolean;
	profileDataLoaded: boolean;
}

/**
 * Definice dat formuláře
 */
interface FormFields {
	first_name: string;
	last_name: string;
	company: string;
	country: string;
	state: string;
	city: string;
	street: string;
	postcode: string;
	phone: string;
	email: string;
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		first_name: {
			title: "Křestní jméno",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "Křestní jméno je povinný údaj" : ""
		},
		last_name: {
			title: "Příjmení",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "Příjmení je povinný údaj" : ""

		},
		company: {
			title: "Název firmy",
			defaultValue: ""
		},
		country: {
			title: "Země",
			defaultValue: "",
		},
		state: {
			title: "Stát",
			defaultValue: ""
		},
		city: {
			title: "Město",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "Město je povinný údaj" : ""
		},
		street: {
			title: "Ulice + č.p.",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "Ulice je povinný údaj" : ""
		},
		postcode: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "PSČ je povinný údaj" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		phone: {
			title: "Telefon",
			defaultValue: "",
			placeHolderText: "+420 XXX XXX XXX",
			required: true,
			validate: value => value.trim().length === 0 ? "Telefon je povinný údaj" : !validations.isPhoneNumber(value) ? "Telefon je v chybném formát" : ""
		},
		email: {
			title: "E-mail",
			defaultValue: "",
			required: true,
			validate: value => value.trim().length === 0 ? "E-mail je povinný údaj" : validations.validateEmailAddress(value)
		}
	}
};

/**
 * Model stránky
 */
export class Model {
	public stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			sent: false,
			profileDataLoaded: false
		}, context);
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.form.getStateContainers()
	]

	/**
	 * Načte data z profilu v případě neanonymního režimu
	 */
	loadProfileData = async () => {
		const alreadyLoaded = this.stateContainer.get().profileDataLoaded;
		if (!this.context.authorization.userLoggedIn() || alreadyLoaded) {
			return;
		}

		let userProfile = this.context.authorization.getUserProfile();
		if (!userProfile) {
			userProfile = await this.context.authorization.loadUserProfile();
		}

		await this.form.setFields({
			first_name: userProfile.profile.first_name,
			last_name: userProfile.profile.last_name,
			city: userProfile.profile.delivery_address?.city ?? userProfile.profile.address?.city ?? "",
			street: userProfile.profile.delivery_address?.street ?? userProfile.profile.address?.street ?? "",
			postcode: userProfile.profile.delivery_address?.postcode == undefined ? "" : userProfile.profile.delivery_address?.postcode.replace(/\s+/g, ""),
			country: userProfile.profile.delivery_address?.country?.name ?? userProfile.profile.address?.country?.name ?? "",
			email: userProfile.profile.contact?.email1 ?? userProfile.profile.contact?.email2 ?? "",
			phone: userProfile.profile.contact?.phone1 ?? userProfile.profile.contact?.phone2 ?? ""
		});

		await this.stateContainer.merge(_ => ({ profileDataLoaded: true }));
	}

	/**
	 * Načtení dat, reset
	 */
	loadData = async () => {
		await Promise.all([
			this.stateContainer.merge(_ => ({ sent: false })),
			this.context.shopCart.loadData()
		]);
		await this.loadProfileData();
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (!this.form.isValid()) {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné nebo chybně zadané údaje. Prosím opravte vyznačené chyby a pak pokračujte.");
			return;
		}

		const cart = this.context.shopCart.getCart();
		await this.context.api.post("/shop_carts/order", { ...cart, ...this.form.getFields() });

		await Promise.all([
			this.stateContainer.merge(_ => ({
				sent: true
			})),
			this.form.clearFields()
		]);
	}

	/**
	 * Objednávka odeslaná?
	 */
	sent = () => {
		return this.stateContainer.get().sent;
	}

	/**
	 * Přesměruje na přihlášení
	 */
	login = async () => {
		await this.context.login.setRedirectRoute("/vzdelavani/knihy/pokladna");
		navigation.to("/prihlaseni");
	}
}