/**
 * Seznam mých akcí
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import List from "../../system/lists/List";

function MyEducationalProgramsPage() {
	const { myEducationalPrograms: myEducationalprograms } = state.useStateContext();
	return (
		<Page
			title="Seznam akcí"
			description=""
			authorized
		>
			<h1>
				Seznam vzdělávacích programů
			</h1>
			<List list={myEducationalprograms.list} simpleTable />
		</Page>
	);
}

export default state.bindContainers(
	MyEducationalProgramsPage,
	context => context.myEducationalPrograms
);