/**
 * Modul stránky "Můj profil"
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";

/**
 * Stav modulu
 */
export interface State {
}

export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	loadData = async () => {
		await this.context.authorization.loadUserProfile();
	}
}