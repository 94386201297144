/**
 * Stránka seznamu stáží
 */
import React from "react";
import * as state from "../../../lib/state";

import NewsPage from "../news/NewsPage";

function NewsForMembersPage() {
	return <NewsPage forMembers />;
}

export default state.bindContainers(
	NewsForMembersPage,
	c => c.newsForMembers
);