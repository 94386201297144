
import React from "react";
import htmlParse, { HTMLReactParserOptions, domToReact } from "html-react-parser";
import * as state from "../../lib/state";

import Link from "./Link";

export interface HtmlContentProps {
	content?: React.ReactNode;
	isErrorMessage?: boolean;
	htmlKey?: string;
	className?: string;
	isDetail?: boolean;
	item?: any;
	customReload?: () => void;
}

function handleOnClick(value: any) {
	document.querySelector("#" + value)?.scrollIntoView();
}

const parseOptions: HTMLReactParserOptions = {
	replace: (node: any) => {
		if (node.name === "a" && node.attribs != undefined && node.attribs.download != undefined) {
			const { class: className, style, href, ...restAttribs } = node.attribs;
			return <a download className={className ?? ""} href={href}>{domToReact(node.children, parseOptions)}</a>;
		}
		else if (node.name === "a") {
			const { class: className, style, href, ...restAttribs } = node.attribs;
			return <Link className={className ?? ""} href={href}>{domToReact(node.children, parseOptions)}</Link>;
		}
		const attr = !node.attribs ? false : node.attribs["data-id"];
		if (attr) {
			return <span className="span-as-hyperlink" onClick={() => handleOnClick(attr)}>{domToReact(node.children, parseOptions)}</span>;
		}
	}
};

export default function HtmlContent(props: HtmlContentProps) {
	const { contents } = state.useStateContext();
	const context = state.useStateContext();
	const { authorization } = state.useStateContext();
	const [, updateState] = React.useState();
	const forceUpdate = React.useCallback(() => updateState({} as any), []);
	const profile = authorization.getUserProfile();

	async function handleContextMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) {
		if (!props.htmlKey || profile?.role.id != 1) {
			return;
		}
		e.preventDefault();

		await context.standardDialogs.openYesNoDialog("Chcete editovat obsah?") === "yes"
			? !props.isDetail ? await context.standardDialogs.openResourceDialog(key, forceUpdate) : await context.dynamicDetail.openDialog(props.item!, forceUpdate, props.customReload)
			: undefined;
	}

	const content = props.htmlKey != undefined ? contents.getContent(props.htmlKey) : props.content;
	return (
		<>
			<div
				onContextMenu={(e) => handleContextMenu(e, props.htmlKey!)}
				data-key={props.htmlKey}
				className={(props.className == undefined ? "" : props.className) + (props.htmlKey == undefined && props.isDetail == undefined ? "" : (props.htmlKey != undefined || props.isDetail != undefined) ? (profile?.role.id == 1 ? " custom-content " : "") : "") + (props.isErrorMessage ? " html-content-error-message" : "")}
			>
				{content &&
					<>
						{htmlParse(content as any, parseOptions)}
					</>
				}
			</div>
		</>
	);
}