import * as api from "../../../lib/api";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiFile from "../../system/file/api-file";
import * as context from "../../../context";
import { decreaseByPercentage } from "../../../lib/formatting";

/**
 * Položka v seznamu zboží (hlavní seznam, související)
 */
export interface ShopItemSimple extends api.EntityReadBase {
	id: api.Id;
	name: string;
	image_id: api.Id;
	price: number;
}

export interface Review {
	id: api.Id;
	name: string;
	link: string;
}

/**
 * Položka zboží (detail)
 */
export interface ShopItem extends api.EntityReadBase {
	id: api.Id;
	name: string;
	image_id: api.Id;
	price: number;
	categories: apiCodeList.CodeList[];
	review?: Review;
	author: string;
	// HTML
	info: string;
	description: {
		title: string;
		// HTML
		text: string;
	};
	related_items: ShopItemSimple[];
}

/**
 * Košík
 */
export interface Cart extends api.EntityReadBase {
	items: CartItem[];
	sum: number;
	transport_price: number;
	total_price: number;
}

/**
 * Položka v košíku
 */
export interface CartItem {
	shop_item: ShopItemSimple;
	count: number;
}

/**
 * Parametr pro přidání zboží do košíku
 */
export interface InsertCartItem {
	cart_id: api.Id;
	item_id: api.Id;
	count: number;
}

/**
 * Parametr pro aktualizaci množství zboží v košíku
 */
export interface UpdateCartItem {
	cart_id: api.Id;
	item_id: api.Id;
	count: number;
}

/**
 * Parametr pro aktualizaci množství zboží v košíku
 */
export interface RemoveCartItem {
	cart_id: api.Id;
	item_id: api.Id;
}

/**
 * Objednávka
 */
export interface Order {
	first_name: string;
	last_name: string;
	company: string;
	country: string;
	street: string;
	city: string;
	postcode: string;
	phone: string;
	email: string;
	cart_id: api.Id;
	cart_ts: string;
}

const mockItems: ShopItem[] = [
	{
		id: "00000000-0000-0000-0000-000000000001",
		name: "Magistraliter receptura ve stomatologii – druhé, korigované vydání",
		image_id: "https://old.dent.cz/wp-content/uploads/2019/11/zbynek-280x300.jpg",
		info: "Vydala Česká stomatologická komora (Malá edice) ve spolupráci s Havlíček BrainTeam, 2019, 1.vydání, ISBN 978-80-87109-42-7, 335 stran",
		author: "",
		categories: [{ id: "0", name: "Knihy", code: "books" }],
		review: { id: "0", name: "Recenze - MUDr. Robert Houba, Ph.D.", link: "/images/magistralite-sklenar_scigel-2012-recenze-lks_02_2013-1.pdf" },
		price: 445,
		description: {
			title: "Zbyněk Sklenář, Vladimír Ščigel, 335 stran",
			text: "Magistraliter receptura ve stomatologii – druhé, korigované vydání"
		},
		related_items: [],
		deleted: false,
	},
	{
		id: "00000000-0000-0000-0000-000000000002",
		name: "Ortodontický atlas",
		image_id: "https://old.dent.cz/wp-content/uploads/2020/01/uk6mce_280x206_a84248bdee36f88d.jpg",
		info: "Vydala Česká stomatologická komora (Malá edice) ve spolupráci s Havlíček BrainTeam, 2019, 1.vydání, ISBN 978-80-87109-42-7, 335 stran",
		author: "",
		categories: [{ id: "0", name: "Knihy", code: "books" }],
		review: { id: "0", name: "Recenze - MUDr. Jiří Petr", link: "/images/atlas-orto-kotova-recenze_lks-1.pdf" },
		price: 500,
		description: {
			title: "Magdalena Koťová, 143 stran",
			text: "Autor předkládá praktickým zubním lékařům „uživatelsky přátelskou“ knížku, která by jim pomohla v orientaci na někdy nepřehledném a složitém poli temporomandibulárních poruch (TMP), a kvůli praktické využitelnosti používá jen základní informace. Cílem je, aby čtenář získal povědomí o stomatognátním systému, zvládl základní diagnostické schopnosti při výskytu TMP a dokázal posoudit, jaké TMP lze léčit v zubní praxi a kdy je vhodné doporučit pacienta na specializované pracoviště."
		},
		related_items: [],
		deleted: false,
	},
	{
		id: "00000000-0000-0000-0000-000000000003",
		name: "Rukověť zubního lékaře, temporo­mandi­bulární poruchy v praxi",
		image_id: "https://old.dent.cz/wp-content/uploads/2019/11/dncrg2_280x415_b749e02d63f84379-280x300.jpg",
		info: "Vydala Česká stomatologická komora (Malá edice) ve spolupráci s Havlíček BrainTeam, 2019, 1.vydání, ISBN 978-80-87109-42-7, 335 stran",
		author: "",
		categories: [{ id: "0", name: "Knihy", code: "books" }],
		review: { id: "0", name: "Recenze - doc. MUDr. Milan Kotráň", link: "/images/rukovet-tmp-zemen-recenze_lks-1.pdf" },
		price: 273,
		description: {
			title: "Jiří Zemen, 143 stran",
			text: "Autor předkládá praktickým zubním lékařům „uživatelsky přátelskou“ knížku, která by jim pomohla v orientaci na někdy nepřehledném a složitém poli temporomandibulárních poruch (TMP), a kvůli praktické využitelnosti používá jen základní informace. Cílem je, aby čtenář získal povědomí o stomatognátním systému, zvládl základní diagnostické schopnosti při výskytu TMP a dokázal posoudit, jaké TMP lze léčit v zubní praxi a kdy je vhodné doporučit pacienta na specializované pracoviště"
		},
		related_items: [],
		deleted: false,
	},
	{
		id: "00000000-0000-0000-0000-000000000004",
		name: "Sedace dospělých a dětí ve stomatologii",
		image_id: "https://old.dent.cz/wp-content/uploads/2020/08/sedace-hess-300x300.jpg",
		info: "Vydala Česká stomatologická komora v nakladatelství Havlíček BrainTeam, 1. vydání, Praha, 2020, ISBN 978-80 - 87109 - 69 - 4, 111 stran",
		author: "",
		categories: [{ id: "0", name: "Knihy", code: "books" }],
		review: { id: "0", name: "Recenze - MUDr. Vladimír Ščigel, Ph.D., MBA", link: "/images/recenze-sedace-hess-2020.pdf" },
		price: 273,
		description: {
			title: "",
			text: "Vydání knihy doc. MUDr. Ladislava Hesse, DrSc., aktuálně reaguje na zvyšující se poptávku po nabídce sedativních technik ve stomatologické praxi."
		},
		related_items: [],
		deleted: false,
	},
	// {
	// 	id: "00000000-0000-0000-0000-000000000005",
	// 	name: "Zubní kaz a jeho prevence v časném dětském věku",
	// 	image_id: "https://old.dent.cz/wp-content/uploads/2019/11/zubnikaz-280x300.jpg",
	// 	info: "Vydala Česká stomatologická komora v nakladatelství Havlíček BrainTeam, 1. vydání, Praha, 2020, ISBN 978-80 - 87109 - 69 - 4, 111 stran",
	// 	author: "",
	// 	categories: [{ id: "0", name: "Knihy", code: "books" }],
	// 	price: 200,
	// 	description: {
	// 		title: "Vlasta Merglová, Romana Ivančaková, 111 stran",
	// 		text: "Monografie je určena široké stomatologické veřejnosti a představuje přehledně zpracovaný manuál praktických možností a metod prevence zubního kazu u malých dětí. Autoři shrnují jak tradiční ověřené metody, tak nejnovější poznatky z dentální kariologie a prevence."
	// 	},
	// 	related_items: [],
	// 	deleted: false,
	// }
];

const mock: api.ListResponse<ShopItem> = {
	message: "",
	data: mockItems.map(i => ({ ...i, related_items: mockItems.filter(j => j.id !== i.id) })),
	pagination: {
		page: 1,
		object_count: 4,
		per_page: 30
	}
};

let mockCart: Cart = createCart();

function createCart(): Cart {
	return {
		id: api.emptyGuid,
		items: [],
		sum: 0,
		transport_price: 0,
		total_price: 0,
		deleted: false
	};
}

export class Api {
	itemsSimple: api.EntityApi<ShopItemSimple, ShopItemSimple>;
	items: api.EntityApi<ShopItem, ShopItem>;

	constructor(private context: context.StateContext) {
		this.items = new api.EntityApi<ShopItem, ShopItem>({
			api: context.api,
			path: "/web/shop/items"
		});

		this.itemsSimple = this.items as any as api.EntityApi<ShopItemSimple, ShopItemSimple>;

		this.items.loadList = (query: api.Query<ShopItem>) => {
			return new Promise((resolve) => resolve(mock));
		};

		this.items.load = (id: api.Id) => {
			return new Promise((resolve) => resolve(mock.data.find(i => i.id === id)!));
		};
	}

	/**
	 * Přepočítání košíku
	 */
	private updateSums = () => {
		mockCart.sum = mockCart.items.reduce((sum, i) => sum + i.count * i.shop_item.price, 0);
		mockCart.transport_price = mockCart.items.length > 0 ? 135 : 0;
		mockCart.total_price = mockCart.sum + mockCart.transport_price;
	}

	private createTs() {
		return new Date().toISOString();
	}

	createCart = (): Promise<api.OperationResponse> => {
		return new Promise((resolve) => resolve({
			id: api.emptyGuid,
			messages: []
		}));
	}

	/**
	 * Načte nebo vytvoří košík. Vytvoří nový košík, pokud cartId není definováno 
	 * nebo pokud košík s daným cartId neexistuje
	 */
	loadOrCreateCart = (cartId: api.Id | undefined): Promise<Cart> => {
		return new Promise((resolve) => resolve(mockCart));
	}

	/**
	 * Přidá položku v určeném množství do košíku
	 */
	addToCart = (data: InsertCartItem): Promise<api.OperationResponse> => {
		if (mockCart.items.find(i => i.shop_item.id === data.item_id)) {
			mockCart.items = mockCart.items.map(i => ({
				...i,
				count: i.count + data.count
			}));
		} else {
			const item = mockItems.find(i => i.id === data.item_id);
			if (item) {
				mockCart.items.push({
					shop_item: item,
					count: data.count
				});
			}
		}
		this.updateSums();
		return new Promise((resolve) => resolve({
			id: "-1",
			messages: []
		}));
	}

	/**
	 * Aktualizuje položku v košíku
	 */
	updateItem = (data: UpdateCartItem): Promise<api.OperationResponse> => {
		mockCart.items = mockCart.items.map(i => ({
			...i,
			count: i.shop_item.id === data.item_id ? data.count : i.count
		}));
		mockCart.items = mockCart.items.filter(i => i.count > 0);
		this.updateSums();
		return new Promise((resolve) => resolve({
			id: "-1",
			messages: []
		}));
	}

	/**
	 * Provede objednávku
	 */
	order = (order: Order): Promise<api.OperationResponse> => {
		mockCart = createCart();
		return new Promise((resolve) => resolve({
			id: "-1",
			messages: []
		}));
	}
}