import * as React from "react";

export type CardProps = React.HTMLProps<HTMLDivElement>;

export default function Card(props: CardProps) {
	const { className, ...restProps } = props;
	return (
		<div className={"bg-lightgrey p-4 " + props.className} {...restProps}>
			{props.children}
		</div>
	);
}