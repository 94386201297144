/**
 * Stránka seznamu zubních lékařů
 */
import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import JoindedList from "../../../components/controls/JoinedList";
import Paginator from "../../system/lists/ListPaginator";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import { format } from "numeral";

function DentistsPage() {
	const { contents } = state.useStateContext();
	const title = contents.getRes("seznam-poskytovatelu-zubni-pece-nadpis");
	const { dentists: model } = state.useStateContext();
	const filterForm = model.workplaces.getAdvancedFilterForm();

	return (
		<Page title={title} description="">
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="seznam-poskytovatelu-zubni-pece-nadpis" />
				{model.workplaces.justLoadingData() &&
					<Spinner small className="ml-1" />
				}
			</h1>

			<HtmlContent className="mb-3" htmlKey="seznam-poskytovatelu-zubni-pece-obsah" />

			<div className="form-filter u-mb-lg">
				<div id="records" className="form-filter__inner row">
					<div className="col-lg-12">
						<div className="row mb-4">
							<div className="col-lg-6">
								<span className="inp-fix">
									<input
										type="text"
										name="default"
										id="inp-filter-search-1"
										placeholder="Lékař / poskytovatel"
										className="inp-text inp-text--filter mb-3"
										value={model.workplaces.getFilterFulltextPhrase()}
										onChange={e => model.workplaces.setFilterFulltextPhrase(e.target.value)}
									/>
								</span>
							</div>
							<div className="col-lg-6">
								<span className="inp-fix">
									<input
										type="text"
										name="default"
										id="inp-filter-search-2"
										placeholder={filterForm.getFieldOptions("place").placeHolderText}
										className="inp-text inp-text--filter mb-3"
										value={filterForm.getField("place").value}
										onChange={e => filterForm.setField("place", e.target.value)}
									/>
								</span>
							</div>
						</div>
						<div className="mb-3">
							<span className="span-as-hyperlink">Zdravotní pojišťovny</span>
							<div className="row">
								{model.getInsuranceCompanies().map((i, index) =>
									<div key={index} className="col-md-6 col-lg-4">
										<label className="inp-label inp-label--checkbox inp-label--filter">
											<input
												type="checkbox"
												name="checkbox"
												value="0"
												className="inp-checkbox"
												checked={model.isInsuranceCompanyChecked(i)}
												onChange={e => model.toggleInsuranceCompany(i, e.target.checked)}
											/>
											<span>
												{i.name}
											</span>
										</label>
									</div>
								)}
							</div>
							{/* <div className="row mt-3">
								<div className="col-md-6 col-lg-4">
									<label className="inp-label inp-label--checkbox inp-label--filter">
										<input
											type="checkbox"
											name="checkbox"
											value="0"
											className="inp-checkbox"
											checked={filterForm.getField("accepting_new_patients").value}
											onChange={e => filterForm.setField("accepting_new_patients", e.target.checked)}
										/>
										<span>
											Přijímáme nové pacienty
										</span>
									</label>
								</div>
							</div> */}
						</div>
					</div>
					<div className="col-lg-12 d-flex justify-content-end align-items-end">
						<button type="submit" className="btn btn--filter mr-2 mb-3" onClick={model.workplaces.loadWithResetPage}>
							<span className="btn__text">
								Vyhledat
							</span>
						</button>

						<button type="submit" className="btn btn--filter mb-3" onClick={model.workplaces.clearFilter}>
							<span className="btn__text">
								Reset
							</span>
						</button>
					</div>
				</div>
			</div>

			<div className="u-mb-lg">
				<div className="cross-cross-dentists-list">
					<div className="row">
						{model.workplaces.getData().map((i, index) =>
							<div key={index} className="cross-dentists-list__item col-12 col-md-6 u-mb-md">
								<div>
									<Link to={"/zubar/" + i.id}>
										{i.name}
									</Link>
								</div>
								<p>
									{i.address?.street &&
										<>{i.address?.street} < br /></>
									}
									{i.address?.postcode} {i.address?.city}<br />
									<strong>
										<JoindedList
											items={[
												i.contact?.phone1,
												i.contact?.phone2
											]}
											beforeElement={
												<>Tel.:&nbsp;</>
											}
											itemTemplate={(item) =>
												<a href={"tel:" + item}>
													{item}
												</a>
											}
											delimiter=", "
											afterElement={<br />}
										/>
									</strong>
									<strong>
										<JoindedList
											items={[
												i.contact?.email1,
												i.contact?.email2
											]}
											beforeElement={
												<>E-mail:&nbsp;</>
											}
											itemTemplate={(item) =>
												<a href={"mailto:" + item}>
													{item}
												</a>
											}
											delimiter=", "
											afterElement={<br />}
										/>
									</strong>
								</p>
								<p>
									<Link to={"/zubar/" + i.id} className="btn btn--light btn--dentists">
										<span className="btn__text">
											Zobrazit více
										</span>
									</Link>
								</p>
							</div>
						)}
					</div>
				</div>
				<Paginator list={model.workplaces} />
			</div>
		</Page >
	);
}

export default state.bindContainers(
	DentistsPage,
	c => c.dentists
);