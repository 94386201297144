/**
 * Seznam dokumentů OSK
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiDocuments from "../../shared/documents/api-documents";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam hvězd.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiDocuments.DmsFile>({
		context: context,
		title: "Seznam dokumentů",
		quickFilterPlaceholder: "Vyhledejte dokument...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiRegionalChambers.documents,
		sortingFields: [],
		grid: {
			minWidth: "800px",
			columns: [
				{
					field: "name",
					type: "string",
					title: "Název"
				},
				{
					field: "dms_file_type.name" as any,
					type: "string",
					title: "Typ",
					width: "80px"
				},
				{
					field: "version",
					type: "number",
					title: "Verze",
					width: "100px"
				},
				{
					field: "system_information.creation.user.full_name" as any,
					type: "string",
					title: "Vložil",
					width: "250px"
				},
				{
					field: "system_information.creation.date" as any,
					type: "date",
					title: "Vloženo",
					width: "120px"
				}
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiDocuments.DmsFile>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.userLoggedIn())
			await this.list.loadWithResetPage();
	}
}