/**
 * Hospodářství
 */
import React from "react";
import * as state from "../../../lib/state";
import CardExpandable from "../../../components/controls/CardExpandable";
import useExclusiveSwitch from "../../../hooks/exclusive-switch";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export function AgendaEconomyPage() {
	const model = state.useStateContext().economy;
	const { contents } = state.useStateContext();
	const cardSwitch = useExclusiveSwitch();

	return (
		<Page title={contents.getRes("hospodareni-csk-nadpis")} description={contents.getRes("hospodareni-csk-nadpis")} authorized>
			<h1>{contents.getRes("hospodareni-csk-nadpis")}</h1>

			{model.getData().map((s, i) =>
				<div className="col-12">
					<CardExpandable
						open={cardSwitch.current === i.toString()}
						onToggle={() => cardSwitch.toggle(i.toString())}
						cardTitle={<div className="mb-0 secondary-title">{s.title}</div>}
						className="mb-gutter"
					>
						<HtmlContent content={s.text} />
					</CardExpandable>
				</div>
			)}
		</Page>
	);
}

export default state.bindContainers(
	AgendaEconomyPage,
	c => c.economy
);