import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";

import useExclusiveSwitch from "../../hooks/exclusive-switch";
import * as state from "../../lib/state";
import SlideDown from "./SlideDown";

interface MenuBlock {
	title: string;
	items: MenuItem[];
}
interface MenuItem {
	title: string;
	link: string;
}

export default function MenuFooter() {
	const { contents } = state.useStateContext();
	const exclusiveSwitch = useExclusiveSwitch();
	const menuBlocks: MenuBlock[] = [
		{
			title: contents.getRes("o-nas-nadpis"),
			items: [
				{ title: contents.getRes("stavovske-predpisy-nadpis"), link: "/o-nas/stavovske-predpisy" },
				{ title: contents.getRes("organy-csk-nadpis"), link: "/o-nas/organy-csk" },
				{ title: contents.getRes("stante-se-clenem-nadpis"), link: "/o-nas/stante-se-clenem" },
				{ title: contents.getRes("uredni-deska-nadpis"), link: "/o-nas/uredni-deska" },
				{ title: contents.getRes("jednani-snemu-nadpis"), link: "/o-nas/snem" },
				{ title: contents.getRes("rocenky-nadpis"), link: "/o-nas/rocenky" },
				{ title: contents.getRes("gdpr-nadpis"), link: "/o-nas/gdpr" },
				{ title: contents.getRes("graficke-standardy-nadpis"), link: "/o-nas/graficke-standardy" },
				{ title: contents.getRes("zubni-prukaz-ditete"), link: "/aktuality/ccdf9719-0991-469b-988a-82940009e485" },
			]
		},
		{
			title: contents.getRes("zubni-lekari-nadpis"),
			items: [
				{ title: contents.getRes("seznam-poskytovatelu-zubni-pece-nadpis-menu"), link: "/zubni-lekari" },
				{ title: contents.getRes("abecedni-seznam-clenu-nadpis-menu"), link: "/zubni-lekari/abecedne" },
				{ title: contents.getRes("seznam-hostujicich-osob-nadpis"), link: "/zubni-lekari/seznam-hostujicich-osob" },
			]
		},
		{
			title: contents.getRes("vzdelavani-nadpis"),
			items: [
				{ title: contents.getRes("kalendar-akci-nadpis"), link: "/vzdelavani/akce" },
				{ title: contents.getRes("staze-nadpis"), link: "/vzdelavani/staze" },
				{ title: contents.getRes("akreditovana-pracoviste-nadpis"), link: "/vzdelavani/pracoviste" },
				{ title: contents.getRes("kongres-pdd-nadpis"), link: "/vzdelavani/kongres-pdd" },
				{ title: contents.getRes("knihy-nadpis"), link: "/vzdelavani/knihy" },
				{ title: contents.getRes("casopisy-nadpis"), link: "/vzdelavani/casopis" },
				{ title: contents.getRes("registrace-akce-nadpis"), link: "/vzdelavani/registrace-akce" },
				// { title: contents.getRes("registrace-e-learningova-akce-nadpis"), link: "/vzdelavani/registrace-e-learningova-akce" }
			]
		}
	];
	return (
		<div className="menu-footer js-footer-menu">
			<div className="menu-footer__inner">
				{menuBlocks.map((i, index) =>
					<div key={index} className="menu-footer__block">
						<div className="menu-footer__title js-footer-menu__title secondary-title" onClick={() => exclusiveSwitch.toggle(i.title)}>
							{i.title}
						</div>
						<nav role="navigation" id="menu-footer" className="menu-footer__menu">
							<SlideDown
								as="ul"
								className="menu-footer__list"
								open={exclusiveSwitch.current === i.title}
							>
								{i.items.filter(x => x.title != "").map(i =>
									<li key={i.title} className="menu-footer__item">
										<Link to={i.link} className="menu-footer__link">
											{i.title}
										</Link>
									</li>
								)}
							</SlideDown>
						</nav>
					</div>
				)}
			</div>
		</div>
	);
}