import { library } from "@fortawesome/fontawesome-svg-core";

import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons/faAngleDoubleDown";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons/faAngleDoubleUp";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faSortAmountUp } from "@fortawesome/free-solid-svg-icons/faSortAmountUp";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons/faFileUpload";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faCrop } from "@fortawesome/free-solid-svg-icons/faCrop";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons/faSyncAlt";
import { faFillDrip } from "@fortawesome/free-solid-svg-icons/faFillDrip";
import { faEyeDropper } from "@fortawesome/free-solid-svg-icons/faEyeDropper";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons/faLockOpen";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faFolder } from "@fortawesome/free-solid-svg-icons/faFolder";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons/faFileExcel";
import { faFileWord } from "@fortawesome/free-solid-svg-icons/faFileWord";
import { faFilePowerpoint } from "@fortawesome/free-solid-svg-icons/faFilePowerpoint";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faFileAudio } from "@fortawesome/free-solid-svg-icons/faFileAudio";
import { faFileVideo } from "@fortawesome/free-solid-svg-icons/faFileVideo";
import { faFileImage } from "@fortawesome/free-solid-svg-icons/faFileImage";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons/faFileCsv";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons/faFileArchive";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";

export type Icon = keyof typeof icon;

export const icon = {
	// Home: "home",
	// Search: "search",
	// ExpandPanel: "angle-double-down",
	// CollapsePanel: "angle-double-up",
	// ModuleHelp: "info-circle",

	// ImageUpload: "file-upload",
	// Image: "image",
	// Confirm: "check",
	// Cancel: "times",
	// CropImageIcon: "crop",
	// FillImageBackgroundIcon: "fill-drip",
	// PickImageBackgroundIcon: "eye-dropper",
	Document: "file",
	// User: "user",
	// Detail: "edit",
	// Logout: "sign-out-alt",
	// LockLocked: "lock",
	// LockOpen: "lock-open",

	Add: "plus-circle",
	Remove: "minus-circle",
	ImageDelete: "trash-alt",
	SortAsc: "sort-amount-up",
	SortDesc: "sort-amount-down",
	FirstRecord: "angle-double-left",
	LastRecord: "angle-double-right",
	PrevRecord: "angle-left",
	NextRecord: "angle-right",
	Refresh: "sync-alt",
	CaretDown: "caret-down",
	Folder: "folder",
	FileExcel: "file-excel",
	FileWord: "file-word",
	FilePdf: "file-pdf",
	FilePowerpoint: "file-powerpoint",
	FileCsv: "file-csv",
	FileArchive: "file-archive",
	FileImage: "file-image",
	FileAudio: "file-audio",
	FileVideo: "file-video",
	FileTxt: "file-alt",
};

library.add(faHome);
library.add(faSearch);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faAngleDoubleDown);
library.add(faAngleDoubleUp);
library.add(faAngleDoubleLeft);
library.add(faAngleDoubleRight);
library.add(faInfoCircle);
library.add(faSortAmountUp);
library.add(faSortAmountDown);
library.add(faFileUpload);
library.add(faTrashAlt);
library.add(faImage);
library.add(faFile);
library.add(faCrop);
library.add(faCheck);
library.add(faTimes);
library.add(faUser);
library.add(faEdit);
library.add(faSignOutAlt);
library.add(faSyncAlt);
library.add(faFillDrip);
library.add(faEyeDropper);
library.add(faLock);
library.add(faLockOpen);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faCaretDown);
library.add(faFolder);
library.add(faFileExcel);
library.add(faFileWord);
library.add(faFilePdf);
library.add(faFileImage);
library.add(faFileAudio);
library.add(faFileVideo);
library.add(faFileCsv);
library.add(faFileArchive);
library.add(faFilePowerpoint);
library.add(faFileAlt);