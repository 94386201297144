/**
 *  Informace o volbě prezidenta
 */
import React from "react";
import * as state from "../../../lib/state";
import HtmlContent from "../../../components/controls/HtmlContent";
import Page from "../../../components/templates/Page";

export default function PresidentialElectionInformationPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("informace-nadpis")} description={contents.getRes("informace-nadpis")} authorized>
			<HtmlContent htmlKey="volby-prezidenta-obsah" />
		</Page>
	);
}