/**
 *  Formulář žádost o vyškrtnutí ze seznamu členů oblastní komory
 */

import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import WebRequest from "../../../components/templates/WebRequest";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";
import FormsSelect from "../../../components/controls/Forms/FormSelect";

interface WebRequestMembersLeavingPageProps {
	id?: string;
}

function WebRequestMembersLeavingPage(props: WebRequestMembersLeavingPageProps) {
	const title = "VYSTOUPENÍ Z ČESKÉ STOMATOLOGICKÉ KOMORY (ukončení členství v ČSK)";
	const { webRequestMembersLeaving: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p className="mb-5">
				<strong>Oznamuji, že vystupuji z České stomatologické komory:</strong>
			</p>
			<div className="row">
				<div className="col-md-5">
					<FormsInput form={form} field="date" inputProps={{ type: "date", min: formatting.formatDate(new Date(), { format: "iso" }) }} />
				</div>
			</div>

			<div className="row">
				<div className="col-md-5">
					<FormsSelect form={form} field="reason_for_termination" options={model.getReasons().map(i => ({ title: i.name, value: i.id }) as any)} />
				</div>
			</div>

			<strong>Poučení:</strong><br />
			<ul className="mb-5">
				<li><small>Výstup z ČSK učiněný po rozhodném dni nezprošťuje člena povinnosti včas a řádně uhradit členské příspěvky na příslušný kalendářní rok.</small></li>
				<li><small>Bez členství v ČSK není zubní lékař oprávněn vykonávat povolání zubního lékaře na území ČR.</small></li>
				<li><small>Výstupem z ČSK dochází ke ztrátě nároku na preskripci.</small></li>
			</ul>

			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestMembersLeavingPage,
	c => c.webRequestMembersLeaving
);