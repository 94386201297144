import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as apiEducationalActions from "../../shared/educational-actions/api-educational-actions";


interface EducationalActionsTimeLineItemProps {
	action: apiEducationalActions.WebEducationalAction;
	stages?: boolean;
}

export default function EducationalActionsTimeLineItemOriginal(props: EducationalActionsTimeLineItemProps) {
	return (
		<div className="box-new">
			<Link to={(props.stages ? "/vzdelavani/staz/" : "/vzdelavani/akce/") + props.action.id} className="box-new__inner educational-actions-page__time-line-content">
				<div className="mb-2 color-csk-primary font-weight-bold secondary-title">
					{props.action.name}
				</div>
				<div className="d-flex">
					<div style={{ minWidth: 110 }} className="text-dark mr-2">Místo konání:</div>
					<div className="font-weight-bold text-dark">{props.action.place_of_activity}</div>
				</div>
				<div className="d-flex">
					<div style={{ minWidth: 110 }} className="text-dark mr-2">Lektoři:</div>
					<div className="font-weight-bold color-csk-primary">{props.action.lecturers}</div>
				</div>
				<div className="d-flex">
					<div style={{ minWidth: 110 }} className="text-dark mr-2">Pořadatel:</div>
					<div className="font-weight-bold text-dark">{props.action.subject.name}</div>
				</div>
				{props.action.capacity_exhausted &&
					<div className="d-flex">
						<div className="badge badge-secondary mt-1 p-2">
							OBSAZENO
						</div>
					</div>
				}
			</Link>
		</div>
	);
}