/**
 * Abecední seznam zubařů
 */
import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Alphabet from "../../../components/controls/Alphabet";
import Spinner from "../../../components/controls/Spinner";
import ListPaginator from "../../system/lists/ListPaginator";
import HtmlContent from "../../../components/controls/HtmlContent";

function DentistsAlphabeticalPage() {
	const { contents } = state.useStateContext();

	const title = contents.getRes("abecedni-seznam-clenu-nadpis");
	const { dentistsAlphabetical: dentists } = state.useStateContext();

	return (
		<Page title={title} description="">
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="abecedni-seznam-clenu-nadpis" />
				{dentists.list.justLoadingData() &&
					<Spinner small className="ml-1" />
				}
			</h1>

			<HtmlContent className="mb-3" htmlKey="abecedni-seznam-clenu-obsah" />

			<div className="form-filter u-mb-md">
				<div id="records" className="form-filter__inner row">
					<div className="col-12 col-sm-6 col-md-4 col-lg-5 u-mb-md">
						<label htmlFor="filter-search" className="inp-label u-vhide">
							Zadejte jméno člena
						</label>
						<span className="inp-fix">
							<input
								type="text"
								id="inp-filter-search"
								placeholder={dentists.list.options.quickFilterPlaceholder}
								className="inp-text inp-text--filter"
								value={dentists.list.getFilterFulltextPhrase()}
								onChange={(e) => dentists.list.setFilterFulltextPhrase(e.target.value)}
							/>
						</span>
					</div>
					<div className="col-12 col-sm-6 col-md-auto u-mb-md ml-md-auto">
						<button type="submit" className="btn btn--filter" onClick={dentists.list.loadWithResetPage}>
							<span className="btn__text">
								Vyhledat
							</span>
						</button>
					</div>
					<div className="col-12 col-sm-6 col-md-auto u-mb-md">
						<button type="submit" className="btn btn--filter" onClick={dentists.list.clearFilter}>
							<span className="btn__text">
								Reset
							</span>
						</button>
					</div>
				</div>
			</div>

			<Alphabet
				current={dentists.list.getAdvancedFilterForm().getField("letter").value}
				onLetterClick={letter => dentists.list.getAdvancedFilterForm().setField("letter", letter)}
			/>

			<div className="u-mb-lg">
				<div className="cross-dentists-alphabetical">
					<div className="row">
						{dentists.list.getData().map(i =>
							<div className="cross-dentists-alphabetical__item col-12 col-md-6 col-lg-4 u-mb-md">
								<p>
									<Link to={"/clen/" + i.id}>
										{i.full_name}
									</Link>
								</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<ListPaginator list={dentists.list} />
		</Page >
	);
}

export default state.bindContainers(
	DentistsAlphabeticalPage,
	context => context.dentistsAlphabetical
);