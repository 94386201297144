import * as React from "react";

export default function ContacSmall() {
	return (
		<div className="box-contact-small">
			<ul>
				<li>
					<span>
						Tel.:&nbsp;
					</span>
					<a href="tel:+420234709611">
						+420 234 709 611
					</a>
				</li>
				<li>
					<span>
						E-mail:&nbsp;
					</span>
					<a href="mailto:info@dent.cz">
						info@dent.cz
					</a>
				</li>
			</ul>
		</div>
	);
}