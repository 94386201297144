/**
 * Stránka seznamu stáží
 */
import * as React from "react";
import Page from "../../../components/templates/Page";

export default function StaticTargetPage() {
	return (
		<Page title="Statická stránka CMS" description="">
			<h1>
				Budoucí stránka
			</h1>

			<p>
				Tato stránka teprve bude pravděpodobně vytvořena.
			</p>
		</Page >
	);
}