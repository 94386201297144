/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import List from "../../system/lists/List";

function MyExamsPage() {
	const { myExams } = state.useStateContext();
	return (
		<Page
			title={myExams.list.getTitle()}
			description=""
			authorized
		>
			<h1>
				{myExams.list.getTitle()}
			</h1>
			<List list={myExams.list} simpleTable />
		</Page>
	);
}

export default state.bindContainers(
	MyExamsPage,
	context => context.myExams
);