/**
 * Model žádosti o příspěvek z účtu klinik a vzdělávání
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as forms from "../../../lib/forms";
import * as formating from "../../../lib/formatting";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";



export interface FormFields {
	// Pracoviště
	workplace_name: string;
	township: string;
	street_and_number: string;
	psc: string;

	// Základní údaje o akci
	action_name: string;
	action_date_from: string;
	broadcaster: string;
	confirmation?: apiFile.File;
	information_confirmation: string;
	action_place: string;
	action_date_to: string;
	stay_time: string;

	// Náklady
	costs: string;
	congress_fee: string;

	travel_expenses: string;
	accommodations: string;

	// Požadováno
	requested_contribution: string;
	deposit: boolean;
	contribution_use: string;

	// Individuální žádost
	lecture: boolean;
	congress: boolean;
	course: boolean;

	internship: boolean;
	poster: boolean;
	symposium: boolean;

	lecture_name: string;
	application_file?: apiFile.File;


	overview_of_cooperation: string;
	proposal_of_cooperation: string;

	// K žádosti přiložte tyto přílohy
	statement_attachment?: apiFile.File;
	participation_confirmation_attachment?: apiFile.File;
	information_attachment?: apiFile.File;
	costs_attachment?: apiFile.File;
	abstract_attachment?: apiFile.File;
	poster_attachment?: apiFile.File;

	submission_date: string;

	name: string;
	email: string;
	affidavit: boolean;
	clinic: string;

	is_professional_society: boolean;
	professional_society: string;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public commonModel: webRequest.Model;

	constructor(private context: context.StateContext) {
		const current_date = formating.formatDate(new Date(), { format: "iso" });

		this.commonModel = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				workplace_name: {
					title: "Název pracoviště",
					//readOnly: true,
					defaultValue: ""
				},
				township: {
					title: "Obec",
					//readOnly: true,
					defaultValue: ""
				},
				professional_society: {
					title: "Odborná společnost",
					defaultValue: ""
				},
				is_professional_society: {
					title: "",
					defaultValue: false
				},
				street_and_number: {
					title: "Ulice a číslo",
					//readOnly: true,
					defaultValue: ""
				},
				psc: {
					title: "PSČ",
					//readOnly: true,
					defaultValue: "",
					validate: value => value.trim().length == 5 || value.trim().length == 0 ? "" : "PSČ musí obsahovat 5 znaků"
				},

				// Základní údaje o akci
				action_name: {
					title: "Název akce",
					defaultValue: "",
					placeHolderText: "Akce 21",
					required: true,
					validate: value => value.length == 0 ? "Název je povinné pole" : "",
				},
				action_date_from: {
					title: "Termín od",
					defaultValue: current_date,
					required: true,
					validate: value => value.length == 0 ? "Termín je povinné pole" : "",
				},
				broadcaster: {
					title: "Vysilatel",
					defaultValue: "",
					placeHolderText: "Praha 2"
				},

				action_place: {
					title: "Místo akce",
					defaultValue: "",
					placeHolderText: "Krakov",
					required: true,
					validate: value => value.length == 0 ? "Místo akce je povinné pole" : "",
				},
				action_date_to: {
					title: "Termín do",
					defaultValue: current_date,
					required: true,
					validate: value => value.length == 0 ? "Termín je povinné pole" : "",
				},
				stay_time: {
					title: "Délka pobytu ve dnech",
					defaultValue: "",
					placeHolderText: "5"
				},

				// Náklady
				costs: {
					title: "Náklady celkem v Kč",
					defaultValue: "",
					placeHolderText: "0 ,-",
					required: true,
					validate: value => value.length == 0 ? "Náklady jsou povinné pole" : "",
				},
				congress_fee: {
					title: "Kongresový poplatek v Kč",
					defaultValue: "",
					placeHolderText: "0 ,-",
					onChange: (value, field, form) => form.setField("costs", (Number(value) + Number(form.getField("travel_expenses").value) + Number(form.getField("accommodations").value)).toString())
				},

				travel_expenses: {
					title: "Cestovné v Kč",
					defaultValue: "",
					placeHolderText: "0 ,-",
					onChange: (value, field, form) => form.setField("costs", (Number(value) + Number(form.getField("congress_fee").value + form.getField("accommodations").value)).toString())
				},
				accommodations: {
					title: "Ubytování v Kč",
					defaultValue: "",
					placeHolderText: "0 ,-",
					onChange: (value, field, form) => form.setField("costs", (Number(value) + Number(form.getField("congress_fee").value) + Number(form.getField("travel_expenses").value)).toString())
				},

				// Požadováno
				requested_contribution: {
					title: "Požadovaný příspěvek od ČSK",
					defaultValue: "",
					required: true,
					validate: value => value.length == 0 ? "Požadovaný příspěvek je povinné pole" : "",
				},
				deposit: {
					title: "žádám o zálohu",
					defaultValue: false
				},
				contribution_use: {
					title: "Příspěvěk bude sloužit k úhradě",
					defaultValue: ""
				},

				// Individuální žádost
				lecture: {
					title: "Přednáška",
					defaultValue: false
				},
				congress: {
					title: "Kongres",
					defaultValue: false
				},
				course: {
					title: "Praktický kurs",
					defaultValue: false
				},

				internship: {
					title: "Stáž",
					defaultValue: false
				},
				poster: {
					title: "Poster",
					defaultValue: false
				},
				symposium: {
					title: "Sympozium",
					defaultValue: false
				},

				lecture_name: {
					title: "Název přednášky / posteru - Autor / autoři",
					defaultValue: ""
				},


				overview_of_cooperation: {
					title: "Dosavadní spolupráce s ČSK (přednášková činnost, publikační činnost...)",
					defaultValue: ""
				},
				proposal_of_cooperation: {
					title: "Návrh další spolupráce s ČSK",
					defaultValue: ""
				},

				// K žádosti přiložte tyto přílohy
				statement_attachment: {
					title: "Vyjádření přednosty kliniky (tuto vytištěnou a potvrzenou žádost přiložte dodatečně k žádosti)",
					defaultValue: undefined
				},
				participation_confirmation_attachment: {
					title: "Přihláška a potvrzení účasti (formát:pdf nebo obrázek, velikost: max 4MB)",
					defaultValue: undefined
				},
				information_attachment: {
					title: "Informace / brožura o kongresu, sympoziu, praktickém kurzu, akci",
					defaultValue: undefined
				},
				costs_attachment: {
					title: "Doklady k nákladům: cestovné, ubytování, kongresový/registrační poplatek (formát:pdf nebo obrázek, velikost: max 4MB)",
					defaultValue: undefined
				},
				abstract_attachment: {
					title: "Abstrakt  (formát:pdf nebo obrázek, velikost: max 4MB)",
					defaultValue: undefined
				},
				poster_attachment: {
					title: "Kopie posteru ve formátu A4 (formát:pdf nebo obrázek, velikost: max 4MB)",
					defaultValue: undefined
				},

				submission_date: {
					title: "Datum podání",
					defaultValue: current_date,
					readOnly: true
				},

				confirmation: {
					title: "Potvrzení účasti (formát:pdf nebo obrázek, velikost: max 4MB)",
					defaultValue: undefined
				},
				information_confirmation: {
					title: "Informace o kongresu, sympoziu, praktickém kurzu, akci, stáži - odkaz na web akce",
					defaultValue: "",
				},
				affidavit: {
					title: "Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti jsou pravdivé a úplné.",
					defaultValue: false,
					required: true,
					validate: value => !value ? "Potvrzení je povinné pole" : "",
				},
				email: {
					title: "Email",
					defaultValue: "",
					readOnly: true
				},
				name: {
					title: "Přednosta - jméno, příjmení, tituly",
					defaultValue: ""
				},
				clinic: {
					title: "Klinika",
					defaultValue: "",
					required: true,
					validate: value => !value ? "Klinika je povinné pole" : "",
				},
			}
		}, context);
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormFields = {
				...this.form.getFields(),
			};
			await this.commonModel.send(data, "zadost-o-prispevek-z-uctu-klinik");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
 * Vrací kolekci stavových kontejnerů
 */
	getStateContainers = () => {
		return [
			...this.commonModel.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	loadData = async (webRequestId: string) => {
		await this.commonModel.loadData(webRequestId);
		const webRequest = this.commonModel.getWebRequest();

		if (webRequest) {
			const data = webRequest.data as FormFields;
			await this.form.setFields(data);
		}
	}

	loadDefaultData = async () => {
		let userProfile = this.context.authorization.getUserProfile();
		if (!userProfile) {
			userProfile = await this.context.authorization.loadUserProfile();
		}

		await this.form.setFields({
			workplace_name: userProfile.profile.workplace_main.name,
			street_and_number: userProfile.profile.workplace_main.address?.street,
			township: userProfile.profile.workplace_main.address?.city,
			psc: userProfile.profile.workplace_main.address?.postcode,
			name: userProfile.profile.workplace_main.head.full_name_with_title,
			email: userProfile.profile.workplace_main.head.email
		});
	}

	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.commonModel.reset(),
			this.loadDefaultData()
		]);
	}
}