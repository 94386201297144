import * as api from "../../../lib/api";
import * as apiUsers from "../../shared/users/api-users";
import * as context from "../../../context";

export interface WebOrgan {
	id: api.Id;
	name: string;
	heads_title: string;
	heads: apiUsers.UserSimple[];
	members_title: string;
	members: apiUsers.UserSimple[];
}

export class Api {
	constructor(private context: context.StateContext) { }

	load = (id: api.Id) => {
		return this.context.api.get<WebOrgan>("/organs/" + id);
	}
}