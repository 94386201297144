/**
 * Tlačítka pro stránkování obsahu.
 */

import * as React from "react";
import * as lists from "./lists";
import Paginator, { PaginatorProps } from "../../../components/controls/Paginator";

export interface ListPaginatorProps {
	list: lists.StandardList<any, any>;
	paginatorProps?: PaginatorProps;
}

export default function ListPaginator(props: ListPaginatorProps) {
	const { list, paginatorProps, ...restProps } = props;
	return (
		<Paginator
			listResponse={props.list.getListResponse()}
			onPageChanged={props.list.setPage}
			{...restProps}
		/>
	);
}