/**
 * Klikací povinnost
 */
import React, { useEffect } from "react";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as formatting from "../../../lib/formatting";
import * as authorization from "../../system/authorization/authorization";
import * as navigation from "../../../lib/navigation";
import HtmlContent from "../../../components/controls/HtmlContent";
import Button from "../../../components/controls/Button";
import WebRequest from "../../../components/templates/WebRequest";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";

interface WebRequestOskCskEntry3PageProps {
	id?: string;
}

function ClickableObligationPage(props: WebRequestOskCskEntry3PageProps) {
	const { clickableObligation: model } = state.useStateContext();

	useEffect(() => {
		if (api.getUrlParam("checkPersonalInfo") && api.getUrlParam("checkPersonalInfo") != "false") {
			model.form.setField("control_of_personal_data", true);
		}

		if (api.getUrlParam("workplace") && api.getUrlParam("workplace") != "false") {
			model.form.setField("workplace_control", true);
		}

		if (api.getUrlParam("education") && api.getUrlParam("education") != "false") {
			model.form.setField("checking_data_on_educational_attainment", true);
		}
	}, []);

	const form = model.form;

	function handlePersonalInfo() {
		const param = window.location.search;
		if (!param) {
			navigation.to("/muj-profil?checkPersonalInfo=false");
		}
		else if (!param.includes("checkPersonalInfo")) {
			navigation.to("/muj-profil" + param + "&checkPersonalInfo=false");
		}
		else {
			navigation.to("/muj-profil" + param);
		}
	}

	function handleEducation() {
		const param = window.location.search;
		if (!param) {
			navigation.to("/moje-vzdelani?education=false");
		}
		else if (!param.includes("education")) {
			navigation.to("/moje-vzdelani" + param + "&education=false");
		}
		else {
			navigation.to("/moje-vzdelani" + param);
		}
	}

	function handleWorkplace() {
		const param = window.location.search;
		if (!param) {
			navigation.to("/moje-agenda/pracoviste?workplace=false");
		}
		else if (!param.includes("workplace")) {
			navigation.to("/moje-agenda/pracoviste" + param + "&workplace=false");
		}
		else {
			navigation.to("/moje-agenda/pracoviste" + param);
		}
	}

	const app = state.useStateContext();
	const myProfile = app.authorization.getUserProfile()?.profile;

	return (
		<WebRequest
			title={"KLIKACÍ POVINNOST"}
			description=""
			authorized={false}
			model={model.common}
			id={props.id}
		>
			<HtmlContent htmlKey="klikaci-povinnost-obsah" />
			<div className="mb-3">
				<div className=" row">
					<div className="col-md-5">
						<FormsCheckBox
							form={form}
							enhancedTitle={<>1. Kontrola osobních údajů<div onClick={() => handlePersonalInfo()} className="d-inline cursor-pointer"> (<span className="font-weight-bold text-decoration-underline">zde</span>)</div></>}
							field="control_of_personal_data"
						/>
					</div>
				</div>
				<div className=" row">
					<div className="col-md-5">
						<FormsCheckBox
							form={form}
							enhancedTitle={<>2. Kontrola údajů o dosaženém vzdělání<div onClick={() => handleEducation()} className="d-inline cursor-pointer"> (<span className="font-weight-bold text-decoration-underline">zde</span>)</div></>}
							field="checking_data_on_educational_attainment"
						/>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-md-5">
						<FormsCheckBox
							form={form}
							enhancedTitle={<>3. Kontrola pracovišť<div onClick={() => handleWorkplace()} className="d-inline cursor-pointer"> (<span className="font-weight-bold text-decoration-underline">zde</span>)</div></>}
							field="workplace_control"
						/>
					</div>
				</div>
				{(!myProfile?.date_off_last_confirmations || (myProfile?.date_off_last_confirmations && myProfile?.date_off_last_confirmations!.getFullYear()! < new Date().getFullYear())) &&
					<div>POZOR - V roce {myProfile?.date_off_last_confirmations && (myProfile?.date_off_last_confirmations.getFullYear() == new Date().getFullYear() - 1) ?
						new Date().getFullYear() :
						new Date().getFullYear() - 1
					} jste klikací povinost neprovedl, tímto provedete klikací povinnost za chybějící rok dodatečně.</div>
				}
			</div>
			<div className="text-right">
				<Button theme="primary" onClick={_ => model.confirm()} spinner={model.common.justSending()}>Potvrzení správnosti členských údajů (klikací povinnost)</Button>
			</div>
		</WebRequest>
	);
}


export default state.bindContainers(
	ClickableObligationPage,
	c => c.clickableObligation
);