/**
 * Model žádosti o vydání osvědčení o splnění podmínek k výkonu funkce odborného zástupcE
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as navigation from "../../../lib/navigation";
import * as notification from "../../../lib/notification";
import * as forms from "../../../lib/forms";
import * as apiFile from "../../system/file/api-file";
import { CodeBookItem } from "app/lib/api";

export enum Category {
	F1A = 1,
	F1B = 2,
	F1C = 3,
	F2A = 4,
	F2B = 5,
	F2C = 6,
	F2D = 7,
	F3A = 8,
	F4A = 9,
	F4C = 10,
	F4D = 11,
	F4E = 12,
	F4G = 13,
	F4Ga = 14,
	F4Gb = 15,
	F4Gc = 16,
	F5A = 17,
	F5B = 18,
	F6A = 19,
	F6B = 20,
	F7A = 21,
}

export interface FileFormFields {
	attachment?: apiFile.File;
}
export interface FormFields {
	id_main_category: number;
	id_category: number;
	date_from: string;
	date_to: string;
	name: string;
	description: string;
	quantity?: number;
	year?: number;
	company?: CodeBookItem;
	credits: number;
	declaration: boolean;
}

interface FormData extends FormFields {
	attachments: FileFormFields[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public formsFiles: forms.FormCollection<FileFormFields>;

	constructor(private context: context.StateContext) {
		this.form = new forms.Form<FormFields>({
			fields: {
				name: {
					title: "Název",
					defaultValue: "",
				},
				declaration: {
					defaultValue: false,
					title: "Čestně prohlašuji, že jsem se vzdělával/a četbou odborných článků publikovaných v odborných časopisech medicínského zaměření.",
					required: (form) => form.getField("id_category").value == Category.F7A,
					validate: (value, field, form) => !value && form.getField("id_category").value == Category.F7A ? "Prohlášení je povinný atribut" : "",
				},
				date_from: {
					title: (value) => !this.categories(this.form.getField("id_category").value).dateTo ? "Datum od" : "Datum",
					required: (form) => !this.categories(form.getField("id_category").value).dateFrom,
					validate: (value, field, form) => !value && !this.categories(form.getField("id_category").value).dateFrom ? "Datum od je povinný atribut" : "",
					defaultValue: "",
				},
				date_to: {
					title: "Datum do",
					required: (form) => !this.categories(form.getField("id_category").value).dateTo,
					validate: (value, field, form) => !value && !this.categories(form.getField("id_category").value).dateTo ? "Datum do je povinný atribut" : "",
					defaultValue: "",
				},
				description: {
					title: "Popis",
					defaultValue: ""
				},
				id_main_category: {
					title: "",
					defaultValue: 1,
					onChange: async (id, field, form) => {
						await form.setField("date_from", "");
						await form.setField("date_to", "");
						await form.setField("description", "");
						await form.setField("year", undefined);
						await form.setField("company", undefined);
						await form.setField("quantity", undefined);
						await form.setField("credits", 0);

						form.setField("id_category",
							id == 1 ? Category.F1A : id == 2 ? Category.F2D : id == 3 ? Category.F3A :
								id == 4 ? Category.F4A : id == 5 ? Category.F5A : id == 6 ? Category.F6B : id == 7 ? Category.F7A : Category.F1A
						);

						if ([Category.F1B, Category.F4A, Category.F4C, Category.F4D, Category.F4E].includes(id)) {
							await form.setField("quantity", 1);
						}
						else {
							await form.setField("quantity", 0);
						}
					}
				},
				id_category: {
					title: "",
					defaultValue: 1,
					onChange: async (id, field, form) => {
						await form.setField("date_from", "");
						await form.setField("date_to", "");
						await form.setField("year", undefined);
						await form.setField("company", undefined);
						await form.setField("quantity", undefined);
						await form.setField("description", "");
						await form.setField("credits", 0);
					}
				},
				year: {
					title: "Rok",
					defaultValue: undefined,
					required: (form) => [Category.F1C, Category.F4E].includes(form.getField("id_category").value),
					validate: (value, field, form) => !value && [Category.F1C, Category.F4E].includes(form.getField("id_category").value) ? "Rok je povinný údaj" : "",
				},
				company: {
					title: () => this.form.getField("id_category").value == Category.F4E ? "Vysoká škola" : "Odborná společnost",
					defaultValue: undefined,
				},
				quantity: {
					defaultValue: undefined,
					title: (form) => [Category.F1A].includes(form.getField("id_category").value) ? "Počet hodin" : "Množství",
					required: (form) => !this.categories(form.getField("id_category").value).quantity,
					validate: (value, field, form) => !value && !this.categories(form.getField("id_category").value).quantity ? "Množství je povinný údaj" : "",
				},
				credits: {
					title: "Kredity",
					defaultValue: 0,
				},
			}
		}, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Připojit soubor",
					defaultValue: undefined,
					required: (form) => true,
					validate: (value, field, form) => !value && this.form.getField("id_category").value != Category.F7A ? "Prosím, vyplňte přílohu" : ""
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
	}

	/**
 * Vrací kolekci stavových kontejnerů
 */
	getStateContainers = () => [
		...this.form.getStateContainers()
	]


	getDaysFromDates = (date1: Date, date2: Date) => {
		// To calculate the time difference of two dates
		var differenceInTime = date2.getTime() - date1.getTime();
		// To calculate the no. of days between two dates
		return differenceInTime / (1000 * 3600 * 24) + 1;
	}

	calcCreditFromDates = (date1: Date | null, date2: Date | null, credit: number) => {
		const dateFrom = this.form.getField("date_from").value ? new Date(this.form.getField("date_from").value) : null;
		const dateTo = this.form.getField("date_to").value ? new Date(this.form.getField("date_to").value) : null;
		let days = 0;
		if (dateFrom && dateTo)
			days = this.getDaysFromDates(dateFrom, dateTo);
		return !days ? 0 : (days < 0 ? 0 : days * credit)
	}

	categories = (sub: number) => {
		const dateFrom = [Category.F1C, Category.F7A, Category.F4E].includes(sub);
		const dateTo = [Category.F1A, Category.F1B, Category.F1C, Category.F4A, Category.F4C, Category.F4D, Category.F4E, Category.F4Ga, Category.F4Gb, Category.F4Gc, Category.F5A, Category.F5B, Category.F6B, Category.F7A].includes(sub);
		const quantity = [Category.F1C, Category.F1B, Category.F2D, Category.F3A, Category.F4A, Category.F4C, Category.F4D, Category.F4E, Category.F4Ga, Category.F4Gb, Category.F4Gc, Category.F5A, Category.F5B, Category.F6B, Category.F7A].includes(sub);
		return { dateFrom: dateFrom, dateTo: dateTo, quantity: quantity };
	}

	getCredits = () => {
		const dateFrom = this.form.getField("date_from").value ? new Date(this.form.getField("date_from").value) : null;
		const dateTo = this.form.getField("date_to").value ? new Date(this.form.getField("date_to").value) : null;
		const quantity = Number(this.form.getField("quantity").value);
		const year = Number(this.form.getField("year").value);
		const sub = this.form.getField("id_category").value;
		let credits = 0;
		let days = 0;
		switch (sub) {
			case Category.F1A:
			case Category.F6A:
				credits = (!quantity || quantity < 0) ? 0 : quantity == 1 ? 1 : 2
				break;
			case Category.F1A:
				credits = (!quantity || quantity < 0) ? 0 : quantity == 1 ? 1 : 2
				break;
			case Category.F2A:
			case Category.F2B:
				credits = (!quantity || quantity < 0) ? 0 : quantity > 5 ? 5 : quantity
				break;
			case Category.F2A:
				if (dateFrom && dateTo)
					days = this.getDaysFromDates(dateFrom, dateTo);

				credits = !days ? 0 : days > 8 ? 8 : days
				break;
			case Category.F2D:
				credits = this.calcCreditFromDates(dateFrom, dateTo, 2);
				break;
			case Category.F7A:
				credits = 2
				break;
			case Category.F3A:
				credits = this.calcCreditFromDates(dateFrom, dateTo, 10);
				break;
			case Category.F4A:
				credits = 5
				break;
			case Category.F4Ga:
				credits = 15
				break;
			case Category.F4Gb:
				credits = 2
				break;
			case Category.F4Gc:
				credits = 30
				break;
			case Category.F4C:
			case Category.F4D:
				credits = 20
				break;
			case Category.F4E:
			case Category.F5A:
				credits = 2;
				break;
			case Category.F5B:
				credits = 1;
				break;
			case Category.F6B:
				credits = 1;
				break;
			case Category.F7A:
				credits = 1;
				break;
			case Category.F1C:
				credits = 1;
				break;
		}
		return credits;
	}

	save = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				credits: this.getCredits()
			};
			data.id_main_category = undefined as any;
			data.date_from = new Date(data.date_from) as any;
			data.date_to = new Date(data.date_to) as any;
			const result = await this.context.api.post("/users/educational_actions/add", data);
			if (result) {
				navigation.to("/moje-agenda/akce")
				this.form.clearFields();
				this.context.standardDialogs.openInformationDialog("Akce byla přidána.");
			}
		}
		else {
			await notification.errorMessage("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}
}
