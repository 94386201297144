import * as dialogs from "./dialogs";
import * as api from "../../../lib/api";
import * as navigation from "../../../lib/navigation";
import * as context from "../../../context";
import * as common from "../../../lib/common";

interface InformationDialogFields {
	message: string;
	buttonOkTitle: string;
	isErrorMessage: boolean;
}

interface YesNoDialogFields {
	message: string;
	buttonYesTitle: string;
	buttonNoTitle: string;
}

interface SearchDialogFields {
	fulltext: string;
}

interface ContentFields {
	id: string;
	key: string;
	text: string;
	content_type: api.CodeBookItem | null;
	placement: string;
	data: api.CodeBookItem[];
}

interface ChangeStateFields {
	ids: string[];
	state_id: number;
	message?: string;
}

export class Model {
	resourceDialog: dialogs.Dialog<undefined, ContentFields>;
	informationDialog: dialogs.Dialog<undefined, InformationDialogFields>;
	request1Dialog: dialogs.Dialog<boolean, boolean>;
	yesNoDialog: dialogs.Dialog<"yes" | "no", YesNoDialogFields>;
	searchDialog: dialogs.Dialog<boolean, SearchDialogFields>;
	changeStateDialog: dialogs.Dialog<undefined, ChangeStateFields>;

	constructor(private context: context.StateContext) {
		this.request1Dialog = new dialogs.Dialog<boolean, boolean>({
			defaultCustomFields: false,
			hideCloseButton: true
		}, context);
		this.informationDialog = new dialogs.Dialog<undefined, InformationDialogFields>({
			defaultCustomFields: {
				message: "",
				buttonOkTitle: "Ok",
				isErrorMessage: false
			},
			hideCloseButton: true
		}, context);

		this.yesNoDialog = new dialogs.Dialog<"yes" | "no", YesNoDialogFields>({
			defaultCustomFields: {
				message: "",
				buttonYesTitle: "Ano",
				buttonNoTitle: "Ne",
			},
			hideCloseButton: true
		}, context);

		this.searchDialog = new dialogs.Dialog<boolean, SearchDialogFields>({
			defaultCustomFields: {
				fulltext: ""
			},
			hideCloseButton: true
		}, context);

		this.resourceDialog = new dialogs.Dialog<undefined, ContentFields>({
			defaultCustomFields: {
				key: "",
				content_type: null,
				placement: "",
				text: "",
				id: "",
				data: []
			}
		}, context);
		this.changeStateDialog = new dialogs.Dialog<undefined, ChangeStateFields>({
			defaultCustomFields: {
				ids: [],
				state_id: 0,
				message: ""
			}
		}, context);
	}

	openInformationDialog = async (message: string, buttonOkTitle?: string, error?: boolean) => {
		await this.informationDialog.setCustomField("message", message);
		if (error) {
			await this.informationDialog.setCustomField("isErrorMessage", true);
		}
		if (buttonOkTitle !== undefined && buttonOkTitle !== "") {
			await this.informationDialog.setCustomField("buttonOkTitle", buttonOkTitle);
		}
		return this.informationDialog.open();
	}

	openYesNoDialog = async (message: string, buttonYesTitle?: string, buttonNoTitle?: string) => {
		await this.yesNoDialog.setCustomField("message", message);
		if (buttonYesTitle !== undefined && buttonYesTitle !== "") {
			await this.yesNoDialog.setCustomField("buttonYesTitle", buttonYesTitle);
		}
		if (buttonNoTitle !== undefined && buttonNoTitle !== "") {
			await this.yesNoDialog.setCustomField("buttonNoTitle", buttonNoTitle);
		}
		return this.yesNoDialog.open();
	}

	openSearchDialog = async () => {
		return this.searchDialog.open();
	}

	openResourceDialog = async (key: string, reload: any) => {
		const data = await this.context.api.loadList<api.CodeBookItem>("/code-list/content_types", {});
		const item = await this.context.api.get<ContentFields>("/contents/" + key);

		await this.resourceDialog.setCustomField("content_type", item.content_type);
		await this.resourceDialog.setCustomField("id", item.id);
		await this.resourceDialog.setCustomField("key", item.key);
		await this.resourceDialog.setCustomField("placement", item.placement);
		await this.resourceDialog.setCustomField("text", item.text);
		const items = data.data;
		items.unshift({ id: "", name: "" })
		await this.resourceDialog.setCustomField("data", items);

		await this.resourceDialog.open();
		reload();
	}

	openChangeStateDialog = async (id: string, state: number, reload: (id: string) => Promise<void>) => {
		await this.changeStateDialog.setCustomField("ids", [id]);
		await this.changeStateDialog.setCustomField("state_id", state);

		await this.changeStateDialog.open();
		reload(id);
	}

	saveResourceDialog = async () => {
		const item = this.resourceDialog.getCustomFields();
		const result = await this.context.api.put("/contents/" + item.id, item);
		if (result) {
			await this.context.contents.loadContents();
			await this.context.standardDialogs.resourceDialog.close();
			await this.context.standardDialogs.openInformationDialog("Obsah byl úspěšně uložen.");

		}
	}

	saveChangeStateDialog = async () => {
		const item = this.changeStateDialog.getCustomFields();
		const result = await this.context.api.post("/requests/bulk/change_state", item);
		if (result) {
			await this.context.standardDialogs.changeStateDialog.close();
			await this.context.standardDialogs.openInformationDialog("Stav byl úspěšně změněn.");

		}
	}

	saveSearchDialog = async () => {
		const item = this.searchDialog.getCustomFields();
		await navigation.to("/obsahy");
		await common.deffered(async () => {
			await this.context.content.loadData(item.fulltext, 1);
		});
		this.searchDialog.close();
	}
}