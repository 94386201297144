/**
 *  Formulář žádosti o změnu kontaktních údajů
 */

import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";
import * as api from "../../../lib/api";

import WebRequest from "../../../components/templates/WebRequest";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormFileUpload from "../../system/file/FormFileUpload";
import CheckBox from "../../../components/controls/Checkbox";
import FormsAutocomplete from "../../../components/controls/Forms/FormsAutocomplete";
import Button from "../../../components/controls/Button";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import Spinner from "../../../components/controls/Spinner";

interface WebRequestOskRegistrationPageProps {
	id?: string;
}

function WebRequestOskRegistrationPage(props: WebRequestOskRegistrationPageProps) {
	const title = "ŽÁDOST O ZÁPIS DO SEZNAMU ČLENŮ OBLASTNÍ KOMORY (nová hlavní Oblastní komora, změna hlavní Oblastní komory, nové hlavní pracoviště, obnovení zápisu do OSK pro členy bez příslušnosti k OSK - Bez OSK- a Bez OSK+)";
	const { webRequestOskRegistration: model } = state.useStateContext();
	const { authorization } = state.useStateContext();
	const form = model.form;
	const workplace = form.getField("workplace").value;
	const profile = authorization.getUserProfile() == undefined ? undefined : authorization.getUserProfile()!.profile;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p className="font-weight-bold">
				Aktuálně jsem zapsán/a v seznamu členů Oblastní komory (hlavní):
			</p>
			<div className="mb-4">
				{form.getField("primary_chamber_old").value?.name}
			</div>

			<p>
				{profile && profile.regional_chamber_main?.id == api.emptyGuid &&
					<strong>
						Beru na vědomí, že zápisem do seznamu členů nové Oblastní komory (hlavní),
						o který žádám, zanikne zápis v seznamu členů Oblastní komory (hlavní),
						ve kterém jsem zapsán/a v současné době.
					</strong>
				}
				{profile && profile.regional_chamber_main == null &&
					<strong>
						Beru na vědomí, že v případě, že můj zápis do seznamu členů OSK zanikl marným uplynutím náhradní lhůty k zaplacení členského příspěvku nebo ke splnění kontrolní a oznamovací povinnosti (klikací povinnost),
						bude žádost schválena až po uhrazení dlužných členských příspěvků nebo dodatečném splnění kontrolní a oznamovací povinnosti člena (klikací povinnost).
					</strong>
				}
			</p>
			<CheckBoxRadioFrame className="mb-3">
				<div className="row">
					<div className="col-12">
						<FormRadio
							form={form}
							field="current_regional_chamber"
							option="set_as_cancel"
							title="Aktuální hlavní Oblastní komoru ukončit"
						/>
					</div>
					<div className="col-12">
						<FormRadio
							form={form}
							field="current_regional_chamber"
							option="set_as_secondary"
							title="Aktuální hlavní Oblastní komoru nastavit jako vedlejší"
						/>
					</div>
					<div className="col-12">
						<FormRadio
							form={form}
							field="current_regional_chamber"
							option="set_as_only_emergency"
							title="Aktuální hlavní Oblastní komoru nastavit jako vedlejší - jen pohotovost"
						/>
					</div>
				</div>
			</CheckBoxRadioFrame>
			<p className="font-weight-bold mb-3">Žádám o zápis do seznamu členů nové hlavní Oblastní komory:</p>
			<p>Vyberte nového poskytovatele zdravotní péče a pracoviště, dle kterého Vám bude určena nová hlavní Oblastní komora.</p>
			<CheckBoxRadioFrame className="mb-3">
				<div className="row">
					<div className="col-12">
						<FormRadio
							form={form}
							field="request_workplace_type"
							option="existing_workplace"
							title="výběr nového poskytovatele a pracoviště z nabídky"
						/>
					</div>
					<div className="col-12">
						<FormRadio
							form={form}
							field="request_workplace_type"
							option="hosting_workplace"
							title="výběr jedné z mých hostujících oblastí (pokud vykonáváte povolání zubního lékaře ještě v dalších Oblastních komorách, které máte nyní vedeny jako hostující Oblastní komory (jste zde evidovaný člen), můžete si svou novou Oblastní komoru (hlavní), zvolit mezi nimi)"
						/>
					</div>
					<div className="col-12">
						<FormRadio
							form={form}
							field="request_workplace_type"
							option="not_found"
							title="pokud jste poskytovatele a pracoviště v nabídce nenalezli, založte nového poskytovatele nebo pracoviště"
						/>
					</div>
				</div>
			</CheckBoxRadioFrame>
			{model.form.getField("request_workplace_type").value == "existing_workplace" &&
				<>
					<FormsAutocomplete
						form={form}
						field="workplace"
						autocompleteProps={{
							trigger: "onfocus",
							loadItems: model.loadWorkplaces,
							getItemText: item => item?.name ?? "",
							getMenuItem: item => item?.name
						}}
					/>
					{workplace &&
						<div className="row mb-3">
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Poskytovatel</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.provider.name}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Název pracoviště</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.name}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Telefon</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.contact?.phone1}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">E-mail</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.contact?.email1}</span>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">IČO</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.provider.registration_number}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Ulice a číslo</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.street}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Obec</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.city}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">PSČ</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.postcode}</span>
									</div>
								</div>
							</div>
						</div>
					}
				</>
			}
			{model.form.getField("request_workplace_type").value == "hosting_workplace" &&
				<>
					<div className="secondary-title">
						Pokud vykonáváte povolání zubního lékaře ještě v dalších Oblastních komorách,
						které máte nyní vedeny jako hostující Oblastní komory (jste zde evidovaný člen), můžete si svou novou Oblastní komoru (hlavní), zvolit mezi nimi:
					</div>
					<CheckBoxRadioFrame className="mb-4">
						{
							(form.getField("host_chambers").value.length == 0 && model.loaded()) &&
							<div className="ml-3">Nejsou k dispozici žádné hostujici oblastni komory</div>
						}
						{model.justLoadingHostChambers() &&
							<div className="d-flex align-items-center">
								<Spinner small className="ml-3 mr-2" /> Nahrávám seznam oblastních komor a pracovišť...
							</div>
						}
						{form.getField("host_chambers").value.length > 0 &&
							<>
								{form.getField("host_chambers").value.map(i =>
									<div className="row">
										<div className="col-md-6">
											<CheckBox
												title={
													<>
														<div>
															{i.regional_chamber.name}
														</div>
														<div>
															<small>
																{[i.workplace.name, i.workplace.address?.street, i.workplace.address?.city].filter(i => i !== undefined).join(", ")}
															</small>
														</div>
													</>}
												checked={i.checked}
												onCheckedChanged={_ => model.toggleHostChamber(i)} />
										</div>
									</div>
								)}
							</>
						}
					</CheckBoxRadioFrame>
				</>
			}
			{model.form.getField("request_workplace_type").value == "not_found" &&
				<>
					<div className="row mt-3 mb-1">
						<div className="col-lg-6">
							<FormsInput form={form} field="workplace_new_provider" />
							<FormsInput form={form} field="workplace_new_ico" />
							<FormsInput form={form} field="workplace_new_provider_address_street" />
							<FormsInput form={form} field="workplace_new_provider_address_city" />
							<FormsInput hideNumeric={true} form={form} field="workplace_new_provider_address_postcode" inputProps={{ type: "number" }} />
						</div>
						<div className="col-lg-6">
							<FormsInput form={form} field="workplace_new_name" />
							<FormsInput form={form} field="workplace_new_address_street" />
							<FormsInput form={form} field="workplace_new_address_city" />
							<FormsInput hideNumeric={true} form={form} inputProps={{ type: "number" }} field="workplace_new_address_postcode" />
							<FormsInput form={form} field="workplace_new_contact_phone" />
							<FormsInput form={form} field="workplace_new_contact_email" />
						</div>
					</div>
				</>
			}
			{model.form.getField("request_workplace_type").value == "not_found" &&
				<>
					<p className="mt-5 mb-3">
						<strong>
							Přiložte Rozhodnutí o udělení oprávnění k poskytování zdravotních služeb
							z KÚ nebo MHMP (v případě Prahy): <span className="form-item__required">*</span>
						</strong>
					</p>
					{model.formsFiles.get().map((formFile) =>
						<div className="row">
							<div className="col-8 col-lg-10">
								<FormFileUpload
									fileUploadProps={{
										className: "mb-4"
									}}
									form={formFile}
									field="attachment"
									formItemOptions={{
										hideTitle: true
									}}
								/>
							</div>
							<div className="col-4 col-lg-2">
								<Button className="d-block w-100" onClick={() => model.removeFile(formFile)}>Odebrat</Button>
							</div>
						</div>
					)}
					<Button onClick={model.addFile} className="mb-5">Přidat přílohu</Button>
				</>
			}

			<div className="row">
				<div className="col-6">
					<FormsInput form={form} field="date_of_entry_into_force" inputProps={{ type: "date" }} />
				</div>
				<div className="col-6">
					<FormsInput form={form} field="date_of_validity_of_the_amendments" inputProps={{ type: "date" }} />
				</div>
			</div>

			<p><span className="font-weight-bold">Vztah k poskytovateli</span> (lze označit i více možností):</p>
			<CheckBoxRadioFrame className="mb-5">
				<div className="row">
					<div className="col-lg-6">
						<FormCheckBox form={form} field="relationship_legal_person" />
						<FormCheckBox form={form} field="relationship_statutory_authority" />
						<FormCheckBox form={form} field="relationship_osvc" />
						<FormCheckBox form={form} field="relationship_employee" />
					</div>
					<div className="col-lg-6">
						<FormCheckBox form={form} field="relationship_professional_representative" />
						<FormCheckBox form={form} field="relationship_chief_physician" />
						<FormCheckBox form={form} field="relationship_primary" />
					</div>
				</div>
			</CheckBoxRadioFrame>

			<p>
				<strong>
					Oznamuji, že výkon povolání zahajuji (vztah k poskytovateli) na území nové hlavní Oblastní komory:
				</strong>
			</p>
			<div className="row">
				<div className="col-md-6">
					<FormsAutocomplete
						form={form}
						field="primary_chamber_new"
						autocompleteProps={{
							trigger: "onfocus",
							loadItems: model.loadChambers,
							getItemText: item => item?.name ?? "",
							getMenuItem: item => item?.name
						}}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<FormsInput form={form} field="date" inputProps={{ type: "date", min: formatting.formatDate(formatting.getDateBefore(new Date(), -14), { format: "iso" }) }} />
				</div>
			</div>

			<p className="mb-5">
				<em>
					UPOZORNĚNÍ: V případě, že v souvislosti se změnou pracoviště měníte i adresu bydliště,
					nezapomeňte podat i Žádost o změnu kontaktních údajů.
				</em>
			</p>

			{
				!props.id &&
				<div className="text-right">
					<div className="mb-2">
						<div className="mb-2">
							<em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em>
						</div>
					</div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestOskRegistrationPage,
	c => c.webRequestOskRegistration
);