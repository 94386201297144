/**
 * Žádost o změnu termínu profesní zkoušky
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

/**
 * Deklarace polí hlavního fomuláře
 */
export interface FormFields {
	program_type: "vppzl" | "vpchir" | "vpparo" | "vppedo";
	old_date: string;
	zop: boolean;
}

/**
 * Definice hlavního fomuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		program_type: {
			title: "Druh vzdělávacího programu",
			defaultValue: "vppzl"
		},
		old_date: {
			title: "Původní termín",
			defaultValue: "",
			required: true,
			validate: value => value === "" ? "Datum je povinné pole" : ""
		},
		zop: {
			title: "",
			defaultValue: false
		}
	}
};

interface FormData extends FormFields { }

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public common: webRequest.Model;
	public form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers(),
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
			};
			let code = "zadost-o-zmenu-terminu-profesni-zkousky";
			switch (this.form.getField("program_type").value) {
				case "vppzl":
					code = "zadost-o-zmenu-terminu-profesni-zkousky-vppzl";
					break;
				case "vpchir":
					code = "zadost-o-zmenu-terminu-profesni-zkousky-vpchir";
					break;
				case "vpparo":
					code = "zadost-o-zmenu-terminu-profesni-zkousky-vpparo";
					break;
				case "vppedo":
					code = "zadost-o-zmenu-terminu-profesni-zkousky-vppedo";
					break;
			}
			await this.common.send(data, code as any);
		} else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string, zop: boolean) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			this.common.loadData(webRequestId);
			const { ...formFields } = data;
			await Promise.all([this.form.setFields(formFields)]);
			this.form.setField("zop", zop);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async (zop: boolean) => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.form.setField("zop", zop)
		]);
	}
}

