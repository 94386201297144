/*
 * Ovládací prvek Autocomplete.
 */
import * as React from "react";
import Input, { InputProps } from "./Input";

import * as common from "../../lib/common";

interface State<Item> {
	shownDropdown: boolean;
	text: string;
	items: Item[];
	selectedItem: Item | undefined;
}

export interface AutocompleteProps<Item> {
	value?: Item | undefined;
	onChange?: (value: string) => void;
	onSelect?: (value: Item | undefined) => void;
	loadItems: (prefix: string) => Promise<Item[]>;
	getItemText?: (item: Item | undefined) => string;
	getMenuItem?: (item: Item | undefined) => React.ReactNode;
	inputProps?: InputProps;
	trigger?: "onchange" | "onfocus";
}

export default class Autocomplete<Item> extends React.Component<AutocompleteProps<Item>, State<Item>> {
	private refDropDown: React.RefObject<HTMLDivElement>;
	private showOrUpdateDropDownDebounced: () => void;

	constructor(props: AutocompleteProps<Item>) {
		super(props);
		this.state = { shownDropdown: false, items: [], text: this.getItemTextValue(props.value), selectedItem: undefined };
		this.refDropDown = React.createRef<HTMLDivElement>();
		this.showOrUpdateDropDownDebounced = common.debounce(this.showOrUpdateDropDown, 350);
	}

	getMenuItem = (item: Item | undefined): React.ReactNode => {
		if (this.props.getMenuItem) {
			return this.props.getMenuItem(item);
		}

		if (typeof item === "string") {
			return item;
		}

		return item !== undefined && (item as any).toString ? (item as any).toString() : "N/A";
	}

	getItemTextValue = (item: Item | undefined): string => {
		if (this.props.getItemText) {
			return this.props.getItemText(item);
		}

		if (typeof item === "string") {
			return item;
		}

		return item !== undefined && (item as any).toString ? (item as any).toString() : "N/A";
	}

	showOrUpdateDropDown = async () => {
		if (!this.props.loadItems) {
			return;
		}

		this.setState(() => ({
			shownDropdown: true
		}));

		if (this.refDropDown.current) {
			this.refDropDown.current.scrollTop = 0;
		}

		// Načíst položky
		let items: Item[] = [];
		if (typeof this.props.loadItems === "function") {
			items = await this.props.loadItems(this.state.text);
		} else {
			items = this.props.loadItems;
		}

		// Uložit položky do stavu
		this.setState(() => ({
			items,
			selectedItem: undefined
		}));
	}

	hideDropDown = () => {
		this.setState(() => ({
			shownDropdown: false,
			selectedItem: undefined
		}));
	}

	selectItem = (item: Item | undefined) => {
		if (this.state.selectedItem !== item) {
			this.setState(() => ({ selectedItem: item }));
		}
	}

	scrollTop = () => {

	}

	handleChange = async (value: string) => {
		this.setState(() => ({ text: value }));
		if (this.props.onChange) {
			this.props.onChange(value);
		}

		if (value.trim().length === 0) {
			if (this.props.onSelect) {
				this.props.onSelect(undefined);
			}
		}

		this.showOrUpdateDropDownDebounced();
	}

	handleSelect = (item: Item | undefined) => {
		if (this.props.onSelect) {
			this.props.onSelect(item);
		}

		if (this.props.onChange) {
			this.props.onChange(this.getItemTextValue(item));
		}

		this.hideDropDown();
	}

	handleFocus = async (e: React.FocusEvent<HTMLInputElement>) => {
		if (!this.props.trigger || this.props.trigger === "onfocus") {
			if ((typeof this.props.value === "string" && this.props.value === "") || this.props.value === undefined) {
				await this.showOrUpdateDropDown();
			}
		}
	}

	handleItemMouseEnter = async (item: Item) => {
		this.selectItem(item);
	}

	handleItemMouseLeave = async (item: Item) => {
		this.selectItem(undefined);
	}

	handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!this.state.shownDropdown) {
			return;
		}

		// ENTER or TAB
		if (e.keyCode === 13 || e.keyCode === 9) {
			if (this.state.items.length === 1) {
				e.preventDefault();
				this.handleSelect(this.state.items[0]);
			} else if (this.state.selectedItem !== undefined) {
				e.preventDefault();
				this.handleSelect(this.state.selectedItem);
			}
		}

		// šipka dolů
		if (e.keyCode === 40) {
			for (let i = 0; i < this.state.items.length; i++) {
				if (this.state.selectedItem === undefined) {
					this.selectItem(this.state.items[i]);
					break;
				}
				if (this.state.selectedItem === this.state.items[i] && i + 1 < this.state.items.length) {
					this.selectItem(this.state.items[i + 1]);
					break;
				}
			}
		}

		// šipka nahoru
		if (e.keyCode === 38) {
			for (let i = this.state.items.length - 1; i >= 0; i--) {
				if (this.state.selectedItem === undefined) {
					this.selectItem(this.state.items[i]);
					break;
				}
				if (this.state.selectedItem === this.state.items[i] && i > 0) {
					this.selectItem(this.state.items[i - 1]);
					break;
				}
			}
		}
	}

	componentDidUpdate = (prevProps: AutocompleteProps<Item>, state: State<Item>) => {
		if (prevProps.value != this.props.value) {
			this.setState(() => ({ text: this.getItemTextValue(this.props.value) }));
		}
	}

	render = () => {
		return (
			<div className="autocomplete">
				<Input
					{...this.props.inputProps}
					onChange={this.handleChange}
					onBlur={this.hideDropDown}
					onFocus={this.handleFocus}
					onKeyDown={this.handleInputKeyDown}
					value={this.state.text}
				/>
				<div
					className={"autocomplete__dropdown " + (!this.state.shownDropdown ? "autocomplete__dropdown--hidden " : "")}
					ref={this.refDropDown}
				>
					{this.state.items.map(item =>
						<div className={"autocomplete__dropdown-item " + (this.state.selectedItem === item ? "autocomplete__dropdown-item--selected " : "")}
							onClick={() => this.handleSelect(item)}
							onMouseEnter={() => this.handleItemMouseEnter(item)}
							onMouseLeave={() => this.handleItemMouseLeave(item)}>
							{this.getMenuItem(item)}
						</div>
					)}
				</div>
			</div>
		);
	}
}