/**
 * Seznam vlastních plateb
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiPaymentPrescriptions from "../../shared/payment-prescriptions/api-payment-prescriptions";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiPaymentPrescriptions.PaymentPrescription>({
		context: context,
		title: "Seznam předpisů k úhradě",
		quickFilterPlaceholder: "Vyhledejte předpis k úhradě...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiPaymentPrescriptions.user,
		sortingFields: [],
		grid: {
			minWidth: "1200px",
			columns: [
				{
					field: "variable_symbol",
					type: "string",
					title: "Variabilní symbol"
				},
				{
					field: "text",
					type: "string",
					title: "Text",
					width: "350px"
				},
				{
					field: "payment_prescription_state.name" as any,
					type: "string",
					title: "Stav"
				},
				{
					field: "invoice_date",
					type: "date",
					title: "Datum vystavení"
				},
				{
					field: "due_date",
					type: "date",
					title: "Splatnost"
				},
				{
					field: "payment_date",
					type: "date",
					title: "Zaplaceno"
				},
				{
					field: "total_price_with_vat",
					type: "number",
					title: "Celkem"
				},
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiPaymentPrescriptions.PaymentPrescription>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({ counter: 0 }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.userLoggedIn())
			await this.list.loadWithResetPage();
	}
}