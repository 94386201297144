import React, { useEffect, useRef } from "react";

import Page, { PageProps } from "./Page";
import SlideDown from "../controls/SlideDown";
import Button from "../controls/Button";
import Link from "../controls/Link";
import IconSvg from "../controls/IconSvg";
import Spinner from "../controls/Spinner";
import HtmlContent from "../controls/HtmlContent";

import * as state from "../../lib/state";
import * as apiLib from "../../lib/api";
import * as formatting from "../../lib/formatting";
import * as webRequest from "../../modules/shared/web-request/web-request";
import List from "../../modules/system/lists/List";

interface WebRequestProps extends PageProps {
	model: webRequest.Model;
	id?: string;
	authorized?: boolean;
}

export default function WebRequest(props: WebRequestProps) {
	const { authorization, api, apiFile } = state.useStateContext();
	const { standardDialogs } = state.useStateContext();
	const inputRef = useRef(null as any as HTMLInputElement);

	const webRequest = props.model.getWebRequest();
	const justLoading = props.model.justLoading();

	function handleState(state: number) {
		standardDialogs.openChangeStateDialog(props.id!, state, props.model.loadData);
	}

	function handleOpenUpload() {
		inputRef.current!.click();
	}

	async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
		await authorization.setJustSendingWebRequest(true);
		const key = "request.id=" + props.id;
		const file = (e as any).target.files![0];
		await apiFile.upload(file, { key: key });
		await authorization.setJustSendingWebRequest(false);
		authorization.loadWebRequest(props.id!);
	}

	useEffect(() => {
		if (props.id && props.id != apiLib.emptyGuid && props.id != authorization.stateContainer.get().webRequestId) {
			authorization.loadWebRequest(props.id!);
		}
	}, []);

	return (
		<Page authorized={props.authorized ?? true} {...props}>
			<h1 className="d-flex align-items-center">
				{props.title}
				{justLoading &&
					<Spinner small className="ml-1" />
				}
			</h1>

			{props.model.sent() &&
				<>
					<div className="mb-4">
						<HtmlContent content={props.model.getSentText()} />
					</div>
					{authorization.userLoggedIn() &&
						<div>
							<Link to="/moje-agenda/zadosti" noStyle><Button>Seznam žádostí</Button></Link>
						</div>
					}
				</>
			}

			{!props.model.sent() &&
				<>
					{props.id &&
						<>
							{authorization.getCurrentSection() === "osk" &&
								<div className="mb-3">
									<Button onClick={() => handleState(7)} className="mr-2 text-success font-weight-bold">Doporučeno OSK</Button>
									<Button onClick={() => handleState(10)} className="mr-2 text-danger font-weight-bold">Nedoporučeno OSK</Button>
								</div>
							}
							<div className="web-request__header-status">
								<div className="row">
									<div className="col-lg-6">
										{authorization.getCurrentSection() === "osk" &&
											<div className="row">
												<div className="col-4">
													<span className="box-profile__label">Žadatel</span>
												</div>
												<div className="col-8">
													<span className="box-profile__value">{webRequest?.member.full_name}</span>
												</div>
											</div>
										}

										<div className="row">
											<div className="col-4">
												<span className="box-profile__label">Stav</span>
											</div>
											<div className="col-8">
												<span className="box-profile__value">{webRequest?.request_state.name}</span>
											</div>
										</div>

										<div className="row">
											<div className="col-4">
												<span className="box-profile__label">Číslo</span>
											</div>
											<div className="col-8">
												<span className="box-profile__value">{webRequest?.number}</span>
											</div>
										</div>

										<div className="row">
											<div className="col-4">
												<span className="box-profile__label">Datum uhrazení</span>
											</div>
											<div className="col-8">
												<span className="box-profile__value">{formatting.formatDate(webRequest?.payment_date)}</span>
											</div>
										</div>

										<div className="row">
											<div className="col-4">
												<span className="box-profile__label">Důvod zamítnutí</span>
											</div>
											<div className="col-8">
												<span className="box-profile__value">{formatting.formatDate(webRequest?.date_rejected)}</span>
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="row">
											<div className="col-4">
												<span className="box-profile__label">Vytvořeno</span>
											</div>
											<div className="col-8">
												<span className="box-profile__value">{formatting.formatDate(webRequest?.date)}</span>
											</div>
										</div>

										<div className="row">
											<div className="col-4">
												<span className="box-profile__label">Poplatek</span>
											</div>
											<div className="col-8">
												<span className="box-profile__value">{webRequest ? webRequest.chargeable ? "Ano" : "Ne" : ""}</span>
											</div>
										</div>

										<div className="row">
											<div className="col-4">
												<span className="box-profile__label">Stav OSK</span>
											</div>
											<div className="col-8">
												<span className="box-profile__value">{webRequest?.regional_chamber_state?.name}</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="web-request__form-switch secondary-title">
								{!props.model.formShown() &&
									<span onClick={props.model.toggleForm}>
										Zobrazit formulář <IconSvg name="arrow-down" className="ml-2" />
									</span>
								}
								{props.model.formShown() &&
									<span onClick={props.model.toggleForm}>
										Skrýt formulář <IconSvg name="arrow-up" className="ml-2" />
									</span>
								}
							</div>

							<SlideDown open={props.model.formShown()}>
								<div style={{ pointerEvents: "none" }} className="pt-4">
									{props.children}
								</div>
							</SlideDown>
							{props.id != apiLib.emptyGuid &&
								<div>
									<h1 className="d-flex align-items-center">
										Dodatečné dokumenty
									</h1>
									<Button className="mb-3 " theme="primary" spinner={authorization.getJustSendingWebRequest()} onClick={handleOpenUpload}>
										Nahrát soubor
									</Button>
									<input
										className="d-none"
										type="file"
										multiple
										onChange={handleUpload}
										ref={inputRef}>
									</input>
									<List
										list={authorization.webRequestList}
										itemNode={(item, field) => {
											switch (field) {
												case "last_change": return formatting.formatDate(item.last_change);
												case "action" as any: return <Button theme="primary" onClick={() => api.download("/dms_files/" + item.id + "/download", item.name)} className="mr-2 btn-sm p-2">Stáhnout</Button>;
												default: return undefined;
											}
										}}
									/>
								</div>
							}
						</>
					}

					{!props.id &&
						<>{props.children}</>
					}
				</>
			}
		</Page>
	);
}