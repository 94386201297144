import React, { useEffect } from "react";
import { BrowserRouter, StaticRouter, Route, Switch, withRouter, RouteComponentProps } from "react-router-dom";

import * as state from "./lib/state";
import * as api from "./lib/api";
import storage from "store";
import * as common from "./lib/common";
import * as navigation from "./lib/navigation";
import * as routing from "./lib/routing";
import AppContext, { StateContext } from "./context";

import Page404 from "./modules/pages/page404/Page404";
import InformationDialog from "./modules/shared/dialogs/InformationDialog";
import YesNoDialog from "./modules/shared/dialogs/YesNoDialog";
import ResourceDialog from "./modules/shared/dialogs/ResourceDialog";
import ChangeStateDialog from "./modules/shared/dialogs/ChangeStateDialog";
import Request1Dialog from "./modules/shared/dialogs/Request1Dialog";
import SearchDialog from "./modules/shared/dialogs/SearchDialog";
import DynamicDetailDialog from "./modules/shared/dialogs/DynamicDetailDialog";
import DmsDialog from "./modules/shared/dialogs/DmsDialog";

// Komponenta pro odchycení a registraci history objektu pro práci s HTML5 history API
const HistoryRegistration = withRouter(class extends React.Component<RouteComponentProps> {
	constructor(props: RouteComponentProps) {
		super(props);
	}

	componentDidMount() {
		navigation.registerHistory(this.props.history as any);
	}

	render() {
		return null;
	}
});

// Komponenta pro "odskrolování nahoru" při aktivaci stránky v react routeru
const ScrollToTop = withRouter(class extends React.Component<RouteComponentProps> {
	componentDidUpdate(prevProps: RouteComponentProps) {
		if (this.props.history.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}
	render() {
		return this.props.children;
	}
});

// Komponenta pro načtení dat modulu
const LoadDataTriggerComponent = withRouter(class extends React.Component<RouteComponentProps & { routes: routing.Route[] }> {
	componentDidMount = async () => {
		await routing.loadData(window.location.pathname, this.props.routes, true);
	}

	componentDidUpdate = async (prevProps: RouteComponentProps) => {
		if (this.props.history.location.pathname !== prevProps.location.pathname) {
			await routing.loadData(window.location.pathname, this.props.routes, false);
		}
	}

	render = () => null;
});

/**
 * Komponenta pro reakci na změnu property
 */
interface WatcherProps {
	watchExpression: boolean;
	onChange: (expressionValue: boolean) => void;
}

class Watcher extends React.Component<WatcherProps> {
	componentDidUpdate = (prevProps: WatcherProps) => {
		if (prevProps.watchExpression !== this.props.watchExpression) {
			this.props.onChange(this.props.watchExpression);
		}
	}
	render = () => null;
}

/**
 * Abstrakce routeru pro clienta a server
 */
function Router(props: any) {
	return common.serverExecution()
		? <StaticRouter location={props.url}>{props.children}</StaticRouter>
		: <BrowserRouter>{props.children}</BrowserRouter>;
}

interface AppProps {
	url?: string;
	context: StateContext;
	routes: routing.Route[];
}

function AppWithStateContext(props: AppProps) {
	useEffect(() => {
		function createPartialState(state: Partial<any>) {

			return state;
		}
		async function logout() {
			const isLogout = api.getUrlParam("logout");
			const token = storage.get("authToken");
			if (isLogout && token) {
				navigation.to("/")
				storage.remove("authToken");
				storage.remove("section");

				await authorization.stateContainer.merge(() => createPartialState({
					userProfile: undefined,
					userLoggedIn: false,
					currentSection: undefined
				}));
			}
		}
		logout();
	}, []);
	const stateContext = state.useStateContext();
	const { authorization } = stateContext;

	async function onAutorizationChanged() {
		await routing.loadData(window.location.pathname, props.routes);
	}

	return (
		<Router url={props.url}>
			<>
				<Watcher watchExpression={authorization.userLoggedIn()} onChange={onAutorizationChanged} />
				<InformationDialog />
				<ResourceDialog />
				<ChangeStateDialog />
				<YesNoDialog />
				<SearchDialog />
				<HistoryRegistration />
				<Request1Dialog />
				<DynamicDetailDialog />
				<DmsDialog />
				<LoadDataTriggerComponent routes={props.routes} />
				<ScrollToTop>
					<Switch>
						{props.routes.map((i, index) => <Route
							key={index}
							exact
							path={i.route}
							component={i.component}
							render={i.render}
						/>)}
						{props.routes.map((i, index) => i.aliases?.map(a => <Route
							key={index}
							exact
							path={a}
							component={i.component}
							render={i.render}
						/>))}

						{/* Fallback pro neexistující stránky */}
						<Route component={Page404} />
					</Switch>
				</ScrollToTop>
			</>
		</Router>
	);
}

const AppWithStateContextBound = state.bindContainers(
	AppWithStateContext,
	c => ({
		getStateContainers: () => {
			return [
				c.dialogs.stateContainer,
				c.authorization.stateContainer,
				c.shopCart.stateContainer
			];
		}
	})
);

export default function App(props: AppProps) {
	return (
		<AppContext.Provider value={props.context}>
			<AppWithStateContextBound {...props} />
		</AppContext.Provider>
	);
}