/**
 * Domovská stránka
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Tile from "../../../components/controls/Tile";
import Spinner from "../../../components/controls/Spinner";

function OskPage() {
	const context = state.useStateContext();
	const statistics = context.osk.getStatisctics();
	const justLoading = context.osk.justLoadingStatisctics();

	return (
		<Page title="OSK" description="" authorized>
			<div className="d-flex align-items-center">
				{justLoading &&
					<>
						<Spinner small className="mr-2" /> Načítám statistiku...
					</>
				}
				&nbsp;
			</div>

			<h2 className="h1">Seznam členů</h2>
			<div className="d-flex flex-wrap mb-5">
				<Tile number={statistics?.number_of_total_members} text="Počet členů" link="/osk/seznam-clenu-komory" />
				<Tile number={statistics?.number_of_visiting_members} text="Počet hostujících členů" link="/osk/seznam-clenu-komory?hostujici=true" />
				<Tile number={statistics?.number_of_new_members} text="Nových členů / měsíc" link="/osk/seznam-clenu-komory" emphasiz />
				<Tile number={statistics?.number_of_members_osk_plus} text="Počet OSK+" link="/osk/seznam-clenu-komory?oskplus=true" />
			</div>

			<h2 className="h1">Žádosti</h2>
			<div className="d-flex flex-wrap mb-5">
				<Tile number={statistics?.number_of_total_requests} text="Žádostí celkem" link="/osk/zadosti" />
				<Tile number={statistics?.number_of_new_requests} text="Nové žádosti" emphasiz link="/osk/zadosti" />
			</div>

			<h2 className="h1">Kategorie</h2>
			<div className="d-flex flex-wrap mb-5">
				<Tile number={statistics?.number_of_category_p} text="Kategorie P" link="/osk/seznam-clenu-komory" />
				<Tile number={statistics?.number_of_category_a} text="Kategorie A" link="/osk/seznam-clenu-komory" />
				<Tile number={statistics?.number_of_category_n} text="Kategorie N" link="/osk/seznam-clenu-komory" />
			</div>

			<h2 className="h1">Zprávy</h2>
			<div className="d-flex flex-wrap mb-5">
				<Tile number={statistics?.number_of_total_messages} text="Zpráv celkem" link="/osk/zpravy" />
				<Tile number={statistics?.number_of_new_messages} text="Nové zprávy" emphasiz link="/osk/zpravy" />
			</div>

			<h2 className="h1">Dokumenty</h2>
			<div className="d-flex flex-wrap mb-5">
				<Tile number={statistics?.number_of_total_documents} text="Dokumentů celkem" link="/osk/materialy-ze-skoleni" />
				<Tile number={statistics?.number_of_new_documents} text="Nové dokumenty" emphasiz link="/osk/materialy-ze-skoleni" />
			</div>

		</Page >
	);
}

export default state.bindContainers(
	OskPage,
	context => context.osk
);