import * as dialogs from "./dialogs";
import * as context from "../../../context";

interface DynamicDetail {
	item: any;
	customReload?: any;
}

export class Model {
	dialog: dialogs.Dialog<undefined, DynamicDetail>;

	constructor(private context: context.StateContext) {
		this.dialog = new dialogs.Dialog<undefined, DynamicDetail>({
			defaultCustomFields: {
				item: {
					text: ""
				},
			}
		}, context);
	}
	openDialog = async (item: any, reload: any, customReload?: () => void) => {
		await this.dialog.setCustomField("item", item);
		await this.dialog.setCustomField("customReload", customReload);

		await this.dialog.open();
		reload();
	}

	save = async () => {
		const detail = this.dialog.getCustomFields();
		const result = await this.context.api.put("/dynamic_lists/" + detail.item.id, detail.item);
		if (result) {
			await this.context.contents.loadContents();
			await this.context.dynamicDetail.dialog.close();
			await this.context.standardDialogs.openInformationDialog("Detail byl úspěšně uložen.");

		}
		if (detail.customReload && detail.customReload()) {
			detail.customReload()();
		}
	}
}