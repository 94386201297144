import * as React from "react";

export interface EnterPressAreaProps {
	onEnter?: () => void;
	children?: React.ReactNode;
}

export default function EnterPressArea(props: EnterPressAreaProps) {
	function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
		if (e.key === "Enter" && props.onEnter) {
			props.onEnter();
		}
	}

	return (
		<div onKeyDown={handleKeyDown}>
			{props.children}
		</ div>
	);
}