/**
 * Jednání představenstva
 */

import React, { useState } from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";

import Spinner from "../../../components/controls/Spinner";
import Link from "../../../components/controls/Link";
import CardExpandable from "../../../components/controls/CardExpandable";
import HtmlContent from "../../../components/controls/HtmlContent";

type ClickableExpandableCard = {
	className?: string;
	cardTitle?: React.ReactNode;
	children?: React.ReactNode;
};

function ClickableExpandableCard(props: ClickableExpandableCard) {
	let [open, setOpen] = useState(false);

	return (
		<CardExpandable onToggle={() => setOpen(!open)} cardTitle={props.cardTitle ?? ""} open={open} className={props.className ?? ""}>
			{props.children}
		</CardExpandable>
	);
}

function AgendaDentalPracticePage() {
	const model = state.useStateContext().dentalPractice;

	const practice = model.getPracticeData();
	const finance = model.getFinanceData();
	const corona = model.getCoronavirusData();

	return (
		<Page title="Jednání představenstva" description="Jednání představenstva">
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="provozni-zubni-praxe-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<p>Odpovědi na otázky týkající se provozování zubní praxe nově přináší také <Link to={"/komornik"}>KOMORNÍK</Link>.</p>

			<ClickableExpandableCard cardTitle={<h2 className="mt-0 mb-0">Financování zubní praxe</h2>} className="mb-3">
				<div className="mt-4">
					{finance.map(d =>
						<div className="mb-2">
							<Link className="mb-3" href={"/agendy-komory/financovani-zubni-praxe/" + d.id}>
								{d.title}
							</Link>
						</div>
					)}
				</div>
			</ClickableExpandableCard>

			<ClickableExpandableCard cardTitle={<h2 className="mt-0 mb-0">Provozování zubní praxe</h2>} className="mb-3">
				<div className="mt-4">
					{practice.map(d =>
						<div className="mb-2">
							<Link className="mb-3" href={"/agendy-komory/provozovani-zubni-praxe/" + d.id}>
								{d.title}
							</Link>
						</div>
					)}
				</div>
			</ClickableExpandableCard>

			<ClickableExpandableCard cardTitle={<h2 className="mt-0 mb-0">Vše o koronaviru</h2>} className="mb-3">
				<div className="mt-4">
					{corona.map(d =>
						<div className="mb-2">
							<Link className="mb-3" href={"/agendy-komory/vse-o-koronaviru/" + d.id}>
								{d.title}
							</Link>
						</div>
					)}
				</div>
			</ClickableExpandableCard>
		</Page>
	);
}

export default state.bindContainers(
	AgendaDentalPracticePage,
	c => c.dentalPractice
);