/**
 * volby-clenu-cestne-rady
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function HonoraryCouncilMembersElectionPage() {
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("volby-clenu-cestne-rady-nadpis")} description="">
			<HtmlContent htmlKey="volby-clenu-cestne-rady-obsah" />
		</Page >
	);
}