import * as React from "react";
import { Tooltip } from "react-tippy";

import * as forms from "../../../lib/forms";

import QuestionMarkIcon from "../../icons/QuestionMarkIcon";

export interface FormItemOptions {
	hideTitle?: boolean;
	hideRequired?: boolean;
	hint?: React.ReactNode;
}

export interface FormItemProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof TFormFields;
	className?: string;
	children?: React.ReactNode;
	options?: FormItemOptions;
}

export default function FormItem<TFormFields>(props: FormItemProps<TFormFields>) {
	const fieldOptions = props.form.getFieldOptions(props.field);
	const title = typeof fieldOptions.title === "function" ? fieldOptions.title(props.form) : fieldOptions.title;

	return (
		<div className={"form-item" + ((props.form.getField(props.field).validation).length > 0 ? " form-item--invalid" : "") + " " + (props.className ?? "")}>
			{props.options?.hideTitle !== true &&
				<div className="form-item__label">
					<div>
						<label>
							{title}
						</label>

						{props.form.isFieldRequired(props.field) && props.options?.hideRequired !== true &&
							<span className="form-item__required ml-1">*</span>
						}
					</div>

					{fieldOptions.hint && !props.options?.hint &&
						<Tooltip
							className="form-item__hint-tooltip"
							arrow
							position="top"
							theme={"gray" as any}
							html={fieldOptions.hint as any}
							trigger="mouseenter">
							<QuestionMarkIcon className="form-item__hint-icon" />
						</Tooltip>
					}

					{props.options?.hint &&
						<div>
							{props.options.hint}
						</div>
					}
				</div>
			}
			<Tooltip
				className="d-block form-item__tooltip"
				arrow
				position="bottom-start"
				title={props.form.getField(props.field).validation}
				trigger="mouseenter"
				disabled={props.form.getField(props.field).validation === ""}
			>
				{props.children}
			</Tooltip>
		</div>
	);
}