/**
 *  Formulář zadost-o-jednorazovy-vzdelavaci-program-snimatelna-protetika
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";
import HtmlContent from "../../../components/controls/HtmlContent";

function WebRequestDisposableEducationalProgramRemovableProsthetics(props: any) {
	const title = "ŽÁDOST O ZAŘAZENÍ DO JEDNORÁZOVÉHO VZDĚLÁVACÍHO PROGRAMU - SNÍMATELNÁ PROTETIKA";
	const { webRequestDisposableEducationalProgramRemovableProsthetics: model } = state.useStateContext();

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
		>
			<div className="text-right">
				<HtmlContent htmlKey="zadost-o-jednorazovy-vzdelavaci-program-snimatelna-protetika-obsah" />
				<div className="text-center font-weight-bold text-danger">
					Litujeme, ale přijímání žádostí o zařazení do jednorázového vzdělávacího programu Snímatelná protetika bylo ukončeno.
				</div>
				{/* <Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button> */}
			</div>
		</WebRequest >
	);
}

export default state.bindContainers(
	WebRequestDisposableEducationalProgramRemovableProsthetics,
	c => c.webRequestDisposableEducationalProgramRemovableProsthetics
);