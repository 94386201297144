/**
 *  Formulář žádosti o vydání potvrzení o členství a bezúhonnosti v ČSK
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import Button from "../../../components/controls/Button";

interface WebRequestMembersConfirmationPageProps {
	id?: string;
}

function WebRequestMembersConfirmationPage(props: WebRequestMembersConfirmationPageProps) {
	const title = "ŽÁDOST O VYDÁNÍ POTVRZENÍ O ČLENSTVÍ A BEZÚHONNOSTI V ČSK";
	const { webRequestMembersConfirmation: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p className="font-weight-bold">
				Žádám o vystavení potvrzení o členství a bezúhonnosti v České stomatologické komoře:
			</p>

			<strong>Potvrzení žádám vystavit v</strong> (lze označit více možností):
			<CheckBoxRadioFrame className="mb-5">
				<FormCheckBox form={form} field="lang_czech" />
				<FormCheckBox form={form} field="lang_english" />
				<FormCheckBox form={form} field="lang_german" />
			</CheckBoxRadioFrame>

			<p>
				Beru na vědomí, že tato žádost je zpoplatněna dle aktuálního usnesení sněmu ČSK ve výši {form.getField("amount").value} Kč za každou zvolenou jazykovou variantu potvrzení.
			</p>

			{!props.id &&
				<div className="text-right">
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestMembersConfirmationPage,
	c => c.webRequestMembersConfirmation
);