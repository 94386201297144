/*
 * Zavaděč aplikace do browseru
 */

import * as React from "react";
import * as ReactDOM from "react-dom";
import * as smoothscroll from "smoothscroll-polyfill";
import appinit from "../app/init";
import createRoutes from "../app/routes";
import App from "../app/App";
import { StateContext } from "../app/context";

smoothscroll.polyfill();
appinit();

document.addEventListener("DOMContentLoaded", async () => {
	const renderMethod = (window as any).developmentMode ? ReactDOM.render : ReactDOM.hydrate;
	const stateContext = new StateContext(window.location.hostname);
	const routes = createRoutes(stateContext, window.location.hostname);

	renderMethod(
		React.createElement(App, { context: stateContext, routes: routes }),
		document.getElementById("app")
	);
});