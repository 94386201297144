/**
 *  Formulář oznámení o přerušení výkonu povolání
 */

import React from "react";

import * as state from "../../../lib/state";
import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";
import FormFileUpload from "../../system/file/FormFileUpload";
import IconSvg from "../../../components/controls/IconSvg";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";
import HtmlContent from "../../../components/controls/HtmlContent";
import FormText from "../../../components/controls/FormText";
import FormsInput from "../../../components/controls/Forms/FormsInput";

interface Props {
	id?: string;
}

function WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023(props: Props) {
	const title = "Žádost o vykonání profesní zkoušky v rámci vstupního vzdělávacího programu v praktickém zubním lékařství (žadatel s 10letou praxí – platné od 1. 1. 2023)";
	const { webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023: model } = state.useStateContext();

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p>
				<div>Místo konání profesních zkoušek: <span className="font-weight-bold">ČSK, Vzdělávací středisko, Slavojova 22, 128 00 Praha 2.</span></div>
				<div>Termín zkoušky Vám Kancelář Komory přidělí s ohledem na aktuální nabídku dostupných termínů.</div>
			</p>

			<div className="font-weight-bold d-inline">ČESTNÉ PROHLÁŠENÍ ŽADATELE</div> <span className="form-item__required">*</span>
			<div>
				<FormsCheckBox
					form={model.form}
					field="confirmed_declaration"
					className="web-request-repeated-exam-in-vp__declaration"
					enhancedTitle={
						<>
							Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti, jsou pravdivé a úplné. Čestně prohlašuji, že jsem před zařazením do vzdělávacího programu v praktickém zubním lékařství vykonával/a povolání zubního lékaře alespoň v rozsahu preventivní, diagnostické a léčebné činnosti v oblasti péče o zuby, ústa, čelisti a související tkáně, a to souvisle po dobu nejméně 10 let. Čestně prohlašuji, že jsem členem Komory, že jsem způsobilý/á k samostatnému výkonu povolání zubního lékaře, že mi nebylo pravomocně uloženo disciplinární opatření anebo že takové disciplinární opatření již bylo zahlazeno, že jsem nebyl/a pravomocně odsouzen/a za trestný čin spáchaný v souvislosti s výkonem povolání zubního lékaře anebo že se na mě hledí, jako bych nebyl/a odsouzen/a, a že nemám vůči Komoře nedoplatek na stanovených příspěvcích či poplatcích nebo na pokutě uložené pravomocným rozhodnutím v disciplinárním řízení.
							<br />
						</>
					} />
			</div>
			<FormsCheckBox form={model.form} field="confirmed_children_care" className="mb-5" />

			{model.form.getField("confirmed_children_care").value &&
				<div className="mb-5">
					{
						model.formChildren.get().map((i, order) =>
							<div className="row">
								<div className="col-md-2">
									<FormText>Doba péče o {order + 1}. dítě</FormText>
								</div>
								<div className="col-md-3">
									<FormsInput form={i} field="care_from" inputProps={{ type: "date" }} />
								</div>
								<div className="col-md-3">
									<FormsInput form={i} field="care_to" inputProps={{ type: "date" }} />

								</div>
								<div className="col-md-4">
									{order !== 0 &&
										<Button className="mr-3 mb-3" onClick={_ => model.formChildren.remove(i)}>Odebrat</Button>
									}

									{order + 1 === model.formChildren.get().length &&
										<Button onClick={model.formChildren.add} className="mt-4">Přidat</Button>
									}
								</div>
							</div>
						)
					}
					<p>
						<strong>Poučení o péči o dítě:</strong><br />
						<em>V rámci vstupního vzdělávacího programu v praktickém zubním lékařství se do lhůt
							pro přihlášení k profesní zkoušce a pro absolvování předepsaných vzdělávacích akcí
							nezapočítává doba, po kterou člen Komory soustavně osobně pečoval o dítě do 4 let věku.
							Pečují-li o totéž dítě dva členové Komory, považuje se za člena Komory pečujícího o dítě do 4
							let pouze jeden z nich, a to podle jejich volby.
						</em>
					</p>
				</div>
			}
			<span className="font-weight-bold">Přílohy</span>
			<ol className="about-us-list-bullets">
				<li className="text-decoration-underline">
					doklady prokazující souvislý výkon povolání zubního lékaře mimo území ČR (není-li doklad vyhotoven v českém nebo slovenském jazyce, nutno doplnit překladem do českého jazyka, není nutný úřední překlad)
				</li>
			</ol>
			{model.formsFiles.get().map((formFile) =>
				<div className="row pl-0">
					<div className="col-8 col-lg-10">
						<FormFileUpload
							fileUploadProps={{
								className: "mb-4"
							}}
							form={formFile}
							field="attachment"
						/>
					</div>
					<div className="col-4 col-lg-2">
						<Button className="d-block w-100 mt-4" onClick={() => model.removeFile(formFile)}>Odebrat</Button>
					</div>
				</div>
			)}

			<Button onClick={model.addFile} className="mb-5"><IconSvg name="plus" className="mr-3" /> Připojit další soubor</Button>
			<HtmlContent htmlKey="zadost-o-vykonani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl-pouceni-2023" />

			{!props.id &&
				<div className="text-right">
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}

export default state.bindContainers(
	WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023,
	c => c.webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl2023
);