/**
 * Model žádosti o osvědčení po absolvování celoživotního vzdělávání
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";

export interface FileFormFields {
	attachment?: apiFile.File;
}

export interface FormFields {
	program_type: "vppzl" | "vpparo" | "vpchir" | "vppedo";
	description: string;
	amount: number;
	affidavit: boolean;
}

interface FormData extends FormFields {
	attachments: FileFormFields[];
}

const affidavit = "Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti, jsou pravdivé a úplné. Čestně prohlašuji, že jsem nejméně po dobu 2 let před podáním této žádosti vykonával/a povolání zubního lékaře alespoň v rozsahu preventivní, diagnostické a léčebné činnosti v oblasti péče o zuby, ústa, čelisti a související tkáně. Čestně prohlašuji, že jsem členem Komory, že jsem způsobilý/á k samostatnému výkonu povolání zubního lékaře, že mi nebylo pravomocně uloženo disciplinární opatření anebo že takové disciplinární opatření již bylo zahlazeno, že jsem nebyl/a pravomocně odsouzen/a za trestný čin spáchaný v souvislosti s výkonem povolání zubního lékaře anebo že se na mě hledí, jako bych nebyl/a odsouzen/a, a že nemám vůči Komoře nedoplatek na stanovených příspěvcích či poplatcích nebo na pokutě uložené pravomocným rozhodnutím v disciplinárním řízení. Čestně prohlašuji, že jsem v posledních nejvýše 5 letech před po-dáním této žádosti vykonával/a soustavnou činnost praktického zubního lékaře v protetice (fixní i snímatelné zubní náhrady), záchovné stomatologii, chirurgii, parodontologii a pedostomatologii.";

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public formsFiles: forms.FormCollection<FileFormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {

		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				program_type: {
					title: "Požadovaný typ osvědčení",
					defaultValue: "vppzl",
					onChange: () => this.loadAmount()
				},
				description: {
					title: "Popis",
					defaultValue: "",
					required: true,
					validate: value => !value ? "Popis je povinné pole" : "",
				},
				affidavit: {
					title: affidavit,
					defaultValue: false,
					required: true,
					validate: value => !value ? "Prohlášení je povinné pole" : "",
				},
				amount: {
					title: "",
					defaultValue: 0
				}
			}
		}, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Připojit soubor",
					defaultValue: undefined
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		...this.common.getStateContainers(),
		...this.form.getStateContainers()
	]

	loadAmount = async () => {
		const amount = await this.context.api.get<number>("/request_types/" + this.getCode() + "/price/", {});
		await this.form.setField("amount", amount);
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			await this.common.send(data, this.getCode() as any);
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	getCode = () => {
		let code = "zadost-o-vydani-osvedceni-po-celozivotnim-studiu-vppzl";
		switch (this.form.getField("program_type").value) {
			case "vppzl":
				code = "zadost-o-vydani-osvedceni-po-celozivotnim-studiu-vppzl";
				break;
			case "vpchir":
				code = "zadost-o-vydani-osvedceni-po-celozivotnim-studiu-vpchir";
				break;
			case "vpparo":
				code = "zadost-o-vydani-osvedceni-po-celozivotnim-studiu-vpparo";
				break;
			case "vppedo":
				code = "zadost-o-vydani-osvedceni-po-celozivotnim-studiu-vppedo";
				break;
		}
		return code;
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { attachments, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.formsFiles.setFields(attachments),
				this.loadAmount()
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadAmount()
		]);
	}
}