/**
 * Stránka košíku e-shopu
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Link from "../../../components/controls/Link";
import Spinner from "../../../components/controls/Spinner";
import Button from "../../../components/controls/Button";
import Input from "../../../components/controls/Input";
import RemoveIcon from "../../../components/icons/RemoveIcon";
import ShopingCartIcon from "../../../components/icons/ShopingCartIcon";

export default function BooksCartPage() {
	const { shopCart: model } = state.useStateContext();
	const cart = model.getCart();
	const justLoading = model.justLoadingCart();
	const title = "Košík";

	return (
		<Page title={title} description="">
			<h1 className="d-flex align-items-center mb-5">
				{justLoading &&
					<>
						<Spinner small className="ml-1" /> Načítám košík...
					</>
				}
				{!justLoading &&
					<><ShopingCartIcon className="books-cart-page__cart-icon mr-2" /> {title}</>
				}
			</h1>

			{cart.items.length > 0 &&
				<>
					<div className="table-wrap mb-5">
						<table className="table-designed books-cart-page__table-items">
							<thead>
								<tr>
									<th>Kniha</th>
									<th className="text-right" style={{ width: "8rem" }}>Cena</th>
									<th className="text-right">Množství</th>
									<th className="text-right">Mezisoučet</th>
								</tr>
							</thead>
							<tbody>
								{cart.items.map(i =>
									<tr>
										<td>
											<div className="d-flex align-items-center">
												<div className="flex-shrink-0">
													<span onClick={_ => model.removeItem(i)}>
														<RemoveIcon className="books-cart-page__remove-icon mr-3" />
													</span>
													<Link noStyle to={"/vzdelavani/knihy/" + i.shop_item.id}>
														<img src={i.shop_item.image_id} className="books-cart-page__item-image mr-3" />
													</Link>
												</div>
												<div>
													<Link to={"/vzdelavani/knihy/" + i.shop_item.id}>
														{i.shop_item.name}
													</Link>
												</div>
											</div>
										</td>
										<td className="text-right">{formatting.formatCurrency(i.shop_item.price)}</td>
										<td width={150} className="text-right">
											<Input
												className="mb-0"
												type="number"
												value={i.count}
												onChange={value => model.updateItemCount(i, value)}
												onFocus={e => e.target.select()}
											/>
										</td>
										<td className="text-right">{formatting.formatCurrency(i.shop_item.price * i.count)}</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
					<div className="text-right">
						<h2>Celkem k platbě</h2>
						<table className="books-cart-page__table-sum mb-4">
							<tr>
								<th>Mezisoučet</th>
								<td className="text-right pl-3" style={{ width: "7rem" }}>{formatting.formatCurrency(cart.sum)}</td>
							</tr>
							<tr>
								<th className="pb-3" style={{ width: "20rem" }}>
									Doprava a dobírka<br />
									<small>Možnosti přepravy budou aktualizovány na stránce pokladny</small>
								</th>
								<td className="text-right pl-3">
									<div>
										{formatting.formatCurrency(cart.transport_price)}
									</div>
								</td>
							</tr>
							<tr>
								<th className="text-uppercase font-weight-bold">Cena celkem</th>
								<td className="text-right pl-3"><strong>{formatting.formatCurrency(cart.total_price)}</strong></td>
							</tr>
						</table>
						<div className="text-right">
							<Button className="mr-2" onClick={_ => history.back()}>&lt; Zpět</Button>
							{cart.items.length > 0 &&
								<Link noStyle to="/vzdelavani/knihy/pokladna"><Button theme="primary" className="mb-5">Přejít k pokladně</Button></Link>
							}
						</div>
					</div>
				</>
			}

			{cart.items.length === 0 &&
				<p className="mb-5 text-center">
					<strong>Váš košík je prázdný</strong>
				</p>
			}
		</Page >
	);
}