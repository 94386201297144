/**
 * Domovská stránka
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function ContactsOskPage() {
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("kliniky-nadpis")} description="">
			<HtmlContent htmlKey="kliniky" />
		</Page >
	);
}