import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiMessages from "../../shared/messages/api-messages";
import * as apiDocuments from "../../shared/documents/api-documents";

export interface UserSimple extends api.EntityReadBase {
	id: api.Id;
	full_name: string;
}

export class Api {
	messages: api.EntityApi<apiMessages.Message, apiMessages.Message>;
	documents: api.EntityApi<apiDocuments.DmsFile, apiDocuments.DmsFile>;
	documentsSnem: api.EntityApi<apiDocuments.DmsFile, apiDocuments.DmsFile>;

	constructor(private context: context.StateContext) {
		this.messages = new api.EntityApi<apiMessages.Message, apiMessages.Message>({
			api: context.api,
			path: "/users/messages"
		});
		this.documents = new api.EntityApi<apiDocuments.DmsFile, apiDocuments.DmsFile>({
			api: context.api,
			path: "/users/documents"
		});
		this.documentsSnem = new api.EntityApi<apiDocuments.DmsFile, apiDocuments.DmsFile>({
			api: context.api,
			path: "/dms_files_snem"
		});
	}

	loadUserCertifications = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/users/certifications", { per_page: Number.MAX_SAFE_INTEGER });
	}

	changePassword = (newPasssword: string, oldPassword: string) => {
		return this.context.api.post("/auth/change_password", { new_password: newPasssword, old_password: oldPassword });
	}
}