/**
 * Seznam orgánů OSK
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiAddress from "../../shared/address/api-address";
import * as apiContact from "../../shared/contact/api-contact";
import * as apiRegionalChambers from "../../shared/regional-chambers/api-regional-chambers";
import * as apiWorkplace from "../../shared/workplace/api-workplace";

interface Profile {
	identification_number: string;
	x_title_after: string;
	x_title_before: string;
	x_title_pedagogical: string;
	first_name: string;
	last_name: string;
	middle_name: string;
	member_category?: api.CodeBookItem;
	regional_chamber_main?: apiRegionalChambers.RegionalChamberSimple;
	visiting_regional_chambers: api.CodeBookItem[];
	address?: apiAddress.Address;
	delivery_address?: apiAddress.Address;
	contact?: apiContact.Contact;
	birthdate?: Date;
	child_birthdate?: Date;
	gender?: api.CodeBookItem;
	nationality?: api.CodeBookItem;
	validity_of_the_residence_permit?: Date;
	reason_for_termination_of_membership: string;
	membership_from?: Date;
	membership_to?: Date;
	send_bulk_emails: boolean;
	send_monitoring: boolean;
	journal_subscribe: boolean;
	journal_address: api.CodeBookItemWithNumberId;
	school: string;
	decision_of_the_ministry_of_health_on_eligibility: string;
	date_of_graduation: Date;
	certification_first: string;
	certification_second: string;
	diploma_number: string;
	graduation_place: string;
	professional_specializations: string;
	specialization_field: string;
	workplace_main: apiWorkplace.Workplace;
	date_of_last_confirmation: Date;
}

/**
 * Stav modulu
 */
interface State {
	profile?: Profile;
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			profile: undefined,
			justLoading: false
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	justLoading = () => {
		return this.stateContainer.get().justLoading;
	}

	/**
	 * Načte data
	 */
	loadData = async (id: string) => {
		await this.stateContainer.merge(prevState => ({
			justLoading: true
		}));
		const profile = await this.context.api.get<Profile>("/osk/members/" + id, {});
		await this.stateContainer.merge(prevState => ({
			profile: profile,
			justLoading: false
		}));
	}
}