/**
 * Partneři
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function PartnersPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("partneri-nadpis")} description={contents.getRes("partneri-nadpis")}>
			<HtmlContent htmlKey="partneri-obsah" />
		</Page>
	);
}