import * as React from "react";

export type TextAreaProps = Omit<React.HTMLProps<HTMLTextAreaElement>, "onChange"> & {
	onChange?: (value: string) => void;
};

export default function TextArea(props: TextAreaProps) {
	function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	}

	const propsToDelegate = {
		...props
	};

	delete propsToDelegate.onChange;

	return (
		<textarea
			{...propsToDelegate}
			onChange={handleChange}
			className={"textarea " + (props.className ?? "")}
		/>
	);
}