/**
 * Obsah
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

const PAGE_SIZE = 20;

/**
 * Stav modelu
 */
interface State {
	contents: api.ListResponse<apiDynamicLists.Item>;
	fulltext: string;
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			contents: {
				data: [],
				pagination: {
					per_page: PAGE_SIZE,
					object_count: 0,
					page: 1
				},
				message: null
			},
			justLoading: false,
			fulltext: ""
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Indikace načítání seznamu
	 */
	justLoading = () => this.stateContainer.get().justLoading;

	fulltext = () => this.stateContainer.get().fulltext;

	/**
	 * Načte data
	 */
	loadData = async (fulltext: string, page: number) => {
		const text = this.context.standardDialogs.searchDialog.getCustomField("fulltext");
		const contents = await this.context.api.post("/dynamic_lists/search", { fulltext: text ? text : fulltext, page: page }) as any;
		await this.stateContainer.merge(_ => ({
			contents: contents,
			fulltext: text ? text : fulltext
		}));
	}

	/**
	 * Vrací seznam obsahů
	 */
	getContents = () => {
		return this.stateContainer.get().contents;
	}
}