import * as React from "react";

export default function HomePageLogo() {
	return (
		<svg version="1.0" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			width="211px" height="158px" viewBox="0 0 211 158" enable-background="new 0 0 211 158" xmlSpace="preserve">
			<g>
				<path fill="#FFFFFF" d="M137.759,42.176v-4.699l3.175-6.151h-2.188l-2.015,3.903l-2.024-3.903h-2.217l3.189,6.151v4.699H137.759z
		 M47.431,129.617H43.87v5.978h3.561v21.692h6.943v-21.692h4.12v-5.978h-4.12V119.42h-6.943V129.617z M18.972,139.993
		c0.781-1.712,1.631-2.906,2.548-3.586c1.46-1.084,3.159-1.627,5.095-1.627c1.782,0,3.349,0.483,4.699,1.449
		c1.351,0.967,2.256,2.222,2.714,3.764H18.972z M16.034,133.09c-2.72,2.789-4.081,6.294-4.081,10.516
		c0,4.12,1.364,7.549,4.094,10.287S22.312,158,26.652,158c2.798,0,5.214-0.495,7.249-1.487c2.034-0.992,3.781-2.522,5.239-4.591
		l-5.85-2.746c-1.865,1.813-4.094,2.721-6.688,2.721c-2.17,0-3.955-0.581-5.354-1.742c-1.398-1.162-2.259-2.726-2.581-4.692h22.304
		l0.026-1.322c0-4.578-1.365-8.261-4.095-11.05s-6.273-4.185-10.631-4.185C22.168,128.905,18.756,130.301,16.034,133.09
		 M6.943,118.936H0v38.352h6.943V118.936z M85.638,105.905c0-9.882,0.667-17.388,2.005-22.514c1.335-5.127,3.27-8.74,5.805-10.842
		c2.536-2.101,5.439-3.152,8.712-3.152c4.793,0,8.698,2.54,11.717,7.621c3.018,5.081,4.528,14.316,4.528,27.709
		c0,13.209-1.683,22.63-5.047,28.264c-2.766,4.665-6.498,6.997-11.198,6.997c-4.886,0-8.861-2.459-11.925-7.378
		C87.169,127.692,85.638,118.791,85.638,105.905 M74.9,64.826c-1.817,2.646-3.348,5.803-4.593,9.469
		c-0.069-6.077-1.807-11.368-5.224-15.872c-3.488-4.595-8.324-7.816-14.513-9.664c4.711-2.309,8.302-5.334,10.773-9.074
		c2.47-3.742,3.706-7.874,3.706-12.4c0-7.298-2.83-13.602-8.487-18.913c-5.657-5.31-12.666-7.966-21.024-7.966
		c-9.377,0-17.158,3.186-23.346,9.559c-4.433,4.573-7.135,10.624-8.105,18.151h18.635c0.831-2.678,2.378-4.86,4.641-6.547
		c2.262-1.685,4.78-2.529,7.551-2.529c3.141,0,5.68,0.913,7.62,2.737c1.94,1.825,2.91,4.122,2.91,6.893
		c0,2.125-0.635,4.133-1.905,6.026c-1.271,1.895-3.048,3.385-5.334,4.469c-2.287,1.085-5.762,1.813-10.426,2.182v16.626
		c7.574,0.093,13.37,1.732,17.388,4.919c4.018,3.186,6.026,7.09,6.026,11.707c0,4.203-1.488,7.759-4.467,10.668
		c-2.979,2.91-6.732,4.365-11.258,4.365c-4.341,0-7.932-1.282-10.772-3.846c-2.84-2.562-4.676-6.478-5.507-11.742H0
		c0.599,10.298,4.017,18.439,10.253,24.419c6.234,5.981,14.293,8.971,24.176,8.971c10.437,0,19.027-3.175,25.77-9.525
		c3-2.824,5.328-5.928,6.994-9.305c-0.633,5.064-0.952,10.691-0.952,16.885c0,13.395,1.477,23.717,4.434,30.967
		c2.954,7.251,7.041,12.653,12.261,16.21c5.219,3.556,11.661,5.334,19.328,5.334c7.665,0,14.016-1.767,19.05-5.3
		c5.032-3.532,9.041-9.062,12.02-16.591c2.978-7.526,4.467-18.057,4.467-31.589c0-13.393-1.466-23.842-4.398-31.348
		c-2.934-7.504-7.089-13.081-12.47-16.729c-5.38-3.648-11.788-5.473-19.223-5.473C90.255,50.971,81.319,55.588,74.9,64.826
		 M128.707,35.309c-0.248,0.172-0.7,0.258-1.357,0.258h-0.646V33.34h0.565c0.55,0,0.928,0.038,1.133,0.115
		c0.206,0.076,0.371,0.202,0.492,0.377c0.123,0.175,0.185,0.381,0.185,0.618C129.078,34.85,128.954,35.136,128.707,35.309
		 M131.589,42.175l-2.57-4.823c0.688-0.246,1.204-0.609,1.546-1.088s0.513-1.075,0.513-1.788c0-0.679-0.155-1.267-0.464-1.763
		c-0.311-0.496-0.72-0.852-1.228-1.066c-0.509-0.214-1.361-0.321-2.56-0.321h-2.188v10.849h2.065v-4.594h0.188l2.44,4.594H131.589z
		 M120.486,36.769c0,1.038-0.346,1.91-1.034,2.619c-0.689,0.708-1.523,1.062-2.503,1.062c-0.866,0-1.634-0.273-2.303-0.819
		c-0.862-0.698-1.293-1.647-1.293-2.847c0-1.076,0.343-1.964,1.025-2.662c0.686-0.699,1.529-1.048,2.533-1.048
		c0.995,0,1.839,0.358,2.532,1.073C120.138,34.863,120.486,35.737,120.486,36.769 M120.916,40.803
		c1.09-1.096,1.635-2.435,1.635-4.019c0-1.598-0.553-2.952-1.657-4.064c-1.104-1.111-2.425-1.667-3.96-1.667
		c-1.013,0-1.959,0.255-2.837,0.764c-0.878,0.509-1.568,1.198-2.069,2.069c-0.502,0.87-0.753,1.823-0.753,2.861
		c0,1.549,0.537,2.886,1.615,4.012c1.077,1.126,2.432,1.689,4.06,1.689C118.504,42.448,119.826,41.9,120.916,40.803 M78.269,42.176
		v-4.821l3.345,4.821h2.448l-4.156-6.008l3.788-4.842h-2.48l-2.945,3.741v-3.741h-2.087v10.85H78.269z M88.948,17.731h-5.996v2.035
		h1.925v8.814h2.095v-8.814h1.976V17.731z M90.634,33.074c0.994,0,1.838,0.358,2.531,1.073c0.695,0.716,1.042,1.59,1.042,2.622
		c0,1.038-0.344,1.91-1.034,2.619c-0.689,0.708-1.523,1.062-2.503,1.062c-0.866,0-1.634-0.273-2.303-0.819
		c-0.861-0.698-1.292-1.647-1.292-2.847c0-1.076,0.342-1.964,1.026-2.662C88.785,33.422,89.629,33.074,90.634,33.074 M90.656,31.053
		c-1.014,0-1.96,0.255-2.837,0.764c-0.879,0.509-1.57,1.198-2.071,2.069c-0.502,0.87-0.753,1.823-0.753,2.861
		c0,1.549,0.539,2.886,1.616,4.012c1.078,1.126,2.431,1.689,4.059,1.689c1.555,0,2.878-0.547,3.967-1.645
		c1.09-1.096,1.635-2.435,1.635-4.019c0-1.598-0.552-2.952-1.657-4.064C93.511,31.608,92.191,31.053,90.656,31.053 M91.906,23.189
		c0-1.077,0.342-1.964,1.026-2.663c0.684-0.698,1.528-1.047,2.532-1.047c0.995,0,1.839,0.358,2.533,1.073
		c0.693,0.715,1.041,1.59,1.041,2.622c0,1.038-0.345,1.91-1.034,2.618s-1.523,1.063-2.503,1.063c-0.866,0-1.634-0.273-2.303-0.819
		C92.337,25.338,91.906,24.39,91.906,23.189 M90.579,20.291c-0.502,0.871-0.753,1.824-0.753,2.862c0,1.549,0.538,2.886,1.616,4.012
		c1.077,1.126,2.431,1.689,4.059,1.689c1.556,0,2.877-0.548,3.967-1.645c1.09-1.096,1.635-2.435,1.635-4.019
		c0-1.598-0.552-2.953-1.657-4.064c-1.104-1.111-2.424-1.667-3.959-1.667c-1.014,0-1.96,0.255-2.838,0.764
		C91.771,18.73,91.081,19.42,90.579,20.291 M101.284,31.326h-2.012l-1.879,10.85h2.014l1.191-6.852l2.284,6.852h1.82l2.305-6.852
		l1.163,6.852h1.998l-1.821-10.85h-2.01l-2.538,7.567L101.284,31.326z M76.967,25.454L75.23,26.5c0.408,0.812,0.9,1.407,1.473,1.785
		c0.574,0.378,1.246,0.568,2.02,0.568c0.989,0,1.791-0.29,2.407-0.87c0.615-0.581,0.923-1.315,0.923-2.205
		c0-0.458-0.089-0.879-0.263-1.265c-0.175-0.386-0.471-0.791-0.89-1.213c-0.168-0.172-0.646-0.588-1.434-1.247
		c-0.841-0.713-1.346-1.178-1.514-1.394c-0.124-0.162-0.185-0.334-0.185-0.516s0.087-0.346,0.259-0.49
		c0.172-0.146,0.394-0.218,0.665-0.218c0.556,0,1.103,0.374,1.64,1.121l1.53-1.35c-0.571-0.663-1.102-1.121-1.592-1.372
		c-0.49-0.251-1.005-0.376-1.546-0.376c-0.843,0-1.55,0.269-2.124,0.808c-0.573,0.538-0.86,1.184-0.86,1.936
		c0,0.521,0.172,1.044,0.517,1.567c0.345,0.524,1.109,1.267,2.291,2.231c0.62,0.507,1.021,0.883,1.203,1.128
		c0.182,0.246,0.274,0.493,0.274,0.738c0,0.271-0.121,0.509-0.362,0.715c-0.241,0.207-0.539,0.31-0.894,0.31
		C78.089,26.892,77.488,26.413,76.967,25.454 M104.102,17.731l-1.878,10.849h2.014l1.191-6.852l2.284,6.852h1.82l2.305-6.852
		l1.163,6.852h1.997l-1.82-10.849h-2.01l-2.538,7.567l-2.515-7.567H104.102z M122.46,24.333h-2.903l1.456-3.725L122.46,24.333z
		 M124.074,28.581h2.146l-4.173-10.849h-2.094l-4.185,10.849h2.146l0.883-2.235h4.427L124.074,28.581z M130.767,19.767h1.978v-2.035
		h-5.997v2.035h1.925v8.814h2.095V19.767z M142.833,23.175c0,1.038-0.345,1.91-1.034,2.618c-0.688,0.708-1.522,1.063-2.502,1.063
		c-0.867,0-1.635-0.273-2.304-0.819c-0.861-0.698-1.292-1.647-1.292-2.847c0-1.077,0.342-1.964,1.025-2.663
		c0.685-0.698,1.528-1.047,2.533-1.047c0.994,0,1.838,0.358,2.532,1.073C142.486,21.268,142.833,22.143,142.833,23.175
		 M143.263,27.208c1.09-1.096,1.636-2.435,1.636-4.019c0-1.598-0.553-2.953-1.657-4.064c-1.104-1.111-2.424-1.667-3.959-1.667
		c-1.015,0-1.96,0.255-2.838,0.764c-0.878,0.508-1.568,1.198-2.07,2.068c-0.502,0.871-0.753,1.824-0.753,2.862
		c0,1.549,0.538,2.886,1.616,4.012s2.431,1.689,4.06,1.689C140.851,28.854,142.174,28.306,143.263,27.208 M146.845,28.581h5.075
		v-1.969h-3.009v-8.88h-2.066V28.581z M162.164,23.175c0,1.038-0.344,1.91-1.034,2.618c-0.688,0.708-1.522,1.063-2.503,1.063
		c-0.866,0-1.634-0.273-2.303-0.819c-0.861-0.698-1.292-1.647-1.292-2.847c0-1.077,0.342-1.964,1.025-2.663
		c0.685-0.698,1.528-1.047,2.533-1.047c0.994,0,1.838,0.358,2.531,1.073C161.817,21.268,162.164,22.143,162.164,23.175
		 M162.594,27.208c1.09-1.096,1.635-2.435,1.635-4.019c0-1.598-0.552-2.953-1.656-4.064c-1.104-1.111-2.424-1.667-3.959-1.667
		c-1.015,0-1.96,0.255-2.838,0.764c-0.878,0.508-1.568,1.198-2.07,2.068c-0.502,0.871-0.753,1.824-0.753,2.862
		c0,1.549,0.538,2.886,1.616,4.012s2.431,1.689,4.059,1.689C160.182,28.854,161.505,28.306,162.594,27.208 M176.229,26.236
		c0.492-0.85,0.738-1.751,0.738-2.7l-0.015-0.457h-5.458v1.94h3.142c-0.3,0.639-0.695,1.108-1.186,1.409
		c-0.489,0.3-1.091,0.45-1.804,0.45c-1.099,0-2.021-0.364-2.77-1.092c-0.749-0.728-1.122-1.615-1.122-2.662
		c0-1.013,0.36-1.874,1.081-2.582s1.615-1.062,2.684-1.062c1.226,0,2.298,0.487,3.219,1.46l1.454-1.438
		c-0.663-0.717-1.343-1.224-2.036-1.519c-0.821-0.349-1.703-0.524-2.647-0.524c-1.805,0-3.269,0.632-4.396,1.896
		c-0.949,1.062-1.424,2.31-1.424,3.746c0,1.048,0.257,2.023,0.771,2.929c0.515,0.904,1.212,1.601,2.095,2.09
		c0.882,0.489,1.887,0.734,3.013,0.734c1.053,0,1.97-0.223,2.752-0.671C175.1,27.734,175.737,27.085,176.229,26.236 M178.714,28.581
		h2.051V17.731h-2.051V28.581z M191.615,21.016l1.443-1.378c-0.599-0.696-1.313-1.234-2.142-1.612
		c-0.828-0.378-1.69-0.567-2.585-0.567c-1.053,0-2.034,0.256-2.947,0.767c-0.912,0.511-1.612,1.188-2.102,2.028
		c-0.489,0.841-0.733,1.802-0.733,2.884c0,1.672,0.536,3.042,1.611,4.112c1.075,1.069,2.455,1.604,4.141,1.604
		c0.915,0,1.729-0.151,2.442-0.453c0.712-0.303,1.469-0.845,2.271-1.627l-1.399-1.461c-0.594,0.59-1.141,0.997-1.635,1.222
		c-0.497,0.223-1.046,0.335-1.644,0.335c-0.703,0-1.342-0.16-1.917-0.48c-0.575-0.319-1.019-0.76-1.334-1.321
		c-0.313-0.561-0.471-1.204-0.471-1.927c0-1.039,0.354-1.913,1.064-2.621c0.709-0.709,1.583-1.063,2.62-1.063
		C189.527,19.458,190.633,19.977,191.615,21.016 M202.762,28.581l-4.156-6.007l3.787-4.842h-2.479l-2.946,3.74v-3.74h-2.087v10.849
		h2.087v-4.82l3.345,4.82H202.762z M207.25,16.891L209.949,14h-2.382l-1.764,2.891H207.25z M206.291,26.553v-2.854h3.872v-1.984
		h-3.872v-1.962h3.872v-2.021h-5.923v10.849h5.923v-2.028H206.291z"/>
				<path fill="#92754E" d="M82.175,3.306l2.198-2.087h-2.198l-0.797,0.974L80.56,1.219h-2.213l2.213,2.087H82.175z M84.721,7.416
		l1.444-1.377c-0.6-0.697-1.314-1.235-2.142-1.613c-0.828-0.378-1.691-0.567-2.585-0.567c-1.053,0-2.035,0.256-2.947,0.767
		c-0.912,0.512-1.613,1.188-2.102,2.028c-0.489,0.841-0.734,1.802-0.734,2.885c0,1.672,0.537,3.042,1.612,4.111
		c1.074,1.069,2.454,1.604,4.141,1.604c0.915,0,1.728-0.151,2.442-0.453c0.713-0.302,1.47-0.845,2.271-1.627l-1.4-1.461
		c-0.595,0.59-1.14,0.998-1.636,1.222c-0.496,0.223-1.044,0.336-1.643,0.336c-0.703,0-1.341-0.161-1.916-0.481
		c-0.575-0.319-1.019-0.76-1.334-1.321c-0.315-0.561-0.471-1.204-0.471-1.927c0-1.038,0.354-1.912,1.064-2.621
		s1.583-1.063,2.62-1.063C82.633,5.857,83.739,6.376,84.721,7.416 M87.98,14.98h5.922v-2.028H90.03v-2.854h3.872V8.114H90.03V6.152
		h3.872V4.131H87.98V14.98z M97.612,7.06c-0.123-0.162-0.184-0.334-0.184-0.516s0.086-0.345,0.259-0.49
		c0.171-0.146,0.394-0.218,0.664-0.218c0.557,0,1.103,0.374,1.64,1.121l1.53-1.35c-0.571-0.663-1.102-1.121-1.592-1.371
		c-0.49-0.251-1.005-0.377-1.546-0.377c-0.843,0-1.55,0.269-2.124,0.808c-0.573,0.538-0.86,1.184-0.86,1.936
		c0,0.521,0.173,1.044,0.517,1.567c0.346,0.524,1.109,1.267,2.291,2.231c0.62,0.507,1.021,0.883,1.204,1.129
		c0.182,0.245,0.273,0.492,0.273,0.737c0,0.271-0.121,0.509-0.362,0.716c-0.241,0.206-0.539,0.309-0.894,0.309
		c-0.679,0-1.28-0.479-1.802-1.438L94.89,12.9c0.409,0.812,0.9,1.407,1.473,1.785c0.574,0.378,1.246,0.568,2.02,0.568
		c0.989,0,1.791-0.29,2.407-0.87c0.615-0.581,0.923-1.315,0.923-2.205c0-0.458-0.088-0.88-0.263-1.265
		c-0.175-0.386-0.471-0.791-0.89-1.213c-0.167-0.172-0.645-0.588-1.433-1.247C98.285,7.741,97.78,7.275,97.612,7.06 M111.577,14.98
		l-4.156-6.007l3.787-4.842h-2.479l-2.944,3.74v-3.74h-2.087V14.98h2.087v-4.82l3.344,4.82H111.577z M116.065,3.291l2.699-2.891
		h-2.383l-1.763,2.891H116.065z M113.056,14.98h5.923v-2.028h-3.872v-2.854h3.872V8.114h-3.872V6.152h3.872V4.131h-5.923V14.98z"/>
			</g>
		</svg>
	);
}