/**
 * Model žádosti o vydání potvrzení o členství a bezúhonnosti v ČSK
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

/**
 * Definice dat formuláře
 */
export interface FormFields {
	lang_czech: boolean;
	lang_english: boolean;
	lang_german: boolean;
	amount: number;
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		lang_czech: {
			title: "v českém jazyce",
			defaultValue: false,
			onChange: (value, field, form) => { form.validateField("lang_german"); form.validateField("lang_english") },
			validate: (value, item, form) => value || form.getField("lang_english").value || form.getField("lang_german").value ? "" : "Prosím, vyberte alespoň jednu možnost"
		},
		lang_english: {
			title: "v anglickém jazyce",
			onChange: (value, field, form) => { form.validateField("lang_german"); form.validateField("lang_czech") },
			defaultValue: false,
			validate: (value, item, form) => value || form.getField("lang_german").value || form.getField("lang_czech").value ? "" : "Prosím, vyberte alespoň jednu možnost"
		},
		lang_german: {
			title: "v německém jazyce",
			onChange: (value, field, form) => { form.validateField("lang_czech"); form.validateField("lang_english") },
			defaultValue: false,
			validate: (value, item, form) => value || form.getField("lang_english").value || form.getField("lang_czech").value ? "" : "Prosím, vyberte alespoň jednu možnost"
		},
		amount: {
			title: "",
			defaultValue: 0
		}
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			await this.common.send(data, "zadost-o-potvrzeni-clenstvi");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	loadAmount = async () => {
		const amount = await this.context.api.get<number>("/request_types/" + "zadost-o-potvrzeni-clenstvi" + "/price/", {});
		await this.form.setField("amount", amount);
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			await this.form.setFields(data);
			this.loadAmount();
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadAmount()
		]);
	}
}