import * as api from "../../../lib/api";
import * as apiDms from "../../shared/dialogs/api-dms";
import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as lists from "../../system/lists/lists";

interface DmsDialogFields {
	idParent?: string;
	isImage?: boolean;
	onSet?: (item: api.CodeBookItem) => void;
	path: {
		id: string;
		name: string;
		index: number;
	}[];
}

export class Model {
	list: lists.StandardList<apiDms.DmsFile>;
	stateContainer: state.StateContainer<DmsDialogFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<DmsDialogFields>({ idParent: undefined, path: [], isImage: undefined, onSet: undefined }, context);
		this.list = new lists.StandardList<apiDms.DmsFile>({
			context: context,
			title: "Dokumenty",
			quickFilterPlaceholder: "Vyhledejte položku...",
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiDms.snem,
			filterSystem: _ => [
				api.qp("id_parent" as any, "=", this.stateContainer.get().idParent),
				api.qp("is_image" as any, "=", this.stateContainer.get().isImage)
			],
			grid: {
				minWidth: "600px",
				columns: [
					{
						field: "dms_file_type.name" as any,
						type: "string",
						title: "Složka",
						width: "100px",
					},
					{
						field: "name" as any,
						type: "string",
						title: "Název"
					},
					{
						field: "last_change" as any,
						type: "date",
						title: "Datum",
						width: "200px",
					},
					{
						field: "user_last_change_full_name" as any,
						type: "string",
						title: "Vložil",
						width: "350px",
					},
				]
			},
			
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true,
			hideFilterRow: true
		});
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers(),
	]

}