import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import usePopup from "../../hooks/popup";
import * as navigation from "../../lib/navigation";
import * as state from "../../lib/state";
import useExclusiveSwitch from "../../hooks/exclusive-switch";

import IconSvg from "./IconSvg";
import SlideDown from "./SlideDown";

export interface MenuItem {
	title: string;
	link: string;
	subItems?: MenuSubItem[];
}

interface MenuSubItem {
	title: string;
	link: string;
}

interface SubMenuProps {
	items: MenuSubItem[];
	isOpen: boolean;
	onTriggerClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onContentClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type MenuTheme = "public" | "authorized";

export interface MenuMainProps {
	className?: string;
	theme?: MenuTheme;
	items: MenuItem[];
	search?: () => void;
}

function pageBelongsToSection(pageRoute: string, sectionRoute: string) {
	return pageRoute === sectionRoute || pageRoute.startsWith(sectionRoute + "/");
}

export default function MenuMain(props: MenuMainProps) {
	const popup = usePopup();
	const exclusiveSwitch = useExclusiveSwitch();

	return (
		<nav role="navigation" className={"menu-main " + (props.theme === "authorized" ? "menu-main--authorized " : "") + (props.className ?? "")}>
			<span className="menu-main__burger js-menu__burger" onClick={popup.toggle}>
				<IconSvg name={props.theme === "authorized" ? "dots" : "burger"} />
			</span>
			<SlideDown
				as="ul"
				className="menu-main__list"
				open={popup.isOpen}
				onClick={(e) => { popup.close(e), exclusiveSwitch.closeAll(); }}
			>
				{props.items.filter(x => x.title != "").map(i =>
					<li key={i.title} className="menu-main__item">
						<Link to={i.link} className={"menu-main__link " + (pageBelongsToSection(navigation.getCurrent(), i.link) ? " --current" : "")}>
							{i.title}
						</Link>
						{
							i.subItems && i.subItems.length > 0 &&
							<SubMenu
								items={i.subItems}
								isOpen={exclusiveSwitch.current === i.title}
								onTriggerClick={(e) => { e.stopPropagation(); exclusiveSwitch.toggle(i.title); }}
								onContentClick={() => exclusiveSwitch.closeAll}
							/>
						}
					</li>
				)}
				{props.theme != "authorized" &&
					<div onClick={props.search} key={"search"} className="menu-main__item menu-main__search">
						<IconSvg name="search" />
					</div>
				}
			</SlideDown>
		</nav >
	);
}

function SubMenu(props: SubMenuProps) {
	return (
		<>
			<span className="menu-main__opener js-menu__opener" onClick={props.onTriggerClick}>
				<IconSvg name={props.isOpen ? "minus" : "plus"} />
				<IconSvg name="minus" />
			</span>

			<SlideDown
				as="ul"
				className="menu-main__sublist"
				open={props.isOpen}
				onClick={props.onContentClick}
			>
				{props.items.filter(x => x.title != "").map(i =>
					<li key={i.title} className="menu-main__subitem">
						<Link smooth to={i.link} className="menu-main__sublink">
							<span className="menu-main__sublink-title">{i.title}</span>
						</Link>
					</li>
				)}
			</SlideDown>
		</>
	);
}