import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiUsers from "../../shared/users/api-users";

export type RequestType =
	"zadost-o-vydani-prvniho-osvedceni"
	| "zadost-o-prodlouzeni-doby-platnosti-osvedceni"
	| "zadost-o-zapocitani-kreditu"
	| "zadost-o-zarazeni-do-vstupniho-vp"
	| "zadost-o-vykonani-profesni-zkousky-vp"
	| "zadost-o-opakovani-profesni-zkousky-vp"
	| "zadost-o-zmenu-terminu-profesni-zkousky"
	| "zadost-bez-osk"
	| "zadost-o-potvrzeni-clenstvi"
	| "zadost-o-vystoupeni-z-CSK"
	| "zadost-o-preskripci"
	| "zadost-o-prominuti-prispevku"
	| "zadost-o-vydani-osvedceni-primar-vedouci-lekar"
	| "zadost-o-ukonceni-vykonu-povolani"
	| "zadost-o-preruseni-vykonu-povolani"
	| "zadost-o-zmenu-kontaktnich-udaju"
	| "zadost-o-zapis-do-hlavni-osk"
	| "oznameni-o-zahajeni-vykonu"
	| "zadost-prihlaska-na-akci"
	| "zadost-o-vydani-osvedceni-po-celozivotnim-studiu"
	| "zavazna-objednavka-casopis-cspzl"
	| "zavazna-objednavka-casopis-lks"
	| "zadost-o-akreditaci-typu-i"
	| "zadost-o-akreditaci-typu-ii"
	| "zadost-o-registraci-akce"
	| "zadost-o-registraci-e-lerningove-akce"
	| "zadost-o-obnoveni-clenstvi"
	| "zadost-o-zapis-do-csk"
	| "zadost-o-vydani-osvedceni-odborny-zastupce"
	| "zadost-o-vydani-osvedceni-pro-absolvovani-vp"
	| "zadost-o-prispevek-z-uctu-klinik"
	| "zadost-o-zapis-do-seznamu-znalcu"
	| "zadost-o-peci-o-dite"
	| "zadost-o-peci-o-dite-odvolani"
	| "zadost-o-zmenu-osobnich-udaju"
	| "zadost-o-vykonani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl"
	| "zadost-o-vykonani-profesni-zkousky-opakovane-10let-praxe-vppzl"
	| "zadost-o-jednorazovy-vzdelavaci-program-snimatelna-protetika";

export interface WebRequest extends api.EntityReadBase {
	date: Date;
	payment_date: Date;
	date_rejected: Date;
	number: string;
	member: apiUsers.UserSimple;
	request_type: api.CodeBookItemWithCode;
	request_state: apiCodeList.CodeListSystem;
	regional_chamber_state?: apiCodeList.CodeList;
	chargeable: boolean;
	reason: string;
	data: any;
}

export interface WebRequestDocument extends api.EntityReadBase {
	name: string;
	last_change: Date;
}

export interface WebRequestCreate {
	type: RequestType;
	data: any;
}

export interface SendOperationResponse extends api.OperationResponse {
	text: string;
}

export class Api {
	standard: api.EntityApi<WebRequest, WebRequest>;
	documents: api.EntityApi<WebRequestDocument, WebRequestDocument>;

	constructor(private context: context.StateContext) {
		this.standard = new api.EntityApi<WebRequest, WebRequest>({
			api: context.api,
			path: "/users/requests"
		});
		this.documents = new api.EntityApi<WebRequestDocument, WebRequestDocument>({
			api: context.api,
			path: "/dms_files"
		});
	}

	send = (data: any, requestType: RequestType, options?: api.RequestOptions, urlParam?: string) => {
		const webRequestEnvelope: WebRequestCreate = {
			type: requestType,
			data: data
		};
		return this.context.api.post<WebRequestCreate, SendOperationResponse>("/request/send" + (urlParam ? urlParam : ""), webRequestEnvelope, options);
	}
}