/**
 * Právní analýza
 */
import React from "react";
import * as state from "../../../lib/state";
import HtmlContent from "../../../components/controls/HtmlContent";
import Page from "../../../components/templates/Page";

export default function PresidentialElectionLegalAnalysisPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("pravni-analyza-nadpis")} description={contents.getRes("pravni-analyza-nadpis")} authorized>
			<HtmlContent htmlKey="pravni-analyza-obsah" />
		</Page>
	);
}