/**
 * Grafiské standarty
 */
import React from "react";
import * as state from "../../../lib/state";
import HtmlContent from "../../../components/controls/HtmlContent";
import Page from "../../../components/templates/Page";


export default function AboutUsGraphicStandartsPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("graficke-standardy-nadpis")} description={contents.getRes("graficke-standardy-nadpis")}>
			<div className="about-us-graphics-standarts">
				<h1><HtmlContent htmlKey="graficke-standardy-nadpis" /></h1>
				<HtmlContent htmlKey="graficke-standardy-obsah" />
			</div>
		</Page>
	);
}