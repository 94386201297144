/**
 * Stavovské předpisy
 */
import React, { useState } from "react";
import Page from "../../../components/templates/Page";

import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

import * as state from "../../../lib/state";
import DynamicListExpandableCard from "../dynamic-list-item/DynamicListExpandableCard";
import Link from "../../../components/controls/Link";
import CardExpandable from "../../../components/controls/CardExpandable";

type ClickableExpandableCard = {
	className?: string;
	cardTitle?: React.ReactNode;
	children?: React.ReactNode;
};

function ClickableExpandableCard(props: ClickableExpandableCard) {
	let [open, setOpen] = useState(false);

	return (
		<CardExpandable onToggle={() => setOpen(!open)} cardTitle={props.cardTitle ?? ""} open={open} className={props.className ?? ""}>
			{props.children}
		</CardExpandable>
	);
}

function AboutUsEstablishmentRegulationsPage() {
	const model = state.useStateContext().aboutUsEstabilishmentRegulations;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("stavovske-predpisy-nadpis")} description={contents.getRes("stavovske-predpisy-nadpis")}>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="stavovske-predpisy-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<HtmlContent htmlKey="stavovske-predpisy-obsah" />

			{model.getSections().map((s, index) =>
				<ClickableExpandableCard key={index} cardTitle={<h2 className="mt-0 mb-0">{s.section.title}</h2>} className="mb-3">
					<div key={index} className="mt-4">
						{s.data.map((i, index) =>
							<div key={index} className="mb-2">
								<Link href={"/o-nas/stavovsky-predpis/" + i.id}>
									{i.title}
								</Link>
							</div>
						)}
					</div>
				</ClickableExpandableCard>
			)}

			{/* {model.getSections().map((s, index) =>
				<DynamicListExpandableCard reload={model.loadData} key={index} data={s.data} title={s.section.title} url="/o-nas/stavovske-predpisy/" />
			)} */}
		</Page>
	);
}

export default state.bindContainers(
	AboutUsEstablishmentRegulationsPage,
	c => c.aboutUsEstabilishmentRegulations
);