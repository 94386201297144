/**
 * Formulář žádosti o vydání prvního osvědčení
 */

import React from "react";

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import WebRequest from "../../../components/templates/WebRequest";
import FormFileUpload from "../../system/file/FormFileUpload";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import Button from "../../../components/controls/Button";

interface WebRequestIssueFirstCertificatePageProps {
	id?: string;
}

function WebRequestIssueFirstCertificatePage(props: WebRequestIssueFirstCertificatePageProps) {
	const { webRequestIssueFirstCertificate: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			authorized
			model={model.common}
			title="ŽÁDOST O VYDÁNÍ PRVNÍHO OSVĚDČENÍ (netýká se absolventů vstupních vzdělávacích programů)"
			description=""
			id={props.id}
		>
			<h2 className="form-title mb-5">Žádám o vydání prvního osvědčení</h2>

			<div className="row">
				<div className="col-md-6 col-lg-6">
					<FormSelect
						form={form}
						field="certificate_type"
						options={model.getCertificationTypes().map(i => ({ title: i.name, value: i.id }))}
					/>
				</div>
				{form.getField("certificate_type").value != 0 &&
					<div className="col-md-6 col-lg-6">
						<FormSelect
							form={form}
							field="request_reason"
							options={model.getRequestReasons().map(i => ({ title: i.name, value: i.id }))}
						/>
					</div>
				}
			</div>
			{form.getField("request_reason").value == 1 &&
				<div className="col-md-6 col-lg-6">
					<FormInput
						inputProps={{ type: "date" }}
						form={form}
						field="exam_date"
					/>
				</div>
			}
			<FormCheckBox
				form={form}
				field="declaration"
				className="mb-5"
			/>
			<h2>Přiložte dokumenty, na jejichž základě je žádáno o osvědčení</h2>
			{model.formsFiles.get().map((formFile, index) =>
				<div key={index} className="row">
					<div className="col-8 col-lg-10">
						<FormFileUpload
							fileUploadProps={{
								className: "mb-4"
							}}
							form={formFile}
							field="attachment"
							formItemOptions={{
								hideTitle: true
							}}
						/>
					</div>
					<div className="col-4 col-lg-2">
						<Button className="d-block w-100" onClick={() => model.removeFile(formFile)}>Odebrat</Button>
					</div>
				</div>
			)}

			<Button onClick={model.addFile} className="mb-5">Přidat přílohu</Button>

			<p>
				<strong>Za zpracování této žádosti je stanoven poplatek ve výši {form.getField("amount").value} Kč.</strong>
			</p>
			<p>
				Pokyn k úhradě poplatku obdržíte obratem po odeslání Vaší žádosti. Zaplatit můžete ihned platební kartou nebo bankovním převodem
				na základě předpisu, který Vám bude současně odeslán na Váš e-mail.
			</p>
			<div>Vaše žádost bude zpracována po připsání platby na účet ČSK.</div>

			<div className="row mt-3">
				<div className="col-md-4 col-lg-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={form}
						field="submission_date"
					/>
				</div>
			</div>

			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů</em><br /><br />
					<Button
						theme="primary"
						onClick={model.confirm}
						spinner={model.common.justSending()}>

						Odeslat
					</Button>
				</div>
			}

		</WebRequest>
	);
}


export default state.bindContainers(
	WebRequestIssueFirstCertificatePage,
	c => c.webRequestIssueFirstCertificate
);

