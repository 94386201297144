import * as React from "react";
import * as formatting from "../../lib/formatting";
import * as common from "../../lib/common";

const macOsSafari =
	!common.serverExecution()
	&& (/constructor/i.test(window.HTMLElement as any) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!(window as any)["safari"] || (typeof (window as any)["safari"] !== "undefined" && (window as any)["safari"].pushNotification)))
	&& navigator.platform.indexOf("Mac") > -1;

export type InputProps = Omit<React.HTMLProps<HTMLInputElement>, "onChange" | "ref"> & {
	search?: boolean;
	onChange?: (value: string) => void;
	ref?: React.Ref<HTMLInputElement>;
};

export default React.forwardRef((props: InputProps, ref: typeof props.ref) => {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	}

	const { search, onChange, className, value: origValue, ...rest } = props;

	let value = origValue;
	if (props.type === "date" && typeof value === "string" && !macOsSafari) {
		value = formatting.formatDate(formatting.parseDate(value), { format: "iso" });
	}

	return (
		<input
			onChange={handleChange}
			ref={ref}
			className={"input" + (search ? " input--search" : "") + " " + (className ?? "")}
			value={value}
			{...rest}
		/>
	);
});