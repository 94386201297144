import * as api from "../../../lib/api";
import * as apiFile from "../../system/file/api-file";
import * as context from "../../../context";

export interface Information extends api.EntityReadBase {
	title: string;
	image?: apiFile.File;
	perex: string;
	text: string;
	valid_from: Date;
	files: apiFile.File[];
}

export class Api {
	standard: api.EntityApi<Information, Information>;
	standardForMembers: api.EntityApi<Information, Information>;

	constructor(context: context.StateContext) {
		this.standard = new api.EntityApi<Information, Information>({
			api: context.api,
			path: "/informations"
		});
		this.standardForMembers = new api.EntityApi<Information, Information>({
			api: context.api,
			path: "/informations/private"
		});
	}
}