/**
 * Model žádosti o registraci akce
 * 
 * */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as validations from "../../../lib/validations";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";
import * as api from "../../../lib/api";
import * as apiWorkplace from "../../shared/workplace/api-workplace";

export interface FileFormFields {
	attachment?: apiFile.File;
}

export interface State {
	titles_before: api.CodeBookItem[];
	titles_after: api.CodeBookItem[];
}

export interface LectorsFormFields {
	title_before?: api.CodeBookItem;
	first_name: string;
	last_name: string;
	title_after?: api.CodeBookItem;
}

export interface FormFields {
	clear: boolean;
	organizer: string;
	ico: string;
	category: string;
	responsible_person: string;
	email: string;
	phone: string;
	action_name: string;
	action_place_url: string;
	from: string;
	to: string;
	determination: string;
	lecturer: string;

	price: string;
	web: string;

	description: string;

	provider_type: "existing_provider" | "new_provider";
	provider?: apiWorkplace.Workplace;
	provider_name: string;
	provider_number: string;
	provider_phone: string;
	provider_email: string;
	provider_registration_number: string;
	provider_street: string;
	provider_city: string;
	provider_postcode: string;
}

interface FormData extends FormFields {
	attachments: FileFormFields[];
	lectors: LectorsFormFields[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public formsFiles: forms.FormCollection<FileFormFields>;
	public formsLectors: forms.FormCollection<LectorsFormFields>;
	public stateContainer: state.StateContainer<State>;

	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.stateContainer = new state.StateContainer<State>({
			titles_after: [],
			titles_before: []
		}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				clear: {
					defaultValue: false,
					title: ""
				},
				provider_type: {
					title: "",
					defaultValue: "existing_provider"
				},
				provider: {
					title: "Zadejte pořadatele",
					defaultValue: undefined,
					required: (form) => form.getField("provider_type").value == "existing_provider" ? true : false,
					validate: (value, field, form) => form.getField("provider_type").value == "existing_provider" && value == null ? "Pořadatel je povinné pole" : "",
				},
				provider_city: {
					title: "Obec",
					defaultValue: ""
				},
				provider_name: {
					title: "Pořadatel",
					defaultValue: ""
				},
				provider_email: {
					title: "E-mail",
					defaultValue: ""
				},
				provider_number: {
					title: "Registrační číslo pořadatele",
					defaultValue: ""
				},
				provider_phone: {
					title: "Telefon",
					placeHolderText: "+420 XXX XXX XXX",
					defaultValue: "",
					validate: value => value.length > 0 && !validations.isPhoneNumber(value)
						? "Neplatný formát telefonního čísla"
						: ""
				},
				provider_postcode: {
					title: "PSČ",
					defaultValue: "",
					validate: value => value.trim().length == 5 || value.trim().length == 0 ? "" : "PSČ musí obsahovat 5 znaků"
				},
				provider_registration_number: {
					title: "IČO",
					defaultValue: ""
				},
				provider_street: {
					title: "Ulice a číslo",
					defaultValue: ""
				},
				organizer: {
					title: "Pořadatel akce",
					defaultValue: "",
				},
				ico: {
					title: "IČO",
					defaultValue: "",
					onChange: async (value, field, form) => {
						if (value.length == 8) {
							const organizer = await context.api.get<api.CodeBookItem>("/providers/find/" + value, {});
							if (organizer) {
								form.setField("organizer", organizer.name);
							}
						}
					}
				},
				category: {
					title: "Kategorie",
					defaultValue: ""
				},
				responsible_person: {
					title: "Odpovědná osoba",
					defaultValue: ""
				},
				email: {
					title: "E-mail",
					defaultValue: "",
					required: true,
					validate: value => value.length == 0 ? "Email je povinné pole" : "",
				},
				phone: {
					title: "Telefon",
					defaultValue: "",
					placeHolderText: "+420 XXX XXX XXX",
					required: true,
					validate: value => value.trim().length === 0 ? "Telefon je povinný údaj" : !validations.isPhoneNumber(value) ? "Telefon je v chybném formát" : ""
				},
				action_name: {
					title: "Název akce",
					defaultValue: "",
					required: true,
					validate: value => value.length == 0 ? "Název akce je povinné pole" : "",
				},
				action_place_url: {
					title: "Internetová adresa kurzu",
					defaultValue: "",
					required: true,
					validate: value => value.length == 0 ? "Adresa je povinné pole" : "",
				},
				from: {
					title: "Od",
					defaultValue: "",
					required: true,
					validate: value => value.length == 0 ? "Od je povinné pole" : "",
				},
				to: {
					title: "Do",
					defaultValue: "",
					required: true,
					validate: value => value.length == 0 ? "Do je povinné pole" : "",
				},
				determination: {
					title: "Určení",
					defaultValue: ""
				},
				lecturer: {
					title: "Lektor",
					defaultValue: ""
				},
				price: {
					title: "Cena",
					defaultValue: ""
				},
				web: {
					title: "Umístění na web",
					defaultValue: ""
				},
				description: {
					title: "Popis akce ke zvěřejnění na webu",
					defaultValue: ""
				}
			}
		}, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Připojit soubor",
					defaultValue: undefined
				}
			}
		}, this.context, this.form);

		this.formsLectors = new forms.FormCollection({
			fields: {
				title_before: {
					title: "Titul před",
					defaultValue: undefined
				},
				first_name: {
					title: "Jméno",
					defaultValue: ""
				},
				last_name: {
					title: "Příjmení",
					defaultValue: ""
				},
				title_after: {
					title: "Titul za",
					defaultValue: undefined
				},
			}
		}, context, this.form);

		this.formsFiles.add();
		this.formsLectors.add();
	}

	addLector = () => this.formsLectors.add();

	removeLector = async (item: forms.Form<LectorsFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Smazat lektora?") === "yes"
			? this.formsLectors.remove(item)
			: undefined

	addFile = () => this.formsFiles.add();

	removeFile = async (formFile: forms.Form<FileFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? this.formsFiles.remove(formFile)
			: undefined

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	loadProvider = async (prefix: string): Promise<apiWorkplace.Workplace[]> => {
		const workplaces = await this.context.api.post("/companies", {}) as any;
		return workplaces.data;
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { attachments, lectors, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.formsFiles.setFields(attachments),
				this.formsLectors.setFields(lectors),
			]);
		}
		await Promise.all([
			this.loadTitleAfter(),
			this.loadTitlesBefore()
		]);
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				lectors: this.formsLectors.get().map(f => f.getFields()),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			await this.common.send(data, "zadost-o-registraci-akce");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
	 * Načte seznam zemí pro dropdown titulů
	 */
	loadTitlesBefore = async () => {
		const titlesBefore = await this.context.api.post("/code-list/title_befores", {}) as any;
		const data = titlesBefore.data;
		data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			titles_before: data
		}));
		await this.form.setField("clear", true);
	}

	getTitlesBefore = () => {
		return this.stateContainer.get().titles_before;
	}

	/**
	 * Načte seznam zemí pro dropdown titulů
	 */
	loadTitleAfter = async () => {
		const titlesAfter = await this.context.api.post("/code-list/title_afters", {}) as any;
		const data = titlesAfter.data;
		data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			titles_after: data
		}));
		await this.form.setField("clear", true);
	}

	getTitlesAfter = () => {
		return this.stateContainer.get().titles_after;
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadTitleAfter(),
			this.loadTitlesBefore()
		]);
	}
}