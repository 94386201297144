/**
 * Ročenky
 */

import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";

import Spinner from "../../../components/controls/Spinner";
import CardLink from "../../../components/controls/CardLink";
import HtmlContent from "../../../components/controls/HtmlContent";

function AboutUsYearbooksPage() {
	const model = state.useStateContext().aboutUsYearbooks;
	const { contents } = state.useStateContext();

	const section = model.getSection();

	return (
		<Page title={contents.getRes("rocenky-nadpis")} description={contents.getRes("rocenky-nadpis")}>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="rocenky-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<HtmlContent htmlKey="rocenky-prehled" />

			{section.data.map(d =>
				<CardLink href={d.link} className="d-block mb-3">
					<HtmlContent content={d.text} />
				</CardLink>
			)}
		</Page>
	);
}

export default state.bindContainers(
	AboutUsYearbooksPage,
	c => c.aboutUsYearbooks
);