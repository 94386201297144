import React, { useState } from "react";
import * as formatting from "../../lib/formatting";

export type InputProps = Omit<React.HTMLProps<HTMLInputElement>, "onChange" | "ref"> & {
	search?: boolean;
	onChange?: (value: string) => void;
	ref?: React.Ref<HTMLInputElement>;
};

export default function InputDate(props: InputProps) {
	const { search, onChange, className, value: origValue, ...rest } = props;

	const [value, setValue] = useState("");
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.onChange) {
			props.onChange(e.target.value);
			setValue(e.target.value);
		}
	}

	return (
		<input
			onChange={handleChange}
			className={"input" + (props.search ? " input--search" : "") + " " + (props.className ?? "")}
			value={!value ? "" : formatting.formatDate(new Date(value as string), { format: "iso" })}
			type="date"
			{...rest}
		/>
	);
}