import * as React from "react";
import * as forms from "../../../lib/forms";
import * as formatting from "../../../lib/formatting";
import * as common from "../../../lib/common";
import * as apiFile from "./api-file";


import FormItem, { FormItemOptions } from "../../../components/controls/Forms/FormItem";
import FileUpload, { FileUploadProps } from "./FileUpload";


export interface FormsInputProps<TFormFields> {
	form: forms.Form<TFormFields>;
	formItemOptions?: FormItemOptions;
	field: keyof common.SubType<TFormFields, apiFile.File | undefined>;
	className?: string;
	fileUploadProps?: FileUploadProps;
}

export default function FormsInput<TFormFields>(props: FormsInputProps<TFormFields>) {
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
			className={props.className}
		>
			<FileUpload
				{...props.fileUploadProps}
				value={props.form.getField(props.field).value as any}
				onChange={value => props.form.setField(props.field, value as any)}
			/>
		</FormItem>
	);
}