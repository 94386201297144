/**
 * Obsahy
 */

import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";

import CardLink from "../../../components/controls/CardLink";
import Spinner from "../../../components/controls/Spinner";
import Paginator from "../../../components/controls/Paginator";
import Link from "../../../components/controls/Link";
import HtmlContent from "../../../components/controls/HtmlContent";

function ContentPage() {
	const { content } = state.useStateContext();

	async function loadData(page: number) {
		content.loadData(content.fulltext(), page);
	}

	return (
		<Page title="Obsahy" description="Obsahy">
			<h1 className="d-flex align-items-center">
				<div>Obsahy</div>
				{content.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<div className="mb-5">
				{content.getContents().data.map(d =>
				    <div>
						<Link to={"/obsah/" + d.id} className={"card-link font-weight-bold"}>
							<div className="w-100 mb-3">{d.title}</div>
						</Link>
						<HtmlContent content={d.text} />
					</div>
				)}
			</div>
			<Paginator listResponse={content.getContents()} onPageChanged={loadData} />
		</Page>
	);
}


export default state.bindContainers(
	ContentPage,
	c => c.content
);