import React from "react";

import * as state from "../../../lib/state";

import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormsTextArea from "../../../components/controls/Forms/FormsTextArea";
import Button from "../../../components/controls/Button";
import EnterPressArea from "../../../components/controls/EnterPressArea";
import Page from "../../../components/templates/Page";

function LoginDificultiesPage() {
	const { loginDifficulties, authorization } = state.useStateContext();

	return (
		<>
			<Page description="Problémy s přihlášením" title={"Problémy s přihlášením"}>
				<h1 className="d-flex align-items-center">
					Problémy s přihlášením
				</h1>
				<EnterPressArea onEnter={loginDifficulties.doLogin}>
					<div className="row">
						<div className="col">
							<FormsInput form={loginDifficulties.form} field="identification_number" />
						</div>
						<div className="col">
							<FormsInput form={loginDifficulties.form} field="email" />
						</div>
					</div>
					<div className="row">
						<div className="col">
							<FormsTextArea form={loginDifficulties.form} field="description" />
						</div>
					</div>
					<p>
						<Button theme="primary" onClick={loginDifficulties.doLogin} spinner={authorization.justLoggingIn()}>Odeslat</Button>
					</p>
				</EnterPressArea>
			</Page>
		</>
	);
}

export default state.bindContainers(
	LoginDificultiesPage,
	context => context.loginDifficulties
);