/**
 * Stránka detailu stáže
 */
import React from "react";
import * as state from "../../../lib/state";

import EducationalActionPage from "../educational-action/EducationalActionPage";

function StagePage() {
	return <EducationalActionPage stages />;
}

export default state.bindContainers(
	StagePage,
	c => c.stage
);