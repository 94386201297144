/**
 * Modul stránky pro registraci
 */

import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as context from "../../../context";
import * as notification from "../../../lib/notification";
import * as navigation from "../../../lib/navigation";
import * as validations from "../../../lib/validations";

/**
 * Stav modulu
 */
export interface State {
}

export interface FormFields {
	first_name: string;
	last_name: string;
	email: string;
	password: string;
	password_copy: string;
}

export class Model implements state.StateModel {

	stateContainer: state.StateContainer<State>;
	form: forms.Form<FormFields>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({}, context);
		this.form = new forms.Form<FormFields>({
			fields: {
				first_name: {
					title: "Jméno",
					defaultValue: "",
					required: true,
					validate: (value) => value.trim().length === 0 ? "Vyplňte pole" : ""
				},
				last_name: {
					title: "Příjmení",
					defaultValue: "",
					required: true,
					validate: (value) => value.trim().length === 0 ? "Vyplňte pole" : ""
				},
				email: {
					title: "Email",
					required: true,
					defaultValue: "",
					validate: (value) => (value.trim().length === 0 || !validations.isEmailAddress(value)) ? "Neplatný formát emailové adresy" : ""
				},
				password: {
					title: "Heslo",
					required: true,
					defaultValue: "",
					validate: (value) => {
						if (value.length < 8) {
							return "Heslo musí mít nejméně 8 znaků";
						}

						return value.trim().length == 0 ? "Vyplňte pole" : "";
					}
				},
				password_copy: {
					title: "Potvrďte heslo",
					required: true,
					defaultValue: "",
					validate: (value, model, form) => {
						if (value != form.getField("password").value) {
							return "Heslo pro potvrzení musí být stejné";
						}

						if (value.length < 8) {
							return "Heslo musí mít nejméně 8 znaků";
						}

						return value.trim().length == 0 ? "Vyplňte pole" : "";
					}
				}
			}
		}, context);
	}

	doRegister = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const firstname = this.form.getField("first_name").value;
			const lastname = this.form.getField("last_name").value;
			const password = this.form.getField("password").value;
			const email = this.form.getField("email").value;

			const result = await this.context.api.post("/registration", { first_name: firstname, last_name: lastname, password: password, email: email })
			if (result) {
				notification.successMessage("Registrace proběhla úspěšně, můžete se přihlásit");
				navigation.to("/prihlaseni");
			}
		}
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer,
		...this.form.getStateContainers()
	]

	loadPageData = async () => { };
}