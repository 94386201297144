import * as api from "../../../lib/api";
import * as context from "../../../context";

export interface Country extends api.EntityReadBase {
	code: string;
	name: string;
}

export class Api {
	constructor(private context: context.StateContext) { }

	loadCountries = () => {
		return this.context.api.loadList<Country>("/nationality", { sort_fields: ["name"] });
	}
}