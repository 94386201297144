/**
 * Seznam vlastních akcí
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiEducationalActions from "../../shared/educational-actions/api-educational-actions";

/**
 * Stav modulu
 */
export interface State {
	firstTypeActive: boolean;
	secondTypeActive: boolean;
}

/**
 * Vytvoří nový seznam.
 */
export function createListSubmitted(context: context.StateContext) {
	return new lists.StandardList<apiEducationalActions.EducationalActionMember>({
		context: context,
		title: "Seznam akcí",
		quickFilterPlaceholder: "Vyhledejte akci...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiEducationalActions.member,
		filterSystem: _ => [
			api.qp("educational_action_member_state.id" as any, "=", 1),
		],
		sortingFields: [],
		grid: {
			minWidth: "1000px",
			columns: [
				{
					field: "educational_action.code" as any,
					type: "string",
					title: "Kód akce",
				},
				{
					field: "date" as any,
					type: "date",
					title: "Datum konání"
				},
				{
					field: "educational_action.name" as any,
					type: "string",
					title: "Název",
					width: "50%"
				},
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {

	createListCompleted = (context: context.StateContext) => {
		return new lists.StandardList<apiEducationalActions.EducationalActionMember>({
			context: context,
			title: "Seznam akcí",
			quickFilterPlaceholder: "Vyhledejte akci...",
			itemActionsList: { actions: [] },
			standardEntityApi: context.apiEducationalActions.member,
			filterSystem: _ => [
				api.qp("educational_action_member_state.id" as any, "=", 3),
				api.qp("type" as any, "=", this.getFirstTypeActive() ? 1 : undefined),
				api.qp("type" as any, "=", this.getSecondTypeActive() ? 2 : undefined),
			],
			sortingFields: [],
			grid: {
				minWidth: "1000px",
				columns: [
					{
						field: "educational_action.code" as any,
						type: "string",
						title: "Kód akce",
					},
					{
						field: "date" as any,
						type: "date",
						title: "Datum konání"
					},
					{
						field: "educational_action.name" as any,
						type: "string",
						title: "Název",
						width: "50%"
					},
					{
						field: "applied_to",
						type: "string",
						title: "Uplatněno"
					},
					{
						field: "credits",
						type: "number",
						width: "90px",
						title: "Kreditů"
					}
				]
			},
			defaultPageSize: 40,
			hideTabFilter: true,
			hideQueryMode: true
		});
	}

	listSubmitted: lists.StandardList<apiEducationalActions.EducationalActionMember>;
	listCompleted: lists.StandardList<apiEducationalActions.EducationalActionMember>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.listSubmitted = createListSubmitted(context);
		this.listCompleted = this.createListCompleted(context);
		this.stateContainer = new state.StateContainer<State>({ firstTypeActive: false, secondTypeActive: false }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.listCompleted.getStateContainers(),
		...this.listSubmitted.getStateContainers()
	]

	getFirstTypeActive = () => {
		return this.stateContainer.get().firstTypeActive;
	}

	getSecondTypeActive = () => {
		return this.stateContainer.get().secondTypeActive;
	}

	setFirstTypeActive = (value: boolean) => {
		this.stateContainer.merge(_ => ({
			firstTypeActive: value
		}))
	}

	setSecondTypeActive = (value: boolean) => {
		this.stateContainer.merge(_ => ({
			secondTypeActive: value
		}))
	}

	loadData = async () => {
		if (this.context.authorization.userLoggedIn()) {
			await this.listCompleted.loadWithResetPage();
			await this.listSubmitted.loadWithResetPage();
		}
	}
}