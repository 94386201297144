/**
 * Seznam knih (e-shop)
 */

import * as lists from "../../system/lists/lists";
import * as apiShop from "../../shared/shop/api-shop";
import * as context from "../../../context";

/**
 * Model stránky
 */
export class Model {
	public list: lists.StandardList<apiShop.ShopItemSimple>;

	constructor(private context: context.StateContext) {
		this.list = new lists.StandardList<apiShop.ShopItemSimple>({
			context: context,
			standardEntityApi: context.apiShop.itemsSimple,
			defaultPageSize: 30
		});
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		...this.list.getStateContainers()
	]

	/**
	 * Načte data modulu
	 */
	loadData = async () => {
		await Promise.all([
			this.list.loadWithResetPage(),
			this.context.shopCart.loadData(),
		]);
	}
}