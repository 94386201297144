/*
 * API pro autorizaci
 */

import * as context from "../../../context";
import * as api from "../../../lib/api";
import * as apiCodeList from "../code-list/api-code-list";
import * as apiAddress from "../../shared/address/api-address";
import * as apiContact from "../../shared/contact/api-contact";
import * as apiRegionalChambers from "../../shared/regional-chambers/api-regional-chambers";
import * as apiWorkplace from "../../shared/workplace/api-workplace";

/**
 * Přihlašovací informace
 */
export interface Credentials {
	username: string;
	password: string;
}

/**
 * Požadavek na změnu hesla
 */
export interface ChangePasswordRequest {
	old_password: string;
	new_password: string;
}

export interface UserProfile {
	identification_number: string;
	personal_identification_number: string;
	title_before: string;
	title_after: string;
	title_pedagogical: string;
	first_name: string;
	last_name: string;
	middle_name: string;
	member_category?: apiCodeList.CodeList;
	regional_chamber_main?: apiRegionalChambers.RegionalChamberSimple;
	visiting_regional_chambers: apiCodeList.CodeList[];
	address?: apiAddress.Address;
	delivery_address?: apiAddress.Address;
	certificate: boolean;
	new_education_oral_maxifacial_chirurgy: boolean;
	next_education_atest_1: boolean;
	next_education_atest_2: boolean;
	truespecialization_clinic_stomatology: boolean;
	specialization_ortodoncy: boolean;
	specialization_surgery: boolean;
	contact?: apiContact.Contact;
	birthdate?: Date;
	graduated_date?: Date;
	university_name: string;
	object_title_before?: api.CodeBookItem;
	object_title_after?: api.CodeBookItem;
	object_title_pedagogical?: api.CodeBookItem;
	child_birthdate?: Date;
	gender?: apiCodeList.CodeListSystem;
	nationality?: apiCodeList.CodeList;
	validity_of_the_residence_permit?: Date;
	reason_for_termination_of_membership: string;
	membership_from?: Date;
	membership_to?: Date;
	send_bulk_emails: boolean;
	send_monitoring: boolean;
	journal_subscribe: boolean;
	journal_address: api.CodeBookItemWithNumberId;
	school: string;
	decision_of_the_ministry_of_health_on_eligibility: string;
	date_of_graduation: Date;
	certification_first: string;
	certification_second: string;
	diploma_number: string;
	graduation_place: string;
	professional_specializations: string;
	specialization_field: string;
	specialization_year: string;
	workplace_main: apiWorkplace.Workplace;
	date_off_last_confirmations: Date;
	visiting_only_emergency_regional_chambers: apiCodeList.CodeList[];
}
export interface Profile {
	full_name: string;
	first_name: string;
	last_name: string;
	is_manager: boolean;
	role: api.CodeBookItemWithNumberId;
	is_member: boolean;
	is_osk: boolean;
	is_snemovnik?: boolean;
	profile: UserProfile;
}

/**
 * Výsledek autorizace
 */
export interface AccessTokenResponse {
	access_token: string;
	id_role: number;
	is_osk: boolean;
	is_member: boolean;
	membership_to: Date;
}

export class Api {
	constructor(private context: context.StateContext) { }

	/**
	 * Přihlásí uživatele pomocí uživatelského jména a hesla
	 */
	async loginWithCredentials(credentials: Credentials) {
		return await this.context.api.post<Credentials, AccessTokenResponse>("/auth/token", credentials);
	}

	/**
	 * Odhlásí uživatele
	 */
	async logout() {
		return await this.context.api.post("/auth/logout", {});
	}

	/**
	 * Změní heslo uživatele
	 */
	async changePassword(request: ChangePasswordRequest) {
		return await this.context.api.post<ChangePasswordRequest>("/auth/change_password", request);
	}

	/**
	 * Požadavek na obnovu hesla
	 */
	async passwordRestoreRequest(email: string) {
		return await this.context.api.post<{ email: string }>("/auth/forgot_password", { email });
	}

	/**
	 * Kontrola tokenu pro obnovu hesla
	 */
	async checkPasswordRestoreToken(token: string) {
		return await this.context.api.post<{}, boolean>("/auth/token/" + token + "/is_valid", {});
	}

	/**
	 * Obnova hesla
	 */
	async passwordRestore(token: string, newPassword: string) {
		return await this.context.api.post<{ new_password: string }>("/auth/change_password?token=" + token, { new_password: newPassword });
	}

	/**
	 * Načte profil uživatele
	 */
	loadUserProfile() {
		return this.context.api.get<Profile>("/auth/info");
	}
}
