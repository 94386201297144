import * as api from "../../../lib/api";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

export interface EducationalExam {
	id: api.Id;
	place_action: string;
}

export interface UserExam extends api.EntityReadBase {
	number: string;
	educational_exam_date: Date;
	repetition: number;
	educational_exam: EducationalExam;
	educational_exam_term_state: apiCodeList.CodeListSystem;
	canceled: boolean;
	condition: string;
}

export class Api {
	user: api.EntityApi<UserExam, UserExam>;

	constructor(private context: context.StateContext) {
		this.user = new api.EntityApi<UserExam, UserExam>({
			api: context.api,
			path: "/users/exams"
		});
	}
}	