/**
 * Ostatní
 */
import React from "react";
import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";


export default function OtherPage() {
	return (
		<Page title={"Další informace"} description={"Další informace"}>
			<HtmlContent htmlKey="dalsi-informace-obsah" />
		</Page>
	);
}