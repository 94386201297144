/**
 *  Formulář žádost o vyškrtnutí ze seznamu členů oblastní komory
 */

import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";
import WebRequest from "../../../components/templates/WebRequest";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import Button from "../../../components/controls/Button";
import FormInput from "../../../components/controls/Forms/FormsInput";

interface WebRequestMembersRemovingPageProps {
	id?: string;
}

function WebRequestMembersRemovingPage(props: WebRequestMembersRemovingPageProps) {
	const title = "ŽÁDOST O VYŠKRTNUTÍ ZE SEZNAMU ČLENŮ OBLASTNÍ KOMORY (zachování členství v ČSK, bez příslušnosti k OSK)";
	const { webRequestMembersRemoving: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p>Tato žádost je určena pouze členům nevykonávajícím povolání (tj. kategorie N a N70)</p>
			<div className="font-weight-bold">Žádám o vyškrtnutí ze seznamu členů Oblastní Komory (hlavní Oblastní Komory) <span className="form-item__required">*</span></div>
			<div className="row">
				<div className="col-sm-5">
					<FormSelect
						className="mt-3"
						formItemOptions={{ hideTitle: true }}
						form={form}
						field="regional_chamber_id"
						options={model.getRegionalChambers().map(i => ({ title: i.name, value: i.id }))}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-5">
					<FormInput
						form={form}
						field="day"
						inputProps={{ type: "date", min: formatting.formatDate(new Date(), { format: "iso" }) }}
					/>
				</div>
			</div>
			<p>
				Prohlašuji, že nevykonávám povolání zubního lékaře na území České republiky ani nejsem
				společníkem právnické osoby, která je poskytovatelem zdravotních služeb v oboru zubní lékařství,
				orální a maxilofaciální chirurgie, ortodoncie nebo klinická stomatologie.
			</p>

			<p className="mb-5">
				<strong>Poučení:</strong><br />
				<small>
					Zápis do seznamu členů vedeného Oblastní komorou zaniká dnem doručení této žádosti Kanceláři Komory, avšak jen za předpokladu, že žadatel nevykonává povolání zubního lékaře na území České republiky ani není společníkem právnické osoby, která je poskytovatelem zdravotních služeb v oboru zubní lékařství. Nadále zůstává členem České stomatologické komory, avšak bez příslušnosti k Oblastní komoře.
					<br /> Člen, který znovu zahájí výkon povolání, je povinen se opětovně zapsat do seznamu vedeného Oblastní komorou dle místa výkonu povolání.
				</small>
			</p>

			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestMembersRemovingPage,
	c => c.webRequestMembersRemoving
);