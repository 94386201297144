/**
 * Filter podle stavu (zcela nahoře v seznamech).
 */
import * as React from "react";
import Icon from "../icons/Icon";
import * as lists from "./lists";
import * as api from "../../../lib/api";

export interface Item<Value> {
	title: string;
	value: Value;
}

export interface Props<ListItem extends api.EntityReadBase, AdvancedFilterFields> {
	list: lists.StandardList<ListItem, AdvancedFilterFields>;
	className?: string;
}

export default function ListTabFilter<ListItem extends api.EntityReadBase, AdvancedFilterFields>(props: Props<ListItem, AdvancedFilterFields>) {

	function getItemCssClass(value: lists.TabFilterItem<ListItem>) {
		let className = "list-tab-filter__item ";
		if (props.list.getCurrentFilterTab() === value) {
			className += " list-tab-filter__item--active";
		}
		return className;
	}

	function getDeletedCssClass() {
		let className = "list-tab-filter__item ";
		if (props.list.getCurrentFilterTab() === lists.TabFilterDeleted) {
			className += " list-tab-filter__item--active";
		}
		return className;
	}

	return (
		<div className={props.className || ""}>
			<div className="list-tab-filter">
				<div className="list-tab-filter__states">
					{props.list.getFilterTabs().filter(i => i !== lists.TabFilterDeleted).map(i =>
						<span key={i.title} className={getItemCssClass(i)} onClick={() => props.list.setFilterTab(i)}> {i.title}</span>
					)}
				</div>
				{!props.list.options.hideDeletedTabFilter &&
					<span key="_deleted" className={getDeletedCssClass() + " list-tab-filter__item--deleted"} onClick={() => props.list.setFilterTab(lists.TabFilterDeleted)}>
						<Icon picture="ImageDelete" className="mr-2" />
						{lists.TabFilterDeleted.title}
					</span>
				}
			</div>
			<div className="list-tab-filter__line">
			</div>
		</div>
	);
}