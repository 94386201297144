/**
 * Domovská stránka
 */
import React, { useState } from "react";

import * as state from "../../../lib/state";
import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";
import Spinner from "../../../components/controls/Spinner";
import JoindedList from "../../../components/controls/JoinedList";
import CardExpandable from "../../../components/controls/CardExpandable";
import Card from "../../../components/controls/Card";

function OskOrgansPage() {
	const { contents } = state.useStateContext();
	const model = state.useStateContext().oskOrgans;

	function ClickableExpandableCard(props: any) {
		let [open, setOpen] = useState(false);

		return (
			<CardExpandable cardTitle={props.cardTitle ?? ""} onToggle={() => setOpen(!open)} open={open} className={"mb-2"}>
				{props.children}
			</CardExpandable>
		);
	}

	function ExpertCard(props: { item: any }) {
		return (
			<>
				<ClickableExpandableCard cardTitle={props.item.name}>
					<div className="row align-items-stretch">
						{props.item.items.map((i: any, index: number) =>
							<div key={index} className="col-12 col-md-6 col-lg-4 mb-gutter">
								<Card className="contacts-experts-page__card">
									{i.regional_chamber_main &&
										<div>{i.regional_chamber_main.name}</div>
									}
									<strong>{i.member.full_name}</strong>
									<div>{i.member.contact.phone1}</div>
									<div>{i.member.contact.email1}</div>
									<div className="mt-3">
										<JoindedList
											items={[
												i.member.address?.city,
												i.member.address?.street,
												i.member.address?.postcode,
											]}
											itemTemplate={(item) => item}
											delimiter=", "
										/>
									</div>
								</Card>
							</div>
						)}
					</div>
				</ClickableExpandableCard>
			</>
		);
	}

	return (
		<Page
			title={contents.getRes("organy-osk-nadpis")}
			description=""
			authorized
		>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="organy-osk-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			{model.getSections().map((i, index) =>
				<>
					<h2>{i.name}</h2>
					{i.organs.map((i, index) => <ExpertCard key={index} item={i} />)}
				</>
			)}
		</Page>
	);
}

export default state.bindContainers(
	OskOrgansPage,
	context => context.oskOrgans
);