/**
 * Seznam inzerce
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Paginator from "../../system/lists/ListPaginator";
import Spinner from "../../../components/controls/Spinner";
import Books from "../../shared/shop/Books";
import CartBanner from "../../shared/shop/CartBanner";
import HtmlContent from "../../../components/controls/HtmlContent";

function BooksPage() {
	const { books: model, shopCart: cart } = state.useStateContext();
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("knihy-nadpis")} description="">
			<h1 className="d-flex align-items-center mb-5">
				<HtmlContent htmlKey="knihy-nadpis" />
				{model.list.justLoadingData() &&
					<Spinner small className="ml-1" />
				}
			</h1>

			<HtmlContent htmlKey="knihy-obsah" />

			<CartBanner />

			<div className="d-flex aling-items-center justify-content-between mb-5">
				<span>
					{formatting.pluralize(model.list.getData().length, "Zobrazena", "Zobrazeny", "Zobrazeno")}
					&nbsp;{model.list.getData().length}
					&nbsp;{formatting.pluralize(model.list.getData().length, "kniha", "knihy", "knih")}
					&nbsp;{model.list.getTotalRecords()}
				</span>
			</div>

			<Books
				className="mb-4"
				items={model.list.getData()}
			/>

			<Paginator list={model.list} />
		</Page >
	);
}

export default state.bindContainers(
	BooksPage,
	c => c.books
);