/**
 * Formulář pro zadání inzerátu
 */

import React from "react";

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";

import Page from "../../../components/templates/Page";
import Card from "../../../components/controls/Card";
import Link from "../../../components/controls/Link";
import Spinner from "../../../components/controls/Spinner";
import Checkbox from "../../../components/controls/Checkbox";
import FormItem from "../../../components/controls/Forms/FormItem";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormsTextArea from "../../../components/controls/Forms/FormsTextArea";
import Button from "../../../components/controls/Button";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormsSelect from "../../../components/controls/Forms/FormSelect";
import HtmlContent from "../../../components/controls/HtmlContent";
import { Api } from "app/modules/shared/country/api-country";

interface AdvertisementsEntryPageProps {
	id?: string;
}

function AdvertisemenetsEntryPage(props: AdvertisementsEntryPageProps) {
	const title = "Detail inzerátu";
	const { advertisementsEntry: model } = state.useStateContext();
	const form = model.form;

	return (
		<Page title={title} authorized>
			<h1>Detail inzerátu</h1>

			{!model.isSent() &&
				<>
					<Card>
						<HtmlContent htmlKey="novy-inzerat-obsah" />
					</Card>

					<h2>Osobní údaje</h2>
					<p className="mb-5">
						Tyto údaje slouží pouze interně pro identifikaci zadavatele inzerce a nebudou
						dále šířeny a ani nebudou součástí inzerátu
					</p>
					<div className="row">
						<div className="col-lg-6">
							<FormsInput form={form} field="first_name" />
							<FormsInput form={form} field="last_name" />
							<FormsInput form={form} field="company" />
						</div>
						<div className="col-lg-6">
							<FormsInput form={form} field="email" />
							<FormsInput form={form} field="phone" />
						</div>
					</div>

					<h2 className="mb-5">Veřejná část inzerátu</h2>
					{model.justLoadingCategories() &&
						<div className="d-flex align-items-center mb-5">
							<Spinner small className="mr-1" /> Načítám seznam rubrik...
						</div>
					}

					{form.getField("categories").value.length > 0 &&
						<FormItem form={form} field="categories">
							<CheckBoxRadioFrame className="mb-formitem column-count-lg-2">
								{form.getField("categories").value.map(i =>
									<Checkbox title={i.name} toolip={i.tooltip} checked={i.checked} onCheckedChanged={_ => model.toggleCategory(i)} />
								)}
							</CheckBoxRadioFrame>
						</FormItem>
					}

					<FormsSelect
						form={form}
						field="region"
						options={[
							{
								title: "",
								value: undefined
							},
							...model.getRegions().map(i => ({
								title: i.name,
								value: i
							}))
						]}
					/>
					<FormsTextArea form={form} field="description" textAreaProps={{ className: "mb-0" }} />
					<div className="text-right mb-5">
						<small>zbývá znaků: </small> <strong>{1000 - form.getField("description").value.length}</strong>
					</div>

					<h2>Kontaktní údaje</h2>
					<p className="mb-5">
						Tyto údaje budou součástí zveřejněného inzerátu (vyplňte alespoň jeden údaj)
					</p>
					<FormsInput form={form} field="contact_email" />
					<FormsInput form={form} field="contact_phone" />
					<FormsInput form={form} field="contact_web" />

					<div className="text-right mb-5">
						{model.getId() != api.emptyGuid &&
							<Button className="mr-2" theme="primary" spinner={model.justSending()} onClick={model.remove}>
								Smazat inzerát
							</Button>
						}
						<Button theme="primary" spinner={model.justSending()} onClick={model.confirm}>
							Odeslat inzerát
						</Button>
					</div>
				</>
			}

			{model.isSent() &&
				<p>
					Váš inzerát byl úspěšně odeslán. Můžete pokračovat na <Link to="/inzerce">Seznam inzerce</Link>.
				</p>
			}
		</Page >
	);
}

export default state.bindContainers(
	AdvertisemenetsEntryPage,
	c => c.advertisementsEntry
);