/**
 *  Formulář žádosti o změnu kontaktních údajů
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";
import WebRequestsProvider from "../web-requests/WebRequestsProvider";

interface WebRequestOskRegistrationJobPageProps {
	id?: string;
}

function WebRequestOskRegistrationJobPage(props: WebRequestOskRegistrationJobPageProps) {
	const title = "OZNÁMENÍ O ZAHÁJENÍ VÝKONU POVOLÁNÍ NA ÚZEMÍ OBLASTNÍ KOMORY (přidání hostující Oblastní komory - evidovaný člen, nové nebo další hostující pracoviště, další pracoviště v rámci jedné OSK)";
	const { webRequestOskRegistrationJob: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p className="font-weight-bold">
				Aktuálně jsem zapsán/a v seznamu členů Oblastní komory (hlavní):
			</p>

			<div className="mb-3">
				{form.getField("primary_chamber").value?.name}
			</div>


			<p className="font-weight-bold">
				Žádám o zápis do seznamu členů hostující oblastní komory:
			</p>

			<WebRequestsProvider order={1} form={model.form} />

			<p className="mb-5">
				<em>
					UPOZORNĚNÍ: V případě, že v souvislosti se změnou pracoviště měníte i adresu bydliště,
					nezapomeňte podat i Žádost o změnu kontaktních údajů.
				</em>
			</p>

			{
				!props.id &&
				<div className="text-right">
					<div className="mb-2">
						<div className="mb-2">
							<em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em>
						</div>
					</div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestOskRegistrationJobPage,
	c => c.webRequestOskRegistrationJob
);