/**
 * Odpověď platební brány
 */

import queryString from "query-string";
import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as context from "../../../context";
import * as apiPaymentGateway from "../../shared/payment-gateway/api-payment-gateway";

/**
 * Stav modulu
 */
interface State {
	paymentConfirmResult?: apiPaymentGateway.PaymentConfirmResult;
	justConfirms: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justConfirms: false
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Vrací potvrzení platby
	 */
	getPaymemtConfirmResult = () => {
		return this.stateContainer.get().paymentConfirmResult;
	}

	/**
	 * Vrací true, pokud se platba právě potvrzuje
	 */
	justConfirms = () => {
		return this.stateContainer.get().justConfirms;
	}

	/**
	 * Potvrdí platbu
	 */
	confirm = async () => {
		const qs = queryString.parse(window.location.search);
		const paymentConfirmation: apiPaymentGateway.PaymentConfirm = {
			PRCODE: parseInt(qs["PRCODE"] as string),
			ORDERNUMBER: parseInt(qs["ORDERNUMBER"] as string),
			SRCODE: qs["SRCODE"] as string
		};
		const response = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justConfirms: true })),
			exec: () => this.context.apiPaymentGateway.confirm(paymentConfirmation),
			finish: () => this.stateContainer.merge(_ => ({ justConfirms: false }))
		});
		await this.stateContainer.merge(_ => ({
			paymentConfirmResult: response
		}));
	}
}