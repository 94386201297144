/**
* Zobecněný odkaz
*/
import React from "react";
import { HashLink, HashLinkProps } from "react-router-hash-link";

export type LinkProps = (React.AnchorHTMLAttributes<HTMLAnchorElement> | HashLinkProps) & {
	noStyle?: boolean;
};

function isLinkProps(link: LinkProps): link is HashLinkProps {
	return (link as HashLinkProps).to !== undefined;
}

function getClassName(props: LinkProps) {
	return "link " + (props.className ?? "") + (props.noStyle ? " --no-style " : "");
}

export default function Link(props: LinkProps) {
	const { className, href, noStyle, ...restProps } = props;

	if (isLinkProps(restProps)) {
		return (
			<HashLink className={getClassName(props)} {...restProps}>
				{props.children}
			</HashLink>
		);
	} else if (props.href?.startsWith("/")) {
		return (
			<HashLink to={href!} className={getClassName(props)} {...restProps}>
				{props.children}
			</HashLink>
		);
	} else {
		return (
			<a className={getClassName(props)} href={href} {...restProps}>
				{props.children}
			</a>
		);
	}
}