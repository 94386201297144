/**
 * Orgán ČSK
 */
import React from "react";

import * as state from "../../../lib/state";

import Spinner from "../../../components/controls/Spinner";
import Page from "../../../components/templates/Page";
import Card from "../../../components/controls/Card";
import HtmlContent from "../../../components/controls/HtmlContent";

function AboutUsCskAuthorityPage() {
	const model = state.useStateContext().aboutUsCskAuthority;
	const authority = model.getAuthority();

	return (
		<Page title="Členové sněmu" description="">
			<h1 className="d-flex align-items-center">
				{authority?.name ?? "Orgán ČSK"}
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<h2>{authority?.heads_title}</h2>
			<div className="row">
				{authority?.heads.map(i =>
					<div className="col-12 col-md-6 col-lg-4">
						<Card className="mb-gutter">
							{i.full_name}
						</Card>
					</div>
				)}
			</div>

			<h2>{authority?.members_title}</h2>
			<div className="row">
				{authority?.members.map(i =>
					<div className="col-12 col-md-6 col-lg-4">
						<Card className="mb-gutter">
							{i.full_name}
						</Card>
					</div>
				)}
			</div>
			{model.getCommision() == false &&
				<div className="mt-3">
					<HtmlContent htmlKey="cestna-rada-kontakt" />
				</div>
			}
			{model.getCommision() &&
				<div className="mt-3">
					<HtmlContent htmlKey="predstavenstvo-kontakt" />
				</div>
			}
		</Page >
	);
}


export default state.bindContainers(
	AboutUsCskAuthorityPage,
	c => c.aboutUsCskAuthority
);