/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import List from "../../system/lists/List";
import Link from "../../../components/controls/Link";
import IconSvg from "../../../components/controls/IconSvg";

function MyDocumentsPage() {
	const { myDocuments, apiFile } = state.useStateContext();
	return (
		<Page
			title="Seznam doumentů"
			description=""
			authorized
		>
			<h1>
				Seznam dokumentů
			</h1>
			<List
				list={myDocuments.list}
				simpleTable
				itemNode={(item, field) => {
					switch (field) {
						case "name": return <span>
							<IconSvg name="doc" className="my-documents-page__doc-icon mr-2 mt-2" />
							<Link href={apiFile.getDownloadLink(item.id)}>{item[field]}</Link>
							<div className="font-weight-bold">{item.description}</div>
						</span>;
						default: return undefined;
					}
				}}
			/>
		</Page>
	);
}

export default state.bindContainers(
	MyDocumentsPage,
	context => context.myDocuments
);