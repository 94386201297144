/**
 * Domovská stránka
 */
import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import TimeLine from "../../../components/controls/TimeLine";
import Spinner from "../../../components/controls/Spinner";
import HomePageLogo from "../../../components/icons/HomePageLogo";
import ToothIcon from "../../../components/icons/ToothIcon";
import EducationalActionsTimeLineItem from "../../shared/educational-actions/EducationalActionsTimeLineItem";
import NewsTimeLineItem from "../news/NewsTimeLineItem";

function HomePage() {
	const context = state.useStateContext();
	const actions = context.home.actions;
	const news = context.home.news;
	const { contents } = state.useStateContext();

	return (
		<Page title="Home" description="">
			<div className="row mb-3">
				<div className="col-md-8 col-lg-9">
					<div className="home-page__blue-panel mb-3">
						<HomePageLogo />

						<h1>Stojíme v čele českého zubního lékařství</h1>
						<cite>
							Česká stomatologická komora ze zákona sdružuje všechny zubní lékaře v České republice
						</cite>
					</div>

				</div>
				<div className="col-md-4 col-lg-3 d-flex flex-column justify-content-between">
					<Link to="/zubni-lekari" className="btn btn--light home-page__btn-main mb-3">
						<span className="btn__text d-flex"><ToothIcon className="home-page__tooth-icon mr-3" /> <span>Vyhledej zubního lékaře</span></span>
					</Link>
					<Link to="/vzdelavani/akce" className="btn btn--light home-page__btn-main mb-3">
						<span className="btn__text">{contents.getRes("kalendar-akci-nadpis")}</span>
					</Link>
					<a href="http://www.lks-casopis.cz" className="btn btn--light  home-page__btn-main mb-3">
						<span className="btn__text">Časopis LKS</span>
					</a>
					<a href="https://cspzl.dent.cz" className="btn btn--light home-page__btn-main mb-3">
						<span className="btn__text">Časopis ČSPZL</span>
					</a>
					<a href="https://www.republikabezkazu.cz" className="btn btn--light home-page__btn-main mb-3">
						<span className="pt-1 pb-1 btn__text d-flex">
							Republika bez kazu
						</span>
					</a>
					<a href="/images/Jak najít zubního lékaře - brožura.pdf" download className="btn btn--light home-page__btn-main mb-3">
						<span className="pt-1 pb-1 btn__text d-flex">
							Jak najít zubního lékáře?
						</span>
					</a>

				</div>
			</div>
			{/* <div className="homepage-temporary">
				Dobrý den, omlouváme se, v těchto dnech probíhá aktualizace webu.
			</div> */}
			{/* <h2 className="h1 d-flex align-items-center">
				{news.justLoadingData() &&
					<Spinner small className="mr-2" />
				}
				Aktuality
				<Link to="/aktuality" className="u-f-s-sm u-text-uppercase ml-2">[zobrazit všechny]</Link>
			</h2>
			<TimeLine
				items={news.getData()}
				mark={i => formatting.formatDate(i.valid_from, { format: "long" })}
				content={i => <NewsTimeLineItem news={i} />}
			/> */}
			<h2 className="h1 d-flex align-items-center">
				{actions.justLoadingData() &&
					<Spinner small className="mr-2" />
				}
				Akce
				<Link to="/vzdelavani/akce" className="u-f-s-sm u-text-uppercase ml-2">[zobrazit všechny]</Link>
			</h2>
			<TimeLine
				items={actions.getData()}
				mark={i => formatting.formatDate(i.date_from, { format: "long" })}
				content={i => <EducationalActionsTimeLineItem action={i} />}
			/>
		</Page >
	);
}

export default state.bindContainers(
	HomePage,
	context => context.home
);