/**
 * Stránka novinek
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import JoindedList from "../../../components/controls/JoinedList";
import Spinner from "../../../components/controls/Spinner";
import MapyCzMap from "../../../components/controls/MapyCzMap";

function DentistPage() {
	const title = "Karta zubaře";
	const context = state.useStateContext();
	const workplace = context.dentist.getWorkplace();
	const justLoading = context.dentist.getJustLoading();

	return (
		<Page title={title} description="">
			<div className="box-detail">
				<h1>
					{justLoading &&
						<><Spinner /> Načítám zubního lékaře...</>
					}
					{workplace?.name}
				</h1>
				<div className="box-detail__inner">
					<div className="box-detail__item">
						<p>
							<strong>
								Kontakty:
							</strong>
						</p>
						<p>
							{workplace?.address?.street}<br />
							{workplace?.address?.postcode} {workplace?.address?.city}<br />
							<strong>
								<JoindedList
									items={[
										workplace?.contact?.phone1,
										workplace?.contact?.phone2
									]}
									beforeElement={
										<>Tel.:&nbsp;</>
									}
									itemTemplate={(item) =>
										<a href={"tel:" + item}>
											{item}
										</a>
									}
									delimiter=", "
									afterElement={<br />}
								/>
								<JoindedList
									items={[
										workplace?.contact?.email1,
										workplace?.contact?.email2
									]}
									beforeElement={
										<>E-mail:&nbsp;</>
									}
									itemTemplate={(item) =>
										<a href={"mailto:" + item}>
											{item}
										</a>
									}
									delimiter=", "
									afterElement={<br />}
								/>
							</strong>
						</p>
						<p>
							<strong>
								Zubní lékaři:
							</strong>
						</p>
						<p>
							{workplace?.membes.map(i =>
								<>{i.full_name}<br /></>
							)}
						</p>
						{workplace?.insurance_companies.filter(x => x.name != "Neuvedeno").length! > 0 &&
							<>
								<p>
									<strong>
										Pojišťovny:
									</strong>
								</p>
								<p>
									{workplace?.insurance_companies.map(i =>
										<>{i.name != "Neuvedeno" &&
											<>{i.name}<br /></>
										}
										</>
									)}
								</p>
							</>
						}
						{workplace?.contact?.web &&
							<>
								<p>
									<strong>
										Web:
									</strong>
								</p>
								<a href={workplace?.contact?.web}>
									{workplace?.contact?.web}
								</a>
							</>
						}
						{workplace?.type_cares && workplace?.type_cares.length > 0 &&
							<>
								<p>
									<strong>
										Zdravotní péče v oboru:
									</strong>
								</p>
								<p>
									{workplace?.type_cares.map(i =>
										<>{i.name != "Neuvedeno" &&
											<>{i.name}<br /></>
										}
										</>
									)}
								</p>
							</>
						}
					</div>
					<div className="box-detail__item">
						{workplace?.gps?.longitude && workplace?.gps?.latitude &&
							<MapyCzMap title={workplace?.name} longitude={workplace?.gps?.longitude} latitude={workplace?.gps?.latitude} />
						}
					</div>
				</div>
			</div>
		</Page >
	);
}

export default state.bindContainers(
	DentistPage,
	c => c.dentist
);