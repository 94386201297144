/**
 *  Formulář žádosti o registraci akce
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormSelect from "../../../components/controls/Forms/FormSelect";

import Button from "../../../components/controls/Button";


interface WebRequestOrderLksPageProps {
	id?: string;
}


function WebRequestOrderLksPage(props: WebRequestOrderLksPageProps) {

	const model = state.useStateContext().webRequestOrderLks;

	const form = model.form;
	const buyer = model.form.getField("buyer").value;

	return (
		<WebRequest authorized={false} id={props.id} model={model.common} title="ZÁVAZNÁ OBJEDNÁVKA - PŘEDPLATNÉ ČASOPIS LKS" description="">

			<CheckBoxRadioFrame>
				<FormRadio
					form={form}
					field="buyer"
					option={1}
					title="student zubního lékařství v ČR, 4. nebo 5. ročník"
				/>
				<FormRadio
					form={form}
					field="buyer"
					option={3}
					title="firma, instituce, soukromá osoba apod"
				/>
			</CheckBoxRadioFrame>

			{buyer != 2 &&
				<>

					<h2 className="mb-5">Informace o objednateli:</h2>

					<div className="row">
						<div className="col-md">
							{buyer !== 1 &&
								<>
									<FormInput
										form={form}
										field="title_before"
									/>
									<FormInput
										form={form}
										field="title_pedagogical"
									/>
									<FormInput
										form={form}
										field="title_after"
									/>
								</>
							}

							<FormInput
								form={form}
								field="name"
							/>
							<FormInput
								form={form}
								field="lastname"
							/>
							{buyer === 1 &&
								<FormSelect
									form={form}
									field="school"
									options={[
										{
											title: "",
											value: ""
										},
										{
											title: "LF UK Praha",
											value: "LF UK Praha"
										},
										{
											title: "LF UK Plzeň",
											value: "LF UK Plzen"
										},
										{
											title: "LF UK Hradec Králové",
											value: "LF UK Hradec Kralove"
										},
										{
											title: "LF MU Brno",
											value: "LF MU Brno"
										},
										{
											title: "LF UP Olomouc",
											value: "LF UP Olomouc"
										}
									]}
								/>
							}
						</div>
						<div className="col-md">
							<FormInput
								form={form}
								field="tel"
							/>
							<FormInput
								form={form}
								field="email"
							/>
							{buyer === 1 &&
								<FormSelect
									form={form}
									field="study_year"
									options={[
										{
											title: "",
											value: ""
										},
										{
											title: "4. ročník",
											value: "4."
										},
										{
											title: "5. ročník",
											value: "5."
										}
									]}
								/>
							}
						</div>
					</div>

					<h2 className="mb-5">Adresa pro zasílání časopisu: (jen adresa v ČR)</h2>


					<div className="row">
						<div className="col-md">
							{buyer === 3 &&
								<FormInput
									form={form}
									field="company_name"
								/>
							}

							<FormInput
								form={form}
								field="street"
							/>
							<FormInput
								form={form}
								field="city"
							/>
						</div>
						<div className="col-md">
							<FormInput
								form={form}
								inputProps={{ type: "number" }}
								hideNumeric={true}
								field="psc"
							/>
							<FormSelect
								form={form}
								field="state"
								options={[
									{
										title: "ČR",
										value: "čr"
									}
								]}
							/>
						</div>
					</div>


					{buyer !== 1 &&
						<>
							<h2 className="mb-5">Fakturační adresa:</h2>

							<div className="row">
								<div className="col-md">
									<FormInput
										form={form}
										field="billing_name"
									/>
									<FormInput
										form={form}
										field="billing_street"
									/>
									<FormInput
										form={form}
										field="billing_city"
									/>
									<FormInput
										form={form}
										hideNumeric={true}
										inputProps={{ type: "number" }}
										field="billing_psc"
									/>
								</div>
								<div className="col-md">
									{buyer === 3 &&
										<FormInput
											form={form}
											field="billing_state"
										/>
									}
									<FormInput
										form={form}
										field="billing_ico"
									/>
									<FormInput
										form={form}
										field="billing_dic"
									/>
								</div>
							</div>
						</>
					}

					<p>
						{buyer == 1 &&
							<strong>Objednávám předplatné časopisu LKS, akademický rok 2024/2025.</strong>
						}
						{buyer != 1 &&
							<strong>Objednávám předplatné časopisu LKS, rok 2024.</strong>
						}
						<br />
						Cena předplatného (včetně poštovného a DPH) činí: <strong>{model.getPriceString()}</strong>

						{buyer !== 1 &&
							<><br /> (Při zvýšení poštovného a DPH během roku si vyhrazujeme právo doúčtovat rozdíl.)</>
						}
					</p>

					{buyer !== 1 &&
						<>

							<div className="row">
								<div className="col-md">
									<FormInput
										form={form}
										field="print_count"
										inputProps={{
											min: 1,
											max: 2,
											type: "number"
										}}
									/>
								</div>
								<div className="mb-3 col-md row align-items-center">
									<div className="col">
										<span className="box-profile__label">Celkem k úhradě v Kč</span>
									</div>
									<div className="col">
										<span className="box-profile__value">{model.getPrice() * Number.parseInt(form.getField("print_count").value)}</span>
									</div>
								</div>
							</div>



						</>
					}

					<p>
						<em>
							{buyer === 1 ?
								<>
									<strong>UPOZORNĚNÍ:</strong> Student zubního lékařství v ČR ve 4. a 5. ročníku studia může odebírat časopis LKS zdarma. <br />
									Doručovací adresa je možná pouze v rámci ČR
								</>
								:
								<>
									<strong>UPOZORNĚNÍ:</strong> Bude Vám vystavena faktura s přiděleným variabilním symbolem. <br />
								</>
							}
						</em>
					</p>

					{(!props.id && model.form.getField("buyer").value != 2) &&
						<div className="text-right mt-5">
							<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
							<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
							<Button
								theme="primary"
								onClick={model.confirm}
								spinner={model.common.justSending()}
							>Odeslat</Button>
						</div>
					}
				</>
			}
			{model.form.getField("buyer").value == 2 &&
				<p className="font-weight-bold mt-3">Distribuce formou přeplatného po Slovenské republice zajišťuje Mediaprint-Kapa Pressegrosso a.s. email: <a href="mailto:predplatne@abompkapa.sk">predplatne@abompkapa.sk</a></p>
			}
		</WebRequest>
	);
}


export default state.bindContainers(
	WebRequestOrderLksPage,
	context => context.webRequestOrderLks
);