import * as React from "react";
import HtmlContent from "../../../components/controls/HtmlContent";
import { HashLink as Link } from "react-router-hash-link";
import * as formatting from "../../../lib/formatting";
import * as apiInformation from "../../shared/information/api-information";

interface NewsTimeLineItemProps {
	news: apiInformation.Information;
	stages?: boolean;
	forMembers?: boolean;
}

export default function NewsTimeLineItem(props: NewsTimeLineItemProps) {
	return (
		<div className="box-new">
			<Link to={(props.forMembers ? "/aktuality-pro-cleny/" : "/aktuality/") + props.news.id} className="box-new__inner news-page__time-line-content">
				<h3 className="mb-1 pb-1 time-line__bb">
					{props.news.title}
				</h3>
				<p className="box-new__date d-md-none">
					{formatting.formatDate(props.news.valid_from)}
				</p>
				<p>
					{props.news.perex}
				</p>
			</Link>
		</div>
	);
}