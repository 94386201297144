import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";

import { CardExpandableSelf as CardExpandable } from "./CardExpandable";

export interface RequestItem {
	name: string;
	link: string;
	marginBottom?: boolean;
}

export interface RequestGroup {
	title: string;
	description: string;
	items: RequestItem[];
}

export interface RequestsProps {
	items: RequestGroup[];
}

export default function Requests(props: RequestsProps) {
	return (
		<>
			{props.items.map(i =>
				<CardExpandable
					cardTitle={
						<>
							<h2 className="mt-0 mb-0">{i.title}</h2>
							<p className="mb-0">{i.description}</p>
						</>
					}
					className="mb-3"
				>
					<div className="mt-4">
						{i.items.map(i =>
							<div className={i.marginBottom ? "mb-3" : "mb-1"}>
								<Link to={i.link}>
									{i.name}
								</Link>
							</div>
						)}
					</div>
				</CardExpandable>
			)}
		</>
	);
}