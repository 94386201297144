/**
 * Seznam převodů na OSK
 */
import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import List from "../../system/lists/List";

function OskTransferFeesItemsPage() {
	const { oskTransferFeesItems } = state.useStateContext();
	const transferFee = oskTransferFeesItems.getTransferFee();
	return (
		<Page
			title={oskTransferFeesItems.list.getTitle()}
			description=""
			authorized
		>
			<h1 className="d-flex align-items-center">
				{oskTransferFeesItems.justLoadingTransferFee() &&
					<Spinner small className="mr-2" />
				}
				Položky převodu na OSK
				{transferFee &&
					<>: {formatting.formatDate(transferFee?.date_of_transfer)}, celkem: {formatting.formatCurrency(transferFee?.total_price_with_vat)}</>
				}
			</h1>
			<List
				list={oskTransferFeesItems.list}
				itemNode={(item, field) => {
					switch (field) {
						case "price_with_vat": return formatting.formatCurrency(item[field]);
						default: return undefined;
					}
				}}
			/>
		</Page>
	);
}

export default state.bindContainers(
	OskTransferFeesItemsPage,
	context => context.oskTransferFeesItems
);