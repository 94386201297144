import { useState } from "react";

export default function useSwitch() {
	const [isOn, setOpened] = useState(false);

	function toogle() {
		setOpened(!isOn);
	}

	return { isOn, toogle } as const;
}