/**
 * Profesní pojištění
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function ProfessionalInsurancePage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("profesni-pojisteni-nadpis")} description={contents.getRes("profesni-pojisteni-nadpis")}>
			<HtmlContent htmlKey="profesni-pojisteni-obsah" />
		</Page>
	);
}