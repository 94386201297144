import * as React from "react";

interface ToothIconProps {
	className?: string;
}

export default function ToothIcon(props: ToothIconProps) {
	return (
		<svg className={props.className ?? ""} version="1.0" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			width="31px" height="47px" viewBox="0 0 31 47" enable-background="new 0 0 31 47" xmlSpace="preserve">
			<g>
				<defs>
					<rect id="SVGID_1_" y="-0.048" width="30.984" height="46.096" />
				</defs>
				<clipPath id="SVGID_2_">
					<use xlinkHref="#SVGID_1_" overflow="visible" />
				</clipPath>
				<path clip-path="url(#SVGID_2_)" fill="#304581" d="M24.673-0.047c-2.277,0-4.772,1.619-7.043,2.603
					c1.726,0.564,3.785,0.87,6.387,0.938c-4.782,0.802-8.312,0.629-11.997-1.573C8.821,0.749,5.482,0.553,2.789,2.776
					c-4.394,3.626-2.695,11.45-0.982,16.803c1.19,2.86,2.867,5.297,5.037,7.606c-1.235-0.887-2.398-1.84-3.482-2.92
					c0.09,0.508,0.135,1.068,0.119,1.479c-0.651,16.316,7.74,24.744,9.534,17.93c0.637-2.418,0.332-12.006,2.936-12.439
					c1.433-0.234,2.048,6.998,2.193,8.467c0.353,3.58,0.499,5.064,1.577,5.252c1.983,0.355,7.831-4.535,6.789-19.111
					c-0.024-0.354,0.024-0.809,0.122-1.24c-0.511,0.438-1.033,0.863-1.571,1.281c0.831-1.004,1.609-2.035,2.333-3.098
					C34.547,8.171,29.614-0.047,24.673-0.047"/>
			</g>
		</svg>
	);
}