/**
 *  Formulář žádosti o registraci akce
 */

import React, { useState } from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormTextArea from "../../../components/controls/Forms/FormsTextArea";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import FormFileUpload from "../../system/file/FormFileUpload";

import Button from "../../../components/controls/Button";
import Radio from "../../../components/controls/Radio";
import Input from "../../../components/controls/Input";



interface WebRequestOrderCspzlPageProps {
	id?: string;
}



function CskMember() {

	const model = state.useStateContext().webRequestOrderCspzl;

	return (
		<>
			<h2 className="mb-5">Informace o objednateli:</h2>

			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.cskForm}
						field="title_before"
					/>
					<FormInput
						form={model.cskForm}
						field="title_pedagogical"
					/>
					<FormInput
						form={model.cskForm}
						field="title_after"
					/>
					<FormInput
						form={model.cskForm}
						field="name"
					/>
					<FormInput
						form={model.cskForm}
						field="lastname"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.cskForm}
						field="tel"
					/>
					<FormInput
						form={model.cskForm}
						field="email"
					/>
				</div>
			</div>

			<h2 className="mb-5">Adresa pro zasílání časopisu (jen adresa v ČR):</h2>

			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.cskForm}
						field="street"
					/>
					<FormInput
						form={model.cskForm}
						field="city"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.cskForm}
						inputProps={{ type: "number" }}
						hideNumeric={true}
						field="psc"
					/>
					<FormSelect
						form={model.studentForm}
						field="state"
						options={[
							{
								title: "ČR",
								value: "čr"
							}
						]}
					/>
				</div>
			</div>


			<h2 className="mb-5">Fakturační adresa:</h2>


			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.cskForm}
						field="billing_name"
					/>
					<FormInput
						form={model.cskForm}
						field="billing_street"
					/>
					<FormInput
						form={model.cskForm}
						field="billing_city"
					/>
					<FormInput
						form={model.cskForm}
						hideNumeric={true}
						inputProps={{ type: "number" }}
						field="billing_psc"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.cskForm}
						field="billing_ico"
					/>
					<FormInput
						form={model.cskForm}
						field="billing_dic"
					/>
				</div>
			</div>

			<hr />

			<p className="mb-5">
				<strong>Objednávám předplatné časopisu ČSPZL, ročník 2024. </strong> <br />
				Cena předplatného (včetně poštovného a DPH) činí: <strong>350 Kč</strong> <br />
				<em>(Při zvýšení poštovného a DPH během roku si vyhrazujeme právo doúčtovat rozdíl.)</em>
			</p>


			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.cskForm}
						inputProps={{
							max: "2",
							min: "1",
							type: "number"
						}}
						field="print_count"
					/>
				</div>
			</div>


			<div className="row">
				<div className="col-md-6">
					<label>Celkem k úhradě v Kč</label>
					<Input
						type="number"
						readOnly={true}
						value={Number.parseInt(model.cskForm.getField("print_count").value) * 350}
					/>
				</div>
			</div>

			<p>
				<strong>UPOZORNĚNÍ:</strong> <em> Bude Vám vystavena faktura s přiděleným variabilním symbolem. </em> <br />
			</p>
		</>
	);
}

function Student() {
	const model = state.useStateContext().webRequestOrderCspzl;

	return (
		<>
			<h2 className="mb-5">Informace o objednateli:</h2>

			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.studentForm}
						field="name"
					/>
					<FormInput
						form={model.studentForm}
						field="lastname"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.studentForm}
						field="tel"
					/>
					<FormInput
						form={model.studentForm}
						field="email"
					/>
				</div>
			</div>

			<h2 className="mb-5">Adresa pro zasílání časopisu: (jen adresa v ČR):</h2>

			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.studentForm}
						field="street"
					/>
					<FormInput
						form={model.studentForm}
						field="city"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.studentForm}
						inputProps={{ type: "number" }}
						hideNumeric={true}
						field="psc"
					/>
					<FormSelect
						form={model.studentForm}
						field="state"
						options={[
							{
								title: "ČR",
								value: "čr"
							}
						]}
					/>
				</div>
			</div>


			<h2 className="mb-5">Fakturační adresa:</h2>


			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.studentForm}
						field="company_name"
					/>
					<FormInput
						form={model.studentForm}
						field="company_street"
					/>
					<FormInput
						form={model.studentForm}
						field="company_city"
					/>
					<FormInput
						form={model.studentForm}
						hideNumeric={true}
						inputProps={{ type: "number" }}
						field="company_psc"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.studentForm}
						field="company_ico"
					/>
					<FormInput
						form={model.studentForm}
						field="company_dic"
					/>
				</div>
			</div>

			<hr />

			<p className="mb-5">
				<strong>Objednávám předplatné časopisu ČSPZL, ročník 2024. </strong> <br />
				Cena předplatného (včetně poštovného a DPH) činí: <strong>350 Kč</strong> <br />
				<em>(Při zvýšení poštovného a DPH během roku si vyhrazujeme právo doúčtovat rozdíl.)</em>
			</p>


			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.studentForm}
						inputProps={{
							max: "2",
							min: "1",
							type: "number"
						}}
						field="print_count"
					/>
				</div>
			</div>


			<div className="row">
				<div className="col-md-6">
					<label>Celkem k úhradě v Kč</label>
					<Input
						type="number"
						readOnly={true}
						value={Number.parseInt(model.studentForm.getField("print_count").value) * 350}
					/>
				</div>
			</div>

			<p>
				<strong>UPOZORNĚNÍ:</strong> <em> Bude Vám vystavena faktura s přiděleným variabilním symbolem. </em> <br />
			</p>

		</>
	);
}

function CompanyOrInstitution() {
	const model = state.useStateContext().webRequestOrderCspzl;

	return (
		<>
			<h2 className="mb-5">Informace o objednateli:</h2>

			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.companyForm}
						field="title_before"
					/>
					<FormInput
						form={model.companyForm}
						field="title_pedagogical"
					/>
					<FormInput
						form={model.companyForm}
						field="title_after"
					/>
					<FormInput
						form={model.companyForm}
						field="name"
					/>
					<FormInput
						form={model.companyForm}
						field="lastname"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.companyForm}
						field="tel"
					/>
					<FormInput
						form={model.companyForm}
						field="email"
					/>
				</div>
			</div>

			<h2 className="mb-5">Adresa pro zasílání časopisu (jen adresa v ČR):</h2>

			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.companyForm}
						field="company_name"
					/>
					<FormInput
						form={model.companyForm}
						field="street"
					/>
					<FormInput
						form={model.companyForm}
						field="city"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.companyForm}
						inputProps={{ type: "number" }}
						hideNumeric={true}
						field="psc"
					/>
					<FormInput
						form={model.companyForm}
						field="state"
					/>
				</div>
			</div>


			<h2 className="mb-5">Fakturační adresa:</h2>


			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.companyForm}
						field="billing_name"
					/>
					<FormInput
						form={model.companyForm}
						field="billing_street"
					/>
					<FormInput
						form={model.companyForm}
						field="billing_city"
					/>
				</div>
				<div className="col-md-6">
					<FormInput
						form={model.companyForm}
						hideNumeric={true}
						inputProps={{ type: "number" }}
						field="billing_psc"
					/>
					<FormInput
						form={model.companyForm}
						field="billing_state"
					/>
					<FormInput
						form={model.companyForm}
						field="billing_ico"
					/>
					<FormInput
						form={model.companyForm}
						field="billing_dic"
					/>
				</div>
			</div>

			<hr />

			<p className="mb-5">
				<strong>Objednávám předplatné časopisu ČSPZL, ročník 2024. </strong> <br />
				Cena předplatného (včetně poštovného a DPH) činí: <strong>700 Kč</strong> <br />
				<em>(Při zvýšení poštovného a DPH během roku si vyhrazujeme právo doúčtovat rozdíl.)</em>
			</p>


			<div className="row">
				<div className="col-md-6">
					<FormInput
						form={model.companyForm}
						inputProps={{
							max: "2",
							min: "1",
							type: "number"
						}}
						field="print_count"
					/>
				</div>
			</div>


			<div className="row">
				<div className="col-md-6">
					<label>Celkem k úhradě v Kč</label>
					<Input
						type="number"
						readOnly={true}
						value={Number.parseInt(model.companyForm.getField("print_count").value) * 700}
					/>
				</div>
			</div>

			<p>
				<strong>UPOZORNĚNÍ:</strong> <em> Bude Vám vystavena faktura s přiděleným variabilním symbolem. </em> <br />
			</p>
		</>
	);
}


function WebRequestOrderCspzlPage(props: WebRequestOrderCspzlPageProps) {
	const model = state.useStateContext().webRequestOrderCspzl;
	const title = "ZÁVAZNÁ OBJEDNÁVKA - ČASOPIS ČESKÁ STOMATOLOGIE A PRAKTICKÉ ZUBNÍ LÉKAŘSTVÍ";


	return (
		<WebRequest authorized={false} id={props.id} model={model.common} title={title} description="">

			<CheckBoxRadioFrame>
				<Radio onCheckedChanged={() => model.changeBuyer(1)} checked={model.getBuyer() === 1} title="člen ČSK" />
				<Radio onCheckedChanged={() => model.changeBuyer(2)} checked={model.getBuyer() === 2} title="student zubního lékařství v ČR" />
				<Radio onCheckedChanged={() => model.changeBuyer(3)} checked={model.getBuyer() === 3} title="firma, instituce, soukromá osoba apod." />
			</CheckBoxRadioFrame>


			{{
				1: <CskMember />,
				2: <Student />,
				3: <CompanyOrInstitution />
			}[model.getBuyer()]}




			{!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button
						theme="primary"
						onClick={model.confirm}
						spinner={model.common.justSending()}
					>Odeslat</Button>
				</div>
			}

		</WebRequest>
	);
}



export default state.bindContainers(
	WebRequestOrderCspzlPage,
	ctx => ctx.webRequestOrderCspzl
);

