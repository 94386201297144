/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import useSwitch from "../../../hooks/switch";
import List from "../../system/lists/List";

function MyRecordsPage() {
	const { myRecords: grid } = state.useStateContext();
	const newRecordSwitch = useSwitch();
	return (
		<Page
			title="Seznam záznamů"
			description=""
			authorized
		>
			<h1>
				Seznam záznamů
			</h1>
			<List simpleTable
				list={grid.list}
				itemNode={(item, field) => field === "link" ? <a href={item.link}>Stáhnout</a> : undefined}
			/>
		</Page>
	);
}

export default state.bindContainers(
	MyRecordsPage,
	context => context.myRecords
);