import * as React from "react";

import Card, { CardProps } from "./Card";
import Link from "./Link";
import IconSvg from "./IconSvg";

type CardLinkProps = React.HTMLProps<HTMLAnchorElement> & {
	cardProps?: CardProps
};

export default function CardLink(props: CardLinkProps) {
	const { cardProps, className, ...restProps } = props;
	const { className: cardClassName, ...restCardProps } = cardProps ?? {};

	return (
		<Link className={"card-link " + (className ?? "")}  {...restProps} >
			<Card className={"card-link__card " + (cardClassName ?? "")} {...restCardProps}>
				<div className="w-100">{props.children}</div>
				<div><IconSvg name="arrow-right" className="card-link__arrow" /></div>
			</Card>
		</Link>
	);
}