import * as React from "react";
import * as forms from "../../../lib/forms";

import FormItem, { FormItemOptions } from "./FormItem";
import Radio, { RadioProps } from "../Radio";


export interface FormsInputProps<TFormFields, TField extends keyof TFormFields> {
	form: forms.Form<TFormFields>;
	field: TField;
	option: TFormFields[TField];
	className?: string;
	checkBoxProps?: RadioProps;
	formItemOptions?: FormItemOptions;
	title?: React.ReactNode;
}

export default function FormsRadio<TFormFields, TField extends keyof TFormFields>(props: FormsInputProps<TFormFields, TField>) {
	const field = props.form.getField(props.field);
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={{ ...props.formItemOptions, hideTitle: true }}
			className={props.className}
		>
			<Radio
				{...props.checkBoxProps}
				title={props.title}
				checked={field.value === props.option}
				readOnly={props.checkBoxProps?.readOnly ? props.checkBoxProps?.readOnly : field.readOnly}
				onCheckedChanged={value => props.form.setField(props.field, props.option)}
			/>
		</FormItem>
	);
}