/**
 * Dialog pro dms dialog
 */
import React, { useEffect } from "react";
import * as state from "../../../lib/state";

import Dialog from "./Dialog";
import EnterPressArea from "../../../components/controls/EnterPressArea";
import List from "../../../modules/system/lists/List";
import Button from "../../../components/controls/Button";
import ListDms from "./ListDms";

function DmsDialog() {
	const { dmsDialog } = state.useStateContext();
	const dialog = dmsDialog.dmsDialog;

	return (
		<Dialog className="dialog-min-width-900" dialog={dialog}>
			<EnterPressArea>
				<ListDms />
				<div className="mt-4 text-center">
					<Button onClick={e => dmsDialog.dmsDialog.close()} className="pt-2 pb-2 float-right">
						<strong>Zavřít</strong>
					</Button>
				</div>
			</EnterPressArea>
		</Dialog >
	);
}

export default state.bindContainers(DmsDialog,
	context => context.dmsDialog
);
