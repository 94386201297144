/**
 *  Formulář žádosti o registraci akce
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormTextArea from "../../../components/controls/Forms/FormsTextArea";
import Button from "../../../components/controls/Button";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import FormsAutocomplete from "../../../components/controls/Forms/FormsAutocomplete";
import FormsSelect from "../../../components/controls/Forms/FormSelect";

interface WebRequestActionRegistrationPageProps {
	id: string;
}

function WebRequestActionELearningRegistrationPage(props: WebRequestActionRegistrationPageProps) {

	const model = state.useStateContext().webRequestWebRequestActionElearningRegistration;
	const form = model.form;
	const provider = form.getField("provider").value;

	return (
		<WebRequest id={props.id} model={model.common} authorized={false} title="ŽÁDOST O REGISTRACI E-LEAARNINGOVÉ AKCE" description="">

			<div className="secondary-title">Pořadatel</div>

			<p>
				Pokud jste nový pořadatel, použijte možnost "Nově registrovaný pořadatel"
			</p>

			<CheckBoxRadioFrame className="mb-3">
				<div className="row">
					<div className="col-12">
						<FormRadio
							form={form}
							field="provider_type"
							option="existing_provider"
							title="výběr z již registrovaného pořadatele"
						/>
					</div>
					<div className="col-12">
						<FormRadio
							form={form}
							field="provider_type"
							option="new_provider"
							title="nově registrovaný pořadatel"
						/>
					</div>
				</div>
			</CheckBoxRadioFrame>

			{model.form.getField("provider_type").value == "existing_provider" &&
				<>
					<FormsAutocomplete
						form={form}
						field="provider"
						autocompleteProps={{
							trigger: "onfocus",
							loadItems: model.loadProvider,
							getItemText: item => item?.name ?? "",
							getMenuItem: item => item?.name
						}}
					/>
					{provider &&
						<div className="row mb-3">
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Poskytovatel</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{provider.name}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Registrační číslo pořadatele</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{provider.name}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Telefon</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{provider.contact?.phone1}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">E-mail</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{provider.contact?.email1}</span>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">IČO</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{provider.registration_number}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Ulice a číslo</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{provider.address?.street}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Obec</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{provider.address?.city}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">PSČ</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{provider.address?.postcode}</span>
									</div>
								</div>
							</div>
						</div>
					}
				</>
			}

			{model.form.getField("provider_type").value == "new_provider" &&
				<>
					<div className="row mt-3 mb-1">
						<div className="col-lg-6">
							<FormInput form={form} field="provider_name" />
							<FormInput form={form} field="provider_number" />
							<FormInput form={form} field="provider_phone" />
							<FormInput form={form} field="provider_email" />
						</div>
						<div className="col-lg-6">
							<FormInput form={form} field="provider_registration_number" />
							<FormInput form={form} field="provider_street" />
							<FormInput form={form} field="provider_city" />
							<FormInput hideNumeric={true} form={form} inputProps={{ type: "number" }} field="provider_postcode" />
						</div>
					</div>
				</>
			}

			<div className="secondary-title">Informace o akci</div>

			<div className="row">
				<div className="col-md-6">
					<FormInput form={form} field="action_name" />
				</div>
				<div className="col-md-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={form}
						field="from"
					/>
				</div>
				<div className="col-md-3">
					<FormInput
						inputProps={{ type: "date" }}
						form={form}
						field="to"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<FormInput form={form} field="action_place_url" />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<FormInput form={form} field="email" />
				</div>
				<div className="col-md-6">
					<FormInput form={form} field="phone" />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<FormInput form={form} field="price" />
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<FormTextArea form={form} field="description" />
				</div>
			</div>

			<div className="secondary-title">Lektoři</div>

			{
				model.formsLectors.get().map((item) =>
					<div className="row mt-2">
						<div className="col-md-2">
							<FormsSelect form={item} field="title_before" options={
								model.getTitlesBefore().map(i => ({
									title: i.name,
									value: i
								}))
							} />
						</div>
						<div className="col col-md-3">
							<FormInput
								form={item}
								field="first_name"
							/>
						</div>
						<div className="col col-md-3">
							<FormInput
								form={item}
								field="last_name"
							/>
						</div>
						<div className="col-md-2">
							<FormsSelect form={item} field="title_after" options={
								model.getTitlesAfter().map(i => ({
									title: i.name,
									value: i
								}))
							} />
						</div>
						<div className="col-md-2">
							<Button className="d-block w-100 mt-4" onClick={() => model.removeLector(item)}>Odebrat lektora</Button>
						</div>
					</div>
				)
			}

			<Button onClick={model.addLector} className="mb-5 mt-2">Přidat nového lektora</Button>


			{
				!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}

		</WebRequest >
	);
}


export default state.bindContainers(
	WebRequestActionELearningRegistrationPage,
	c => c.webRequestWebRequestActionElearningRegistration
);