/**
 * Model žádosti o vydání osvědčení o splnění podmínek k výkonu funkce odborného zástupcE
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as notification from "../../../lib/notification";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

interface Credit {
	id: string
	checked: boolean;
	date: Date;
	description: string;
	value: string;
}

export interface FormFields {
	care: "zubni-lekarstvi" | "ortodencie" | "ustni-celistni-oblicejova-chirurgie" | "klinicka-stomatologie";
	multiple_declaration: "5_years_of_experience" | "no_need_to_prove_practice" | "serious_reasons";
	serious_reasons: "maternity_and_parental_leave" | "serious_health_reasons" | "study_stay_abroad" | "damage_due_to_natural_disaster" | "other";
	amount: number;
	declaration: boolean;
	second_declaration: boolean;
	third_declaration: boolean;
	attachments: FileFormFields[];
	credits: Credit[];
	request_text: string;
}

interface FormData extends FormFields {
	attachments: FileFormFields[];
}

export interface FileFormFields {
	attachment?: api.CodeBookItem;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;
	public formsFiles: forms.FormCollection<FileFormFields>;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				attachments: {
					title: "Příloha",
					defaultValue: [],
				},
				credits: {
					defaultValue: [],
					title: ""
				},
				care: {
					title: "Poskytování zdravotní péče v oboru",
					defaultValue: "zubni-lekarstvi"
				},
				declaration: {
					title: "Čestně prohlašuji, že jsem zdravotně způsobilý/á k výkonu povolání zubního lékaře.",
					validate: value => !value ? "Prohlášení je povinné pole" : "",
					defaultValue: false
				},
				second_declaration: {
					title: "Čestně prohlašuji, že jsem nebyl/a pravomocně odsouzen/a za trestný čin spáchaný v souvislosti s výkonem povolání zubního lékaře.",
					validate: value => !value ? "Prohlášení je povinné pole" : "",
					defaultValue: false
				},
				third_declaration: {
					title: "A zároveň čestně prohlašuji, že zbývající dobu, na kterou nežádám prominutí praxe, jsem vykonával/a povolání zubního lékaře.",
					defaultValue: false,
					required: true,
					validate: (value, field, form) => form.getField("multiple_declaration").value == "serious_reasons" && !value ? "Prohlášení je povinné pole" : "",
				},
				multiple_declaration: {
					title: "",
					defaultValue: "5_years_of_experience"
				},
				serious_reasons: {
					title: "Závažné důvody, pro které jsem nemohl/a absolvovat praxi:",
					defaultValue: "maternity_and_parental_leave"
				},
				amount: {
					title: "",
					defaultValue: 0
				},
				request_text: { defaultValue: "", title: "" }
			}
		}, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Jako doklad k žádosti přikládám",
					defaultValue: undefined,
					required: (value) => this.form.getField("multiple_declaration").value == "serious_reasons" ? true : false,
					validate: (value, field, form) => this.form.getField("multiple_declaration").value == "serious_reasons" && !value ? "Prohlášení je povinné pole" : "",
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
	}

	/**
 * Vrací kolekci stavových kontejnerů
 */
	getStateContainers = () => [
		...this.common.getStateContainers(),
		...this.form.getStateContainers()
	]

	addFile = () => this.formsFiles.add();

	removeFile = async (formFile: forms.Form<FileFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? this.formsFiles.remove(formFile)
			: undefined

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const certificate = this.context.authorization.getUserProfile()?.profile.certificate;
			if (!certificate && this.form.getField("credits").value.filter(x => x.checked).reduce((a, b) => a + Number(b.value), 0) < 30) {
				notification.errorMessage("Musí být vybráno minimálně 30 kreditů")
				return;
			}
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			await this.common.send(data, "zadost-o-vydani-osvedceni-odborny-zastupce");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	loadAmount = async () => {
		const amount = await this.context.api.get<number>("/request_types/" + "zadost-o-vydani-osvedceni-odborny-zastupce" + "/price/", {});
		await this.form.setField("amount", amount);
	}

	/**
	 * Načte kredity
	 */
	loadCredits = async () => {
		const credits = await this.context.api.post("/users/records/credits", {}) as Credit[];
		const credit = this.form.getField("credits").value;
		if (credit.length == 0) {
			await this.form.setField("credits", credits);
		}
	}

	toggleCredit = async (category: Credit) => {
		const credits = this.form.getField("credits").value;
		await this.form.setField("credits", credits.map(i => ({ ...i, checked: i.id === category.id ? !i.checked : i.checked })));
	}

	loadText = async () => {
		const result = await this.context.api.get("/certificate_text?type=1") as any;
		if (result) {
			this.form.setField("request_text", result.data);
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormFields;
			const { attachments, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.formsFiles.setFields(attachments),
				this.loadCredits(),
				this.loadText()
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadAmount(),
			this.loadCredits(),
			this.loadText()
		]);
	}
}