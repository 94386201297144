/**
 * Model žádosti o změnu kontaktních údajů
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";
import * as validations from "../../../lib/validations";

export type LskJournalTargetAddress = "contact-address" | "workplace-address";

/**
 * Stav modelu
 */
interface State {
	originalAddressFormFields: AddressFormFields;
}

/**
 * Definice dat formuláře
 */
export interface FormFields {
	subscribe_lsk_journal: boolean;
	lsk_journal_address: LskJournalTargetAddress;
	subscribe_mailing: boolean;
	subscribe_print_monitoring: boolean;
	loaded: boolean;
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		subscribe_lsk_journal: {
			title: "Mám zájem o odběr časopisu LKS (pro členy zdarma)",
			defaultValue: false
		},
		lsk_journal_address: {
			title: "LKS bude zasílán na adresu:",
			defaultValue: "contact-address"
		},
		subscribe_mailing: {
			title: "Mám zájem o zasílání hromadných informačních e-mailů od ČSK",
			defaultValue: false,
		},
		subscribe_print_monitoring: {
			title: "Mám zájem o zasílání monitoringu tisku e-mailem",
			defaultValue: false
		},
		loaded: {
			defaultValue: false,
			title: ""
		}
	}
};

/**
 * Definice dat formuláře s adresou a kontaktem
 */
export interface AddressFormFields {
	street: string;
	contact_street: string;
	city: string;
	contact_city: string;
	postcode: string;
	contact_postcode: string;
	phone: string;
	email: string;
}

/**
 * Definice formuláře s adresou a kontaktem
 */
const addressFormOptions: forms.FormOptions<AddressFormFields> = {
	fields: {
		street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => !value ? "Ulice je povinné pole" : ""
		},
		city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => !value ? "Obec je povinné pole" : ""
		},
		postcode: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => !value ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		contact_street: {
			title: "Ulice a číslo",
			defaultValue: "",
			required: true,
			validate: value => !value ? "Ulice je povinné pole" : ""
		},
		contact_city: {
			title: "Obec",
			defaultValue: "",
			required: true,
			validate: value => !value ? "Obec je povinné pole" : ""
		},
		contact_postcode: {
			title: "PSČ",
			defaultValue: "",
			required: true,
			validate: value => !value ? "PSČ je povinné pole" : value.trim().length == 5 ? "" : "PSČ musí obsahovat 5 znaků"
		},
		phone: {
			title: "Telefon",
			defaultValue: "",
			placeHolderText: "+420 XXX XXX XXX",
			validate: value => value.trim().length === 0 ? "Telefon je povinný údaj" : !validations.isPhoneNumber(value) ? "Telefon je v chybném formát" : ""
		},
		email: {
			title: "E-mail",
			defaultValue: "",
			validate: value => value.trim().length === 0
				? "Vyplňte prosím e-mail"
				: validations.validateEmailAddress(value),
		},
	}
};

/**
 * Data odesílaná a načítaná
 */
interface FormData extends FormFields {
	address: AddressFormFields;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public formAddress: forms.Form<AddressFormFields>;
	public common: webRequest.Model;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			originalAddressFormFields: {
				city: "",
				email: "",
				phone: "",
				postcode: "",
				street: "",
				contact_city: "",
				contact_postcode: "",
				contact_street: ""
			},
		}, context);
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);
		this.formAddress = new forms.Form<AddressFormFields>(addressFormOptions, context, this.form);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	loadDefaultData = async () => {
		const user = this.context.authorization.getUserProfile() ?? await this.context.authorization.loadUserProfile();

		const formFields: FormFields = {
			subscribe_lsk_journal: user.profile.journal_subscribe,
			lsk_journal_address: user.profile.journal_address.id == 1 ? "contact-address" : "workplace-address",
			subscribe_mailing: user.profile.send_bulk_emails,
			subscribe_print_monitoring: user.profile.send_monitoring,
			loaded: true
		};

		const addressFormFields: AddressFormFields = {
			street: user.profile.address?.street ?? "",
			city: user.profile.address?.city ?? "",
			postcode: user.profile.address?.postcode == undefined ? "" : user.profile.address?.postcode.replace(/\s+/g, ""),
			phone: user.profile.contact?.phone1 ?? user.profile.contact?.phone2 ?? "",
			email: user.profile.contact?.email1 ?? user.profile.contact?.email2 ?? "",
			contact_street: user.profile.delivery_address?.street ?? "",
			contact_city: user.profile.delivery_address?.city ?? "",
			contact_postcode: user.profile.delivery_address?.postcode == undefined ? "" : user.profile.delivery_address?.postcode.replace(/\s+/g, ""),
		};
		await this.stateContainer.merge(_ => ({
			originalFormFields: formFields,
			originalAddressFormFields: addressFormFields,
		}));


		await this.form.setFields(formFields);
		await this.formAddress.setFields(addressFormFields);
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				address: this.formAddress.getFields(),
			};
			await this.common.send(data, "zadost-o-zmenu-kontaktnich-udaju");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	checkChangedAddressField = (field: keyof AddressFormFields) => {
		const value = this.formAddress.getFields()[field];
		const originalValue = this.stateContainer.get().originalAddressFormFields[field];
		return originalValue == value ? "" : "bg-input-changed";
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { address, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.formAddress.setFields(address),
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
		]);
		await this.loadDefaultData();
	}
}