/*
 * Univerzální sdílená klienta pro sdílené samostatné funkce mimo vlastní moduly.
 */

/**
 * Pomocný typ pro AllowedNames
 */
type FilterFlags<Base, Condition> = {
	[Key in keyof Base]: Base[Key] extends Condition ? Key : never
};
/**
 * Pomocný typ pro SubType
 */
type AllowedNames<Base, Condition> = FilterFlags<Base, Condition>[keyof Base];

/**
 * Definuje podmnožinu typu Base, která obsahuje properties pouze typu Condition.
 */
export type SubType<Base, Condition> = Pick<Base, AllowedNames<Base, Condition>>;

/**
 *  Vrací HOC funkci, která volání předané funkce fn odloží až po ukočení případného sledu volání.
 *  Sledem přitom rozumíme řadu opakovaného volání s kratšími prodlevami, než parametr wait.
 */
export function debounce(fn: () => void, wait: number, context?: any): () => void {
	let timeout: any = null;
	let callbackArgs: any = null;

	const later = () => fn.apply(context, callbackArgs);

	return function () {
		callbackArgs = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

export async function deffered(fn: () => void): Promise<void> {
	return new Promise<void>((resolve) => {
		setTimeout(() => { fn(); resolve(); }, 0);
	});
}

/**
 * Pomocná funkce do příkazů switch pro exhaustivní statickou kontrolu ošetření všech větví.
 */
export function unwanted(v: never): never {
	throw new Error("Unwanted reached place");
}

/**
 * Generuje pseudo-náhodný řetězec složený z číslic hexadecimální abecedy.
 */
export function unique() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	return s4() + s4() + s4() + s4();
}

/**
 * Vrací promise, které bude resolvována za timeOout milisekund. 
 */
export async function sleep(timeOut: number) {
	return new Promise(resolve => setTimeout(resolve, timeOut));
}

/**
 *  HOC funkce pro indikaci průběhu asynchronní operace execFunc(). Indikaci nastavuje
 *  funkce indicateStart() a ukončuje indicateFinish(). Funkce indicateStart() je volána se
 *  zpožděním 500ms.
 */
export async function withIndication<ExcecResult>(options: IndicationOptions<ExcecResult>) {
	if (options.start) {
		await options.start();
	}

	const timeOut = options.indicateStart ? setTimeout(options.indicateStart, 0) : undefined;

	try {
		return await options.exec();
	} finally {
		if (timeOut) {
			clearTimeout(timeOut);
		}
		if (options.finish) {
			await options.finish();
		}
	}
}

interface IndicationOptions<ExecResult> {
	/**
	 * Výkonná funkce
	 */
	exec: () => Promise<ExecResult>;

	/**
	 * Tato funkce bude zavolána a dokončena vždy před výkonnou funkcí.
	 */
	start?: () => Promise<void>;

	/**
	 * Tato funkce bude zavolána se spožděním 500ms. Výkonná funkce v tu dobu již může být zpožděna.
	 */
	indicateStart?: () => Promise<void>;

	/**
	 * Finalizační funkce. Bude zavolána po dokončení nebo po výjimce ve výkonné funkci.
	 */
	finish?: () => Promise<void>;
}

/**
 * Obálka pro ignorování varování neošetřeného výsledku asynchronní funkce. 
 */
export function ignorePromises(...promises: Promise<any>[]) { }

/**
 * Detekce, zda aplikace běží na serveru
 */
export function serverExecution() {
	return typeof window === "undefined" || !window.document || !window.document.createElement;
}