/**
 * Seznam mých akcí
 */
import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Link from "../../../components/controls/Link";
import List from "../../system/lists/List";

function MyEducationalProgramsPage() {
	const { myMessages } = state.useStateContext();
	return (
		<Page
			title="Seznam zpráv"
			description=""
			authorized
		>
			<h1>
				Seznam zpráv
			</h1>
			<List simpleTable
				list={myMessages.list}
				itemNode={(item, field) => {
					switch (field) {
						case "subject": return <Link to={"/moje-agenda/zprava/" + item.id}>{item[field]}</Link>;
						default: return undefined;
					}
				}}
			/>
		</Page>
	);
}

export default state.bindContainers(
	MyEducationalProgramsPage,
	context => context.myMessages
);