import * as React from "react";
import * as forms from "../../../lib/forms";

import FormItem, { FormItemOptions } from "./FormItem";
import TextArea, { TextAreaProps } from "../TextArea";


export interface FormsTextAreaProps<TFormFields> {
	form: forms.Form<TFormFields>;
	field: keyof TFormFields;
	className?: string;
	textAreaProps?: TextAreaProps;
	formItemOptions?: FormItemOptions;
}

export default function FormTextArea<TFormFields>(props: FormsTextAreaProps<TFormFields>) {
	return (
		<FormItem
			form={props.form}
			field={props.field}
			options={props.formItemOptions}
			className={props.className}
		>
			<TextArea
				{...props.textAreaProps}
				placeholder={props.form.getFieldOptions(props.field).placeHolderText}
				value={props.form.getField(props.field).value as any}
				onChange={value => props.form.setField(props.field, value as any)}
			/>
		</FormItem>
	);
}