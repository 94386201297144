/**
 * Seznam inzerce
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as lists from "../../system/lists/lists";
import * as apiAdvertisments from "../../shared/advertisements/api-advertisements";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

/**
 * Stav modulu
 */
interface State {
	regions: apiCodeList.CodeList[];
	categories: apiCodeList.CodeList[];
	justLoadingCategories: boolean;
	justLoadingRegions: boolean;
}

/**
 * Fiedly pro filtrování seznamu
 */
interface FilterFields {
	region?: apiCodeList.CodeList;
	categories: api.Id[];
	my_advertisement: boolean;
}

/**
 * Modul stránky seznamu zubařů
 */
export class Model {
	public list: lists.StandardList<apiAdvertisments.Advertisement, FilterFields>;
	private stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoadingCategories: false,
			justLoadingRegions: false,
			regions: [],
			categories: []
		}, context);

		this.list = new lists.StandardList<apiAdvertisments.Advertisement, FilterFields>({
			context: context,
			standardEntityApi: context.apiAdvertisements.standard,
			defaultPageSize: 30,
			defaultFilterMode: "advanced",
			advancedFilter: {
				region: {
					field: {
						title: "Kraj",
						defaultValue: undefined
					},
					query: value => value ? [api.qp("region.id" as any, "=", value.id)] : []
				},
				categories: {
					field: {
						title: "Rubrika",
						defaultValue: []
					},
					query: value => value ? [api.qp("categories" as any, "=", value)] : []
				},
				my_advertisement: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => value ? [api.qp("my_advertisement" as any, "=", value)] : []
				}
			}
		});
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	/**
	 * Načte data modulu
	 */
	loadData = async () => {
		await Promise.all([
			this.list.clearFilter(),
			this.loadCategories(),
			this.loadRegions(),
		]);
	}

	/**
	 * Načte seznam kategorií
	 */
	loadCategories = async () => {
		const categoriesList = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingCategories: true })),
			exec: () => this.context.api.loadList("/adv_categories", {}),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingCategories: false }))
		}) as any;
		await this.stateContainer.merge(_ => ({
			categories: categoriesList.data
		}));
	}

	/**
	 * Načte seznam krajů
	 */
	loadRegions = async () => {
		const regions = await this.context.apiRegions.loadList({});
		await this.stateContainer.merge(_ => ({
			regions: regions.data
		}));
	}

	/**
	 * Přepne rubriku ve filtru
	 */
	toggleCategory = async (category_id: api.Id) => {
		const filterForm = this.list.getAdvancedFilterForm();
		const categories = filterForm.getField("categories").value;
		if (categories.find(i => i === category_id)) {
			await filterForm.setField("categories", categories.filter(i => i !== category_id));
		} else {
			await filterForm.setField("categories", categories.concat(category_id));
		}
	}

	/**
	 * Vrací seznam kategorií
	 */
	getCategories = () => {
		return this.stateContainer.get().categories;
	}

	/**
	 * Indikace načítání rubrik
	 */
	justLoadingCategories = () => {
		return this.stateContainer.get().justLoadingCategories;
	}

	/**
	 * Vrací seznam krajů
	 */
	getRegions = () => {
		return this.stateContainer.get().regions;
	}
}