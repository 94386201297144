/**
 * Stránka s akcí
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import FileList from "../../../components/controls/FileList";
import Spinner from "../../../components/controls/Spinner";
import Button from "../../../components/controls/Button";
import JoinedList from "../../../components/controls/JoinedList";
import Link from "../../../components/controls/Link";
import HtmlContent from "../../../components/controls/HtmlContent";
import Address from "../../shared/address/Address";

interface EducationalActionPageProps {
	stages: boolean;
}

function EducationalActionPage(props: EducationalActionPageProps) {
	const title = "Akce";
	const context = state.useStateContext();
	const model = props.stages ? context.stage : context.educationalAction;
	const justLoading = model.getJustLoading();
	const action = model.getAction();
	const { api, webRequestEducationActionRegistration } = state.useStateContext();
	const apiUrl = api.options.endpoint;

	function continueToAction() {
		webRequestEducationActionRegistration.reset();
	}

	return (
		<Page title={title} description="">
			<div className="box-detail">
				<h1>
					{justLoading &&
						<><Spinner className="mr-2" />Načítám detail akce...</>
					}
					{!justLoading && action &&
						<>{action.name}</>
					}
				</h1>
				{action &&
					<div className="box-detail__inner">
						<div className="box-detail__item">
							<div className="box-detail__info">
								{action.educational_action_category?.id != 26 &&
									<>
										<p>
											<strong>
												Datum konání
											</strong>
										</p>

										<p>
											<>{formatting.formatDateRange(action?.date_from, action?.date_to)} </>
											<>
												{formatting.formatCustomDate(action?.date_from, "H:mm") != formatting.formatCustomDate(action?.date_to, "H:mm") &&
													<>
														{formatting.formatCustomDate(action?.date_from, "H:mm") != "0:00" &&
															<>
																{formatting.formatCustomDate(action?.date_from, "H:mm")}
															</>
														}
														{formatting.formatCustomDate(action?.date_to, "H:mm") != "0:00" &&
															<>
																- {formatting.formatCustomDate(action?.date_to, "H:mm")}
															</>
														}
													</>
												}
												{formatting.formatCustomDate(action?.date_from, "H:mm") == formatting.formatCustomDate(action?.date_to, "H:mm") &&
													<>
														{formatting.formatCustomDate(action?.date_from, "H:mm") != "0:00" &&
															<>
																{formatting.formatCustomDate(action?.date_from, "H:mm")}
															</>
														}
													</>
												}
											</>
										</p>
									</>
								}

								{action.place_of_activity.length > 0 || action.educational_action_category?.id != 26 &&
									<>
										<p>
											<strong>
												Místo konání
											</strong>
										</p>
										<p>
											{action.place_of_activity}
										</p>
									</>
								}

								{action.lecturers.length > 0 &&
									<>
										<p>
											<strong>
												Lektor
											</strong>
										</p>
										<p>
											{action?.lecturers}
										</p>
									</>
								}
								{action?.perex &&
									<>
										<p>
											<strong>
												Obsah
											</strong>
										</p>
										<p>
											{action?.perex}
										</p>
									</>
								}
								{action.price_lists.length > 0 && action.price_lists[0].items.length > 0 &&
									<>
										<p>
											<strong>
												Cena:
											</strong>
										</p>
										<p>
											<table className="table-clean">
												{action.price_lists[0].items.map(i =>
													<tr>
														<td>{i.name}</td>
														<td className="pl-5 text-right">{formatting.formatCurrency(i.amount, i.currency ? i.currency.name : undefined)}</td>
													</tr>
												)}
											</table>
										</p>
									</>
								}
							</div>
						</div>
						<div className="box-detail__item">
							<div className="box-detail__info box-detail__info--second">
								<p>
									<strong>
										Pořadatel
									</strong>
								</p>
								<p>
									{action.subject.name}<br />
									<Address address={action.subject.address} /><br />
									<JoinedList
										items={[
											action.subject.contact?.email1,
											action.subject.contact?.email2,

										]}
										delimiter=", "
										itemTemplate={item => <a href={"mailto:" + item}>{item}</a>}
										afterElement={<br />}
									/>
									<JoinedList
										items={[
											action.subject.contact?.phone1,
											action.subject.contact?.phone2,

										]}
										delimiter=", "
										itemTemplate={item => <a href={"tel:" + item}>{item}</a>}
										afterElement={<br />}

									/>
									{(action.subject.contact?.web ?? "").length > 0 &&
										<>
											<a href={action.subject.contact?.web}>{action.subject.contact?.web}</a><br />
										</>
									}
								</p>
								{action.groups.length > 0 &&
									<>
										<p>
											<strong>
												Určení
											</strong>
										</p>
										<p>
											{action.groups.map(i =>
												<>{i.name}<br /></>
											)}
										</p>
									</>
								}
								<p>
									<strong>
										Forma
									</strong>
								</p>
								<p>
									{action.educational_action_category?.name}<br />
								</p>
								<p>
									<strong>
										Kredity
									</strong>
								</p>
								<p>
									{action.credits}<br />
								</p>
								<p>
									<strong>
										Kód
									</strong>
								</p>
								<p>
									{action.code}<br />
								</p>
							</div>
						</div>
					</div>
				}
			</div>
			{action &&
				<>
					<div className="mb-3 educational-action-description">
						<HtmlContent content={action.description} />
					</div>
					{action.files.length > 0 &&
						<div className="mb-3">
							<FileList items={action.files.map(f => ({
								fileName: f.name,
								icon: "doc",
								link: apiUrl + "/dms_files/" + f.id + "/download"
							}))} />
						</div>
					}
					{!action.do_not_register && !action.capacity_exhausted &&
						<div className="text-center mb-5">
							<Link onClick={continueToAction} noStyle to={"/vzdelavani/prihlasit-se-na-akci/" + action.id}><Button>Přihlásit se na {props.stages ? "stáž" : "akci"}</Button></Link>
						</div>
					}
				</>
			}
		</Page >
	);
}

export default state.bindContainers(
	EducationalActionPage,
	c => c.educationalAction
);