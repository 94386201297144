/**
 * Modul dashboardu OSK
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as context from "../../../context";
import * as apiRegionalChambers from "../../shared/regional-chambers/api-regional-chambers";

/**
 * Stav modulu
 */
interface State {
	justLoadingStatisctics: boolean;
	statisctics?: apiRegionalChambers.RegionalChamberStatistics;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;
	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoadingStatisctics: false
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	loadStatistics = async () => {
		const statistics = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingStatisctics: true })),
			exec: () => this.context.apiRegionalChambers.loadStatistics(),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingStatisctics: false }))
		});
		await this.stateContainer.merge(_ => ({
			statisctics: statistics
		}));
	}

	loadData = async () => {
		if (this.context.authorization.isOSKMember()) {
			await this.loadStatistics();
		}
	}

	getStatisctics = () => {
		return this.stateContainer.get().statisctics;
	}

	justLoadingStatisctics = () => {
		return this.stateContainer.get().justLoadingStatisctics;
	}
}