/**
 *  Formulář žádosti o preskripci
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";

interface WebRequestPrescriptionPageProps {
	id?: string;
}

function WebRequestPrescriptionsPage(props: WebRequestPrescriptionPageProps) {
	const title = <>
		ŽÁDOST O VYDÁNÍ POTVRZENÍ ZÁRUKY ODBORNOSTI PRO ZVLÁŠTNÍ SMLOUVU SE ZDRAVOTNÍ POJIŠŤOVNOU
		<br />
		O VYSTAVOVÁNÍ LÉKAŘSKÝCH PŘEDPISŮ (preskripce)
	</>;
	const { webRequestPrescription: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<p className="font-weight-bold">
				Žádám o vydání záruky České stomatologické komory pro účely uzavření zvláštní smlouvy
				se zdravotní pojišťovnou o vystavování lékařských předpisů smluvním lékařem poskytujícím
				hrazené služby sobě, manželovi, svým rodičům, prarodičům, dětem, vnukům a sourozencům
				podle zákona o veřejném zdravotním pojištění.
			</p>
			<p className="mb-5">
				<b>Čestně prohlašuji, že jsem:</b>
				<br />
				a) způsobilý/a k samostatnému výkonu povolání zubního lékaře (tj. jsem odborně způsobilý/á a zdravotně způsobilý/á),
				<br />
				b) trestně bezúhonný (tj. nebyl/a jsem pravomocně odsouzen/a pro trestný čin spáchaný v souvislosti s výkonem povolání zubního lékaře).
			</p>
            <div>
			<span className="font-weight-bold">Beru na vědomí, že platnost vydaného osvědčení je 2 roky.</span>
			</div>

			{!props.id &&
				<div className="text-right">
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestPrescriptionsPage,
	c => c.webRequestPrescription
);