/**
 * Členové sněmu
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";

interface Statistic {
	count_of_board_of_directors: number;
	count_of_cr: number;
	count_of_member: number;
	count_of_regional_chamber: number;
	count_of_rk: number;
	count_of_snem: number;
	count_of_visiting_foreigners: number;
	id: string;
}

/**
 * Stav modelu
 */
interface State {
	statistic: Statistic;
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			statistic: {
				count_of_board_of_directors: 0,
				count_of_cr: 0,
				count_of_member: 0,
				count_of_regional_chamber: 0,
				count_of_rk: 0,
				count_of_snem: 0,
				count_of_visiting_foreigners: 0,
				id: api.emptyGuid
			},
			justLoading: false
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	justLoading = () => this.stateContainer.get().justLoading;

	/**
	 * Načte data
	 */
	loadData = async () => {
		const statistic = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.api.get("/global_statistics"),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		}) as any;
		await this.stateContainer.merge(_ => ({
			statistic: statistic
		}));
	}

	getStatistics = () => this.stateContainer.get().statistic;
}