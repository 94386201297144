import * as context from "../../../context";
import * as api from "../../../lib/api";
import * as apiAddress from "../address/api-address";
import * as apiContact from "../contact/api-contact";
import * as apiUsers from "../users/api-users";
import * as apiCodeList from "../../system/code-list/api-code-list";

export interface Head {
	email: string;
	first_name: string;
	full_name: string;
	full_name_with_title: string;
	id: string;
	last_name: string;
}

export interface Workplace extends api.EntityReadBase {
	name: string;
	address?: apiAddress.Address;
	gps?: apiAddress.Gps;
	contact?: apiContact.Contact;
	regional_chamber?: api.CodeBookItem | null;
	provider: {
		id: api.Id;
		name: string;
		registration_number: string;
	};
	membes: apiUsers.UserSimple[];
	accepts_new_patients: boolean;
	insurance_companies: apiCodeList.CodeList[];
	type_cares: apiCodeList.CodeList[];
	registration_number: string;
	head: Head;
	editable: boolean;
}

export class Api {
	standard: api.EntityApi<Workplace, Workplace>;

	constructor(context: context.StateContext) {
		this.standard = new api.EntityApi<Workplace, Workplace>({
			api: context.api,
			path: "/workplaces"
		});
	}
}