/**
 * Stránka seznamu stáží
 */
import React from "react";
import * as state from "../../../lib/state";

import EducationalActions from "../../shared/educational-actions/EducationslActions";

function StagesPage() {
	return <EducationalActions stages />;
}

export default state.bindContainers(
	StagesPage,
	c => c.stages
);