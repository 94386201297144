/**
 *  Formulář přihlášky na akci
 */

import React, { useEffect, useState } from "react";

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as formatting from "../../../lib/formatting";
import * as webRequestActionRegistration from "./web-request-education-action-registration";

import WebRequest from "../../../components/templates/WebRequest";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import HtmlContent from "../../../components/controls/HtmlContent";
import Button from "../../../components/controls/Button";
import Checkbox from "../../../components/controls/Checkbox";
import Radio from "../../../components/controls/Radio";
import Input from "../../../components/controls/Input";
import Card from "../../../components/controls/Card";
import Spinner from "../../../components/controls/Spinner";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import { Tooltip } from "react-tippy";
import Select from "../../../components/controls/Select";

interface WebRequestEducationActionRegistrationPageProps {
	id?: string;
	step: webRequestActionRegistration.FormStep;
	hostname?: string;
}

function WebRequestEducationregistrationPage(props: WebRequestEducationActionRegistrationPageProps) {
	const context = state.useStateContext();
	const [enablePayment, setEnablePayment] = useState(false);
	const [idRequest, setIdRequest] = useState("");
	useEffect(() => {
		const isPaymentEnabled = api.getUrlParam("enablePayment");
		if (isPaymentEnabled) {
			setEnablePayment(true);
		}
		const idRequest = api.getUrlParam("id_request");
		if (idRequest) {
			setIdRequest(idRequest);
		}
	}, []);

	const { webRequestEducationActionRegistration: model } = context;
	const form = model.form;
	const action = form.getField("action").value;
	const actionPrice = form.getField("price").value;
	const socialPrograms = model.getSocialPrograms();
	const discount = model.getDiscount();
	const totalPrice = model.getTotalPrice();
	const title =
		model.common.sent()
			? "PŘIHLÁŠKA NA AKCI BYLA ODESLÁNA"
			: props.step === "summary"
				? "SHRNUTÍ PŘIHLÁŠKY NA AKCI"
				: "PŘIHLÁŠKA NA AKCI";

	function formatPriceAndValiditySocialProgram(socialProgram: webRequestActionRegistration.SocialProgram) {
		let result = formatting.formatCurrency(model.getSocialProgramTotalPrice(socialProgram));
		if (socialProgram.prices[0].valid_to) {
			result += " (platí do " + formatting.formatDate(socialProgram.prices[0].valid_to) + ")";
		}
		return result;
	}

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
			authorized={false}
		>
			{props.step === "form" &&
				<>
					<div className="mt-5 mb-5">
						{action &&
							<>
								<h2 className="form-title text-center web-request-action-registration-page__action-name">{action.name}</h2>
								{idRequest &&
									<div className="text-center mb-2">(zde můžete k Vaší přihlášce na kongres přiobjednat účast na Společenskému večeru pro Vás a Vaše doprovodné osoby)</div>
								}
								{!idRequest &&
									<>
										{form.getField("educational_action_category").value?.id != 26 &&
											<div className="d-flex justify-content-center">
												<table className="mb-3">
													<tr>
														<td>Datum konání:</td>
														<td className="pl-4"><strong>{formatting.formatDateRange(action.date_from, action.date_to)}</strong></td>
													</tr>
													<tr>
														<td>Místo konání:</td>
														<td className="pl-4"><strong>{action.place_of_activity}</strong></td>
													</tr>
												</table>
											</div>
										}
										{(action?.introduction.trim() ?? "").length > 0 &&
											<div className="mb-5">
												<HtmlContent content={action?.introduction} />
											</div>
										}
									</>
								}
							</>
						}
						{!action && model.justLoadingAction() &&
							<div className="text-center"><Spinner small /> Načítám akci...</div>
						}
					</div>
					{!action?.is_public &&
						<>
							<h2 className="font-weight-bold">Účastník akce</h2>
							{!context.authorization.userLoggedIn() && !idRequest &&
								<div className="row mb-5">
									<div className="col-lg-8">
										<CheckBoxRadioFrame>
											<FormRadio form={form} field="is_chamber_member" option={true} title="Jsem člen ČSK" />
											<FormRadio form={form} field="is_chamber_member" option={false} title="Nejsem člen ČSK" />
										</CheckBoxRadioFrame>
									</div>
								</div>
							}
						</>
					}

					{!context.authorization.userLoggedIn() && !idRequest &&
						<div className="row">
							{form.getField("is_chamber_member").value === false && !action?.is_public && form.getField("educational_action_category").value?.id != 25 &&
								<div className="col-lg-12 mb-4">
									<b>Upozornění pro členy ČSK – při přihlášení v této sekci nebude účast na akci zaznamenána do vašich záznamů o vzdělávání</b>
								</div>
							}
							<div className="col-lg-6">
								{form.getField("is_chamber_member").value === true &&
									<FormsInput form={form} field="ikc" />
								}
								{form.getField("is_chamber_member").value === false &&
									<>
										<FormsInput form={form} field="birthday_date" inputProps={{ type: "date", min: formatting.formatDate(new Date("1/1/1900"), { format: "iso" }) }} />

									</>
								}
							</div>
							{form.getField("is_chamber_member").value === false && action?.is_public &&
								<div className="col-lg-6">
									<FormsInput form={form} field="place_of_birth" />
								</div>
							}

						</div>
					}
					<div className="row">
						<div className="col-lg-6">
							<FormsInput form={form} field="first_name" inputProps={{ name: "first-name" }} />
						</div>
						<div className="col-lg-6">
							<FormsInput form={form} field="last_name" inputProps={{ name: "last-name" }} />
						</div>
					</div>

					<div className="row">
						<div className="col-lg-6">
							<FormsInput form={form} field="title_before" />
						</div>
						<div className="col-lg-6">
							<FormsInput form={form} field="title_after" />
						</div>
					</div>

					<div className="row mb-5">
						<div className="col-lg-6">
							<FormsInput form={form} field="email" inputProps={{ type: "email" }} />
						</div>
						<div className="col-lg-6">
							<FormsInput form={form} field="phone" inputProps={{ name: "phone" }} />
						</div>
					</div>

					{!idRequest &&
						<div className="row">
							<div className="col-lg-8">
								<FormSelect
									form={form}
									field="price"
									options={
										[
											{ title: "- Vyberte kategorii účastníka -", value: undefined },
											...model
												.getPrices()
												.map(i => ({ title: i.name, value: i }))
										]
									}
								/>
							</div>
							{actionPrice &&
								<>
									<div className="col-2 offset-5 offset-lg-0 col-lg-1 mt-lg-3 pt-4">
										<span className="box-profile__label">Cena</span>
									</div>
									<div className="col-5 col-lg-3 mt-lg-3 text-right pt-4">
										<span className="box-profile__value">{formatting.formatCurrency(actionPrice.amount)}</span>
									</div>
								</>
							}
						</div>
					}

					{socialPrograms.length > 0 &&
						<div>
							{socialPrograms.map((i, index) =>
								<React.Fragment key={index}>
									{form.getField("educational_action_category").value?.id == 18 && i.prices[0].amount == 0 &&
										<>
											Pokud máte zájem účastnit se koncertu, prosím zaškrtněte políčko (pro registrované účastníky je vstup zdarma).
										</>
									}
									<div>{<HtmlContent content={i.name} />}</div>
									<div className="d-inline">Rezervační poplatek pro účastníka kongresu {i.prices[0].amount > 0 ? <HtmlContent className="d-inline" content={formatting.formatCurrency(i.prices[0].amount)} /> : ""}</div><span className="form-item__required ml-1">*</span>
									<div className="row">
										<div className={"col-lg-4 form-item" + ((i.checked == undefined && form.validated()) ? " form-item--invalid" : "")}>
											<Tooltip
												className="d-block form-item__tooltip"
												arrow
												position="bottom-start"
												title={(i.checked == undefined && form.validated()) ? "Vyberte hodnotu." : undefined}
												trigger="mouseenter"
												disabled={(i.checked == undefined && form.validated()) ? false : true}
											>
												<Select
													value={i.checked}
													onChange={(e) => model.toggleSocialProgram(e)}
													options={[{ title: "Zvolte", value: undefined }, { title: "Mám zájem", value: true }, { title: "Nemám zájem", value: false }]}
												/>
											</Tooltip>
										</div>

										{i.checked &&
											<>
												{i.prices[0].amount2 !== undefined &&
													<>
														<div className="col-4 offset-5 col-lg-2 offset-lg-0">
															<div className="mt-2 mb-3 text-center">
																Doprovodná osoba <span className="form-item__required ml-1">*</span> <span className="text-nowrap">{formatting.formatCurrency(i.prices[0].amount2)}</span><br />
																{i.prices[0].max_count <= 40 &&
																	<small>(max. {i.prices[0].max_count} {formatting.pluralize(i.prices[0].max_count, "osoba", "osoby", "osob")})</small>
																}
															</div>
														</div>
														<div className="col-3 col-lg-2">
															<div className={"col-md-9 form-item"}>
																<Input
																	type="number"
																	className="mb-3"
																	value={i.next_persons}
																	onChange={value => model.setSocialProgramNextPersons(i, value)}
																/>
															</div>
														</div>
													</>
												}
												{i.prices[0].amount2 == undefined &&
													<div className="col-7 col-lg-4">
													</div>
												}
												<div className="col-2 offset-5 offset-lg-0 col-lg-1">
													<div className="mt-lg-3">Cena</div>
												</div>
												<div className="col-5 col-lg-3 mt-lg-3 mb-3 mb-lg-0 text-right">
													<span className="box-profile__value">{formatPriceAndValiditySocialProgram(i)}</span>
												</div>
											</>
										}
									</div>
								</React.Fragment>
							)}
						</div>
					}

					{discount.amount < 0 &&
						<div className="row">
							<div className="col-lg-8">
								<h2 className="mt-3">{discount.text}</h2>
							</div>
							<div className="col-2 offset-5 offset-lg-0 col-lg-1 mt-lg-3">
							</div>
							<div className="col-5 col-lg-3 mt-lg-3 text-right">
								<span className="box-profile__value">{formatting.formatCurrency(discount.amount)}</span>
							</div>
						</div>
					}

					{totalPrice != 0 &&
						<div className="row">
							<div className="col-2 offset-5 offset-lg-8 col-lg-1 mt-lg-3">
								<strong>CELKEM</strong>
							</div>
							<div className="col-5 col-lg-3 mt-lg-3 text-right">
								<span className="box-profile__value"><span className="h2 form-title font-weight-bold">{formatting.formatCurrency(totalPrice)}</span></span>
							</div>
						</div>
					}
					{action?.show_hotel &&
						<>
							<div className="mt-3"><HtmlContent htmlKey="prihlaska-hotel-popis" /></div>
							<div className="row">
								<div className="col-lg-12">
									<FormSelect
										form={form}
										field="offers_in_accommodation_facilities"
										options={
											[
												{ title: "- Vyberte ANO/NE -", value: undefined },
												{
													title: "ANO", value: true
												},
												{
													title: "NE", value: false
												},
											]
										}
									/>
								</div>
								{model.form.getField("offers_in_accommodation_facilities").value &&
									<div className="col-lg-6">
										<FormSelect
											form={form}
											field="hotel"
											options={
												[
													{ title: "- Vyberte hotel -", value: undefined },
													{
														title: "hotel Duo", value: { id: 1, name: "hotel Duo" }
													},
													{
														title: "hotel Holiday Inn", value: { id: 2, name: "hotel Holiday Inn" }
													},
													{
														title: "jiný hotel/penzion", value: { id: 3, name: "jiný hotel/penzion" }
													}
												]
											}
										/>
									</div>
								}
							</div>
						</>
					}

					{form.getField("is_chamber_member").value === false && action?.is_public &&
						<>
							<h2 className="font-weight-bold">Pracoviště</h2>
							<div className="row mb-5">
								<div className="col-lg-6">
									<FormsInput form={form} field="workplace_name" inputProps={{ name: "name" }} />
									<FormsInput form={form} field="workplace_street" inputProps={{ name: "street" }} />
									<FormsInput form={form} field="workplace_city" inputProps={{ name: "city" }} />
									<FormsInput form={form} field="workplace_postcode" inputProps={{ name: "postcode" }} />
									<FormsInput form={form} field="workplace_country" inputProps={{ name: "country" }} />
								</div>
								<div className="col-lg-6">
									<FormsInput form={form} field="workplace_ico" />
									<FormsInput form={form} field="workplace_dic" />
								</div>
							</div>
						</>
					}
					<h2 className="font-weight-bold">Fakturace</h2>
					<p className="mb-5">
						<em>
							{!action?.is_public &&
								<>
									Pokud jste členem ČSK, fakturační adresa byla předvyplněna z registru členů, v případě potřeby můžete adresu změnit.
								</>
							}
							<div className="color-red"><b>Prosíme o pečlivou kontrolu vyplněných fakturačních údajů.</b></div><div>Tyto údaje bude obsahovat předpis k platbě i následný daňový doklad.</div><div className="color-red"><b>Po odeslání přihlášky změna fakturačních údajů není možná.</b></div>
						</em>
					</p>
					<div className="row mb-5">
						<div className="col-lg-6">
							<FormsInput form={form} field="invoice_name" inputProps={{ name: "name" }} />
							<FormsInput form={form} field="invoice_street" inputProps={{ name: "street" }} />
							<FormsInput form={form} field="invoice_city" inputProps={{ name: "city" }} />
							<FormsInput form={form} field="invoice_postcode" inputProps={{ name: "postcode" }} />
							<FormsInput form={form} field="invoice_country" inputProps={{ name: "country" }} />
						</div>
						<div className="col-lg-6">
							<FormsInput form={form} field="invoice_ico" />
							<FormsInput form={form} field="invoice_dic" />
						</div>
					</div>

					{action && action.programs.length > 0 && !idRequest &&
						<>
							<h2 className="font-weight-bold">Dotazník - zájem o programové sekce</h2>
							<p className="mb-5">
								<em>
									Aby byla co nejefektivněji využita kapacita sálů, rádi bychom zjistili předběžný
									zájem o jednotlivé programové bloky. Prosím, zaškrtněte programové sekce, kterých
									se chcete během kongresu zúčastnit. Každý den vždy jednu sekci dopoledne a jednu odpoledne.
									Podrobný program najdete na <a href="https://www.dent.cz">www.dent.cz</a>.<br /><br />

									Děkujeme za vyplnění!
								</em>
							</p>
							<div className="row">
								{action.programs.map((p, pindex) =>
									<div key={pindex} className="col-lg-6">
										<div className="secondary-title">{p.name}</div>
										{/* Naprosto úchylácky nechtěno zákazníkem */}

										{/* <Radio
											title={<span>Neúčastním se</span>}
											checked={p.items.filter(i => i.checked).length === 0}
											onCheckedChanged={_ => model.toggleProgram(pindex, undefined)}
										/> */}
										{p.items.map((pitem, index) =>
											<React.Fragment key={index} >
												<div>
													<Radio
														checked={pitem.checked} title={<div><strong>{pitem.title}</strong><br />{pitem.lecturers}</div>}
														onCheckedChanged={_ => model.toggleProgram(pindex, pitem)}
													/>
												</div>
											</React.Fragment>
										)}
									</div>
								)}
							</div>
						</>
					}
					{model.form.getField("capacity_exhausted").value &&
						<div className="mt-3 font-weight-bold text-center">
							Kapacita vyčerpána - nelze se již přihlásit
						</div>
					}

					{idRequest &&
						<p>
							<h2 className="font-weight-bold">STORNO ÚČASTI</h2>
							<div>Storno účasti lze provést pouze písemnou formou.</div>
							<b>V termínu 1. 9. - 10. 10. 2024 činí storno poplatek 30 %, po tomto datu 100 % celkové uhrazené částky.</b>
						</p>
					}

					{!props.id &&
						<div className="text-right mt-5 mb-5">
							<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
							<Button disabled={model.form.getField("capacity_exhausted").value} theme="primary" onClick={model.nextToSummary}>Pokračovat na shrnutí &gt;</Button>
						</div>
					}
				</>
			}
			{
				props.step === "summary" && action &&
				<>
					<h2>Cena</h2>
					<table className="table-designed books-checkout-page__table-items mb-5">
						<thead>
							<tr>
								<th>Položka</th>
								<th className="text-right" style={{ width: "8rem" }}>Cena</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									Cena za účast: {actionPrice?.name}
								</td>
								<td className="text-right">
									{formatting.formatCurrency(actionPrice?.amount)}
								</td>
							</tr>
							{model.getSocialPrograms().filter(i => i.checked).map((i, index) =>
								<React.Fragment key={index}>
									{i.prices[0].amount > 0 &&
										<tr>
											<td>Společenský program: <strong>{i.name} </strong></td>
											<td className="text-right pl-3">
												{formatting.formatCurrency(i.prices[0].amount)}
											</td>
										</tr>
									}
									{i.next_persons > 0 && i.prices[0].amount2 !== undefined &&
										<tr>
											<td className="pl-5">+ {i.next_persons} {formatting.pluralize(i.next_persons, "doprovodná osoba", "doprovodné osoby", "doprovodných osob")}</td>
											<td className="text-right pl-3">
												{formatting.formatCurrency(i.next_persons * i.prices[0].amount2)}
											</td>
										</tr>
									}
								</React.Fragment>
							)}
							{discount.amount < 0 &&
								<tr>
									<td>
										{discount.text}
									</td>
									<td className="text-right">
										{formatting.formatCurrency(discount.amount)}
									</td>
								</tr>
							}
							<tr>
								<th className="text-uppercase font-weight-bold">Cena celkem</th>
								<td className="text-right pl-3"><strong>{formatting.formatCurrency(model.getTotalPrice())}</strong></td>
							</tr>
						</tbody>
					</table>

					<h2>Fakturační údaje</h2>
					<Card>
						<div className="row">
							<div className="col-lg-6">
								<div className="row">
									<div className="col-lg-4">
										<span className="box-profile__label">
											{form.getFieldOptions("invoice_name").title}
										</span>
									</div>
									<div className="col-lg-8">
										<span className="box-profile__value">
											{form.getField("invoice_name").value}
										</span>
									</div>

									<div className="col-lg-4">
										<span className="box-profile__label">
											{form.getFieldOptions("invoice_street").title}

										</span>
									</div>
									<div className="col-lg-8">
										<span className="box-profile__value">
											{form.getField("invoice_street").value}
										</span>
									</div>

									<div className="col-lg-4">
										<span className="box-profile__label">
											{form.getFieldOptions("invoice_city").title}

										</span>
									</div>
									<div className="col-lg-8">
										<span className="box-profile__value">
											{form.getField("invoice_city").value}
										</span>
									</div>

									<div className="col-lg-4">
										<span className="box-profile__label">
											{form.getFieldOptions("invoice_postcode").title}
										</span>
									</div>
									<div className="col-lg-8">
										<span className="box-profile__value">
											{form.getField("invoice_postcode").value}
										</span>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row">
									<div className="col-lg-4">
										<span className="box-profile__label">
											{form.getFieldOptions("invoice_ico").title}

										</span>
									</div>
									<div className="col-lg-8">
										<span className="box-profile__value">
											{form.getField("invoice_ico").value}
										</span>
									</div>

									<div className="col-lg-4">
										<span className="box-profile__label">
											{form.getFieldOptions("invoice_dic").title}
										</span>
									</div>
									<div className="col-lg-8">
										<span className="box-profile__value">
											{form.getField("invoice_dic").value}
										</span>
									</div>
								</div>
							</div>
						</div>
					</Card>
					{action.generate_payments &&
						<>
							<h2>Způsob platby</h2>
							<div className="row mb-5">
								<div className="col-12 col-lg-8 offset-lg-4">
									<CheckBoxRadioFrame className="mb-1">
										<FormRadio checkBoxProps={{ readOnly: enablePayment ? undefined : undefined }} form={form} field="payment_method" option="transfer" title="Převodem" />
										<FormRadio checkBoxProps={{ readOnly: enablePayment ? undefined : undefined }} form={form} field="payment_method" option="gateway" title="Platební kartou" />
									</CheckBoxRadioFrame>
									{form.getField("description_summary").value &&
										<HtmlContent content={form.getField("description_summary").value} />
									}

									{form.getField("payment_method").value === "transfer" &&
										<p>
											Na Váš e-mail bude odeslán předpis k úhradě.
										</p>
									}
									{form.getField("payment_method").value === "gateway" &&
										<p>
											Budete přesměrováni na platební bránu.
										</p>
									}
								</div>
							</div>
						</>
					}
					{!action.generate_payments && form.getField("description_summary").value &&
						<HtmlContent className="mt-3" content={form.getField("description_summary").value} />
					}
					<div className="text-right mt-5">
						<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
						<em>Odesláním potvrzuji správnost údajů</em><br /><br />
					</div>
					<div className="d-flex align-items-center justify-content-end mb-5">
						<Button onClick={_ => window.history.back()} className="mr-3">&lt; Zpět</Button>
						<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
					</div>
				</>
			}
		</WebRequest >
	);
}

export default state.bindContainers(
	WebRequestEducationregistrationPage,
	c => c.webRequestEducationActionRegistration
);
