/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

import Page from "../../../components/templates/Page";
import Card from "../../../components/controls/Card";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

function ExpertCard(props: { item: apiDynamicLists.Item }) {
	return (
		<div className="col-12 col-md-6 col-lg-4 mb-gutter">
			<Card className="contacts-experts-page__card">
				<strong>{props.item.title}</strong>
				<div>
					<HtmlContent content={props.item.text} />
				</div>
			</Card>
		</div>
	);
}

function ContactsExpertsPage() {
	const model = state.useStateContext().contactsExperts;
	const { contents } = state.useStateContext();

	const cards = <div className="row">
		{[1, 2, 3, 4, 5, 6, 7, 8,].map(i =>
			<div className="col-12 col-md-6 col-lg-4">
				<Card className="mb-gutter">
					<strong>MUDr. Ivo Dřízhal CSc.</strong>
					<p>
						Fakultní nemocnice Hradec Králové Stomatologická klinika<br />
					</p>
					<p>
						<address>
							&mdash; 500 05, Hradec Králové
						</address>
					</p>
				</Card>
			</div>
		)}
	</div>;

	return (
		<Page authorized={true} title={contents.getRes("znalci-nadpis")} description="">
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="znalci-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<HtmlContent htmlKey="znalci-obsah" />

			{model.getSections().map(i =>
				<>
					<h2>{i.section.title}</h2>
					<div className="row align-items-stretch">
						{i.data.map(i => <ExpertCard item={i} />)}
					</div>
				</>
			)}
		</Page>
	);
}

export default state.bindContainers(
	ContactsExpertsPage,
	c => c.contactsExperts
);