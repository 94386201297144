/**
 * Volba víceprezidenta
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function VicePresidentialElectionPage() {
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("volby-viceprezidenta-nadpis")} description="">
			<HtmlContent htmlKey="volby-viceprezidenta-obsah" />
		</Page >
	);
}