import * as React from "react";

export type SelectProps<TValue> = Omit<React.HTMLProps<HTMLSelectElement>, "value" | "onChange"> & {
	value: TValue;
	options: { title: string; value: TValue }[];
	onChange?: (value: TValue) => void;
};

export default function Select<TValue>(props: SelectProps<TValue>) {
	function handleChange(value: TValue) {
		if (props.onChange) {
			props.onChange(value);
		}
	}
	const { value, options, onChange, ...restProps } = props;
	return (
		<select
			{...restProps}
			onChange={(e) => handleChange(props.options[e.currentTarget.options.selectedIndex].value)}
			className={"custom-select " + (props.className ?? "")}>

			{props.options.map(i =>
				<option key={i.title} selected={
					value == undefined ? false :
						((value as any).name == undefined ?
							(i.value == undefined ? false : value == i.value) :
							i.value == undefined ? false :
								(value as any).name == (i.value as any).name)}
				>{i.title}</option>
			)}
		</select>
	);
}