import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

interface PhotoGalleryProps extends React.HTMLProps<HTMLImageElement> {
	gallerySrc: string;
}

export default function PhotoGallery(props: PhotoGalleryProps) {
	const [imageIndex, viewImage] = useState(undefined as number | undefined);
	const { className, onClick, gallerySrc, crossOrigin, ...restProps } = props;
	return (
		<>
			<ModalGateway>
				{imageIndex !== undefined ? (
					<Modal onClose={(e) => viewImage(undefined)}>
						<Carousel views={[{ source: gallerySrc }]} currentIndex={0} />
					</Modal>
				) : null}
			</ModalGateway>
			<img
				className={"photo-gallery-image " + (className ?? "")}
				onClick={(e) => { e.preventDefault(); viewImage(0); }}
				{...restProps}
			/>
		</>
	);
}