/**
 * Knihovna pro implementaci formulářů
 */

import * as state from "./state";
import * as common from "./common";
import * as context from "../context";
import * as React from "react";

/**
 * Definice jednoho pole
 */
export interface FieldOption<TValue, TFields> {
	title: string | ((form: Form<TFields>) => string);
	hint?: React.ReactNode;
	readOnly?: boolean | ((form: Form<TFields>) => boolean);
	defaultValue: TValue | (() => TValue);
	validate?: (value: TValue, field: FieldOption<TValue, TFields>, form: Form<TFields>) => string;
	required?: boolean | ((form: Form<TFields>) => boolean);
	placeHolderText?: string;
	onChange?: (value: TValue, field: FieldOption<TValue, TFields>, form: Form<TFields>) => void;
}

/**
 * Hodnota formulářového pole a validační hláška 
 */
export interface Field<TValue> {
	value: TValue;
	validation: string;
}

/**
 * Definice polí
 */
export type FieldsOptions<TFields> = {
	[P in keyof TFields]: FieldOption<TFields[P], TFields>
};

/**
 * Hodnoty polí
 */
export type Fields<TFields> = {
	[P in keyof TFields]: Field<TFields[P]>
};

/**
 * Parametry formuláře
 */
export interface FormOptions<TFields> {
	fields: FieldsOptions<TFields>;
	onChangeForm?: (form: Form<TFields>) => void;
}

/**
 * Stav formuláře
 */
interface FormState<TFields> {
	fields: Fields<TFields>;
	validated: boolean;
	childChangedStamp: string;
	readOnly: boolean;
}

interface ValidationReport {
	field: string;
	message: string;
}

/**
 * Pomocná metoda pro vytvoření parciálního stavu 
 */
function createPartialState<TFields>(state: Partial<FormState<TFields>>) {
	return state;
}

/**
 * Třída formuláře
 */
export class Form<TFields> implements state.StateModel {
	stateContainer: state.StateContainer<FormState<TFields>>;
	private subForms: (Form<any> | FormCollection<any>)[] = [];

	constructor(private options: FormOptions<TFields>, context: context.StateContext, public parent?: Form<any>) {
		let defaultFields: any = {};

		for (let i in options.fields) {
			defaultFields[i] = {
				value: options.fields[i].defaultValue,
				validation: ""
			};
		}

		this.stateContainer = new state.StateContainer<FormState<TFields>>({
			fields: defaultFields,
			validated: false,
			childChangedStamp: "",
			readOnly: false
		}, context);

		if (parent) {
			parent.subForms.push(this);
		}
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = (): state.StateContainer<any>[] => [
		this.stateContainer,
		...this.subForms.map(i => i.getStateContainers()).flat()
	]

	/**
	 * Pomocná metoda pro bezpečnou aktualizaci konkrétního pole
	 */
	private updateField = <TField extends keyof TFields>(prevFields: Fields<TFields>, field: TField, mapFce: (field: Field<any>, fieldOption: FieldOption<any, TFields>) => Field<any>) => {
		const fields = { ...prevFields };
		for (let i in fields) {
			if (i === field as string) {
				fields[i] = mapFce(fields[i], this.getFieldOptions(i));
			}
		}
		return fields;
	}

	/**
	 * Pomocná metoda pro bezpečnou hromadnou aktualizaci polí pomocí mapovací funkce
	 */
	private updateFields = (prevFields: Fields<TFields>, mapFce: (fieldKey: keyof TFields, field: Field<any>, fieldOption: FieldOption<any, TFields>) => Field<any>) => {
		const fields = { ...prevFields };
		for (let i in fields) {
			fields[i] = mapFce(i, fields[i], this.getFieldOptions(i));
		}
		return fields;
	}

	private handleChangeForm = async () => {
		const onChangeForm = this.options.onChangeForm;
		if (onChangeForm) {
			onChangeForm(this);
		}

		if (this.parent) {
			await this.parent.forceChange();
		}
	}

	/**
	 * Vrací zda je hodnota pouze pro čtení
	 */
	isFieldReadOnly = <TField extends keyof TFields>(field: TField) => {
		const fieldOptions = this.options.fields[field];
		let readOnly = false;
		if (this.isReadOnly()) {
			readOnly = true;
		} else if (typeof fieldOptions.readOnly === "boolean") {
			readOnly = fieldOptions.readOnly ?? false;
		} else if (typeof fieldOptions.readOnly === "function") {
			readOnly = fieldOptions.readOnly(this);
		}
		return readOnly;
	}

	/**
	 * Vrací hodnoty pole
	 */
	getField = <TField extends keyof TFields>(field: TField) => {
		return {
			...this.stateContainer.get().fields[field],
			readOnly: this.isFieldReadOnly(field)
		};
	}

	/**
	 * Vrací definici pole
	 */
	getFieldOptions = <TField extends keyof TFields>(field: TField) => {
		return this.options.fields[field];
	}

	/**
	 * Vrací zda je hodnota povinná
	 */
	isFieldRequired = (field: keyof TFields) => {
		const requiredFlag = this.options.fields[field].required;
		if (typeof requiredFlag === "boolean") {
			return requiredFlag;
		}
		if (typeof requiredFlag === "function") {
			return requiredFlag(this);
		}

		return false;
	}

	getFieldTitle = (field: keyof TFields) => {
		const title = this.options.fields[field].title;
		return typeof title === "function" ? title(this) : title;
	}

	/**
	 * Nastaví hodnotu pole
	 */
	setField = async <TField extends keyof TFields>(field: TField, value: TFields[TField]) => {
		await this.stateContainer.merge(prevState => createPartialState({
			fields: this.updateField(prevState.fields, field, (field, fieldOptions) => ({
				...field,
				value: value,
				validation: prevState.validated && fieldOptions.validate ? fieldOptions.validate(value, fieldOptions, this) : "",
			})),
		}));

		const onChange = this.options.fields[field].onChange;
		if (onChange) {
			await onChange(value, this.options.fields[field], this);
		}

		await this.handleChangeForm();
	}

	/**
	 * Formulář je validován. Avšak nemusí obsahovat validní hodnoty!
	 */
	validated = (): boolean => {
		return this.stateContainer.get().validated && this.subForms.map(i => i.validated()).length === this.subForms.length;
	}

	/**
	 * Formulář vyl validován a obsahuje validní hodnoty.
	 */
	isValid = (): boolean => {
		if (!this.validated()) {
			return false;
		}

		const fields = this.stateContainer.get().fields;
		for (let i in fields) {
			if (fields[i].validation !== "") {
				return false;
			}
		}

		return this.subForms.filter(i => i.isValid()).length === this.subForms.length;
	}

	/**
	 * Provede validaci formuláře
	 */
	validate = async () => {
		await this.stateContainer.merge(prevState => createPartialState({
			fields: this.updateFields(prevState.fields, (fieldKey, field, fieldOptions) => ({
				...field,
				validation: fieldOptions.validate ? fieldOptions.validate(field.value, fieldOptions, this) : ""
			})),
			validated: true
		}));

		await Promise.all(this.subForms.map(i => i.validate()));
	}

	/**
	 * Provede revalidaci fieldu, pokud již byl field validován 
	 */
	validateField = async <TField extends keyof TFields>(field: TField) => {
		await this.stateContainer.merge(prevState => createPartialState({
			fields: this.updateField(prevState.fields, field, (field, fieldOptions) => ({
				...field,
				validation: prevState.validated && fieldOptions.validate ? fieldOptions.validate(field.value, fieldOptions, this) : "",
			})),
		}));
	}

	/**
	 * Odstraní provedené validace
	 */
	clearValidations = async () => {
		await this.stateContainer.merge(prevState => createPartialState({
			fields: this.updateFields(prevState.fields, (fieldKey, field, fieldOptions) => ({
				...field,
				validation: ""
			})),
			validated: false
		}));
		await Promise.all(this.subForms.map(i => i.clearValidations()));
	}

	/**
	 * Vrací položky formuláře
	 */
	getFields = () => {
		const result: any = {};
		const fields = this.stateContainer.get().fields;
		for (let p in fields) {
			result[p] = fields[p].value;
		}

		return result as TFields;
	}

	/**
	 * Nastaví položky formuláře
	 */
	setFields = async (fields: Partial<TFields>) => {
		await this.stateContainer.merge(prevState => createPartialState({
			fields: this.updateFields(prevState.fields, (fieldKey, field, fieldOptions) => ({
				...field,
				value: fields[fieldKey] !== undefined ? fields[fieldKey] : field.value,
				validation: fields[fieldKey] !== undefined && prevState.validated && fieldOptions.validate ? fieldOptions.validate(field.value, fieldOptions, this) : field.validation
			})),
			validated: prevState.validated
		}));

		await this.handleChangeForm();
		return this;
	}

	/**
	 * Resetuje položky formuláře
	 */
	clearFields = async () => {
		await this.stateContainer.merge(prevState => createPartialState({
			fields: this.updateFields(prevState.fields, (fieldKey, field, fieldOptions) => ({
				...field,
				value: fieldOptions.defaultValue,
				validation: ""
			})),
			validated: false
		}));
		this.subForms.map(i => i.clearFields());
		await this.handleChangeForm();
	}

	forceChange = async () => {
		await this.stateContainer.merge(_ => ({
			childChangedStamp: common.unique()
		}));
	}

	setReadOnly = async (readOnly: boolean) => {
		await Promise.all([
			this.stateContainer.merge(_ => ({
				readOnly: readOnly
			})),
			...this.subForms.map(i => i.setReadOnly(readOnly))
		]);
	}

	isReadOnly = () => {
		return this.stateContainer.get().readOnly;
	}

	setSubForms = (subForms: Form<TFields>[]) => {
		this.subForms = subForms;
	}
}

/**
 * Kolekce formulářů
 */
export class FormCollection<TFields> implements state.StateModel {
	private subForms: Form<TFields>[];

	constructor(private options: FormOptions<TFields>, private context: context.StateContext, public parentForm: Form<any>) {
		this.subForms = [];
	}

	getStateContainers = () => {
		return this.subForms.map(i => i.getStateContainers()).flat();
	}

	addWithOptions = (options: FormOptions<TFields>) => {
		const newForm = new Form<TFields>(options ?? this.options, this.context, this.parentForm);
		this.subForms.push(newForm);
		common.ignorePromises(this.parentForm.forceChange());
		return newForm;
	}

	add = () => {
		return this.addWithOptions(this.options);
	}

	remove = (form: Form<TFields>) => {
		this.subForms = this.subForms.filter(i => i !== form);
		common.ignorePromises(this.parentForm.forceChange());
		this.parentForm.setSubForms(this.subForms.filter(i => i !== form));
	}

	get = () => {
		return this.subForms;
	}

	validate = async () => {
		await Promise.all([
			this.subForms.map(i => i.validate())
		]);
	}

	clearFields = async () => {
		await Promise.all([
			this.subForms.map(i => i.clearFields())
		]);
	}

	clearValidations = async () => {
		await Promise.all([
			this.subForms.map(i => i.clearValidations())
		]);
	}

	isValid = () => {
		return this.subForms.filter(i => !i.isValid()).length == 0;
	}

	validated = (): boolean => {
		return this.subForms.length === this.subForms.map(i => i.validated()).length;
	}

	setFields = async (data: TFields[]) => {
		this.subForms = [];
		await Promise.all(data.map(i => this.add().setFields(i)));
	}

	setReadOnly = async (readOnly: boolean) => {
		await Promise.all(this.subForms.map(i => i.setReadOnly(readOnly)));
	}
}