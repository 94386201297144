/**
 * Seznam inzerce
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Link from "../../../components/controls/Link";
import Button from "../../../components/controls/Button";
import ShopingCartIcon from "../../../components/icons/ShopingCartIcon";

export default function CartBanner() {
	const { shopCart: model } = state.useStateContext();
	const cartItemsCount = model.getCart().items.length;

	return (
		<>
			{ cartItemsCount > 0 &&
				<>
					<div className="text-right">
						<Link noStyle to="/vzdelavani/knihy/kosik">
							<Button theme="primary" className="books-page__button-cart">
								<ShopingCartIcon className="books-page__cart-icon mr-3" /> {cartItemsCount} {formatting.pluralize(cartItemsCount, "kniha", "knihy", "knih")} v košíku
							</Button>
						</Link>
					</div>
					<hr />
				</>
			}
		</>
	);
}