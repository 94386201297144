/**
 * Domovská stránka
 */
import React from "react";

import * as state from "../../../lib/state";

import Spinner from "../../../components/controls/Spinner";
import Page from "../../../components/templates/Page";
import MapyCzMap from "../../../components/controls/MapyCzMap";
import Card from "../../../components/controls/Card";
import HtmlContent from "../../../components/controls/HtmlContent";

function ContactsCskPage() {
	const model = state.useStateContext().contactsCsk;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("kontakty-kancelare-csk")} description={contents.getRes("kontakty-kancelare-csk")}>

			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="kontakty-kancelare-csk" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>
			<div className="row">
				<div className="col-12 col-md-6">
					<HtmlContent htmlKey="kontakty-obsah" />
				</div>
				<div className="col-12 col-md-6 mb-5">
					<MapyCzMap title="Česká stomatologická komora" latitude={50.0635981} longitude={14.4256228} zoom={17} />
				</div>
			</div>
			<div className="contacts-csk-page-container">
				<h2>Zaměstnanci Kanceláře</h2>
				{model.getStaff().map(data =>
					<Card className="mb-gutter">
						<div className="row">
							<HtmlContent content={data.text} />
						</div>
					</Card>
				)}
			</div>
		</Page>
	);
}

export default state.bindContainers(
	ContactsCskPage,
	c => c.contactsCsk
);