/*
 * Komponenta checkboxu a radio tlačítka.
 */
import * as React from "react";

export interface CheckBoxRadioProps {
	className?: string;
	type: "checkbox" | "radio";
	title?: React.ReactNode | string;
	checked?: boolean;
	onCheckedChanged?: (value: boolean) => void;
	small?: boolean;
	readOnly?: boolean;
	toolip?: string;
}

export default function CheckboxRadio(props: CheckBoxRadioProps) {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.onCheckedChanged) {
			props.onCheckedChanged(e.target.checked);
		}
	}

	return (
		<label className={
			(props.toolip ? " tooltip " : "") + "checkbox-radio checkbox-radio--" + (props.type) + " "
			+ (props.small ? "checkbox-radio--small " : "")
			+ (props.readOnly ? "checkbox-radio--readonly " : "")
			+ (props.className || "")}
		>
			<input
				className="checkbox-radio__input"
				type={props.type} checked={props.checked ? true : false}

				disabled={props.readOnly}
				onChange={handleChange}>
			</input>
			<span className={"checkbox-radio__checkmark " + (props.readOnly ? "checkbox-radio__checkmark--readonly " : "")}></span>
			{props.title &&
				<span className="checkbox-radio__title">{props.title}</span>
			}
			{(props.toolip && props.toolip != 'string') &&
				<span className="tooltip-text">{props.toolip}</span>
			}
		</label>
	);
}