/**
 * Model žádosti o vydání osvědčení po absolvování navazujícího vzdělávacího programu
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as api from "../../../lib/api";
import * as formating from "../../../lib/formatting";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";
import * as apiCodeList from "../../system/code-list/api-code-list";

interface State {
	certifications: apiCodeList.CodeList[];
}

interface Credit {
	id: string
	checked: boolean;
	date: Date;
	description: string;
	value: string;
}
export interface FormFields {
	certificate?: api.Id;
	declaration: boolean;
	amount: number;
	credits_for_other_forms: string;
	index_attachment?: apiFile.File;
	credits: Credit[];
}


export interface FileFormFields {
	attachment?: apiFile.File;
}
interface FormData extends FormFields {
	attachments: FileFormFields[];
}

/**
* Model stránky
*/
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;
	public formsFiles: forms.FormCollection<FileFormFields>;

	constructor(private context: context.StateContext) {
		const current_date = formating.formatDate(new Date(), { format: "iso" });

		this.stateContainer = new state.StateContainer<State>({
			certifications: []
		}, context);

		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				certificate: {
					title: "Vyberte osvědčení",
					required: true,
					validate: value => this.getCertifications().length > 1 && value === api.emptyGuid ? "Osvědčení je povinné pole" : "",
					defaultValue: api.emptyGuid,
					onChange: (value, field, form) => this.loadCredits(value != undefined && value != api.emptyGuid ? value : undefined)
				},
				credits: {
					defaultValue: [],
					title: ""
				},
				declaration: {
					title: "Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti, jsou pravdivé a úplné. Čestně prohlašuji, že jsem členem Komory, že jsem způsobilý/á k samostatnému výkonu povolání zubního lékaře, že mi nebylo pravomocně uloženo disciplinární opatření anebo že takové disciplinární opatření již bylo zahlazeno, že jsem nebyl/a pravomocně odsouzen/a za trestný čin spáchaný v souvislosti s výkonem povolání zubního lékaře anebo že se na mě hledí, jako bych nebyl/a odsouzen/a, a že nemám vůči Komoře nedoplatek na stanovených příspěvcích či poplatcích nebo na pokutě uložené pravomocným rozhodnutím v disciplinárním řízení.",
					defaultValue: false,
					required: true,
					validate: value => !value ? "Prohlášení je povinné pole" : "",
				},
				amount: {
					title: "",
					defaultValue: 0
				},
				credits_for_other_forms: {
					title: "Požadované kredity za jiné formy vzdělávání a odborné činnosti.",
					defaultValue: ""
				},
				index_attachment: {
					title: "Kredity za absolvované stáže doložte zasláním nascanovaného záznamu v indexu",
					defaultValue: undefined
				},
			}
		}, context);
		this.formsFiles = new forms.FormCollection({
			fields: {
				attachment: {
					title: "Připojit soubor",
					defaultValue: undefined
				}
			}
		}, this.context, this.form);
		this.formsFiles.add();
	}

	addFile = () => this.formsFiles.add();

	removeFile = async (formFile: forms.Form<FileFormFields>) =>
		await this.context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? this.formsFiles.remove(formFile)
			: undefined

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	loadCertifications = async () => {
		const certifications = await this.context.apiUsers.loadUserCertifications();
		const data = certifications.data;
		data.unshift({ id: api.emptyGuid, name: "", code: "" })
		await this.stateContainer.merge(_ => ({
			certifications: data,
		}));
	}

	loadAmount = async () => {
		const amount = await this.context.api.get<number>("/request_types/" + "zadost-o-vydani-osvedceni-pro-absolvovani-vp" + "/price/", {});
		await this.form.setField("amount", amount);
	}

	/**
	 * Načte kredity
	 */
	loadCredits = async (id?: string) => {
		if (id) {
			const credits = await this.context.api.post("/users/records/" + id + "/credits", {}) as Credit[];
			const credit = this.form.getField("credits").value;
			if (credit.length == 0) {
				await this.form.setField("credits", credits);
			}
		}
	}

	toggleCredit = async (category: Credit) => {
		const credits = this.form.getField("credits").value;
		await this.form.setField("credits", credits.map(i => ({ ...i, checked: i.id === category.id ? !i.checked : i.checked })));
	}

	getCertifications = () => {
		return this.stateContainer.get().certifications;
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsFiles.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			await this.common.send(data, "zadost-o-vydani-osvedceni-pro-absolvovani-vp");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { attachments, ...formFields } = data;
			await this.form.setFields(data);
			await Promise.all([
				this.formsFiles.setFields(attachments),
				this.loadAmount(),
				this.loadCertifications(),
				this.loadCredits(data.certificate)
			]);
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadAmount(),
			this.loadCertifications()
		]);
	}
}