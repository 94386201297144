/**
 * Informace o cookies
 */
import React from "react";
import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";


export default function PrivacyStatementPage() {
	return (
		<Page title={"Informace o cookies"} description={"Informace o cookies"}>
			<HtmlContent htmlKey="prohlaseni-o-ochrane-soukromi-content" />
		</Page>
	);
}