/**
 * Pohotovosti
 */
import React from "react";

import * as state from "../../../lib/state";
import * as emergencies from "./emergencies";

import Page from "../../../components/templates/Page";
import Card from "../../../components/controls/Card";
import Select from "../../../components/controls/Select";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

function EmergenciesPage() {
	const model = state.useStateContext().emergencies;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("pohotovosti-nadpis")} description={contents.getRes("pohotovosti-nadpis")}>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="pohotovosti-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>
			<HtmlContent htmlKey="pohotovosti-obsah" />
			<Select
				className="d-md-none"
				value={model.getActiveRegion()}
				onChange={model.setActiveRegion}
				options={emergencies.regions.map(i => ({ title: i.title, value: i }))}
			/>

			<div className="row">
				<div className="col-md-4 d-none d-md-block">
					{emergencies.regions.map((region, index) =>
						<div
							key={index}
							onClick={() => model.setActiveRegion(region)}
							className={"emergencies-page__menu-item " + (model.isRegionActive(region) ? "--active" : "")}
						>
							<h2 className="m-2">{region.title}</h2>
						</div>
					)}
				</div>
				<div className="col-md-8">
					{model.getRegionItems(model.getActiveRegion()).map((d, index) =>
						<Card key={index} className="mb-2">
							<HtmlContent content={d.text} />
						</Card>
					)}
				</div>
			</div>
		</Page>
	);
}



export default state.bindContainers(
	EmergenciesPage,
	c => c.emergencies
);
