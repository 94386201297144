/**
 * Model zadost-o-peci-o-dite-odvolani
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as webRequest from "../../shared/web-request/web-request";

/**
 * Definice dat formuláře
 */
export interface FormFields {
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
	}
};

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.common.send({}, "zadost-o-peci-o-dite-odvolani");
	}
} 