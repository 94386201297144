import * as React from "react";
import * as apiAddress from "./api-address";
import JoinedList from "../../../components/controls/JoinedList";

interface AddressProps {
	address?: apiAddress.Address;
}

export default function Address(props: AddressProps) {
	return <JoinedList
		items={[
			props.address?.street,
			props.address?.postcode,
			props.address?.city
		]}
		delimiter={", "}
		itemTemplate={item => item}
	/>;
}