/**
 * Stránka 404
 */
import React, { useEffect } from "react";

import * as navigation from "../../../lib/navigation";
import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function RegistrationStarPage() {

	useEffect(() => {
		const path = window.location.pathname + window.location.search;
		if (path == "/o-vzdelavani/kalendar-akci/?viewDetail=true&akceID=19913") {
			navigation.to("/vzdelavani/akce/da333e92-3f12-42e9-896e-0751470d16f7");
			return;
		}

		if (path == "/o-vzdelavani/kalendar-akci/?viewDetail=true&akceID=19875") {
			navigation.to("/vzdelavani/akce/6603ffd9-e26c-46f7-96bc-abd3a9ce605b");
			return;
		}

		if (path.includes("noRedirect")) {
			return;
		}

		(window as any).location = "https://old.dent.cz" + path;
	}, []);

	return (
		<Page title="Stránka neexistuje" description="">
			<main role="main">
				<HtmlContent htmlKey="stranka-nenalezena-presmerovani" />
				{/* <div className="container mb-40">
						<div className="row">
							<div className="col col-xl-5">
								<HtmlContent htmlKey="stranka-nenalezena" />
								<Link to="/" noStyle>
									<Button className="page-404__button-home">
										Domů
									</Button>
								</Link>
							</div>
						</div>
					</div> */}
			</main>
		</Page >
	);
}