/**
 * Model žádosti o změnu osobních údajů
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as formatting from "../../../lib/formatting";
import * as api from "../../../lib/api";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";

export interface State {
	titles_before: api.CodeBookItem[];
	titles_after: api.CodeBookItem[];
	nationalities: api.CodeBookItem[];
	pedagogical_titles: api.CodeBookItem[];
}

/**
 * Definice dat formuláře
 */
export interface FormFields {
	new_first_name: string;
	new_last_name: string;
	new_title_before?: api.CodeBookItem;
	new_pedagogical_title?: api.CodeBookItem;
	new_title_after?: api.CodeBookItem;
	new_other_title: string;
	approbation_test_date: string;
	ortodoncy: boolean;
	oral_ortodoncy: boolean;
	clinical_dentistry: boolean;
	attestation_first_degree: boolean;
	attestation_second_degree: boolean;
	dental_surgery: boolean;
	dental_prosthetics: boolean;
	child_stomatologi: boolean;
	other_specialization: boolean;
	paradontology: boolean;
	social_medicine: boolean;
	identification_number: string;
	nationality?: api.CodeBookItem;
	date_stay: string;
	other_text: string;
	clear: boolean;
	permanent_residence: boolean;
}

/**
 * Definice formuláře
 */
const formOptions: forms.FormOptions<FormFields> = {
	fields: {
		clear: {
			title: "",
			defaultValue: false
		},
		new_first_name: {
			title: "Nové jméno",
			defaultValue: ""
		},
		new_last_name: {
			title: "Nové příjmení",
			defaultValue: ""
		},
		new_title_before: {
			title: "Nový titul před jménem",
			defaultValue: undefined
		},
		new_pedagogical_title: {
			title: "Nový pedagogický titul",
			defaultValue: undefined
		},
		new_title_after: {
			title: "Nový titul za jménem",
			defaultValue: undefined
		},
		new_other_title: {
			title: "Jiný titul (v případě, že jste nenašli v nabídce výše) ",
			defaultValue: ""
		},
		approbation_test_date: {
			title: "Prohlašuji, že jsem složil/a aprobační zkoušku dne:",
			defaultValue: ""
		},
		ortodoncy: {
			title: "ortodoncie",
			defaultValue: false
		},
		oral_ortodoncy: {
			title: "orální a maxilofaciální chirurgie",
			defaultValue: false
		},
		clinical_dentistry: {
			title: "klinická stomatologie",
			defaultValue: false
		},
		attestation_first_degree: {
			title: "atestace I. stupně",
			defaultValue: false
		},
		attestation_second_degree: {
			title: "atestace II. stupně",
			defaultValue: false
		},
		dental_surgery: {
			title: "stomatologická chirurgie",
			defaultValue: false
		},
		dental_prosthetics: {
			title: "stomatologická protetika",
			defaultValue: false
		},
		child_stomatologi: {
			title: "dětská stomatologie",
			defaultValue: false
		},
		other_specialization: {
			title: "jiné",
			defaultValue: false
		},
		paradontology: {
			title: "parodontologie",
			defaultValue: false
		},
		social_medicine: {
			title: "sociální lékařství a organizace zdravotnictví",
			defaultValue: false
		},
		identification_number: {
			title: "Rodné číslo",
			placeHolderText: "000000/0000",
			defaultValue: "",
			validate: value => formatting.identificationNumberValidation(value)!,

		},
		nationality: {
			title: "Státní příslušnost",
			defaultValue: undefined
		},
		date_stay: {
			title: "Pobyt na území ČR prodloužen do:",
			defaultValue: ""
		},
		other_text: {
			title: "",
			readOnly: (form) => form.getField("other_specialization").value ? false : true,
			placeHolderText: "uveďte jaké",
			defaultValue: ""
		},
		permanent_residence: {
			title: "Mám trvalý pobyt na území ČR",
			defaultValue: false
		}
	}
};

/**
 * Definice polí fomuláře přílohy
 */
export interface FileFormFields {
	attachment: apiFile.File | undefined;
}

/**
 * Data odesílaná a načítaná
 */
interface FormData extends FormFields {
	proof_of_applications: FileFormFields[];
	title_documents: FileFormFields[];
	title_documents_2: FileFormFields[];
	title_documents_3: FileFormFields[];
	specialization_documents: FileFormFields[];
	specialization_documents_2: FileFormFields[];
	specialization_documents_3: FileFormFields[];
	document_decision_mzcrs: FileFormFields[];
	identification_number_documents: FileFormFields[];
	nationality_documents: FileFormFields[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;
	public stateContainer: state.StateContainer<State>;
	public proof_of_applications: forms.FormCollection<FileFormFields>;
	public title_documents: forms.FormCollection<FileFormFields>;
	public title_documents_2: forms.FormCollection<FileFormFields>;
	public title_documents_3: forms.FormCollection<FileFormFields>;
	public specialization_documents: forms.FormCollection<FileFormFields>;
	public specialization_documents_2: forms.FormCollection<FileFormFields>;
	public specialization_documents_3: forms.FormCollection<FileFormFields>;
	public document_decision_mzcrs: forms.FormCollection<FileFormFields>;
	public identification_number_documents: forms.FormCollection<FileFormFields>;
	public nationality_documents: forms.FormCollection<FileFormFields>;


	constructor(private context: context.StateContext) {
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>(formOptions, context);

		/**
		 * Definice formuláře
		 */
		const proofOfApplicationsFormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined,
					validate: value => (this.form.getField("new_first_name").value || this.form.getField("new_last_name").value) && !value ? "Doklad je povinné pole" : "",
				}
			}
		};

		/**
		 * Definice formuláře
		 */
		const nationalityDocumentsFormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined,
					validate: value => (this.form.getField("nationality").value ||
						this.form.getField("date_stay").value) && !value ? "Doklad je povinné pole" : "",
				}
			}
		};

		/**
		 * Definice formuláře
		 */
		const identificationNumberDocumentsFormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined,
					validate: value => (this.form.getField("identification_number").value) && !value ? "Doklad je povinné pole" : "",
				}
			}
		};

		/**
		 * Definice formuláře
		 */
		const titleDocumentsFormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined,
					validate: value => ((this.form.getField("new_title_before").value || this.form.getField("new_pedagogical_title").value || this.form.getField("new_title_after").value || this.form.getField("new_other_title").value)) && !value ? "Doklad je povinné pole" : "",
				}
			}
		};

		/**
		 * Definice formuláře
		 */
		const titleDocuments2FormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined
				}
			}
		};

		/**
		 * Definice formuláře
		 */
		const titleDocuments3FormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined
				}
			}
		};
		/**
		 * Definice formuláře
		 */
		const specializationDocumentsFormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined,
					validate: value => (this.form.getField("ortodoncy").value ||
						this.form.getField("oral_ortodoncy").value ||
						this.form.getField("clinical_dentistry").value ||
						this.form.getField("attestation_first_degree").value ||
						this.form.getField("attestation_second_degree").value ||
						this.form.getField("dental_prosthetics").value ||
						this.form.getField("child_stomatologi").value ||
						this.form.getField("other_specialization").value ||
						this.form.getField("paradontology").value ||
						this.form.getField("dental_surgery").value ||
						this.form.getField("social_medicine").value) &&
						!value ? "Doklad je povinné pole" : "",
				}
			}
		};

		/**
		 * Definice formuláře
		 */
		const specializationDocuments2FormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined
				}
			}
		};

		/**
		 * Definice formuláře
		 */
		const specializationDocuments3FormOptions: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined
				}
			}
		};

		/**
		 * Definice formuláře
		 */
		const documentDecisionMzcrs: forms.FormOptions<FileFormFields> = {
			fields: {
				attachment: {
					title: "Příloha",
					defaultValue: undefined,
					validate: value => (this.form.getField("approbation_test_date").value) && !value ? "Doklad je povinné pole" : "",
				}
			}
		};

		this.proof_of_applications = new forms.FormCollection(proofOfApplicationsFormOptions, this.context, this.form);
		this.proof_of_applications.add();
		this.title_documents = new forms.FormCollection(titleDocumentsFormOptions, this.context, this.form);
		this.title_documents.add();
		this.title_documents_2 = new forms.FormCollection(titleDocuments2FormOptions, this.context, this.form);
		this.title_documents_2.add();
		this.title_documents_3 = new forms.FormCollection(titleDocuments3FormOptions, this.context, this.form);
		this.title_documents_3.add();
		this.specialization_documents = new forms.FormCollection(specializationDocumentsFormOptions, this.context, this.form);
		this.specialization_documents.add();
		this.specialization_documents_2 = new forms.FormCollection(specializationDocuments2FormOptions, this.context, this.form);
		this.specialization_documents_2.add();
		this.specialization_documents_3 = new forms.FormCollection(specializationDocuments3FormOptions, this.context, this.form);
		this.specialization_documents_3.add();
		this.document_decision_mzcrs = new forms.FormCollection(documentDecisionMzcrs, this.context, this.form);
		this.document_decision_mzcrs.add();
		this.identification_number_documents = new forms.FormCollection(identificationNumberDocumentsFormOptions, this.context, this.form);
		this.identification_number_documents.add();
		this.nationality_documents = new forms.FormCollection(nationalityDocumentsFormOptions, this.context, this.form);
		this.nationality_documents.add();

		this.stateContainer = new state.StateContainer<State>({
			pedagogical_titles: [],
			nationalities: [],
			titles_after: [],
			titles_before: []
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	/**
	 * Načte seznam zemí pro dropdown titulů
	 */
	loadTitlesBefore = async () => {
		const titlesBefore = await this.context.api.post("/code-list/title_befores", {}) as any;
		const data = titlesBefore.data;
		data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			titles_before: data
		}));
		await this.form.setField("clear", true);
	}

	getTitlesBefore = () => {
		return this.stateContainer.get().titles_before;
	}

	/**
	 * Načte seznam zemí pro dropdown titulů
	 */
	loadTitleAfter = async () => {
		const titlesAfter = await this.context.api.post("/code-list/title_afters", {}) as any;
		const data = titlesAfter.data;
		data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			titles_after: data
		}));
		await this.form.setField("clear", true);
	}

	getTitlesAfter = () => {
		return this.stateContainer.get().titles_after;
	}

	getNationalities = () => {
		return this.stateContainer.get().nationalities;
	}

	/**
	 * Načte seznam zemí pro dropdown titulů
	 */
	loadTitlesPedagogical = async () => {
		const pedagogicalTitles = await this.context.api.post("/code-list/title_pedagogicals", {}) as any;
		const data = pedagogicalTitles.data;
		data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			pedagogical_titles: data
		}));
		await this.form.setField("clear", true);
	}

	getTitlesPedagogical = () => {
		return this.stateContainer.get().pedagogical_titles;
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				proof_of_applications: this.proof_of_applications.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				title_documents: this.title_documents.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				title_documents_2: this.title_documents_2.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				title_documents_3: this.title_documents_3.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				specialization_documents: this.specialization_documents.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				specialization_documents_2: this.specialization_documents_2.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				specialization_documents_3: this.specialization_documents_3.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				document_decision_mzcrs: this.document_decision_mzcrs.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				identification_number_documents: this.identification_number_documents.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields()),
				nationality_documents: this.nationality_documents.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};
			await this.common.send(data, "zadost-o-zmenu-osobnich-udaju");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Proběhla kontrola formuláře. Opravte prosím označené chyby a akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { proof_of_applications, specialization_documents, specialization_documents_2, specialization_documents_3, document_decision_mzcrs, title_documents, title_documents_2, title_documents_3, identification_number_documents, nationality_documents, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.proof_of_applications.setFields(proof_of_applications),
				this.title_documents.setFields(title_documents),
				this.title_documents_2.setFields(title_documents_2),
				this.title_documents_3.setFields(title_documents_3),
				this.specialization_documents.setFields(specialization_documents),
				this.specialization_documents_2.setFields(specialization_documents_2),
				this.specialization_documents_3.setFields(specialization_documents_3),
				this.document_decision_mzcrs.setFields(document_decision_mzcrs),
				this.identification_number_documents.setFields(identification_number_documents),
			]);
			await Promise.all([
				this.nationality_documents.setFields(nationality_documents)
			]);
			this.loadTitleAfter();
			this.loadTitlesBefore();
			this.loadNationalities();
			this.loadTitlesPedagogical();
		}
	}

	loadNationalities = async () => {
		const data = await this.context.api.loadList<api.CodeBookItem>("/code-list/nationality", { per_page: 500 }) as any;
		data.data.unshift({ id: api.emptyGuid, name: "" });
		await this.stateContainer.merge(_ => ({
			nationalities: data.data
		}));
		await this.form.setField("clear", true);
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadTitleAfter(),
			this.loadTitlesBefore(),
			this.loadNationalities(),
			this.loadTitlesPedagogical(),
		]);
	}
}