import * as context from "../../../context";
import * as api from "../../../lib/api";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiWorkplace from "../../shared/workplace/api-workplace";
import * as apiRegionalChambers from "../../shared/regional-chambers/api-regional-chambers";
import * as apiAddress from "../../shared/address/api-address";
import * as apiContact from "../../shared/contact/api-contact";

export interface VisitingRegionalChamber {
	id: api.Id;
	regional_chamber: apiCodeList.CodeList;
	workplace: apiWorkplace.Workplace;
}

export interface WebMember extends api.EntityReadBase {
	full_name: string;
	last_name: string;
	regional_chamber_main: apiRegionalChambers.RegionalChamberSimple;
	workplaces: apiWorkplace.Workplace[];
}

export interface WebMemberFind {
	id: api.Id;
	identification_number: string;
	first_name: string;
	last_name: string;
	birthdate: Date;
	title_before: string;
	full_name: string;
	title_after: string;
	title_pedagogical: string;
	registration_number: string;
	vat_number: string;
	address: apiAddress.Address;
	contact: apiContact.Contact;
}

export class Api {
	standard: api.EntityApi<WebMember, WebMember>;

	constructor(private context: context.StateContext) {
		this.standard = new api.EntityApi<WebMember, WebMember>({
			api: context.api,
			path: "/members"
		});
	}

	loadRegionalChambers = () => {
		return this.context.api.loadList<apiCodeList.CodeList>("/member/regional_chambers", { per_page: Number.MAX_SAFE_INTEGER });
	}

	loadVisitingRegionalChambers = () => {
		return this.context.api.loadList<VisitingRegionalChamber>("/member/visiting_regoinal_chambers", { per_page: Number.MAX_SAFE_INTEGER });
	}

	loadVisitingRegionalChambersWithFilter = (emergency: boolean) => {
		return this.context.api.loadList<VisitingRegionalChamber>("/member/visiting_regoinal_chambers", { per_page: Number.MAX_SAFE_INTEGER, filter: [api.qp("just_an_emergency" as any, "=", emergency)] });
	}

	/**
	 * Vyhledá člena podle IKČ
	 */
	findMemberByIKC = (ikc: string) => {
		return this.context.api.get<WebMemberFind>("/members/find/" + ikc);
	}
}