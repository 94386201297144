import * as api from "../../../lib/api";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

export interface Item extends api.EntityReadBase {
	order_number: number;
	valid_from: Date;
	valid_to: Date;
	dynamic_list_state: apiCodeList.CodeListSystem;
	title: string;
	deleted: boolean;
	text: string;
	tags: apiCodeList.CodeList[];
	link: string;
}

export class Api {
	standard: api.EntityApi<Item, Item>;

	constructor(private context: context.StateContext) {
		this.standard = new api.EntityApi<Item, Item>({
			api: context.api,
			path: "/dynamic_lists"
		});
	}
}