/*
 * Komponenta checkboxu.
 */
import React from "react";
import DropDown, { DropDownProps } from "./DropDown";
import Icon from "../../modules/system/icons/Icon";

export interface DropDownMenuItem {
	title: string;
	onClick?: () => void;
}

export interface DropDownMenuProps {
	dropDownProps?: DropDownProps;
	className?: string;
	title?: string;
	currentItemTitle: string;
	items: DropDownMenuItem[];
}

export default function DropDownMenu(props: DropDownMenuProps) {
	return (
		<div className={"d-inline-block drop-down-menu " + (props.className ?? "")}>
			{props.title &&
				<strong className="text-nowrap">{props.title + ": "}</strong>
			}
			<DropDown
				{...props.dropDownProps}
				triggerElement={
					<div className="drop-down-menu__trigger py-1 color-text-dark text-nowrap">
						<span>{props.currentItemTitle}</span>
						<Icon picture="CaretDown" className="ml-1" />
					</div>
				}
			>
				<div className="text-left">
					{props.items.map(i =>
						<div
							key={i.title}
							className={"drop-down-menu__item"}
							onClick={i.onClick}
						>
							{i.title}
						</div>
					)}
				</div>
			</DropDown>
		</div>
	);
}