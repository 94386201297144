import React, { useState } from "react";
import Card, { CardProps } from "./Card";
import IconSvg from "./IconSvg";
import SlideDown from "./SlideDown";

type CardExpandableProps = CardProps & {
	open?: boolean;
	onToggle?: () => void;
	cardTitle?: React.ReactNode;
};

export default function CardExpandable(props: CardExpandableProps) {
	const { cardTitle, className, ...cardProps } = props;
	return (
		<Card className={"card-expandable " + (className ?? "")} {...cardProps}>
			<div className="d-flex justify-content-between align-items-center card-expandable__title" onClick={props.onToggle}>
				<div>{props.cardTitle}</div>
				<IconSvg name={props.open ? "minus" : "plus"} className="card-expandable__expand-icon" />
			</div>
			<SlideDown open={props.open ?? true}>
				{props.children}
			</SlideDown>
		</Card>
	);
}

export type CardExpandableSelfProps = {
	className?: string;
	cardTitle?: React.ReactNode;
	children?: React.ReactNode;
};

export function CardExpandableSelf(props: CardExpandableSelfProps) {
	let [open, setOpen] = useState(false);

	return (
		<CardExpandable onToggle={() => setOpen(!open)} cardTitle={props.cardTitle ?? ""} open={open} className={props.className ?? ""}>
			{props.children}
		</CardExpandable>
	);
}