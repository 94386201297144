/**
 * Jednání sněmu
 */

import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";

import CardLink from "../../../components/controls/CardLink";
import Spinner from "../../../components/controls/Spinner";
import Paginator from "../../../components/controls/Paginator";
import HtmlContent from "../../../components/controls/HtmlContent";

function AboutUsParliamentaryProceedingsPage() {
	const model = state.useStateContext().aboutUsParliamentaryProceedings;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("jednani-snemu-nadpis")} description={contents.getRes("jednani-snemu-nadpis")}>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="jednani-snemu-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>

			<div className="mb-5">
				{model.getCouncils().data.map(d =>
					<CardLink href={"/o-nas/jednani-snemu/" + d.id} className="d-block mb-3">
						{d.title}
					</CardLink>
				)}
			</div>

			<Paginator listResponse={model.getCouncils()} onPageChanged={model.loadData} />
		</Page>
	);
}


export default state.bindContainers(
	AboutUsParliamentaryProceedingsPage,
	c => c.aboutUsParliamentaryProceedings
);