/**
 * Stránka detailu člena (zubaře)
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import JoinedList from "../../../components/controls/JoinedList";
import Card from "../../../components/controls/Card";

function MemberPage() {
	const { member: model } = state.useStateContext();
	const member = model.getMember();
	const title = member?.full_name ?? "Zubní lékař";

	return (
		<Page title={title} description="">
			<h1 className="d-flex align-items-center">
				{title}
				{model.justLoading() &&
					<Spinner small className="ml-1" />
				}
			</h1>

			<h2>Působí v oblastech</h2>
			{member?.regional_chamber_main.name}

			<h2>Pracuje ve zdravotnických zařízeních</h2>
			{member?.workplaces.map(i =>
				<Card className="mb-3">
					<div className="row">
						<div className="col-md-8">
							<div className="secondary-title">{i.name}</div>
							<JoinedList
								items={[
									i.address?.street,
									i.address?.postcode,
									i.address?.city
								]}
								itemTemplate={i => i}
								delimiter=", "
							/>
						</div>
						<div className="col-md-4 d-md-flex justify-content-end">
							<div>
								<JoinedList
									items={[
										i.contact?.phone1,
										i.contact?.phone2
									]}
									beforeElement="Tel: "
									afterElement={<br />}
									itemTemplate={i => <strong>{i}</strong>}
									delimiter=", "
								/>
								<JoinedList
									items={[
										i.contact?.email1,
										i.contact?.email2
									]}
									beforeElement="E-mail: "
									afterElement={<br />}
									itemTemplate={i => <strong>{i}</strong>}
									delimiter=", "
								/>
								{i.contact?.web &&
									<>
										Web: <a href={i.contact?.web}>{i.contact?.web}</a>
									</>
								}
							</div>
						</div>
					</div>
				</Card>
			)}
		</Page >
	);
}

export default state.bindContainers(
	MemberPage,
	c => c.member
);