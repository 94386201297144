/**
 * Modul seznamu akcí
 */

import * as lists from "../../system/lists/lists";
import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as formatting from "../../../lib/formatting";
import * as api from "../../../lib/api";
import * as apiEducationalActions from "./api-educational-actions";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

interface State {
	groups: (apiCodeList.CodeList & { checked: boolean })[];
	categories: (apiCodeList.CodeListSystem & { checked: boolean })[];
	organizers: (apiEducationalActions.EducationalActionOrganizer & { checked: boolean })[];

	justLoadingGroups: boolean;
	justLoadingOrganizers: boolean;
	justLoadingCategories: boolean;
	filterExpanded: boolean;
}

interface FilterFields {
	actionGroups: apiCodeList.CodeList[];
	organizers: apiEducationalActions.EducationalActionOrganizer[];
	categories: apiCodeList.CodeListSystem[];

	fulltext: string;
	dateFrom: string;
	dateTo: string;
	lector: string;
	place: string;
	vsc: boolean;
	rvsoo: boolean;
	op: boolean;
	tk: boolean;
	tpk: boolean;
	elk: boolean;
	ok: boolean;
	pk: boolean;
	vkpoz: boolean;
	pzl: boolean;
	vppzl: boolean;
	vpchir: boolean;
	vpparo: boolean;
	vppedo: boolean;
	dvppzl: boolean;
}

interface ModelOptions {
	stages: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	/**
	 * Seznam výukových skupin
	 */
	public stateContainer: state.StateContainer<State>;

	/**
	*  Seznam akcí
	*/
	public list: lists.StandardList<apiEducationalActions.WebEducationalAction, FilterFields>;

	constructor(private context: context.StateContext, private options?: ModelOptions) {
		this.stateContainer = new state.StateContainer<State>({
			groups: [],
			organizers: [],
			categories: [],
			justLoadingGroups: false,
			justLoadingCategories: false,
			justLoadingOrganizers: false,
			filterExpanded: false
		}, context);

		this.list = new lists.StandardList<apiEducationalActions.WebEducationalAction, FilterFields>({
			context: context,
			standardEntityApi: context.apiEducationalActions.standard,
			defaultSorting: { field: "date_from", order: lists.SortingOrder.Desc },
			defaultPageSize: 30,
			defaultFilterMode: "advanced",
			filterSystem: _ => options?.stages
				? [
					// pouze stáže
					api.qp("educational_action_category.id" as any, "=", [{ id: 2 }, { id: 14 }])
				]
				: [
					// pouze akce
					api.qp("educational_action_category.id" as any, "!=", 2),
					api.qp("educational_action_category.id" as any, "!=", 14)
				],
			advancedFilter: {
				fulltext: {
					field: {
						title: "Vyhledat (např. parodontologie, chirurgie, pedostomatologie)",
						defaultValue: "",
						onChange: value => this.list.setFilterFulltextPhrase(value)
					},
					query: _ => {
						return [];
					}
				},
				vsc: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("vsc", "=", value)];
					}

				},
				elk: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("elk", "=", value)];
					}

				},
				ok: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("ok", "=", value)];
					}

				},
				op: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("op", "=", value)];
					}

				},
				pk: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("pk", "=", value)];
					}

				},
				dvppzl: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("dvppzl", "=", value)];
					}

				},
				pzl: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("pzl", "=", value)];
					}

				},
				rvsoo: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("rvsoo", "=", value)];
					}

				},
				tk: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("tk", "=", value)];
					}

				},
				tpk: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("tpk", "=", value)];
					}

				},
				vkpoz: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("vkpoz", "=", value)];
					}

				},
				vpchir: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("vpchir", "=", value)];
					}

				},
				vpparo: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("vpparo", "=", value)];
					}

				},
				vppedo: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("vppedo", "=", value)];
					}

				},
				vppzl: {
					field: {
						title: "",
						defaultValue: false
					},
					query: value => {
						return [api.qp("vppzl", "=", value)];
					}

				},
				dateFrom: {
					field: {
						title: "Datum od",
						defaultValue: ""
					},
					query: value => {
						const date = formatting.parseDate(value);
						return date ? [api.qp("date_from", ">=", date)] : [];
					}
				},
				dateTo: {
					field: {
						title: "Datum do",
						defaultValue: ""
					},
					query: value => {
						const date = formatting.parseDate(value);
						return date ? [api.qp("date_to", "<=", new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))] : [];
					}
				},
				lector: {
					field: {
						title: "Lektor",
						defaultValue: ""
					},
					query: value => value ? [api.qp("lecturers", "=", "*" + value + "*")] : []
				},
				place: {
					field: {
						title: "Místo konání",
						defaultValue: ""
					},
					query: value => value ? [api.qp("place_of_activity", "=", "*" + value + "*")] : []
				},
				actionGroups: {
					field: {
						title: "Určení",
						defaultValue: []
					},
					query: groups => [api.qp("educational_action_groups.id" as any, "=", groups)]
				},
				organizers: {
					field: {
						title: "Pořadatel",
						defaultValue: []
					},
					query: organizers => [api.qp("subject.id" as any, "=", organizers)]
				},
				categories: {
					field: {
						title: "Forma",
						defaultValue: []
					},
					query: category => [api.qp("educational_action_category.id" as any, "=", category)]
				}
			}
		});
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	/**
	 * Načte místa určení
	 */
	loadGroups = async () => {
		await this.stateContainer.merge(_ => ({
			groups: []
		}));
		const groups = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingGroups: true })),
			exec: () => this.context.apiEducationalActionGroups.loadList({
				sort_fields: ["name"]
			}),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingGroups: false }))
		});
		await this.stateContainer.merge(_ => ({
			groups: groups.data.map(i => ({ ...i, checked: false }))
		}));
	}

	/**
	 * Načte číselník pořadatelů
	 */
	loadOrganizers = async () => {
		await this.stateContainer.merge(_ => ({
			organizers: []
		}));
		const organizers = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingOrganizers: true })),
			exec: () => this.context.apiEducationalActions.loadOrganizers({
				sort_fields: ["name"]
			}),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingOrganizers: false }))
		});
		await this.stateContainer.merge(_ => ({
			organizers: organizers.data.map(i => ({ ...i, checked: false }))
		}));
	}

	/**
	 * Načte číselník kategorií (forem)
	 */
	loadCategories = async () => {
		await this.stateContainer.merge(_ => ({
			categories: []
		}));
		const categories = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoadingCategories: true })),
			exec: () => this.context.apiEducationalActionCategories.loadList({
				sort_fields: ["name"]
			}),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingCategories: false }))
		});
		await this.stateContainer.merge(_ => ({
			categories: categories.data.map(i => ({ ...i, checked: false }))
		}));
	}

	/**
	 * Načte data modulu
	 */
	loadData = async () => {
		if (this.options?.stages) {
			await Promise.all([
				this.list.load(),
				this.loadGroups()
			]);
		} else {
			await Promise.all([
				this.list.load(),
				this.loadGroups(),
				this.loadOrganizers(),
				this.loadCategories()
			]);
		}
	}

	/**
	 * Vrací skupiny (určení)
	 */
	getGroups = (key: keyof FilterFields) => {
		return this.list.getAdvancedFilterForm().getField(key).value as boolean;
	}

	/**
	 * Právě se načítají skupiny
	 */
	justLoadingGroups = () => {
		return this.stateContainer.get().justLoadingGroups;
	}

	/**
	 * Vrací seznam pořadatelů
	 */
	getOrganizers = () => {
		return this.stateContainer.get().organizers;
	}

	/**
	 * Právě se načítají pořadatelé
	 */
	justLoadingOrganizers = () => {
		return this.stateContainer.get().justLoadingOrganizers;
	}

	/**
	 * Vrací seznam kategorií (forem)
	 */
	getCategories = () => {
		return this.stateContainer.get().categories;
	}

	/**
	 * Právě se načítají kategorie (formy)
	 */
	justLoadingCategories = () => {
		return this.stateContainer.get().justLoadingCategories;
	}

	groupChecked = (group: apiCodeList.CodeList) => {
		return this.stateContainer.get().groups.find(i => i.id === group.id && i.checked) !== undefined;
	}

	setGroup = async (key: keyof FilterFields, value: boolean) => {
		await this.list.getAdvancedFilterForm().setField(key, value);
	}

	organizerChecked = (organizer: apiEducationalActions.EducationalActionOrganizer) => {
		return this.stateContainer.get().organizers.find(i => i.id === organizer.id && i.checked) !== undefined;
	}

	toggleOrganizer = async (organizer: apiEducationalActions.EducationalActionOrganizer, checked: boolean) => {
		await this.stateContainer.merge(prevState => ({
			organizers: prevState.organizers.map(i => ({ ...i, checked: i.id === organizer.id ? checked : i.checked }))
		}));
		await this.list.getAdvancedFilterForm().setField("organizers", this.stateContainer.get().organizers.filter(i => i.checked));
	}

	categoryChecked = (category: apiCodeList.CodeListSystem) => {
		return this.stateContainer.get().categories.find(i => i.id === category.id && i.checked) !== undefined;
	}

	toggleCategory = async (category: apiCodeList.CodeListSystem, checked: boolean) => {
		await this.stateContainer.merge(prevState => ({
			categories: prevState.categories.map(i => ({ ...i, checked: i.id === category.id ? checked : i.checked }))
		}));
		await this.list.getAdvancedFilterForm().setField("categories", this.stateContainer.get().categories.filter(i => i.checked));
	}

	clearFilter = async () => {
		await this.stateContainer.merge(prevState => ({
			groups: prevState.groups.map(i => ({ ...i, checked: false })),
			organizers: prevState.organizers.map(i => ({ ...i, checked: false })),
			categories: prevState.categories.map(i => ({ ...i, checked: false }))
		}));
		await this.list.clearFilter();
	}

	isStagesMode = () => {
		return this.options?.stages ?? false;
	}

	toggleEnhancedFilter = async () => {
		await this.stateContainer.merge(prevState => ({
			filterExpanded: !prevState.filterExpanded
		}));
	}

	enhancedFilterExpanded = () => {
		return this.stateContainer.get().filterExpanded;
	}
}