/**
 * Časopisy
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import HtmlContent from "../../../components/controls/HtmlContent";

export default function EducationMagazinesPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("casopisy-nadpis")} description={contents.getRes("casopisy-nadpis")}>
			<HtmlContent htmlKey="casopisy-obsah" />
		</Page>
	);
}