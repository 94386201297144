/**
 * Modul seznamu žádostí
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiWebRequests from "../../shared/web-request/api-web-request";

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiWebRequests.WebRequest>({
		context: context,
		title: "Seznam žádostí",
		quickFilterPlaceholder: "Vyhledejte žádost...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiWebRequests.standard,
		sortingFields: [],
		defaultSorting: { field: "date", order: lists.SortingOrder.Desc },
		grid: {
			minWidth: "1200px",
			columns: [
				{
					field: "number",
					type: "string",
					title: "Číslo žádosti",
					width: "180px"
				},
				{
					field: "request_state.name" as any,
					type: "string",
					title: "Stav",
				},
				{
					field: "request_type.name" as any,
					type: "string",
					title: "Typ",
					width: "30%"
				},
				{
					field: "date",
					type: "date",
					title: "Zadáno",
					width: "190px",
					alignRight: true
				},
				{
					field: "payment_date",
					type: "date",
					title: "Uhrazeno",
					width: "190px",
					alignRight: true
				},
				{
					field: "request_type.paid" as any,
					type: "boolean",
					title: "Poplatek",
				}
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiWebRequests.WebRequest>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.userLoggedIn()) {
			await this.list.loadWithResetPage();
		}
	}

	getRegionalChamberByPostcode = async (postcode: string) => {
		const chamber = await this.context.api.get("/regional_chambers/by_postcode/" + postcode);
		return chamber;
	}
}