/**
 * Domovská stránka
 */
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import * as state from "../../../lib/state";
import useSwitch from "../../../hooks/switch";

import Page from "../../../components/templates/Page";
import Button from "../../../components/controls/Button";
import IconSvg from "../../../components/controls/IconSvg";
import Requests from "../../../components/controls/Requests";
import SlideDown from "../../../components/controls/SlideDown";
import Label from "../../../components/controls/Label";
import List from "../../system/lists/List";
import HtmlContent from "../../../components/controls/HtmlContent";

function WebRequestsPage() {
	const { webRequests } = state.useStateContext();
	const formSwitch = useSwitch();
	const { contents } = state.useStateContext();

	return (
		<Page
			title={contents.getRes("zadosti-nadpis")}
			description=""
			authorized
		>
			<h1>
				<HtmlContent htmlKey="zadosti-nadpis" />
			</h1>
			<div className="u-mb-lg secondary-title">
				<Button theme="primary" onClick={formSwitch.toogle}>
					<IconSvg name={formSwitch.isOn ? "minus" : "plus"} className="mr-3" /> Nová žádost
				</Button>
			</div>
			<SlideDown open={formSwitch.isOn}>
				<div className="u-mb-lg">
					<Requests items={[
						{
							title: "Členská agenda",
							description: "Změna kontaktních údajů, Změna pracoviště, Změna výkonu povolání, Potvrzení o členství,...",
							items: [
								{
									name: "Žádost o změnu osobních údajů (titul, specializace, jméno, rodné číslo, státní příslušnost, povolení k pobytu)",
									link: "/moje-agenda/zadosti/zadost-o-zmenu-osobnich-udaju"
								},
								{
									name: "Žádost o změnu kontaktních údajů (adresa, telefon, e-mail, časopis, mailing)",
									link: "/moje-agenda/zadosti/zadost-o-zmenu-kontaktnich-udaju"
								},
								{
									name: "Čestné prohlášení - péče o dítě do 4 let věku",
									link: "/moje-agenda/zadosti/zadost-o-peci-o-dite",
								},
								{
									name: "Odvolání (zrušení) čestného prohlášení - péče o dítě do 4 let věku",
									link: "/moje-agenda/zadosti/zadost-o-peci-o-dite-odvolani",
									marginBottom: true
								},
								{
									name: "Žádost o zápis do seznamu členů oblastní komory (nová hlavní Oblastní komora, změna hlavní Oblastní komory, nové hlavní pracoviště, obnovení zápisu do OSK pro členy bez příslušnosti k OSK - Bez OSK- a Bez OSK+)",
									link: "/moje-agenda/zadosti/zadost-o-zapis-do-hlavni-osk"
								},
								{
									name: "Oznámení o zahájení výkonu povolání na území oblastní komory (přidání hostující Oblastní komory - evidovaný člen, nové nebo další hostující pracoviště, další pracoviště v rámci jedné OSK)",
									link: "/moje-agenda/zadosti/oznameni-o-zahajeni-vykonu"
								},
								{
									name: "Oznámení ukončení výkonu povolání (ukončení výkonu povolání tj. ukončení poskytovatele nebo pracoviště v hlavní nebo hostující Oblastní komoře)",
									link: "/moje-agenda/zadosti/zadost-o-ukonceni-vykonu-povolani"
								},
								{
									name: "Oznámení o přerušení výkonu povolání pouze pro OSVČ a právnické subjekty (přerušení poskytování zdravotních služeb v hlavní nebo hostující Oblastní komoře)",
									link: "/moje-agenda/zadosti/zadost-o-preruseni-vykonu-povolani"
								},
								{
									name: "Žádost o vyškrtnutí ze seznamu členů oblastní komory (zachování členství v ČSK, bez příslušnosti k OSK)",
									link: "/moje-agenda/zadosti/zadost-bez-osk"
								},
								{
									name: "Žádost o vystoupení z ČSK (ukončení členství)",
									link: "/moje-agenda/zadosti/zadost-o-vystoupeni-z-CSK",
									marginBottom: true
								},
								{
									name: "Žádost o vydání potvrzení o členství a bezúhonnosti v ČSK",
									link: "/moje-agenda/zadosti/zadost-o-potvrzeni-clenstvi"
								},
								{
									name: "Žádost o vydání potvrzení záruky odbornosti pro zvláštní smlouvu se zdravotní pojišťovnou o vystavování lékařských předpisů (preskripce)",
									link: "/moje-agenda/zadosti/zadost-o-preskripci",
									marginBottom: true
								},
								{
									name: "Žádost o vydání osvědčení o splnění podmínek pro výkon funkce odborného zástupce",
									link: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-odborny-zastupce"
								},
								{
									name: "Žádost o vydání osvědčení o splnění podmínek pro výkon funkce vedoucího lékaře a primáře",
									link: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-primar-vedouci-lekar",
									marginBottom: true
								},
								{
									name: "Žádost o prominutí poplatku nebo příspěvku České stomatologické komory",
									link: "/moje-agenda/zadosti/zadost-o-prominuti-prispevku"
								},
							],
						},
						{
							title: "Vzdělávací středisko",
							description: "Žádost o vydání osvědčení, Žádost o vykonání profesní zkoušky, Zařazení do vstupního vzdělávacího programu,...",
							items: [
								{
									name: "Žádost o zařazení do jednorázového vzdělávacího programu - Snímatelná protetika",
									link: "/moje-agenda/zadosti/zadost-o-jednorazovy-vzdelavaci-program-snimatelna-protetika",
									marginBottom: true
								},
								{
									name: "Žádost o zařazení do vstupního vzdělávacího programu",
									link: "/moje-agenda/zadosti/zadost-o-zarazeni-do-vstupniho-vp",
									marginBottom: true
								},
								{
									name: "Žádost o vykonání profesní zkoušky v rámci vstupního vzdělávacího programu",
									link: "/moje-agenda/zadosti/zadost-o-vykonani-profesni-zkousky-vp"
								},
								{
									name: "Žádost o vykonání opakované profesní zkoušky v rámci vstupního vzdělávacího programu",
									link: "/moje-agenda/zadosti/zadost-o-opakovani-profesni-zkousky-vp"
								},
								{
									name: "Žádost o změnu termínu profesní zkoušky",
									link: "/moje-agenda/zadosti/zadost-o-zmenu-terminu-profesni-zkousky",
									marginBottom: true
								}/*,
								{
									name: "Žádost o vykonání profesní zkoušky v rámci vstupního vzdělávacího programu v praktickém zubním lékařství (žadatel s desetiletou praxí – rok 2021 a 2022)",
									link: "/moje-agenda/zadosti/zadost-o-vykonani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl"
								}*/,
								{
									name: "Žádost o vykonání opakované profesní zkoušky v rámci vstupního vzdělávacího programu v praktickém zubním lékařství (žadatel s 10letou praxí, který žádost o výkonání profesní zkoušky VPPZL podal v roce 2021 a 2022)",
									link: "/moje-agenda/zadosti/zadost-o-vykonani-profesni-zkousky-opakovane-10let-praxe-vppzl",
									marginBottom: true
								},
								{
									name: "Žádost o vykonání profesní zkoušky v rámci vstupního vzdělávacího programu v praktickém zubním lékařství (žadatel s 10letou praxí – platné od 1. 1. 2023)",
									link: "/moje-agenda/zadosti/zadost-o-vyknani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl-2023",
									marginBottom: true
								},
								{
									name: "Žádost o vydání prvního osvědčení (netýká se absolventů vstupních vzdělávacích programů)",
									link: "/moje-agenda/zadosti/zadost-o-vydani-prvniho-osvedceni"
								},
								{
									name: "Žádost o vydání osvědčení po absolvování navazujícího vzdělávacího programu",
									link: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-pro-absolvovani-vp"
								},
								{
									name: "Žádost o prodloužení doby platnosti osvědčení o 1 rok",
									link: "/moje-agenda/zadosti/zadost-o-prodlouzeni-doby-platnosti-osvedceni",
									marginBottom: true
								},
								{
									name: "Žádost o započítání kreditů za účast na neregistrované vzdělávácí akci nebo jiné formě vzdělávání nebo jiné odborné činnosti.",
									link: "/moje-agenda/zadosti/zadost-o-zapocitani-kreditu"
								},
								{
									name: "Žádost o vydání osvědčení po absolvování celoživotního vzdělávání, mimo systém celoživotního vzdělání komory",
									link: "/moje-agenda/zadosti/zadost-o-vydani-osvedceni-po-celozivotnim-studiu",
								},
								{
									name: "Žádost o akreditaci typu I.",
									link: "/moje-agenda/zadosti/zadost-o-akreditaci-typu-i"
								},
								{
									name: "Žádost o akreditaci typu II.",
									link: "/moje-agenda/zadosti/zadost-o-akreditaci-typu-ii",
									marginBottom: true
								},
							]
						},
						{
							title: "Ostatní",
							description: "Seznam odborníků Komory, účet klinik, objednání ČSPZL",
							items: [
								{
									name: "Žádost o příspěvek z účtu klinik a vzdělávání",
									link: "/moje-agenda/zadosti/zadost-o-prispevek-z-uctu"
								},
								{
									name: "Žádost o zápis do seznamu odborníků Komory",
									link: "/moje-agenda/zadosti/zadost-o-zapis-do-seznamu-znalcu"
								},
								{
									name: "Závazná objednávka - časopis Česká stomatologie a praktické zubní lékařství",
									link: "/moje-agenda/zadosti/zavazna-objednavka-casopis-cspzl"
								},
							]
						}
					]} />
				</div>
			</SlideDown>
			<List
				simpleTable
				list={webRequests.list}
				itemNode={(item, field) => {
					switch (field) {
						case "request_state.name" as any: return item.request_state.id === 1 ? <Label>{eval("item." + field)}</Label> : undefined;
						case "number": return <Link to={"/moje-agenda/zadosti/" + item.request_type.url_web + "/" + item.id}>{item.number}</Link>;
						default: return undefined;
					}
				}}
			/>
		</Page>
	);
}

export default state.bindContainers(
	WebRequestsPage,
	context => context.webRequests
);