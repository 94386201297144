/**
 * Seznam mých akcí
 */
import React from "react";

import * as state from "../../../lib/state";
import * as navigation from "../../../lib/navigation";

import Page from "../../../components/templates/Page";
import List from "../../system/lists/List";
import Button from "../../../components/controls/Button";
import Checkbox from "../../../components/controls/Checkbox";

function MyEducationalActionsPage() {
	const { myEducationalActions } = state.useStateContext();
	const firstTypeActive = myEducationalActions.getFirstTypeActive();
	const secondTypeActive = myEducationalActions.getSecondTypeActive();

	function handleFilter(type: number) {
		if (type == 1) {
			myEducationalActions.setFirstTypeActive(!firstTypeActive);

		}
		if (type == 2) {
			myEducationalActions.setSecondTypeActive(!secondTypeActive);
		}
		myEducationalActions.listCompleted.loadWithResetPage();
	}

	async function handleAdd() {
		navigation.to("/moje-agenda/akce/pridat")
	}

	return (
		<Page
			title="Přehled celoživotního vzdělávání"
			description=""
			authorized
		>
			<h1>
				Absolvované celoživotní vzdělávání
			</h1>
			<div className="secondary-title">Podané přihlášky (pouze na akce pořádané VS ČSK)</div>
			<List list={myEducationalActions.listSubmitted} simpleTable />
			<div className="secondary-title">Absolvované celoživotní vzdělávání</div>
			<div id="records" className="row mb-3">
				{/*
				<div className="col-lg-4">
					<Checkbox
						title="Komorové akce"
						className="mb-0"
						checked={firstTypeActive}
						onCheckedChanged={() => handleFilter(1)} />
				</div>
				<div className="col-lg-4">
					<Checkbox
						title="Mimo-komorové akce"
						className="mb-0"
						checked={secondTypeActive}
						onCheckedChanged={() => handleFilter(2)} />
				</div>
				*/}

				<div className="col-lg-12">
					<Button style={{ height: 55 }} theme={"" as any} className="float-right my-educational-actions-btn mb-0 mt-0" onClick={handleAdd}>přidat záznam celoživotního vzdělávání</Button>
				</div>

			</div>
			<List list={myEducationalActions.listCompleted} simpleTable />
		</Page >
	);
}

export default state.bindContainers(
	MyEducationalActionsPage,
	context => context.myEducationalActions
);