/**
 * Článek publikace
 */
import React, { useState, useEffect } from "react";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as navigation from "../../../lib/navigation";

import PublicationArticleTemplate from "../../../components/templates/PublicationArticleTemplate";
import HtmlContent from "../../../components/controls/HtmlContent";
import Link from "../../../components/controls/Link";
import IconSvg from "../../../components/controls/IconSvg";

function PublicationArticlePage() {
	const model = state.useStateContext().publicationArticle;
	const content = model.getContent();
	const topic = model.getTopic();
	const justLoadingContent = model.getJustLoadingContent();
	const justLoadingTopic = model.getJustLoadingTopic();
	const isRoot = model.getIsRoot();
	const [isExpand, setIsExpand] = useState(false);

	useEffect(() => {
		const isExpand = api.getUrlParam("expand");
		if (isExpand) {
			setIsExpand(true);
		}
		else {
			setIsExpand(false);
		}
	}, []);

	async function loadData(isRoot: boolean, id: string) {
		await model.setIsRoot(isRoot);
		model.loadData(id);
	}

	async function handleTopic(id: string) {
		await model.setIsRoot(false);
		navigation.to("/komornik/clanek/" + id);
	}

	function handleTop() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	const title = topic
		? content
			? "Komorník > " + topic.number + " " + topic.name + " > " + content.number + " " + content.name
			: "Komorník > " + topic.number + " " + topic.name
		: "Komorník";

	const contentTitle = topic ? content
		? <div className="font-weight-bold">
			<Link to={"/komornik"}>{"Komorník > "}</Link>
			<Link onClick={() => loadData(true, topic.id)} to={"/komornik/clanek/" + topic.id + "?isRoot=true"}>{topic.number + " " + topic.name}</Link>
			<>{' > '}</>
			<Link onClick={() => loadData(false, content.id)} to={"/komornik/clanek/" + content.id}>{content.number + " " + content.name}</Link>
		</div> :
		<div className="font-weight-bold">
			<Link to={"/komornik"}>{"Komorník > "}</Link>
			<Link onClick={() => loadData(true, topic.id)} to={"/komornik/clanek/" + topic.id + "?isRoot=true"}>{topic.number + " " + topic.name}</Link></div> :
		<Link to={"/komornik/"}>{"Komorník"}</Link>;

	return (
		<PublicationArticleTemplate
			headerTitle={title}
			contentTitle={contentTitle}
			justLoading={justLoadingContent}
			justLoadingTopic={justLoadingTopic}
			topic={topic}
		>

			{content && !isRoot &&
				<div className="publication-topic-page-content-container">
					<div className="form-title secondary-title">Téma kapitoly</div>
					<HtmlContent content={content.theme} />

					{content.information.trim() != "" &&
						<div className="mt-4">
							<div className="form-title secondary-title">Obecný úvod</div>
							<HtmlContent content={content.information} />
						</div>
					}

					<div className="form-title secondary-title">Obsah kapitoly</div>
					<HtmlContent content={content.content} />

					<HtmlContent content={content.text} />

					{content.keywords && content.keywords.length > 0 &&
						<p>
							<strong>Klíčová slova: </strong> {content.keywords}
						</p>
					}
					{!content.text &&
						<div className="publication-page-link font-weight-bold text-dark secondary-title">Nebyly nalezeny žádné výsledky vyhledávání.</div>
					}
				</div>
			}
			{topic && isRoot &&
				<ul>
					{topic.items.map((i, index) =>
						<React.Fragment key={index}>
							<div className="cursor-link font-weight-bold secondary-title" onClick={() => handleTopic(i.id)}><span>{i.number} {i.name}</span></div>
							{!isExpand &&
								<HtmlContent content={i.information} />
							}

						</React.Fragment>
					)}
					{topic.items.length == 0 &&
						<div className="publication-page-link font-weight-bold text-dark secondary-title">Nebyly nalezeny žádné výsledky vyhledávání.</div>
					}
				</ul>
			}
			<div onClick={handleTop} className="float-right mr-2 mt-4 publication-page-arrow-top"><IconSvg name="arrow-up" /></div>
		</PublicationArticleTemplate>
	);
}

export default state.bindContainers(
	PublicationArticlePage,
	c => c.publicationArticle
);