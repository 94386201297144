/**
 *  Formulář žádost o změnu údajů
 */

import React from "react";

import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import WebRequest from "../../../components/templates/WebRequest";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import FormFileUpload from "../../system/file/FormFileUpload";
import Button from "../../../components/controls/Button";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";

interface WebRequestChangeOfPersonalDataPageProps {
	id?: string;
}

function WebRequestChangeOfPersonalDataPage(props: WebRequestChangeOfPersonalDataPageProps) {
	const title = "ŽÁDOST O ZMĚNU OSOBNÍCH ÚDAJŮ (titul, specializace, jméno, rodné číslo, státní příslušnost, povolení k pobytu)";
	const { webRequestChangeOfPersonalData: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<h2 className="font-weight-bold">Doplňte pouze nové údaje.</h2>

			<hr />

			<div className="font-weight-bold mb-3">Změna jména / příjmení:</div>

			<div className="row">
				<div className="col-md-6">
					<FormsInput form={form} field="new_first_name" inputProps={{ type: "text" }} />
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<FormsInput form={form} field="new_last_name" inputProps={{ type: "text" }} />
				</div>
			</div>

			<strong>Poučení:</strong><br />
			<ul className="mb-3">
				<li>
					<small>
						Změna jména či příjmení je třeba doložit kopií příslušného úředního dokladu (matriční záznam, oddací list, občanský průkaz s novým jménem, doklad o rozvodu
						manželství s vyznačením nabytí právní moci apod.).
					</small>
				</li>
			</ul>
			<div>Jako doklad k žádosti přikládám: <span className="form-item__required">{(form.getField("new_first_name").value || form.getField("new_last_name").value) ? "*" : ""}</span></div>
			{model.proof_of_applications.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.proof_of_applications.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.proof_of_applications.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div className="font-weight-bold mb-3">Titul – změna / přidání:</div>

			<div className="row">
				<div className="col-md-6">
					<FormSelect form={form} field="new_title_before" options={
						model.getTitlesBefore().map(i => ({
							title: i.name,
							value: i
						}))
					} />
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<FormSelect form={form} field="new_pedagogical_title" options={
						model.getTitlesPedagogical().map(i => ({
							title: i.name,
							value: i
						}))
					} />
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<FormSelect form={form} field="new_title_after" options={
						model.getTitlesAfter().map(i => ({
							title: i.name,
							value: i
						}))
					} />
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<FormsInput form={form} field="new_other_title" inputProps={{ type: "text" }} />
				</div>
			</div>

			<strong>Poučení:</strong><br />
			<ul className="mb-3">
				<li>
					<small>
						Změna titulu je třeba doložit kopií dokladu o dosažení tohoto titulu (diplom apod.). Pokud je titul získán v zahraničí, je třeba doložit i jeho uznání Rozhodnutím Ministerstva zdravotnictví ČR. V případě zahraničního dokladu (diplomu) je třeba doložit i překlad do českého jazyka (musí být proveden překladatelem zapsaným do seznamu znalců a tlumočníků České republiky). Překlad není nutný u diplomů v latině (vydaných v ČR) a u diplomů ve slovenštině.
					</small>
				</li>
			</ul>

			<div>Jako doklad k žádosti přikládám (diplom): <span className="form-item__required">{(form.getField("new_title_before").value || form.getField("new_pedagogical_title").value || form.getField("new_title_after").value || form.getField("new_other_title").value) ? "*" : ""}</span></div>
			{model.title_documents.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.title_documents.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.title_documents.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div>Jako doklad k žádosti přikládám (Rozhodnutí MZČR):</div>
			{model.title_documents_2.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.title_documents_2.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.title_documents_2.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div>Jako doklad k žádosti přikládám (překlad do českého jazyka):</div>
			{model.title_documents_3.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.title_documents_3.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.title_documents_3.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div className="font-weight-bold mb-3">Uznání vzdělání / složení aprobační zkoušky :</div>

			<div className="row">
				<div className="col-md-6">
					<FormsInput form={form} field="approbation_test_date" inputProps={{ type: "date", min: formatting.formatDate(formatting.getDateBefore(new Date(), -1456), { format: "iso" }) }} />
				</div>
			</div>

			<strong>Poučení:</strong><br />
			<ul className="mb-3">
				<li>
					<small>
						Změnu je třeba doložit Rozhodnutím Ministerstva zdravotnictví ČR o uznání způsobilosti k výkonu povolání zubního lékaře v ČR.
					</small>
				</li>
			</ul>

			<div>Jako doklad přikládám (Rozhodnutí MZČR): <span className="form-item__required">{form.getField("approbation_test_date").value ? "*" : ""}</span></div>
			{model.document_decision_mzcrs.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.document_decision_mzcrs.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.document_decision_mzcrs.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div className="font-weight-bold mb-3">Získání / uznání dalšího vzdělání:</div>
			<div className="font-weight-bold">Specializace (dle zákona č. 95/2004 Sb.):</div>

			<div className=" row">
				<div className="col-md-4">
					<FormCheckBox
						form={form}
						field="ortodoncy"
					/>
				</div>
				<div className="col-md-4">
					<FormCheckBox
						form={form}
						field="oral_ortodoncy"
					/>
				</div>
				<div className="col-md-4">
					<FormCheckBox
						form={form}
						field="clinical_dentistry"
					/>
				</div>
			</div>

			<div className="font-weight-bold">Další dosažené vzdělání:</div>

			<div className=" row">
				<div className="col-md-4">
					<FormCheckBox
						form={form}
						field="attestation_first_degree"
					/>
				</div>
				<div className="col-md-4">
					<FormCheckBox
						form={form}
						field="attestation_second_degree"
					/>
				</div>
			</div>

			<div className="font-weight-bold">Nástavbová specializace:</div>

			<div className=" row">
				<div className="col-md-6">
					<FormCheckBox
						form={form}
						field="dental_surgery"
					/>
				</div>
				<div className="col-md-6">
					<FormCheckBox
						form={form}
						field="dental_prosthetics"
					/>
				</div>
				<div className="col-md-6">
					<FormCheckBox
						form={form}
						field="child_stomatologi"
					/>
				</div>
				<div className="col-md-6">
					<div className="row">
						<div className="col-md-4">
							<FormCheckBox
								form={form}
								field="other_specialization"
							/>
						</div>
						<div className="col-md-8">
							<FormsInput form={form} field="other_text" inputProps={{ type: "text" }} />
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<FormCheckBox
						form={form}
						field="paradontology"
					/>
				</div>
				<div className="col-md-6"></div>
				<div className="col-md-6">
					<FormCheckBox
						form={form}
						field="social_medicine"
					/>
				</div>
			</div>
			<hr />

			<strong>Poučení:</strong><br />
			<ul className="mb-3">
				<li>
					<small>
						Získání specializace nebo atestace je třeba doložit kopií dokladu o dosažení specializace nebo atestace (diplom apod.). Pokud je specializace nebo atestace získána
						v zahraničí, je třeba doložit i její uznání Rozhodnutím Ministerstva zdravotnictví ČR a úředním překladem dokladu o dosažení do češtiny (tj. provedený překladatelem
						nebo tlumočníkem zapsaným do seznamu znalců a tlumočníků ČR).
					</small>
				</li>
			</ul>

			<div>Jako doklad k žádosti přikládám (diplom): <span className="form-item__required">
				{(form.getField("ortodoncy").value ||
					form.getField("oral_ortodoncy").value ||
					form.getField("clinical_dentistry").value ||
					form.getField("attestation_first_degree").value ||
					form.getField("attestation_second_degree").value ||
					form.getField("dental_prosthetics").value ||
					form.getField("child_stomatologi").value ||
					form.getField("other_specialization").value ||
					form.getField("paradontology").value ||
					form.getField("dental_surgery").value ||
					form.getField("social_medicine").value) ? "*" : ""}
			</span></div>
			{model.specialization_documents.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.specialization_documents.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.specialization_documents.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div>Jako doklad k žádosti přikládám (Rozhodnutí MZČR):</div>
			{model.specialization_documents_2.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.specialization_documents_2.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.specialization_documents_2.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div>Jako doklad k žádosti přikládám (překlad do českého jazyka):</div>
			{model.specialization_documents_3.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.specialization_documents_3.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.specialization_documents_3.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div className="font-weight-bold">Přidělení rodného čísla:</div>

			<div className="row">
				<div className="col-md-6">
					<FormsInput form={form} field="identification_number" inputProps={{ type: "text" }} />
				</div>
			</div>

			<strong>Poučení:</strong><br />
			<ul className="mb-3">
				<li>
					<small>
						Přidělení rodného čísla je třeba doložit kopií dokladu, kde je nové rodné číslo uvedeno (občanský průkaz apod.).
					</small>
				</li>
			</ul>

			<div>Jako doklad k žádosti přikládám: <span className="form-item__required">{form.getField("identification_number").value ? "*" : ""}</span></div>
			{model.identification_number_documents.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.identification_number_documents.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.identification_number_documents.add}>Přidat přílohu</Button>
			</div>
			<hr />

			<div className="font-weight-bold mb-3">Změna státní příslušnosti / prodloužení povolení k pobytu:</div>

			<div className="row">
				<div className="col-md-6">
					<FormSelect
						form={form}
						field="nationality"
						options={
							model.getNationalities().map(i => ({
								title: i.name,
								value: i
							}))
						} />
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<FormsInput form={form} field="date_stay" inputProps={{ type: "date", min: formatting.formatDate(formatting.getDateBefore(new Date(), -1456), { format: "iso" }) }} />
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<FormsCheckBox
						form={form}
						field="permanent_residence"
					/>
				</div>
			</div>
			<strong>Poučení:</strong><br />
			<ul className="mb-3">
				<li>
					<small>
						Státní příslušnost je třeba doložit příslušným dokladem (např. občanský průkaz, cestovní pas apod.).
						Prodloužení povolení k pobytu na území ČR je třeba doložit příslušným dokladem (např. modrá karta, záznam v cestovním pasu, vízum apod.).
					</small>
				</li>
			</ul>

			<div>Jako doklad k žádosti přikládám: <span className="form-item__required">{
				(form.getField("nationality").value ||
					form.getField("date_stay").value) ? "*" : ""}</span></div>
			{model.nationality_documents.get().map((formAttachment, index) =>
				<div key={index} className="row">
					<div className="col-md-6">
						<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
					</div>
					<div className="col-4 col-lg-2">
						<Button onClick={() => model.nationality_documents.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
					</div>
				</div>
			)}
			<div className="mb-5">
				<Button onClick={model.nationality_documents.add}>Přidat přílohu</Button>
			</div>
			<hr />

			{
				!props.id &&
				<div className="text-right">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů</em><br /><br />
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestChangeOfPersonalDataPage,
	c => c.webRequestChangeOfPersonalData
);