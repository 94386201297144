/**
 * Orgány ČSK
 */
import React from "react";
import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Link, { LinkProps } from "../../../components/controls/Link";
import HtmlContent from "../../../components/controls/HtmlContent";

function Card(props: LinkProps) {
	const { className, ...restProps } = props;
	return (
		<Link className={"about-us-csk-authorities-page__card " + (className ?? "")} {...restProps}>
			<div className="about-us-csk-authorities-page__card-title">
				{props.children}
			</div>
			<div className="about-us-csk-authorities-page__card-link">
				Více informací
			</div>
		</Link>
	);
}

export default function AboutUsCskAuthoritiesPage() {
	const { contents } = state.useStateContext();
	return (
		<Page title="Orgány ČSK" description="">
			<h1><HtmlContent htmlKey="organy-csk-nadpis" /></h1>

			<div className="about-us-csk-authorities-page__cards">
				<div className="about-us-csk-authorities-page__cards-left">
					<Card href="/o-nas/clenove-snemu" className="mr-lg-3 mb-3 mb-lg-0">Sněm</Card>
					<div className="about-us-csk-authorities-page__cards-vertical">
						<Card href="/kontakty/osk" className="--osk mb-3">
							<span className="process-title">je volen</span>
							Oblastní<br /> stomatologické<br /> komory
						</Card>
						<Card href="/o-nas/predstavenstvo" className="--president mb-3">
							<span className="process-title">je volen</span>
							Představenstvo,<br /> prezident,<br /> viceprezident
						</Card>
					</div>
				</div>
				<div className="about-us-csk-authorities-page__cards-right">
					<Card href="/o-nas/revizni-komise" className="mr-3">Revizní komise</Card>
					<Card href="/o-nas/cestna-rada">Čestná rada</Card>
				</div>
			</div>
		</Page >
	);
}