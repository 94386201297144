/**
 * Seznam orgánů OSK
 */

import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";
import * as context from "../../../context";


export interface Section {
	id: string;
	name: string;
	organs: SectionOrgans[];
	category: api.CodeBookItem;
}

export interface SectionOrgans {
	is_group: boolean;
	items: SectionItem[];
	member_count: number;
	type: api.CodeBookItem;
}

export interface SectionItem {
	id: string;
	is_active: boolean;
	member: api.CodeBookItemWithFullName;
	member_organ_type: { id: 1, name: "Člen" }
}

/**
 * Stav modulu
 */
interface State {
	sections: Section[];
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			sections: [],
			justLoading: false
		}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer
	]

	justLoading = () => {
		return this.stateContainer.get().justLoading;
	}

	/**
	 * Načte data
	 */
	loadData = async () => {
		const sections = await this.context.api.post<any, { data: Section[] }>("/member_organs", {});
		await this.stateContainer.merge(prevState => ({
			sections: sections.data
		}));
	}

	getSections = () => {
		return this.stateContainer.get().sections;
	}
}