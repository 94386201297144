/**
 * Změna stavu
 */
import * as React from "react";
import * as state from "../../../lib/state";

import Dialog from "./Dialog";
import Button from "../../../components/controls/Button";
import EnterPressArea from "../../../components/controls/EnterPressArea";
import TextArea from "../../../components/controls/TextArea";

function ChangeStateDialog() {
	const { standardDialogs } = state.useStateContext();
	const dialog = standardDialogs.changeStateDialog;

	return (
		<Dialog dialog={dialog}>
			<EnterPressArea onEnter={dialog.close}>
				<h1 className="pb-1">Zde uveďte důvod rozhodnutí OSK (není povinné)</h1>
				<div>
					<TextArea
						style={{ width: 600 }}
						value={dialog.getCustomField("message")}
						onChange={value => dialog.setCustomField("message", value)}
					/>
				</div>
				<div className="mt-4 text-center">
					<Button onClick={e => standardDialogs.saveChangeStateDialog()} className="pt-2 pb-2 float-right">
						<strong>Odeslat</strong>
					</Button>
				</div>
			</EnterPressArea>
		</Dialog >
	);
}

export default state.bindContainers(ChangeStateDialog,
	context => context.standardDialogs.changeStateDialog
);