/**
 * Modul pro vývoj a test gridu
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as actions from "../../system/actions/actions";
import * as lists from "../../system/lists/lists";
import * as apiRecords from "../../shared/records/api-records";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Definice akcí nad hvězdou.
 */
export const actionList: actions.ItemActionList<apiRecords.Record> = {
	actions: []
};

/**
 * Vytvoří nový seznam hvězd.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiRecords.Record>({
		context: context,
		title: "Seznam záznamů",
		quickFilterPlaceholder: "Vyhledejte záznam...",
		itemActionsList: actionList,
		standardEntityApi: context.apiRecords.user,
		sortingFields: [],
		grid: {
			columns: [
				{
					field: "number",
					type: "string",
					title: "Evid. číslo"
				},
				{
					field: "record_category.name" as any,
					type: "string",
					title: "Druh"
				},
				{
					field: "record_type.name" as any,
					type: "string",
					title: "Typ"
				},
				{
					field: "date_of_award",
					type: "date",
					title: "Uděleno"
				},
				{
					field: "date_to",
					type: "date",
					title: "Platnost do"
				},
				{
					field: "link",
					type: "string",
					title: "Stáhnout"
				}
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiRecords.Record>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({ counter: 0 }, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.userLoggedIn())
			await this.list.loadWithResetPage();
	}
}