/**
 *  Zdravotní pojištovny
 */
import * as state from "../../../lib/state";
import HtmlContent from "../../../components/controls/HtmlContent";
import React from "react";

import Page from "../../../components/templates/Page";

export default function CoronavirusHealthInsurencePage() {
	const { contents } = state.useStateContext();
	return (
		<Page title={contents.getRes("zdravotni-pojistovny-nadpis")} description={contents.getRes("zdravotni-pojistovny-nadpis")} authorized>
			<HtmlContent htmlKey="zdravotni-pojistovny" />
		</Page>
	);
}