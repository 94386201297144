import * as api from "../../../lib/api";
import * as context from "../../../context";

export interface CodeList {
	id: api.Id;
	name: string;
	code: string;
	tooltip?: string;
}

export interface CodeListSystem {
	id: number;
	name: string;
	code: string;
}

export class Api {
	constructor(private context: context.StateContext, private codeList: string) { }

	loadList = (query: api.Query<CodeList>, options?: api.RequestOptions) => {
		return this.context.api.loadList("/code-list/" + this.codeList, query, options);
	}
}

export class ApiSystem {
	constructor(private context: context.StateContext, private codeList: string) { }

	loadList = (query: api.Query<CodeListSystem>, options?: api.RequestOptions) => {
		return this.context.api.loadList("/code-list-system/" + this.codeList, query, options);
	}
}