/**
 * Seznam zpráv
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";
import * as lists from "../../system/lists/lists";
import * as apiMessages from "../../shared/messages/api-messages";

/**
 * Stav modulu
 */
export interface State {
}

/**
 * Vytvoří nový seznam.
 */
export function createList(context: context.StateContext) {
	return new lists.StandardList<apiMessages.Message>({
		context: context,
		title: "Seznam zpráv",
		quickFilterPlaceholder: "Vyhledejte zprávu...",
		itemActionsList: { actions: [] },
		standardEntityApi: context.apiUsers.messages,
		sortingFields: [],
		grid: {
			minWidth: "1000px",
			columns: [
				{
					field: "message_state.name" as any,
					type: "string",
					title: "Stav",
				},
				{
					field: "date",
					type: "date",
					title: "Datum"
				},
				{
					field: "subject",
					type: "string",
					title: "Věc",
					width: "50%"
				},
				{
					field: "has_attachment",
					type: "boolean",
					title: "Příloha"
				}
			]
		},
		defaultPageSize: 40,
		hideTabFilter: true,
		hideQueryMode: true
	});
}

export class Model implements state.StateModel {
	list: lists.StandardList<apiMessages.Message>;
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.list = createList(context);
		this.stateContainer = new state.StateContainer<State>({}, context);
	}

	/**
	* Vrací kolekci stavových kontejnerů
	*/
	getStateContainers = () => [
		this.stateContainer,
		...this.list.getStateContainers()
	]

	loadData = async () => {
		if (this.context.authorization.userLoggedIn())
			await this.list.loadWithResetPage();
	}
}