/**
 * Staňte se členem
 */
import React, { useState } from "react";
import Page from "../../../components/templates/Page";
import * as state from "../../../lib/state";
import * as navigation from "../../../lib/navigation";

import CardExpandable from "../../../components/controls/CardExpandable";
import Button from "../../../components/controls/Button";
import Link from "../../../components/controls/Link";
import HtmlContent from "../../../components/controls/HtmlContent";


type ClickableExpandableCard = {
	cardTitle?: React.ReactNode;
	children?: React.ReactNode;
};

function ClickableExpandableCard(props: ClickableExpandableCard) {
	let [open, setOpen] = useState(false);

	return (
		<CardExpandable onToggle={() => setOpen(!open)} cardTitle={props.cardTitle ?? ""} open={open}>
			{props.children}
		</CardExpandable>
	);
}

export default function AboutUsBecomeMemberPage() {
	const { contents, authorization } = state.useStateContext();
	return (
		<Page title={contents.getRes("stante-se-clenem-nadpis")} description={contents.getRes("stante-se-clenem-nadpis")}>
			<HtmlContent htmlKey="stante-se-clenem-obsah-horni" />

			<p><u>K žádosti je nutno přiložit tyto podklady:</u></p>

			<ClickableExpandableCard
				cardTitle={
					<strong>
						A. Absolvent magisterského studijního programu stomatologie nebo zubního lékařství
						v českém jazyce na lékařské fakultě české univerzity nebo v českém a slovenském jazyce na
						lékařské fakultě československé univerzity.
					</strong>
				}>
				<p>
					<ol>
						<li>
							<p>Státní příslušník členského státu EU včetně občana ČR, příslušník smluvního státu dohody o Evropském hospodářském prostoru a občan Švýcarské konfederace dokládá:</p>
							<p>
								<ol className="about-us-list-bullets">
									<li>Kopii diplomu včetně dodatku k diplomu nebo potvrzení o absolvování studia. Pokud je dokladem k žádosti „Potvrzení o absolvování studia“, musí člen Komory dodatečně doložit kopii diplomu a dodatku k diplomu.</li>
									<li>
										Kopii dokladu o zdravotní způsobilosti (nesmí být starší 90 dnů).
										<p className="about-us-member-page-i">Zdravotní způsobilost se dokládá lékařským posudkem, který vydává registrující poskytovatel zdravotních služeb v oboru všeobecné praktické lékařství v ČR (náležitosti posudku – viz <a download href="/images/vzor-lekarsky-posudek-zdravotni-zpusobilost.pdf"><b>Vzor lékařského posudku</b></a>), a není-li, jiný praktický lékař. V případě zahraničního posudku je třeba doložit překlad do českého jazyka (musí být proveden tlumočníkem zapsaným do seznamu znalců a tlumočníků České republiky). Doklad nesmí být starší 90 dnů.</p>
									</li>
									<li>
										Kopii dokladu o bezúhonnosti (nesmí být starší 90 dnů).
										<p className="about-us-member-page-i">Bezúhonnost se dokládá výpisem z evidence Rejstříku trestů, dokladem prokazujícím splnění podmínky bezúhonnosti vydaným státem, jehož je fyzická osoba občanem, a doklady vydanými státy, ve kterých se fyzická osoba zdržovala v posledních 3 letech nepřetržitě déle než 6 měsíců; tyto doklady nesmí být starší 90 dnů. Nevydává-li stát uvedený ve větě první výpis z evidence trestů nebo rovnocenný doklad, nebo nelze-li jej získat, předloží fyzická osoba čestné prohlášení o bezúhonnosti, které učinila před notářem nebo příslušným orgánem tohoto státu. Cizinec, který je nebo byl státním občanem jiného členského státu Evropské unie nebo má nebo měl adresu bydliště v jiném členském státě Evropské unie, může místo výpisu z evidence obdobné rejstříku trestů doložit bezúhonnost výpisem z Rejstříku trestů s přílohou obsahující formace, které jsou zapsané v evidenci trestů jiného členského státu Evropské unie. V případě zahraničního výpisu je třeba doložit překlad do českého jazyka (musí být proveden tlumočníkem zapsaným do seznamu znalců a tlumočníků České republiky). Fyzické osoby, které mají zřízenou datovou schránku, mohou zaslat elektronický výpis z evidence Rejstříku trestů nebo doložit vytištěný elektronický výpis v  konvertované podobě.</p>
									</li>
								</ol>
							</p>
						</li>
						<li>
							<p>Státní příslušník jiného než členského státu EU a smluvního státu dohody o Evropském hospodářském prostoru a Švýcarské konfederace k dokladům uvedeným v bodu 1. doloží navíc:</p>

							<p>
								<ol className="about-us-list-bullets">
									<li>Kopii průkazu o povolení k pobytu</li>
								</ol>
							</p>
						</li>
					</ol>
				</p>
				<div className="mb-0" style={{ height: 0 }}>&nbsp;</div>
			</ClickableExpandableCard>

			<br />

			<ClickableExpandableCard cardTitle={<strong>B. Zubní lékař, který získal odbornou způsobilost a/nebo specializovanou způsobilost k výkonu povolání zubního lékaře v magisterském studiu v ČR v jiném jazyce než českém, v jiném členském státu EU než v ČR nebo v jiném než členském státu EU.</strong>}>
				<p className="mb-0">
					<ol>
						<li>
							<p>Státní příslušník členského státu EU včetně občana ČR, příslušník smluvního státu dohody o Evropském hospodářském prostoru a občan Švýcarské konfederace dokládá:</p>

							<p>
								<ol className="about-us-list-bullets">
									<li>
										Kopii rozhodnutí Ministerstva zdravotnictví ČR o uznání způsobilosti k výkonu povolání zubního lékaře v ČR s vyznačenou doložkou právní moci, nebo
									</li>
									<li>Kopii dokladů o dosažené kvalifikaci (diplom, osvědčení, event. jiný doklad) vydaných příslušným orgánem nebo institucí členského státu, které jsou uvedeny v seznamu dokladů o dosažené kvalifikaci (§ 28a odst. 1 zákona č. 95/2004 Sb.) a jejich překlady do českého jazyka (musí být provedeny tlumočníkem zapsaným do seznamu znalců a tlumočníků České republiky).</li>
									<li>Kopii dokladů o absolvovaném vzdělávání (přílohy diplomu – tzv. supplements) a jejich překlady do českého jazyka (musí být provedeny tlumočníkem zapsaným do seznamu znalců a tlumočníků České republiky)</li>
									<li>Kopii osvědčení o uznání vysokoškolského vzdělání (tzv. nostrifikace) v případě, že doklad o dosažené kvalifikaci není uveden v seznamu dokladů o dosažené kvalifikaci (§ 28a odst. 1 zákona č. 95/2004 Sb.).</li>
									<li>Kopii potvrzení o členství a bezúhonnosti v zahraničních profesních organizacích (např. Good standing letter apod) a jeho/jejich překlady do českého jazyka (musí být provedeny tlumočníkem zapsaným do seznamu znalců a tlumočníků České republiky).</li>
									<li>
										Doklad nebo kopii dokladu o zdravotní způsobilosti (nesmí být starší 90 dnů).
										<p className="about-us-member-page-i">Zdravotní způsobilost se dokládá lékařským posudkem, který vydává registrující poskytovatel zdravotních služeb v oboru všeobecné praktické lékařství v ČR (náležitosti posudku – viz <a download href="/images/vzor-lekarsky-posudek-zdravotni-zpusobilost.pdf"><b>Vzor lékařského posudku</b></a>), a není-li, jiný praktický lékař. V případě zahraničního posudku je třeba doložit překlad do českého jazyka (musí být proveden tlumočníkem zapsaným do seznamu znalců a tlumočníků České republiky). Doklad nesmí být starší 90 dnů.</p>
									</li>
									<li>
										Doklad nebo kopii dokladu o bezúhonnosti (nesmí být starší 90 dnů).
										<p className="about-us-member-page-i">Bezúhonnost se dokládá výpisem z evidence Rejstříku trestů, dokladem prokazujícím splnění podmínky bezúhonnosti vydaným státem, jehož je fyzická osoba občanem, a doklady vydanými státy, ve kterých se fyzická osoba zdržovala v posledních 3 letech nepřetržitě déle než 6 měsíců; tyto doklady nesmí být starší 90 dnů. Nevydává-li stát uvedený ve větě první výpis z evidence trestů nebo rovnocenný doklad, nebo nelze-li jej získat, předloží fyzická osoba čestné prohlášení o bezúhonnosti, které učinila před notářem nebo příslušným orgánem tohoto státu. Cizinec, který je nebo byl státním občanem jiného členského státu Evropské unie nebo má nebo měl adresu bydliště v jiném členském státě Evropské unie, může místo výpisu z evidence obdobné rejstříku trestů doložit bezúhonnost výpisem z Rejstříku trestů s přílohou obsahující formace, které jsou zapsané v evidenci trestů jiného členského státu Evropské unie. V případě zahraničního výpisu je třeba doložit překlad do českého jazyka (musí být proveden tlumočníkem zapsaným do seznamu znalců a tlumočníků České republiky). Fyzické osoby, které mají zřízenou datovou schránku, mohou zaslat elektronický výpis z evidence Rejstříku trestů nebo doložit vytištěný elektronický výpis v  konvertované podobě.</p>
									</li>

								</ol>
							</p>
						</li>

						<li>
							<p>Státní příslušník jiného než členského státu EU a smluvního státu dohody o Evropském hospodářském prostoru a Švýcarské konfederace k dokladům uvedeným v bodu 1. doloží navíc:</p>

							<p>
								<ol className="about-us-list-bullets">
									<li>Kopii průkazu o povolení k pobytu</li>
								</ol>
							</p>
						</li>
					</ol>
				</p>

			</ClickableExpandableCard>
			<HtmlContent htmlKey="stante-se-clenem-obsah-spodni" />
			<div className="text-center">
				{!authorization.userLoggedIn() &&
					<Button theme="primary" onClick={() => navigation.to("/moje-agenda/zadosti/zadost-o-zapis-do-csk")}>Žádost o zápis do seznamu členů ČSK a OSK</Button>
				}
				{authorization.userLoggedIn() &&
					<div className="text-danger font-weight-bold">Nelze podat Žádost o zápis do ČSK a OSK, jste již přihlášen.</div>
				}
			</div>
		</Page>
	);
}