import * as api from "../../../lib/api";
import * as apiWorkplace from "../workplace/api-workplace";
import * as context from "../../../context";

interface SubjectSimple {
	id: api.Id;
	name: string;
}

export interface Accreditation extends api.EntityReadBase {
	provider: SubjectSimple;
	workplace: apiWorkplace.Workplace;
	decision_date_from?: Date;
	decision_date_to?: Date;
}

export class Api {
	standard: api.EntityApi<Accreditation, Accreditation>;

	constructor(context: context.StateContext) {
		this.standard = new api.EntityApi<Accreditation, Accreditation>({
			api: context.api,
			path: "/accreditations"
		});
	}
}