/**
 * Model žádosti o prominutí poplatku nebo příspěvku ČSK
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as forms from "../../../lib/forms";
import * as common from "../../../lib/common";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiFile from "../../system/file/api-file";
import * as apiMembers from "../../shared/members/api-members";
import { CodeList } from "../../../modules/system/code-list/api-code-list";

interface State {
	justLoadingHostChambers: boolean;
	loaded: boolean;
	payments?: CodeList[];
}

type Remission = "full" | "partial";

interface HostChamber extends apiMembers.VisitingRegionalChamber {
	checked: boolean;
}

/**
 * Definice dat formuláře
 */
export interface FormFields {
	yearly_members_contribution: boolean;
	yearly_evidence_contribution: boolean;
	registration_fee: boolean;
	services_fee: boolean;
	yearly_members_contribution_for_year: string;
	remission: Remission;
	partial_remission_amount: string;
	reason: string;
	host_chambers: HostChamber[];
	payment?: CodeList;
	not_possible_to_select_more: boolean;
}


/**
 * Definice polí fomuláře přílohy
 */
export interface FileFormFields {
	attachment: apiFile.File | undefined;
}

/**
 * Definice formuláře
 */
const fileFormOptions: forms.FormOptions<FileFormFields> = {
	fields: {
		attachment: {
			title: "Příloha",
			defaultValue: undefined
		}
	}
};

/**
 * Data odesílaná a načítaná
 */
interface FormData extends FormFields {
	attachments: FileFormFields[];
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public formsAttachments: forms.FormCollection<FileFormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({ justLoadingHostChambers: false, loaded: false, payments: [] }, context);
		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				yearly_members_contribution: {
					title: "ročního členského příspěvku",
					onChange: () => this.form.setFields({
						yearly_evidence_contribution: false,
						registration_fee: false,
						services_fee: false
					}),
					defaultValue: true
				},
				yearly_evidence_contribution: {
					title: "ročního evidenčního příspěvku",
					onChange: () => this.form.setFields({
						yearly_members_contribution: false,
						registration_fee: false,
						services_fee: false
					}),
					defaultValue: false
				},
				registration_fee: {
					title: "registračního poplatku",
					onChange: () => this.form.setFields({
						yearly_members_contribution: false,
						yearly_evidence_contribution: false,
						services_fee: false
					}),
					defaultValue: false
				},
				services_fee: {
					title: "úhrady za služby",
					onChange: () => this.form.setFields({
						yearly_members_contribution: false,
						yearly_evidence_contribution: false,
						registration_fee: false
					}),
					defaultValue: false
				},
				remission: {
					title: "Prominutí částky",
					defaultValue: "full"
				},
				partial_remission_amount: {
					title: "Částka",
					defaultValue: "",
					validate: (value, field, form) => form.getField("remission").value == "partial" ? (value.trim().length === 0 || parseInt(value) <= 0 ? "Částka musí být vyšší než 0" : "") : ""
				},
				reason: {
					title: "",
					defaultValue: "",
					validate: value => value.trim().length === 0 ? "Odůvodnění žádosti je povinné pole" : ""
				},
				yearly_members_contribution_for_year: {
					title: "",
					defaultValue: "",
					placeHolderText: "za rok:",
					validate: value => value.trim().length === 0 ? "za rok je povinné pole" : Number(value) < 2020 ? "hodnota musí být větší než 2019" : Number(value) > new Date().getFullYear() ? ("maximální hodnota je " + new Date().getFullYear()) : ""
				},
				host_chambers: {
					title: "",
					defaultValue: [],
				},
				payment: {
					title: "",
					defaultValue: undefined
				},
				not_possible_to_select_more: {
					title: "Není možné zvolit více",
					defaultValue: false
				}
			}
		}, context);
		this.formsAttachments = new forms.FormCollection(fileFormOptions, this.context, this.form);
		this.formsAttachments.add();
	}

	private createHostChamber(hostChamber: HostChamber) {
		return hostChamber;
	}

	toggleHostChamber = async (chamber: HostChamber) => {
		await this.form.setField(
			"host_chambers",
			this.form.getField("host_chambers").value.map(i => ({
				...i,
				checked: chamber.id === i.id ? !i.checked : i.checked
			})));
	}

	justLoadingHostChambers = () => {
		return this.stateContainer.get().justLoadingHostChambers;
	}

	loaded = () => {
		return this.stateContainer.get().loaded;
	}

	loadRegionalChambers = async () => {
		const loadedChambers = await common.withIndication({
			start: () => this.stateContainer.merge(_ => ({ justLoadingHostChambers: true })),
			exec: () => this.context.apiMembers.loadVisitingRegionalChambersWithFilter(false),
			finish: () => this.stateContainer.merge(_ => ({ justLoadingHostChambers: false, loaded: true }))
		});
		const hostChambers = loadedChambers.data.map(i => this.createHostChamber({ ...i, checked: false }));
		await this.form.setField("host_chambers", hostChambers);
	}

	/**
 * Načte seznam plateb
 */
	loadPayments = async () => {
		const payments = await this.context.api.loadList<CodeList>("/systems/price_lists", {});
		await this.stateContainer.merge(_ => ({
			payments: payments.data
		}));
	}

	/**
	 * Vrací seznam plateb
	 */
	getPayments = () => {
		return this.stateContainer.get().payments;
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data: FormData = {
				...this.form.getFields(),
				attachments: this.formsAttachments.get().filter(i => i.getField("attachment").value !== undefined).map(i => i.getFields())
			};

			await this.common.send(data, "zadost-o-prominuti-prispevku");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		await this.common.loadData(webRequestId);
		const webRequest = this.common.getWebRequest();
		if (webRequest) {
			const data = webRequest.data as FormData;
			const { attachments, ...formFields } = data;
			await Promise.all([
				this.form.setFields(formFields),
				this.formsAttachments.setFields(attachments)
			]);
			this.loadRegionalChambers();
			this.loadPayments();
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			this.form.clearFields(),
			this.common.reset(),
			this.loadRegionalChambers(),
			this.loadPayments()
		]);
	}
}