/**
 *  Formulář žádosti o zařazení do vstupního vzdělávacího programu
 */
import React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";
import * as forms from "../../../lib/forms";
import * as webRequestInsuranceApplication from "./web-request-checkout-from-insurance";
import WebRequest from "../../../components/templates/WebRequest";
import FormInput from "../../../components/controls/Forms/FormsInput";
import Button from "../../../components/controls/Button";
import FormFileUpload from "../../system/file/FormFileUpload";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import Checkbox from "../../../components/controls/Checkbox";
import IconSvg from "../../../components/controls/IconSvg";
import FormsRadio from "../../../components/controls/Forms/FormRadio";

interface Props {
	id?: string;
}

function WebRequestCheckoutFromInsurancePage(props: Props) {
	const title = "ODHLÁŠKA Z POJIŠTĚNÍ / OZNÁMENÍ DŮVODU UKONČENÍ POJIŠTĚNÍ";
	const { webRequestCheckoutFromInsurance } = state.useStateContext();
	const form = webRequestCheckoutFromInsurance.form;
	const configDate = formatting.fromDate(new Date(), 15, 4);

	function setItem(index: number, value: boolean) {
		const items = form.getField("termination_of_insurances").value.map(x => ({ ...x, checked: false }));
		items[index].checked = value;
		form.setField("termination_of_insurances", items);
	}

	function setValue(index: number, value: string) {
		const items = form.getField("termination_of_insurances").value;
		items[index].value = value;
		form.setField("termination_of_insurances", items);
	}

	interface FormFilesProps {
		forms: forms.FormCollection<webRequestInsuranceApplication.FilesFormFields>;
		label: React.ReactNode;
		hint?: React.ReactNode;
		required?: boolean;
	}

	function FormFiles(props: FormFilesProps) {
		return (
			<div className="web-request-osk-csk-entry-page__files mb-4">
				<div className="mb-1">
					<div>
						{props.label}
						{props.required &&
							<span className="form-item__required ml-1">*</span>
						}
					</div>
					<div>
						{props.hint}
					</div>
				</div>
				<Button className="mb-3" onClick={props.forms.add}>
					<IconSvg name="plus" className="mr-3" />

					{props.forms.get().length === 0 &&
						<>Připojit soubor</>
					}
					{props.forms.get().length > 0 &&
						<>Připojit další soubor</>
					}
				</Button >
				{props.forms.get().map((form, index) =>
					<>
						<FormFileUpload
							key={index}
							form={form}
							field="file"
						/>
						<div className="col-4 col-lg-2 pl-0">
							<Button className="d-block w-100 mt-3 mb-3" onClick={() => webRequestCheckoutFromInsurance.formsAttachment.remove(form)}>Odebrat</Button>
						</div>
					</>
				)
				}
			</div>
		);
	}

	const day = new Date().getDay();

	return (
		<WebRequest
			title={title}
			description=""
			authorized
			model={webRequestCheckoutFromInsurance.commonModel}
			id={props.id}
		>
			<>podle rámcové pojistné smlouvy č. 8601750250/8601750275 uzavřené mezi
				Českou stomatologickou komorou a Kooperativou pojišťovnou, a.s., Vienna Insurance Group
			</>

			<h2>A.	Údaje o pojištěném </h2>

			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="insurance_name"
					/>
				</div>
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="provider_address"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<FormInput
						form={form}
						field="representation"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="registration_number"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="identification_number"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="insurance_certificate_number"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="phone"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="mobile"
					/>
				</div>
				<div className="col-sm-4">
					<FormInput
						form={form}
						field="email"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="account_number"
					/>
				</div>
				<div className="col-sm-6">
					<FormInput
						form={form}
						field="mailing_address"
					/>
				</div>
			</div>

			<hr />

			<h2>B.	Údaje k ukončení pojištění</h2>

			<div><strong>Oznamuji / žádám o ukončení pojištění dle Rámcové pojistné smlouvy č. 8601750250/ 8601750275 z důvodu</strong> (zaškrtněte jednu z variant):</div>

			<table className="table-designed mt-5 mb-3">
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("termination_of_insurances").value[0].checked}
							onCheckedChanged={value => setItem(0, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-12">
									<label className="d-inline">úmrtí pojištěné fyzické osoby *</label>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("termination_of_insurances").value[6].checked}
							onCheckedChanged={value => setItem(6, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-12">
									<label className="d-inline">odchod do starobního důchodu</label>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("termination_of_insurances").value[1].checked}
							onCheckedChanged={value => setItem(1, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-12">
									<label className="d-inline">zániku pojištěného – právnické osoby bez právního nástupce      </label>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("termination_of_insurances").value[2].checked}
							onCheckedChanged={value => setItem(2, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-12">
									<label className="d-inline">zániku způsobilosti pojištěného – fyzické osoby k výkonu povolání zubního lékaře </label>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("termination_of_insurances").value[3].checked}
							onCheckedChanged={value => setItem(3, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-12">
									<label className="d-inline">zániku oprávnění pojištěného k poskytování zdravotních služeb ** </label>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("termination_of_insurances").value[4].checked}
							onCheckedChanged={value => setItem(4, value)}
						/>
					</td>
					<td>
						<div className="col-12">
							<div className="row">
								<div className="col-md-12">
									<label className="d-inline">pojištěný - právnická osoba přestal splňovat podmínku, že alespoň jeden její společník je členem ČSK  </label>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<Checkbox
							className="remove-panel-radio cursor-point no-background"
							checked={form.getField("termination_of_insurances").value[5].checked}
							onCheckedChanged={value => setItem(5, value)}
						/>
					</td>
					<td>
						na žádost, tj. pouze z důvodu rozhodnutí pojištěného nebýt nadále pojištěn na základě rámcové pojistné smlouvy***)
					</td>
				</tr>
			</table>

			<div>*) 	Připojte kopii úmrtního listu.</div>
			<div>**) 	Připojte kopii rozhodnutí o zrušení/odnětí oprávnění k poskytování zdravotních služeb.</div>
			<div>***) 	V případě ukončení na žádost zanikne pojištění uplynutím kalendářního měsíce, v němž je odhláška doručena ČSK.</div>

			<hr />

			<FormFiles
				forms={webRequestCheckoutFromInsurance.formsAttachment}
				label="Přílohy"
				required={true}
			/>

			<hr />
			<h3>
				<div className="row">
					<div style={{ maxWidth: 40 }} className="col-1">C.</div>
					<div className="col-11">
						<strong>Pojištění pro účely předepisování léčivých přípravků</strong> (možné pouze pokud je v oddíle B oznamován zánik oprávnění pojištěné fyzické osoby k poskytování zdravotních služeb, důvodem zániku oprávnění je odchod do starobního/invalidního důchodu a ČSK současně zaručuje odbornost pojištěného pro účely předepisování léčivých přípravků)
					</div>
				</div>
			</h3>

			<div className="mt-4">
				<strong>Mám zájem o pojištění pro účely předepisování léčivých přípravků po ukončení poskytování zdrav. služeb z důvodu odchodu do starobního/ invalidního důchodu</strong> za podmínek dle Rámcové pojistné smlouvy č. 8601750250/8601750275 za celkové <strong>roční pojistné 600 Kč</strong>
				<div className="d-flex mt-3">
					<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="insurance" option={true} title="ANO" />
					<FormRadio checkBoxProps={{ className: "remove-panel-radio" }} form={form} field="insurance" option={false} title="NE" />
				</div>
			</div>

			<hr />

			<div className="row">
				<div className="col-md-4">
					<FormInput
						inputProps={{
							type: "month",
							min: configDate.minDate,
							max: configDate.maxDate,
						}}
						form={form}
						field="date"
					/>
				</div>
			</div>
			{webRequestCheckoutFromInsurance.form.getField("date").value &&
				<div className="row">
					{day == 1 &&
						<div className="col-4">
							<FormsRadio
								form={form}
								field="day"
								option={1}
								title="1.den" />
						</div>
					}
					<div className="col-4">
						<FormsRadio
							form={form}
							field="day"
							option={15}
							title="15.den" />
					</div>
				</div>
			}

			{!props.id &&
				<div className="text-right">
					{/* <div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br /> */}
					<Button theme="primary" onClick={webRequestCheckoutFromInsurance.confirm} spinner={webRequestCheckoutFromInsurance.commonModel.justSending()}>Odeslat</Button>
				</div>
			}

		</WebRequest>
	);
}
export default state.bindContainers(
	WebRequestCheckoutFromInsurancePage,
	c => c.webRequestCheckoutFromInsurance
);