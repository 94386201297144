/**
 * O nas, Stavovské předpisy
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiDynamicLists from "../../shared/dynamic-lists/api-dynamic-lists";

/**
 * Stav modulu
 */
interface State {
	sections: { section: Section; data: apiDynamicLists.Item[] }[];
	justLoading: boolean;
}

interface Section {
	title: string;
	tag: string;
}

const sections: Section[] = [
	{ title: "Úplná znění řádů ČSK", tag: "stavovske-predpisy-uplna-zneni-radu-csk" },
	{ title: "Úplná znění závazných stanovisek ČSK", tag: "stavovske-predpisy-uplna-zneni-zavaznych-stanovisek-csk" },
	{ title: "Úplná znění opatření představenstva ČSK", tag: "stavovske-predpisy-uplna-zneni-opatreni-predstavenstva-csk" },
	{ title: "Úplná znění odborných stanovisek představenstva ČSK", tag: "stavovske-predpisy-uplna-zneni-odbornych-stanovisek-predstavenstva-csk" },
	{ title: "Sbírka předpisů ČSK", tag: "stavovske-predpisy-sbirka-predpisu-csk" },
	{ title: "Archiv úplných znění předpisů ČSK", tag: "stavovske-predpisy-archiv-uplnych-zneni-predpisu-csk" }
];

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			sections: sections.map(i => ({ section: i, data: [] })),
			justLoading: false
		}, context);
	}

	/**
 * Vrací kolekci stavových kontejnerů
 */
	getStateContainers = () => [
		this.stateContainer
	]

	justLoading = () => {
		return this.stateContainer.get().justLoading;
	}

	private getItemsByTag = (tag: string, data: apiDynamicLists.Item[]) => {
		return data.filter(i => i.tags.find(i => i.name === tag) !== undefined);
	}

	/**
	 * Načte data
	 */
	loadData = async () => {
		const list = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiDynamicLists.standard.loadList({ sort_fields: ["order_number"], filter: [api.qp("tagsname" as any, "=", sections.map(i => i.tag))], per_page: 999 }),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(prevState => ({
			sections: prevState.sections.map(i => ({ ...i, data: this.getItemsByTag(i.section.tag, list.data) }))
		}));
	}

	getSections = () => {
		return this.stateContainer.get().sections;
	}
}