/*
 * Komponenta checkboxu.
 */
import React, { useState } from "react";
import SlideDown from "./SlideDown";

export interface DropDownProps {
	children?: React.ReactNode;
	triggerElement?: React.ReactNode;
	className?: string;
	nested?: boolean;
	slide?: boolean;
}

export default function DropDown(props: DropDownProps) {
	const [open, setOpen] = useState(false);

	async function onTriggerClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		// cause onGlobalClick() calling before setOpen()
		setTimeout(() => setOpen(!open), 0);
	}

	async function onContentClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		if (props.nested !== true) {
			e.stopPropagation();
		}
		setOpen(false);
	}

	async function onGlobalClick() {
		setOpen(false);
	}

	return (
		<div
			className={"dropdown " + (open ? "dropdown--open " : "") + (props.nested ? "dropdown--nested " : "") + (props.className ?? "")}
		>
			<GlobalClickListener onClick={onGlobalClick} />
			<div onClick={onTriggerClick} className="dropdown__trigger">
				{props.triggerElement}
			</div>
			{props.slide &&
				<SlideDown open={open} className="dropdown__content" onClick={onContentClick}>
					{props.children}
				</SlideDown>
			}
			{!props.slide && open &&
				<div className="dropdown__content" style={{ display: !open ? "none" : "block" }} onClick={onContentClick}>
					{props.children}
				</div>
			}
		</div>
	);
}

interface GlobalClickListenerProps {
	onClick: () => void;
}

class GlobalClickListener extends React.Component<GlobalClickListenerProps> {
	componentDidMount = () => {
		window.addEventListener("click", this.props.onClick);
	}

	componentWillUnmount = () => {
		window.removeEventListener("click", this.props.onClick);
	}

	render = () => {
		return null;
	}
}