/**
 *  Formulář žádosti o prominutí poplatku nebo příspěvku ČSK
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";
import FormCheckBox from "../../../components/controls/Forms/FormCheckBox";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FormsTextArea from "../../../components/controls/Forms/FormsTextArea";
import FormFileUpload from "../../../modules/system/file/FormFileUpload";
import Checkbox from "../../../components/controls/Checkbox";
import Spinner from "../../../components/controls/Spinner";
import FormsSelect from "../../../components/controls/Forms/FormSelect";

interface WebRequestFreeRemissionPageProps {
	id?: string;
}

function WebRequestFreeRemissionPage(props: WebRequestFreeRemissionPageProps) {
	const title = "ŽÁDOST O PROMINUTÍ POPLATKU NEBO PŘÍSPĚVKU ČESKÉ STOMATOLOGICKÉ KOMORY";
	const { webRequestFeeRemission: model } = state.useStateContext();
	const form = model.form;

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>
			<div className="web-request-fee-remission-page">
				<div className="mb-5">
					<p>
						<strong>Žádám o prominutí:</strong>
					</p>
					<div className="row">
						<div className="col-md-8">
							<FormCheckBox form={form} field="yearly_members_contribution" className="mb-formitem" />
							<FormCheckBox form={form} field="yearly_evidence_contribution" className="mb-formitem" />
							<FormCheckBox form={form} field="registration_fee" className="mb-formitem" />
							<FormCheckBox form={form} field="services_fee" className="mb-formitem" />
						</div>
						<div className="col-md-4">
							<FormsInput inputProps={{ className: "web-request-year" }} form={form} field="yearly_members_contribution_for_year" />
						</div>
					</div>

					{model.form.getField("yearly_evidence_contribution").value &&
						<>
							<FormCheckBox form={form} field="not_possible_to_select_more" />
							<div className="mb-4">
								<em>Pokud žádáte o prominutí evidenčního příspěvků ve více hohstujících OSK, je třeba podat žádost na každou hostující OSK zvlášť.</em>
							</div>
						</>
					}

					{model.form.getField("services_fee").value &&
						<FormsSelect
							form={form}
							field="payment"
							options={[
								{
									title: "",
									value: undefined
								},
								...model.getPayments()!.map(i => ({
									title: i.name,
									value: i
								}))
							]}
						/>
					}

					<div>
						<p>
							<strong>Prominutí:</strong>
						</p>
						<div className="row mb-md-5">
							<div className="col-md-8">
								<CheckBoxRadioFrame className="mb-formitem mb-md-0">
									<FormRadio form={form} field="remission" option="full" title="úplné – tj. prominutí v celkové výši" />
									<FormRadio form={form} field="remission" option="partial" title="částečné – tj. snížení o částku" />
								</CheckBoxRadioFrame>
							</div>
							<div className="col-md-4">
								{form.getField("remission").value === "partial" &&
									<FormsInput form={form} field="partial_remission_amount" inputProps={{ type: "number" }} />
								}
							</div>
						</div>
						<p>
							<strong>Odůvodnění žádosti: <span className="form-item__required">*</span></strong>
						</p>
						<FormsTextArea form={form} className="h-650" field="reason" />

						<p>
							<strong>Přílohy žádosti (nepovinné):</strong>
						</p>

						{model.formsAttachments.get().map((formAttachment, index) =>
							<div key={index} className="row">
								<div className="col-md-6">
									<FormFileUpload form={formAttachment} field="attachment" formItemOptions={{ hideTitle: true }} />
								</div>
								<div className="col-4 col-lg-2">
									<Button onClick={() => model.formsAttachments.remove(formAttachment)} className="justify-content-center w-100 mb-4">Odebrat</Button>
								</div>
							</div>
						)}

						<div className="mb-5">
							<Button onClick={model.formsAttachments.add}>Přidat přílohu</Button>
						</div>
					</div>
				</div>

				{!props.id &&
					<div className="text-right">
						<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
						<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
						<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
					</div>
				}
			</div>
		</WebRequest >
	);
}



export default state.bindContainers(
	WebRequestFreeRemissionPage,
	c => c.webRequestFeeRemission
);