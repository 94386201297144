import * as api from "../../../lib/api";
import * as context from "../../../context";

export interface File {
	id: api.Id;
	name: string;
}

export interface FileFormFields {
	attachment?: api.CodeBookItem;
}

export enum DocumentType {
	Folder = 1,
	Word = 2,
	Excel = 3,
	Archive = 1,
	Video = 5,
	Powerpoint = 6,
	Image = 7,
	Txt = 8,
	Csv = 9,
	Audio = 10,
	Pdf = 11,
	Other = 12
}

export class Api {
	constructor(private context: context.StateContext) {
	}

	getDownloadLink = (id: api.Id) => {
		return this.context.api.options.endpoint + "/dms_files/" + id + "/download";
	}

	/**
	* Provede upload souboru.
	*/
	upload = async (file: globalThis.File, options?: api.RequestOptions) => {
		let formData = new FormData();
		if (options?.key) {
			formData.append("key", options?.key);
		}
		formData.append("file", file);
		const result = await this.context.api.post<FormData>("/files/00000000-0000-0000-0000-000000000000", formData, options)
		return result;
	}
}