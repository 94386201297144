/**
 *  Formulář oznámení o přerušení výkonu povolání
 */

import React from "react";

import * as state from "../../../lib/state";
import WebRequest from "../../../components/templates/WebRequest";
import Button from "../../../components/controls/Button";
import FormFileUpload from "../../system/file/FormFileUpload";
import IconSvg from "../../../components/controls/IconSvg";
import FormsCheckBox from "../../../components/controls/Forms/FormCheckBox";
import HtmlContent from "../../../components/controls/HtmlContent";

interface Props {
	id?: string;
}

function WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzlPage(props: Props) {
	const title = "ŽÁDOST O VYKONÁNÍ PROFESNÍ ZKOUŠKY V RÁMCI VSTUPNÍHO VZDĚLÁVACÍHO PROGRAMU V PRAKTICKÉM ZUBNÍM LÉKAŘSTVÍ (žadatel s desetiletou praxí – rok 2021 a 2022)";
	const { webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl: model } = state.useStateContext();

	return (
		<WebRequest
			model={model.common}
			title={title}
			description=""
			id={props.id}
		>

			<p>
				<div>Místo konání profesních zkoušek: <span className="font-weight-bold">ČSK, Vzdělávací středisko, Slavojova 22, 128 00 Praha 2.</span></div>
				<div>Termín zkoušky Vám Kancelář Komory přidělí s ohledem na aktuální nabídku dostupných termínů.</div>
			</p>

			<div className="font-weight-bold d-inline">ČESTNÉ PROHLÁŠENÍ ŽADATELE</div> <span className="form-item__required">*</span>
			<div>
				<FormsCheckBox
					form={model.form}
					field="confirmed_declaration"
					className="web-request-repeated-exam-in-vp__declaration"
					enhancedTitle={
						<>
							Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti, jsou pravdivé a úplné. Čestně prohlašuji, že jsem před zařazením do vzdělávacího programu v praktickém zubním lékařství vykonával/a povolání zubního lékaře alespoň v rozsahu preventivní, diagnostické a léčebné činnosti v oblasti péče o zuby, ústa, čelisti a související tkáně, a to souvisle po dobu nejméně 10 let. Čestně prohlašuji, že jsem členem Komory, že jsem způsobilý/á k samostatnému výkonu povolání zubního lékaře, že mi nebylo pravomocně uloženo disciplinární opatření anebo že takové disciplinární opatření již bylo zahlazeno, že jsem nebyl/a pravomocně odsouzen/a za trestný čin spáchaný v souvislosti s výkonem povolání zubního lékaře anebo že se na mě hledí, jako bych nebyl/a odsouzen/a, a že nemám vůči Komoře nedoplatek na stanovených příspěvcích či poplatcích nebo na pokutě uložené pravomocným rozhodnutím v disciplinárním řízení.
						</>
					} />
			</div>

			<span className="font-weight-bold">Přílohy</span>
			<ol className="about-us-list-bullets">
				<li className="text-decoration-underline">
					doklady prokazující souvislý výkon povolání zubního lékaře mimo území ČR (není-li doklad vyhotoven v českém nebo slovenském jazyce, nutno doplnit překladem do českého jazyka, není nutný úřední překlad)
				</li>
			</ol>

			{model.formsFiles.get().map((formFile) =>
				<div className="row pl-0">
					<div className="col-8 col-lg-10">
						<FormFileUpload
							fileUploadProps={{
								className: "mb-4"
							}}
							form={formFile}
							field="attachment"
						/>
					</div>
					<div className="col-4 col-lg-2">
						<Button className="d-block w-100 mt-4" onClick={() => model.removeFile(formFile)}>Odebrat</Button>
					</div>
				</div>
			)}
			<Button onClick={model.addFile} className="mb-5"><IconSvg name="plus" className="mr-3" /> Připojit další soubor</Button>
			<HtmlContent htmlKey="zadost-o-vykonani-profesni-zkousky-v-ramci-vstupniho-programu-vppzl-pouceni" />

			{!props.id &&
				<div className="text-right">
					<Button theme="primary" onClick={model.confirm} spinner={model.common.justSending()}>Odeslat</Button>
				</div>
			}
		</WebRequest >
	);
}

export default state.bindContainers(
	WebRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzlPage,
	c => c.webRequestForPerformanceProfessionalExamsWithinTheEntryProgramVppzl
);