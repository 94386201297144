/**
 * Modul pro tvorbu dialogů
 */

import * as state from "../../../lib/state";
import * as context from "../../../context";

/**
 * Stav dialogu
 */
interface State<CustomState = {}> {

	/**
	 * Dialog je otevřený
	 */
	readonly isOpen: boolean;
	readonly custom: CustomState;
}

/**
 * Vytvoří výchozí stav.
 */
function createPartialState<CustomState>(state: Partial<State<CustomState>>) {
	return state;
}

/**
 * Parametry dialogu
 */
interface DialogOptions<CustomFields = {}> {
	defaultCustomFields: CustomFields;
	hideCloseButton?: boolean;
}

export class Dialog<TResult = undefined, TCustomState = {}> implements state.StateModel {
	stateContainer: state.StateContainer<State<TCustomState>>;
	promiseResolve: ((value?: TResult) => void) | null = null;

	constructor(public options: DialogOptions<TCustomState>, private context: context.StateContext) {
		this.options = options;
		this.stateContainer = new state.StateContainer<State<TCustomState>>(
			this.createDefaultState(options.defaultCustomFields),
			context
		);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Vytvoří výchozí stav.
	 */
	createDefaultState = (defaultCustomFields: TCustomState): State<TCustomState> => {
		return {
			isOpen: false,
			custom: defaultCustomFields
		};
	}

	/**
	 * Otevře dialog
	 */
	open = async (): Promise<TResult | undefined> => {
		await this.stateContainer.merge(() => createPartialState({
			isOpen: true
		}));
		// await this.context.dialogs.stateContainer.merge((prevState) => createPartialGlobState({
		// 	openDialogsCount: prevState.openDialogsCount + 1
		// }));
		return new Promise<TResult | undefined>((resolve) => {
			this.promiseResolve = resolve;
		});
	}

	/**
	 * Uzavře dialog
	 */
	close = async (result?: TResult) => {
		await this.stateContainer.merge(() => createPartialState({
			isOpen: false,
		}));
		// await this.context.dialogs.stateContainer.merge((prevState) => createPartialGlobState({
		// 	openDialogsCount: prevState.openDialogsCount - 1
		// }));
		this.promiseResolve!(result);
	}

	/**
	 * Test, zda je dialog otevřený
	 */
	isOpen = () => {
		return this.stateContainer.get().isOpen;
	}

	/**
	 * Skryté tlačítko zavřít
	 */
	closeButtonHidden = () => {
		return this.options.hideCloseButton === true;
	}

	/**
	 * Vrací uživatelsky definované pole
	 */
	getCustomField = <TField extends keyof TCustomState>(field: TField) => {
		return this.stateContainer.get().custom[field];
	}

	/**
	 * Vrací uživatelsky definovaná pole
	 */
	getCustomFields = () => {
		return this.stateContainer.get().custom;
	}

	/**
	 * Nastaví uživatelsky definované pole
	 */
	setCustomField = async <TField extends keyof TCustomState>(field: TField, value: TCustomState[TField]) => {
		await this.stateContainer.merge((prevState) => createPartialState({
			custom: {
				...prevState.custom,
				[field]: value,
			}
		}));
	}
}

interface GlobState {
	openDialogsCount: number;
}

/**
 * Vytvoří výchozí stav.
 */
function createPartialGlobState(state: Partial<GlobState>) {
	return state;
}

export class Model {
	stateContainer: state.StateContainer<GlobState>;

	constructor(context: context.StateContext) {
		this.stateContainer = new state.StateContainer<GlobState>(
			{ openDialogsCount: 0 }, context
		);
	}

	isSomeDialogOpen = () => {
		return this.stateContainer.get().openDialogsCount > 0;
	}
}