/**
 * Jednání představenstva
 */

import React from "react";

import * as state from "../../../lib/state";

import Page from "../../../components/templates/Page";
import Spinner from "../../../components/controls/Spinner";
import CardLink from "../../../components/controls/CardLink";
import HtmlContent from "../../../components/controls/HtmlContent";

function DentistsVisitingGuests() {
	const model = state.useStateContext().dentistsVisitingGuests;
	const { contents } = state.useStateContext();

	return (
		<Page title={contents.getRes("seznam-hostujicich-osob-nadpis")} description={contents.getRes("seznam-hostujicich-osob-nadpis")}>
			<h1 className="d-flex align-items-center">
				<HtmlContent htmlKey="seznam-hostujicich-osob-nadpis" />
				{model.justLoading() &&
					<Spinner small className="ml-2" />
				}
			</h1>


			{model.getData().map(d =>
				<CardLink cardProps={{ className: "mb-3" }} href={d.link}>
					<HtmlContent content={d.text} />
				</CardLink>
			)}
		</Page>
	);
}

export default state.bindContainers(
	DentistsVisitingGuests,
	c => c.dentistsVisitingGuests
);