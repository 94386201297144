/**
 * Detail zubaře 
 */

import * as apiWorkplace from "../../shared/workplace/api-workplace";
import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as common from "../../../lib/common";

/**
 * Definice stavu
 */
interface State {
	workplace?: apiWorkplace.Workplace;
	justLoading: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	/**
	*  Seznam zubařů
	*/
	public stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoading: false
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	/**
	 * Načte data modulu
	 */
	loadData = async (id: api.Id) => {
		await this.stateContainer.merge(_ => ({
			workplace: undefined
		}));
		const dentist = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiWorkplace.standard.load(id),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(_ => ({
			workplace: dentist
		}));
	}

	getJustLoading = () => this.stateContainer.get().justLoading;
	getWorkplace = () => this.stateContainer.get().workplace;
}