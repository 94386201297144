/**
 * Orgán ČSK
 */

import * as state from "../../../lib/state";
import * as common from "../../../lib/common";
import * as navigation from "../../../lib/navigation";
import * as context from "../../../context";
import * as apiOrgans from "../../shared/organs/api-organs";

/**
 * Stav modelu
 */
interface State {
	authority?: apiOrgans.WebOrgan;
	justLoading: boolean;
	commision?: boolean;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	stateContainer: state.StateContainer<State>;

	constructor(private context: context.StateContext) {
		this.stateContainer = new state.StateContainer<State>({
			justLoading: false,
			commision: undefined
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => [
		this.stateContainer
	]

	justLoading = () => this.stateContainer.get().justLoading;

	private getId = () => {
		const route = navigation.getCurrent();
		switch (route) {
			case "/o-nas/predstavenstvo": return "598546ed-a3e3-4f2c-8e45-9c76c48102ff";
			case "/o-nas/revizni-komise": return "f5374d48-0e2c-4ffd-9531-1c57617f59fe";
			case "/o-nas/cestna-rada": return "54fff59e-46aa-44b4-be4e-e932627a1bd4";
			default: return undefined;
		}
	}

	/**
	 * Načte data
	 */
	loadData = async (commision: boolean) => {
		await this.stateContainer.merge(_ => ({
			authority: undefined,
			commision: commision
		}));

		const id = this.getId();
		if (!id) {
			return;
		}

		const authority = await common.withIndication({
			indicateStart: () => this.stateContainer.merge(_ => ({ justLoading: true })),
			exec: () => this.context.apiOrgans.load(id),
			finish: () => this.stateContainer.merge(_ => ({ justLoading: false }))
		});
		await this.stateContainer.merge(_ => ({
			authority: authority
		}));
	}

	getAuthority = () => this.stateContainer.get().authority;
	getCommision = () => this.stateContainer.get().commision;
}