import * as api from "../../../lib/api";
import * as apiFile from "../../system/file/api-file";
import * as apiSubject from "../subject/api-subject";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as apiPriceList from "../price-list/api-price-list";
import * as context from "../../../context";
import * as apiAuth from "../../../modules/system/authorization/api-authorization";

export interface WebEducationalAction extends api.EntityReadBase {
	name: string;
	description: string;
	introduction: string;
	show_hotel: boolean;
	is_public: boolean;
	perex: string;
	date_from?: Date;
	date_to?: Date;
	files: apiFile.File[];
	place_of_activity: string;
	lecturers: string;
	code: string;
	subject: apiSubject.Subject;
	groups: apiCodeList.CodeList[];
	credits: number;
	educational_action_category: apiCodeList.CodeListSystem;
	description_summary: string;
	price_lists: apiPriceList.WebPriceList[];
	social_programs: WebEducationalActionProgram[];
	programs: WebEducationalActionProgramGroup[];
	generate_payments: boolean;
	vsc?: boolean;
	rvsoo?: boolean;
	op?: boolean;
	tk?: boolean;
	tpk?: boolean;
	elk?: boolean;
	ok?: boolean;
	pk?: boolean;
	vkpoz?: boolean;
	pzl?: boolean;
	vppzl?: boolean;
	vpchir?: boolean;
	vpparo?: boolean;
	vppedo?: boolean;
	dvppzlvsc?: boolean;
	dvppzl?: boolean;
	do_not_register: boolean;
	capacity_exhausted?: boolean;
	profile: apiAuth.UserProfile;
}

export interface WebEducationalActionProgram extends api.CodeBookItem {
	price_lists: apiPriceList.WebPriceList[];
}

export interface WebEducationalActionProgramGroup {
	name: string;
	date?: Date;
	items: WebEducationalActionProgramGroupItem[];
}

export interface WebEducationalActionProgramGroupItem {
	id: api.Id;
	title: string;
	lecturers: string;
}

export interface EducationalActionOrganizer extends api.EntityReadBase {
	name: string;
}

export interface EducationalActionMember extends api.EntityReadBase {
	educational_action: apiCodeList.CodeList;
	credits: number;
	date_of_registration: Date;
	type: number;
	applied_to: string;
}

export interface Discount {
	amount: number;
	text: string;
}

export class Api {
	standard: api.EntityApi<WebEducationalAction, WebEducationalAction>;
	member: api.EntityApi<EducationalActionMember, EducationalActionMember>;

	constructor(private context: context.StateContext) {
		this.standard = new api.EntityApi<WebEducationalAction, WebEducationalAction>({
			api: context.api,
			path: "/educational_actions"
		});

		this.member = new api.EntityApi<EducationalActionMember, EducationalActionMember>({
			api: context.api,
			path: "/users/educational_actions"
		});
	}

	loadOrganizers = (query: api.Query<EducationalActionOrganizer>, options?: api.RequestOptions) => {
		return this.context.api.loadList("/educational_actions/organizers", query, options);
	}

	/**
	 * Načte slevu na akci
	 */
	loadDiscount = (actionId: api.Id, ikc?: string, options?: api.RequestOptions) => {
		let url = "/educational_actions/" + actionId + "/discount" + (ikc ? "?identification_number=" + ikc : "");
		return this.context.api.get<Discount>(url, options);
	}
}