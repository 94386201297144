/**
 * Stránka novinek
 */
import React, { useEffect } from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import FileList from "../../../components/controls/FileList";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

interface Props {
	isPage?: boolean;
	authorized?: boolean;
}

function NewsDetailPage(props: Props) {
	const title = "Aktualita";
	const context = state.useStateContext();
	const news = context.newsDetail.getNewsItem();
	const justLoading = context.newsDetail.getJustLoading();
	const { api } = state.useStateContext();
	const apiUrl = api.options.endpoint;

	useEffect(() => {
		function handleUserKeyPress(this: HTMLDivElement) {
			const el = this.querySelector(".html-expand-panel-body");
			if (el) {
				if (!el.className.includes("hidden")) {
					el.className = "html-expand-panel-body hidden";
				}
				else {
					el.className = "html-expand-panel-body";
				}
			}
		}

		const elements = document.querySelectorAll(".html-expand-panel");
		elements.forEach(e =>
			e.addEventListener("click", handleUserKeyPress)
		)

		return () => {
			elements.forEach(e =>
				e.removeEventListener("click", handleUserKeyPress)
			);
		};
	}, []);

	return (
		<Page authorized={props.authorized} title={title} description="">
			<div className="box-detail">
				<h1 className="mb-1">
					{justLoading &&
						<><Spinner className="mr-1" /> Načítám aktualitu...</>
					}
					{news?.title}
				</h1>
				{!props.isPage &&
					<p className="mb-3">
						<strong>
							{formatting.formatDate(news?.valid_from)}
						</strong>
					</p>
				}
				<div className="box-detail__content">
					<p>
						{news?.perex}
					</p>
					<p className="u-mb-lg">
						<HtmlContent content={news?.text} />
					</p>
					{news && news.files?.length > 0 &&
						<div className="u-mb-lg">
							<FileList items={news.files.map(f => ({
								fileName: f.name,
								icon: "doc",
								link: apiUrl + "/dms_files/" + f.id + "/download"
							}))} />
						</div>
					}
				</div>
			</div>

		</Page >
	);
}

export default state.bindContainers(
	NewsDetailPage,
	c => c.newsDetail
);