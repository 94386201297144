/**
 * State management pro reaktivní UI.
 */
import React, { useContext } from "react";
import md5 from "blueimp-md5";

import * as common from "./common";
import * as context from "../context";
import AppContext, { StateContext } from "../context";

export class StateContainerContext {
	ssrHookedStates: { [index: string]: any } =
		!common.serverExecution() && (window as any).ssrState
			? (window as any).ssrState
			: {};

	renderHookedSsrState() {
		return `window.ssrState = ${JSON.stringify(this.ssrHookedStates)};`;
	}
}

/**
 * Předpis metody pro transformaci původního stavu na nový parciální.
 */
export interface TransformMethod<State> {
	(prevState: State): Partial<State>;
}

/**
 * State container uchovává stavové informace, změny stavu jsou immutabilní,
 * umožňuje napojení na React komponentu.
 */
export class StateContainer<State> {

	/**
	 * Zde je uložen stav i v případě, pokud kontejner není napojen na 
	 * žádnou React komponentu.
	 */
	private internalState: State;

	/**
	 * Klíč, podle kterého je párován stav clienta se serveru při SSR
	 * 
	 */
	private ssrKey: string;

	/**
	 * React komponenta, na níž je kontejner napojen. Pokud dojde ke změně
	 * stavu, tak dojde k aktualizaci (rekonciliaci) této vizuální komponenty.
	 */
	private reactComponent?: React.Component;

	constructor(defaultState: State, private context: context.StateContext) {
		this.ssrKey = md5(JSON.stringify(defaultState));

		if (!common.serverExecution() && context.state.ssrHookedStates[this.ssrKey] !== undefined) {
			this.internalState = { ...context.state.ssrHookedStates[this.ssrKey] };
			this.convertDates(this.internalState);

		} else {
			this.internalState = { ...defaultState };
		}
	}

	/**
	 * Traverzuje do hloubky objekt a nahradí řetězce ze serializovaným datem objektem typu Date 
	 */
	convertDates = (o: any) => {
		const reCandidate = /^\d{4}.*/;
		const reISO = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d{3})([+-][0-2]\d:[0-5]\d|Z)$/;

		for (let p in o) {
			if (Array.isArray(o[p])) {
				for (let i of o[p]) {
					this.convertDates(o[p]);
				}

			} else if (typeof o[p] === "object") {
				this.convertDates(o[p]);

			} else if (typeof o[p] === "string") {
				if (reCandidate.test(o[p]) && reISO.test(o[p])) {
					o[p] = new Date(o[p]);
				}
			}
		}
	}

	/**
	 * Připojí kontejner k dané React komponentě. 
	 * 
	 * @param  {React.Component} reactComponent React komponenta
	 */
	bind = (reactComponent: React.Component): void => {
		this.reactComponent = reactComponent;
		reactComponent.state = { ...this.internalState };
	}

	/**
	 * Odpojí kontejner od react komponenty. Pokud je stávající stav
	 * napojen na jinou komponentu, než reactComponent, neprovede nic.
	 */
	unbind = (reactComponent: React.Component): void => {
		if (reactComponent === this.reactComponent) {
			this.reactComponent = undefined;
		}
	}

	/**
	 * Vrací stav.
	 */
	get = (): State => {
		if (this.reactComponent === undefined || common.serverExecution())
			return this.internalState;

		return this.reactComponent.state as State;
	}

	/**
	 * Aktualizuje stav pomocí transformační metody. Transformační metoda musí vracet 
	 * nový stav na základě předchozího stavu a vstupu jako immutable objekt. 
	 * V opačném případě by bylo narušeno korektní reaktivní fungování.
	 * 
	 * @param  {TransformMethod} transformMethod Metoda pro transformaci na nový stav
	 */
	merge = async (transformMethod: TransformMethod<State>): Promise<void> => {
		const newState = transformMethod(this.internalState);

		this.internalState = { ...this.internalState, ...newState };

		if (this.ssrKey && common.serverExecution()) {
			this.context.state.ssrHookedStates[this.ssrKey] = { ...this.internalState };
		}

		if (this.reactComponent === undefined || common.serverExecution())
			return;

		return new Promise<void>((resolve) => {
			if (this.reactComponent !== undefined) {
				this.reactComponent.setState(newState, resolve);
			}
		});
	}
}

/**
 * Vrací HOC, která zapouzdřuje React komponentu a jejíž stav je propojen s daným stavovým kontejnerem
 */
function bindContainer<State, ComponentProps>(
	component: React.ComponentType<ComponentProps>,
	container: StateContainer<State>
) {
	return class StateContainerHolder extends React.Component<ComponentProps> {
		constructor(props: any) {
			super(props);
			container.bind(this);
		}

		componentWillUnmount = () => {
			container.unbind(this);
		}

		render = () => {
			return React.createElement(component, this.props);
		}
	};
}

export interface StateModel {
	getStateContainers(): StateContainer<any>[];
}

/**
 * Vrací HOC, která zapouzdřuje React komponentu a jejíž stav je propojen s vícero stavovými kontejnery
 */
export function bindContainers<ComponentProps>(
	component: React.ComponentType<ComponentProps>,
	model: (context: StateContext) => StateModel
): React.ComponentType<ComponentProps> {

	const contexRootComponent = class ContextComponent extends React.Component<ComponentProps> {
		static contextType = AppContext;
		constructor(props: any, context: any) {
			super(props, context);
		}

		render = () => {
			let wrappedComponent = component;
			for (let container of model(this.context).getStateContainers()) {
				wrappedComponent = bindContainer(wrappedComponent, container);
			}
			return React.createElement(wrappedComponent, this.props);
		}
	};

	return contexRootComponent;
}

export function useStateContext() {
	return useContext(AppContext);
}