/**
 * API pro publikace
 */

import * as api from "../../../lib/api";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as context from "../../../context";

/**
 * Článek publikace
 */
export interface PublicationTopicContent extends api.EntityReadBase {
	number: string;
	name: string;
	parent?: api.CodeBookItem;
	theme: string;
	information: string;
	content: string;
	text: string;
	keywords: string;
	publication_topic: apiCodeList.CodeList;
}

/**
 * Téma publikace
 */
export interface WebPublicationTopic extends api.EntityReadBase {
	items: PublicationTopicContent[];
	menuItems: PublicationTopicContent[];
	number: string;
	name: string;
}

export class Api {
	topics: api.EntityApi<WebPublicationTopic, WebPublicationTopic>;
	contents: api.EntityApi<PublicationTopicContent, PublicationTopicContent>;

	constructor(private context: context.StateContext) {
		this.topics = new api.EntityApi<WebPublicationTopic, WebPublicationTopic>({
			api: context.api,
			path: "/publication_topics"
		});
		this.contents = new api.EntityApi<PublicationTopicContent, PublicationTopicContent>({
			api: context.api,
			path: "/publication_topic_contents"
		});
	}
}