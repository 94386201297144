/**
 * Stránka novinek
 */
import * as React from "react";
import * as state from "../../../lib/state";
import * as formatting from "../../../lib/formatting";

import Page from "../../../components/templates/Page";
import FileList from "../../../components/controls/FileList";
import Label from "../../../components/controls/Label";
import Spinner from "../../../components/controls/Spinner";
import HtmlContent from "../../../components/controls/HtmlContent";

function MessagePage() {
	const context = state.useStateContext();
	const message = context.message.get();
	const justLoading = context.message.justLoading();
	const title = message ? message.subject : "Zpráva";
	const { api } = state.useStateContext();
	const apiUrl = api.options.endpoint;

	return (
		<Page title={title} description="">
			<h1 className="d-flex align-items-center mb-2">
				{justLoading &&
					<><Spinner small className="mr-1" /> Načítám zprávu...</>
				}
				{!justLoading &&
					<>
						{title}
					</>
				}
			</h1>

			{message &&
				<>
					<p>
						<Label className="mr-3">{message?.message_state.name}</Label>
						<strong>{formatting.formatDate(message?.date)}</strong>
					</p>

					<p>
						<HtmlContent content={message.body} />
					</p>

					{message.attachments.length > 0 &&
						<FileList items={message.attachments.map(f => ({
							fileName: f.name,
							icon: "doc",
							link: apiUrl + "/dms_files/" + f.id + "/download"
						}))} />
					}
				</>
			}
		</Page >
	);
}

export default state.bindContainers(
	MessagePage,
	c => c.message
);