import * as api from "../../../lib/api";
import * as context from "../../../context";
import * as apiCodeList from "../../system/code-list/api-code-list";
import * as icons from "../../system/icons/icons";
import Icon from "../../../modules/system/icons/Icon";

export enum DocumentType {
	Folder = 1,
	Word = 2,
	Excel = 3,
	Archive = 1,
	Video = 5,
	Powerpoint = 6,
	Image = 7,
	Txt = 8,
	Csv = 9,
	Audio = 10,
	Pdf = 11,
	Other = 12
}

export interface DmsFile extends api.EntityReadBase {
	name: string;
	dms_file_type?: apiCodeList.CodeList;
	full_name: string;
	last_change: Date;
	user_last_change_full_name: string;
	publish_to_web: boolean;
}

export class Api {
	standard: api.EntityApi<DmsFile, DmsFile>;
	snem: api.EntityApi<DmsFile, DmsFile>;
	constructor(private context: context.StateContext) {
		this.standard = new api.EntityApi<DmsFile, DmsFile>({
			api: context.api,
			path: "/dms_files "
		});
		this.snem = new api.EntityApi<DmsFile, DmsFile>({
			api: context.api,
			path: "/dms_files_snem  "
		});
	}
}

export function getDocumentType(type: DocumentType) {
	let icon = "Folder" as icons.Icon;
	let color = "wheat";
	switch (type) {
		case DocumentType.Pdf:
			icon = "FilePdf";
			color = "lightcoral";
			break;
		case DocumentType.Word:
			icon = "FileWord";
			color = "cornflowerblue";
			break;
		case DocumentType.Excel:
			icon = "FileExcel";
			color = "green";
			break;
		case DocumentType.Powerpoint:
			icon = "FilePowerpoint";
			color = "rgb(199,19,69)";
			break;
		case DocumentType.Video:
			icon = "FileVideo";
			color = "rgb(199,19,69)";
			break;
		case DocumentType.Audio:
			icon = "FileAudio";
			color = "green";
			break;
		case DocumentType.Image:
			icon = "FileImage";
			color = "green";
			break;
		case DocumentType.Csv:
			icon = "FileCsv";
			color = "green";
			break;
		case DocumentType.Txt:
			icon = "FileTxt";
			color = "slategrey";
			break;
	}
	return { icon: icon, color: color };
}