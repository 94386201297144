/**
 * Model žádosti o prodloužení doby platnosti osvědčení o 1 rok
 */

import * as context from "../../../context";
import * as state from "../../../lib/state";
import * as api from "../../../lib/api";
import * as forms from "../../../lib/forms";
import * as formating from "../../../lib/formatting";
import * as webRequest from "../../shared/web-request/web-request";
import * as apiCodeList from "../../system/code-list/api-code-list";

/**
 * Stav modulu
 */
interface State {
	certifications: apiCodeList.CodeList[];
}

export interface FormFields {
	certificate?: api.Id;
	declaration: boolean;
	submission_date: string;
	amount: number;
}

/**
 * Model stránky
 */
export class Model implements state.StateModel {
	private stateContainer: state.StateContainer<State>;
	public form: forms.Form<FormFields>;
	public common: webRequest.Model;

	constructor(private context: context.StateContext) {
		const current_date = formating.formatDate(new Date());

		this.stateContainer = new state.StateContainer<State>({
			certifications: []
		}, context);

		this.common = new webRequest.Model(context);
		this.form = new forms.Form<FormFields>({
			fields: {
				certificate: {
					title: "Vyberte osvědčení",
					required: true,
					validate: value => this.getCertifications().length > 1 && value === api.emptyGuid ? "Osvědčení je povinné pole" : "",
					defaultValue: api.emptyGuid,
				},
				declaration: {
					title: "Čestně prohlašuji, že všechny údaje, které jsem uvedl/a v této žádosti, jsou pravdivé a úplné. Čestně prohlašuji, že jsem členem Komory, že jsem způsobilý/á k samostatnému výkonu povolání zubního lékaře, že mi nebylo pravomocně uloženo dosciplinární opatření anebo že takové disciplinární opatření již bylo zahlazeno, že jsem nebyl/a pravomocně odsouzen/a za trestní čin spáchaný v souvislosti s výkonem povolání zubního lékaře anebo že se na mně hledí, jako bych nebyl/a odsouzen/a, a že nemám vůči Komoře nedoplatek na stanovených příspěvcích či poplatcích nebo na pokutě uložené pravomocným rozhodnutím v disciplinárním řízení.",
					validate: value => !value ? "Prohlášení je povinné pole" : "",
					defaultValue: false
				},
				submission_date: {
					title: "Datum podání",
					readOnly: true,
					defaultValue: current_date
				},
				amount: {
					title: "",
					defaultValue: 0
				}
			}
		}, context);
	}

	/**
	 * Vrací kolekci stavových kontejnerů
	 */
	getStateContainers = () => {
		return [
			this.stateContainer,
			...this.common.getStateContainers(),
			...this.form.getStateContainers()
		];
	}

	loadCertifications = async () => {
		if (this.context.authorization.userLoggedIn()) {
			const certifications = await this.context.apiUsers.loadUserCertifications();
			const data = certifications.data;
			data.unshift({ id: api.emptyGuid, name: "", code: "" })
			await this.stateContainer.merge(_ => ({
				certifications: data,
			}));
			if (certifications.data.length > 0 && this.form.getField("certificate").value === undefined) {
				await this.form.setField("certificate", certifications.data[0].id);
			}
		}
	}

	loadAmount = async () => {
		const amount = await this.context.api.get<number>("/request_types/" + "zadost-o-prodlouzeni-doby-platnosti-osvedceni" + "/price/", {});
		await this.form.setField("amount", amount);
	}

	getCertifications = () => {
		return this.stateContainer.get().certifications;
	}

	/**
	 * Potvrdí formulář uživatelem
	 */
	confirm = async () => {
		await this.form.validate();
		if (this.form.isValid()) {
			const data = this.form.getFields();
			await this.common.send(data, "zadost-o-prodlouzeni-doby-platnosti-osvedceni");
		}
		else {
			await this.context.standardDialogs.openInformationDialog("Formulář obsahuje neúplné informace nebo chyby. Opravte prosím červeně vyznačené položky a poté akci opakujte.");
		}
	}

	/**
	 * Načte data do formuláře 
	 */
	loadData = async (webRequestId: string) => {
		if (this.context.authorization.userLoggedIn()) {
			await Promise.all([
				// this.form.setReadOnly(true),
				this.loadCertifications(),
				this.loadAmount()
			]);
			await this.common.loadData(webRequestId);
			const webRequest = this.common.getWebRequest();
			if (webRequest) {
				const data = webRequest.data as FormFields;
				await this.form.setFields(data);
			}
		}
	}

	/**
	 * Provede reset formuláře
	 */
	reset = async () => {
		await Promise.all([
			// this.form.setReadOnly(false),
			this.form.clearFields(),
			this.common.reset(),
			this.loadCertifications(),
			this.loadAmount()
		]);
	}
}